import {
  Email,
  SneModal,
  SneQuestion,
  SNE_INPUT_TYPES,
  SneMulChoiceOption,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';

const EmailThread: Email[] = [
  {
    emailSubject: 'Boosting Employee Engagement',
    emailResponse: [
      {
        from: 'Claudia',
        to: 'Felipe',
        image: '/assets/sne/ct-email-profile-pic.png',
        body: 'Last month, I encouraged two employees from my group to attend a leadership conference. My hope was that they would develop better interpersonal skills. However, their performance has not improved much: they have been just barely reaching their sales quotas since then. I will probably not recommend that other employees in my group attend such conferences, since there is no obvious benefit.',
      },
      {
        from: 'Felipe',
        to: 'Claudia',
        image: '/assets/sne/negotiation-jordan-profile-pic.png',
        body: 'I agree with you, Claudia. Too bad your employees have wasted time attending the conference… I think mindfulness is a better way to boost performance - it is all the rage now. Maybe you can ask your staff to try that?',
      },
    ],
  },
];

const question1Answers: SneMulChoiceOption[] = [
  {
    userOption:
      'She shifts from "developing better interpersonal skills" to "reaching sales quotas".',
    userLetter: 'A',
  },
  {
    userOption:
      'She does not mention how much it cost to attend the conference.',
    userLetter: 'B',
  },
  {
    userOption:
      'She uses a vague expression "barely reaching" to describe her employees\' performance.',
    userLetter: 'C',
  },
  {
    userOption:
      'She does not specify what type of product the two employees are selling.',
    userLetter: 'D',
  },
];

const question2Answers: SneMulChoiceOption[] = [
  {
    userOption:
      'Claudia is relatively new to the group and does not know all her employees all that well yet.',
    userLetter: 'A',
  },
  {
    userOption:
      'Claudia\'s group has consistently been among the best in the company in terms of sales performance.',
    userLetter: 'B',
  },
  {
    userOption:
      'Most employees in Claudia\'s group have actually seen a decrease in their sales performance since last month.',
    userLetter: 'C',
  },
  {
    userOption:
      '    Several employees in Claudia\'s group have also taken mindfulness classes.',
    userLetter: 'D',
  },
];

const question3Answers: SneMulChoiceOption[] = [
  {
    userOption:
      'She does not mention how much it cost to attend the conference.',
    userLetter: 'A',
  },
  {
    userOption: 'She is making a decision based on a couple of cases.',
    userLetter: 'B',
  },
  {
    userOption: 'She seems to expect that Felipe will agree with her.',
    userLetter: 'C',
  },
  {
    userOption:
      'She uses the word "encouraged" but probably means "ordered".',
    userLetter: 'D',
  },
];

const question4Answers: SneMulChoiceOption[] = [
  {
    userOption:
      'Leadership training can provide only sales-related benefits.',
    userLetter: 'A',
  },
  {
    userOption:
      'The leadership conference lasted longer than it should have.',
    userLetter: 'B',
  },
  {
    userOption:
      'The employees did not really want to attend the conference.',
    userLetter: 'C',
  },
  {
    userOption:
      'Attending conferences is not the best way to learn new things.',
    userLetter: 'D',
  },
];

const question5Answers: SneMulChoiceOption[] = [
  {
    userOption:
      'Have employees in other groups had similar outcomes from attending the conference?',
    userLetter: 'A',
  },
  {
    userOption:
      'What is the cost per employee of attending a leadership conference?',
    userLetter: 'B',
  },
  {
    userOption:
      'Is it always possible to objectively measure employee job performance?',
    userLetter: 'C',
  },
  {
    userOption:
      'How exactly is leadership training related to sales performance?',
    userLetter: 'D',
  },
  {
    userOption:
      'What are other approaches to improving employee performance?',
    userLetter: 'E',
  },
];

export const CTRhetoricalData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Evaluating a Training',
      info: 'This task will give you insight into how you evaluate written arguments, and we will use what we learn together to enhance your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/rhetorical.svg', // placeholder
      questionIndex: 3,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you\'ll have a chance to pause when you finish.',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question: 'Which of the following features of Claudia\'s statement makes her decision harder to evaluate?',
          mulChoiceOptions: [...question1Answers]
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question: 'Which of the following, if true, would most strongly <span class=\'convertToBlue\'>weaken</span> Claudia\'s conclusion that leadership training did not convey any benefit to her employees?',
          mulChoiceOptions: [...question2Answers]
        }
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question: 'Which of the following is the <span class=\'convertToBlue\'>most</span> appropriate criticism of Claudia\'s argument?',
          mulChoiceOptions: [...question3Answers]
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question: 'When Felipe says, "Too bad your employees have wasted time attending the conference", what assumption is he most likely making?',
          mulChoiceOptions: [...question4Answers]
        },
      ],
      submitId: 'item_4',
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      emailThread: [...EmailThread],
      numSelectOptions: 2,
      multipleMulChoiceOptions: [
        {
          question: 'Which <span class=\'convertToBlue\'>two</span> of the following questions would be the most helpful to answer for evaluating the claim that taking leadership training is beneficial?',
          mulChoiceOptions: [...question5Answers]
        },
      ],
      submitId: 'item_5',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work evaluating those emails! Your decisions help illustrate how you analyze written communication, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png', // placeholder
      questionIndex: 3,
      moduleSize: 6,
      buttonText: 'NEXT',
      progress: '16.67%', // placeholder,
      goto: CT_TYPES.SCHEDULING,
    },
  ],
]);

export const CTRhetoricalInstructionModal: SneModal = {
  title: 'Analyze an Email Exchange',
  pageList: [
    {
      info: 'Read the two emails that Claudia and Don recently sent to each other.',
    },
  ],
};
