import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EducationHistory } from '../types/education-history-types';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class EducationHistoryService extends BaseService {

  private educationHistoryUrl = environment.educationHistoryAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Education_History';
  }

  // Method to get the CPS Learner_Assessment records for the current user
  getEducationHistoryRecords(): Observable<EducationHistory[]> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let requestLogObj: LogObject = {
        message: 'Education History: Get Education History Records Request',
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<EducationHistory[]>(this.educationHistoryUrl, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Education History: Get Education History Records Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Method to save append any action to the answerJSON column of a Learner_Assessment record
  postAction(requestBody: EducationHistory[]): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = requestBody;

      let requestLogObj: LogObject = {
        message: 'Education History: Post Answer Request',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.educationHistoryUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Education History: Post Answer Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

}