import { Component, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Profile from '../../types/profile';
import { DashboardProfile, TopSkill } from '../../types/dashboard';
import { Assessment } from '../../types/assessments';
import { SneAssessmentRecord } from '../../types/sne';

@Component({
  selector: 'ets-dashboard-side-bar',
  templateUrl: './dashboard-side-bar.component.html',
  styleUrls: ['./dashboard-side-bar.component.scss']
})
export class DashboardSideBarComponent {

  constructor() { }

  @Input() dashboardProfile: DashboardProfile | undefined;
  @Input() profile: Profile | undefined;
  @Input() topSkills: TopSkill[] = [];
  @Input() skillsAssessments: Assessment[] = [];
  @Input() assessmentRecords: SneAssessmentRecord[] = [];

  faInfoCircle = faInfoCircle;
  careerChecklistTip = 'Your Career Checklist is a guide that that points you to actvities that are relevant during your career journey. Feeling stuck or lost? Check here periodically for next step suggestions.';
  myFoundationTip = 'Your Foundation is a reminder of the goals you\'ve set and what you\'re working toward on your career journey.';

}
