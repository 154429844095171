<div class="header-container"
    [ngClass]="{
        'CPS': module === 'CPS',
        'CRT': module === 'CRT',
        'CCH': module === 'CCH',
        'ORC': module === 'ORC',
        'WRC': module === 'WRC'
    }"
>
    <div class="banner-container">
        <p class="banner-text"></p>
    </div>

    <div class="outer-body-container">
        <div class="inner-body-container">
            <div class="title-container">
                <h2 class="title-name">{{name}}, your results are in.</h2>
                <h3 class="title-result" *ngIf="stringScore === 'low'">
                    You have <span class="convert-to-blue">room to grow</span> in <span class="capitalize">{{moduleInfo.humanReadableName}}.</span>
                </h3>
                <h3 class="title-result" *ngIf="stringScore === 'medium'">
                    You are <span class="convert-to-blue">on your way</span> in <span class="capitalize">{{moduleInfo.humanReadableName}}.</span>
                </h3>
                <h3 class="title-result" *ngIf="stringScore === 'high'">
                    You <span class="convert-to-blue">stand out</span> in <span class="capitalize">{{moduleInfo.humanReadableName}}.</span>
                </h3>
            </div>
            
            <div class="practice-container">
                <div class="practice-box">
                    <p class="practice-title">PRACTICE, PRACTICE, PRACTICE</p>
                    <p class="practice-text">Even those who have lots of experience in <span class="lowercase">{{moduleInfo.humanReadableName}}</span> can find ways to hone this skill. Check out the details below for tips on how to improve.</p>
                </div>
                
                <div class="triangle"></div>
            </div>
        </div>

        <!-- Vehicle image - one for each view -->
        <div class="vehicle-container">
            <img class="vehicle-img desktop" [src]="moduleInfo.vehicleImg" alt="Vehicle indicating progress" style="left: {{desktopVehiclePercent}}%">
            <img class="vehicle-img tablet" [src]="moduleInfo.vehicleImg" alt="Vehicle indicating progress" style="left: {{tabletVehiclePercent}}%">
            <img class="vehicle-img mobile" [src]="moduleInfo.vehicleImg" alt="Vehicle indicating progress" style="left: {{mobileVehiclePercent}}%">
        </div>
        <!-- Repeated wave image -->
        <div class="wave-img"></div>
    </div>

    <div class="progress-bar-container">  
        <!-- Bar -->
        <ul class="progress-bar">
            <li 
                *ngFor="let step of progressArray; let index=index"
                [ngClass]="{
                    'hide': index === 0,
                    'filled': roundedScore > ((index)*10),
                    'active': roundedScore === ((index)*10)
                }"
            >
            </li>
        </ul>

        <div class="experience-container">
            <p class="experience-text">INEXPERIENCED</p>
            <p class="experience-text">EXPERIENCED</p>
        </div>
    </div>
</div>