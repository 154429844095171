import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NegotiationService } from 'src/app/services/negotiation.service';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { ChatMessage, NegotiationAnswer, NegotiationQuestion, Star, StarStatus, Statement,SneMulChoiceQuestion, SneMulChoiceOption } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';
import { SneAnswerRequest, SNE_TASK_ENUM } from 'src/app/types/sne';

@Component({
  selector: 'ets-client-ranking-chat-history-split-screen',
  templateUrl: './client-ranking-chat-history-split-screen.component.html',
  styleUrls: ['./client-ranking-chat-history-split-screen.component.scss']
})
export class ClientRankingChatHistorySplitScreenComponent extends SneCollaborativeProblemSolvingBase implements OnInit {

  constructor(private negotiationService: NegotiationService, protected route: ActivatedRoute, protected router: Router, protected sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState = HEADER_STATES.BACK;
  chatList: ChatMessage[] = [];
  answer: NegotiationAnswer = {};
  negotiationQuestionNum=0;
  subs: Subscription[] = [];
  negotiationQuestionData: NegotiationQuestion | undefined;
  answerText: Statement | undefined;
  jordanStars: Star[] =[];
  clientStars: Star[] =[];
  StarStatus= StarStatus;
  scrollElement: HTMLElement | null = null;
  goToEnd = false;
  answerIndex: number|undefined;
  drawerVisible: boolean = false;
  nextBtnEnabled = false;
  currentModalPage = 0;

  backEndAnswers: SneMulChoiceOption[] = [];
  backEndQuestion: SneMulChoiceQuestion ={
    question: '',
    mulChoiceOptions: this.backEndAnswers,
  };


  singleResponseHandler(answer: any) {
    if (answer.answer === 'A'){
      this.answerIndex = 0;
      this.nextBtnEnabled = true;
    } else if (answer.answer === 'B'){
      this.answerIndex = 1;
      this.nextBtnEnabled = true;
    } else if (answer.answer === 'C'){
      this.answerIndex = 2;
      this.nextBtnEnabled = true;
    }
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
      this.multiResponse.sort();
    }
  }

  toggleDrawerVisible() {
    this.drawerVisible = !this.drawerVisible;
  }

  getNextQuestion() {
    this.negotiationQuestionNum++;

    this.answer.time = Date.now();
    if (this.negotiationQuestionNum) {
      this.subs.push(this.negotiationService.saveAndGetNextQuestion(this.answer).subscribe({
        next: question => {
          if (question.question === 'end') {
            this.backEndQuestion.question ='';
            let requestBody: SneAnswerRequest = {
              task: SNE_TASK_ENUM.CPS,
              data: null,
              completed: true
            };
            this.sneAnswerService.postAction(requestBody).subscribe();
            console.log('Collaborative Problem Solving completed.');
            super.getNextQuestion();
          }
          this.negotiationQuestionData = question;
          if (this.answerText) {
            let chat: ChatMessage = {
              user: 'You',
              message: this.answerText.message,
            };
            this.chatList.push(chat);
          }
          if (this.negotiationQuestionData.jordan) {
            let chat: ChatMessage = {
              user: 'Jordan',
              message: this.negotiationQuestionData.jordan,
            };
            this.chatList.push(chat);
          }

          if (!this.answer.answer) {
            this.negotiationQuestionData.question = 'You meet with Jordan on Monday morning. How do you want to begin your interaction?';
            this.backEndQuestion!.question='You meet with Jordan on Monday morning. How do you want to begin your interaction?';
          } else if (!this.negotiationQuestionData.options) {
            this.negotiationQuestionData.question = '';
            this.backEndQuestion!.question='';           
          } else {
            this.negotiationQuestionData.question = 'How would you like to respond to Jordan?';
            this.backEndQuestion!.question='How would you like to respond to Jordan?';
          } 

          if (!this.negotiationQuestionData.options) {
            this.goToEnd = true;
            this.answer.answer = 0;
            this.nextBtnEnabled = true;
          } else {
            this.answer = {};
          }

          this.fillStarArrays();
          this.answerText = undefined;
          this.answerIndex=0;
          if (this.scrollElement) {
            this.scrollElement.scrollTop = this.scrollElement?.scrollHeight + 500;
            console.log(this.scrollElement.scrollHeight);
          }

          
          for(let i =0;i< this.negotiationQuestionData.options.length; i++){
            this.backEndQuestion?.mulChoiceOptions.push({'userOption': this.negotiationQuestionData.options[i].text,'userLetter': String.fromCharCode(i+64+1)});
          }
        }
      })

      );
    }
  }

  updateAnswer(index: number) {
    this.nextBtnEnabled = false;
    this.answer.answer = index + 1;
    if (this.negotiationQuestionData?.options[index].text) {
      this.answerText = {
        person: 'You',
        message: '[' + this.negotiationQuestionData?.options[index].text + ']',
        image: '../../../assets/sne/negotiation-you-profile-pic'
      };
    }
    this.backEndQuestion.question='';
    this.backEndQuestion.mulChoiceOptions = [];
    this.getNextQuestion();
    this.toggleDrawerVisible();
  }

  fillStarArrays() {
    let newJordanStars: Star[] = [];
    let newClientStars: Star[] = [];
    for (let i = 0; i < 5; i++) {
      if (this.jordanStars[i].status === StarStatus.NEW_FILLED) {
        this.jordanStars[i].status = StarStatus.FILLED;
      } else if (this.jordanStars[i].status === StarStatus.NEW_EMPTY) {
        this.jordanStars[i].status = StarStatus.EMPTY;
      }

      if (this.clientStars[i].status === StarStatus.NEW_FILLED) {
        this.clientStars[i].status = StarStatus.FILLED;
      } else if (this.clientStars[i].status === StarStatus.NEW_EMPTY) {
        this.clientStars[i].status = StarStatus.EMPTY;
      }

    }
    if (this.negotiationQuestionData) {
      for (let i = 0; i < this.negotiationQuestionData.jordanSatisfaction; i++) {
        let newStar: Star = { status: StarStatus.FILLED };
        newJordanStars.push(newStar);
      }
      for (let i = 0; i < (5 - this.negotiationQuestionData.jordanSatisfaction); i++) {
        let newStar: Star = { status: StarStatus.EMPTY };
        newJordanStars.push(newStar);
      }
      for (let i = 0; i < this.negotiationQuestionData.clientSatisfaction; i++) {
        let newStar: Star = { status: StarStatus.FILLED };
        newClientStars.push(newStar);
      }
      for (let i = 0; i < (5 - this.negotiationQuestionData.clientSatisfaction); i++) {
        let newStar: Star = { status: StarStatus.EMPTY };
        newClientStars.push(newStar);
      }
      for (let i = 0; i < 5; i++) {
        if (newJordanStars[i].status !== this.jordanStars[i].status) {
          if (newJordanStars[i].status === StarStatus.FILLED) {
            newJordanStars[i].status = StarStatus.NEW_FILLED;
          }

          if (newJordanStars[i].status === StarStatus.EMPTY) {
            newJordanStars[i].status = StarStatus.NEW_EMPTY;
          }

        }
        if (newClientStars[i].status !== this.clientStars[i].status) {
          if (newClientStars[i].status === StarStatus.FILLED) {
            newClientStars[i].status = StarStatus.NEW_FILLED;
          }

          if (newClientStars[i].status === StarStatus.EMPTY) {
            newClientStars[i].status = StarStatus.NEW_EMPTY;
          }

        }
      }
      this.jordanStars = newJordanStars;
      this.clientStars = newClientStars;
    }
  }

  endTask() {
    this.answer.answer = 0;
    this.getNextQuestion();
  }

  ngOnDestroy(): void {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.processParams();
    this.scrollElement = document.getElementById('statement-box');
    for (let i = 0; i < 5; i++) {
      this.jordanStars.push({ status: StarStatus.EMPTY });
      this.clientStars.push({ status: StarStatus.EMPTY });
    }
    this.getNextQuestion();
  }
  updateCurrentPage(page:any){
    this.currentModalPage = page;
  }

}
