
<div class="page-container">
  <div class="content-outer-wrapper">
    <div class="content-inner-wrapper">
      <div class="title-wrapper">
        <h1 class="title">*Dev Dashboard*</h1>
        <h2 class="description margin-top-0">Where would you like to go?</h2>
      </div>
      <div class="button-outer-wrapper">
        <div class="button-inner-wrapper">
          <button class="btn btn-outline option-button" (click)="startNewSignUp()">Onboarding</button> *will reset onboardingComplete flag and lastPageVisited ^*
          <button class="btn btn-outline option-button" [routerLink]="['/account-profile/account']">Profile</button>
          <button class="btn btn-outline option-button" [routerLink]="['/dashboard']">Dashboard</button>
          <button class="btn btn-outline option-button" [routerLink]="['/foundational-assessment/power-skills-intro']">FACETS</button>
          <button class="btn btn-outline option-button" [routerLink]="['/foundational-assessment/power-skills-personality-results']">FACETS Results</button>
          <button class="btn btn-outline option-button" [routerLink]="['/career-interests']">CIP Results</button>
          <button class="btn btn-outline option-button" 
                  (click)="navigateToAssessment('CPS')">
            SNE CPS
          </button>
          <button class="btn btn-outline option-button" [routerLink]="['/sne-new/report/cps']">SNE CPS REPORT</button>
          <button class="btn btn-outline option-button"
                  (click)="navigateToAssessment('CRT')">
            SNE CT
          </button>
          <button class="btn btn-outline option-button" [routerLink]="['/sne-new/report/ct']">SNE CT REPORT</button>
          <button class="btn btn-outline option-button"
                  (click)="navigateToAssessment('CCH')">
            SNE CCH
          </button>
          <button class="btn btn-outline option-button" [routerLink]="['/sne-new/report/coachability']">SNE CCH REPORT</button>
          <button class="btn btn-outline option-button"
                  (click)="navigateToAssessment('ORC')">
            SNE ORC
          </button>
          <button class="btn btn-outline option-button" [routerLink]="['/sne-new/report/oc']">SNE ORC REPORT</button>
          <button class="btn btn-outline option-button"
                  (click)="navigateToAssessment('WRC')">
            SNE WRC
          </button>
          <button class="btn btn-outline option-button" [routerLink]="['/sne-new/report/wc']">SNE WRC REPORT</button>
          <!-- <button class="btn btn-outline option-button" [ngClass]="{'records-deleted': updateFacetsScoresText === 'Scores Updated'}" (click)="updateFacetsScores()">{{updateFacetsScoresText}}</button> -->
          <button class="btn btn-outline option-button" [ngClass]="{'records-deleted': deleteRecordsText === 'Records Deleted'}" (click)="deleteSneRecords()">{{deleteRecordsText}}</button>
          <div *ngIf="deleteRecordsText === 'Records Deleted'">***This page will refresh in 3 seconds***</div>
          <button class="btn btn-outline option-button" (click)="signOut()">Sign Out</button>
        </div>
      </div>
    </div>
  </div>
</div>
