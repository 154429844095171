<div class="container">
  <fa-icon *ngIf="progressCompletePercent===1" [icon]="faCheckCircle" class="check-mark-icon" size="2x"></fa-icon>
  <div *ngIf="!progressCompletePercent || progressCompletePercent < 1">
    <div class="progress-circle-container"
         *ngIf="multiStep && progressCompletePercent && progressCompletePercent < 1"
    >
      <span class="progress-number">{{section}}</span>
      <div class="progress-svg-wrapper">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          xml:space="preserve"
          class="svg"
        >
          <circle
            class="circle-bg"
            cx="15"
            cy="15"
            r="12"
            transform="rotate(270 15 15)"
          />
          <circle
            class="circle"
            cx="15"
            cy="15"
            r="12"
            [ngStyle]="{'stroke-dasharray': (arcLengthPercent * 50) + ' ' + 50}"
            transform="rotate(270 15 15)"
          />
        </svg>
      </div>
    </div>
    <div class="progress-circle-container" *ngIf="onCurrentSection && !multiStep">
      <span class="progress-number">{{section}}</span>
      <div class="progress-svg-wrapper">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          xml:space="preserve"
          class="svg"
        >
        <circle
          class="thin-circle"
          cx="15"
          cy="15"
          r="12"
          transform="rotate(270 15 15)"
        />
      </svg>
      </div>
    </div>
    <div class="progress-circle-container" *ngIf="!onCurrentSection">
      <span class="progress-number">{{section}}</span>
      <div class="progress-svg-wrapper">
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 30 30"
          xml:space="preserve"
          class="svg"
        >
        <circle
          class="thin-circle-bg"
          cx="15"
          cy="15"
          r="12"
          transform="rotate(270 15 15)"
        />
      </svg>
      </div>
    </div>
  </div>
  <div class="progress-bar-wrapper" *ngIf="!lastSection">
    <div class="progress-bar-top" [ngClass]="{'progress-complete': progressCompletePercent===1,
          'progress-incomplete': progressCompletePercent!==1}"></div>
    <div class="progress-bar" [ngClass]="{'progress-complete': progressCompletePercent===1,
          'progress-incomplete': progressCompletePercent!==1}"></div>
    <div class="progress-bar-bottom" [ngClass]="{'progress-complete': progressCompletePercent===1,
          'progress-incomplete': progressCompletePercent!==1}"></div>
  </div>
</div>
