import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { getResolvedUrl } from './guard.helpers';
import { AuthService } from '../services/auth.service';
import { AUTH_SESSION_STORAGE_KEYS } from '../auth/auth.helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedSectionGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  authenticate(route: ActivatedRouteSnapshot): Observable<UrlTree> | boolean {
    const currentPath = getResolvedUrl(route);
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      sessionStorage.setItem(AUTH_SESSION_STORAGE_KEYS.ORIGINAL_ROUTE, currentPath);
      return of(this.router.parseUrl('/auth/sign-in'));
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authenticate(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authenticate(childRoute);
  }

}
