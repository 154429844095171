import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Assessment } from '../types/assessments';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService extends BaseService {

  private assessmentsURL = environment.assessmentAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Assessment';
  }

  getAssessments(): Observable<Assessment[]> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let requestLogObj: LogObject = {
        message: 'ASSESSMENTS: Get Assessments Request',
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<Assessment[]>(this.assessmentsURL, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'ASSESSMENTS: Get Assessments Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

}
