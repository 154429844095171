import { Component, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ets-interstitial',
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.scss']
})
export class InterstitialComponent {
  constructor() { }

  @ViewChild('taskIndex') taskIndex?: ElementRef;
  
  // info
  @Input() title: string | undefined;
  @Input() info: string | undefined;
  @Input() image: string | undefined;
  @Input() questionIndex: number | undefined;
  @Input() moduleSize: number | undefined;
  @Input() duration: string | undefined;
  @Input() durationBoxColor: string | undefined;
  @Input() marquis: boolean | undefined; 

  // action button
  @Input() buttonText: string | undefined;
  @Input() buttonColor: string | undefined;
  @Output() clickEvent = new EventEmitter<any>();
  @Input() showButton = true;

  handleClick() {
    this.clickEvent.emit();
  }
}
