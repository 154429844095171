import { CtInterstitialComponent } from './sne-v2/critical-thinking/ct-interstitial/ct-interstitial.component';
import { OcMultiVideoChoiceVideoSplitscreenComponent } from './sne-v2/oral-communication/oc-multi-video-choice-video-splitscreen/oc-multi-video-choice-video-splitscreen.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuestionRankingComponent } from './cip/components/question-ranking/question-ranking.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { DiversityAndPrivacyComponent } from './account-profile/components/diversity-and-privacy/diversity-and-privacy.component';
import { AccountComponent } from './account-profile/components/account/account.component';
import { CareerInterestsComponent } from './career-interests/career-interests.component';
import { ProfileComponent } from './account-profile/components/profile/profile.component';
import { WorkPreferencesComponent } from './account-profile/components/work-preferences/work-preferences.component';
import { CareerSuggestionsComponent } from './career-suggestions/career-suggestions.component';
import { FacetsComponent } from './foundational-assessment/components/facets/facets.component';
import { FoundationalAssessmentComponent } from './foundational-assessment/foundational-assessment.component';
import { FacetsIntroComponent } from './foundational-assessment/components/facets-intro/facets-intro.component';
import { FacetsPersonalityResultsComponent } from './foundational-assessment/components/facets-personality-results/facets-personality-results.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CipQuestionsComponent } from './cip/cip-questions.component';
import { CareerDetailComponent } from './careers/career-detail/career-detail.component';
import { MultiChoiceFullComponent } from './sne-v2/oral-communication/multi-choice-full/multi-choice-full.component';
import { MultiChoiceListFullComponent } from './sne-v2/oral-communication/multi-choice-list-full/multi-choice-list-full.component';
import { MultiChoiceVideoChoiceFullComponent } from './sne-v2/oral-communication/multi-choice-video-choice-full/multi-choice-video-choice-full.component';
import { MadlibsFullComponent } from './sne-v2/oral-communication/madlibs-full/madlibs-full.component';
import { QuizResultsComponent } from './account-profile/components/quiz-results/quiz-results.component';
import { FacetsInterstitialComponent } from './foundational-assessment/components/facets-interstitial/facets-interstitial.component';
import { CoachabilityMultipleSelectEmailComponent } from './sne-v2/coachability/coachability-multiple-select-email/coachability-multiple-select-email.component';
import { CoachabilityMultiChoiceVideoComponent } from './sne-v2/coachability/coachability-multi-choice-video/coachability-multi-choice-video.component';
import { CoachabilitySurveyComponent } from './sne-v2/coachability/coachability-survey/coachability-survey.component';
import { CoachabilityMultiChoiceConvoLogComponent } from './sne-v2/coachability/coachability-multi-choice-convo-log/coachability-multi-choice-convo-log.component';
import { CoachabilityInterstitialComponent } from './sne-v2/coachability/coachability-interstitial/coachability-interstitial.component';
import { SneV2Component } from './sne-v2/sne-v2.component';
import { CoachabilityComponent } from './sne-v2/coachability/coachability.component';
import { WrittenCommunicationComponent } from './sne-v2/written-communication/written-communication.component';
import { MadlibsComponent } from './sne-v2/components/madlibs/madlibs.component';
import { OcMadlibsVideoSplitscreenComponent } from './sne-v2/oral-communication/oc-madlibs-video-splitscreen/oc-madlibs-video-splitscreen.component';
import { OcInterstitialComponent } from './sne-v2/oral-communication/oc-interstitial/oc-interstitial.component';
import { WcMultiChoiceMemoComponent } from './sne-v2/written-communication/wc-multi-choice-memo/wc-multi-choice-memo.component';
import { WcInterstitialComponent } from './sne-v2/written-communication/wc-interstitial/wc-interstitial.component';
import { OralCommunicationComponent } from './sne-v2/oral-communication/oral-communication.component';
import { MultiChoiceVideoComponent } from './sne-v2/oral-communication/multi-choice-video/multi-choice-video.component';
import { OcMultiChoiceListVideoComponent } from './sne-v2/oral-communication/oc-multi-choice-list-video/oc-multi-choice-list-video.component';
import { WcTextFreeResponseComponent } from './sne-v2/written-communication/wc-text-free-response/wc-text-free-response.component';
import { CipIntroComponent } from './cip/components/cip-intro/cip-intro.component';
import { CriticalThinkingComponent } from './sne-v2/critical-thinking/critical-thinking.component';
import { CtMultiChoiceChatLogComponent } from './sne-v2/critical-thinking/ct-multi-choice-chat-log/ct-multi-choice-chat-log.component';
import { CtMultiChoiceEmailComponent } from './sne-v2/critical-thinking/ct-multi-choice-email/ct-multi-choice-email.component';
import {
  CtMultiChoiceSocialMediaComponent
} from './sne-v2/critical-thinking/ct-multi-choice-social-media/ct-multi-choice-social-media.component';
import { OnBoardingV2Component } from './on-boarding-v2/on-boarding-v2.component';
import { IntroV2Component } from './on-boarding-v2/ob-profile/intro-v2/intro-v2.component';
import { SingleQuestionV2Component } from './on-boarding-v2/ob-profile/single-question-v2/single-question-v2.component';
import { YesNoV2Component } from './on-boarding-v2/ob-profile/yes-no-v2/yes-no-v2.component';
import { CardSelectionV2Component } from './on-boarding-v2/ob-profile/card-selection-v2/card-selection-v2.component';
import { FieldOfStudyComponent } from './on-boarding-v2/ob-profile/field-of-study/field-of-study.component';
import { MultipleChoiceV2Component } from './on-boarding-v2/ob-profile/multiple-choice-v2/multiple-choice-v2.component';
import { InfoBreakComponent } from './on-boarding-v2/ob-profile/info-break/info-break.component';
import {
  CoachabilityMultiChoiceListConvoLogComponent
} from './sne-v2/coachability/coachability-multi-choice-list-convo-log/coachability-multi-choice-list-convo-log.component';
import { SliderComponent } from './on-boarding-v2/ob-profile/slider/slider.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ConfirmationCodeComponent } from './auth/confirmation-code/confirmation-code.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthenticatedSectionGuard } from './guards/authenticated-section.guard';
import { AuthComponent } from './auth/auth.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { OnboardingCompleteGuard } from './guards/onboarding-complete.guard';
import { CpsInterstitialComponent } from './sne-v2/collaborative-problem-solving-v2/cps-interstitial/cps-interstitial.component';
import { CollaborativeProblemSolvingComponentV2 } from './sne-v2/collaborative-problem-solving-v2/collaborative-problem-solving-component-v2.component';

import { WcMultiChoiceEmailComponent } from './sne-v2/written-communication/wc-multi-choice-email/wc-multi-choice-email.component';
import { CpsSurveyComponent } from './sne-v2/collaborative-problem-solving-v2/cps-survey/cps-survey.component';
import {
  MenuFactCardSplitScreenComponent
} from './sne-v2/collaborative-problem-solving-v2/menu-fact-card-split-screen/menu-fact-card-split-screen.component';
import { ClientRankingChatHistorySplitScreenComponent } from './sne-v2/collaborative-problem-solving-v2/client-ranking-chat-history-split-screen/client-ranking-chat-history-split-screen.component';
import {
  SkillSelectionOverviewComponent
} from './on-boarding-v2/ob-skills/skill-selection-overview/skill-selection-overview.component';
import { ExperienceInputComponent } from './on-boarding-v2/ob-skills/experience-input/experience-input.component';
import { ResumeOptionComponent } from './on-boarding-v2/ob-profile/resume-option/resume-option.component';
import { LoadingPageComponent } from './on-boarding-v2/ob-skills/loading-page/loading-page.component';
import { ResumeAttachmentComponent } from './on-boarding-v2/ob-profile/resume-attachment/resume-attachment.component';
import { TopSkillsComponent } from './on-boarding-v2/ob-skills/top-skills/top-skills.component';
import { WorkHistoryStartComponent } from './on-boarding-v2/work-history/work-history-start.component';
import { TransferableAndJobSpecificSkillsComponent } from './on-boarding-v2/ob-skills/transferable-and-job-specific-skills/transferable-and-job-specific-skills.component';
import { OcSneIntroComponent } from './sne-v2/oral-communication/oc-sne-intro/oc-sne-intro.component';
import { WcSneIntroComponent } from './sne-v2/written-communication/wc-sne-intro/wc-sne-intro.component';
import { CtSneIntroComponent } from './sne-v2/critical-thinking/ct-sne-intro/ct-sne-intro.component';
import { OcVideosAndMultiChoiceComponent } from './sne-v2/oral-communication/oc-videos-and-multi-choice/oc-videos-and-multi-choice.component';
import { SneReportComponent } from './sne-v2/sne-report/sne-report.component';
import { SneCoachabilityCompleteGuard } from './guards/sne-coachability-complete.guard';
import { CtMultiChoiceScheduleMapComponent } from './sne-v2/critical-thinking/ct-multi-choice-schedule-map/ct-multi-choice-schedule-map.component';
import { SneWrittenCommunicationCompleteGuard } from './guards/sne-written-communication-complete.guard';
import { SneOralCommunicationCompleteGuard } from './guards/sne-oral-communication-complete.guard';
import { SneCriticalCompleteGuard } from './guards/sne-critical-complete.guard';
import { SneCpsCompleteGuard } from './guards/sne-cps-complete.guard';
import { CpsSneIntroComponent } from './sne-v2/collaborative-problem-solving-v2/cps-sne-intro/cps-sne-intro.component';
import { CchSneIntroComponent } from './sne-v2/coachability/cch-sne-intro/cch-sne-intro.component';
import { DashboardV2Component } from './dashboard-v2/dashboard-v2.component';
import { CtProductionFlowMapComponent } from './sne-v2/critical-thinking/ct-production-flow-map/ct-production-flow-map.component';
import { HiddenProfileComponent } from './sne-v2/collaborative-problem-solving-v2/hidden-profile/hidden-profile.component';
import { CoachabilitySplitScreenMultiChoiceVideoComponent } from './sne-v2/coachability/coachability-split-screen-multi-choice-video/coachability-split-screen-multi-choice-video.component';
import { CoachabilityMultiChoiceSingleVideoComponent } from './sne-v2/coachability/coachability-multi-choice-single-video/coachability-multi-choice-single-video.component';
import { AuthPageGuard } from './guards/auth-page.guard';

const routes: Routes = [
  {
    path: '', redirectTo: '/auth/sign-in', pathMatch: 'full'
  },
  {
    path: 'auth', component: AuthComponent, canActivate: [AuthPageGuard],
    children: [
      { path: 'sign-in', component: SignInComponent },
      { path: 'sign-up', component: SignUpComponent },
      { path: 'confirmation-code', component: ConfirmationCodeComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ]
  },
  { path: 'career-suggestions', component: CareerSuggestionsComponent, canActivate: [AuthenticatedSectionGuard] },
  { path: 'career-interests', component: CareerInterestsComponent, canActivate: [AuthenticatedSectionGuard, OnboardingCompleteGuard] },
  { path: 'on-boarding', component: OnBoardingV2Component, canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard],
    children: [
      { path: '', redirectTo: 'start/0', pathMatch: 'full' },
      { path: 'start/:questionNum', component: IntroV2Component },
      { path: 'single-question/:questionNum', component: SingleQuestionV2Component },
      { path: 'yes-no/:questionNum', component: YesNoV2Component },
      { path: 'cards/:questionNum', component: CardSelectionV2Component },
      { path: 'multiple-choice/:questionNum', component: MultipleChoiceV2Component },
      { path: 'break/:questionNum', component: InfoBreakComponent },
      { path: 'slider/:questionNum', component: SliderComponent},
      { path: 'field-of-study/:questionNum', component: FieldOfStudyComponent },
      { path: 'experience-input/:questionNum', component: ExperienceInputComponent },
      { path: 'skill-selection-overview/:questionNum', component: SkillSelectionOverviewComponent},
      { path: 'top-skills/:questionNum', component: TopSkillsComponent },
      { path: 'work-history/:questionNum', component: WorkHistoryStartComponent },
      { path: 'resume-option/:questionNum', component: ResumeOptionComponent},
      { path: 'resume-attach/:questionNum', component: ResumeAttachmentComponent},
      { path: 'loading-skills-page/:questionNum', component: LoadingPageComponent},
      { path: 'transferable-and-job-specific-skills/:questionNum', component: TransferableAndJobSpecificSkillsComponent},
    ]
  },

  { path: 'cip-questions', component: CipQuestionsComponent, canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard],
    children: [
      { path: '', redirectTo: 'table/0', pathMatch: 'full' },
      { path: 'table/:questionNum', component: QuestionRankingComponent },
      { path: 'intro/:questionNum', component: CipIntroComponent },
    ]
  },
  { path: 'foundational-assessment', component: FoundationalAssessmentComponent,
    canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard],
    children: [
      { path: 'power-skills-intro', component: FacetsIntroComponent },
      { path: 'power-skills-interstitial', component: FacetsInterstitialComponent },
      { path: 'power-skills-assessment', component: FacetsComponent },
      { path: 'power-skills-personality-results', component: FacetsPersonalityResultsComponent },
    ]
  },
  { path: 'account-profile', component: AccountProfileComponent, canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard],
    children: [
      { path: '', redirectTo: 'account', pathMatch: 'full' },
      { path: 'account', component: AccountComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'diversity-privacy', component: DiversityAndPrivacyComponent },
      { path: 'work-preferences', component: WorkPreferencesComponent },
      { path: 'quiz-results', component: QuizResultsComponent }
    ]
  },
  { path: 'dev-dashboard', component: LandingPageComponent, canActivate: [AuthenticatedSectionGuard, OnboardingCompleteGuard] }, // Dev Dashboard
  { path: 'career-detail/:basic/:careerID', component: CareerDetailComponent, canActivate: [AuthenticatedSectionGuard, OnboardingCompleteGuard] },
  { path: 'career-detail/:careerID', component: CareerDetailComponent, canActivate: [AuthenticatedSectionGuard, OnboardingCompleteGuard] },
  { path: 'dashboard', component: DashboardV2Component, canActivate: [AuthenticatedSectionGuard, OnboardingCompleteGuard] },
  {
    path: 'sne-new', component: SneV2Component,
    children: [
      {
        path: 'cps', component: CollaborativeProblemSolvingComponentV2,
        canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard, SneCpsCompleteGuard],
        children: [
          {path: 'sne-intro/:questionNum/:section', component: CpsSneIntroComponent},
          {path: 'interstitial/:questionNum/:section', component: CpsInterstitialComponent},
          {path: 'multi-multiple-choice/:questionNum/:section', component: CpsSurveyComponent},
          {path: 'multi-choice-dropdown/:questionNum/:section', component: CpsSurveyComponent},
          {path: 'menu-fact/:questionNum/:section', component: MenuFactCardSplitScreenComponent},
          {path: 'client-ranking-chat-history/:questionNum/:section', component: ClientRankingChatHistorySplitScreenComponent},
          {path: 'hidden-profile/:questionNum/:section', component: HiddenProfileComponent}
        ],
      },
      {
        path: 'coachability', component: CoachabilityComponent,
        canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard, SneCoachabilityCompleteGuard],
        children: [
          {path: 'sne-intro/:questionNum/:section', component: CchSneIntroComponent},
          {path: 'multi-select-email/:questionNum/:section', component: CoachabilityMultipleSelectEmailComponent},
          {path: 'video-list/:questionNum/:section', component: CoachabilityMultiChoiceVideoComponent},
          {path: 'interstitial/:questionNum/:section', component: CoachabilityInterstitialComponent},
          {path: 'multi-choice-convo/:questionNum/:section', component: CoachabilityMultiChoiceConvoLogComponent},
          {path: 'survey/:questionNum/:section', component: CoachabilitySurveyComponent},
          {path: 'multi-choice-list-convo/:questionNum/:section', component: CoachabilityMultiChoiceListConvoLogComponent},
          {path: 'multi-choice-list-video/:questionNum/:section', component: CoachabilitySplitScreenMultiChoiceVideoComponent},
          {path: 'multi-choice-video/:questionNum/:section', component: CoachabilityMultiChoiceSingleVideoComponent},
        ]
      },
      {
        path: 'wc', component: WrittenCommunicationComponent,
        canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard, SneWrittenCommunicationCompleteGuard],
        children: [
          { path: 'sne-intro/:questionNum/:section', component: WcSneIntroComponent},
          {path: 'multi-choice-memo/:questionNum/:section', component: WcMultiChoiceMemoComponent},
          {path: 'multi-choice-email/:questionNum/:section', component: WcMultiChoiceEmailComponent},
          {path: 'interstitial/:questionNum/:section', component: WcInterstitialComponent},
          {path: 'text-free-response/:questionNum/:section', component: WcTextFreeResponseComponent},
        ]
      },
      {
        path: 'oc', component: OralCommunicationComponent,
        canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard, SneOralCommunicationCompleteGuard],
        children: [
          { path: 'sne-intro/:questionNum/:section', component: OcSneIntroComponent},
          { path: 'interstitial/:questionNum/:section', component: OcInterstitialComponent },
          { path: 'multiple-choice-full/:questionNum/:section', component: MultiChoiceFullComponent},
          { path: 'multiple-choice-video-choice-full/:questionNum/:section', component: MultiChoiceVideoChoiceFullComponent},
          { path: 'multiple-choice-video/:questionNum/:section', component: MultiChoiceVideoComponent },
          { path: 'multiple-choice-list-video/:questionNum/:section', component: OcMultiChoiceListVideoComponent },
          { path: 'madlibs', component: MadlibsComponent },
          { path: 'madlibs-video-splitscreen/:questionNum/:section', component: OcMadlibsVideoSplitscreenComponent },
          { path: 'multi-video-choice-video-splitscreen/:questionNum/:section', component: OcMultiVideoChoiceVideoSplitscreenComponent },
          { path: 'multi-choice-list-full/:questionNum/:section', component: MultiChoiceListFullComponent},
          { path: 'madlibs-full/:questionNum/:section', component: MadlibsFullComponent},
          { path: 'videos-and-multi-choice/:questionNum/:section', component: OcVideosAndMultiChoiceComponent},
        ]
      },
      {
        path: 'ct', component: CriticalThinkingComponent,
        canActivateChild: [AuthenticatedSectionGuard, OnboardingCompleteGuard, SneCriticalCompleteGuard],
        children: [
          { path: 'sne-intro/:questionNum/:section', component: CtSneIntroComponent},
          { path: 'interstitial/:questionNum/:section', component: CtInterstitialComponent },
          { path: 'multiple-choice-convo/:questionNum/:section', component: CtMultiChoiceChatLogComponent},
          { path: 'multiple-choice-email/:questionNum/:section', component: CtMultiChoiceEmailComponent},
          { path: 'multiple-choice-social-media/:questionNum/:section', component: CtMultiChoiceSocialMediaComponent},
          { path: 'multiple-choice-schedule/:questionNum/:section', component: CtMultiChoiceScheduleMapComponent},
          { path: 'multiple-choice-production-flow-map/:questionNum/:section', component: CtProductionFlowMapComponent},
        ]
      },
      {
        path: 'report/:module-name', component: SneReportComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  //imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
