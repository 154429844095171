

import { Component, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { APP_COLORS } from 'src/global-styles';


  @Component({
    selector: 'ets-footer-v2-component',
    templateUrl: './footer-v2.component.html',
    styleUrls: ['./footer-v2.component.scss']
  })

export class FooterV2Component implements OnInit {

  @Input() sectionIndex: number | undefined;
  @Input() sectionSize: number | undefined;

  @Input() showQuestionNumbers: boolean = false;
  @Input() progressBgColor: string = '#0E3AD6';

  sectionList: number [] | undefined;
  progressSectionWidth: string | undefined;
  currentWindowWidth: number = 0;
  APP_COLORS= APP_COLORS;


  constructor() { }

  setProgressBarWidth() {
    this.progressSectionWidth =
      (1/this.sectionSize!) * window.innerWidth - 4 // find the length of a section - the width of the white divider
      + (4/this.sectionSize!) + 'px'; //  make the sections longer by a fraction since we dont show the last white bar
  }

  ngOnInit() {
    this.sectionList = Array.from(Array(this.sectionSize! +1 ).keys());
    this.sectionList = this.sectionList.splice(1);
    this.setProgressBarWidth();
    console.log(this.progressSectionWidth);
    fromEvent(window, 'resize')
      .pipe(map(() => window.innerWidth), distinctUntilChanged())
      .subscribe(() => this.setProgressBarWidth());
  }

}

