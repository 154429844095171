import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadQuestions,
  loadQuestionsFailure,
  loadQuestionsSuccess,
} from './cip.actions';
import { CipService } from '../services/cip.service';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable()
export class CipEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private cipService: CipService
  ) {}

  // Run this code when a loadQuestions action is dispatched
  loadQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQuestions),
      switchMap(() =>
        // Call the getQuestions method, convert it to an observable
        from(this.cipService.getQuestions()).pipe(
          // Take the returned value and return a new success action containing the questions
          map((questions) => loadQuestionsSuccess({ questions: questions })),
          // Or... if it errors return a new failure action containing the error
          catchError((error) => of(loadQuestionsFailure({ error })))
        )
      )
    )
  );
}