<ets-header [isBGWhite]="true"
            [headerState]="headerState"
            [showBackButton]="false"
            [simpleBack]="true">
</ets-header>
<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
  [sneSection]="'left-oc'"
>
  <div class="left">
    <ets-sne-ques-video 
    [videoId]="questionData?.headerVideo?.id"
    [title]="questionData?.headerVideo?.title"
    [mainHeader]="questionData?.headerVideo?.mainHeader"
    [description]="questionData?.headerVideo?.description"
    [videoUrl]="questionData?.headerVideo?.url"
    [playOnce]="questionData?.headerVideo?.playOnce"
    [sharedVideo]="questionData?.sharedVideo"
  >
  </ets-sne-ques-video>
  </div>
  <div class="right">
    <ets-madlibs [headerQuestion]="questionData?.header"
    [madlibsBlanks]="questionData?.madlibsBlanks"
    (selectBlankEvent)="handleUserResponse($event)"
  >
  </ets-madlibs>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="responseLength !== questionData?.numSelectOptions"
>
  <ets-madlibs [headerQuestion]="questionData?.header"
      [madlibsBlanks]="questionData?.madlibsBlanks"
      (selectBlankEvent)="handleUserResponse($event)"
      [mobileCheck]="true"
  >
  </ets-madlibs>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
<div *ngIf="instructionModal?.customContent" contentID=customContent>
  <ets-sne-email-layout *ngIf="instructionModal?.customContent[0].to"
                        [emails]="instructionModal?.customContent"
  ></ets-sne-email-layout>
  <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user" [chatLog]="instructionModal?.customContent" [showHeader]="false"></ets-sne-conversation-log-text>
</div>
</ets-modal>
<ets-modal
  [title]="questionData?.hintModal?.title"
  [showModal]="showHintModal"
  [list]="questionData?.hintModal?.pageList"
  (modalClose)="hintToggle()"
  [hint]="true"
>
</ets-modal>
