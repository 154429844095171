import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { FacetsService } from 'src/app/services/facets.service';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';
import FacetsQuestion, { FacetsFinalQuestionResponse, FacetsRequestBody } from 'src/app/types/facets';
import { SneAnswerRequest, SNE_TASK_ENUM } from 'src/app/types/sne';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'ets-facets',
  templateUrl: './facets.component.html',
  styleUrls: ['./facets.component.scss']
})
export class FacetsComponent implements OnInit, OnDestroy {

  constructor(private facetsService: FacetsService, private sneAnswerService: SneAnswerService, private router: Router, private eventService: EventService) { }

  question: FacetsQuestion | undefined;
  selectedStatement: string = '';
  percentage: string = '';
  showExitModal: boolean = false;
  scoringErrorFlag: boolean = false;
  finalQuestionFlag: boolean = false;
  nextQuestionDisabledFlag: boolean = true;
  sub!: Subscription;
  sub2!: Subscription;
  sub3!: Subscription;
  sub4!: Subscription;
  headerState: HEADER_STATES = HEADER_STATES.FACETS;
  mobileProgressBarColor: string = '#ffffff';
  cardInfo1: any | undefined;
  cardInfo2: any | undefined;

  ngOnInit() {
    document.title = 'Apprize | Power Skills';
    this.initiateOrResumeFacetsQuiz();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.sub2) {
      this.sub2.unsubscribe();
    }

    if (this.sub3) {
      this.sub3.unsubscribe();
    }

    if (this.sub4) {
      this.sub4.unsubscribe();
    }

  }
  /**
   * setSelectedStatement - sets the selected statement with the top score
   * @param {string} statementValue
   */
  setSelectedStatement(statementValue: string) {
    this.selectedStatement = statementValue;
    // Enable the next question button
    // this.nextQuestionDisabledFlag = false;
  }
  /**
   * setSentimentScores - clickHandler for sentimentCard scores
   * @param {number} cardChoice - 1st card or 2nd card 
   * @param {number} sentimentScore - score based on least likely (1) to most likely (4)
   * @param {event} $event - The click event DOM object containing DOM classes and nodes. We will uses this object for interactivity.
   */
  setCardValues(cardChoice: number, sentimentScore: number, event: any): void { 
    let score = sentimentScore;
    // The Target family is the group of buttons within the current card clicked
    const eventTargetFamily = event.currentTarget.parentElement.childNodes;
    // When the sentiment score clicked matches the score already set then this should return true for card1 or card2
    let enabledByFirstCard = (sentimentScore === this.cardInfo1.score);
    let enabledBySecondCard = (sentimentScore === this.cardInfo2.score);
    // Flag for checking if both scores are set to initial state
    let scoresHaveZeroState = (this.cardInfo1.score === 0 && this.cardInfo2.score === 0);
    // Flag for when bothScores match on each card
    let bothScoresMatch = (this.cardInfo1.score === this.cardInfo2.score);
    // Handling event for 1st card
    if (cardChoice === 1 && ((scoresHaveZeroState || bothScoresMatch) || !enabledBySecondCard)) {
      if (score === this.cardInfo1.score) {
        // Invert
        this.selectionInvert(cardChoice, sentimentScore, eventTargetFamily);
        // First we set back to 0 
        score = 0;
        this.cardInfo1 = {...this.cardInfo1, score} as any;
        // Then we invert the sentiment bar. 
      } else {
        this.cardInfo1 = {...this.cardInfo1, score} as any;
        this.selectionTransform(cardChoice, sentimentScore, eventTargetFamily);
        this.cardInfo2 = {...this.cardInfo2} as any;
      }
    }
    // Handling event for 2nd card
    if (cardChoice === 2 && ((scoresHaveZeroState || bothScoresMatch) || !enabledByFirstCard)) {
      if (score === this.cardInfo2.score) {
        this.selectionInvert(cardChoice, sentimentScore, eventTargetFamily);
        // First we set back to 0 
        score = 0;
        this.cardInfo2 = {...this.cardInfo2, score} as any;
        // Then we invert the sentiment bar. 
      } else {
        this.cardInfo2 = {...this.cardInfo2, score} as any;
        this.selectionTransform(cardChoice, sentimentScore, eventTargetFamily);
        this.cardInfo1 = {...this.cardInfo1} as any;
      }
    }
    this.setHighScoreValues();
    this.enableNextQuestion();
  }
  /**
   * selectionTransform - transforms the status of sentiment buttons based on selected, disabled, or active  
   * @param {number} cardChoice - 1st card or 2nd card
   * @param {number} sentimentScore - score based on least likely (1) to most likely (4)
   * @param {Event} buttonGroupEvent - event that triggered from button push
   */
  selectionTransform(cardChoice: number, sentimentScore: number, buttonGroupEvent: any) {
    // Sentiment Index for iterators starting at 0 
    let sentimentIndex = sentimentScore - 1;
    let buttonGroupNodes = buttonGroupEvent;
    // Sets a siblingName string that can be used to update DOM classes and effects on opposite card
    let siblingName = 'group-' + ((cardChoice === 1) ? '2' : '1');
    // Uses the siblingName to get the current classes (statuses) of the sibling buttonGroup
    let cardSiblingGroup = document.getElementsByClassName('btn-group ' + siblingName);
    // The first index's children will always contain the classList
    let siblingChildren = cardSiblingGroup[0].children;

    // Determine if the sibling card has an option selected yet
    let siblingCardSelected = false;
    for (let i = 0; i < siblingChildren.length; i++) {
      if (siblingChildren[i].classList.contains('selected')) {
        siblingCardSelected = true;
        break;
      }
    }

    // Iterate through the current buttonGroup clicked
    for (let i = 0; i < buttonGroupNodes.length; i++) {
      // Checking each button compared to it's sibling and looking for classes "selected" 
      // and checking if sibling is already enabled or disabled
      // based on these will add or remove classes if sibling is selected or not. 
      let buttonNode = buttonGroupNodes[i].classList;
      let siblingNode = siblingChildren[i].classList;
      let siblingNodeSelected = siblingNode.contains('selected');
      if (i === sentimentIndex && siblingNodeSelected !== true) {
        buttonNode.add('selected');
        siblingNode.add('disabled');
        siblingNode.remove('active');
        siblingNode.remove('selected');
      } else {
        buttonNode.remove('active');
        buttonNode.remove('selected');
        buttonNode.add('disabled');
        // Reenable sibling nodes only if the sibling card doesn't have a selected button yet
        if (!siblingCardSelected && !siblingNodeSelected) {
          siblingNode.remove('disabled');
          siblingNode.add('active');
        }
      }
    }
  }
  /**
   * selectionInvert - function that triggers on sentiment bar when the same button is clicked twice. We invert it.
   * @param {number} cardChoice - 1st card or 2nd card
   * @param {number} sentimentScore - sentiment score as a number
   * @param {Event} buttonGroupEvent - event that triggered from button push. Contains all HTML DOM nodes. 
   */
  selectionInvert(cardChoice: number, sentimentScore: number, buttonGroupEvent: any) {
    let sentimentIndex = sentimentScore - 1;
    let buttonGroupNodes = buttonGroupEvent;
    let siblingName = 'group-' + ((cardChoice === 1) ? '2' : '1');
    let cardSiblingGroup = document.getElementsByClassName('btn-group ' + siblingName);
    let siblingChildren = cardSiblingGroup[0].children;

    // Determine if the sibling card has an option selected yet
    let siblingCardSelected = false;
    for (let i = 0; i < siblingChildren.length; i++) {
      if (siblingChildren[i].classList.contains('selected')) {
        siblingCardSelected = true;
        break;
      }
    }

    for (let i = 0; i < buttonGroupNodes.length; i++) {
      let buttonNode = buttonGroupNodes[i].classList;
      let siblingNode = siblingChildren[i].classList;
      let siblingNodeSelected = siblingNode.contains('selected');
      let currentNodeDisabled = buttonNode.contains('disabled');
      if (i === sentimentIndex) {
        buttonNode.remove('selected');
        buttonNode.add('active');
        // Reenable sibling nodes only if the sibling card doesn't have a selected button yet
        if (!siblingCardSelected) {
          siblingNode.remove('disabled');
          siblingNode.add('active');
        }
      } 
      if (i !== sentimentIndex && !siblingNodeSelected && currentNodeDisabled) {
        buttonNode.remove('disabled');
        buttonNode.add('active');
      } 
    }
  }
  /**
   * enableNextQuestion - function that enables the next button when scores are selected.
   */
  enableNextQuestion() {
    if (this.cardInfo1.score > 0 && this.cardInfo2.score > 0) {
      this.nextQuestionDisabledFlag = false;
    } if (this.cardInfo1.score === 0 || this.cardInfo2.score === 0) {
      this.nextQuestionDisabledFlag = true;
    }
  }
  /**
   * setHighScoreValues - sets selectedStatement to the top scoring card to send to payload
   */
  setHighScoreValues() {
    let noScoresZero = (this.cardInfo1.score !== 0 && this.cardInfo2.score !== 0);
    if((this.cardInfo1.score > this.cardInfo2.score) && noScoresZero) {
      this.setSelectedStatement(this.cardInfo1.value);
    }
    if((this.cardInfo2.score > this.cardInfo1.score) && noScoresZero) {
      this.setSelectedStatement(this.cardInfo2.value);
    }
  }
  toggleExitModal() {
    this.showExitModal = !this.showExitModal;
  }

  initiateOrResumeFacetsQuiz() {

    let sessionID = localStorage.getItem('facetsSessionId');
    if (sessionID) {
      this.sub = this.facetsService.resumeTestSession(sessionID).subscribe({
        next: question => {
          this.question = question;
          this.cardInfo1 = {...question.statements[0], score: 0} as any;
          this.cardInfo2 = {...question.statements[1], score: 0} as any;
          let progress = (Number.parseInt(this.question.stepNum) / Number.parseInt(this.question.testLength)) * 100;
          this.percentage = progress.toString() + '%';
          this.finalQuestionFlag = (Number.parseInt(this.question.testLength) === Number.parseInt(this.question.stepNum)) ? true : false;

          if (environment.analyticsTrackingEnabled) {
            // Replace state for Google Analytics
            let stateObj = {
              title: 'Power Skills Assessment',
              pathname: window.location.pathname + '?questionNum=' + this.question.stepNum,
              questionNum: this.question.stepNum
            };
            history.replaceState(stateObj, 'Power Skills Assessment', window.location.pathname + '?questionNum=' + this.question.stepNum);
          }
          // Track the page view in AWS Athena
          this.eventService.buildEvent('Power Skills Assessment', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);
        },
        error: err => {
          // TODO - error handling
        }
      });
    } else {
      this.sub = this.facetsService.initiateTestSession().subscribe({
        next: question => {
          this.question = question;
          this.cardInfo1 = {...question.statements[0], 'score': 0} as any;
          this.cardInfo2 = {...question.statements[1], 'score': 0} as any;
          let progress = (Number.parseInt(this.question.stepNum) / Number.parseInt(this.question.testLength)) * 100;
          this.percentage = progress.toString() + '%';
          // store the session ID so if a user refreshes the page the quiz will resume where they were
          localStorage.setItem('facetsSessionId', this.question.sessionID);

          if (environment.analyticsTrackingEnabled) {
            // Replace state for Google Analytics
            let stateObj = {
              title: 'Power Skills Assessment',
              pathname: window.location.pathname + '?questionNum=' + this.question.stepNum,
              questionNum: this.question.stepNum
            };
            history.replaceState(stateObj, 'Power Skills Assessment', window.location.pathname + '?questionNum=' + this.question.stepNum);
          }
          // Track the page view in AWS Athena
          this.eventService.buildEvent('Power Skills Assessment', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);
        },
        error: err => {
          // TODO - error handling
        }
      });
    }
  }

  saveAndGetNextQuestion() {
    if (this.question) {
      let nextQuestionRequest: FacetsRequestBody = {
        sessionID: this.question.sessionID,
        action: 'next',
        stepNumber: this.question.stepNum,
        statementChoiceID: this.selectedStatement
      };

      let answerRecordRequest : SneAnswerRequest = {
        task: SNE_TASK_ENUM.FACETS,
        data: {
          stepNumber: this.question.stepNum,
          answers: [
            {
              statementChoiceID: this.selectedStatement,
              sentimentScore: this.cardInfo1.score
            },
            {
              statementChoiceID: this.selectedStatement,
              sentimentScore: this.cardInfo2.score
            }
          ]
        },
      };

      this.nextQuestionDisabledFlag = true;
      if (this.question.stepNum !== this.question.testLength) {
        this.sub3 = this.sneAnswerService.postAction(answerRecordRequest as SneAnswerRequest).subscribe({
          next: question => {
            console.log('next!');
            console.log(question);
          },
          error: err => {
            console.error('Error');
            console.error(err);
          }
        });
        this.sub2 = this.facetsService.saveAndGetNextQuestion(nextQuestionRequest).subscribe({
          next: question => {
            this.question = question;
            this.cardInfo1 = {...question.statements[0], score: 0} as any;
            this.cardInfo2 = {...question.statements[1], score: 0} as any;  
            let progress = (Number.parseInt(this.question.stepNum) / Number.parseInt(this.question.testLength)) * 100;
            this.percentage = progress.toString() + '%';
            this.finalQuestionFlag = (Number.parseInt(this.question.testLength) === Number.parseInt(this.question.stepNum)) ? true : false;
            // Disable the next question button for the new question
            this.nextQuestionDisabledFlag = true;

            if (environment.analyticsTrackingEnabled) {
              // Replace state for Google Analytics
              let stateObj = {
                title: 'Power Skills Assessment',
                pathname: window.location.pathname + '?questionNum=' + this.question.stepNum,
                questionNum: this.question.stepNum
              };
              history.replaceState(stateObj, 'Power Skills Assessment', window.location.pathname + '?questionNum=' + this.question.stepNum);
            }
            // Track the page view in AWS Athena
            this.eventService.buildEvent('Power Skills Assessment', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);
          },
          error: err => {
            // TODO - error handling for when the next question can not be retrieved
            this.nextQuestionDisabledFlag = false;
          }
        });
      } else {
        this.saveFinalQuestion(nextQuestionRequest);
      }

    }
  }

  saveFinalQuestion(nextQuestionRequest: FacetsRequestBody) {
    this.sub2 = this.facetsService.saveFinalQuestion(nextQuestionRequest).subscribe({
      next: finalQuestionResponse => {
        let response: FacetsFinalQuestionResponse = finalQuestionResponse;
        if (response.scorable) {
          // Save session ID to storage and route to the results page
          if (nextQuestionRequest.sessionID) {
            localStorage.setItem('facetsSessionId', nextQuestionRequest.sessionID);
          }

          this.router.navigateByUrl('/foundational-assessment/power-skills-personality-results');
        } else {
          // Error handling for when the test comes back as scorable = false
          this.scoringErrorFlag = true;
        }
      },
      error: err => {
        // TODO - error handling
        this.nextQuestionDisabledFlag = false;
      }
    });
  }

  handleScoreError() {
    this.scoringErrorFlag = false;
    // Track the error in AWS Athena
    this.eventService.buildEvent('Power Skills Scoring Error', EventActionEnum.ERROR, EventSectionEnum.POWER_SKILLS);
    this.router.navigate(['/dashboard']);
  }

  saveAndExitQuiz() {
    this.router.navigate(['/dashboard']);
  }
}
