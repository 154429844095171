import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  constructor() { }

  @Input() fullWidth: boolean = false;

  redirectToSurvey() {
    window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=t-mmC7Ngrk-S835t3Z6bZR8kFpBZ06VMucrx-q4UI09UQU1QVVBJTEVHNUFIVFMwUUkwVUs0R0I0QS4u');
  }

}
