<ets-header [isBGWhite]="false" [headerState]="headerState" [showBackButton]="false"
            [simpleBack]="true">
</ets-header>
<!--
  <div class="left">
    <ets-sne-instruction
    [title]="questionData?.title"
    [subtitle]="questionData?.subtitle"
    [subtitle2]="questionData?.subtitle2"
    ></ets-sne-instruction>
  </div>
  -->
  <div class="container">
    <div class="content-wrapper">
      <ets-sne-multi-choice-list
    [headerQuestion]="questionData?.header"
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
    [inputType]="questionData?.inputType"
    [preventRepeatAnswers]="true"
    [currentAnswers]="multiResponse"
    >
    </ets-sne-multi-choice-list>
    </div>
  </div>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  [drawerActive]="false"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]= "multiResponse.length !== questionData?.numSelectOptions"
>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
<div *ngIf="instructionModal?.customContent" contentID=customContent>
  <ets-sne-email-layout 
    *ngIf="instructionModal?.customContent[0].to"
    [emails]="instructionModal?.customContent"
    [colorArray]="['blue']"
    [firstColorIndex]="0"
  ></ets-sne-email-layout>
  <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user" [chatLog]="instructionModal?.customContent" [showHeader]="false"></ets-sne-conversation-log-text>
</div>
</ets-modal>
<ets-modal
  [title]="questionData?.hintModal?.title"
  [showModal]="showHintModal"
  [list]="questionData?.hintModal?.pageList"
  (modalClose)="hintToggle()"
  [hint]="true"
>
</ets-modal>
