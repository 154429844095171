import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SplitScreenIntroComponent } from './split-screen-intro/split-screen-intro.component';
import {
  RoundedContainedButtonComponent
} from './rounded-contained-button/rounded-contained-button.component';
import { SolidButtonComponent } from './solid-button/solid-button.component';
import { OutlineButtonComponent } from './outline-button/outline-button.component';
import { BeginFacetsButtonComponent } from './begin-facets-button/begin-facets-button.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { ClarityModule } from '@clr/angular';
import { InterstitialComponent } from './interstitial/interstitial.component';
import { CardComponent } from './card/card.component';
import { WhyWeAskModalComponent } from './why-we-ask-modal/why-we-ask-modal.component';
import { SkillsButtonComponent } from './skills-button/skills-button.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FeedbackComponent,
    SplitScreenIntroComponent,
    RoundedContainedButtonComponent,
    SolidButtonComponent,
    OutlineButtonComponent,
    BeginFacetsButtonComponent,
    RadioButtonComponent,
    CheckboxComponent,
    ModalComponent,
    InterstitialComponent,
    CardComponent,
    WhyWeAskModalComponent,
    SkillsButtonComponent,
    SpinnerComponent,
    TooltipComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ClarityModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SplitScreenIntroComponent,
    RoundedContainedButtonComponent,
    SolidButtonComponent,
    OutlineButtonComponent,
    FeedbackComponent,
    BeginFacetsButtonComponent,
    RadioButtonComponent,
    CheckboxComponent,
    ModalComponent,
    InterstitialComponent,
    CardComponent,
    WhyWeAskModalComponent,
    SkillsButtonComponent,
    SpinnerComponent,
    TooltipComponent,
  ]
})
export class SharedModule { }
