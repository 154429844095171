import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProfileService } from 'src/app/services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import Profile from 'src/app/types/profile';
import { FacetsService } from '../services/facets.service';
import { SneAnswerService } from '../services/sne-answer.service';
import {
 findNextAssessmentPath
} from '../sne-v2/sne-v2.helpers';
import { FacetsRequestBody } from '../types/facets';

@Component({
  selector: 'ets-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
// THIS IS A DEV LANDING PAGE TO ROUTE THROUGH THE APP, NOT MEANT FOR EXTERNAL USE
export class LandingPageComponent implements OnInit {

  constructor(private store: Store, private router: Router,
              private profileService: ProfileService, private sneAnswerService: SneAnswerService,
              private facetsService: FacetsService) { }

  errorMessage: string = '';
  userProfile!: Profile;
  assessments: any[] = [];
  deleteRecordsText = "Delete SNE Records";
  updateFacetsScoresText = "Update Facets Scores";

  ngOnInit() {
    this.profileService.getProfile().subscribe({
      next: profile => {
        this.userProfile = profile;
        this.store.dispatch(setProfile({payload: profile}));
      },
      error: err => this.errorMessage = err
    });
    this.sneAnswerService.getSneAssessmentRecords().subscribe(assessments => {
      this.assessments = assessments;
    });

  }

  startNewSignUp() {
    if (this.userProfile) {
      let profileCopy = {...this.userProfile};
      profileCopy.lastPageVisited = null;
      profileCopy.onboardingComplete = false;
      this.profileService.saveProfile(profileCopy).subscribe({
        next: profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.router.navigate(['/on-boarding']);
        },
        error: err => console.log('Error saving the profile', err)
      });
    }
  }

  deleteSneRecords() {
    this.sneAnswerService.deleteSneAssessmentRecords().subscribe({
      next: () => {
        this.deleteRecordsSuccessful();
      }
    });
  }

  // Method to change the text on the delete records button
  deleteRecordsSuccessful() {
    this.deleteRecordsText = "Records Deleted";
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  updateFacetsScores() {
    let requestBody: FacetsRequestBody = {
      action: "updateScores",
    };
    this.updateFacetsScoresText = "Updating...";
    this.facetsService.updateScoreResults(requestBody).subscribe({
      next: () => {
        this.updateFacetsScoresSuccessful();
      },
      error: () => {
        this.updateFacetsScoresText = "Error Updating";
      }
    });
  }

  // Method to change the text on the update facets score button
  updateFacetsScoresSuccessful() {
    this.updateFacetsScoresText = "Scores Updated";
  }

  signOut() {
    localStorage.clear();
    window.location.reload();
  }

  navigateToAssessment(assessmentType: string): void {
    const path = findNextAssessmentPath(this.assessments, assessmentType);
    if (path) {
      this.router.navigate([path]);
    }
  }

}
