<div class="container">
  <div class="heading-wrapper">
    <div class="heading">
      <span class="module-title-desktop">MEASURING POWER SKILLS</span>
      <span class="module-header">Demonstrate your skills</span>
      <div class="complete-counter-mobile">
        <ets-assessment-complete [completion]="totalAssessmentsCompleted" [total]="totalAssessments"></ets-assessment-complete>
      </div>
      <p>
        Employers want to hire people who can speak to the following power skills. Complete the quizzes below to clarify what
        your strengths are and where you can improve.
      </p>
    </div>
    <div class="complete-counter-desktop">
      <ets-assessment-complete [completion]="totalAssessmentsCompleted" [total]="totalAssessments"></ets-assessment-complete>
    </div>
  </div>
  <div class="section">
    <ng-container *ngFor="let assessment of skillAssessments; let i = index">
      <!-- Hiding assessments marked as hide: true-->
<!--      <div >-->
        <ets-skill-card *ngIf="assessment.SNE === true && assessment.show !== false"
                        [skillAssessment]="assessment"
                        [assessmentId]="assessment.assessmentID"
                        (openAssessment)="navigateToAssessment($event)"
        >
        </ets-skill-card>
<!--      </div>-->
    </ng-container>
  </div>
</div>

