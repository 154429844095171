import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { sneReportModuleScores } from '../../../data/sne/sne-report/module-scores';
import { Router } from '@angular/router';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'ets-sne-report-body',
  templateUrl: './sne-report-body.component.html',
  styleUrls: ['./sne-report-body.component.scss']
})
export class SneReportBodyComponent implements OnInit, OnChanges {

  constructor(private router: Router) { }

  @Input() name: string = '';
  @Input() score: string = '';
  @Input() module: string = '';
  // CPS subscores
  @Input() CPS_collab_score= '';
  @Input() CPS_PS_score= '';
  // OC subscores
  @Input() persuasive_subscore= '';
  @Input() info_subscore= '';
  @Input() dialogic_subscore= '';

  title = '';
  subTitle = '';
  abilities: string[] = [];
  inTheWorkPlace = '';
  scoreHumanReadable = '';
  moduleOrSubModule = '';

  twoRoomtoGrowSections = false;
  title2 = '';
  subTitle2 = '';
  abilities2: string[] = [];
  inTheWorkPlace2 = '';
  scoreHumanReadable2 = '';
  moduleOrSubModule2 = '';

  threeRoomtoGrowSections = false;
  title3 = '';
  subTitle3 = '';
  abilities3: string[] = [];
  inTheWorkPlace3 = '';
  scoreHumanReadable3 = '';
  moduleOrSubModule3 = '';

  better?: {title: string, subTitle: string, ideas:{ title: string; text: string; }[]} = {title: '', subTitle: '', ideas: []};

  showWhatScoreMeans = true;
  scoreExplanation = '';
  examples?: { title: string; text: string; }[] = [];

  iconImage1= '';
  iconImage2= '';
  iconImage3= '';

  buildCopy() {
    sneReportModuleScores.forEach(module => {
      if(module.module === this.module){
        if (this.score === 'low') {
          this.updateWhatScoreMeansValues(module, 'low');
        } else if (this.score === 'medium') {
          this.updateWhatScoreMeansValues(module, 'medium');
        } else if (this.score === 'high') {
          this.updateWhatScoreMeansValues(module, 'high');
          this.showWhatScoreMeans = false;
        }
      }
      this.buildSubsectionCopy(module);
    });
  }

  updateWhatScoreMeansValues(module: any, score: string) {
    this.iconImage1 = this.getModuleIcon(module[score].moduleOrSubModule);
    this.scoreHumanReadable = module[score].scoreHumanReadable;
    this.moduleOrSubModule = module[score].moduleOrSubModule;
    this.title = module[score].title;
    this.subTitle = module[score].subtitle;
    this.abilities = module[score].abilities;
    this.inTheWorkPlace = module[score].inTheWorkPlace;
    this.better = module.better;
    this.scoreExplanation = module.scoreExplanation || '';
    this.examples = module.examples || [];
  }

  updateWhatScoreMeans2Values(module: any, score: string) {
    this.iconImage2 = this.getModuleIcon(module[score].moduleOrSubModule);
    this.scoreHumanReadable2 = module[score].scoreHumanReadable;
    this.moduleOrSubModule2 = module[score].moduleOrSubModule;
    this.twoRoomtoGrowSections = true;
    this.title2 = module[score].title;
    this.subTitle2 = module[score].subtitle;
    this.abilities2 = module[score].abilities;
    this.inTheWorkPlace2 = module[score].inTheWorkPlace;
  }

  updateWhatScoreMeans3Values(module: any, score: string) {
    this.iconImage3 = this.getModuleIcon(module[score].moduleOrSubModule);
    this.scoreHumanReadable3 = module[score].scoreHumanReadable;
    this.moduleOrSubModule3 = module[score].moduleOrSubModule;
    this.threeRoomtoGrowSections = true;
    this.title3 = module[score].title;
    this.subTitle3 = module[score].subtitle;
    this.abilities3 = module[score].abilities;
    this.inTheWorkPlace3 = module[score].inTheWorkPlace;
  }

  getModuleIcon(module: string){
    let iconImage='';
    switch(module){
      case 'critical thinking': 
        iconImage = '/../assets/sne/sne-report/icons/sne-report-ct-icon.svg';
        break;
      case 'written communication':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-wc-icon.svg';
        break;
      case 'coachability':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-cc-icon.svg';
        break;
      case 'collaboration':
      case 'conversational oral communication':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-collaboration-icon.svg';
        break;
      case 'problem solving ':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-problem-solving-icon.svg';
        break;
      case 'persuasive oral communication':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-poc-icon.svg';
        break;
      case 'informational oral communication':
        iconImage = '/../assets/sne/sne-report/icons/sne-report-ioc-icon.svg';
        break;
      default:
        iconImage = '';
        break;
    }
    return iconImage;
  }

  buildSubsectionCopy(module: any) {
    //build module scores section (CT and OC have 2 and 3  'what score means' sections each)
    if (this.module === 'CPS' && module.module === 'CPS' && module.high2) {
      if (this.CPS_collab_score === 'high') {
        this.updateWhatScoreMeans2Values(module, 'high2');
      } else if (this.CPS_collab_score === 'medium') {
        this.updateWhatScoreMeans2Values(module, 'medium2');
      } else if (this.CPS_collab_score === 'low') {
        this.updateWhatScoreMeans2Values(module, 'low2');
      }
    } else if (this.module === 'ORC' && module.module === 'ORC' && module.high2){
      if (this.persuasive_subscore === 'high') {
        this.updateWhatScoreMeans2Values(module, 'high2');

      } else if (this.persuasive_subscore === 'medium') {
        this.updateWhatScoreMeans2Values(module, 'medium2');

      } else if (this.persuasive_subscore === 'low') {
        this.updateWhatScoreMeans2Values(module, 'low2');

      }
    }
    if (this.module === 'ORC' && module.module === 'ORC' && module.high3){
      console.log(this.info_subscore);
      if (this.info_subscore === 'high') {
        this.updateWhatScoreMeansValues(module, 'high');
        this.updateWhatScoreMeans3Values(module, 'high3');
      } else if (this.info_subscore === 'medium') {
        this.updateWhatScoreMeansValues(module, 'medium');
        this.updateWhatScoreMeans3Values(module, 'medium3');
      } else if (this.info_subscore === 'low') {
        this.updateWhatScoreMeansValues(module, 'low');
        this.updateWhatScoreMeans3Values(module, 'low3');
      }
    }
  }

  ngOnInit(): void {
    this.buildCopy();
  }

  //update copy on ngOnChanges incase prop from parent component won't be updated on ngOnInit
  ngOnChanges(changes: SimpleChanges): void {
    this.buildCopy();
  }

  finish() {
    // Route to dashboard
    this.router.navigate(['/dashboard']);
  }

}
