import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CCH_HRID, CPS_HRID, CRT_HRID, ORC_HRID, WRC_HRID } from '../sne-v2/sne-v2.helpers';
import { Assessment } from '../types/assessments';
import { LogObject } from '../types/log';
import { SneAnswerRequest, SneAssessmentRecord, SNE_TASK_ENUM } from '../types/sne';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class SneAnswerService extends BaseService {

  private sneAnswerURL = environment.sneAnswerAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'SNE_Answer';
  }

  // Method to get all SNE Learner_Assessment records for the current user
  getSneAssessmentRecords(): Observable<SneAssessmentRecord[]> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let requestLogObj: LogObject = {
        message: 'SNE: GET Assessments Request',
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<SneAssessmentRecord[]>(this.sneAnswerURL, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'SNE: GET Assessments Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Method to save append any action to the answerJSON column of a Learner_Assessment record
  postAction(requestBody: SneAnswerRequest): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = requestBody;

      let requestLogObj: LogObject = {
        message: 'SNE: Post Answer Request',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.sneAnswerURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'SNE: Post Answer Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Method to delete all SNE records or the task provided for the current user - should only be called from the Dev Dashboard
  // taskToDelete parameter is optional
  deleteSneAssessmentRecords(taskToDelete?: CPS_HRID | CRT_HRID | CCH_HRID | ORC_HRID | WRC_HRID): Observable<any> {
    let requestBody: SneAnswerRequest;
    // If a task is provided, delete only that task
    if (taskToDelete) {
      requestBody = {
        task: SNE_TASK_ENUM.DELETE_SNE_RECORDS,
        data: {
          'taskID': taskToDelete
        },
      };
    }
    else {
      requestBody = {
        task: SNE_TASK_ENUM.DELETE_SNE_RECORDS,
        data: {},
      };
    }

    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = requestBody;

      let requestLogObj: LogObject = {
        message: 'SNE: Delete Assessment Records Request',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.sneAnswerURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'SNE: Delete Assessment Records Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

}
