<div class="page-container">

  <h2 class="title">Quiz Results</h2>

  <div class="results-container">
    <clr-spinner class="spinner" *ngIf="gettingAssessmentData"></clr-spinner>

    <div class="result-card" *ngFor='let assessment of assessments'>
      <div class="top-card-wrapper">
        <h4 class="assessment-title margin-top-0">{{assessment.assessmentTitle}}</h4>

        <div class="button-wrapper">
          <button class="retake-btn" [disabled]='disableFacetsRetakeBtn' *ngIf="assessment.completed && assessment.assessmentTitle === 'Get to Know Yourself Quiz'" (click)='retakeOrResumeAssessment(assessment)'>Retake</button>
          <button class="resume-results-btn" *ngIf='!assessment.completed'(click)='retakeOrResumeAssessment(assessment)'>Resume</button>
          <button class="resume-results-btn" *ngIf='assessment.completed' (click)='viewResults(assessment)'>View Results</button>
        </div>
      </div>

      <!-- Bottom card wrapper -->
      <div class="bottom-card-wrapper">
        <div class="date-wrapper">
          <p class="date-label margin-top-0">Date started</p>
          <p class="date-text margin-top-0">{{assessment.formattedStartTimestamp}}</p>
        </div>

        <!-- In-progress/completed tag -->
        <p class="in-progress-tag margin-top-0" *ngIf='!assessment.completed'>In-progress</p>
        <p class="complete-tag margin-top-0" *ngIf='assessment.completed'>Complete</p>
      </div>
    </div>
  </div>
</div>