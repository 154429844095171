<div class="container">
    
    <h1>RECOMMENDED TRAININGS</h1>

    <div class="inner" *ngIf="trainings.length !== 0" >
        <div class="peach-header">
            <img src={{trainings[trainingIndex].course_header}} alt="" aria-hidden="true"/>
        </div>

        <div class="slide-wrapper" *ngFor="let training of trainings; index as i;">
            <div *ngIf="i === trainingIndex" class="transition">
                <ets-training-provider-carousel
                    [training]="training"
                    [career]="careerDetailData"
                    [numberOfTrainings]="numberOfTrainings"
                    [trainingIndex]="trainingIndex"
                    [trainings]="trainings"
                    (changeSlideEvent)="changeSlide($event)"
                >
                </ets-training-provider-carousel>
            </div>
        </div>
    </div>

    <div class="noTraining" *ngIf="trainings.length === 0">
        <span class="text-header"><b>Training coming soon!</b> <br> Check back soon to view training opportunities for this career!</span>
    </div>
</div>