import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HEADER_STATES } from './header.helper';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum } from 'src/app/types/event';
import { QUESTION_SECTIONS } from 'src/app/types/question-types';

@Component({
  selector: 'ets-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() headerState: HEADER_STATES | undefined;
  @Input() isBGWhite: boolean = false;
  @Output() backTrigger = new EventEmitter<any>();
  @Output() signUpTrigger = new EventEmitter<any>();
  @Output() signInTrigger = new EventEmitter<any>();
  @Input() simpleBack: boolean = false;
  @Input() showBackButton: boolean = true;
  @Input() showSignIn = false;
  @Input() currentQuestionSection: QUESTION_SECTIONS | undefined;
  @Input() backgroundColor: string | undefined;

  headerStates = HEADER_STATES;
  faChevronLeft = faChevronLeft;
  showMobileDropDown: boolean = false;
  showCareerPathwaySubMenu: boolean = false;
  showSkillBuilderSubMenu: boolean = false;
  currentIndex: number | undefined;
  QUESTION_SECTIONS = QUESTION_SECTIONS;

  constructor(private router: Router, private location: Location, private store: Store, private eventService: EventService) {}


  back = () => {
    // Track the event in AWS
    this.eventService.buildEvent('Back button', EventActionEnum.CLICK);

    if (this.simpleBack) {
      history.back();
    } else {
      this.backTrigger.emit();
    }

  };

  signUp = () => {
    this.showSignIn = !this.showSignIn;
    this.router.navigate(['/auth/sign-up']);
    this.signUpTrigger.emit();
  }

  signIn = () => {
    this.showSignIn = !this.showSignIn;
    this.router.navigate(['/auth/sign-in']);
    this.signInTrigger.emit();
  }

  trackProfileHeadshotClick() {
    // Track the event in AWS
    this.eventService.buildEvent('Profile Headshot Nav Bar button', EventActionEnum.CLICK);
    this.router.navigate(['/account-profile/account']);
  }

  signOut() {
    // Track the event in AWS
    this.eventService.buildEvent('Sign Out Nav Bar button', EventActionEnum.CLICK);

    localStorage.clear();
    window.location.reload();
  }

  toggleMobileDropDown(selection: string) {
    // Logic to track the click on the BE
    if (selection === 'Overview') {
      // Track the event in AWS
      this.eventService.buildEvent('Overview Nav Bar button', EventActionEnum.CLICK);
    } else if (selection === 'Career Prep') {
      // Track the event in AWS
      this.eventService.buildEvent('Career Prep Nav Bar button', EventActionEnum.CLICK);
    }

    this.showMobileDropDown = !this.showMobileDropDown;

    //prevent page from scrolling if user clicks on hamburger icon in top bar
    let lock = document.getElementById('lock-screen');
    if (this.showMobileDropDown) {
      lock!.style.position = 'fixed';
    } else if(!this.showMobileDropDown) {
      lock!.style.position = 'absolute';
    }

  }

  redirectToSurvey() {
    window.open('https://forms.office.com/pages/responsepage.aspx?id=t-mmC7Ngrk-S835t3Z6bZXXi6j6qEQ5Jiv3vwNr2B6BUME4ySk5BR0pNQjA4STlHVjdBSUw0R0k1Ui4u');
  }

  redirectToDashboard() {
    this.eventService.buildEvent('Apprize logo', EventActionEnum.CLICK);
    this.router.navigate(['/dashboard']);
  }

  redirectToSignIn() {
    this.router.navigate(['/auth/sign-in']);
  }
}
