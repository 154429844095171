import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CareerDetailService } from 'src/app/services/career-detail.service';
import { TrainingProviderModule } from 'src/app/types/career-detail';
@Component({
  selector: 'ets-training-provider',
  templateUrl: './training-provider.component.html',
  styleUrls: ['./training-provider.component.scss'],
})

export class TrainingProviderComponent implements OnChanges {
  showModal = false;// flag to show the No Work History modal
  pageCounter = 1;
  constructor( private careerDetailService: CareerDetailService) {
  }
  
  @Input() careerTitle: string | undefined = '';
  @Input() careerID: number | undefined = 0;
  @Input() careerDetailData: any;
  trainingIndex = 0;
  trainings: TrainingProviderModule[] = [];
  numberOfTrainings = this.trainings.length;

  ngOnChanges(changes: SimpleChanges): void {
    let careerTitleSlugFormat = this.careerTitle?.replace(/ /g, '-').toLowerCase() || '';

    if(this.trainings.length === 0){
      this.careerTitle ? 
        this.careerDetailService.getTrainingProviderModules(careerTitleSlugFormat).subscribe(trainingsFromBE => {
          console.log(trainingsFromBE);
          trainingsFromBE && trainingsFromBE.length > 0 ? this.trainings = trainingsFromBE : this.trainings = [];
          this.numberOfTrainings = trainingsFromBE.length;
        }) 
        : null;
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
  toggleModalNext() {
    this.pageCounter++;
    this.showModal = false;
    this.showModal = !this.showModal;
  }

  changeSlide(index: number){
    this.trainingIndex = index;
  }
}
