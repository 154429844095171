import { HttpErrorResponse } from '@angular/common/http';
import { LogService } from './log.service';
import { LogObject } from '../types/log';
import { throwError } from 'rxjs';
import { Store } from '@ngrx/store';

export class BaseService {

  protected serviceName: string = '';
  constructor(protected logService: LogService) {}

  protected handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    // Expired/bad token, need to get a new one
    if (err.status === 401) {
      localStorage.clear();
      window.location.reload();
    }

    let errorLogObj: LogObject = {
      message: errorMessage,
      location: this.serviceName,
    };
    this.logService.logError(errorLogObj)?.subscribe();

    return throwError(errorMessage);
  }

}
