import { Component, Input, OnChanges, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CareerDetailService } from 'src/app/services/career-detail.service';
import { selectProfile } from 'src/app/store';

// Ambient declaration for Facebook Pixel to allow compiler to be happy... fbq will be defined by runtime
declare const fbq: any;

@Component({
  selector: 'ets-additional-info-modal',
  templateUrl: './additional-info-modal.component.html',
  styleUrls: ['./additional-info-modal.component.scss']
})
export class AdditionalInfoModalComponent implements OnInit {
  myForm = new FormGroup({
    linkedIninput: new FormControl('',
      [Validators.required,Validators.maxLength(256)]),
    phoneNumberInput: new FormControl('',
      [Validators.required,Validators.maxLength(10)]),
    startDateInput: new FormControl('',
      [Validators.required,Validators.maxLength(256)])
  });

  constructor(private careerDetailService: CareerDetailService, private store: Store) {
  }

  @Input() training: any;
  @Input() career: any;
  @Output() closeModalSignal = new EventEmitter<any>();
  openBox = false;
  valueProp1: string = '';
  valueProp2: string = '';
  valueProp3: string = '';
  valueProp4: string = '';
  valueProp1image: string = '';
  valueProp2image: string = '';
  valueProp3image: string = '';
  valueProp4image: string = '';
  educationAndExperience: string[] = [];
  supplementalInfo: string[] = [];
  careerTitle = '';
  careerID = '';
  courseTitle = '';
  trainingProviderName = '';
  description = '';
  duration = '';
  program = '';
  cost = '';
  showModal = false;
  pageCounter = 1;
  courseRequirements = '';
  showLearnMoreModal = false;
  salaryRange: string = '';
  logo: string = '';
  sliderImg = '';
  trainingExplanation = '';
  offerTitle = '';
  schedule = '';
  startDateText = '';
  trainingType = '';
  linkedIn = true;
  startDate = true;
  phoneNumberField = true;
  toolTipSize = '';
  linkedInUrl: any;
  startDateMonth: any;
  phoneNumber: any;
  privacyPolicyURL: string = '';
  learnerID: number | undefined;

  dateObj = new Date();
  monthNum = this.dateObj.getMonth();
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  month1 = '';
  month2 = '';
  month3 = '';
  year1: any;
  year2: any;
  year3: any;
  currentYearNum = this.dateObj.getFullYear();
  newYear = this.currentYearNum + 1;
  becomePrepared = `<br><b>Career Ready</b> courses are well-rounded and comprehensive, designed to make individuals with varying levels of experience employable and confident in a new career path. Both Career Intensive and Train to Hire programs fall into this category.<br><br>

  <b>Become a Specialist</b> courses allow individuals to hone in on a specific skillset. They can either prepare people who need to learn a few new skills for a particular career or help those looking to enhance their current capabilities. Certain types of Skill Specific programs (usually those that encompass multiple skills) fall into this category.<br><br>

  <b>Close a Skill Gap</b> courses allow individuals to refresh or learn a specific skill or discover more about a field of work without a significant financial investment or time commitment. Certain types of Skill Specific programs (usually those that focus on one skill) fall into this category.<br><br>

  <b>Expected Salary</b> is the estimated average salary for a full-time employee within a given career`;

  trainingTypeText = `<br><b>Career Intensive</b> programs prepare you to work within a specific field. Completing one of these programs should fully equip you with the skills you need to succeed, and graduates typically receive career services as they explore potential roles and employers.<br><br>

  <b>Train to Hire</b> programs prepare you for a specific role with a specific employer. The training features direct, on-the-job experience, and the program typically concludes when the employer formally hires the trainee.<br><br>

  <b>Skill Specific</b> programs can be highly targeted (i.e., certification in Google Analytics) or general (i.e. “Introduction to Digital Marketing”). They typically add to or enhance your current skillset, but are not comprehensive enough to fully prepare you for a new career.<br>`


  ngOnInit() {
    this.valueProp1image = `/assets/career-details/${this.training.course_value_prop_1}.png`;
    this.valueProp2image = `/assets/career-details/${this.training.course_value_prop_2}.png`;
    this.valueProp3image = `/assets/career-details/${this.training.course_value_prop_3}.png`;
    this.valueProp4image = `/assets/career-details/${this.training.course_value_prop_4}.png`;
    this.trainingProviderName = this.training.training_provider;
    this.courseTitle = this.training.course_title;
    this.cost = this.training.course_price;
    this.duration = this.training.course_duration.replace(/-/g, ' ');
    this.startDateText = this.training.course_start_date.replace(/-/g, ' ');
    this.description = this.training.course_description;
    this.program = this.training.course_training_type.replace(/-/g, ' ');
    this.schedule = this.training.course_schedule.replace(/-/g, ' ');
    this.trainingType = this.training.course_training_type.replace(/-/g, ' ');
    this.courseRequirements = this.training.course_requirements;
    this.privacyPolicyURL = this.training.training_provider_privacy_policy_url;

    this.linkedIn = this.training.course_additional_requirements_linkedin;
    this.startDate = this.training.course_additional_requirements_start_date;
    this.phoneNumberField = this.training.course_additional_requirements_phone_number;
    this.valueProp1 = this.transformValuePropText(this.training.course_value_prop_1);
    this.valueProp2 = this.transformValuePropText(this.training.course_value_prop_2);
    this.valueProp3 = this.transformValuePropText(this.training.course_value_prop_3);
    this.valueProp4 = this.transformValuePropText(this.training.course_value_prop_4);

    this.logo = this.training.training_provider_logo;

    this.parseExplanationText(this.training.course_requirements);

    this.buildBlueBox();
    this.toggleModal();
    this.getMonthsToDisplay();
    this.getYearToDisplay();

    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.learnerID = profile.learnerID;
      }
    });
  }

  ngOnChanges() {
    this.salaryRange = this.career.salaryRange;
  }

  transformValuePropText(oldText: string) {
    let newText = '';
    if (oldText === 'career-support-mentorship') {
      newText = 'Career support & mentorship';
    } else if (oldText === 'flexible-program-schedule') {
      newText = 'Flexible program & schedule';
    } else {
      newText = oldText.replace(/-/g, ' ');
    };
    return newText;
  }

  parseExplanationText(originalText: string) {
    let newText = originalText.split('\n@');
    this.educationAndExperience = newText[0].split('\n* ').splice(1);
    if(newText[1]){
      this.supplementalInfo = newText[1].split('\n* ').splice(1);
    }
  }

  buildBlueBox() {
    if (this.training.course_training_type === 'train-to-hire') {
      this.offerTitle = 'FIND A JOB OR GET REFUNDED';
      this.sliderImg = '/assets/career-details/career-ready.png';
      this.trainingExplanation = 'Free train to hire programs allow you to get hands on experience while getting paid for the work that you do.';
    } else if (this.training.course_training_type === 'career-intensive') {
      this.offerTitle = 'LEARN FROM INDUSTRY LEADERS';
      this.sliderImg = '/assets/career-details/career-ready.png';
      this.trainingExplanation = 'Find a job within six months or we guarantee reimbursement of your tuition.';
    } else if (this.training.course_training_type === 'skill-specific') {
      this.offerTitle = 'LEARN AT NO COST TO YOU';
      this.sliderImg = '/assets/career-details/close-a-skill-gap.png';
      this.trainingExplanation = 'Gain knowledge of best practices from industry veterans that have more than a decade of experience.';
    }
  }
  getYearToDisplay() {
    if (this.monthNum === 10) {
      this.year1 = this.currentYearNum;
      this.year2 = this.currentYearNum;
      this.year3 = this.newYear;
      return;
    }
    if (this.monthNum === 11) {
      this.year1 = this.currentYearNum;
      this.year2 = this.newYear;
      this.year3 = this.newYear;
      return;
    } else {
      this.year1 = this.currentYearNum;
      this.year2 = this.currentYearNum;
      this.year3 = this.currentYearNum;
    }
  }


  getMonthsToDisplay() {
    if (this.monthNum === 10) {
      this.month1 = 'November';
      this.month2 = 'December';
      this.month3 = 'January';
      return;
    }
    if (this.monthNum === 11) {
      this.month1 = 'December';
      this.month2 = 'January';
      this.month3 = 'February';
      return;
    } else {
      this.month1 = this.monthNames[this.monthNum];
      this.month2 = this.monthNames[this.monthNum + 1];
      this.month3 = this.monthNames[this.monthNum + 2];
    }
  }

  toggleModal() {
    if (!this.linkedIn && !this.startDate && !this.phoneNumberField) {
      this.pageCounter = 2;
    } else {
      this.pageCounter = 1;
    }
  }
  prevModal() {
    if (!this.linkedIn && !this.startDate) {
      this.closeModalSignal.emit();
    } else {
      this.pageCounter = 1;
    }
  }
  closeModal() {
    this.closeModalSignal.emit();
    this.toggleModal();
  }
  toggleModalNext() {
    this.pageCounter++;
  }
  agreeBtn() {
    this.pageCounter++;
    this.sendInfoBack();
  }
  disableSubmit() {
    if ((this.linkedIn && this.myForm.controls.linkedIninput.value === '') || (this.startDate && this.myForm.controls.startDateInput.value === '') || ( this.phoneNumberField && this.myForm.controls.phoneNumberInput.value === '')) {
      return true;
    } else {
      return false;
    }
  }

  sendInfoBack() {
    this.linkedInUrl = this.myForm.controls.linkedIninput.value;
    this.startDateMonth = this.myForm.controls.startDateInput.value;
    this.phoneNumber = this.myForm.controls.phoneNumberInput.value;
    this.trainingProviderName = this.training.training_provider;
    this.courseTitle = this.training.training_provider + '-' + this.courseTitle;


    const submitData = {
      linkedInURL: this.linkedInUrl,
      earliestStartDate: this.startDateMonth,
      phoneNumber: this.phoneNumber,
      trainingProvider: this.trainingProviderName,
      courseName: this.courseTitle
    };

    this.careerDetailService.submissionTrainingModule(submitData).subscribe({
      next: (data) => {
        if (this.learnerID) {
          fbq('trackCustom', 'ApplyToTraining', {
            learnerID: this.learnerID,
          });
        } else {
          fbq('trackCustom', 'ApplyToTraining');
        }
      }
    });

  }

}
