import { Component } from '@angular/core';
import { HEADER_STATES } from '../shared/header/header.helper';

@Component({
  selector: 'ets-access',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

  HEADER_STATES = HEADER_STATES;

  constructor() { }

}
