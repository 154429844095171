import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Profile from '../../../types/profile';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { formatDate } from '@angular/common';
import OB_PAGES from '../../../types/on-boarding-pages.types';
import { EventService } from 'src/app/services/event.service';
import { APP_COLORS } from '../../../../global-styles';
import { faCalendarDay  } from '@fortawesome/free-solid-svg-icons/faCalendarDay';

@Component({
  selector: 'ets-v2-sets-setup-input-name',
  templateUrl: './single-question-v2.component.html',
  styleUrls: ['./single-question-v2.component.scss']
})
export class SingleQuestionV2Component extends OnBoardingV2Base implements OnInit, OnDestroy {

  faCalendarDay = faCalendarDay;

  myForm = new FormGroup({
    input: new FormControl('')
  });
  error: String = 'This field is required.';
  displayQuestion = ''; // question to display
  showModal = false; // flag to show the No Work History modal
  value: string| undefined;
  salary: any = '';

  OB_PAGES = OB_PAGES;

  APP_COLORS = APP_COLORS;

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  processQuestion() {
    if (this.userProfile) {
      this.processProfile();
    }
  }

  processSalaryInput(event: any) {
    if (this.currentQuestion?.profileAttribute === 'currentSalary') {
      if (event.keyCode >= 48 && event.keyCode <= 57) {  // numeric
        //remove $ and commas, add new digit
        this.salary = this.salary.substring(1).replace(/,/g, '') + event.key;
        this.salary = `$${Number(this.salary).toLocaleString()}`;
        this.myForm.controls.input.setValue(this.salary);
      } else if (event.key === 'Backspace') {
        this.salary = event.target.value;
        let lastChar = event.target.value.charAt(event.target.value.length - 1);

        if (!isNaN(lastChar)){ // if last char is a number, format with commas and $
          this.salary = this.salary.substring(1).replace(/,/g, '');
          this.salary = `$${Number(this.salary).toLocaleString()}`;
          this.myForm.controls.input.setValue(this.salary);

          if (event.target.value.length === 1) { // set salary to 0 (final char is '$')
            this.salary = 0;
          }
        }
      } else {  // alpha, do not format with '$' and commas
        this.salary = event.target.value;
      }
    }
  }

  processProfile() {
    if (this.currentQuestion && this.userProfile) {
      const { profileAttribute } = this.currentQuestion;
      const key = profileAttribute as keyof Profile;
      this.myForm.controls.input.clearValidators();
      if (this.currentQuestion.profileAttribute && this.userProfile[key]) {
        if (this.currentQuestion.profileAttribute === 'birthDate' && this.userProfile['birthDate']) {
          let bday = new Date(this.userProfile['birthDate']);
          bday.setDate(bday.getDate() + 1);
          let bdayString = formatDate(bday, 'yyyy-MM-dd', 'en-US');
          this.myForm.controls.input.setValue(bdayString);
        } else {
          this.myForm.controls.input.setValue(this.userProfile[key]);
          if (this.currentQuestion.profileAttribute === 'currentSalary'){
            //format salary with $ and commas on initial setting of value
            this.salary = '$' + this.userProfile[key]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.myForm.controls.input.setValue(this.salary);
          }
        }
        this.resetFormValidation();
      } else {
        this.myForm.controls.input.setValue('');
        this.resetFormValidation();
      }
      if (this.currentQuestion && this.currentQuestion.errorMessage) {
        this.error = this.currentQuestion.errorMessage;
      }

      // Logic to build question for currentSalary question
      if (this.currentQuestion.questionFunc && this.userProfile.initialRole) {
        this.displayQuestion = this.currentQuestion.questionFunc(this.userProfile.initialRole);
      } else if (this.currentQuestion.question) {
        this.displayQuestion = this.currentQuestion.question;
      }
    }
  }

  resetFormValidation():void {
    if (this.currentQuestion && this.currentQuestion.validator) {
      this.myForm.controls.input.setValidators([Validators.required, Validators.pattern(this.currentQuestion.validator), Validators.maxLength(256)]);
      this.myForm.controls.input.updateValueAndValidity();
      this.myForm.controls.input.markAsUntouched();
      this.myForm.controls.input.markAsPristine();
    }
  }

  isValidDate(): boolean {
    const userBirthDate = new Date(this.myForm.controls.input.value);
    const userBirthDateValid = this.myForm.controls.input.valid;
    const firstDate = new Date('12/12/1900');
    let currentDate = new Date();
    return userBirthDate > firstDate && userBirthDate < currentDate && userBirthDateValid;
  }

  triggerNextQuestion() {
    if (this.currentQuestion?.profileAttribute === 'currentSalary'){
      //remove $ and commas before sending to backend
      this.userResponse = this.myForm.controls.input.value.replace('$', '').replace(',', '');
    } else {
      this.userResponse = this.myForm.controls.input.value;
      if (this.currentQuestion?.profileAttribute === 'birthDate' && this.userResponse) {
        this.userResponse = new Date(this.myForm.controls.input.value);
        this.userResponse = formatDate(this.myForm.controls.input.value, 'yyyy-MM-dd', 'en-US');
        console.log(this.userResponse);
      }
    }
    if (this.userResponse) {
      this.submitResponse();
    }

  }

  disableSubmit() {
    if (this.myForm && this.myForm.controls) {
      return this.myForm.controls.input.invalid;
    } else {
      return true;
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  markNoHistoryAndRoute() {
    // Empty out initialRole and salary in case they had been answered and clicked back
    if (this.userProfile) {
      let newProfile = {...this.userProfile};
      if (newProfile.initialRole) {
        newProfile.initialRole = null;
      }
      if (newProfile.currentSalary) {
        newProfile.currentSalary = null;
      }

      this.profileService.saveProfile({...newProfile})
        .subscribe(profile => {
          if (profile) {
            this.store.dispatch(setProfile({payload: profile}));
          }
        });
    }

    let questionIndex = this.questionList.findIndex(question => {
      return question.page === OB_PAGES.SECTION_ONE_END_INFO_BREAK;
    });
    // Route to the sectionOneInfoBreak
    this.router.navigate(['/on-boarding/break/' + questionIndex]);
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }
}
