import {
  SneQuestion,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import OC_TYPES from 'src/app/types/oral-communication-types';

export const OCPersuasive1Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Communicating Your Strengths',
      info: 'This task will give you insight into how you might convey your strengths and qualifications, and we will use what we learn together to enhance your Skills Profile. <br><br>You will play one or more videos and answer corresponding questions. In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      questionIndex: 5,
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      image: '/assets/section-4/sne-interstitial/persuasive-1.svg',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'Review the following video options. Then select the most persuasive option. This option should best express the purpose for your meeting. ',
      headerVideo:{
        url: 'https://vimeo.com/605848066 ',
        id: '605848066',
        title: 'Meeting with Your Supervisor',
        mainHeader: 'Video 1',
        description: 'Watch and listen to your supervisor, then answer the first question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605874490',
          id: '605874490',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605874571',
          id: '605874571',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605874623',
          id: '605874623',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question:
        'Review the following video options. Then select the most persuasive option. This option should best explain what you\'ve learned through your work at the company and how it applies to you being ready for a leadership position.',
      headerVideo:{
        url: 'https://vimeo.com/605848124',
        id: '605848124',
        title: 'Responding to Questions',
        mainHeader: 'Video 2',
        description: 'Watch and listen to your supervisor, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605657726',
          id: '605657726',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605657876',
          id: '605657876',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605875116',
          id: '605875116',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question:
        'Review the following video options. Then select the most persuasive option. This option should most clearly demonstrate your communication and organization skills.',
      headerVideo:{
        url: 'https://vimeo.com/605848168',
        id: '605848168',
        title: 'An Offer of Support',
        mainHeader: 'Video 3',
        description: 'Watch and listen to your supervisor, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605875184',
          id: '605875184',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605875257',
          id: '605875257',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605875288',
          id: '605875288',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question:
        'Review the following video options. Then select the most persuasive option. This option should most clearly demonstrate your nonverbal communication skills.',
      headerVideo:{
        url: 'https://vimeo.com/605848168',
        id: '605848168',
        title: 'An Offer of Support',
        mainHeader: 'Video 3',
        description: 'Watch and listen to your supervisor, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605875520',
          id: '605875520',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605875692',
          id: '605875692',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605875754',
          id: '605875754',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
        {
          url: 'https://vimeo.com/605875801',
          id: '605875801',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
        },
      ],
      submitId: 'item_4',
      complete: true,
      sharedVideo: { 
        url: 'https://vimeo.com/605848168',
        id: '605848168',
      },
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work communicating with your supervisor! Your decisions help illustrate how you might convey your strengths and qualifications, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready. One more to go!',
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png', // placeholder
      questionIndex: 5,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: OC_TYPES.PERSUASIVE_2,
    },
  ],
]);


export const OCPersuasive1InstructionModal: SneModal =
  {
    title: 'Communicating Your Strengths',
    pageList: [
      {
        info: 'You saw a posting for a new leadership position in your company, and you think you would be well qualified for the position. You set up a meeting with your supervisor to discuss the role and convince them that you are ready for the increased responsibility of this leadership position.<br><br>This section contains multiple videos and corresponding questions. Each video can be played <span class=\'convertToBlue\'>once</span>.<br><br>Play the first video. Then answer the question(s) that follow. '
      },
    ],
  };
