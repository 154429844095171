<div class="page-container">
  <div class="content-container">
    <h2 role="region" aria-live="assertive" class="title">Gathering and categorizing your skills.</h2>
      <ets-spinner
      [width]="'125.13px'"
      [height]="'125px'"
      [margin]="'48px 0 0 0'"
      >
      </ets-spinner>
  </div>
</div>
