import { createAction, props } from '@ngrx/store';
import QUESTION_TYPES, { QUESTION_SECTIONS } from '../types/question-types';

export const setOnBoardingSection = createAction('[On Boarding] Set On-Boarding Section', props<{payload: QUESTION_SECTIONS}>());
export const setOnBoardingQuestionType = createAction('[On Boarding] Set On-Boarding Type', props<{payload: QUESTION_TYPES}>());
export const setOnBoardingCurrentQuestionIndex = createAction('[On Boarding] Set On-Boarding Question Index', props<{payload: number}>());
export const setWorkHistoryInFlight = createAction('[On Boarding] Updating Work History In-flight', props<{payload: boolean}>());
export const setSkillsInFlight = createAction('[On Boarding] Updating Skills In-flight', props<{payload: boolean}>());


