import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareerDetailComponent } from './career-detail/career-detail.component';
import { TrainingProviderComponent } from './career-detail/training-provider/training-provider.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrainingProviderCarouselComponent } from './career-detail/training-provider/carousel/carousel.component';
import { LearnMoreModalComponent } from './career-detail/training-provider/learn-more-modal/learn-more-modal.component';
import { AdditionalInfoModalComponent } from './career-detail/training-provider/additional-info-modal/additional-info-modal.component';
import { JobPostingCarouselComponent } from './career-detail/job-posting-carousel/job-posting-carousel.component';
import { JobPostingCardComponent } from './career-detail/job-posting-carousel/components/job-posting-card/job-posting-card.component';

@NgModule({
  declarations: [
    CareerDetailComponent,
    TrainingProviderComponent,
    TrainingProviderCarouselComponent,
    LearnMoreModalComponent,
    AdditionalInfoModalComponent,
    JobPostingCarouselComponent,
    JobPostingCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgxChartsModule,
    FontAwesomeModule,
    // IvyCarouselModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CareersModule { }
