<ets-header [headerState]="headerState" (backTrigger)='toggleExitModal()'></ets-header>

<clr-modal [(clrModalOpen)]="scoringErrorFlag" [clrModalClosable]="false">
  <h3 class="modal-title">Oops! Something went wrong.</h3>
  <div class="modal-body">
    <p>
      It looks like something got scrambled, and you will not be able to finish the quiz from here. 
      But don't worry – we saved your place on the "Quiz Results" page of your profile.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline modal-btn" (click)="handleScoreError()">View My Quizzes</button>
  </div>
</clr-modal>

<div class="page-container">  
  <div class="outer-question-container">
    <div class="question-container padding-0">
      <h1 class="question">Select the response that best matches how you feel about each statement</h1>
    </div>
  </div>

  <div class="answers-outer-container">
    <div class="answers-inner-container">
      <div class="card-wrapper padding-0" *ngFor="let statement of question?.statements; index as i">
        <div class="answer-card">
          <p class="answer-text margin-top-0">
            {{statement.statement}}
          </p>
          <div [class]="'btn-group group-' + (i + 1)" >
            <div class="group-1 active" (click)="setCardValues(i + 1, 1, $event)">
              <button class="btn btn-sm"></button>
              <span> Very not like me</span>
            </div>
            <div class="group-2 active" (click)="setCardValues(i + 1, 2, $event)">
              <button class="btn btn-sm"></button>
              <span> Not like me</span>
            </div>
            <div class="group-3 active" (click)="setCardValues(i + 1, 3, $event)">
              <button class="btn btn-sm"></button>
              <span>Like me</span>
            </div>
            <div class="group-4 active" (click)="setCardValues(i + 1, 4, $event)">
              <button class="btn btn-sm"></button>
              <span>Very much like me</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

<div class="button-wrapper mobile-hide">
  <button class="save-exit-button" (click)="saveAndExitQuiz()">
    Save & Exit
  </button>
  <button [disabled]='nextQuestionDisabledFlag' class="next-question-button" *ngIf='!finalQuestionFlag' (click)="saveAndGetNextQuestion()">
    Next Question
  </button>
  <button [disabled]='nextQuestionDisabledFlag' class="next-question-button" *ngIf='finalQuestionFlag' (click)="saveAndGetNextQuestion()">
    View Results
  </button>
</div>

<div class="button-wrapper non-mobile-hide">
  <button class="mobile-next-question-btn" 
      *ngIf='!finalQuestionFlag'
      [disabled]='nextQuestionDisabledFlag'
      (click)='saveAndGetNextQuestion()'>
    Next Question
  </button>
  <button class="mobile-next-question-btn" 
      *ngIf='finalQuestionFlag'
      [disabled]='nextQuestionDisabledFlag'
      (click)='saveAndGetNextQuestion()'>
    View Results
  </button>
</div>

<ets-footer class="desktop-tablet-footer-bar mobile-hide" [showQuestionNumbers]='false' [progress]='percentage'></ets-footer>
<ets-footer class="mobile-footer-bar non-mobile-hide" [showQuestionNumbers]='false' [progress]='percentage' [progressBgColor]='mobileProgressBarColor'></ets-footer>

<div class="exit-modal-container" *ngIf='showExitModal'>
  <div class="exit-modal">
    <h2 class="exit-modal-title">Need a break?</h2>
    <h3 class="exit-modal-question">Self-exploration is tiring. We get it. But you’re almost there!</h3>
    <hr class="line-break">
    <button class="exit-modal-btn" (click)="toggleExitModal()">Push Through</button>
    <hr class="line-break">
    <button class="exit-modal-btn" (click)="saveAndExitQuiz()">Save and exit</button>
  </div>
</div>
