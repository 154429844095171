import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ets-rounded-contained-button',
  templateUrl: './rounded-contained-button.component.html',
  styleUrls: ['./rounded-contained-button.component.scss']
})
export class RoundedContainedButtonComponent {

  constructor() { }

  @Input() buttonText: string | undefined;
  @Input() backgroundColor: string | undefined;
  @Input() borderColor: string | undefined;
  @Input() textColor: string | undefined;
  @Input() fullWidth: boolean = false;
  @Input() disabled: boolean = false;
  @Output() clickEvent = new EventEmitter<any>();
  @Input() ariaLabel? : string | undefined = '';

  onClick = () => {
    this.clickEvent.emit();
  };

}
