<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>
<ets-sne-split-screen
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="right">
    <ets-single-multi-choice-question
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      [headerQuestion]="questionData?.header"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      [inputType]="questionData?.inputType"
    >
    </ets-single-multi-choice-question>
  </div>
  <div class="left">
    <div class="hint-btn-container">
      <span class="interaction-text">Email Thread</span>
      <ets-outline-button
        [text]="'Hint'"
        [color]="APP_COLORS.uiaction"
        *ngIf="questionData?.hintModal"
        [iconImgSrc]="'/assets/icons/blue-lightbulb-no-flash.svg'"
        [iconImgAlt]="'blue lightbulb'"
        [iconWidth]="'16px'"
        (clickEvent)="hintToggle()"
      >
      </ets-outline-button>
    </div>
    <ets-sne-email-layout
      [subject]="emailList[0]?.emailSubject"
      [emails]="emailList"
      [colorArray]="['white', 'blue']"
      [firstColorIndex]="1"
    >
    </ets-sne-email-layout>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  (overviewEmitter)="openInstructionModal()"
  (nextEmitter)="getNextQuestion()"
  [questionNum]="questionNum"
  [isButtonDisabled]="
    questionData?.inputType === SNE_INPUT_TYPES.RADIO
      ? !singleResponse
      : multiResponse.length !== questionData?.numSelectOptions
  "
>
  <ets-single-multi-choice-question
    [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
    [headerQuestion]="questionData?.header"
    (selectMultiResponseEvent)="multiResponseHandler($event)"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-single-multi-choice-question>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID="customContent">
    <ets-sne-email-layout
      *ngIf="instructionModal?.customContent[0].to"
      [emails]="instructionModal?.customContent"
      [colorArray]="['blue']"
      [firstColorIndex]="0"
      [subject]="instructionModal?.customContent[0].emailSubject"
    ></ets-sne-email-layout>
    <ets-sne-conversation-log-text
      *ngIf="instructionModal?.customContent[0].user"
      [chatLog]="instructionModal?.customContent"
      [showHeader]="false"
    ></ets-sne-conversation-log-text>
  </div>
</ets-modal>
<ets-modal
[title]="questionData?.hintModal?.title"
[showModal]="showHintModal"
[list]="questionData?.hintModal?.pageList"
(modalClose)="hintToggle()"
[hint]="true"
>
</ets-modal>
