import { SneVideos } from './../../../types/sne';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-sne-ques-video',
  templateUrl: './sne-question-video.component.html',
  styleUrls: ['./sne-question-video.component.scss']
})
export class SneQuestionVideoComponent {

  constructor() { }

  @Input() title: string | undefined;
  @Input() mainHeader: string | undefined;
  @Input() videoId: string | undefined;
  @Input() videoUrl: string | undefined;
  @Input() description: string | undefined;
  @Input() playOnce?: boolean = false;
  @Input() sharedVideo?: SneVideos;
  @Input() titleColor? : string;

}
