<ets-header [headerState]="headerState" [simpleBack]='true'></ets-header>

<div class="page-container">

  <div class="left-container padding-0">
    <img class="img" src="/assets/facets/facets-intro-blue.jpeg" alt="Intro Welcome Image">
  </div>

  <div class="right-container padding-0">
    <div class="right-inner-container">
      <div class="title-wrapper">
        <h1 class="title">Get to know yourself</h1>
        <h3 class="description">
          This exercise uncovers your unique combination of traits. Learning more about yourself and how you tend to operate will help you convey your talents to employers.
        </h3>
      </div>

      <div class="skills-container">
        <h2 class="skills-title">Categories of traits we’ll explore:</h2>

        <div class="skills-wrapper">
          <div class="skills-left-wrapper">
            <div class="skill-row margin-bottom-12">
              <fa-icon class="fa-icon" [icon]="faArrowRight"></fa-icon>
              <p class="skill">Teamwork</p>
            </div>
            <div class="skill-row margin-bottom-12">
              <fa-icon class="fa-icon" [icon]="faArrowRight"></fa-icon>
              <p class="skill">Approachability</p>
            </div>
            <div class="skill-row margin-bottom-12">
              <fa-icon class="fa-icon" [icon]="faArrowRight"></fa-icon>
              <p class="skill">Conscientiousness</p>
            </div>
          </div>

          <div class="skills-right-wrapper">
            <div class="skill-row margin-bottom-12">
              <fa-icon class="fa-icon" [icon]="faArrowRight"></fa-icon>
              <p class="skill">Temperament</p>
            </div>
            <div class="skill-row margin-bottom-12">
              <fa-icon class="fa-icon" [icon]="faArrowRight"></fa-icon>
              <p class="skill">Mindfulness</p>
            </div>
          </div>
        </div>
      </div>

      <div class="button-container">
        <ets-rounded-contained-button
          *ngIf='!incompleteQuiz'
          type="button"
          [fullWidth]="true"
          [backgroundColor]="'#0E3AD6'"
          [textColor]="'#fff'"
          [buttonText]="'Let&rsquo;s Begin!'"
          (click)="letsBeginAction()"
        ></ets-rounded-contained-button>

        <ets-rounded-contained-button
          *ngIf='incompleteQuiz'
          type="button"
          [fullWidth]="true"
          [backgroundColor]="'#0E3AD6'"
          [textColor]="'#fff'"
          [buttonText]="'Resume'"
          (click)='resumeAssessment()'
        ></ets-rounded-contained-button>
      </div>

      <div class="quiz-description-wrapper">
        <p class="quiz-description">Rapid-fire Questions</p>
        <p class="quiz-time">30-40 min</p>
      </div>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="facetsOutage" [clrModalClosable]="false" [clrModalSize]="'md'">
  <h3 class="modal-title">We're Undergoing Maintenance</h3>
  <div class="modal-body">
    <p>
      We're sorry, it looks like this service is currently unavailable.<br>Please come back and try again later.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline modal-btn" [routerLink]="['/dashboard']">Take Me Back</button>
  </div>
</clr-modal>
