<div class="container" *ngIf="displayProgressBar">
  <div
    class="progress-bar" id="progress-bar"
    [ngStyle]="{
              'width': progress,
              'background-color': progressBgColor
            }"
  >

  <label for="progress-bar" class="progress-bar-label">progress bar</label>
    <!-- <span class="progress"
        *ngIf='showQuestionNumbers'
        [ngStyle]="{
          'color': questionNumColor
        }"
    >
      {{questionIndex}}/{{questionList?.length}}
    </span> -->
  </div>
  <span class="progress" id="progress"
    *ngIf='showQuestionNumbers'
    [ngStyle]="{
      'color': questionNumColor
    }"
  >
    {{questionIndex}}/{{questionListLength}}
  </span>
  <label for="progress" class="progress-label">{{questionIndex}}/{{questionListLength}}</label>
</div>
