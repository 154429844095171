import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HPSharedFacts } from 'src/app/data/sne/collaborative-problem-solving/02_hidden-profile';
import { HIDDEN_PROFILE_MENU_TYPES, HiddenProfileUserResponses, VENDOR_SET, VendorFact } from 'src/app/types/sne';

@Component({
  selector: 'ets-sne-hp-menu',
  templateUrl: './sne-hp-menu.component.html',
  styleUrls: ['./sne-hp-menu.component.scss']
})
export class SneHpMenuComponent implements OnInit {

  constructor() { }

  VENDOR_SET = VENDOR_SET;

  // Enum for which menu view to display
  @Input() menuType: HIDDEN_PROFILE_MENU_TYPES = HIDDEN_PROFILE_MENU_TYPES.MAIN_MENU;
  HIDDEN_PROFILE_MENU_TYPES = HIDDEN_PROFILE_MENU_TYPES;

  // Main Menu Inputs/Outputs
  @Output() requestInfoBtnClick = new EventEmitter<any>(); // Request Info button is clicked
  @Output() shareInfoBtnClick = new EventEmitter<any>();   // Share Info button is clicked
  @Output() pickVendorBtnClick = new EventEmitter<any>();  // Pick Vendor button is clicked
  @Input() disableRequestInfoBtn: boolean = false;         // Set flag to disable Request Info button
  @Input() disableShareInfoBtn: boolean = false;           // Set flag to disable Share Info button

  // Request Info Inputs/Outputs
  @Output() requestAInfoBtnClick = new EventEmitter<any>(); // Request Info button is clicked
  @Output() requestBInfoBtnClick = new EventEmitter<any>(); // Share Info button is clicked
  @Output() returnToMenuBtnClick = new EventEmitter<any>(); // Pick Vendor button is clicked
  @Input() disableRequestAInfoBtn: boolean = false;         // Set flag to disable Tell Me About Vendor A button
  @Input() disableRequestBInfoBtn: boolean = false;         // Set flag to disable Tell Me About Vendor B button

  // Is Info Helpful Inputs/Outputs
  @Output() helpfulInfoUserResponseClick = new EventEmitter<any>(); // Helpful Info response is selected and passed back the map key
  HP_SHARED_FACTS_MAP: Map<string, HiddenProfileUserResponses> = HPSharedFacts;

  // Share Info Inputs/Outputs
  @Output() shareUserSelectedFactWithBot = new EventEmitter<any>(); // Selected vendor fact id that is to be shared with bot is sent to parent component
  @Input() shareInfoVendorAFactList: VendorFact[] = [];           // Vendor A fact list to display for Share Info page
  @Input() shareInfoVendorBFactList: VendorFact[] = [];           // Vendor B fact list to display for Share Info page

  // Pick a Vendor Inputs/Outputs
  @Output() pickVendorABtnClick = new EventEmitter<any>(); // I Want To Select Vendor A button is clicked
  @Output() pickVendorBBtnClick = new EventEmitter<any>(); // I Want To Select Vendor B button is clicked

  // Pick a Vendor Confirmation Inputs/Outputs
  @Output() confirmVendorPickBtnClick = new EventEmitter<any>();     // Yes I am Certain button is clicked
  @Output() reconsiderVendorPickBtnClick = new EventEmitter<any>(); // No, Let's Think Some More button is clicked

  // Hitting continue after process when user gains info from bot
  @Output() continueToMainMenu = new EventEmitter<any>();

  // Response key after user selects to receive information from bot
  helpfulInfoUserResponseKey = '';

  // Selected vendor Fact to share with bot
  sharedVendorFact: {factId: string, vendor: VENDOR_SET} | undefined = undefined;

  emitRequestInfoBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.REQUEST_INFO;
    this.requestInfoBtnClick.emit();
  }

  emitShareInfoBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.SHARE_INFO;
    this.shareInfoBtnClick.emit();
  }

  emitPickVendorBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.PICK_VENDOR;
    this.pickVendorBtnClick.emit();
  }

  emitRequestAInfoBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.IS_INFO_HELPFUL;
    this.requestAInfoBtnClick.emit();
  }

  emitRequestBInfoBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.IS_INFO_HELPFUL;
    this.requestBInfoBtnClick.emit();
  }

  emitReturnToMenuBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.MAIN_MENU;
    this.returnToMenuBtnClick.emit();
  }

  emitPickVendorABtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.PICK_VENDOR_CONFIRMATION;
    this.pickVendorABtnClick.emit();
  }

  emitPickVendorBBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.PICK_VENDOR_CONFIRMATION;
    this.pickVendorBBtnClick.emit();
  }

  emitConfirmVendorPickBtnClick() {
    this.confirmVendorPickBtnClick.emit();
  }

  emitReconsiderVendorPickBtnClick() {
    // Set the menu type in the parent component logic if need be
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.MAIN_MENU;
    this.reconsiderVendorPickBtnClick.emit();
  }

  // store vendor fact to be shared with bot
  saveSharedVendorFact(vendorFactId: string, vendor: VENDOR_SET) {
    this.sharedVendorFact = { factId: vendorFactId, vendor: vendor};
  }

  emitShareInfoUserResponseClick() {
    // returns the id of the VendorFact that was clicked
    this.shareUserSelectedFactWithBot.emit(this.sharedVendorFact);
    this.sharedVendorFact = undefined;
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.BLANK_MENU;
  }

  // store user's response to shared information from bot
  saveHelpfulInfoUserResponse(key: string) {
    this.helpfulInfoUserResponseKey = key;
  }

  emitHelpfulInfoUserResponseClick() {
    // returns the key of the HpSharedFact map entry that was clicked
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.BLANK_MENU;
    this.helpfulInfoUserResponseClick.emit(this.helpfulInfoUserResponseKey);
    this.helpfulInfoUserResponseKey = '';
  }

  emitGoToMainMenu() {
    this.menuType = HIDDEN_PROFILE_MENU_TYPES.MAIN_MENU;
    this.continueToMainMenu.emit();
  }

  ngOnInit() {
  }

}
