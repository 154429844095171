import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { QUESTION_SECTIONS } from '../../../types/question-types';
import { APP_COLORS } from '../../../../global-styles';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'ets-intro-v2',
  templateUrl: './intro-v2.component.html',
  styleUrls: ['./intro-v2.component.scss']
})
export class IntroV2Component extends OnBoardingV2Base implements OnInit, OnDestroy {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  questionSections = QUESTION_SECTIONS;
  triggerNext = false;
  APP_COLORS = APP_COLORS;
  numberText: string = '';
  desktopImageSrc: string = '';
  tabletImageSrc: string = ''
  mobileImageSrc: string = ''
  buttonText: string = 'Continue';
  title: string = '';
  leftBgColor: string = '';

  processQuestion = () => {
    if (this.currentQuestion && this.currentQuestion?.section) {
      switch (this.currentQuestion.section) {
        case QUESTION_SECTIONS.SECTION_ONE:
          this.desktopImageSrc = '/assets/section-1/hand-desktop.png';
          this.tabletImageSrc = '/assets/section-1/hand-tablet.png';
          this.mobileImageSrc = '/assets/section-1/hand-mobile.png';
          this.buttonText = 'BEGIN!';
          this.leftBgColor = APP_COLORS.mustardlight;
          break;
        case QUESTION_SECTIONS.SECTION_TWO:
          this.desktopImageSrc = '/assets/section-2/mirror-desktop.png';
          this.tabletImageSrc = '/assets/section-2/mirror-tablet.png';
          this.mobileImageSrc = '/assets/section-2/mirror-mobile.png';
          this.leftBgColor = APP_COLORS.aquamarinelight;
          break;
        case QUESTION_SECTIONS.SECTION_THREE:
          this.desktopImageSrc = '/assets/section-3/cheer-desktop.png';
          this.tabletImageSrc = '/assets/section-3/cheer-tablet.png';
          this.mobileImageSrc = '/assets/section-3/cheer-mobile.png';
          this.leftBgColor = APP_COLORS.lbblight;
          break;
        case QUESTION_SECTIONS.SECTION_FOUR:
          // this.desktopImageSrc = '/assets/section-4/intro-image-desktop.png';
          // this.tabletImageSrc = '/assets/section-4/intro-image-tablet.png';
          // this.mobileImageSrc = '/assets/section-4/intro-image-mobile.png';
          // this.buttonText = 'Add My Skills';
          // break;
        case QUESTION_SECTIONS.SECTION_FIVE:
          break;
        case QUESTION_SECTIONS.FACETS:
          break;
        default:
          console.log('Section not found');
      }
    }

    if (this.currentQuestion?.questionFunc) {
      if (this.userProfile?.preferredName) {
        this.title = this.currentQuestion.questionFunc(this.userProfile?.preferredName);
      }
    } else if (this.currentQuestion?.question) {
      this.title = this.currentQuestion?.question;
    }

  };

  onClick = () => {
    this.submitResponse();
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }
}
