import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StoreModule } from '@ngrx/store';
import { appStoreKey, reducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CareerInterestsComponent } from './career-interests/career-interests.component';
import { CareerSuggestionsComponent } from './career-suggestions/career-suggestions.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify, { Auth } from 'aws-amplify';
import { SharedModule } from './shared/shared.module';
import { CipModule } from './cip/cip.module';
import { SneV2Module } from './sne-v2/sne-v2.module';
import { FoundationalAssessmentModule } from './foundational-assessment/foundational-assessment.module';
import { OnBoardingV2Module } from './on-boarding-v2/on-boarding-v2.module';
import { AuthModule } from './auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import { CipEffects } from './store/cip.effects';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DashboardV2Module } from './dashboard-v2/dashboard-v2.module';
import { CareersModule } from './careers/careers.module';
import { AccountProfileModule } from './account-profile/account-profile.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { DirectivesModule } from './directives/directives.module';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: environment.userPoolID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.clientID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

// You can get the current config object
const currentConfig = Auth.configure();

@NgModule({
  declarations: [
    AppComponent,
    CareerInterestsComponent,
    CareerSuggestionsComponent,
    LandingPageComponent,
  ],
  imports: [
    AccountProfileModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    StoreModule.forRoot({[appStoreKey]: reducers}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    HttpClientModule,
    NgxChartsModule,
    AmplifyUIAngularModule,
    SharedModule,
    CipModule,
    SneV2Module,
    FoundationalAssessmentModule,
    OnBoardingV2Module,
    DashboardV2Module,
    CareersModule,
    AuthModule,
    EffectsModule.forRoot([CipEffects]),
    DirectivesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {}
