<div class="container" *ngIf="currentQuestion">
  <div class="inner">
    <h1 class="question">{{displayQuestion}}</h1>
    <h2 class="subtext" *ngIf="currentQuestion.info">{{currentQuestion.info}}</h2>
    <form class="form" [formGroup]="myForm" novalidate *ngIf="currentQuestion.profileAttribute !== 'birthDate' && currentQuestion.profileAttribute !== 'currentSalary' ">
      <div class="form-group">
        <input class="form-control"
               type="text"
               formControlName="input"
               placeholder="{{currentQuestion.placeholder}}"
               [ngClass]="{
                'has-success': myForm.controls.input.touched,
                'has-danger': myForm.controls.input.invalid && (myForm.controls.input.dirty || myForm.controls.input.touched)
              }"
        />
<!--        <button class="submit-btn" type="submit" (click)="triggerNextQuestion()" [disabled]="myForm.invalid">-->
<!--          <div-->
<!--            *ngIf="myForm.controls.input.invalid && (myForm.controls.input.dirty || myForm.controls.input.touched); else arrow">-->
<!--            <img class="submit-img" src="../../../../assets/icons/error-icon.svg" alt="Submit">-->
<!--          </div>-->
<!--          <ng-template #arrow>-->
<!--            <div *ngIf="myForm.controls.input.untouched; else blueArrow">-->
<!--              <img class="submit-img" src="../../../../assets/icons/circle-chevron-right.svg" alt="Submit">-->
<!--            </div>-->
<!--            <ng-template #blueArrow>-->
<!--              <img class="submit-img" src="../../../../assets/icons/circle-chevron-right-active.svg" alt="Submit">-->
<!--            </ng-template>-->
<!--          </ng-template>-->
<!--        </button>-->
      </div>
    </form>

    <form class="form" [formGroup]="myForm" novalidate *ngIf="currentQuestion.profileAttribute === 'currentSalary'">
      <div class="form-group">
        <input class="form-control"
               type="text"
               formControlName="input"
               placeholder="{{currentQuestion.placeholder}}"
               (keyup)="processSalaryInput($event)"
               [ngClass]="{
                'has-success': myForm.controls.input.touched,
                'has-danger': myForm.controls.input.invalid && (myForm.controls.input.dirty || myForm.controls.input.touched)
              }"
        />
      </div>
    </form>

    <form class="form" [formGroup]="myForm" *ngIf="currentQuestion.profileAttribute === 'birthDate'">
      <div class="form-group">
        <input class="form-control form-control-date"
               type="date"
               formControlName="input"
               name="input"
               required
               [ngClass]="{
                'has-success': myForm.controls.input.touched,
                'has-danger': !isValidDate() && (myForm.controls.input.touched)
              }"
        />
<!--        <button class="submit-btn" type="submit" (click)="triggerNextQuestion()" [disabled]="!isValidDate()">-->
<!--          <div>-->
<!--            <img class="submit-img" src="../../../../assets/icons/circle-chevron-right.svg" alt="Submit">-->
<!--          </div>-->
<!--        </button>-->
        <fa-icon class="date-icon" [icon]="faCalendarDay" size="lg"></fa-icon>
      </div>
    </form>
    <p class="form-control-feedback"
       *ngIf="currentQuestion.profileAttribute !== 'birthDate' && currentQuestion?.profileAttribute !== 'initialRole' && myForm.controls.input.invalid && (myForm.controls.input.dirty || myForm.controls.input.touched)">{{error}}</p>
    <button class="no-work-history-link" *ngIf="currentQuestion.page === OB_PAGES.INITIAL_ROLE" (click)="toggleModal()">
      I have no work history
    </button>

    <div class="why-we-ask-container" *ngIf="currentQuestion && currentQuestion.whyWeAskModal">
      <ets-why-we-ask-modal [whyWeAskData]="currentQuestion.whyWeAskModal"></ets-why-we-ask-modal>
    </div>

    <ets-rounded-contained-button
      class="next-btn"
      [backgroundColor]="APP_COLORS.uiaction"
      [textColor]="APP_COLORS.white"
      [buttonText]="'NEXT QUESTION'"
      [disabled]="this.currentQuestion.profileAttribute === 'birthDate' ? !isValidDate() : this.myForm.controls.input.invalid"
      (click)="triggerNextQuestion()"
    ></ets-rounded-contained-button>
  </div>
</div>


<clr-modal [(clrModalOpen)]="showModal" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-title modal-title-container">
    <h2 class="modal-title-text">Working? Not working?</h2>
    <h2 class="modal-title-text">It doesn't matter.</h2>
  </div>

  <div class="modal-body modal-body-container">
    <p class="modal-body-text">Knowing where you are in your employment journey helps us to create a tailored experience
      for our users.</p>
    <p class="modal-body-text margin-top-8">This also gives us a chance to understand the type of work you currentlly do
      and the skills that it takes to do it successfully.</p>
  </div>

  <div class="modal-footer">
    <ets-solid-button [text]="'Continue'" [color]="'#0E3AD6'" (clickEvent)="markNoHistoryAndRoute()"></ets-solid-button>
  </div>
</clr-modal>
