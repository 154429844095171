<ets-header [headerState]="headerState" (backTrigger)="back()" [isBGWhite] = 'true'></ets-header>
<div class="page-container">

  <div class="outer-title-container">
    <div class="title-container">
      <h1 class="title">Here are your career interest results, {{preferredName}}!</h1> <!-- {{userProfile?.preferredName}} -->
      <p class="subtitle">Based on your answers, we can predict the kind of career that's right for you. Then we'll help you pursue it.</p>
    </div>
  </div>

  <div class="traits-container">
    <div
      class="trait-outer-wrapper"
      *ngFor='let interest of CAREER_INTERESTS; let i = index'
    >
      <div class="trait-content">
        <div class = "trait-top-line">
          <p class="interest-name left">{{interest.title}}</p>
          <div class="progress-bar">
            <img src="/assets/cip/progress-circle.svg" alt="Circle indicating progress" class = "progress-circle" id="progress-circle" style="left: {{interest.percentage}}%">
            <label for="progress-circle" class="progress-circle-label">progress circle</label>
            <img src="/assets/cip/progress-bar.svg" alt="Empty progress bar" class = "progress-line" id="progress-line">
            <label for="progress-line" class="progress-line-label">progress line</label>
          </div>
          <p class="interest-name right">{{interest.opposite}}</p>
        </div>
        <div class = "card-bottom">
          <div>
            <h2>{{interest.description}}</h2>
            <div class="card-left-inner-wrapper">
              <div class="card-left-inner-bottom-left-wrapper">
                <h3 class="likes">WE THINK YOU WILL LIKE:</h3>
                <p class="likes-description">{{interest.likes}}</p>
              </div>
              <div class="card-left-inner-bottom-right-wrapper">
                <h3 class="dislikes">We think you will dislike:</h3>
                <p class="dislikes-description">{{interest.dislikes}}</p>
              </div>
              <!-- <img class="trait-img" src="{{interest.img}}" alt="trait image"> -->
            </div>
          </div>
          <img class="trait-img" src="{{interest.img}}" alt="trait image" id="trait-img">
        </div>
      </div>
      <!-- <hr
        class="trait-line-break"
        [ngClass]="{'trait-line-break-hide': interest.showCard}"
        *ngIf="i !== 5"
      > -->
      <div class="trait-content-mobile">
        <img class="trait-img" src="{{interest.img}}" alt="trait image">
        <div class="progress-bar">
          <img src="/assets/cip/progress-circle.svg" alt="Circle indicating progress" class = "progress-circle" style="left: {{interest.percentage}}%">
          <img src="/assets/cip/progress-bar.svg" alt="Empty progress bar" class = "progress-line">
        </div>
        <div class="trait-names-mobile">
          <p class="interest-name">{{interest.title}}</p>
          <p class="interest-name right">{{interest.opposite}}</p>
        </div>
        <div class = "card-bottom-mobile">
        <h2>{{interest.description}}</h2>
        <div class="card-left-inner-wrapper">
            <div class="card-left-inner-bottom-left-wrapper">
              <h3 class="likes">WE THINK YOU WILL LIKE:</h3>
              <p class="likes-description">{{interest.likes}}</p>
            </div>
            <div class="card-left-inner-bottom-right-wrapper">
              <h3 class="dislikes">We think you will dislike:</h3>
              <p class="dislikes-description">{{interest.dislikes}}</p>
            </div>
          </div>
        </div>
      </div>
      <hr
        class="trait-line-break"
        [ngClass]="{'trait-line-break-hide': interest.showCard}"
        *ngIf="i !== 5"
      >
    </div>

  <div class="button-wrapper mobile-hide">
    <button class="view-careers-button" [routerLink]="['/dashboard']">
      Dashboard
    </button>
  </div>

  <div class="button-wrapper non-mobile-hide">
    <ets-rounded-contained-button
      style="width: 90%; margin-bottom: 40px; position: fixed; bottom: 10px; z-index: 1000;"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'Dashboard'"
      [routerLink]="['/dashboard']"
    ></ets-rounded-contained-button>
  </div>

  <!-- <ets-footer class="footer" [progress]="'90%'"></ets-footer> -->

</div>


