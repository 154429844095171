<div class="container">
  <div class="inner">
    <div class="question-container">
      <h1 class="question">{{currentQuestion?.question}}</h1>
      <h3 *ngIf="currentQuestion?.selectAllThatApply">Select all that apply.</h3>
    </div>

    <div
      class="answers-container"
      *ngIf="currentQuestion?.page !== OB_PAGES.CRITICAL_BENEFITS"
      [ngClass]="{
                  'remote-work-cards': currentQuestion?.cardSize === CARD_SIZE_ENUM.LARGER,
                  'large-card-container': currentQuestion?.cardSize === CARD_SIZE_ENUM.LARGE,
                  'small-card-container': currentQuestion?.cardSize === CARD_SIZE_ENUM.SMALL,
                  'center-cards-container': currentQuestion?.cards?.length! <= 4
                 }"
      >
        <ets-card
          *ngFor="let card of currentQuestion?.cards; let idx = index"
          [title]="card?.title"
          [image]="card?.image"
          [description]="card?.description"
          [cardId]="idx"
          [cardSize]="currentQuestion?.cardSize"
          [selectedFlag]="card.selected"
          (isCardSelected)="cardToggle(idx)"
        >
        </ets-card>
    </div>
    <!-- Critical Benefits Question -->
    <div
      class="answers-container"
      *ngIf="currentQuestion?.page === OB_PAGES.CRITICAL_BENEFITS"
      [ngClass]="{
                  'remote-work-cards': currentQuestion?.cardSize === CARD_SIZE_ENUM.LARGER,
                  'large-card': currentQuestion?.cardSize === CARD_SIZE_ENUM.LARGE,
                  'small-card': currentQuestion?.cardSize === CARD_SIZE_ENUM.SMALL,
                  'center-cards': currentQuestion?.cards?.length! <= 4
                 }">
      <ets-card
        *ngFor="let card of cardsArray; let idx = index"
        [title]="card?.title"
        [image]="card?.image"
        [description]="card?.description"
        [cardId]="idx"
        [cardSize]="currentQuestion?.cardSize"
        [selectedFlag]="card.selected"
        (isCardSelected)="cardToggle(idx)"
      >
      </ets-card>
    </div>
    <div class="button-wrapper">
      <ets-rounded-contained-button
      *ngIf="currentQuestion?.selectAllThatApply"
      style="width: 225px; z-index: 1000;"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'NEXT QUESTION'"
      (click)="triggerNextQuestion()"
      [disabled]="disableSubmit()"
    ></ets-rounded-contained-button>
    </div>
    <ets-why-we-ask-modal *ngIf="currentQuestion && currentQuestion.whyWeAskModal" [whyWeAskData]="currentQuestion.whyWeAskModal"></ets-why-we-ask-modal>
  </div>
</div>

