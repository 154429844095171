import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static hasNumber(control: FormControl): ValidationResult | null {
    let hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      // return what´s not valid
      return { hasNumber: true };
    }
    return null;
  }

  public static hasUpper(control: FormControl): ValidationResult | null {
    let hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      // return what´s not valid
      return { hasUpper: true };
    }
    return null;
  }

  public static hasLower(control: FormControl): ValidationResult | null {
    let hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      // return what´s not valid
      return { hasLower: true };
    }
    return null;
  }

  public static hasSpecial(control: FormControl): ValidationResult | null {
    // Acceptable special characters for Cognito: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
    // ref: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
    let hasSpecial = /[\^$*.\[\]{}\(\)?"!@#%&\/\\,><':;|_~`-]/.test(control.value);
    if (!hasSpecial) {
      // return what´s not valid
      return { hasSpecial: true };
    }
    return null;
  }

  public static passwordMatchValidator(group: AbstractControl): ValidationResult | null {
    const password: string = group.get('password')?.value; // get password from our password form control
    const confirmPassword: string = group.get('confirmPassword')?.value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      return { noPasswordMatch: true };
    }
    
    return null;
  }
}
