<div class="clr-col-lg-8 clr-col-sm-12 clr-col-12">
  <div class="title">
    <span>Account Settings</span>
  </div>

  <div class="section-wrapper">
    <div class="section-title">
      <span>Account Preferences</span>
    </div>
  </div>

  <div class="option-container first-option">
    <div class="option-data-wrapper">
      <span class="option-title">Email Address</span>
      <span class="option-info">{{currentEmail}}</span>
    </div>
    <!--    Removing until func is in place-->
    <!--    <button class="btn btn-outline option-button" (click)="toggleEmailModal()">Edit</button>-->
  </div>

  <!--    Removing until func is in place-->
  <!-- <div class="option-container">
    <div class="option-data-wrapper">
      <span class="option-title">Change Password</span>
      <span class="option-info">A minimum of 8 characters, including one number or symbol, one uppercase and one lowercase letter, and no spaces.</span>
    </div>
    <button class="btn btn-outline option-button" (click)="togglePasswordChange()">Change</button>
  </div> -->

  <!-- <div class="option-container">
    <div class="option-data-wrapper">
      <span class="option-title">Fluent Languages<span class="beta">(beta)</span></span>
      <span class="option-info">Speaking other languages can open many doors. Select any languages you are fluent in, and we’ll let you know when Apprize supports them.</span>
      <clr-combobox-container >
        <clr-combobox [(ngModel)]="currentLanguages" name="multiSelect" clrMulti="true">
          <ng-container *clrOptionSelected="let selected">
            {{selected}}
          </ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let language of languages" [clrValue]="language">
              {{language}}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
    </div>
  </div>

  <div class="option-container">
    <div class="option-data-wrapper">
      <span class="option-title">Country</span>
      <span class="option-info">This is your primary location. We only support residents of the United States at the moment, but are working hard to extend our reach.</span>
      <div class="option-select-wrapper">
        <clr-select-container>
          <select clrSelect name="country-options" disabled>
            <option value="United States of America">United States of America</option>
          </select>
        </clr-select-container>
      </div>
    </div>
  </div>

  <div class="option-container">
    <div class="option-data-wrapper">
      <span class="option-title">Countries you are eligible to work in</span>
      <span class="option-info">Select any other countries you are eligable to work in and we’ll let you know when Apprize supports careers in that market.</span>
      <clr-combobox-container >
        <clr-combobox [(ngModel)]="eligibleCountries" name="multiSelect" clrMulti="true">
          <ng-container *clrOptionSelected="let selected">
            {{selected}}
          </ng-container>
          <clr-options>
            <clr-option *clrOptionItems="let country of countries" [clrValue]="country">
              {{country}}
            </clr-option>
          </clr-options>
        </clr-combobox>
      </clr-combobox-container>
    </div>
  </div> -->

  <div class="bottom-section">
    <div class="section-wrapper">
      <div class="section-title">
        <span>Deactivate Account</span>
      </div>
    </div>
    <div class="option-container first-option">
      <span class="option-info clr-col-lg-9 clr-col-12">By deleting your account, you are acknowledging that Apprize will delete all user data. You will lose any of your saved career interests and skill assessments.</span>
      <div class="clr-col-lg-3 clr-col-12">
        <ets-outline-button [text]="'Delete My Account'" [color]="APP_COLORS.uidanger" (clickEvent)="toggleDeleteModal()"></ets-outline-button>
      </div>
    </div>
  </div>

  <div class="button-wrapper mobile-hide">
    <ets-rounded-contained-button
      (clickEvent)="toggleConfirmSaveModal()"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'Save Changes'"
    >
    </ets-rounded-contained-button>
  </div>

  <div class="button-wrapper non-mobile-hide">
    <ets-rounded-contained-button
      [fullWidth]="true"
      (clickEvent)="toggleConfirmSaveModal()"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'Save Changes'"
    ></ets-rounded-contained-button>
  </div>

</div>


<div class="modal-container" *ngIf="showEmailModal"
[class.has-error]="email.invalid && email.touched"
[class.has-success]="email.valid">
  <div class="modal-box">
    <div class="modal-form-wrapper">
      <label for="email" class="modal-label">New email address</label>
      <input type="text" name="email" required email id="email" class="modal-input" #email="ngModel" [(ngModel)]="currentEmail"/>
      <span class="invalid" *ngIf="email.errors?.required && email.touched">
        Email address is required
      </span>
      <span class="invalid" *ngIf="email.errors?.email && email.touched">
        Email address is not valid.
      </span>
    </div>
    <div class="modal-button-wrapper" >
      <button class="submit-button margin-right-16" (click)="confirmEmailChange()" [disabled]="email.touched && (email.errors?.required || email.errors?.email)">
        Send Confirmation
      </button>
      <button class="cancel-button" (click)="cancelEmailChange()">
        Cancel
      </button>
    </div>
  </div>
</div>

<div class="modal-container" *ngIf="showPasswordModal">
  <div class="modal-box">
    <form [formGroup]="passwordForm" (ngSubmit)="confirmPasswordChange()">
      <div class="modal-form-wrapper">
        <label for="old-password" class="modal-label">Old Password</label>
        <div class="password-wrapper">
          <input [type]="togglePasswordInput ? 'text' : 'password'"
                 formControlName="oldPassword"
                 name="old-password"
                 id="old-password"
                 class="modal-input"
          />
          <button class="icon-button" type="button" (click)="toggleShowPassword('email')">
            <fa-icon [icon]="faEye"></fa-icon>
          </button>
        </div>
      </div>
      <div class="modal-form-wrapper margin-top-32">
        <label for="new-password" class="modal-label">New Password</label>
        <input type="password"
               name="email"
               id="new-password"
               formControlName="newPassword"
               class="modal-input"
        />
        <span class="password-blurb">
          A minimum of 8 characters, with one number or symbol, one uppercase and one lowercase letter, and no spaces.
        </span>
      </div>
      <div class="modal-form-wrapper margin-top-32">
        <label for="confirm-password" class="modal-label">Confirm Password</label>
        <div class="password-wrapper">
          <input type="password" name="email" id="confirm-password" formControlName="confirmPassword"
                 [ngClass]="{'modal-password-confirm-success': oldPasswordMatch && newPasswordMatch,
                    'modal-password-confirm-error': passwordForm.controls.confirmPassword.touched && !(oldPasswordMatch && newPasswordMatch)
                 }"
                 class="modal-input modal-password-confirm-input"/>
          <fa-icon *ngIf="oldPasswordMatch && newPasswordMatch" [icon]="faCheckCircle" class="icon success-icon"></fa-icon>
          <fa-icon *ngIf="passwordForm.controls.confirmPassword.touched && !(oldPasswordMatch && newPasswordMatch)"
                   [icon]="faTimesCircle"
                   class="icon error-icon">
          </fa-icon>
        </div>
      </div>
      <div class="modal-button-wrapper">
        <button class="submit-button margin-right-16" type="submit" [disabled]="!(oldPasswordMatch && newPasswordMatch)">
          Change
        </button>
        <button class="cancel-button" (click)="cancelPasswordChange()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal-container" *ngIf="showConfirmSaveModal">
  <div class="modal-box">
    <div class="modal-wrapper">
      <span class="modal-title">Do you want to save these changes before you leave?</span>
      <div class="modal-button-wrapper">
        <button class="submit-button margin-right-16" type="button" (click)="toggleConfirmSaveModal(); saveChanges()">
          Save Changes
        </button>
        <button class="cancel-button" (click)="toggleConfirmSaveModal()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Delete Account Modal -->
<clr-modal [(clrModalOpen)]="showDeleteModal">
  <h3 class="modal-title">This is goodbye</h3>
  <div class="modal-body">
    <p>
      We would hate to see you go... but love to watch you leave with more confidence in your career journey. If you decide to delete your account, understand that this is irreversible and all your data will be lost.
    </p>
  </div>
  <div class="modal-footer">
    <ets-outline-button [text]="'Delete My Account'" [color]="APP_COLORS.uidanger" (clickEvent)="deleteAccount()"></ets-outline-button>
  </div>
</clr-modal>
