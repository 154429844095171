<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="true"
>
</ets-header>


<div class= "container">
    <ets-multi-video-choice-video
      [videoSetId]="'1'"
      [question]="questionData"
      [splitScreen]="questionData?.splitScreen"
      [videos]="questionData?.videos"
      (userResponseEvent)="handleUserResponse($event)"
    ></ets-multi-video-choice-video>
</div>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  [drawerActive]="false"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
    : multiResponse.length !== questionData?.numSelectOptions"
>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  [info]="instructionModal?.customContent[0].info"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video 
      *ngIf="instructionModal?.customContent[0].url"
      [videoUrl]="instructionModal?.customContent[0].url"
      [videoId]="instructionModal?.customContent[0].id"
      [videoOnlyFlag]="true"
      [playOnce]="instructionModal?.customContent[0].playOnce"
      [sharedVideo]="instructionModal?.customContent[0].sharedVideo"
    >
    </ets-sne-video>
  </div>
</ets-modal>

