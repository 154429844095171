import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventActionEnum, EventObj, EventSectionEnum } from '../types/event';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService {

  private eventURL = environment.eventAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Event';
  }

  // Method to decode/parse the username from the auth token
  protected getUsernameFromToken(token:string) {
    if (!token) {
      return 'unknown';
    }

    try {
      const s = token.split('.');
      if (s.length === 3) {
        let text1 = atob(s[1]);
        let user = JSON.parse(text1);
        if (user) {
          if (user.username) {
            return user.username;
          } else {
            return user['cognito:username'];
          }

        }
      } else {
        console.log('Invalid auth token format');
      }

      return 'unknown';
    } catch (err) {
      console.log(err);
      return 'unknown';
    }
  }

  trackEvent(eventObj: EventObj) {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      // let headers = new HttpHeaders().set('Authorization', accessToken).set('Content-Type','application/json');
      let headers = new HttpHeaders().set('Authorization', accessToken);
      eventObj['username'] = this.getUsernameFromToken(accessToken);
      eventObj['browserTimestamp'] = new Date();
      eventObj['userAgent'] = navigator.userAgent;
      eventObj['page'] = window.location.href;

      let requestLogObj: LogObject = {
        message: 'EVENT: Post Event Request',
        object: eventObj
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      // console.log('calling ' + this.eventURL + ' with obj', eventObj,headers);

      return this.http.post<any>(this.eventURL, eventObj, {headers}).subscribe(data => {
        console.log('event response', data);
      });
    } else {
      let requestLogObj: LogObject = {
        message: 'EVENT: No user access token',
      };
      this.logService.logError(requestLogObj)?.subscribe();
    }
    return;
  }

  // Method to build the event object... the object param is optional
  buildEvent(message: string, action: EventActionEnum, section?: EventSectionEnum, object?: any) {
    let eventObj: EventObj = {
      message: message,
      action: action
    };
    if (section) {
      eventObj.section = section;
    }

    if (object) {
      eventObj.object = object;
    }

    // Track the event in AWS
    this.trackEvent(eventObj);
  }
}
