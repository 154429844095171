import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QUESTION_SECTIONS } from 'src/app/types/question-types';
import { APP_COLORS } from '../../../global-styles';


@Component({
  selector: 'ets-split-screen-intro',
  templateUrl: './split-screen-intro.component.html',
  styleUrls: ['./split-screen-intro.component.scss']
})
export class SplitScreenIntroComponent {

  constructor() { }
  APP_COLORS = APP_COLORS;
  QUESTION_SECTIONS = QUESTION_SECTIONS;

  @Input() numberText: string | undefined = '';
  @Input() title: string | undefined  = '';
  @Input() info: string | undefined  = '';
  @Input() buttonText: string | undefined  = '';
  @Input() buttonColor: string | undefined  = '';
  @Input() mobileButtonSize: string = '';
  @Input() desktopImageSrc: string | undefined  = '';
  @Input() tabletImageSrc: string | undefined  = '';
  @Input() mobileImageSrc: string | undefined  = '';
  @Input() isRoundedButton: boolean = false;
  @Input() questionSection?: QUESTION_SECTIONS;
  @Input() leftBgColor?: string;
  @Input() fullSectionBgImage = false; // if true, the image will have a height/width of 100%
    
  @Output() clickEvent = new EventEmitter<any>();
  @Output() skipToSkillInput = new EventEmitter<any>();

  onClick = () => {
    this.clickEvent.emit();
  };

  skipSection = () => {
    this.skipToSkillInput.emit();
  };

}
