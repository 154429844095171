import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faEye } from '@fortawesome/free-solid-svg-icons';
// import { LearnerCareerService } from '../../services/learner-career.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { setProfile } from '../../store/profile.actions';
import { APP_COLORS } from 'src/global-styles';
import { setEmail, setOpenSesame } from 'src/app/store/auth.actions';
import { AUTH_SESSION_STORAGE_KEYS } from '../auth.helpers';

@Component({
  selector: 'ets-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  title = 'ets-project';
  user: CognitoUserInterface | undefined;
  authState!: AuthState;
  faEye = faEye;
  APP_COLORS = APP_COLORS;

  // Flag to denote whether the user is signed in
  userSignedInFlag = false;

  // Error state flags used to display error messages on the UI
  signIn_incorrectUsernameOrPasswordFlag = false;
  signIn_unconfirmedAccountFlag = false;
  signIn_unknownErrorFlag = false;
  signIn_disableSubmitFlag = false;

  // Flags to display various elements on the UI
  signingInSpinnerFlag = false;

  // Form for the sign in page
  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  // These fields are used to transfer the email/password fields between forms/methods
  email!: string;
  password!: string;

  // constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService, private learnerCareerService: LearnerCareerService) {}
  constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.userSignedInFlag = true;
    }

    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  getProfileAndRoute() {
    this.profileService.getProfile().subscribe(profile => {
      this.store.dispatch(setProfile({payload: profile}));

      // if the user was originally heading to another url then proceed to that url
      // otherwise head to the dashboard url and have the guards redirect if user is not allowed
      const originalRoute = sessionStorage.getItem(AUTH_SESSION_STORAGE_KEYS.ORIGINAL_ROUTE);
      if (originalRoute) {
        this.router.navigate([originalRoute]).then(() => {
          sessionStorage.removeItem(AUTH_SESSION_STORAGE_KEYS.ORIGINAL_ROUTE);
        });
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  async signIn() {
    try {
      this.resetUiErrorFlags();
      this.email = this.signInForm.controls.email.value;
      this.password = this.signInForm.controls.password.value;

      // Call Sign In with the values from the sign in form - await is needed to ensure the call completes successfully prior to
      // setting the userSignedInFlag
      this.signIn_disableSubmitFlag = true; // setting this to prevent users from clicking the button more than once
      this.signingInSpinnerFlag = true; // setting this to display the loading spinner
      await this.authService.signIn(this.email, this.password);
      this.signingInSpinnerFlag = false;
      // user is signed in, route to the rest of the app
      this.userSignedInFlag = true;
      this.getProfileAndRoute();
    } catch (error) {
      if (error instanceof Error) {
        this.signingInSpinnerFlag = false; // hide the spinner
        this.signIn_disableSubmitFlag = false; // reset this flag once the call would've returned

        // making this a switch statement incase we want to add support for other Sign In errors
        switch (error.message) {
          case 'Incorrect username or password.':
          case 'UserMigration failed with error Incorrect username or password..':
            this.signIn_incorrectUsernameOrPasswordFlag = true;
            break;
          case 'User is not confirmed.': // user hasn't confirmed their email yet
            // Put email/password in store so the confirmation page can get it
            this.store.dispatch(setEmail({ payload: this.signInForm.controls.email.value }));
            this.store.dispatch(setOpenSesame({ payload: this.signInForm.controls.password.value }));
            this.signIn_unconfirmedAccountFlag = true;
            break;
          default:
            this.signIn_unknownErrorFlag = true;
        }
      }
    }
  }

  // Method to go to sign up page
  gotoSignUp() {
    this.router.navigate(['/auth/sign-up']);
  }

  // Method to go to confirm account
  gotoConfirmAccount() {
    // Call resend to send the code email prior to going to the confirmation page
    this.authService.resendConfirmationCode(this.email);
    this.router.navigate(['/auth/confirmation-code']);
  }

  // Method to toggle the reset password
  gotoResetPassword() {
    this.router.navigate(['/auth/reset-password']);
  }

  // Method to show the passwords being typed in the password fields
  toggleShowPassword(id: string) {
    // Casting to an input element because this method will only be called from input elements
    let inputField = <HTMLInputElement>document.getElementById(id);
    if (inputField) {
      if (inputField.type === 'password') {
        inputField.type = 'text';
      } else {
        inputField.type = 'password';
      }

    }
  }

  // Method to reset UI error flags to false (hide them)
  resetUiErrorFlags() {
    this.signIn_incorrectUsernameOrPasswordFlag = false;
    this.signIn_unconfirmedAccountFlag = false;
    this.signIn_unknownErrorFlag = false;
  }
}
