import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-loading-modal',
  template: `
    <div *ngIf='!showModal' class="modal">
      <div class="modal-dialog fadeDown in no-scrolling" role="dialog" tabindex="-1" aria-modal="true" aria-hidden="false" aria-labelledby="clr-title">
        <div class="modal-content">
            <h3 class="modal-title" id="clr-title">
              Your skills are in, but sit tight! <br> <br>
              It may take us a minute to organize everything for you.
            </h3>
          <ets-spinner
            [width]="'86px'"
            [height]="'86px'"
            [margin]="'48px 80px'"
          >
          </ets-spinner>
        </div>
      </div>
    </div>
    <div *ngIf='!showModal' class="modal-backdrop" aria-hidden="true"></div>
  `,
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent {
  @Input() showModal = false;

}
