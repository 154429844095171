import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AssessmentService } from 'src/app/services/assessment.service';
import { EventService } from 'src/app/services/event.service';
import { ProfileService } from 'src/app/services/profile.service';
import { selectProfile } from 'src/app/store';
import { setProfile } from 'src/app/store/profile.actions';
import { Assessment } from 'src/app/types/assessments';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';
import { FacetsAssessmentData, FacetsRequestBody } from 'src/app/types/facets';
import Profile from 'src/app/types/profile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ets-quiz-results',
  templateUrl: './quiz-results.component.html',
  styleUrls: ['./quiz-results.component.scss']
})
export class QuizResultsComponent implements OnInit, OnDestroy {

  constructor(private store: Store, private router: Router, private profileService: ProfileService, private assessmentService: AssessmentService, private eventService: EventService) { }

  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];
  userProfile!: Profile;
  assessments: Assessment[] = [];
  disableFacetsRetakeBtn: boolean = false; // Flag to disable the retake button if they're already an in-progress Facets quiz
  gettingAssessmentData: boolean = false; // Flag to display the spinner while the page is loading


  // Gets existing assessments for the current user
  getAssessments() {
    let getAssessmentRequest: FacetsRequestBody = {
      action: 'getAssessment'
    };

    this.gettingAssessmentData = true;
    this.subArr.push(this.assessmentService.getAssessments().subscribe({
      next: response => {
        this.assessments = response;
        this.processGetAssessmentsResponse();
        this.gettingAssessmentData = false;
      },
      error: err => {
        // error handling
        this.gettingAssessmentData = false;
      }
    }));
  }

  processGetAssessmentsResponse() {
    this.assessments.forEach(assessment => {
      // Change the displayed title of the assessments to make them user friendly
      if (assessment.assessmentID === 1) {
        assessment.assessmentTitle = 'Career Interest Quiz';
      } else if (assessment.assessmentID === 2) {
        assessment.assessmentTitle = 'Get to Know Yourself Quiz';

        // Disable the Retake button on the Facets cards if there's an incomplete Facets quiz
        if (!assessment.completed) {
          this.disableFacetsRetakeBtn = true;
        }
        
      }
    });
  }

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
      } else {
        this.subArr.push(this.profileService.getProfile().subscribe(profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.userProfile = profile;
        }));
      }
    });
  }

  viewResults(assessment: Assessment) {
    if (assessment.assessmentID === 1) { // CIP
      // Track the event in AWS
      this.eventService.buildEvent('View Results button - CIP', EventActionEnum.CLICK);
      this.router.navigate(['/career-interests']); // route to CIP results
    } else if (assessment.assessmentID === 2) { // Facets
      if (assessment.sessionID) {
        localStorage.setItem('facetsSessionId', assessment.sessionID);
      } // set/replace the session ID if it exists
      
      // Track the event in AWS
      this.eventService.buildEvent('View Results button - Facets', EventActionEnum.CLICK);
      this.router.navigate(['/foundational-assessment/power-skills-personality-results']); // route to Facets results
    }
  }

  retakeOrResumeAssessment(assessment: Assessment) {
    // Putting logic in place for once other assessments are available/able to be retaken
    if (assessment.assessmentID === 2) { // Facets quiz
      // If it's completed, that means "Retake" was clicked, otherwise "Resume" was clicked
      if (assessment.completed) {
        // Track the event in AWS
        this.eventService.buildEvent('Retake button - Facets', EventActionEnum.CLICK);
        localStorage.removeItem('facetsSessionId'); // remove the session ID if it exists
      } else { // Resume the quiz
        if (assessment.sessionID) {
          // Track the event in AWS
          this.eventService.buildEvent('Resume button - Facets', EventActionEnum.CLICK);
          localStorage.setItem('facetsSessionId', assessment.sessionID); // set/replace the session ID if it exists
        }
      }
      // route to Facets intro page
      this.router.navigate(['/foundational-assessment/power-skills-intro']);
    }
  }

  ngOnInit() {
    document.title = 'Apprize | User Profile';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Quiz Results',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Quiz Results', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Quiz Results', EventActionEnum.PAGE_VIEW, EventSectionEnum.USER_PROFILE);
    this.getProfile();
    this.getAssessments();
  }

  ngOnDestroy() {
    this.subArr.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
