<ets-header [isBGWhite]="true"
            [headerState]="headerState"
            [showBackButton]="false"
            [simpleBack]="true"
>
</ets-header>

<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="left">
    <ets-sne-conversation-log-text
      [chatLog]="chatList"
      [cpsCheck]="true"
      [botProfilePic]="questionData?.botProfilePic"
      [youProfilePic]="questionData?.youProfilePic"
    >

    </ets-sne-conversation-log-text>
  </div>

  <div class="right">
    <ets-client-ranking-stars
      [questionData]="negotiationQuestionData"
      [jordanStars]="jordanStars"
      [clientStars]="clientStars"
      [StarStatus]="StarStatus"
    >
    </ets-client-ranking-stars>

    <div class="border"></div>

    <ets-sne-multi-choice-list
      [mulChoiceOptions]="[backEndQuestion]"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [inputType]="questionData?.inputType"
      (answerIndex)="answerIndex"
    >

    </ets-sne-multi-choice-list>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  [isButtonDisabled]="!nextBtnEnabled"
  (nextEmitter)="!this.goToEnd ? updateAnswer(answerIndex!): endTask()"
  (overviewEmitter)="openInstructionModal()"
  (drawerOpenEmitter)="toggleDrawerVisible()"
  [drawerVisible]="drawerVisible"
  [drawerActive]="true"
  [buttonText]="questionData?.buttonText"
>
  <ets-client-ranking-stars
    [questionData]="negotiationQuestionData"
    [jordanStars]="jordanStars"
    [clientStars]="clientStars"
    [StarStatus]="StarStatus"
  >
  </ets-client-ranking-stars>

  <div class="border"></div>

  <ets-sne-multi-choice-list
    [mulChoiceOptions]="[backEndQuestion]"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    (selectMultiResponseEvent)="multiResponseHandler($event)"
    [inputType]="questionData?.inputType"
    (answerIndex)="answerIndex"
    [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>
  
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
  (currentPageOutput)="updateCurrentPage($event)"
>
  <div class="client-ranking-modal-container" *ngIf="instructionModal?.customContent && instructionModal?.customContent[0].negotiation" contentID="customContent">
    <ets-client-ranking-stars *ngIf="currentModalPage === 2" [jordanStars]="jordanStars" [clientStars]="clientStars" [StarStatus]="StarStatus" [questionData]="negotiationQuestionData"></ets-client-ranking-stars>
    <div class="incentives-available-container" *ngIf="currentModalPage === 1" contentID="customContent">
      <div class="content-box">
        <div class="content-1">
          <img class="line-photo" src="../../../assets/icons/ticket.svg" alt="Ticket photo" id="ticket-photo">
          <span class="line-text">50 free tickets to a sold-out concert</span>
        </div>
        <div class="content-2">
          <img class="line-photo" src="../../../assets/icons/hand.svg" alt="Hand photo" id="hand-photo">
          <span class="line-text">A meet and greet for 10 people with the artists performing at the sold-out concert</span>
        </div>
        <div class="content-3">
          <img class="line-photo" src="../../../assets/icons/basketball.svg" alt="basketball photo" id="basketball-photo">
          <span class="line-text">2 season tickets to home games for the city's basketball team</span>
        </div>
        <div class="content-4">
          <img class="line-photo" src="../../../assets/icons/outdoor_grill.svg" alt="Grill photo" id="grill-photo">
          <span class="line-text">A picnic catered by the food service providers that sell concessions at your events</span>
        </div>
      </div>
    </div>
  </div>
</ets-modal>
