import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from 'src/app/services/profile.service';
import Profile from 'src/app/types/profile';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'ets-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends OnBoardingV2Base implements OnInit, OnDestroy {

  @Input() salaryRange: string[] |undefined;

  selectedOption: string | undefined;

  value: number = 55;
  options: Options = {
    floor: 40,
    ceil: 90,
    tickStep: 10,
    showTicks: true,
    showSelectionBarEnd: true,
    showTicksValues: true,
    translate: (value: number, label: LabelType): string => {
      if(label === LabelType.TickValue){
        if(value === 90){
          return '$'+value+'K+';
        } else{
          return '$'+value+'K';
        }
      }
      return '$'+value+'K';
    }
  };

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
    protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }


  triggerNextQuestion() {
    // Single response
    if (this.value) {
      this.userResponse = '$' +this.value+'K';
      this.submitResponse();
    }
  }

  processQuestion() {
    if (this.currentQuestion && this.userProfile) {
      const key = this.currentQuestion?.profileAttribute as keyof Profile;
      if (this.userProfile && this.userProfile[key]) {
        this.selectedOption = this.userProfile[key] as string;
      }
    }
  };

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }

}
