import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[etsFirstHover]'
})

export class ShowRemoveDirective {

  @Input() etsFirstHover: {value: boolean} = {value: false};
  @Input() isFollowing = false;

  constructor() {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.isFollowing) {
      this.etsFirstHover.value = true;
    }
    
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.etsFirstHover.value = false;
  }
}


