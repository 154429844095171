import { Directive, ElementRef, OnInit, AfterViewChecked } from '@angular/core';

@Directive({
  selector: '[etsScrollToBottom]'
})

export class ScrollToBottomDirective implements OnInit, AfterViewChecked {

  element: ElementRef

  constructor(private el: ElementRef) {
    this.element = el;
  }
  
  ngOnInit() {
    this.scrollToBottom();
  }
   
  ngAfterViewChecked() {        
    this.scrollToBottom();        
  } 
  scrollToBottom() {
    try {
      this.element.nativeElement.scrollTop = this.element.nativeElement.scrollHeight;
    } catch (err) { 
      console.log(err); 
    }
  }

}
