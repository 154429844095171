import { SneQuestion } from '../../types/sne';
import CPS_TYPES from '../../types/cps-types';
import QUESTION_TYPES from '../../types/question-types';

export function generateNextQuestionPath(question: SneQuestion, key: string, cpsType: CPS_TYPES, additionalNextQuestionSteps: () => void) {
  let path = '';
  if (question) {
    switch (question.questionType) {
      case QUESTION_TYPES.SNE_INTRO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/sne-intro/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.INTERSTITIAL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/interstitial/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MENU_FACT_LIST:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/menu-fact/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.HIDDEN_PROFILE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/hidden-profile/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.MULTI_MULTIPLE_CHOICE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/multi-multiple-choice/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.MULTI_CHOICE_DROPDOWN:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/cps/multi-choice-dropdown/${key}/${cpsType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_CLIENT_RANKING_CHAT_HISTORY:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path =`sne-new/cps/client-ranking-chat-history/${key}/${cpsType}`;
        break;
      default:
        console.error('Cannot find question type');
    }
  }

  return path;
}
