import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ets-auth-split-screen',
  templateUrl: './auth-split-screen.component.html',
  styleUrls: ['./auth-split-screen.component.scss']
})
export class AuthSplitScreenComponent implements OnInit {

  @Input() leftBackgroundColor: string | undefined;
  @Input() rightBackgroundColor: string | undefined;

  constructor() { }

  ngOnInit() {
  }

}
