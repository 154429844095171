import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { QUESTION_SECTIONS } from '../../types/question-types';
import { APP_COLORS } from '../../../global-styles';
import { OnBoardingV2Base } from '../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { selectProfile } from '../../store';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'ets-work-history-start',
  templateUrl: './work-history-start.component.html',
  styleUrls: ['./work-history-start.component.scss']
})
export class WorkHistoryStartComponent extends OnBoardingV2Base implements OnInit {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  APP_COLORS = APP_COLORS;
  question: string = '';
  info = 'info';
  duration = 'duration';
  QUESTION_SECTIONS = QUESTION_SECTIONS;

  onClick = () => {
    this.submitResponse();
  }

  processTitleAndInfo = () => {
    this.store.select(selectProfile).subscribe(profile => {
      if (this.currentQuestion && profile) {
        if (this.currentQuestion?.questionFunc && profile?.preferredName) {
          this.question = this.currentQuestion.questionFunc(profile.preferredName);
        } else if (this.currentQuestion?.question) {
          this.question = this.currentQuestion.question;
        }
      }

      this.currentQuestion?.info ? this.info = this.currentQuestion?.info : '';
      this.currentQuestion?.duration ? this.duration ='<span class="convertToBold">Duration</span>: ' + this.currentQuestion?.duration: '';
    });
  }

  ngOnInit(): void {
    this.initialize();
    this.processTitleAndInfo();
  }

}
