import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ets-sne-report-header',
  templateUrl: './sne-report-header.component.html',
  styleUrls: ['./sne-report-header.component.scss']
})
export class SneReportHeaderComponent implements OnInit, OnChanges {

  constructor() {}

  sneModulesMap = new Map([
    ["CPS", {
      humanReadableName: "Collaborative Problem Solving",
      vehicleImg: "../../../../assets/sne/sne-report/vehicles/cps-vehicle.png",
    }],
    ["CRT", {
      humanReadableName: "Critical Thinking",
      vehicleImg: "../../../../assets/sne/sne-report/vehicles/ct-vehicle.png"
    }],
    ["CCH", {
      humanReadableName: "Coachability",
      vehicleImg: "../../../../assets/sne/sne-report/vehicles/cch-vehicle.png"
    }],
    ["ORC", {
      humanReadableName: "Oral Communication",
      vehicleImg: "../../../../assets/sne/sne-report/vehicles/orc-vehicle.png"
    }],
    ["WRC", {
      humanReadableName: "Written Communication",
      vehicleImg: "../../../../assets/sne/sne-report/vehicles/wrc-vehicle.png"
    }],
  ]);

  @Input() module: string = "";
  @Input() name: string = "";
  @Input() numScore: number = 0; // score will come in as a decimal
  @Input() stringScore: string = "";
  roundedScore!: number;
  desktopVehiclePercent!: number;
  tabletVehiclePercent!: number;
  mobileVehiclePercent!: number;
  progressArray = Array(11); // empty array of size 11 used to iterate thru in template
  moduleInfo: any | undefined;

  ngOnInit(): void {
    this.moduleInfo = this.getModuleInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {  
      let change = changes[propName];
      let curVal  = change.currentValue;

      if (propName === 'numScore') {
        this.numScore = curVal;
        this.numScore *= 100; // turn it into a percentage out of 100
        this.roundedScore = Math.round(this.numScore / 10) * 10;
    
        // Dividing 100 by 11 because there's 11 segments in the progress bar taking up 100% up the space
        // -6 for desktop, -18 for tablet, -33 for mobile (determined these values by hand)
        this.desktopVehiclePercent = ((this.roundedScore / 10) * (100 / 11) - 6);
        this.tabletVehiclePercent = ((this.roundedScore / 10) * (100 / 11) - 18);
        this.mobileVehiclePercent = ((this.roundedScore / 10) * (100 / 11) - 33);
      }
    }
  }

  getModuleInfo(): any | undefined {
    return this.sneModulesMap.get(this.module);
  }

}
