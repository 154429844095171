import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from '../../../../global-styles';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { answerMap, surveyAnswers} from 'src/app/data/sne/oral-communication/01_Informative-1';
import { SneAnswerService } from '../../../services/sne-answer.service';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';

@Component({
  selector: 'ets-oc-videos-and-multi-choice',
  templateUrl: './oc-videos-and-multi-choice.component.html',
  styleUrls: ['./oc-videos-and-multi-choice.component.scss']
})
export class OcVideosAndMultiChoiceComponent extends SneOralCommunicationBase implements OnInit {
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  numAnswers: number = 0;
  surveyOptions?: string[] = surveyAnswers;
  answersMap: Map<string, string> = answerMap;

  selectAnswer(questionIndex: number, answer: string) {
    this.multiResponse[questionIndex] = answer;
  }
  singleResponseHandler(answer: string) {
    this.singleResponse = answer;
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
      this.multiResponse.sort();
    }

  }

  ngOnInit(): void {
    this.processParams();
  }

}
