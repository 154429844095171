import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ets-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  constructor() { }

  @Input() name: string | undefined;
  @Input() value: string | undefined;
  @Input() id: string | undefined;
  @Input() label: string | undefined;
  @Input() checked: boolean = false;
  @Input() disable: boolean | undefined;
  @Output() clickEvent = new EventEmitter<any>();

  onClick = () => {
    this.checked = !this.checked;
    this.clickEvent.emit();
  };

}
