import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import WC_TYPES from 'src/app/types/written-communication-types';

export const WCSet1Data: Map<string, SneQuestion> = new Map([
  [
    'intro',
    {
      questionType: QUESTION_TYPES.SNE_INTRO,
      title: 'Written Communication',
      info: 'In this section, we will guide you through three different tasks. These tasks will help you understand how you write content, how you analyze other people\'s writing, and how your approach to writing applies to the careers that interest you.',
      image: '/assets/sne/wc/wc-intro.png',
      buttonText: 'BEGIN!',
    }
  ],
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Improving a Company Memo',
      info: 'This task will give you insight into how you analyze someone else\'s writing, and we will use what we learn together to enhance your Skills Profile.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you\'ll have the chance to pause when you finish.',
      questionIndex: 1,
      moduleSize: 3,
      image:'/assets/section-5/sne-interstitial/written-com.svg',
      buttonText: 'NEXT',
      modalBeforeNext: true,
      durationBoxColor: 'lbb',
    },
  ],
  ['question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Which of the following best describes the purpose of sentences 1 and 2 (<span class="highlighted">highlighted</span>)?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '<span class="highlighted">(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring.</span> (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'To show the president\'s credibility by implying that she stays current on industry publications',
          userLetter: 'A'
        },
        {
          userOption: 'To summarize the main claim that the writer develops in the memo',
          userLetter: 'B'
        },
        {
          userOption: 'To provide the context for key ideas that the writer discusses in the memo',
          userLetter: 'C'
        },
        {
          userOption: 'To lend support for Grateford’s views on safety in the industrial flooring industry',
          userLetter: 'D'
        },
      ],
      submitId: 'item_1'
    }],
  ['question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'The memo writer, Beth Sharpe, begins sentence 5 with the <span class="highlighted">highlighted</span> phrase most likely in order to',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers\' joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. <span class="highlighted">(5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry.</span> (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'show awareness of a possible objection to the memo’s main idea',
          userLetter: 'A'
        },
        {
          userOption: 'support the claims made at the start of the memo',
          userLetter: 'B'
        },
        {
          userOption: 'suggest that she has higher standing in the industry than former CEO Grateford',
          userLetter: 'C'
        },
        {
          userOption: 'demonstrate a willingness to entertain alternative viewpoints',
          userLetter: 'D'
        },
      ],
      submitId: 'item_2'
    }],
  ['question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'President Sharpe is considering removing the <span class="highlighted">highlighted</span> phrase from sentence 1. Should the president make the change? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, <span class="highlighted">a key trade publication in our industry.</span> (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Yes, because some readers may be offended that it assumes that they are unfamiliar with the publication.',
          userLetter: 'A'
        },
        {
          userOption: 'Yes, because it reduces the reading load of the memo.',
          userLetter: 'B'
        },
        {
          userOption: 'No, because it may make the publishers of Work Safe Digest view the memo more favorably.',
          userLetter: 'C'
        },
        {
          userOption: 'No, because it provides a detail that lends credibility to the claims about safety flooring.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_3'
    }],
  ['question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Which of the following is the best version of the <span class="highlighted">highlighted</span> portion of sentence 2?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, <span class="highlighted">Grateford staked the claim that</span> US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Grateford staked the claim that (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'Grateford explained that',
          userLetter: 'B'
        },
        {
          userOption: 'the author exclaimed that',
          userLetter: 'C'
        },
        {
          userOption: 'former CEO Grateford weighed in that',
          userLetter: 'D'
        },
      ],
      submitId: 'item_4'
    }],
  ['question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'In context, where should sentence 3 (<span class="highlighted">highlighted</span>) most logically be placed?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. <span class="highlighted">(3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones.</span> (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Where it is now (no change)',
          userLetter: 'A'
        },
        {
          userOption: 'Immediately before sentence 1',
          userLetter: 'B'
        },
        {
          userOption: 'Immediately after sentence 4',
          userLetter: 'C'
        },
        {
          userOption: 'Immediately after sentence 5',
          userLetter: 'D'
        },
      ],
      submitId: 'item_5'
    }],
  ['question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'President Sharpe is considering replacing the <span class="highlighted">highlighted</span> phrase in sentence 4 with “approximately.” Should the president make the change? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours <span class="highlighted">in the neighborhood of</span> $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Yes, because it provides an excuse in case the dollar figure is incorrect.',
          userLetter: 'A'
        },
        {
          userOption: 'Yes, because it better fits the style of the rest of the memo.',
          userLetter: 'B'
        },
        {
          userOption: 'No, because “approximately” may be a less familiar word.',
          userLetter: 'C'
        },
        {
          userOption: 'No, because the current phrase provides context for the comparison of companies.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_6'
    }],
  ['question-7',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Which of the following is the best version of the <span class="highlighted">highlighted</span> portion of sentence 5 (<span class="highlighted">highlighted</span> in the memo)?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers’ joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate <span class="highlighted">predictions of where things are headed in the work safety industry.</span> (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'predictions of where things are headed in the work safety industry. (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'foresights of a bright future for the work safety industry.',
          userLetter: 'B'
        },
        {
          userOption: 'predictions about the work safety industry.',
          userLetter: 'C'
        },
        {
          userOption: 'bets on investments that pay off for the work safety industry.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_7'
    }],
  ['question-8',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'For which of the following audiences is the passage <span class="convertToBlue">least</span> likely intended?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Low-Impact Flooring Study',
      memo: '(1) Last autumn, Bill Grateford, former CEO of Redding Structurals, published an editorial in Work Safe Digest, a key trade publication in our industry. (2) In the editorial, Grateford staked the claim that US companies have fallen behind Asian companies in the installation of safer work space flooring. (3) An important development in making safer flooring, Grateford argued, is the improvement of low-impact technologies that protect workers\' joints, spines, and other bones. (4) The adoption of the new kind of flooring, Grateford stated, stands to save companies like ours in the neighborhood of $2-5 million per year in health care costs. (5) Although Grateford stands to benefit from a newfound focus on safety flooring, he has a good track record of making accurate predictions of where things are headed in the work safety industry. (6) We are therefore launching an internal cost-benefit study to investigate installation of state-of-the-art low-impact flooring in our warehouses and manufacturing spaces.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'The board of directors of President Sharpe\'s company',
          userLetter: 'A'
        },
        {
          userOption: 'The editor of the company newsletter at President Sharpe\'s company',
          userLetter: 'B'
        },
        {
          userOption: 'All employees of President Sharpe\'s company',
          userLetter: 'C'
        },
        {
          userOption: 'The readership of Work Safe Digest',
          userLetter: 'D'
        },
      ],
      submitId: 'item_8',
      complete: true
    }],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished a task!',
      info: 'Your decisions help illustrate how you analyze someone else’s writing, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',      
      questionIndex: 1,
      moduleSize: 3,
      image: '/assets/section-5/sne-interstitial/written-com-end-interstitial.png',
      buttonText: 'NEXT',
      goto: WC_TYPES.SET_2
    },
  ],
]);

export const WCSet1InstructionModal: SneModal = {
  pageList: [
    {
      info: 'The following is a draft of a memo written by Beth Sharpe, president of a manufacturing company.'
    }
  ],
  title: 'Passage Set Prototypes',
};
