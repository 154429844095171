const DOMAIN = 'https://dev.public.api.apprize.com';

export const environment = {

  // Config
  environmentName: 'dev',
  production: true,
  loggingEnabled: true,
  analyticsTrackingEnabled: true,
  'baseHostname': 'dev.apprize.com',
  'redirectURI': 'https://dev.apprize.com/on-boarding/start',
  'userPoolID': 'us-east-1_WVxikFupN',
  'clientID': '6utj4hjpibtpsfl6017d64j9ao',

  // Profile
  'profileAPI':  DOMAIN + '/profile',
  'dashboardAPI': DOMAIN + '/dashboard',
  'learnerCareerAPI': DOMAIN + '/insertLearnerCareer', // to delete
  'ndaAPI': DOMAIN + '/profile/nda', // to delete

  // Assessments
  'assessmentAPI': DOMAIN + '/assessments',
  'sneAnswerAPI': DOMAIN + '/assessments/answer',
  'cipAPI': DOMAIN + '/assessments/cip',
  'facetsAPI': DOMAIN + '/assessments/facets',
  'sneNegotiationAPI': DOMAIN + '/assessments/sne/negotiation',
  'sneScoreAPI': `${DOMAIN}/assessments/sne/score`,

  // Applied Signals
  'stepAPI': DOMAIN + '/match',
  'workHistoryAPI': `${DOMAIN}/match/work-history`,
  'deleteWorkHistoryAPI': DOMAIN + '/match/work-history/delete',
  'skillsAPI': DOMAIN + '/match/skills',
  'educationHistoryAPI': DOMAIN + '/match/education-history',
  'trainingsAPI': DOMAIN + '/match/trainings',
  'trainingApplicationAPI': DOMAIN + '/match/application',
  'fileManagerAPI': `${DOMAIN}/match/file-manager`,
  'careerChoicesAPI': DOMAIN + '/match/career-choices',
  'careerDetailsAPI': DOMAIN + '/match/career-details',
  'bgtAPI': DOMAIN + '/bgt',

  // Logging
  'logAPI': DOMAIN + '/log',
  'eventAPI': 'https://8tuolaw1f2.execute-api.us-east-1.amazonaws.com/event', //DOMAIN + '/event',
};

//v1 - archived
// 'careerDetailsAPI': DOMAIN + '/careerdetails',
// 'bgAPI': DOMAIN + '/match/bgt',
// 'careerPrioritizationAPI': DOMAIN + '/career/prioritization',
// 'workHistoryAPI': `${DOMAIN}/getWorkHistory`,
// 'deleteRole': DOMAIN + '/match/delete-work-history'
// 'applyToTraining': DOMAIN + '/applyToTraining',
// 'fileManagerAPI': `${DOMAIN}/fileManager`,
// 'updateSkillsAPI': DOMAIN + '/step/updateSkillProficiency',
// 'bgAPI': DOMAIN + '/bgt',
// 'stepAPI': DOMAIN + '/step',
// 'skillsAPI': DOMAIN + '/skills',
// 'sneGenerateScoreAPI': `${DOMAIN}/sne/generateScore`,
// 'negotiationAPI': DOMAIN + '/negotiation',
// 'sneAnswerAPI': DOMAIN + '/sne/cpsAnswer',
// 'educationHistoryAPI': DOMAIN + '/fieldOfStudy',