import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SneV2Component } from './sne-v2.component';
import { CoachabilityModule } from './coachability/coachability.module';
import { CriticalThinkingModule } from './critical-thinking/critical-thinking.module';
import { OralCommunicationModule } from './oral-communication/oral-communication.module';
import { RouterModule } from '@angular/router';
import { WrittenCommunicationModule } from './written-communication/written-communication.module';
import { SharedModule } from '../shared/shared.module';
import { CollaborativeProblemSolvingModule } from './collaborative-problem-solving-v2/collaborative-problem-solving.module';
import { SneReportModule } from './sne-report/sne-report.module';

@NgModule({
  declarations: [
    SneV2Component,
  ],
  imports: [
    CommonModule,
    CoachabilityModule,
    CriticalThinkingModule,
    OralCommunicationModule,
    WrittenCommunicationModule,
    CollaborativeProblemSolvingModule,
    RouterModule,
    SharedModule,
    SneReportModule,
  ],
  exports: [
    CoachabilityModule,
    CriticalThinkingModule,
    OralCommunicationModule,
    WrittenCommunicationModule,
    CollaborativeProblemSolvingModule,
  ]
})

export class SneV2Module { }
