import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUI from './ui.reducer';
import * as fromProfile from './profile.reducer';
import * as fromOnBoarding from './on-boarding.reducer';
import * as fromAuth from './auth.reducer';
import * as fromCIP from './cip.reducer';
import * as fromErrorModal from './error-modal.reducer';

export const appStoreKey = 'app';

export interface AppState {
  [fromUI.uiFeatureKey]: fromUI.State
  [fromProfile.profileFeatureKey]: fromProfile.State,
  [fromOnBoarding.onBoardingFeatureKey]: fromOnBoarding.State,
  [fromAuth.authFeatureKey]: fromAuth.State,
  [fromCIP.cipFeatureKey]: fromCIP.State,
  [fromErrorModal.errorModalFeatureKey]: fromErrorModal.State,
}

export function reducers(state: AppState | undefined, action: Action) {
  return combineReducers({
    [fromUI.uiFeatureKey]: fromUI.reducer,
    [fromProfile.profileFeatureKey]: fromProfile.reducer,
    [fromOnBoarding.onBoardingFeatureKey]: fromOnBoarding.reducer,
    [fromAuth.authFeatureKey]: fromAuth.reducer,
    [fromCIP.cipFeatureKey]: fromCIP.reducer,
    [fromErrorModal.errorModalFeatureKey]: fromErrorModal.reducer,
  })(state, action);
}


export const selectAppState = createFeatureSelector<AppState>(
  appStoreKey
);

/** Auth Selectors **/
export const selectAuthState = createSelector(
  selectAppState,
  (state => state.auth)
);

export const selectEmail = createSelector(
  selectAuthState,
  (state => state.email)
);

export const selectOpenSesame = createSelector(
  selectAuthState,
  (state => state.openSesame)
);

// Error Modal Selector
export const selectErrorModalState = createSelector(
  selectAppState,
  (state => state.errorModal)
);

export const selectErrorModal = createSelector(
  selectErrorModalState,
  (state => state.showErrorModal)
);

/** Profile Selectors **/

export const selectProfileState = createSelector(
  selectAppState,
  (state => state.profile)
);

export const selectProfile = createSelector(
  selectProfileState,
  (state => state.profile)
);

/** CIP Selectors */
export const selectCIPState = createSelector(
  selectAppState,
  (state => state.cip)
);

/** On-Boarding Selectors **/

export const selectOnBoardingState = createSelector(
  selectAppState,
  (state => state.onBoarding)
);

export const selectOnBoardingQuestionSection = createSelector(
  selectOnBoardingState,
  (state => state.onBoardingQuestionSection)
);

export const selectOnBoardingQuestionType = createSelector(
  selectOnBoardingState,
  (state => state.onBoardingQuestionType)
);

export const selectOnBoardingCurrentQuestionIndex = createSelector(
  selectOnBoardingState,
  (state => state.onBoardingQuestionIndex)
);

export const selectWorkHistoryInFlight = createSelector(
  selectOnBoardingState,
  (state => state.workHistoryInFlight)
);

export const selectSkillsInFlight = createSelector(
  selectOnBoardingState,
  (state => state.skillsInFlight)
);

/** UI Selectors **/

export const selectUIEntitiesState = createSelector(
  selectAppState,
  (state => state.ui)
);

export const selectCipQuestion = createSelector(
  selectUIEntitiesState,
  (state => state.cipQuestion)
);


export const selectOnBoardingProgress = createSelector(
  selectUIEntitiesState,
  (state => state.onBoardingProgress)
);

export const selectCipProgress = createSelector(
  selectUIEntitiesState,
  (state => state.onBoardingProgress)
);

export const selectCipQuestionList = createSelector(
  selectUIEntitiesState,
  (state => state.cipQuestionList)
);

export const selectCipQuestionIndex = createSelector(
  selectUIEntitiesState,
  (state => state.cipQuestionIndex)
);

export const selectTriggerNextCipQuestion = createSelector(
  selectUIEntitiesState,
  (state => state.triggerNextCipQuestion)
);


export const selectTriggerPreviousQuestion = createSelector(
  selectUIEntitiesState,
  (state => state.triggerPreviousQuestion)
);

export const selectTriggerPreviousCipQuestion = createSelector(
  selectUIEntitiesState,
  (state => state.triggerPreviousCipQuestion)
);

export const selectCipAnswers = createSelector(
  selectUIEntitiesState,
  (state => state.cipAnswers)
);
