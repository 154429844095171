import Profile from './profile';

export interface LearnerCareersResponse {
  learner: Profile;
  careers: LearnerCareer[];
}

export interface LearnerCareer {
  careerID: number;
  matchScore: number;
  interestScore: number;
  isFollowing: boolean;
  isDismissed: boolean;
  careerHumanReadableID: string;
  inDemand: boolean;
  interestFlag: boolean;
  careerTitle: string;
  careerDescription: string;
  careerIndustry: string;
  salaryRange: string;
  salaryMedian: string;
  everydayTasksJSON: any;
  requiresSpecialistSkills: boolean;
  sentence: string;

  // Career Prioritization page specific fields
  showCareer?: boolean;
  enableFollowBtn?: boolean;
  enableDismissBtn?: boolean
  matchResults?: any[];
  interestResults?: any[];
}

export interface CareerPrioritizationRequest {
  careerIDList: number[];
  action: CareerPrioritizationActionEnum;
}

export enum CareerPrioritizationActionEnum {
  FOLLOW = 'follow',
  UNFOLLOW = 'unfollow',
  DISMISS = 'dismiss',
  UNDISMISS = 'undismiss',
}

export interface CareerPrioritizationResponse {
  success: boolean;
  message: string;
}
