<div class="page-container">
  <div class="inner">

    <h1 class="title">{{questionData?.question}}</h1>

    <h3 *ngIf="questionData?.info" class="description">{{questionData?.info}}</h3>

    <div class="table-container">
      <form [formGroup]="cipForm">

      <div class="ranking-table" *ngIf="rankingType === 'ENJOY'">
          <div *ngFor="let header of enjoyStates; index as stateIndex" class="table-col">
            <div *ngIf="header.value === ''; else radioButton" class="ans-col">
              <span class="table-header"></span>
              <div *ngFor="let answer of questionData?.choices">
                <div class="border"></div>
                <span class = "cell">{{answer}}</span>
                <div class="tablet-answers">
                  <div class="tablet-selection" *ngFor="let header of enjoyStates; index as headerIndex">
                    <!-- <span>{{header.value}}</span> -->
                    <label *ngIf="header.value !== ''" for="{{answer}}{{header.value}}tablet" class="table-header">{{header.value}}</label>
                      <input
                        *ngIf="header.value!==''"
                        type="radio"
                        formControlName="{{answer}}"
                        value="{{headerIndex}}"
                        name="{{answer}}"
                        id="{{answer}}{{header.value}}tablet"
                        class="radio-btn"/>
                    <!-- </label> -->
                  </div>
                </div>
                <div class="mobile-answers">
                  <div class="mobile-selection" *ngFor="let header of enjoyStates; index as headerIndex">
                    <label *ngIf="header.value !== ''" for="{{answer}}{{header.value}}mobile" class="table-header">
                      <input
                        type="radio"
                        formControlName="{{answer}}"
                        value="{{headerIndex}}"
                        name="{{answer}}"
                        id="{{answer}}{{header.value}}mobile"
                        class="radio-btn"/>&nbsp;&nbsp;&nbsp;&nbsp;{{header.value}}
                    </label>
                    <!-- <span>{{header.value}}</span> -->
                  </div>
                </div>

              </div>
            </div>
            <ng-template #radioButton>
              <div class="radio-button-cols">
                <span class="table-header">{{header.value}}</span>
                <div class="cell" *ngFor="let answer of questionData?.choices">
                  <label for="{{answer}}{{header.value}}desktop">
                    <input
                      type="radio"
                      formControlName="{{answer}}"
                      name="{{answer}}"
                      id="{{answer}}{{header.value}}desktop"
                      class="radio-btn"
                      value="{{stateIndex}}"
                    />
                  </label>
                </div>
              </div>
            </ng-template>
          </div>
      </div>
      </form>
    </div>
  </div>
</div>
<div class="submit-btn-container">
  <ets-rounded-contained-button
    [backgroundColor]="APP_COLORS.uiaction"
    [textColor]="APP_COLORS.white"
    [buttonText]="'Next Question'"
    (click)="triggerNextQuestionCip()"
  >
  </ets-rounded-contained-button>
</div>
