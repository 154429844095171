import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneMulChoiceOption,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';


export const CTSchedulingData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Scheduling Office Tasks',
      info: 'This task will give you insight into how you interpret guidelines and limitations, and we will use what we learn together to enhance your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/scheduling.svg', // placeholder
      questionIndex: 4,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SCHEDULE,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Task Schedule',
      taskMap: {
        statement: 'A computer operator needs to schedule four data processing tasks to run during a 12-hour period. Tasks must run sequentially (cannot overlap), but can be done in any order.',
        scheduleMap: '~/../assets/map-large.png'
      },
      multipleMulChoiceOptions: [
        {
          question: 'Based on these requirements, at what time is it <span class="convertToBlue">impossible</span> to start a task?',
          mulChoiceOptions: [
            {
              userOption:
                '1 A.M.',
              userLetter: 'A',
            },
            {
              userOption:
                '2 A.M.',
              userLetter: 'B',
            },
            {
              userOption:
                '3 A.M.',
              userLetter: 'C',
            },
            {
              userOption:
                '4 A.M.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_1',
    }
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SCHEDULE,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Task Schedule',
      taskMap: {
        statement: 'A computer operator needs to schedule four data processing tasks to run during a 12-hour period. Tasks must run sequentially (cannot overlap), but can be done in any order.',
        scheduleMap: '~/../assets/map-large.png'
      },
      multipleMulChoiceOptions: [
        {
          question: 'Data center clients can make scheduling requests and the center\'s policy is to accommodate such requests, if reasonable.<br><br>Which of the following scheduling requests from a client cannot be honored because it will make it <span class="convertToBlue">impossible</span> to schedule all three remaining tasks?<br><br>',
          mulChoiceOptions: [
            {
              userOption:
                'Start task A at 12 A.M.',
              userLetter: 'A',
            },
            {
              userOption:
                'Start task B at 2 A.M.',
              userLetter: 'B',
            },
            {
              userOption:
                'Start task C at 6 A.M.',
              userLetter: 'C',
            },
            {
              userOption:
                'Start task D at 8 A.M.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SCHEDULE,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Task Schedule',
      taskMap: {
        statement: 'A computer operator needs to schedule four data processing tasks to run during a 12-hour period. Tasks must run sequentially (cannot overlap), but can be done in any order.',
        scheduleMap: '~/../assets/map-large.png'
      },
      multipleMulChoiceOptions: [
        {
          question: 'The operator would like to have as much flexibility as possible when scheduling tasks, so that any last-minute schedule changes by clients or management could be accommodated. Because of this, assignments that reduce flexibility too much should be avoided. <br><br> Which of the following scheduling requests, if honored, will completely determine the rest of the schedule?',
          mulChoiceOptions: [
            {
              userOption:
                'Start task A at 12 A.M. and task B at 2 A.M.',
              userLetter: 'A',
            },
            {
              userOption:
                'Start task D at 12 A.M. and task A at 10 A.M.',
              userLetter: 'B',
            },
            {
              userOption:
                'Start task C at 3 A.M. and task A at 7 A.M.',
              userLetter: 'C',
            },
            {
              userOption:
                'Start task C at 2 A.M. and task D at 9 A.M.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_3',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice job working with those schedules! Your decisions help illustrate how you interpret and navigate restrictions, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png', // placeholder
      questionIndex: 4,
      moduleSize: 6,      
      buttonText: 'NEXT',
      goto: CT_TYPES.ARGUMENTATION_1
    },
  ],
]);

export const CTSchedulingInstructionModal: SneModal = {
  title: 'Schedule Mapping',
  pageList: [
    {
      info: 'A computer operator needs to schedule four data processing tasks to run during a 12-hour period. Tasks must run sequentially (cannot overlap), but can be done in any order.',
    },
  ],
};