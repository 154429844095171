import { Component, OnInit, OnDestroy, HostListener, ViewChild, Input } from '@angular/core';
import { trigger, state, transition, animate, style, group, query, animateChild } from '@angular/animations';
import { NegotiationQuestion, Statement, NegotiationAnswer, Star, StarStatus } from 'src/app/types/sne';
import { NegotiationService } from 'src/app/services/negotiation.service';
import { Router } from '@angular/router';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ets-client-ranking-stars',
  templateUrl: './client-ranking-stars.component.html',
  styleUrls: ['./client-ranking-stars.component.scss'],
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0.1 }),
        animate('1500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0.1 }))
      ])
    ]),
    trigger('animation2', [
      transition(':enter', [
        style({ opacity: 1 }),
        animate('1500ms', style({ opacity: 0.1 })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeOut', [
      state('invisible', style({
        opacity: 1,
      })),
      state('visible', style({
        opacity: 0.1,
      })),
      transition('invisible => visible',
        animate('1000ms')
      ),
      transition('visible => invisible',
        animate('1000ms')
      ),
    ]),
    trigger('fadeIn', [
      state('visible', style({
        opacity: 1,
      })),
      state('invisible', style({
        opacity: 0.1,
      })),
      transition('invisible => visible',
        animate('1000ms')
      ),
      transition('visible => invisible',
        animate('1000ms')
      ),
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        group([
          animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
          query('@*', [
            animateChild()
          ])]),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class ClientRankingStarsComponent {

  constructor(private negotiationService: NegotiationService, private router: Router) { }

 
  public visible: boolean = false;
  @Input() questionData: NegotiationQuestion | undefined;
  answer: NegotiationAnswer = {};
  @Input() jordanStars: Star[] = [];
  @Input() clientStars: Star[] = [];
  @Input() StarStatus = StarStatus;
  faStar = faStar;
  filled = 'filled';
  empty = 'empty';
  drawerState = 'invisible';

  
}
