import { SneQuestion, SneModal, SNE_INPUT_TYPES } from '../../../types/sne';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CoachabilityPersonalMistakesData: Map<string, SneQuestion> =
  new Map([
    [
      'start',
      {
        questionType: QUESTION_TYPES.INTERSTITIAL,
        title: 'Personal Mistakes',
        info: 'This task will help you better understand how you handle making errors. We’ll add these insights to your Skills Profile.',
        duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you’ll have the option to pause when you finish.',
        questionIndex: 5,
        moduleSize: 6,
        durationBoxColor: 'lbb',
        image: '/assets/section-3/sne-interstitial/personal-mistakes.svg',
        buttonText: 'Next',
        header: '',
        modalBeforeNext: true,
      },
    ],
    [
      'question-1',
      {
        questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
        header: 'How do you want to reply?',
        botProfilePic:'/assets/sne/coachability-sam-profile-pic.png',
        youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
        inputType: SNE_INPUT_TYPES.RADIO,
        submitId: 'item_1',
        singleMulChoiceOptions: [
          {
            userOption:
              'Well, not just yet. After all, chemistry and biographies were popular, and surveys aren’t always accurate. Who’s to say what’s best for the game? ',
            userLetter: 'A',
            autoUser: 'Sam',
            response:
            'Huh. I see your point, but maybe we should still tell her what the data really show, so she has everything she needs to make up her mind. But how do we explain what happened?',
          },
          {
            userOption:
              'I don’t think so. You can see how happy Gail is to go forward with chemistry. In the end, we’re supposed to please our supervisors, and that’s what we’d be doing. ', // content
            userLetter: 'B',
            autoUser: 'Sam',
            response: 'Huh. I see your point, but maybe we should still tell her what the data really show, so she has everything she needs to make up her mind. How do we explain what happened?',
          },
          {
            userOption:
              'She deserves the real results of the survey-otherwise, she doesn’t have what she needs to make a decision about the game. ', // content
            userLetter: 'C',
            autoUser: 'Sam',
            response: 'Yes, I agree. That makes a lot of sense. But how do we explain what happened?',
          },
          {
            userOption:
              'We need to tell her something; we can’t just bury this. But there’s no reason to spell it out for her. I’ll just say that we had a little data hiccup, but that we discovered it in time and it’s under control now. ', // content
            userLetter: 'D',
            autoUser: 'Sam',
            response: 'Huh. I see your point, but maybe we should still tell her what the data really show, so she has everything she needs to make up her mind. How do we explain what happened?',
          },
        ],
      },
    ],
    [
      'question-2',
      {
        questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
        header: 'How do you want to reply?',
        botProfilePic:'/assets/sne/coachability-sam-profile-pic.png',
        youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
        inputType: SNE_INPUT_TYPES.RADIO,
        submitId: 'item_2',
        singleMulChoiceOptions: [
          {
            userOption:
              'I’ll take the blame. It’s my fault that the data weren’t labeled properly.',
            userLetter: 'A',
            autoUser: 'Sam',
            response: 'OK, let me know if there’s anything I can do. How do you want to tell her about the error?',
          
          },
          {
            userOption:
              'It’s really not important to get into the details. We just need to fix it and move on.',
            userLetter: 'B',
            autoUser: 'Sam',
            response: 'OK, but how do you want to tell her about the error? ',
          },
          {
            userOption:
              'Pointing fingers doesn’t help anyone. Technically this was your mistake Sam, but I don’t want to get you in trouble.',
            userLetter: 'C',
            autoUser: 'Sam',
            response: 'OK,thanks. But how do you want to tell her about the error?',
            
          },
          {
            userOption:
              'Gail’s been under a lot of stress lately, and we shouldn’t pile on that by giving her the specifics. Just the fact that we made this mistake is already bad enough.',
            userLetter: 'D',
            autoUser: 'Sam',
            response: 'OK, sounds good. But how do you want to tell her about the error?',
          },
        ],
      },
      
    ],
    [
      'question-3',
      {
        questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
        header: 'How do you want to reply?',
        botProfilePic:'/assets/sne/coachability-sam-profile-pic.png',
        youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
        inputType: SNE_INPUT_TYPES.RADIO,
        submitId: 'item_3',
        singleMulChoiceOptions: [
          {
            userOption:
              'Just a quick text with the main point that we need to focus on dinosaurs and astronomy instead of chemistry and biography.',
            userLetter: 'A'
          },
          {
            userOption:
              'I’d like to explain what went wrong and what could be improved at our next survey, so I think that requires an email.',
            userLetter: 'B',
          },
          {
            userOption:
              'I’ll weave it into the conversation when I chat with her at our next lunch. Don’t worry!',
            userLetter: 'C',
          },
          {
            userOption:
              'I think this is exactly what our company-wide chat group is good for. This way, anyone interested in the project can find out the details.',
            userLetter: 'D'
          },
        ],

      },
      
    ],
    [
      'question-4',
      {
        questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
        header:
          'Please select the <span class=\'convertToBlue\'>three most appropriate responses</span> to Gail’s question by clicking on each statement.',
        inputType: SNE_INPUT_TYPES.CHECKBOX,
        numSelectOptions: 3,
        submitId: 'item_4',
        complete: true,
        hintModalFlag: true,
        singleMulChoiceOptions: [
          {
            userOption:
              'It was totally my fault, and I’m embarrassed that it happened at all. I think I was multitasking too much that day and I made a careless mistake. ',
            userLetter: 'A',
            response: '',
          },
          {
            userOption:
              'Sam is fairly new at the job, and I trusted him to analyze the data. I probably shouldn’t have, since apparently he’s not ready for it.',
            userLetter: 'B',
            response: '',
          },
          {
            userOption:
              'It certainly was a mistake, but it’s really hard to tell exactly what happened. It might have been a glitch or a bug in the software itself.',
            userLetter: 'C',
            response: '',
          },
          {
            userOption:
              'You wanted the results of the survey really quickly, and we just couldn’t do a good enough job in that timeframe. You probably should allow more time in the schedule if we do it again.',
            userLetter: 'D',
            response: '',
          },
          {
            userOption:
              'I think I’m not as comfortable with the new database survey software as I should be. I’ll watch a tutorial video so I’ll be up to speed next time.',
            userLetter: 'E',
            response: '',
          },
          {
            userOption:
              'It really was a simple error regarding our file naming conventions, and it was absolutely my mistake. I need to make sure to double-check my variables next time.',
            userLetter: 'F',
            response: '',
          },
          {
            userOption:
              'The software that we use to analyze the data is really out out-of of-date and buggy. I’m going to leave a comment on their website to warn other users of what might happen.',
            userLetter: 'G',
            response: '',
          },
          {
            userOption:
              'I’m really not sure what went wrong, but I think it probably had to do with Sam and I having different ideas about the best way to approach the work. I’ll need to be clearer about my expectations from now on with him.',
            userLetter: 'H',
            response: '',
          },
        ],

        //Use this template when ready to add hint.
        hintModal: {
          title: 'Gail is curious...',
          pageList: [
            {
              info: 'Soon after you tell Gail about the data error, she asks you to explain to her some of the reasons for the initial data mistake.',
            }
          ],
        }
      },
    ],
    [
      'end',
      {
        questionType: QUESTION_TYPES.INTERSTITIAL,
        title: 'Congrats! You finished the another task!',
        questionIndex: 5,
        moduleSize: 6,
        info: 'Your responses will help us explore how you navigate being accountable—where your strengths lie and where you have room to grow. Feel free to pause if you need a break or start the next task if you\'re ready. One more to go!',
        image: '../assets/section-3/sne-interstitial/coach-end-interstitial.png', // placeholder
        buttonText: 'Next',
        header: '',
        goto: COACHABILITY_TYPES.UNCERTAINTY,
      },
    ],
  ]);

export const CoachabilityPersonalMistakesInstructionModal: SneModal = {
  title: 'Choosing Game Topics',
  pageList: [
    {
      info: 'You work as a manager at SmartGames, a company that designs educational games for children. SmartGames works with other companies that produce the games and ship them to retailers. Your supervisor has asked you to suggest topics for a possible game designed to help children improve their critical thinking skills.',
    },
    {
      info: 'You and Sam, your intern, survey hundreds of elementary school teachers to collect information about subjects that are engaging to elementary students. When you and Sam analyze the data from the survey, you both agree that the most popular topics would be chemistry and biographies. You send your supervisor the following email.',
    },
    {
      info: '',
      showCustomContent: true,
    },
    {
      info: 'Soon after receiving Gail\'s response, you realize that you and Sam had made an error and only analyzed part of the data from the survey because you mislabeled certain records in the database survey software when you told Sam what you wanted him to do. Once you include all of the data the survey had generated, the most popular topics appear to be dinosaurs and astronomy, although chemistry and biographies remain fairly popular.',
    },
  ],
  customContent: [
    {
      to: 'Gail',
      from: 'You',
      emailSubject: 'Topic suggestions for new children\'s game',
      image: '/assets/sne/coachability-gail-profile-pic.png',
      body: 'Sam and I crunched the numbers, and it looks like we should develop two modules, one focusing on chemistry concepts and the other on biographies of people whose work has been important. Those are the two subjects that the teachers think are more interesting right now to kids.',
    },
  ],
};
