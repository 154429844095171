export const APP_COLORS = {
  white: '#ffffff',
  black: '#000000',

  uitext: '#565656',
  uiicon: '#737373',
  uiborder: '#CCCCCC',
  uiaction: '#0E3AD6',
  uisuccess: '#62A420',
  uidanger: '#E62700',

  persianbluebright: '#E7EBFB',
  persianbluelight: '#B7C4F3',
  persianbluesoft: '#6E89E6',
  persianbluemedium: '#0E3AD6',
  persianbluedark: '#082380',
  persianbluedarker: '#041140',

  lbbbright: '#F7FAFF',
  lbblight: '#E8EFFE',
  lbbsoft: '#D0DFFD',
  lbbmedium: '#80A8F8',
  lbbdark: '#6A7997',
  lbbdarker: '#353D48',

  mustardbright: '#FFFBED',
  mustardlight: '#FFF4CA',
  mustardsoft: '#FFE895',
  mustardmedium: '#FFD94E',
  mustarddark: '#CCAE3E',
  mustarddarker: '#806D27',

  aquamarinebright: '#F1FBF5',
  aquamarinelight: '#D5F2E2',
  aquamarinesoft: '#AAE5C5',
  aquamarinemedium: '#72D39F',
  aquamarinedark: '#447F5F',
  aquamarinedarker: '#223F30',

  mangotangobright: '#fff3ec',
  mangotangolight: '#fedac5',
  mangotangosoft: '#fcb58c',
  mangotangomedium: '#FA833F',
  mangotangodark: '#964f26',
  mangotangodarker: '#4b2713',

  zenbright: '#F3F3F3',
  zenlight: '#DFDFDF',
  zensoft: '#D1D1D1',
  zenmedium: '#959595',
  zendark: '#707070',
  zendarker: '#4D4D4D',

  bkrdpage: '#FAFAFA',
  bkrdactive: '#D9E4EA',
  bkrdcomponent: '#FFFFFF'
};
