<ets-header [headerState]="headerState" [simpleBack]='true'></ets-header>

<div class="page-container">

  <div class="content-container">

    <div class="title-container">
      <div class="title-container-content-wrapper">
        <h2 class="title">Let's pick out some careers.</h2>
        <p class="description">Given what we just learned about you, we think this list of careers is worth exploring</p>
        <p class="description"><span class="blue-description">Select up to three</span> you want to follow</p>
        <img class="bgt-logo" alt="Emsi Burning Glass Logo" src="~/../assets/BGT-logo.png">
      </div>
    </div>

    <div class="careers-container">
      <div class="careers-inner-container">
        <div class="filters-wrapper">
          <!-- <div class="in-demand-container">
            <div class="toggle-switch-label-wrapper">
              <div class="in-demand-icon-wrapper">
                <img class="in-demand-icon" src='../../assets/career-suggestions/trending_up.svg' alt='Trending Up Icon'>
              </div>
              <p class="in-demand-text">In-demand only</p>
            </div>
            <label class="switch">
              <input type="checkbox" name="inDemandOnly" [checked]="inDemandOnlyFlag" (click)='toggleInDemand()'>
              <span class="slider round"></span>
            </label>
          </div> -->

          <!-- <div class="filter-button-container">
            <button class="filter-btn" (click)='toggleFilterModal()'>Filter</button>

            <div class="filter-modal" *ngIf='showFilterModal'>
              <div class="toggle-switch-wrapper">
                <div class="toggle-switch-label-wrapper">
                  <div class="in-demand-icon-wrapper">
                    <img class="in-demand-icon" src='../../assets/career-suggestions/trending_up.svg' alt='Trending Up Icon'>
                  </div>
                  <p class="in-demand-text">In-demand only</p>
                </div>

                <label class="switch">
                  <input type="checkbox" name="inDemandOnly" [checked]="inDemandOnlyFlag" (click)='toggleInDemand()'>
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div> -->

          <!-- drop down -->
          <!-- <button class="btn btn-link btn-sm career-match-text" (click) = 'handleSortByChange()'>
            Career Match
            <img *ngIf = '!sortDown; else sortUp' src= '../../assets/career-suggestions/chevron-down.svg' alt="chevron down" class="sort-chevron">
            <ng-template #sortUp>
              <img src= '../../assets/career-suggestions/chevron-up.svg' alt="chevron up" class="sort-chevron">
            </ng-template>
          </button> -->
          <!-- <clr-select-container class="margin-top-0">
            <select clrSelect #t (change)="handleSortByChange(t.value)">
              <option *ngFor='let option of SORT_BY_OPTIONS; let i = index'
                  class="select-options"
                  [selected]="i === 0"
                  [value]='option'
              >
                {{option}}
              </option>
            </select>
          </clr-select-container> -->
        </div>

        <div class="careers-content-wrapper">
          <span *ngFor='let career of frontEndCareerList'>
            <div class="career-card" *ngIf='career.showCareer'>
              <div class="desktop-tablet-career-img-wrapper"
                [ngStyle]="{'background-color': career.isFollowing ? findBGColor(career.careerTitle) : '#FAFAFA'}"
              >
                <img class="desktop-tablet-career-img"
                     *ngIf='!career.isFollowing'
                     src="https://assets.apprize.com/Careers/Wide/{{career?.careerTitle}}_wide_%403x.png"
                     alt="career-image"
                >
                <img class="desktop-tablet-career-img"
                     *ngIf='career.isFollowing'
                     [ngStyle]="{'background-color': findBGColor(career.careerTitle)}"
                     src="https://assets.apprize.com/Careers/Wide/{{career?.careerTitle}}_wide_%403x.png"
                     alt="career-image"
                >
              </div>
              <div class="mobile-career-img-wrapper">
                <img class="mobile-career-img"
                     *ngIf='!career.isFollowing'
                     src="https://assets.apprize.com/Careers/Wide/{{career?.careerTitle}}_wide_%403x.png"
                     alt="career-image"
                >
                <img class="mobile-career-img"
                     *ngIf='career.isFollowing'
                     [ngStyle]="{'background-color': findBGColor(career.careerTitle)}"
                     src="https://assets.apprize.com/Careers/Wide/{{career?.careerTitle}}_wide_%403x.png"
                     alt="career-image"
                >
              </div>

              <div class="career-content">
                <div class="left-career-content">
                  <div class="career-title-wrapper">
                    <div
                     class="career-title-inner-wrapper"
                     (keydown.enter)="goToCareerDetails(career)"
                     (keydown.space)="goToCareerDetails(career)"
                     (click)='goToCareerDetails(career)'
                     >
                      <h4 class="career-title">{{career.careerTitle}}</h4>
                    </div>
                  </div>

                  <div class="career-tags">
                    <span class="typical-salary-text">Typical Salary</span>
                    <span class="salary" *ngIf="career.salaryMedian">{{career.salaryMedian}}</span>
                    <span class="salary" *ngIf="!career.salaryMedian">$99,625</span>
                    <!-- <div class="in-demand-wrapper" *ngIf='career.inDemand'>
                      <div class="in-demand-icon-tag-wrapper">
                        <img class="in-demand-icon" src='../../assets/career-suggestions/trending_up.svg' alt='Trending Up Icon'>
                      </div>
                      <p class="tag-label">In-Demand</p>
                    </div>

                    <div class="interest-match-wrapper">
                      <div class="career-interest-icon-tag-wrapper">
                        <img class="career-interest-icon" src='../../assets/career-suggestions/sentiment_very_satisfied.svg' alt='Smiley Face Icon'>
                      </div>
                      <p class="tag-label">Interest Match</p>
                    </div> -->
                  </div>

                  <!-- THE NEXT COMMENTED OUT LINE SHOULD NOT BE DELETED. We will likely want it back later. -->
                  <!-- <p class="career-description">{{career.sentence}}</p> -->
                </div>

                <div class="right-career-content">
                  <div class="career-match-percent">
                    <div class="percent-outer-circle">
                      <!-- Skill Match Score -->
                      <ngx-charts-pie-chart *ngIf='sortBySkillMatchFlag'
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="career.matchResults"
                        [gradient]="gradient"
                        [tooltipDisabled]="disableTooltip"
                        [doughnut]="true"
                      >
                      </ngx-charts-pie-chart>

                      <!-- Interest Match Score -->
                      <ngx-charts-pie-chart *ngIf='!sortBySkillMatchFlag'
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="career.interestResults"
                        [gradient]="gradient"
                        [tooltipDisabled]="disableTooltip"
                        [doughnut]="true"
                      >
                      </ngx-charts-pie-chart>

                      <div class="percent-inner-circle">
                        <span class="percent" *ngIf='sortBySkillMatchFlag'>{{career.matchScore}}%</span>
                        <span class="percent" *ngIf='!sortBySkillMatchFlag'>{{career.interestScore}}%</span>
                      </div>
                    </div>
                  </div>

                  <div class="follow-dismiss-wrapper">
                    <!-- follow/added -->
                    <button class="follow-btn"
                            *ngIf='!career.isFollowing'
                            [disabled]='!career.enableFollowBtn'
                            (click)='toggleFollowingCareer(career)'
                            [etsFirstHover]="firstHover"
                            [isFollowing]="career.isFollowing"
                    >Save</button>
                    <button class="added-btn"
                            *ngIf='career.isFollowing'
                            (click)='toggleFollowingCareer(career)'
                            [etsFirstHover]="firstHover"
                            [isFollowing]="career.isFollowing"
                            [ngClass]="{'added-show-remove': !firstHover.value}"
                    >
                      <span class="added-btn-img"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <!-- load more button at bottom -->
          <div class="load-more-wrapper">
            <button class="load-more-btn" *ngIf='loadMoreFlag' (click)='loadMoreCareers()'>load more</button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<div class="footer-container">
  <div class="footer-picture-container">
    <div *ngFor='let i of [0, 1, 2]'>
      <div class = "footer-picture-circle">
        <img *ngIf='i < followedCareerList.length; else blankCircle'
             src="https://assets.apprize.com/Careers/Narrow/{{followedCareerList[i].careerTitle}}_narrow_%403x.png"
             alt="{{followedCareerList[i].careerTitle}}"
             class="footer-picture"
             [ngStyle]="{'background-color': findBGColor(followedCareerList[i].careerTitle)}"/>
      </div>
      <ng-template #blankCircle>
        <img src="../../assets/career-suggestions/plus-circle.svg" alt="blank circle with plus sign" class="footer-picture-circle">
      </ng-template>
    </div>
  </div>
  <!-- <div *ngFor='let career of followedCareerList' class="footer-picture-container">
    <img src={{career.desktopTabletFollowedImage}} alt={{career.careerTitle}} class="footer-picture">
  </div> -->
  <button class="submit-button" [disabled]="followedCareersCount <= 0 || postCallInProgress" (click)='saveCareersAndProceed()'>
    Save Careers
    <div class="careers-followed-wrapper" *ngIf='followedCareersCount > 0'>
      <p class="careers-followed-text">{{followedCareersCount}}/3</p>
    </div>
  </button>
</div>


