<div class="container">
  <div class="looking-objective-container">
    <div class="wrapper">
      <span class="title">I AM LOOKING TO...</span>
      <div *ngIf="dashboardProfileLength === 1">
        <span *ngFor="let careerOption of dashboardProfile?.aspiration" class="objective">{{careerOption}}</span>
      </div>
      <ul class="objective-list" *ngIf="dashboardProfileLength > 1">
        <li class="objective" *ngFor="let careerOption of dashboardProfile?.aspiration">{{careerOption}}</li>
      </ul>
    </div>
  </div>

  <div class="user-info-container">
    <div class="row bottom-margin">
      <div class="wrapper">
        <div class="icon-wrapper green-wrapper">
          <img class="icon" src="assets/dashboard/side-bar/dollar-sign.svg" alt="" aria-hidden="true">
        </div>
        <div class="info-wrapper">
          <span class="title">My min. salary</span>
          <span class="info">{{dashboardProfile?.desiredSalary}}</span>
        </div>
      </div>
      <div class="wrapper">
        <div class="icon-wrapper mango-wrapper">
          <img class="icon" src="assets/dashboard/side-bar/house.svg" alt="" aria-hidden="true">
        </div>
        <div class="info-wrapper">
          <span class="title">I want to work</span>
          <span class="info">{{dashboardProfile?.wantToWork}}</span>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="wrapper">
        <div class="icon-wrapper blue-wrapper">
          <img class="icon" src="assets/dashboard/side-bar/hour-glass.svg" alt="dollar sign">
        </div>
        <div class="info-wrapper">
          <span class="title">Hours per week</span>
          <span class="info">{{dashboardProfile?.hoursPerWeek}}</span>
        </div>
      </div>
      <div class="wrapper">
        <div class="icon-wrapper yellow-wrapper">
          <img class="icon" src="assets/dashboard/side-bar/folding-paper.svg" alt="dollar sign">
        </div>
        <div class="info-wrapper">
          <span class="title">Look for jobs</span>
          <span class="info">{{dashboardProfile?.lookForJobs}}</span>
        </div>
      </div>
    </div> -->
  </div>
</div>
