import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ets-dashboard-progress-bar',
  templateUrl: './dashboard-progress-bar.component.html',
  styleUrls: ['./dashboard-progress-bar.component.scss']
})
export class DashboardProgressBarComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() section: number | undefined;
  @Input() onCurrentSection = false;
  @Input() multiStep = false;
  @Input() lastSection: boolean = false;
  @Input() progressCompletePercent: number | undefined;

  faCheckCircle = faCheckCircle;

  pi = 3.14159265358979;

  circumference = 0;
  arcLengthPercent = 0.5;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['progressCompletePercent']) {
      if (this.progressCompletePercent) {
        this.circumference = (2 * 3.14159265358979) * this.progressCompletePercent;
      }
    }
  }

  ngOnInit(): void {
    if (this.progressCompletePercent) {
      this.circumference = (2 * 3.14159265358979) * this.progressCompletePercent;
    }
  }

}
