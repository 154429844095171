import { Component } from '@angular/core';

@Component({
  selector: 'ets-oral-communication',
  templateUrl: './oral-communication.component.html',
  styleUrls: ['./oral-communication.component.scss']
})
export class OralCommunicationComponent {

  constructor() { }

}
