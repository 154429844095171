<div class="show-question-container mobile" [hidden]="!(!drawerVisible && drawerActive)">
  <img src="../../../../assets/sne/arrow-up.svg" alt="arrow pointing up" class="blue-arrow" (click)="showDrawer()">
  <div class="button-wrapper">
    <ets-solid-button
      (clickEvent)="showDrawer()"
      [color]="APP_COLORS.uiaction"
      [text]="showBtnText"
      [buttonMaxWidth]="true"
    ></ets-solid-button>
  </div>
</div>
<div [hidden]="!(drawerVisible && drawerActive)" [@slideInOut] class="drawer mobile">
  <img src="../../../../assets/sne/arrow-down.svg" alt="arrow pointing down" class="blue-arrow" (click)='showDrawer()'>
  <p class="hide-question" (click)="showDrawer()">{{hideBtnText}}</p>
  <div class="external-drawer-content" id="contentId">
    <ng-content></ng-content>
  </div>
</div>
<div class="container">
  <div class="button-container" *ngIf="overviewEnabled">
    <ets-outline-button class="overview-button non-mobile"
                        [text]="overviewBtnText"
                        [color]="APP_COLORS.uiaction"
                        (click)="overviewEmit($event)"
    >
    </ets-outline-button>
    <ets-solid-button class="next-question-button non-mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction" 
                      (click)="nextEmit($event)" 
                      [disabled]="isButtonDisabled"
                      *ngIf="nextEnabled"
    >
    </ets-solid-button>
    <ets-outline-button class="overview-button mobile"
                        [text]="overviewBtnText"
                        [color]="APP_COLORS.uiaction"
                        [size]="mobileButtonSize"
                        [transparentBg]="true"
                        (click)="overviewEmit($event)"
                        *ngIf="drawerActive ? drawerVisible : true"
    >
    </ets-outline-button>
    <ets-solid-button class="next-question-button mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction"
                      (click)="nextEmit($event)"
                      [size]="mobileButtonSize"
                      [disabled]="isButtonDisabled"
                      *ngIf="drawerActive ? drawerVisible && nextEnabled : true"
    >
    </ets-solid-button>
  </div>
  <div class="button-container" *ngIf="!overviewEnabled && showSaveAndExit">
    <button class="save-exit-button non-mobile" (click)="saveAndExit()">SAVE & EXIT</button>
    <ets-solid-button class="next-question-button non-mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction" 
                      (click)="nextEmit($event)" 
                      [disabled]="isButtonDisabled"
                      *ngIf="nextEnabled"
    >
    </ets-solid-button>
    <p class="save-exit-button mobile" (click)="saveAndExit()">SAVE & EXIT</p>
    <ets-solid-button class="next-question-button mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction"
                      (click)="nextEmit($event)"
                      [size]="mobileButtonSize"
                      [disabled]="isButtonDisabled"
                      *ngIf="drawerActive ? drawerVisible && nextEnabled : true"
    >
    </ets-solid-button>
  </div>
  <div class="solo-button-container" *ngIf="!overviewEnabled && !showSaveAndExit">
    <ets-solid-button class="next-question-button non-mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction"
                      (click)="nextEmit($event)"
                      [disabled]="isButtonDisabled"
    >
    </ets-solid-button>
    <ets-solid-button class="next-question-button mobile"
                      [text]="buttonText ? buttonText : nextBtnText"
                      [color]="APP_COLORS.uiaction"
                      (click)="nextEmit($event)"
                      [size]="mobileButtonSize"
                      [disabled]="isButtonDisabled"
                      *ngIf="drawerActive ? drawerVisible : true"
    >
    </ets-solid-button>
  </div>

  <div class="progress-bar-container">
    <div *ngFor="let ques of moduleList; index as index">
      <div class="progress-bar">
        <div class="progress-bar-section"
        [ngStyle]="{'width':progressSectionWidth,
        'background-color': index+1 < questionIndex! ? APP_COLORS.uiaction: APP_COLORS.lbbsoft}">
      </div>
        <div class="whitespace-bar" *ngIf="ques !==moduleList!.length"></div>
      </div>
    </div>
  </div>
</div>
