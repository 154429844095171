import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from '../../../../global-styles';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { answerMap, surveyAnswers} from 'src/app/data/sne/oral-communication/01_Informative-1';
import { SneAnswerService } from '../../../services/sne-answer.service';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';

@Component({
  selector: 'ets-oc-multi-choice-list-video',
  templateUrl: './oc-multi-choice-list-video.component.html',
  styleUrls: ['./oc-multi-choice-list-video.component.scss']
})
export class OcMultiChoiceListVideoComponent extends SneOralCommunicationBase implements OnInit {
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  numAnswers: number = 0;
  surveyOptions?: string[] = surveyAnswers;
  answersMap: Map<string, string> = answerMap;


  selectAnswer(questionIndex: number, answer: string) {
    this.multiResponse[questionIndex] = answer;
  }

  disableNextButton(): boolean {
    let disable = false;
    if (this.multiResponse.length === this.questionData?.numSelectOptions) {
      this.multiResponse.forEach((answer: string) => {
        // if answer is not set for any question then disable next button
        if (!answer && !disable) {
          disable = true;
        }
      });
    } else {
      disable = true;
    }
    return disable;
  }

  ngOnInit(): void {
    this.processParams();
    if (this.questionData?.numSelectOptions && this.questionData?.numSelectOptions > 1) {
      this.multiResponse = new Array(this.questionData.numSelectOptions).fill(null);
    }
  }

}
