<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>
<ets-sne-split-screen
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="right">
    <ets-sne-multi-choice-list
      [inputType]="questionData?.inputType"
      [headerQuestion]="questionData?.header"
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
      (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
    >
    </ets-sne-multi-choice-list>
  </div>
  <div class="left">
    <ets-sne-social-media-post
      [posts]="questionData?.socialMediaPost"
      [firstColorIndex]="1"
      [colorArray]="['white','green']"
    >

    </ets-sne-social-media-post>

  </div>
  </ets-sne-split-screen>
  <ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  (overviewEmitter)="openInstructionModal()"
  (nextEmitter)="getNextQuestion()"
  [questionNum]="questionNum"
  [isButtonDisabled]="
    questionData?.inputType === SNE_INPUT_TYPES.RADIO
      ? multiResponse.length !== questionData?.multipleMulChoiceOptions?.length
      : multiResponse.length !== questionData?.numSelectOptions
  "
>
  <ets-sne-multi-choice-list
    [inputType]="questionData?.inputType"
    [headerQuestion]="questionData?.header"
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
    (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
    [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
</ets-modal>

