import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthService } from '../services/auth.service';
import { selectProfile } from '../store';
import { filter, switchMap, take } from 'rxjs/operators';
import Question from '../types/question';
import QUESTION_TYPES from '../types/question-types';
import Profile from '../types/profile';
import { QUESTION_LIST_V2 } from '../data/sign-up-questions-v2';

@Injectable({
  providedIn: 'root'
})
export class OnboardingCompleteGuard implements CanActivate, CanActivateChild {

  questionList: Question[] = QUESTION_LIST_V2

  constructor(private store: Store, private authService: AuthService, private router: Router) {}

  getQuestionComponentType(qt: QUESTION_TYPES) {
    switch (qt) {
      case QUESTION_TYPES.INTRO:
        return 'start';
      case QUESTION_TYPES.SINGLE_QUESTION_RESPONSE:
        return 'single-question';
      case QUESTION_TYPES.MULTI_MULTIPLE_CHOICE:
      case QUESTION_TYPES.SINGLE_MULTIPLE_CHOICE:
        return 'multiple-choice';
      case QUESTION_TYPES.SEARCH_MULTI_RESPONSE:
        return 'search';
      case QUESTION_TYPES.TABLE_QUESTION:
        return 'table';
      case QUESTION_TYPES.YES_NO_QUESTION:
        return 'yes-no';
      case QUESTION_TYPES.INFO_BREAK:
        return 'break';
      case QUESTION_TYPES.CARD_RESPONSE:
        return 'cards';
      case QUESTION_TYPES.SLIDER:
        return 'slider';
      case QUESTION_TYPES.FIELD_OF_STUDY:
        return 'field-of-study';
    }
    return '';
  }

  navOnBoarding(profile: Profile | undefined, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let lastQuestionIndex = this.questionList.findIndex(q => q.page === profile?.lastPageVisited);
    let route;
    if (lastQuestionIndex === -1) {
      route = '/on-boarding/start/0';
    } else {
      const nextQuestionIndex = lastQuestionIndex + 1;
      const nextQuestion = this.questionList[nextQuestionIndex];
      if (nextQuestion === undefined) {
        // no more questions so go to dashboard
        route = '/dashboard';
      }
      let componentType = this.getQuestionComponentType(nextQuestion.type);
      route = '/on-boarding/' + componentType + '/' + nextQuestionIndex;
    }
    if (route === state.url) {
      return of(true);
    } else {
      return of(this.router.parseUrl(route));
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const $filteredProfile = this.store.select(selectProfile)
      .pipe(filter(profile => profile !== undefined), take(1));

    return $filteredProfile
      .pipe(switchMap(profile => {
        if (!profile?.onboardingComplete) {
          return this.navOnBoarding(profile, state);
        } else {
          return of(true);
        }
      }));
  }

  canActivateChild (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const $filteredProfile = this.store.select(selectProfile)
      .pipe(filter(profile => profile !== undefined), take(1));

    const baseRoute = route.pathFromRoot[1].url[0].toString();

    return $filteredProfile
      .pipe(switchMap(profile => {
        switch (baseRoute) {
          case 'on-boarding':
            if (profile?.onboardingComplete) {
              return of(this.router.parseUrl('/dashboard'));
            } else {
              return of(true);
            }
          default:
            if (!profile?.onboardingComplete) {
              return this.navOnBoarding(profile, state);
            } else {
              return of(true);
            }
        }
      }));
  }

}
