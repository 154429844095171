import { ActivatedRouteSnapshot } from '@angular/router';
import { SNE_GUARD_CONFIG, SneQuestion } from '../types/sne';
import { CPS_HRID } from '../sne-v2/sne-v2.helpers';

export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
}

export function isPathInList(questionList: string[], assessment: SNE_GUARD_CONFIG,
  currentPath: string, generateNextQuestionPath:any ): boolean {
  return !!questionList.find(questionKey => {
    const question = assessment.questionList.get(questionKey);
    if (question) {
      const _path = generateNextQuestionPath(question, questionKey, assessment.type);
      return currentPath === `/${_path}`;
    } else {
      return false;
    }
  });
}

export function validatePath(configList: SNE_GUARD_CONFIG[], assessments: any[], currentPath: string,
  generateNextQuestionPath: any): boolean {
  let pathFailed = false;
  let pathFound = false;
  let questionsKeyList;
  let nonQuestionsKeyList;
  configList.forEach(config => {
    if (!pathFailed || !pathFound) {
      const assessmentFound = assessments.find(_assessment => _assessment.assessmentHumanReadableID === config.id);
      questionsKeyList = Array.from(config.questionList.keys()).filter(k => k.includes('question'));

      if (assessmentFound) {
        if (assessmentFound.assessmentHumanReadableID === CPS_HRID.CPS_NEGOTIATION || assessmentFound.assessmentHumanReadableID === CPS_HRID.CPS_HIDDEN_PROFILE) {
          pathFound = isPathInList(questionsKeyList, config, currentPath, generateNextQuestionPath);
          if (pathFound && assessmentFound.completed) {
            pathFailed = !pathFailed;
          }
        } else {
          
          
          /*nonQuestionsKeyList = Array.from(config.questionList.keys()).filter(k => !k.includes('question'));
          pathFound = isPathInList(nonQuestionsKeyList, config, currentPath, generateNextQuestionPath);
          if (pathFound && assessmentFound.completed) {
            pathFailed = !pathFailed;
          }*/

          if (assessmentFound.answerJSON && !pathFound ) {
            let numOfQuestionCompleted = Object.keys(assessmentFound.answerJSON).length;
            const completedList = questionsKeyList.slice(0, numOfQuestionCompleted);
            pathFound = isPathInList(completedList, config, currentPath, generateNextQuestionPath);
            if (pathFound) {
              pathFailed = !pathFailed;
            }
          }
        }
      }
    }
  });

  return !pathFailed;
}
