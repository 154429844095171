import Question from '../types/question';
import QUESTION_TYPES from '../types/question-types';

export const higherEducationList = ['Vocational training',
  'Associates degree', 'Bachelors degree', 'Advanced degree', 'Bootcamp or technical training'];

export const generateNextQuestionPath = (nextQuestion: Question, nextIndex: number): string | undefined => {
  let path;
  const base = 'on-boarding/';
  switch (nextQuestion.type) {
    case QUESTION_TYPES.INTRO:
      path = `${base}/start/${nextIndex}`;
      break;
    case QUESTION_TYPES.SINGLE_MULTIPLE_CHOICE:
    case QUESTION_TYPES.MULTI_MULTIPLE_CHOICE:
      path = `${base}/multiple-choice/${nextIndex}`;
      break;
    case QUESTION_TYPES.SINGLE_QUESTION_RESPONSE:
      path = `${base}/single-question/${nextIndex}`;
      break;
    case QUESTION_TYPES.SEARCH_MULTI_RESPONSE:
      path = `${base}/search/${nextIndex}`;
      break;
    case QUESTION_TYPES.TABLE_QUESTION:
      path = `${base}/table/${nextIndex}`;
      break;
    case QUESTION_TYPES.YES_NO_QUESTION:
      path = `${base}/yes-no/${nextIndex}`;
      break;
    case QUESTION_TYPES.INFO_BREAK:
      path = `${base}/break/${nextIndex}`;
      break;
    case QUESTION_TYPES.CARD_RESPONSE:
      path = `${base}/cards/${nextIndex}`;
      break;
    case QUESTION_TYPES.SLIDER:
      path = `${base}/slider/${nextIndex}`;
      break;
    case QUESTION_TYPES.FIELD_OF_STUDY:
      path = `${base}/field-of-study/${nextIndex}`;
      break;
    case QUESTION_TYPES.EXPERIENCE_INPUT:
      path = `${base}/experience-input/${nextIndex}`;
      break;
    case QUESTION_TYPES.SKILLS_LOADING:
      path = `${base}/loading-skills-page/${nextIndex}`;
      break;
    case QUESTION_TYPES.SKILL_SELECTION_OVERVIEW:
      path = `${base}/skill-selection-overview/${nextIndex}`;
      break;
    case QUESTION_TYPES.TRANSFERABLE_JOB_SPECIFIC_SKILLS:
      path = `${base}/transferable-and-job-specific-skills/${nextIndex}`;
      break;
    case QUESTION_TYPES.TOP_SKILLS:
      path = `${base}/top-skills/${nextIndex}`;
      break;
    case QUESTION_TYPES.RESUME_OPTION:
      path =  `${base}/resume-option/${nextIndex}`;
      break;
    case QUESTION_TYPES.RESUME_ATTACH:
      path =  `${base}/resume-attach/${nextIndex}`;
      break;
    case QUESTION_TYPES.WORK_HISTORY_START:
      path = `${base}/work-history/${nextIndex}`;
      break;
    default:
      console.log('On-boarding Questions: question type could not be found');
  }
  return path;
};
