import { ActivatedRoute, Router } from '@angular/router';
import { CTDeductionData, CTDeductionInstructionModal } from 'src/app/data/sne/critical-thinking/01_Deduction';
import { CTProductionData, CTProductionInstructionModal } from 'src/app/data/sne/critical-thinking/02_Production';
import { CTRhetoricalData, CTRhetoricalInstructionModal } from 'src/app/data/sne/critical-thinking/03_Rhetorical';
import { CTSchedulingData, CTSchedulingInstructionModal } from 'src/app/data/sne/critical-thinking/04_Scheduling';
import {
  CTArgumentation1Data,
  CTArgumentation1InstructionModal
} from 'src/app/data/sne/critical-thinking/05_Argumentation-1';
import {
  CTArgumentation2Data,
  CTArgumentation2InstructionModal
} from 'src/app/data/sne/critical-thinking/06_Argumentation-2';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';
import { SNE_INPUT_TYPES, SNE_TASK_ENUM, SneAnswerRequest, SneModal, SneQuestion } from '../../types/sne';
import * as _ from 'lodash';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { generateNextQuestionPath } from './sne-critical-thinking.helpers';

export class SneCriticalThinkingBase {
  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService?: SneAnswerService) { }

    questionNum: string | undefined;
    questionData: SneQuestion | undefined;
    questionIndex: number | undefined;
    moduleIndex: number | undefined;
    moduleSize: number = 6;
    questionKeyList: string[] = [];
    ctType: CT_TYPES | undefined;
    ctData: Map<string, SneQuestion> | undefined;
    showInstructionModal = false;
    instructionModal: SneModal | undefined;
    singleResponse: string = '';
    multiResponse: string[] = [];
    madlibsResponse: { [key: number]: string | number } = {};
    startTimestamp!: string;
    endTimestamp!: string;
    showHintModal = false;

    SNE_INPUT_TYPES = SNE_INPUT_TYPES;

    additionalNextQuestionSteps() { }

    generateNextSectionPath() {
      let path: string | undefined;
      if (this.questionData && this.questionData.goto) {
        switch (this.questionData.goto) {
          case CT_TYPES.DEDUCTION:
            path = generateNextQuestionPath(
              Array.from(CTDeductionData.values())[0],
              Array.from(CTDeductionData.keys())[0],
              CT_TYPES.DEDUCTION,
              this.additionalNextQuestionSteps
            );
            break;
          case CT_TYPES.PRODUCTION:
            path = generateNextQuestionPath(
              Array.from(CTProductionData.values())[0],
              Array.from(CTProductionData.keys())[0],
              CT_TYPES.PRODUCTION,
              this.additionalNextQuestionSteps
            );
            break;
          case CT_TYPES.RHETORICAL:
            path = generateNextQuestionPath(
              Array.from(CTRhetoricalData.values())[0],
              Array.from(CTRhetoricalData.keys())[0],
              CT_TYPES.RHETORICAL,
              this.additionalNextQuestionSteps
            );
            break;
          case CT_TYPES.SCHEDULING:
            path = generateNextQuestionPath(
              Array.from(CTSchedulingData.values())[0],
              Array.from(CTSchedulingData.keys())[0],
              CT_TYPES.SCHEDULING,
              this.additionalNextQuestionSteps
            );
            break;
          case CT_TYPES.ARGUMENTATION_1:
            path = generateNextQuestionPath(
              Array.from(CTArgumentation1Data.values())[0],
              Array.from(CTArgumentation1Data.keys())[0],
              CT_TYPES.ARGUMENTATION_1,
              this.additionalNextQuestionSteps
            );
            break;
          case CT_TYPES.ARGUMENTATION_2:
            path = generateNextQuestionPath(
              Array.from(CTArgumentation2Data.values())[0],
              Array.from(CTArgumentation2Data.keys())[0],
              CT_TYPES.ARGUMENTATION_2,
              this.additionalNextQuestionSteps
            );
            break;
          default:
            path= this.questionData.goto;
            console.error('Cannot find question type');
        }
      }
      return path;
    }

    navToNextPage() {
      //make call to backend to save response
      let path: string | undefined;
      if (this.ctData) {
        const keysArray = Array.from(this.ctData.keys());
        let keyIndex = keysArray.findIndex(key => key === this.questionNum);
        if (keyIndex !== -1) {
          if (keyIndex < keysArray.length - 1) {
            keyIndex++; // go to next key in the question map
            const nextKey = keysArray[keyIndex];
            const newQuestion = this.ctData.get(nextKey);
            if (newQuestion && this.ctType) {
              path = generateNextQuestionPath(newQuestion, nextKey, this.ctType, this.additionalNextQuestionSteps);
            }

          } else {
            path = this.generateNextSectionPath();
          }

        }
      }
      if (path) {
        this.router.navigate([path]);
      }
    }

    getNextQuestion() {
      if (this.questionData?.modalBeforeNext) {
        this.openInstructionModal();
      } else {
        this.submit();
        this.navToNextPage();
      }
    }

    submit() {
      if (this.sneAnswerService) {
        if (this.questionIndex !== undefined && this.questionData?.submitId) {
          this.endTimestamp = Date.now().toString();
          const completed = !!this.questionData?.complete;
          const data: { [key: string]: any } = {};
          if (this.singleResponse) {
            data[this.questionData.submitId] = {
              start: this.startTimestamp,
              response: this.singleResponse,
              end: this.endTimestamp
            };
          } else if (this.multiResponse.length > 0) {
            let aggResponse: string = '';
            this.multiResponse.forEach(response => {
              aggResponse = aggResponse + response;
            });
            data[this.questionData.submitId] = {
              start: this.startTimestamp,
              response: aggResponse,
              end: this.endTimestamp
            };
          } else if (!_.isEmpty(this.madlibsResponse)) {
            let aggResponse: string = '';
            for (const [key, value] of Object.entries(this.madlibsResponse)) {
              aggResponse = aggResponse + value;
            }
            ;
            data[this.questionData.submitId] = {
              start: this.startTimestamp,
              response: aggResponse,
              end: this.endTimestamp
            };
          }

          if (!_.isEmpty(data)) {
            let task: SNE_TASK_ENUM | undefined;
            switch (this.ctType) {
              case CT_TYPES.DEDUCTION:
                task = SNE_TASK_ENUM.DEDUCTION;
                break;
              case CT_TYPES.PRODUCTION:
                task = SNE_TASK_ENUM.PRODUCTION;
                break;
              case CT_TYPES.RHETORICAL:
                task = SNE_TASK_ENUM.RHETORICAL;
                break;
              case CT_TYPES.SCHEDULING:
                task = SNE_TASK_ENUM.SCHEDULING;
                break;
              case CT_TYPES.ARGUMENTATION_1:
                task = SNE_TASK_ENUM.ARGUMENTATION_1;
                break;
              case CT_TYPES.ARGUMENTATION_2:
                task = SNE_TASK_ENUM.ARGUMENTATION_2;
                if (completed) {
                  let requestBody: SneAnswerRequest = {
                    task: SNE_TASK_ENUM.CRT,
                    data: null,
                    completed: completed
                  };
                  this.sneAnswerService.postAction(requestBody).subscribe();
                  console.log('Critical Thinking completed.');
                }
                break;
              default:
                console.error(`Critical Thinking Type could not be identified: ${this.ctType}`);
            }
            if (task) {
              let requestBody: SneAnswerRequest = {
                task: task,
                data: data,
                completed: completed
              };
              this.sneAnswerService.postAction(requestBody).subscribe(response => {
                console.log(`Response to Critical Thinking post request:  + ${response}`);
                this.singleResponse = '';
                this.multiResponse = [];
                this.madlibsResponse = {};
              });
            }
          }
        }
      }
    }

    closeInstructionModal() {
      this.showInstructionModal = false;
    }

    openInstructionModal() {
      this.showInstructionModal = true;
    }
    hintToggle(){
      this.showHintModal = !this.showHintModal;
    }

    closeModalAndNav() {
      this.closeInstructionModal();
      this.navToNextPage();
    }

    additionalParamProcess() { }

    setData(wcData: Map<string, SneQuestion>) {
      if (this.questionNum) {
        console.log(this.questionNum);
        this.ctData = wcData;
        this.questionData = this.ctData.get(this.questionNum);
        console.log(this.questionData);
        this.questionKeyList = Array.from(this.ctData.keys());
        console.log(this.questionKeyList);
        this.questionIndex = this.questionKeyList
          .findIndex(key => key === this.questionNum);
        console.log(this.questionIndex);
      }
    }

    processParams() {
      this.route.params.subscribe(params => {
        const { questionNum, section } = params;
        this.ctType = section;
        this.questionNum = questionNum;
        this.startTimestamp = Date.now().toString();

        if (this.questionNum && this.ctType) {
          switch (this.ctType) {
            case CT_TYPES.DEDUCTION:
              this.moduleIndex = 1;
              this.setData(CTDeductionData);
              this.instructionModal = CTDeductionInstructionModal;
              break;
            case CT_TYPES.PRODUCTION:
              this.moduleIndex = 2;
              this.setData(CTProductionData);
              this.instructionModal = CTProductionInstructionModal;
              break;

            case CT_TYPES.RHETORICAL:
              this.moduleIndex = 3;
              this.setData(CTRhetoricalData);
              this.instructionModal = CTRhetoricalInstructionModal;
              break;
            case CT_TYPES.SCHEDULING:
              this.moduleIndex = 4;
              this.setData(CTSchedulingData);
              this.instructionModal = CTSchedulingInstructionModal;
              break;
            case CT_TYPES.ARGUMENTATION_1:
              this.moduleIndex = 5;
              this.setData(CTArgumentation1Data);
              this.instructionModal = CTArgumentation1InstructionModal;
              break;
            case CT_TYPES.ARGUMENTATION_2:
              this.moduleIndex = 6;
              this.setData(CTArgumentation2Data);
              this.instructionModal = CTArgumentation2InstructionModal;
              break;
            default:
              console.error(`Critical Thinking Type could not be identified: ${this.ctType}`);
          }
          this.additionalParamProcess();
        }
      });
    }
}
