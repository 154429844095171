import { CARD_SIZE_ENUM } from './../../types/question';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ets-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  constructor() { }
  
  CARD_SIZE_ENUM = CARD_SIZE_ENUM;
  blueCheck: string = '../assets/icons/Blue-check.svg';

  @Input() title?: string = '';
  @Input() description?: string = '';
  @Input() image?: string = '';
  @Input() cardId?: number;
  @Input() cardSize?: CARD_SIZE_ENUM;
  @Input() selectedFlag?: boolean = false;

  @Output() isCardSelected = new EventEmitter<any>();

  handleClick() {
    this.isCardSelected.emit(this.cardId)
  }

}
