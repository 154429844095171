import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SneChangingCircumstancesVideos } from 'src/app/data/sne/coachability/02_changing-circumstances';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SneVideos } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCoachabilityBase } from '../sne-coachability.base';

@Component({
  selector: 'ets-coachability-multi-choice-video',
  templateUrl: './coachability-multi-choice-video.component.html',
  styleUrls: ['./coachability-multi-choice-video.component.scss']
})
export class CoachabilityMultiChoiceVideoComponent extends SneCoachabilityBase implements OnInit, OnDestroy {

  constructor(router: Router, route: ActivatedRoute, protected sneAnswerService: SneAnswerService) {
    super(route, router);
  }

  subs: Subscription[] = [];
  showModal = false;
  showHintModal = true;

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  videos: SneVideos[] = SneChangingCircumstancesVideos;

  toggleModal() {
    this.showModal = !this.showModal;
  }

  toggleHintModal() {
    this.showHintModal = !this.showHintModal;
  }

  completeChangingCircumstancesTask() {
    this.getNextQuestion();
  }

  ngOnInit() {
    this.processParams();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  handleUserResponse(emittedVideoId: string) {
    const vidFound = this.videos.find(video => video.id === emittedVideoId);
    if (vidFound && vidFound.answerChoice) {
      let arrIndex = this.multiResponse.indexOf(vidFound.answerChoice);
      if (arrIndex > -1) {
        // if answer is already in the array, remove it at arrIndex
        this.multiResponse.splice(arrIndex, 1);
      } else {
        this.multiResponse.push(vidFound.answerChoice);
        this.multiResponse.sort();
      }

    }
  }

}

