import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ets-assessment-complete',
  templateUrl: './assessment-complete.component.html',
  styleUrls: ['./assessment-complete.component.scss']
})
export class AssessmentCompleteComponent implements OnInit {

  constructor() { }

  @Input() completion = 0;
  @Input() total = 0;

  ngOnInit(): void {
  }

}
