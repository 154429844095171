import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CipQuestionsComponent } from './cip-questions.component';
import { QuestionRankingComponent } from './components/question-ranking/question-ranking.component';
import { CipIntroComponent } from './components/cip-intro/cip-intro.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';

@NgModule({
  declarations: [
    CipQuestionsComponent,
    QuestionRankingComponent,
    CipIntroComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ClarityModule
  ]
})
export class CipModule { }
