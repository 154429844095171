import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function startDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = new Date();
    date.setDate(1);
    const formGroup = control as FormGroup;
    const fromMonthControl = formGroup.get('fromMonth');
    const fromYearControl = formGroup.get('fromYear');

    if (!fromMonthControl || !fromYearControl) {
      return null;
    }

    if (isNaN(fromMonthControl.value) || !fromYearControl.value) {
      return null;
    }

    const fromDate = moment(new Date(+fromYearControl.value, +fromMonthControl.value));
    const todayDate = moment(date);
    const duration = moment.duration(todayDate.diff(fromDate));

    const monthDuration = duration.asMonths();
    if (monthDuration < 0) {
      return {'startDate': true};
    }

    return null;
  };
}

export function endDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = new Date();
    date.setDate(1);
    const formGroup = control as FormGroup;
    const toMonthControl = formGroup.get('toMonth');
    const toYearControl = formGroup.get('toYear');

    if (!toMonthControl || !toYearControl) {
      return null;
    }

    if (isNaN(toMonthControl.value) || !toYearControl.value) {
      return null;
    }


    const toDate = moment(new Date(+toYearControl.value, +toMonthControl.value));
    const todayDate = moment(date);
    const duration = moment.duration(todayDate.diff(toDate));

    const monthDuration = duration.asMonths();
    if (monthDuration < 0) {
      return {'endDate': true};
    }
    return null;
  };
}

export function startToEndDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;

    const fromMonthControl = formGroup.get('fromMonth');
    const fromYearControl = formGroup.get('fromYear');

    const toMonthControl = formGroup.get('toMonth');
    const toYearControl = formGroup.get('toYear');

    if (!toMonthControl || !toYearControl) {
      return null;
    }

    if (isNaN(toMonthControl.value) || !toYearControl.value) {
      return null;
    }

    if (!fromMonthControl || !fromYearControl) {
      return null;
    }

    if (isNaN(fromMonthControl.value) || !fromYearControl.value) {
      return null;
    }

    const fromDate = moment(new Date(+fromYearControl.value, +fromMonthControl.value));
    const toDate = moment(new Date(+toYearControl.value, +toMonthControl.value));

    const duration = moment.duration(toDate.diff(fromDate));

    const monthDuration = duration.asMonths();
    if (monthDuration < 0) {
      return { 'startToEndDate': true };
    }

    return null;
  };
}
