<ets-header [isBGWhite]="true"
[headerState]="headerState"
[showBackButton]="false"
[simpleBack]="true">
</ets-header>
<div class="container">
  <ets-split-screen-intro
    [numberText]="numberText"
    [title]="questionData?.title"
    [info]="questionData?.info"
    [buttonText]="questionData?.buttonText"
    [buttonColor]="APP_COLORS.uiaction"
    [desktopImageSrc]="questionData?.image"
    [tabletImageSrc]="questionData?.image"
    [mobileImageSrc]="questionData?.image"
    [mobileButtonSize]="'md'"
    (clickEvent)="onClick()"
    [isRoundedButton]="true"
    [leftBgColor]="'#E8EFFE'"
    [fullSectionBgImage]="true"
  ></ets-split-screen-intro>
</div>
