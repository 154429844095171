import Question from '../types/question';
import QUESTION_TYPES, { QUESTION_SECTIONS } from '../types/question-types';
import { CipAnswer } from '../types/cip';
import CIP_PAGES from '../types/cip-pages.types';

export const CIP_QUESTION_LIST: Question[] = [
  {
    type: QUESTION_TYPES.INTRO,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'Your Career Path',
    info: 'Help us gain insight into your career aspirations and interests so we can guide you on the right path.',
    page: CIP_PAGES.SECTION_THREE_START
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Building kitchen cabinets',
    //   'Develop a new medicine',
    //   'Write books or plays',
    //   'Teach an individual an exercise routine',
    //   'Buy and sell stocks & bonds',
    //   'Develop a spreadsheet using computer software'
    // ],
    answers: [],
    numbers: ['CII_02', 'CII_12', 'CII_22', 'CII_32', 'CII_42', 'CII_52'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_1
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Lay brick or tile',
    //   'Study ways to reduce water pollution',
    //   'Play a musical instrument',
    //   'Help people with personal or emotional problems',
    //   'Manage a retail store',
    //   'Proofread records or forms'
    // ],
    answers: [],
    numbers: ['CII_03', 'CII_13', 'CII_23', 'CII_33', 'CII_43', 'CII_53'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_2
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Repair household items',
    //   'Conduct chemical experiments',
    //   'Compose or arrange music',
    //   'Give career guidance to people',
    //   'Operate a beauty salon or barbershop',
    //   'Load computer software into a large computer network'
    // ],
    answers: [],
    numbers: ['CII_04', 'CII_14', 'CII_24', 'CII_34', 'CII_44', 'CII_54'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_3
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Raise fish in a hatchery',
    //   'Study the movements of planets',
    //   'Draw pictures',
    //   'Perform rehabilitation therapy',
    //   'Manage a department within a large company',
    //   'Operate a calculator'
    // ],
    answers: [],
    numbers: ['CII_05', 'CII_15', 'CII_25', 'CII_35', 'CII_45', 'CII_55'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_4
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Assemble electronic parts',
    //   'Examine blood samples using a microscope',
    //   'Create special effects for movies',
    //   'Do volunteer work at a non-profit organization',
    //   'Start your own business',
    //   'Keep shipping and receiving records'
    // ],
    answers: [],
    numbers: ['CII_06', 'CII_16', 'CII_26', 'CII_36', 'CII_46', 'CII_56'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_5
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Drive a truck to deliver packages to offices and homes',
    //   'Investigate the cause of a fire',
    //   'Paint sets for plays',
    //   'Teach children how to play sports',
    //   'Negotiate business contracts',
    //   'Calculate the wages of employees'
    // ],
    answers: [],
    numbers: ['CII_07', 'CII_17', 'CII_27', 'CII_37', 'CII_47', 'CII_57'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_6
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Test the quality of parts before shipment',
    //   'Develop a way to better predict the weather',
    //   'Write scripts for movies or television shows',
    //   'Teach sign language to people with hearing disabilities',
    //   'Represent a client in a lawsuit',
    //   'Inventory supplies using a hand-held computer'
    // ],
    answers: [],
    numbers: ['CII_08', 'CII_18', 'CII_28', 'CII_38', 'CII_48', 'CII_58'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_7
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Repair and install locks',
    //   'Work in a biology lab',
    //   'Perform jazz or tap dance',
    //   'Help conduct a group therapy session',
    //   'Market a new line of clothing',
    //   'Record rent payments'
    // ],
    answers: [],
    numbers: ['CII_09', 'CII_19', 'CII_29', 'CII_39', 'CII_49', 'CII_59'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_8
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Set up and operate machines to make products',
    //   'Invent a replacement for sugar',
    //   'Sing in a band',
    //   'Take care of children at a day-care center',
    //   'Sell merchandise at a department store',
    //   'Keep inventory records'
    // ],
    answers: [],
    numbers: ['CII_10', 'CII_20', 'CII_30', 'CII_40', 'CII_50', 'CII_60'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_9
  },
  {
    type: QUESTION_TYPES.TABLE_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'How much would you enjoy these activities?',
    choices: [],
    // choices: [
    //   'Put out forest fires',
    //   'Do laboratory tests to identify diseases',
    //   'Edit movies',
    //   'Teach a high-school class',
    //   'Manage a clothing store',
    //   'Stamp, store, and distribute mail for an organization'
    // ],
    answers: [],
    numbers: ['CII_11', 'CII_21', 'CII_31', 'CII_41', 'CII_51', 'CII_61'],
    headers: 'ENJOY',
    page: CIP_PAGES.CIP_PAGE_10
  }
];

export const CIP_ANSWERS: CipAnswer[] = [];

