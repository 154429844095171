import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor() {}

  @Input() questionIndex: number | undefined;
  @Input() questionListLength: number | undefined;
  @Input() progress: string | undefined;
  @Input() showQuestionNumbers: boolean = true;
  @Input() progressBgColor: string = '#0E3AD6';
  // @Input() questionNumColor: string = '#ffffff';
  @Input() questionNumColor: string = '#0E3AD6';
  @Input() displayProgressBar: boolean = true;

}

