<div class="yes-no-container">
  <div class="title-container">
    <h1 class="title"> {{ currentQuestion?.question }} </h1>
  </div>
  <div class="yes-no-btn-group">
    <button class="yes_btn" (click)="select(true)">Yes</button>
    <button class="no_btn" (click)="select(false)">No</button>
  </div>
</div>

<ets-why-we-ask-modal *ngIf="currentQuestion && currentQuestion.whyWeAskModal" [whyWeAskData]="currentQuestion.whyWeAskModal"></ets-why-we-ask-modal>
