import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { QUESTION_SECTIONS } from '../../../types/question-types';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { selectProfile } from '../../../store';
import { setProfile } from 'src/app/store/profile.actions';
import { EventService } from 'src/app/services/event.service';
import OB_PAGES from 'src/app/types/on-boarding-pages.types';
import { APP_COLORS } from '../../../../global-styles';

@Component({
  selector: 'ets-new-question-info-break',
  templateUrl: './info-break.component.html',
  styleUrls: ['./info-break.component.scss']
})
export class InfoBreakComponent extends OnBoardingV2Base implements OnInit, OnDestroy {

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  question: string = '';
  QUESTION_SECTIONS = QUESTION_SECTIONS;
  OB_PAGES = OB_PAGES;
  APP_COLORS = APP_COLORS;

  processTitle = () => {
    this.store.select(selectProfile).subscribe(profile => {
      if (this.currentQuestion && profile) {
        if (this.currentQuestion?.questionFunc && profile?.preferredName) {
          this.question = this.currentQuestion.questionFunc(profile.preferredName);
        } else if (this.currentQuestion?.question) {
          this.question = this.currentQuestion.question;
        }
      }
    });
  }

  ngOnInit() {
    this.initialize();
    this.processTitle();
  }

  ngOnDestroy() {
    this.teardown();
  }
}
