<div class="container">
  <div class="welcome-back-wrapper">
    <span class="welcome">Welcome</span>
    <span class="username">{{profile?.preferredName}}</span>
  </div>

  <div class="col-wrapper">
    <div class="title-wrapper mobile-display-none">
      <span class="title">MY CAREER CHECKLIST</span>
      <ets-tooltip  [text]="careerChecklistTip"></ets-tooltip>
    </div>
    <ets-career-checklist [assessmentRecords]="assessmentRecords" [skillsAssessments]="skillsAssessments"></ets-career-checklist>
  </div>

  <div class="divider"></div>

  <div class="col-wrapper col-max-width">
    <div class="title-wrapper">
      <span class="title">MY FOUNDATION</span>
      <ets-tooltip  [text]="myFoundationTip"></ets-tooltip>
    </div>

    <ets-foundation [dashboardProfile]="dashboardProfile"></ets-foundation>
  </div>

  <div class="divider"></div>

  <div class="col-wrapper">
    <div class="title-wrapper">
      <span class="title">MY TOP SKILLS</span>
    </div>

    <ets-top-skills [topSkills]="topSkills"></ets-top-skills>
  </div>

  <div class="divider"></div>

  <div class="col-wrapper col-max-mobile">
    <div class="title-wrapper">
      <span>MY RESUME</span>
    </div>

    <ets-resume></ets-resume>
  </div>

  <!-- <div class="col-wrapper gap">
    <ets-open-new-roles></ets-open-new-roles>
  </div> -->

  <div class="mobile-display-none gap">
    <ets-feedback></ets-feedback>
  </div>
</div>
