import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { APP_COLORS } from 'src/global-styles';
import { DashboardService } from '../services/dashboard.service';
import { EventActionEnum, EventSectionEnum } from '../types/event';
import { Assessment } from '../types/assessments';
import { Career, DashboardProfile, TopSkill } from '../types/dashboard';
import { Store } from '@ngrx/store';
import { selectProfile } from '../store';
import Profile from '../types/profile';
import { HEADER_STATES } from '../shared/header/header.helper';
import { SneAssessmentRecord } from '../types/sne';
import { SneAnswerService } from '../services/sne-answer.service';

@Component({
  selector: 'ets-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.scss']
})
export class DashboardV2Component implements OnInit, OnDestroy {

  constructor(private dashboardDetailService: DashboardService,
              private sneAnswerService: SneAnswerService,
              private eventService: EventService,
              private store: Store
  ) { }

  APP_COLORS = APP_COLORS;
  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];
  // learnerAssessments: Array<any> | undefined;
  HEADER_STATES = HEADER_STATES;

  assessments: Assessment[] = [];
  careers: Career[] = [];
  dashboardProfile: DashboardProfile | undefined;
  profile: Profile | undefined;
  topSkills: TopSkill[] = [];
  skillAssessmentsStarted: boolean = false;
  numOfSkillAssessments: number = 0;
  behaviorAssessmentsStarted: boolean = false;
  numOfBehaviorAssessments: number = 0;
  facetsResults: Assessment | undefined;
  cipResults: Assessment | undefined;
  assessmentRecords: SneAssessmentRecord[] = [];

  getSkillsAssessments() {
    this.subArr.push(this.dashboardDetailService.getDashboardData().subscribe({
      next: response => {
        try {
          if(response) {
            this.assessments = response.assessments;
            this.careers = response.careers;
            this.dashboardProfile = response.profile;
            this.topSkills = response.topSkills;
            this.checkAssessmentsProgress();
          }
        } catch {
          console.error('API ERROR: Get Skills Assessments');
        }
      }
    }));
  }

  checkAssessmentsProgress() {
    if (this.assessments.length > 0) {
      this.facetsResults = this.assessments.find(assessment => assessment.assessmentID === 'PSQ');
      this.cipResults = this.assessments.find(assessment => assessment.assessmentID === 'CIP');

      this.skillAssessmentsStarted = !!this.assessments.find(assessment => {
        return (
          (assessment.status === 'Completed' || assessment.status === 'In progress')
          && (assessment.assessmentID !== 'PSQ' && assessment.assessmentID !== 'CIP')
        );
      });
      this.behaviorAssessmentsStarted = !!this.assessments.find(assessment => {
        return (
          (assessment.status === 'Completed' || assessment.status === 'In progress')
          && (assessment.assessmentID === 'PSQ' || assessment.assessmentID === 'CIP')
        );
      });

      this.assessments.forEach(assessment => {
        if ((assessment.status === 'Completed' || assessment.status === 'In progress')
            && (assessment.assessmentID !== 'PSQ' && assessment.assessmentID !== 'CIP')) {
          this.numOfSkillAssessments++;
        }

        if ((assessment.status === 'Completed' || assessment.status === 'In progress')
          && (assessment.assessmentID === 'PSQ' || assessment.assessmentID === 'CIP')) {
          this.numOfBehaviorAssessments++;
        }
      });
    }
  }

  getAssessmentRecords() {
    this.sneAnswerService.getSneAssessmentRecords().subscribe(assessments => {
      this.assessmentRecords = assessments;
    });
  }

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => this.profile = profile);
  }

  ngOnInit(): void {
    document.title = 'Apprize | Dashboard';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Careers Dashboard',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Careers Dashboard v2', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Careers Dashboard v2', EventActionEnum.PAGE_VIEW, EventSectionEnum.DASHBOARD);
    // Load Service Functions
    this.getSkillsAssessments();
    this.getAssessmentRecords();
    this.getProfile();
  }

  ngOnDestroy() {
    this.subArr.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
