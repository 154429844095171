import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Email } from 'src/app/types/sne';
import { APP_COLORS } from '../../../../global-styles';

@Component({
  selector: 'ets-sne-email-layout',
  templateUrl: './sne-email-layout.component.html',
  styleUrls: ['./sne-email-layout.component.scss']
})
export class SneEmailLayoutComponent {

  @Input() subject?: string = '';
  @Input() emails: Email[] = [];
  @Input() firstColorIndex?: number;
  @Input() colorArray: string[] = [];
  @Input() largeSubjectLine: boolean = false;

  constructor() { }
  @Input() hintExists = false;
  @Output() hintModalEvent = new EventEmitter<any>();
  APP_COLORS = APP_COLORS;
  

  heightToScroll=0;

  hintModalClick(){
    this.hintModalEvent.emit();
  }

  chooseColor(messageIndex: number, intendedColor: string): boolean {
    const colorIndex = this.colorArray.findIndex(color => color === intendedColor);

    if (this.colorArray.length > 1) {
      if (messageIndex % 2 !== 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 === 0 && colorIndex !== -1;
      }
    } else {
      if (messageIndex % 2 === 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 !== 0 && colorIndex !== -1;
      }
    }
  }
}
