import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import FacetsQuestion, { FacetsFinalQuestionResponse, FacetsGetAssessmentsResponse, FacetsRequestBody, FacetsScoreResponse } from '../types/facets';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FacetsService extends BaseService{

  // private facetsUrl = 'https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/qa/facets';
  private facetsUrl = environment.facetsAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Facets';
  }

  initiateTestSession(): Observable<FacetsQuestion> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      // The default Facet's test ID is hardcoded on the BE in Secrets Manager. Only pass in the test ID if you would 
      // like to override the ID (i.e. - the shorter quiz for testing purposes) that's in the BE
      let body = {
        // id: "ACCENTURE_WS_13", // 13 question quiz for testing purposes
        action: 'init'
      };
      let requestLogObj: LogObject = {
        message: 'FACETS: Initiate Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsQuestion>(this.facetsUrl, body, {headers}).pipe(
        tap(data => { 
          let responseLogObj: LogObject = {
            message: 'FACETS: Initiate Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  resumeTestSession(sessionID: string): Observable<FacetsQuestion> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = {
        sessionID: sessionID,
        action: 'resume'
      };
      let requestLogObj: LogObject = {
        message: 'FACETS: Resume Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsQuestion>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'FACETS: Resume Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  saveAndGetNextQuestion(requestBody: FacetsRequestBody): Observable<FacetsQuestion> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'FACETS: Next Question Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsQuestion>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {          
          let responseLogObj: LogObject = {
            message: 'FACETS: Next Question Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  saveFinalQuestion(requestBody: FacetsRequestBody): Observable<FacetsFinalQuestionResponse> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'FACETS: Save Final Question Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsFinalQuestionResponse>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'FACETS: Save Final Question Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  getScoreResults(requestBody: FacetsRequestBody): Observable<FacetsScoreResponse> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'FACETS: Score Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsScoreResponse>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'FACETS: Score Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  updateScoreResults(requestBody: FacetsRequestBody): Observable<FacetsScoreResponse> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'FACETS: Update Score Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsScoreResponse>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'FACETS: Update Score Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  getAssessments(requestBody: FacetsRequestBody): Observable<FacetsGetAssessmentsResponse> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'FACETS: Get Assessments Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<FacetsGetAssessmentsResponse>(this.facetsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'FACETS: Get Assessments Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }
}

