<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="false"
>
</ets-header>

<ets-sne-split-screen
   [rightBackgroundColor]="APP_COLORS.white"
   [hideMobile]="'right'">
  <div class="left">
    <div class="container">
      <div class="overview-wrapper">
        <span class="overview">President Beth Sharpe's Memo Draft</span>
      </div>
      <div class="content">
        <span class="title">{{questionData?.subtitle}}</span>
          <div class="sentence" [innerHTML]="questionData?.memo"></div>
      </div>
    </div>
  </div>
  <div class="right">
    <ets-single-multi-choice-question
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType"
    >
    </ets-single-multi-choice-question>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="!singleResponse"
>
  <ets-single-multi-choice-question
    [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    [headerQuestion]="questionData?.header"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-single-multi-choice-question>
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-email-layout *ngIf="instructionModal?.customContent[0].to"
                          [emails]="instructionModal?.customContent"
    ></ets-sne-email-layout>
    <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user"
                                   [chatLog]="instructionModal?.customContent"
                                   [showHeader]="false"
    ></ets-sne-conversation-log-text>
  </div>
</ets-modal>
