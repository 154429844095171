import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';

@Component({
  selector: 'ets-multi-choice-video-choice-full',
  templateUrl: './multi-choice-video-choice-full.component.html',
  styleUrls: ['./multi-choice-video-choice-full.component.scss']
})
export class MultiChoiceVideoChoiceFullComponent extends SneOralCommunicationBase implements OnInit {
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }
  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;

  handleUserResponse(emittedVideoId: string) {
    const vidFound = this.questionData?.videos?.find(
      // using includes here because mobile videos will have an id of "<videoId>-mobile"
      (video) => emittedVideoId.includes(video.id)
    );

    if (vidFound && vidFound.answerChoice) {
      if (this.questionData?.inputType === SNE_INPUT_TYPES.RADIO)
        this.singleResponse = vidFound.answerChoice;
      else if (this.questionData?.inputType === SNE_INPUT_TYPES.CHECKBOX) {
        let arrIndex = this.multiResponse.indexOf(vidFound.answerChoice);
        if (arrIndex > -1) {
          // if answer is already in the array, remove it at arrIndex
          this.multiResponse.splice(arrIndex, 1);
        } else {
          this.multiResponse.push(vidFound.answerChoice);
          this.multiResponse.sort();
        }
      }
    }
  }

  ngOnInit(): void {
    this.processParams();
  }
}
