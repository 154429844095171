<div class="container">
  <ets-split-screen-intro
    [numberText]="numberText"
    [title]="title"
    [info]="currentQuestion?.info"
    [buttonText]="buttonText"
    [buttonColor]="APP_COLORS.uiaction"
    [mobileButtonSize]="'md'"
    [desktopImageSrc]="desktopImageSrc"
    [tabletImageSrc]="tabletImageSrc"
    [mobileImageSrc]="mobileImageSrc"
    (clickEvent)="onClick()"
    [isRoundedButton]="true"
    [questionSection]="currentQuestion?.section"
    [leftBgColor]="leftBgColor"
  ></ets-split-screen-intro>
</div>
