import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
import { SneWrittenCommunicationBase } from '../sne-written-communication.base';
import { SneAnswerService } from 'src/app/services/sne-answer.service';

@Component({
  selector: 'ets-wc-interstitial',
  templateUrl: './wc-interstitial.component.html',
  styleUrls: ['./wc-interstitial.component.scss']
})
export class WcInterstitialComponent extends SneWrittenCommunicationBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);

  }

  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  endInterstitial = false;

  ngOnInit() {
    // Get the route params to determine if it's the end interstitial
    this.route.params.subscribe(params => {
      const { questionNum, section } = params;
      this.endInterstitial = questionNum === 'end';
    });
    this.processParams();
  }

}
