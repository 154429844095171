import { SneQuestion } from '../../types/sne';
import WC_TYPES from '../../types/written-communication-types';
import QUESTION_TYPES from '../../types/question-types';

export function generateNextQuestionPath(question: SneQuestion , key: string, sneType: WC_TYPES,
  additionalNextQuestionSteps?: () => void) {
  let path = '';
  if (question) {
    switch (question.questionType) {
      case QUESTION_TYPES.SNE_INTRO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/wc/sne-intro/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.INTERSTITIAL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/wc/interstitial/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_TEXT_FREE_RESPONSE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/wc/text-free-response/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/wc/multi-choice-memo/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/wc/multi-choice-email/${key}/${sneType}`;
        break;
      default:
        console.error('Cannot find question type');
    }
  }
  return path;
}
