import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import CT_TYPES from 'src/app/types/critical-thinking-types';
import { Email, SNE_INPUT_TYPES } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';
import { HEADER_STATES } from '../../../shared/header/header.helper';

@Component({
  selector: 'ets-ct-multi-choice-email',
  templateUrl: './ct-multi-choice-email.component.html',
  styleUrls: ['./ct-multi-choice-email.component.scss']
})
export class CtMultiChoiceEmailComponent extends SneCriticalThinkingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  emailList: Email[] = [];
  emailSubject?: string;

  multiResponseQuestionHandler(response: string) {
    let arrIndex = this.multiResponse.indexOf(response);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(response);
      this.multiResponse.sort();
    }
  }

  singleReponseQuestionHandler(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }

  additionalParamProcess(): void {
    if (this.questionData && this.questionData.emailThread) {
      this.emailList = this.questionData.emailThread[0]?.emailResponse;
      this.emailSubject = this.questionData.emailThread[0]?.emailSubject;
    }
  }

  ngOnInit(): void {
    this.processParams();
    // if (this.questionData && this.questionData.emailThread) {
    //   this.emailList = this.questionData.emailThread[0]?.emailResponse;
    //   this.emailSubject = this.questionData.emailThread[0]?.emailSubject;
    // }
  }
}
