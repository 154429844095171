import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { APP_COLORS } from '../../../global-styles';

@Component({
  selector: 'ets-dashboard-behavior-interests',
  templateUrl: './dashboard-behavior-interests.component.html',
  styleUrls: ['./dashboard-behavior-interests.component.scss']
})
export class DashboardBehaviorInterestsComponent implements OnInit, OnChanges {


  constructor(private activatedRoute: ActivatedRoute, private router: Router,) {
  }

  APP_COLORS = APP_COLORS;

  @Input() facetsResults: any | undefined;
  @Input() cipResults: any | undefined;

  faCheckCircle = faCheckCircle;

  facetsStatus: string |undefined;
  cipStatus: string | undefined;

  facetsDuration: string |undefined;
  cipDuration: string | undefined;

  facetsPath: string ='';
  cipPath: string ='';

  testComplete: string = '';
  facetsComplete: boolean  = false;
  cipComplete: boolean  =false;

  assessmentsCompleted: number = 0;


  getFacetsStatus(){
    if (this.facetsResults) {
      switch (this.facetsResults.status) {
        case 'Completed':
          this.facetsStatus = this.facetsResults.status + ' ';
          this.facetsDuration = this.facetsResults.completionTimestamp;
          this.facetsComplete = true;
          break;
        case 'Not started':
          this.facetsStatus = 'Incomplete';
          this.facetsDuration = 'Approx. 30 min';
          this.facetsComplete = false;
          break;
        case 'In progress':
          this.facetsStatus = this.facetsResults.status;
          this.facetsDuration = 'Approx. 30 min';
          this.facetsComplete = false;
          break;
        default:
          this.facetsStatus = 'Incomplete';
          this.facetsDuration = 'Approx. 30 min';
          this.facetsComplete = false;
          break;
      }
    }
  }

  getCIPStatus() {
    if (this.cipResults) {
      switch (this.cipResults.status) {
        case 'Completed':
          this.cipStatus = this.cipResults.status + ' ';
          this.cipDuration = this.cipResults.completionTimestamp;
          this.cipComplete = true;
          break;
        case 'Not started':
          this.cipStatus = 'Incomplete';
          this.cipDuration = 'Approx. 30 min';
          this.cipComplete = false;
          break;
        case 'In progress':
          this.cipStatus = this.cipResults.status;
          this.cipDuration = 'Approx. 30 min';
          this.cipComplete = false;
          break;
        default:
          this.cipStatus = 'Incomplete';
          this.cipDuration = 'Approx. 30 min';
          this.cipComplete = false;
          break;
      }
    }
  }

  facetsClick(){
    if(this.facetsResults.status === 'Completed'){
      this.facetsPath ='/foundational-assessment/power-skills-personality-results/';
    } else{
      this.facetsPath = '/foundational-assessment/power-skills-intro/';
    }
    this.router.navigate([this.facetsPath]);
  }

  cipClick(){
    if(this.cipResults.status === 'Completed'){
      this.cipPath = '/career-interests/';
    } else{
      this.cipPath = '/cip-questions/intro/0';
    }
    this.router.navigate([this.cipPath]);
  }

  checkModulesCompleted() {
    this.assessmentsCompleted =  ((+this.facetsComplete) + (+this.cipComplete));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['facetsResults'] || changes['cipResults']) {
      this.getFacetsStatus();
      this.getCIPStatus();
      this.checkModulesCompleted();
    }
  }

  ngOnInit(): void {
    this.getFacetsStatus();
    this.getCIPStatus();
    this.checkModulesCompleted();
  }

}
