<button
  [disabled]='disabled'
  class="button"
  [ngStyle]="{
    'color': textColor && !disabled ? textColor : '',
    'background-color': backgroundColor && !disabled ? backgroundColor : '',
    'border': borderColor ? '1px solid ' + borderColor : ''
  }"
  [class]="fullWidth ? 'btn-full-width' : ''"
  (click)="onClick()"
  attr.aria-label="{{ariaLabel}}"
>
  {{buttonText}}
</button>
