<button
  class="card-wrapper"
  [ngClass]="{
    'remote-work-cards': cardSize === CARD_SIZE_ENUM.LARGER,
    'large-card': cardSize === CARD_SIZE_ENUM.LARGE,
    'medium-card': cardSize === CARD_SIZE_ENUM.MEDIUM,
    'small-card': cardSize === CARD_SIZE_ENUM.SMALL,
    'card-selected': selectedFlag
  }"
  id="{{ cardId }}"
  (click)="handleClick()"
>
  <img
    *ngIf="image && !selectedFlag"
    class="image"
    src="{{ image }}"
    alt="{{ title }}"
  />
  <img
    *ngIf="image && selectedFlag"
    class="image"
    src="{{ blueCheck }}"
    alt="{{ title }}"
  />
  <div class="title-desc-container">
    <h3 class="title">{{ title }}</h3>
    <p class="description" *ngIf="description">{{ description }}</p>
  </div>
</button>
