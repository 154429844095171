import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiChoiceVideoComponent } from './multi-choice-video/multi-choice-video.component';
import { MultiChoiceFullComponent } from './multi-choice-full/multi-choice-full.component';
import { MultiChoiceVideoChoiceFullComponent } from './multi-choice-video-choice-full/multi-choice-video-choice-full.component';
import {MultiChoiceListFullComponent} from './multi-choice-list-full/multi-choice-list-full.component';
import { MadlibsFullComponent } from './madlibs-full/madlibs-full.component';
import { OcInterstitialComponent } from './oc-interstitial/oc-interstitial.component';
import {
  OcMadlibsVideoSplitscreenComponent
} from './oc-madlibs-video-splitscreen/oc-madlibs-video-splitscreen.component';
import { OcMultiChoiceListVideoComponent } from './oc-multi-choice-list-video/oc-multi-choice-list-video.component';
import {
  OcMultiVideoChoiceVideoSplitscreenComponent
} from './oc-multi-video-choice-video-splitscreen/oc-multi-video-choice-video-splitscreen.component';
import { SneComponentsModule } from '../components/sne-components.module';
import { SharedModule } from '../../shared/shared.module';
import { OralCommunicationComponent } from './oral-communication.component';
import { RouterModule } from '@angular/router';
import { OcSneIntroComponent } from './oc-sne-intro/oc-sne-intro.component';
import { OcVideosAndMultiChoiceComponent } from './oc-videos-and-multi-choice/oc-videos-and-multi-choice.component';
//import { OcVideosAndMultiChoiceComponent } from './sne-new/oral-communication/oc-videos-and-multi-choice/oc-videos-and-multi-choice.component';

@NgModule({
  declarations: [
    MultiChoiceVideoComponent,
    OcInterstitialComponent,
    OcMadlibsVideoSplitscreenComponent,
    OcMultiChoiceListVideoComponent,
    OcMultiVideoChoiceVideoSplitscreenComponent,
    OralCommunicationComponent,
    MultiChoiceFullComponent,
    MultiChoiceListFullComponent,
    MultiChoiceVideoChoiceFullComponent,
    MadlibsFullComponent,
    OcSneIntroComponent,
    OcVideosAndMultiChoiceComponent,
  ],
  imports: [
    CommonModule,
    SneComponentsModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    MultiChoiceVideoComponent,
    OcInterstitialComponent,
    OcMadlibsVideoSplitscreenComponent,
    OcMultiChoiceListVideoComponent,
    OcMultiVideoChoiceVideoSplitscreenComponent,
    OralCommunicationComponent,
    MultiChoiceFullComponent,
    MultiChoiceVideoChoiceFullComponent,
    MultiChoiceListFullComponent,
    MadlibsFullComponent,
    OcSneIntroComponent,
    OcVideosAndMultiChoiceComponent,
  ]
})
export class OralCommunicationModule { }
