import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SneComponentsModule } from '../components/sne-components.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CpsInterstitialComponent } from './cps-interstitial/cps-interstitial.component';
import { CollaborativeProblemSolvingComponentV2 } from './collaborative-problem-solving-component-v2.component';
import { CpsSurveyComponent } from './cps-survey/cps-survey.component';
import { MenuFactCardSplitScreenComponent } from './menu-fact-card-split-screen/menu-fact-card-split-screen.component';
import { ClientRankingChatHistorySplitScreenComponent } from './client-ranking-chat-history-split-screen/client-ranking-chat-history-split-screen.component';
import { FactCardListComponent } from './fact-card-list-split-screen/fact-card-list.component';
import { CpsSneIntroComponent } from './cps-sne-intro/cps-sne-intro.component';
import { HiddenProfileComponent } from './hidden-profile/hidden-profile.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CollaborativeProblemSolvingComponentV2,
    CpsInterstitialComponent,
    CpsSurveyComponent,
    MenuFactCardSplitScreenComponent,
    ClientRankingChatHistorySplitScreenComponent,
    FactCardListComponent,
    CpsSneIntroComponent,
    HiddenProfileComponent
  ],
  imports: [
    CommonModule,
    SneComponentsModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    CollaborativeProblemSolvingComponentV2,
    CpsInterstitialComponent,
  ]
})
export class CollaborativeProblemSolvingModule { }
