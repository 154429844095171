
<ets-auth-split-screen [leftBackgroundColor]="APP_COLORS.mustardlight" [rightBackgroundColor]="APP_COLORS.white">
  <div class="left">
    <img src="../../../assets/sign-up/new-hand.png" alt="Waving Hand" id="sign-in-photo">
  </div>
  
  <div class="right">
    <!-- Password Reset Container -->
    <div class="sign-in-content-wrapper">
      <div class="header-info-wrapper">
        <h1 class="sign-in-header">
          Forgot your password?
        </h1>
        <p class="need-account" *ngIf='!showConfirmPasswordReset'>
          No problem! Enter your email below. If we find a matching account, we'll send you a code to reset it.
        </p>
        <p class="need-account" *ngIf='showConfirmPasswordReset'>
          If we found a matching account, a one-time code was sent to your email.
          Please enter it below to reset your password.
        </p>
      </div>
    
      <!-- Initiate Password Reset Form -->
      <div class="login-form-wrapper" *ngIf='!showConfirmPasswordReset'>
        <form [formGroup]="initiatePasswordResetForm" (ngSubmit)="forgotPassword()">
          <!-- Username -->
          <div class="input-content-wrapper">
            <label for="fusername" class="input-label">Email</label>
            <input type="text" id="fusername" name="username" class="sign-in-input" formControlName="username">
            <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon'
                 *ngIf="initiatePasswordResetForm.controls['username'].invalid &&
                  (initiatePasswordResetForm.controls['username'].touched || initiatePasswordResetForm.controls['username'].dirty)"
            >
            <div *ngIf="initiatePasswordResetForm.controls['username'].invalid && (initiatePasswordResetForm.controls['username'].touched || initiatePasswordResetForm.controls['username'].dirty)">
              <div *ngIf="initiatePasswordResetForm.controls['username'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div>
          </div>
    
          <!-- Error Handling -->
          <p class="error-msg" *ngIf="initiateReset_unknownErrorFlag">An unknown error occurred. Please try again.</p>
    
          <div class="submit-container">
            <input type="submit" id="submit" name="submit" value="Reset Password" class="login-button" [disabled]="!initiatePasswordResetForm.valid">
          </div>
        </form>
      </div>
    
      <!-- Confirm Password Reset Form -->
      <div class="login-form-wrapper" *ngIf='showConfirmPasswordReset'>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="forgotPasswordSubmit()">
          <!-- Code -->
          <div class="input-content-wrapper">
            <label for="fcode" class="input-label">Code:</label>
            <input type="text" id="fcode" name="code" class="sign-in-input" formControlName="code">
            <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon'
                 *ngIf="resetPasswordForm.controls['code'].invalid &&
                  (resetPasswordForm.controls['code'].touched || resetPasswordForm.controls['code'].dirty)"
            >
            <div *ngIf="resetPasswordForm.controls['code'].invalid && (resetPasswordForm.controls['code'].touched || resetPasswordForm.controls['code'].dirty)">
              <div *ngIf="resetPasswordForm.controls['code'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div>
          </div>
    
          <!-- New Password -->
          <div class="input-content-wrapper">
            <label for="reset_password" class="input-label">New Password:</label>
            <input type="password" (focus)="focusPassword(true, 1)" (focusout)="focusPassword(false, 1)" id="reset_password" name="password" class="sign-in-input" formControlName="password">
            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("reset_password")'></fa-icon>

            <div *ngIf="passwordRow1Focused === true && resetPasswordForm.controls['password'].errors" class="modal-box">
              <!-- <div *ngIf="resetPasswordForm.controls['password'].errors?.required" class='validation-error'>
                This field is required
              </div> -->
              <div class='sign-up-password-row'>
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                    *ngIf="(resetPasswordForm.controls['password'].value.length===0)||resetPasswordForm.controls['password'].errors?.minlength && !resetPasswordForm.controls['password'].errors?.required else minLengthTemplate"
                  ></fa-icon>
                  <ng-template #minLengthTemplate>
                    <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                  </ng-template>
                </div>
                <div class="sign-up-password-col2">
                  8 characters minimum
                </div>
              </div>
              <div class='sign-up-password-row' *ngIf="resetPasswordForm.controls['password'].errors?.maxlength">
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                  ></fa-icon>
                </div>
                <div class="sign-up-password-col2">
                  99 characters maximum
                </div>
              </div>
              <div class='sign-up-password-row'>
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                    *ngIf="(resetPasswordForm.controls['password'].value.length===0)||resetPasswordForm.controls['password'].hasError('hasNumber') && !resetPasswordForm.controls['password'].errors?.required else numberTemplate"
                  ></fa-icon>
                  <ng-template #numberTemplate>
                    <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                  </ng-template>
                </div>
                <div class="sign-up-password-col2">
                  One number
                </div>
              </div>
              <div class='sign-up-password-row'>
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                    *ngIf="(resetPasswordForm.controls['password'].value.length===0)||resetPasswordForm.controls['password'].hasError('hasUpper') && !resetPasswordForm.controls['password'].errors?.required else upperCaseTemplate"
                  ></fa-icon>
                  <ng-template #upperCaseTemplate>
                    <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                  </ng-template>
                </div>
                <div class="sign-up-password-col2">
                  One uppercase letter
                </div>
              </div>
              <div class='sign-up-password-row'>
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                    *ngIf="(resetPasswordForm.controls['password'].value.length===0)||resetPasswordForm.controls['password'].hasError('hasSpecial') && !resetPasswordForm.controls['password'].errors?.required else specialTemplate"
                  ></fa-icon>
                  <ng-template #specialTemplate>
                    <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                  </ng-template>
                </div>
                <div class="sign-up-password-col2">
                  One special character
                </div>
              </div>
              <div class='sign-up-password-row'>
                <div class="sign-up-password-col1">
                  <fa-icon class="checkmark_circle_grey" 
                    [icon]="faCircle"
                    *ngIf="(resetPasswordForm.controls['password'].value.length===0)||resetPasswordForm.controls['password'].hasError('hasLower') && !resetPasswordForm.controls['password'].errors?.required else lowerCaseTemplate"
                  ></fa-icon>
                  <ng-template #lowerCaseTemplate>
                    <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                  </ng-template>
                </div>
                <div class="sign-up-password-col2">
                  One lowercase letter
                </div>
              </div>
            </div>
          </div>
    
          <!-- Confirm Password -->
          <div class="input-content-wrapper">
            <label for="reset_confirmPassword" class="input-label">Confirm Password:</label>
            <input type="password"  (focus)="focusPassword(true, 2)" (focusout)="focusPassword(false, 2)" id="reset_confirmPassword" name="confirmPassword" class="sign-in-input" formControlName="confirmPassword">
            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("reset_confirmPassword")'></fa-icon>
            <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && passwordRow2Focused === true">
              <div *ngIf="resetPasswordForm.controls['confirmPassword'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div>
            <div *ngIf="resetPasswordForm.hasError('noPasswordMatch') && !resetPasswordForm.controls['confirmPassword'].errors?.required" class='validation-error'>
              Password does not match the above password
            </div>
          </div>
    
          <!-- Error Handling -->
          <p class="error-msg" *ngIf='resetPassword_unknownErrorFlag'>An unknown error occurred. Please try again.</p>
    
          <div class="submit-container">
            <input type="submit" id="submit" name="submit" value="Change Password" class="login-button" [disabled]="!resetPasswordForm.valid">
          </div>
        </form>
      </div>
    </div>
  </div>
</ets-auth-split-screen>