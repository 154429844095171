<div class="page-container clr-col-lg-8 clr-col-sm-12 clr-col-12">

  <div class="title-container">
    <h1 class="title">Diversity & Privacy Settings</h1>
    <span class="subtitle">Apprize believes workplace diversity matters. We want to ensure we do our part to celebrate and increase it—the information below will help us fulfill this mission. You can manage this data to personalize your experience and influence how employers interact with you. To learn more, visit our <a (click)='trackPrivacyPolicyLinkClick()' href='https://www.ets.org/legal/privacy' target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</span>
  </div>

  <form [formGroup]="diversityAndPrivacyForm" id="diversity-privacy-form" (ngSubmit)="saveChanges()">
    <div class="diversity-preferences-container">
      <h2>Diversity Preferences</h2>
      <hr>

      <div class="preference-outer-container-no-wrap margin-top-16">
        <div class="preference-inner-container clr-col-lg-9 clr-col-sm-9 clr-col-9 padding-0">
          <h4>Are you willing to share your background information?</h4>
          <p class="margin-top-0 line-height-1">Allow us to use demographic data to ensure equal hiring practices on our platform.</p>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-3 clr-col-3 padding-0">
          <label class="switch">
            <input type="checkbox" formControlName="diversityWillingToShare" id="diversityWillingToShare" name="diversityWillingToShare" [checked]="userProfile?.diversityWillingToShare">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="preference-outer-container-no-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-9 clr-col-sm-9 clr-col-9 padding-0">
          <h4>Do you identify as LGBTQ+?</h4>
          <p class="margin-top-0 line-height-1">We’re committed to uncovering unseen challenges and fears at work that may hinder everyone's ability to thrive.</p>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-3 clr-col-3 padding-0">
          <label class="switch">
            <input type="checkbox" formControlName="diversityLGBTQ" id="diversityLGBTQ" name="diversityLGBTQ" [checked]="userProfile?.diversityLGBTQ">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="preference-outer-container-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-8 clr-col-sm-12 clr-col-12 padding-0">
          <h4>What is your gender identity?</h4>
          <p class="margin-top-0 line-height-1">Apprize will never share this information—we use this to make sure we are maintaining non-discriminatory, ethical, and legal practices.</p>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-12 clr-col-12 padding-0">
          <clr-select-container>
            <select clrSelect name="diversityGender" formControlName="diversityGender">
              <option [selected]="gender.value === userProfile?.diversityGender" class="select-options" value="{{gender.value}}" *ngFor='let gender of GENDERS | keyvalue'>{{gender.value}}</option>
            </select>
          </clr-select-container>
        </div>
      </div>

      <div class="preference-outer-container-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-8 clr-col-sm-12 clr-col-12 padding-0">
          <h4>How would you describe yourself?</h4>
          <p class="margin-top-0 line-height-1">Apprize will never share this information—we use this to make sure we are maintaining non-discriminatory, ethical, and legal practices.</p>
        </div>
        <div class="input-container clr-col-lg-12 clr-col-sm-12 clr-col-12 padding-0">
          <clr-select-container>
            <select clrSelect name="diversityRace" formControlName="diversityRace">
              <option [selected]="race.value === userProfile?.diversityRace" class="select-options" value="{{race.value}}" *ngFor='let race of RACE | keyvalue'>{{race.value}}</option>
            </select>
          </clr-select-container>
        </div>
      </div>

      <div class="preference-outer-container-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-8 clr-col-sm-12 clr-col-12 padding-0">
          <h4>Do you have any disabilities?</h4>
          <p class="margin-top-0 line-height-1">Apprize will never share this information—we use this to make sure we are maintaining non-discriminatory, ethical, and legal practices.</p>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-12 clr-col-12 padding-0">
          <clr-select-container>
            <select clrSelect name="diversityDisabilityStatus" formControlName="diversityDisabilityStatus">
              <option [selected]="disabilityStatus.value === userProfile?.diversityDisabilityStatus" class="select-options" value="{{disabilityStatus.value}}" *ngFor='let disabilityStatus of DISABILITY_STATUS | keyvalue'>{{disabilityStatus.value}}</option>
            </select>
          </clr-select-container>
        </div>
      </div>
    </div>

    <div class="privacy-preferences-container">
      <h2>Privacy Preferences</h2>
      <hr>

      <div class="preference-outer-container-no-wrap margin-top-16">
        <div class="preference-inner-container clr-col-lg-9 clr-col-sm-9 clr-col-9 padding-0">
          <h4>Should we allow potential employers to view your profile and skills?</h4>
          <p class="margin-top-0 line-height-1">Employers will be able to view details around your specific skills in order to recruit top talent.</p>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-3 clr-col-3 padding-0">
          <label class="switch">
            <input type="checkbox" formControlName="privacyAllowEmployersToView" id="privacyAllowEmployersToView" name="privacyAllowEmployersToView" [checked]="userProfile?.privacyAllowEmployersToView">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="preference-outer-container-no-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-9 clr-col-sm-9 clr-col-9 padding-0">
          <h4>Do you want personalized training suggestions based on information from our partners?</h4>
          <!-- <p class="margin-top-0 line-height-1">Etiam porta sem malesuada magna mollis euismod.</p> -->
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-3 clr-col-3 padding-0">
          <label class="switch">
            <input type="checkbox" formControlName="privacyAllowTrainingPartnersToView" id="privacyAllowTrainingPartnersToView" name="privacyAllowTrainingPartnersToView" [checked]="userProfile?.privacyAllowTrainingPartnersToView">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="preference-outer-container-no-wrap margin-top-24">
        <div class="preference-inner-container clr-col-lg-9 clr-col-sm-9 clr-col-9 padding-0">
          <h4>May we share your skill information with our data partners?</h4>
          <!-- <p class="margin-top-0 line-height-1">Aenean lacinia bibendum nulla sed consectetur. Nullam quis risus eget urna mollis ornare vel eu leo. Donec ullamcorper nulla non metus auctor fringilla.</p> -->
        </div>
        <div class="input-container clr-col-lg-3 clr-col-sm-3 clr-col-3 padding-0">
          <label class="switch">
            <input type="checkbox" formControlName="privacyAllowDataPartnersToView" id="privacyAllowDataPartnersToView" name="privacyAllowDataPartnersToView" [checked]="userProfile?.privacyAllowDataPartnersToView">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </form>

  <div class="button-wrapper mobile-hide">
    <ets-rounded-contained-button type="submit" form="diversity-privacy-form" (clickEvent)="saveChanges()" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'"></ets-rounded-contained-button>
  </div>

  <div class="button-wrapper non-mobile-hide">
    <ets-rounded-contained-button style="width: 100%;" type="submit" form="diversity-privacy-form" [fullWidth]="true" (clickEvent)="saveChanges()" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'"></ets-rounded-contained-button>
  </div>
</div>
