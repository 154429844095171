<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="false"
>
</ets-header>

<ets-sne-split-screen
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
  [sneSection]="'left-cps'"
>
  <div class="left">
    <ets-sne-instruction
      [title]="questionData?.header"
      [subtitle]="questionData?.subtitle"
      [info]="questionData?.info"
      [marquis]="questionData?.marquis"
    >

    </ets-sne-instruction>
  </div>

  <div class="right">
    <ets-sne-multi-choice-list
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer, $event.marquisAnswer)"
      [inputType]="questionData?.inputType"
      [filteredClassifications]="sortedClassifications"
      (handleDropdown)="handleDropdown($event.event, $event.questionIndex)"
    >
    </ets-sne-multi-choice-list>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [isButtonDisabled]="disableNextButton()"
  [overviewEnabled]="true"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [questionNum]="questionNum"
  [buttonText]="questionData?.buttonText"
>
  <ets-sne-multi-choice-list
  [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
  (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer, $event.marquisAnswer)"
  [inputType]="questionData?.inputType"
  [filteredClassifications]="sortedClassifications"
  (handleDropdown)="handleDropdown($event.event, $event.questionIndex)"
  [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>

</ets-sne-progress-bar>

<ets-modal
  [title]="questionData?.submitModal?.title"
  [showModal]="showSubmitModal"
  [list]="questionData?.submitModal?.pageList"
  (modalClose)="closeModalAndNav()"
  [buttonText]="'Submit'"
  [submitModal]="true"
>
</ets-modal>

<!-- Overview Modal -->
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div class="classifications-container" *ngIf="instructionModal?.customContent" contentID="customContent">
    <div *ngFor="let classification of instructionModal?.customContent; let idx = index">
      <div class="text-wrapper">
        <span class="classification-header">{{ classification.header }}</span>
        <p class="classification-info">{{ classification.info }}</p>
      </div>
      <hr style="color: #ccc;" *ngIf="instructionModal?.customContent.length !== idx + 1" />
    </div>
  </div>
</ets-modal>