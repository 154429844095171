<div class="container">
  <button class="why-we-ask-link" (click)="toggleModal()">Why we ask</button>
</div>

<clr-modal [(clrModalOpen)]="showModal" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-title modal-title-container">
    <h2 class="modal-title-text">{{whyWeAskData.title}}</h2>
  </div>

  <div class="modal-body modal-body-container">
    <p class="modal-body-text">{{whyWeAskData?.body}}</p>
  </div>      

  <div class="modal-footer">
    <ets-solid-button [text]="'Continue'" [color]="'#0E3AD6'" (clickEvent)="toggleModal()"></ets-solid-button>
  </div>
</clr-modal>