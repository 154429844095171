import { createReducer, on } from '@ngrx/store';
import { setErrorModal } from './error-modal.actions';

export interface State {
  showErrorModal: boolean;
}

export const errorModalFeatureKey = 'errorModal';

export const initialState: State = {
  showErrorModal: false
};

export const reducer = createReducer(
  initialState,
  on(setErrorModal, (state, { payload }) => ({...state, showErrorModal: payload}))
);
