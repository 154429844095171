export const RIASEC_TEXT = {
  'Realistic': ['You prefer to dream big.',
    'You prefer to put your dreams to work.',
    'You prefer to get your hands dirty.'],

  'Investigative': ['You prefer to keep things simple.',
    'You prefer to do your research.',
    'You prefer to look under the hood.'],

  'Artistic': ['You prefer to stick to what you know.',
    'You prefer a mix of creativity and structure.',
    'You prefer to think outside the box.'],

  'Social': ['You prefer to fly solo.',
    'You prefer a healthy social balance.',
    'You prefer to collaborate.'],

  'Enterprising': ['You prefer not to take risks.',
    'You prefer to weigh your options.',
    'You prefer to take charge.'],

  'Conventional': ['You prefer to do things differently.',
    'You prefer to blend tradition and novelty.',
    'You prefer the status quo.']
};

export const career_phrase_map = new Map([
  ['Agriculture, Horticulture, & the Outdoors', 'working outside'],
  ['Business Management and Operations',	'working behind the scenes'],
  ['Clerical and Administrative',	'helping things run smoothly'],
  ['Community and Social Services',	'supporting mental health'],
  ['Construction, Extraction, and Architecture',	'building things'],
  ['Customer and Client Support',	'helping people resolve issues'],
  ['Design, Media, and Writing',	'conveying information'],
  ['Education and Training',	'teaching other people'],
  ['Engineering',	'solving technical problems'],
  ['Finance',	'crunching the numbers'],
  ['Health Care including Nursing',	'supporting physical health'],
  ['Hospitality, Food, and Tourism',	'making others feel at home'],
  ['Human Resources',	'supporting others at work'],
  ['Information Technology',	'making systems run smoothly'],
  ['Law, Compliance, and Public Safety',	'keeping people safe'],
  ['Maintenance, Repair, and Installation',	'fixing things'],
  ['Manufacturing and Production',	'making things'],
  ['Marketing and Public Relations',	'promoting companies'],
  ['Performing Arts',	'performing for an audience'],
  ['Personal Services',	'helping people get by'],
  ['Planning and Analysis',	'streamlining processes'],
  ['Sales',	'finding customers'],
  ['Science and Research',	'exploring how systems work'],
  ['Transportation',	'moving things around']
]);

export const raisec_high_phrase_map = new Map([
  ['R_job', ['you’re a pragmatist',
    'you’re down-to-earth',
    'you’re grounded',
    'you’re rooted in the present',
    'you live for logic']],
  ['I_job', ['you’re always asking questions',
    'you do your research',
    'you love to peek under the hood',
    'you love learning new things',
    'you’re as curious as a cat']],
  ['A_job', ['you’re a true original',
    'you’re a creative type',
    'you enjoy the unusual',
    'you’re imaginative',
    'you think outside the box']],
  ['S_job', ['you’re outgoing',
    'you’re an extravert',
    'you love interaction',
    'you enjoy working with others',
    'other people energize you']],
  ['E_job', ['you’re self-motivated',
    'you like to take charge',
    'you like to influence decisions',
    'you enjoy leading',
    'you’re a mover and a shaker']],
  ['C_job', ['change flusters you',
    'you prefer the status quo',
    'you like leaving things put',
    'stability matters to you',
    'you stick to what\'s familiar']]
]);

export const raisec_low_phrase_map = new Map([
  ['R_job', ['your head is in the clouds',
    'you’re a daydreamer',
    'you tend to fantasize',
    'you are starry-eyed',
    'you’re prone to whimsy']],
  ['I_job', ['it’s hard to spark your interest',
    'you don’t question things',
    'you take things at face value',
    'you see things as they are',
    'you’re not a skeptic']],
  ['A_job', ['art tends to bore you',
    'you’re not a “creative” type',
    'you\'re a Plain Jane',
    'you don\'t like to push the limits',
    'you like to keep it simple']],
  ['S_job', ['you prefer to be alone',
    'you dislike large groups',
    'you like flying solo',
    'you stick to yourself',
    'you don\'t love socializing']],
  ['E_job', ['you have trouble motivating',
    'you prefer when others lead',
    'you’d rather follow along',
    'you don\'t like to charge ahead',
    'you tend to be hesitant']],
  ['C_job', ['you welcome change',
    'you never stick to a routine',
    'you’re all about new methods',
    'you like to shake things up',
    'you’re a trailblazer']],
]);


export const raisec_likes = {
  'Realistic': ['Careers that require invention and long-term planning.',
    'Careers that involve a healthy mix of innovation and practicality.',
    'Careers that involve immediate, concrete tasks.'],
  'Investigative': ['Careers that require consistency and routine.',
    'Careers that require consistency but allow you room to learn and experiment.',
    'Careers that involve experimentation and variety.'],
  'Artistic': ['Careers that require set rules and procedures.',
    'Careers that require thinking outside the box from time to time.',
    'Careers that include creative problem-solving.'],
  'Social': ['Careers that allow you to work by yourself most of the time.',
    'Careers that allow for both collaboration and independence.',
    'Careers that require frequent interaction with others.'],
  'Enterprising': ['Careers that involve attention to detail and a steady pace.',
    'Careers that provide opportunities for both leadership and supporting roles.',
    'Careers that require decision-making, leadership, and influence.'],
  'Conventional': ['Careers that encourage you to think creatively and discover novel approaches to problems.',
    'Careers that follow established but flexible guidelines.',
    'Careers that follow established guidelines.']
};


export const raisec_dislikes = {
  'Realistic': ['Careers that involve immediate, concrete tasks.',
    'Careers that lean too far toward pure invention or pure hands-on tasks.',
    'Careers that require invention and long-term planning.'],
  'Investigative': ['Careers that involve experimentation and variety.',
    'Careers that require consistency and routine accordingly.',
    'Careers that require consistency and routine.'],
  'Artistic': ['Careers that require artistic expression.',
    'Careers that lack a clear framework and process.',
    'Careers that require set rules and procedures.'],
  'Social': ['Careers that require frequent interaction with others.',
    'Careers that either require you to fly solo or require constant team effort.',
    'Careers that allow you to work by yourself most of the time.'],
  'Enterprising': ['Careers that require quick decision-making and frequent change.',
    'Careers where you are either always or never in charge.',
    'Careers that provide support to co-workers and decision-makers.'],
  'Conventional': ['Careers that follow established guidelines.',
    'Careers that incorporate either too rigid or too open-ended a structure.',
    'Careers that encourage you to think creatively and discover novel approaches to problems.']
};
