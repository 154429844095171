enum QUESTION_TYPES {
  // onboarding
  INTRO,
  SINGLE_MULTIPLE_CHOICE,
  MULTI_MULTIPLE_CHOICE,
  SINGLE_QUESTION_RESPONSE,
  SEARCH_MULTI_RESPONSE,
  TABLE_QUESTION,
  YES_NO_QUESTION,
  INFO_BREAK_TYPE_A, // deprecated
  INFO_BREAK_TYPE_B, //deprecated
  INFO_BREAK,
  CARD_RESPONSE,
  JUMP_TO_CIP,
  RECENT_ROLE,
  SLIDER,
  FIELD_OF_STUDY,
  EXPERIENCE_INPUT,
  SKILL_SELECTION_OVERVIEW,
  TRANSFERABLE_JOB_SPECIFIC_SKILLS,
  RESUME_OPTION,
  RESUME_UPLOAD,
  WORK_HISTORY_START,
  TOP_SKILLS,
  SKILLS_LOADING,
  RESUME_ATTACH,

  //sne
  SNE_INTRO,
  SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
  SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
  SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO,
  SPLIT_SCREEN_MULTIPLE_CHOICE_TEXT,
  SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
  SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
  VIDEO_LIST,
  MULTIPLE_CHOICE_FULL, //Used for new OC
  MULTIPLE_CHOICE_VIDEO_CHOICE_FULL, // Used for new OC
  MULTIPLE_CHOICE_LIST_FULL, // Used for new OC
  MADLIBS_FULL, // used for new OC
  SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE, // Used for WC sets (set-1, set-2, and set-3)
  SPLIT_SCREEN_MADLIBS_VIDEO,
  SPLIT_SCREEN_TEXT_FREE_RESPONSE, // Used for WC freedom of speech task
  SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA,
  SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
  SPLIT_SCREEN_MULTIPLE_CHOICE_COMMENTS,
  SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_CONVO,
  MULTI_CHOICE_DROPDOWN,
  SPLIT_SCREEN_MENU_FACT_LIST,
  SPLIT_SCREEN_CLIENT_RANKING_CHAT_HISTORY,
  VIDEOS_AND_MULTI_CHOICE,
  SPLIT_SCREEN_MULTIPLE_CHOICE_SCHEDULE, // Used for CT schedule task map 
  SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP,
  HIDDEN_PROFILE, // v3 of Hidden Profile
  // all
  INTERSTITIAL,
}

export default QUESTION_TYPES;

export enum QUESTION_SECTIONS {
  SECTION_ONE = 1,
  SECTION_TWO,
  SECTION_THREE,
  SECTION_FOUR,
  SECTION_FIVE,
  FACETS
}
