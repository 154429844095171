<ets-header [isBGWhite]="false" [headerState]="headerState" [showBackButton]="false"
            [simpleBack]="true">
</ets-header>
<ets-sne-split-screen [rightBackgroundColor]="APP_COLORS.white"
                      [hideMobile]="'right'"
>
<div class="right">
  <ets-sne-multi-choice-list
    [inputType]="questionData?.inputType"
    [headerQuestion]="questionData?.header"
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
    (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
  >
  </ets-sne-multi-choice-list>

</div>
<div class="left">
   <div class="container">
      <div class="overview-wrapper">
        <span class="overview">{{questionData?.contentHeader}}</span>
      </div>
    <p class="question">{{ questionData?.taskMap?.statement }}</p>
    <img class="schedule-image" [src]="questionData?.taskMap?.scheduleMap" alt="Time Schedule. Task A, 2 Hours. Task B, 3 Hours. Task C, 4 Hours. Task D, 3 Hours" />
   </div>
</div>

</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (overviewEmitter)="openInstructionModal()"
  (nextEmitter)="getNextQuestion()"
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? multiResponse.length !== questionData?.multipleMulChoiceOptions?.length : multiResponse.length !== questionData?.numSelectOptions"
>


  <ets-sne-multi-choice-list
  [inputType]="questionData?.inputType"
  [headerQuestion]="questionData?.header"
  [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
  (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
  (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
  [mobileCheck]="true"
    >
    </ets-sne-multi-choice-list>
</ets-sne-progress-bar>
<div *ngIf="instructionModal?.customContent" contentID=customContent>
  <ets-sne-email-layout *ngIf="instructionModal?.customContent[0].to"  [emails] = "instructionModal?.customContent" ></ets-sne-email-layout>
  <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user" [chatLog]="instructionModal?.customContent" [showHeader]="false"></ets-sne-conversation-log-text>
</div>


<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>

</ets-modal>
