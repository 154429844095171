<div class="container">
  <div class="inner">
    <span class="title">{{currentQuestion?.question}}</span>
    <div class="info-wrapper">
      <span class="info">{{currentQuestion?.info}}</span>
    </div>
    <!-- <span class="instructions">At least one role input is required</span> -->

    <div class="role-input-list" [formGroup]="form">
      <ng-container formArrayName="roles">
        <ng-container *ngFor="let roleForm of roles.controls ; let index = index">
          <div class="role-input-container" [formGroup]="$any(roleForm)">
            <div class="role-border role-margin-top"></div>
            <label class="role-input-label">Job Title</label>
            <div class="role-input-row">
              <div class="role-input-wrapper">
                <clr-combobox-container>
<!--                  <label>Role Title</label>-->
                  <clr-combobox [formControlName]="EI_FORM_NAMES.ROLE_NAMES"
                                (clrInputChange)="search($event)"
                                placeholder="{{placeholder}}"
                                id="{{index}}"
                                aria-label="Type in a few keywords that describe your role and then select the closest match from the drop-down list"
                  >
                    <ng-container *clrOptionSelected="let selected">
                      {{selected}}
                    </ng-container>
                    <clr-options *ngIf="careerList.length > 0">
                      <clr-option *clrOptionItems="let career of careerList" [clrValue]="career">
                        {{career}}
                      </clr-option>
                    </clr-options>
                  </clr-combobox>
                </clr-combobox-container>
                <span class="error-messages"
                      *ngIf="showErrors && roleForm.get(EI_FORM_NAMES.ROLE_NAMES)?.errors?.required"
                >
                  You must select a career or delete this row
                </span>
              </div>
              <button class="delete-button" (click)="deleteRow(index)">
                <img src="../../../../assets/icons/trash.svg" alt="delete row">
              </button>
            </div>
            <div class="row-info-container">
              <div class="role-add-info-container">
                <div class="input-container input-margin-right">
                  <div class="form-input-wrapper">
                    <span class="from-to-text">From:</span>
                    <div class="date-select-wrapper">
                      <fa-icon [icon]="faAngleDown" class="select-icon"></fa-icon>
                      <select class="date-select month-select" [formControlName]="EI_FORM_NAMES.FROM_MONTH">
                        <option [value]="null" selected disabled hidden>Month</option>
                        <option *ngFor="let month of months; let index = index" [value]="index">{{month}}</option>
                      </select>
                    </div>
                    <div class="date-select-wrapper">
                      <fa-icon [icon]="faAngleDown" class="select-icon"></fa-icon>
                      <select class="date-select year-select" [formControlName]="EI_FORM_NAMES.FROM_YEAR">
                        <option [value]="null" selected disabled hidden>Year</option>
                        <option *ngFor="let year of years.slice().reverse() " [value]="year">{{year}}</option>
                      </select>
                    </div>
                  </div>
                  <span class="error-messages error-center"
                        *ngIf="showErrors&& roleForm?.errors?.startDate"
                  >
                    Start date cannot be in the future
                  </span>
                  <span class="error-messages error-center"
                        *ngIf="showErrors
                        && (roleForm.get(EI_FORM_NAMES.FROM_MONTH)?.errors?.required
                        || roleForm.get(EI_FORM_NAMES.FROM_YEAR)?.errors?.required)"
                  >
                    Start date is required
                  </span>
                </div>
                <div class="input-container input-mobile-margin"
                     *ngIf="!roleForm.get(EI_FORM_NAMES.CURRENTLY_EMPLOYED)?.value">
                  <div class="form-input-wrapper">
                    <span class="from-to-text">To:</span>
                    <div class="date-select-wrapper">
                      <fa-icon [icon]="faAngleDown" class="select-icon"></fa-icon>
                      <select class="date-select month-select" [formControlName]="EI_FORM_NAMES.TO_MONTH">
                        <option [value]="null" selected disabled hidden>Month</option>
                        <option *ngFor="let month of months; let index = index" [value]="index">{{month}}</option>
                      </select>
                    </div>
                    <div class="date-select-wrapper">
                      <fa-icon [icon]="faAngleDown" class="select-icon"></fa-icon>
                      <select class="date-select year-select" [formControlName]="EI_FORM_NAMES.TO_YEAR">
                        <option [value]="null" selected disabled hidden>Year</option>
                        <option *ngFor="let year of years.slice().reverse()" [value]="year">{{year}}</option>
                      </select>
                    </div>
                  </div>
                  <span class="error-messages error-center"
                        *ngIf="showErrors && roleForm?.errors?.endDate"
                  >
                    End date cannot be in the future
                  </span>
                  <span class="error-messages error-center"
                        *ngIf="showErrors && roleForm?.errors?.startToEndDate"
                  >
                    End date cannot be before the start date
                  </span>
                  <span class="error-messages error-center"
                        *ngIf="showErrors &&
                      (roleForm.get(EI_FORM_NAMES.TO_MONTH)?.errors?.required
                      || roleForm.get(EI_FORM_NAMES.TO_YEAR)?.errors?.required)"
                  >
                    End date is required
                  </span>
                </div>
              </div>
              <div class="employed-wrapper">
                <input type="checkbox" [id]="'employed-checkbox' + index" class="employed-checkbox"
                       formControlName="currentlyEmployed"
                >
                <label [for]="'employed-checkbox' + index">Currently employed</label>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

<!--    <clr-combobox-container>-->
<!--      <label>Role Title</label>-->
<!--      <clr-combobox [(ngModel)]="input" placeholder="{{placeholder}}" name="multiSelect" clrMulti="true" required [disabled]="disabled">-->
<!--        <ng-container *clrOptionSelected="let selected">-->
<!--          {{selected}}-->
<!--        </ng-container>-->
<!--        <clr-options>-->
<!--          <clr-option *clrOptionItems="let career of careerList" [clrValue]="career">-->
<!--            {{career}}-->
<!--          </clr-option>-->
<!--        </clr-options>-->
<!--      </clr-combobox>-->
<!--      <clr-control-error>There was an error</clr-control-error>-->
<!--    </clr-combobox-container>-->

    <div class="add-role-button-wrapper">
      <ets-outline-button
        [color]="APP_COLORS.uiaction"
        [borderColor]="APP_COLORS.uiborder"
        [text]="'Add Another Job'"
        [padding]="'16px 0'"
        [maxWidth]="true"
        (clickEvent)="addRole()"
        [ariaLabel]="'add another job'"

      >
      </ets-outline-button>
    </div>

    <div class="submit-button-wrapper">
      <ets-rounded-contained-button
        [buttonText]="'SAVE & CONTINUE'"
        [backgroundColor]="APP_COLORS.persianbluemedium"
        [textColor]="APP_COLORS.white"
        (clickEvent)="submitForm()"
      >
      </ets-rounded-contained-button>
    </div>

  </div>
</div>
