import {
  SneQuestion,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';

const video_1 = {
  url: 'https://vimeo.com/605712075',
  id: '605712075'
};
export const OCPersuasive2Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Proposing an Idea',
      info: 'This task will give you insight into how you might interpret ideas and arguments presented by your colleagues, and we will use what we learn together to enhance your Skills Profile. <br><br>You will play one or more videos and answer corresponding questions.  In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes and is the last task in this section!',
      image: '/assets/section-4/sne-interstitial/persuasive-2.svg',
      questionIndex: 6,
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: video_1.url,
        id: video_1.id,
        title: 'Evaluating Arguments',
        mainHeader: 'Video 1',
        description: 'Watch your colleague propose an idea, then answer the questions. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true, // Next four questions need to be disabled.
      },
      header: 'What is the main objective of your colleague\'s speech?',
      singleMulChoiceOptions: [
        {
          userOption: 'To convince coworkers to use the recycling receptacles more often',
          userLetter: 'A',
        },
        {
          userOption: 'To share information about compostable dining materials',
          userLetter: 'B',
        },
        {
          userOption: 'To persuade coworkers to support an environmental sustainability plan',
          userLetter: 'C',
        },
        {
          userOption: 'To share an experience leading a campaign for reduction of food waste',
          userLetter: 'D',
        },
      ],
      submitId: 'item_1'
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      headerVideo: {
        url: video_1.url,
        id: video_1.id,
        title: 'Evaluating Arguments',
        mainHeader: 'Video 1',
        description: 'Watch your colleague propose an idea, then answer the questions. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'Which <span class="convertToBlue">two</span> claims does your colleague make in his speech?',
      singleMulChoiceOptions: [
        {
          userOption: 'Providing coffee free of charge will encourage employees to use their own cups.',
          userLetter: 'A',
        },
        {
          userOption: 'Switching to reusable coffee cups will reduce trash and save the company money.',
          userLetter: 'B',
        },
        {
          userOption: 'Purchasing reusable dishes will reduce waste and improve cafeteria working conditions.',
          userLetter: 'C',
        },
        {
          userOption: 'Using cardboard instead of Styrofoam food containers will reduce the amount of noncompostable waste produced in the cafeteria.',
          userLetter: 'D',
        },
        {
          userOption: 'Starting a composting program will reduce the amount of food waste produced in the cafeteria.',
          userLetter: 'E',
        },
      ],
      sharedVideo: video_1,
      numSelectOptions: 2,
      submitId: 'item_2'
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: video_1.url,
        id: video_1.id,
        title: 'Evaluating Arguments',
        mainHeader: 'Video 1',
        description: 'Watch your colleague propose an idea, then answer the questions. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'What is one negative aspect of your colleague\'s argument about reusable coffee cups?',
      singleMulChoiceOptions: [
        {
          userOption: 'Your colleague loses the attention of his audience when suggesting that coffee cups are an easy problem to address.',
          userLetter: 'A',
        },
        {
          userOption: 'Your colleague doesn\'t provide any evidence to support the claim that reusable cups will save the company money.',
          userLetter: 'B',
        },
        {
          userOption: 'Your colleague\'s argument loses focus with the suggestion that switching to reusable cups will benefit multiple groups.',
          userLetter: 'C',
        },
      ],
      sharedVideo: video_1,
      submitId: 'item_3'
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_LIST_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: video_1.url,
        id: video_1.id,
        title: 'Evaluating Arguments',
        mainHeader: 'Video 1',
        description: 'Watch your colleague propose an idea, then answer the questions. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'Consider your colleague\'s use of body language to deliver the argument. Select whether each behavior was <span class="convertToBlue">used</span> or <span class="convertToBlue">not used</span> in the delivery of the argument',
      multipleMulChoiceOptions: [
        {
          question: 'Your colleague used props to support his claims.',
          mulChoiceOptions: [
            {
              userOption: 'Your colleague <span class="boldText">did</span> use props.',
              userLetter: 'A',
            },
            {
              userOption: 'Your colleague <span class="boldText">did not</span> use props.',
              userLetter: 'B'
            }
          ],
        },
        {
          question: 'Your colleague used hand gestures to signal important points.',
          mulChoiceOptions: [
            {
              userOption: 'Your colleague <span class="boldText">did</span> use hand gestures.',
              userLetter: 'A',
            },
            {
              userOption: 'Your colleague <span class="boldText">did not</span> use hand gestures.',
              userLetter: 'B'
            }
          ],
        },
        {
          question: 'Your colleague walked around to keep his audience engaged',
          mulChoiceOptions: [
            {
              userOption: 'Your colleague <span class="boldText">did</span> walk around.',
              userLetter: 'A',
            },
            {
              userOption: 'Your colleague <span class="boldText">did not</span> walk around.',
              userLetter: 'B'
            }
          ],
        },
        {
          question: 'Your colleague made purposeful eye contact with the audience',
          mulChoiceOptions: [
            {
              userOption: 'Your colleague <span class="boldText">did</span> make purposeful eye contact.',
              userLetter: 'A',
            },
            {
              userOption: 'Your colleague <span class="boldText">did not</span> make purposeful eye contact.',
              userLetter: 'B'
            }
          ],
        }
      ],
      sharedVideo: video_1,
      numSelectOptions: 4,
      submitId: 'item_4'
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'Select the video in which the speaker most clearly demonstrates an awareness of alternative arguments.',
      headerVideo:{
        url: video_1.url,
        id: video_1.id,
        title: 'Evaluating Arguments',
        mainHeader: 'Video 1',
        description: 'Watch your colleague propose his idea, then answer the questions. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605712665',
          id: '605712665',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605713058',
          id: '605713058',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605712803',
          id: '605712803',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
        {
          url: 'https://vimeo.com/605713175',
          id: '605713175',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
        },
      ],
      sharedVideo: video_1,
      submitId: 'item_5',
    },
  ],
  [
    'question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'In the following video, the speaker uses humor to relay an idea. What effect does the use of humor have on the overall argument?',
      headerVideo:{
        url: 'https://vimeo.com/605713231',
        id: '605713231',
        mainHeader: 'Video 2',
        title: 'Injecting Humor',
        description: 'Watch and listen to your colleague\'s statement, then answer the question.',
      },
      singleMulChoiceOptions: [
        {
          userOption: 'The use of humor engages the audience and relates to an experience some people may have had.',
          userLetter: 'A',
        },
        {
          userOption: 'The use of humor distracts the audience from the real issue at hand and diminishes the effectiveness of the argument.',
          userLetter: 'B',
        },
        {
          userOption: 'The use of humor helps introduce a second argument the speaker is preparing to make.',
          userLetter: 'C',
        },
        {
          userOption: 'The use of humor demonstrates that the speaker actually agrees with the opposing side.',
          userLetter: 'D',
        },
      ],
      submitId: 'item_6',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the last task.',
      info: 'Excellent work listening to your colleague! Your decisions help illustrate how you might understand and respond to a proposal from a coworker, where your strengths lie, and where you have room to grow.<br><br>Check out your score report to learn more about what this means, how we will apply it to your Skills Profile, and how it might influence your career search.',
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png',
      questionIndex: 6,
      moduleSize: 6,
      buttonText: 'REVIEW REPORT',
      goto: '/sne-new/report/oc'
    },
  ],
]);

export const OCPersuasive2InstructionModal: SneModal =
  {
    title: 'Proposing an Idea',
    pageList: [
      {
        info: 'A small group of coworkers are sitting at a cafeteria table eating lunch. One of the coworkers has an idea for the group to consider. <br><br>This section contains one video and several corresponding questions. Play the video, then answer the questions that follow. You can only play the video  <span class="convertToBlue">once</span>.',
      },
      {
        info: '',
        showCustomContent: true,
      }
    ],
    customContent: [
      {
        url: 'https://vimeo.com/605712075',
        id: '605712075',
        info: 'Watch carefully, you can only play this video <span class="convertToBlue">once.</span>',
        playOnce: true,
        sharedVideo: { 
          url: 'https://vimeo.com/605712075',
          id: '605712075',
        },
      },
    ],
  };
