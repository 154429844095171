import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { Store } from '@ngrx/store';
import { setProfile } from '../store/profile.actions';
import { HEADER_STATES } from '../shared/header/header.helper';

@Component({
  selector: 'ets-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit {

  constructor(private learnerService: ProfileService, private store: Store) { }

  HEADER_STATES = HEADER_STATES;

  errorMessage: string = '';

  ngOnInit(): void {
    this.learnerService.getProfile().subscribe({
      next: profile => {
        this.store.dispatch(setProfile({payload: profile}));
      },
      error: err => this.errorMessage = err
    });
  }

}
