import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { Store } from '@ngrx/store';
import { selectProfile } from '../../../store';
import Profile from '../../../types/profile';
import { setProfile } from '../../../store/profile.actions';
import { HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faEye, faCheckCircle,  faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { COUNTRIES, LANGUAGES } from './account.helpers';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventObj, EventSectionEnum } from 'src/app/types/event';
import { APP_COLORS } from 'src/global-styles';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'ets-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(private learnerService: ProfileService, private store: Store, private eventService: EventService, private authService: AuthService) { }

  userProfile: Profile | undefined;

  // form fields
  currentPassword: string | undefined;
  currentEmail: string | undefined;
  currentLanguages: string[] = [];
  eligibleCountries: string[] = [];
  passwordForm = new FormGroup({
    oldPassword: new FormControl('',Validators.required),
    newPassword: new FormControl('',Validators.required),
    confirmPassword: new FormControl('',Validators.required)
  });

  // ui
  showConfirmSaveModal: boolean = false;
  showEmailModal: boolean = false;
  showPasswordModal: boolean = false;
  showDeleteModal: boolean = false;
  postErrorMessage: string | undefined;
  togglePasswordInput: boolean = false;
  newPasswordMatch: boolean = false;
  oldPasswordMatch: boolean = false;
  faEye = faEye; // eye icon
  faCheckCircle = faCheckCircle // check circle icon
  faTimesCircle = faTimesCircle // x circle icon

  countries = COUNTRIES;
  languages = LANGUAGES;
  APP_COLORS = APP_COLORS

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      this.userProfile = profile;
      if (profile) {
        this.currentEmail = profile.email;
        this.currentPassword = profile.password;
        this.currentLanguages = profile.fluentLanguages ? [...profile.fluentLanguages] : [];
        this.eligibleCountries = profile.countryWorkEligible ? [...profile.countryWorkEligible] : [];
      }
    });
  }

  toggleEmailModal() {
    this.showEmailModal = !this.showEmailModal;
  }

  confirmEmailChange() {
    this.toggleEmailModal();
  }

  cancelEmailChange() {
    this.currentEmail = this.userProfile?.email;
    this.toggleEmailModal();
  }

  togglePasswordChange() {
    this.showPasswordModal = !this.showPasswordModal;
  }

  toggleShowPassword(formControl: string) {
    if (formControl) {
      this.togglePasswordInput = !this.togglePasswordInput;
    }

  }

  confirmPasswordChange() {
    if (this.oldPasswordMatch && this.newPasswordMatch) {
      this.currentPassword = this.passwordForm.controls.confirmPassword.value;
    }

    this.togglePasswordChange();
  }

  cancelPasswordChange() {
    this.togglePasswordChange();
  }

  toggleConfirmSaveModal() {
    this.showConfirmSaveModal = !this.showConfirmSaveModal;
  }

  saveChanges(): void {
    if (this.userProfile) {
      const newProfile: Profile = {
        ...this.userProfile,
        email: this.currentEmail,
        password: this.currentPassword,
        fluentLanguages: this.currentLanguages,
        countryWorkEligible: this.eligibleCountries
      };
      this.learnerService.saveProfile(newProfile)
        // .pipe(catchError()); // write error function
        .subscribe((response: HttpResponse<{ learner: Profile }>) => {
          const profile = response.body ? response.body.learner : undefined;
          // Commenting this out for now until the backend sends back the expected response
          // if (profile) {
          this.store.dispatch(setProfile({payload: newProfile}));
        // }
        }
        );
    }
  }

  validateOldPassword() {
    this.passwordForm.controls.oldPassword.valueChanges.subscribe((value: any) => {
      this.oldPasswordMatch = value === this.userProfile?.password;
    });
  }

  validateNewPassword() {
    this.passwordForm.controls.newPassword.valueChanges.subscribe((value: any) => {
      this.newPasswordMatch = value === this.passwordForm.controls.confirmPassword.value;
    });
    this.passwordForm.controls.confirmPassword.valueChanges.subscribe((value: any) => {
      this.newPasswordMatch = value === this.passwordForm.controls.newPassword.value;
    });
  }

  toggleDeleteModal() {
    this.showDeleteModal = !this.showDeleteModal;
  }

  deleteAccount() {
    // Track the event in AWS
    this.eventService.buildEvent('Delete Account button', EventActionEnum.CLICK);
    // This will set the isActiveProfile flag to false and null out the username field and then sign the user out
    this.learnerService.deleteUserProfile().subscribe();
  }

  ngOnInit(): void {
    document.title = 'Apprize | User Profile';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Account',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Account', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Account', EventActionEnum.PAGE_VIEW, EventSectionEnum.USER_PROFILE);

    this.getProfile();
    this.validateOldPassword();
    this.validateNewPassword();
  }

}
