<div class="clr-col-lg-8 clr-col-sm-11 clr-col-12">
  <div class="profile-settings-title">
    <span>Profile Settings</span>
  </div>


  <div class="public-profile-title">
    <span>Public Profile</span>
  </div>

  <!-- <div class="profile-image-container clr-col-lg-12 clr-col-sm-12 clr-col-12">
    <div class="profile-image-wrapper profile-image-mobile ">
      <img class="profile-image " src="~/../assets/default-profile-images/default-profile-image-2.svg" alt="profile-picture">
    </div>
    <div class="upload-image-wrapper image-buttons-mobile">
      <label class="profile-image-label" for="profileImage">UPLOAD NEW IMAGE</label>
      <input class ="upload-new-image-button" type="file" id="profileImage" (clickEvent)="setProfileImage()"
          name="profileImage" accept="image/*">
      <button class="btn btn-outline delete-image-button">DELETE</button>
    </div>
  </div> -->

  <form [formGroup]="profileSettingsForm" id="profile-settings-form" (ngSubmit)="saveChanges()" novalidate>

    <div class="name-wrapper name-flex-wrap">
      <div class="full-name-container  clr-col-lg-6 clr-col-sm-6 clr-col-12">
        <label class="full-name control-label" for="fullName">Full Name</label><br>
        <input class="full-name-input"  type="text" id="fullName"
        formControlName="fullName"  required name="fullName">
        <div class="invalid" *ngIf="profileSettingsForm.controls.fullName.errors?.required && profileSettingsForm.controls.fullName.touched">
          Full Name is required
        </div>
      </div>
      <div class="preferred-name-container preferred-name-mobile clr-col-lg-6 clr-col-sm-6 clr-col-12" [class.has-error]="profileSettingsForm.controls.preferredName.invalid && profileSettingsForm.controls.preferredName.touched"
      [class.has-success]="profileSettingsForm.controls.preferredName.valid">
        <label class="preferred-name" for="preferredName">Preferred Name</label><br>
        <input class="preferred-name-input" type="text" id="preferredName"
            formControlName="preferredName" required name="preferredName">
        <span class="invalid" *ngIf="profileSettingsForm.controls.preferredName.errors?.required && profileSettingsForm.controls.preferredName.touched">
          Preferred Name is required
        </span>
      </div>
    </div>

    <div class="website-wrapper website-flex-wrap clr-col-lg-6 clr-col-sm-6 clr-col-12">

      <label class="personal-website" for="personalWebsite">Personal website</label><br>
      <input class="personal-website-input" type="text" id="personalWebsite" formControlName="personalWebsite" placeholder="http://www.janedoeisreal.com"><br>
      <span class="helper-message">Your blog, portfolio, or other relevant link.</span>

    </div>
  </form>

  <div class="button-wrapper mobile-hide">
    <ets-rounded-contained-button [disabled]="profileSettingsForm.controls.fullName.errors?.required || profileSettingsForm.controls.preferredName.errors?.required" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'" (clickEvent)="saveChanges()" ></ets-rounded-contained-button>
  </div>


  <div class="button-wrapper non-mobile-hide" >
    <ets-rounded-contained-button [disabled]="profileSettingsForm.controls.fullName.errors?.required || profileSettingsForm.controls.preferredName.errors?.required" [fullWidth]="true" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'" (clickEvent)="saveChanges()"></ets-rounded-contained-button>
  </div>


</div>
