import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogObject } from '../types/log';
import { SKILL_TYPES_ENUM } from '../types/question';
import { SkillsResponse } from '../types/skills';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class SkillsService extends BaseService {

  private skillsURL = environment.skillsAPI;
  private updateSkillsURL = environment.skillsAPI;
  private updateWorkHistorySkillsURL = environment.workHistoryAPI;
  private getWorkHistoryURL = environment.workHistoryAPI;
  private deleteWorkHistoryURL = environment.deleteWorkHistoryAPI;

  private careerSearchURL = `${environment.bgtAPI}/suboccsTypeahead`

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Skills';
  }

  careerSearch(searchString: string): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let requestLogObj: LogObject = {
        message: 'Skills: Career Search Request',
        object: searchString,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      const params = {search: searchString};

      return this.http.get<any>(this.careerSearchURL, {headers, params}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Skills: Save Work History Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }

    return new Observable<any>();
  }

  getWorkHistory(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let requestLogObj: LogObject = {
        message: 'Skills: Get Work History Request',
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<any>(this.getWorkHistoryURL, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Skills: Get Work History Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  saveWorkHistory(workHistory: any): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = workHistory;
      let requestLogObj: LogObject = {
        message: 'Skills: Save Work History Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.updateWorkHistorySkillsURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Skills: Save Work History Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // GET Skills
  getSkills(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      return this.http.get<HttpResponse<any>>(this.skillsURL, {headers: headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Skills: Get Skills Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        map(data => data),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // POST Skills
  saveProfileSkills(skillsRequest: any): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = skillsRequest;

      let requestLogObj: LogObject = {
        message: 'Skills: Save Skills Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.updateSkillsURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Skills: Save Skills Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Method that will parse the getSkills() response and return a map of
  // selected skills with the SKILL_TYPE_ENUM as the keys
  parseSelectedSkillsFromSkillsResponse(skillsResponse: SkillsResponse): Map<SKILL_TYPES_ENUM, string[]> {
    // Skill arrays to be put in the return map
    let transferableSkills: string[] = [];
    let jobSpecificSkills: string[] = [];
    let topSkills: string[] = [];

    skillsResponse.selfInput?.forEach(skill => {
      switch(skill.skillType) {
        case SKILL_TYPES_ENUM.TRANSFERABLE:
          transferableSkills.push(skill.eulerSkillName);
          break;
        case SKILL_TYPES_ENUM.JOB_SPECIFIC:
          jobSpecificSkills.push(skill.eulerSkillName);
          break;
        default:
          console.log('Unexpected skillType:', skill.skillType);
      };
      if (skill.topSkill) {
        topSkills.push(skill.eulerSkillName);
      }
    });

    let selectedSkillsMap = new Map<SKILL_TYPES_ENUM, string[]>();
    selectedSkillsMap.set(SKILL_TYPES_ENUM.TRANSFERABLE, transferableSkills);
    selectedSkillsMap.set(SKILL_TYPES_ENUM.JOB_SPECIFIC, jobSpecificSkills);
    selectedSkillsMap.set(SKILL_TYPES_ENUM.TOP, topSkills);
    return selectedSkillsMap;
  }

  filterWorkHistoryByRole(workHistory: any[], role: string) {
    workHistory.filter(work => work.roleTitle === role).map(work => work.eulerSkillName);
  }

  deleteWorkHistory(workHistory: object): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = workHistory;
      let requestLogObj: LogObject = {
        message: 'Delete Work History Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.deleteWorkHistoryURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Delete Work History Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  } 
}
