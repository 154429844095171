import { SNE_GUARD_CONFIG, SneAssessmentRecord } from '../types/sne';
import { CoachabilitySurveyData } from '../data/sne/coachability/01_survey';
import COACHABILITY_TYPES from '../types/coachability-types';
import { CoachabilityChangingCircumstancesData } from '../data/sne/coachability/02_changing-circumstances';
import { CoachabilityCoachesData } from '../data/sne/coachability/03_coaches';
import { CoachabilityCriticismData } from '../data/sne/coachability/04_criticism';
import { CoachabilityPersonalMistakesData } from '../data/sne/coachability/05_personal-mistakes';
import { CoachabilityUncertaintyData } from '../data/sne/coachability/06_uncertainty';
import { CTDeductionData } from '../data/sne/critical-thinking/01_Deduction';
import CT_TYPES from '../types/critical-thinking-types';
import { CTProductionData } from '../data/sne/critical-thinking/02_Production';
import { CTRhetoricalData } from '../data/sne/critical-thinking/03_Rhetorical';
import { CTSchedulingData } from '../data/sne/critical-thinking/04_Scheduling';
import { CTArgumentation1Data } from '../data/sne/critical-thinking/05_Argumentation-1';
import { CTArgumentation2Data } from '../data/sne/critical-thinking/06_Argumentation-2';
import { OCInformative1Data } from '../data/sne/oral-communication/01_Informative-1';
import OC_TYPES from '../types/oral-communication-types';
import { OCInformative2Data } from '../data/sne/oral-communication/02_Informative-2';
import { OCDialogic1Data } from '../data/sne/oral-communication/03_Dialogic-1';
import { OCDialogic2Data } from '../data/sne/oral-communication/04_Dialogic-2';
import { OCPersuasive1Data } from '../data/sne/oral-communication/05_Persuasive-1';
import { OCPersuasive2Data } from '../data/sne/oral-communication/06_Persuasive-2';
import { WCFreedomOfSpeechData } from '../data/sne/written-communication/01_freedom-of-speech';
import WC_TYPES from '../types/written-communication-types';
import { WCSet1Data } from '../data/sne/written-communication/02_set-1';
import { WCSet2Data } from '../data/sne/written-communication/03_set-2';
import { WCSet3Data } from '../data/sne/written-communication/04_set-3';
import { CPSNegotiationData } from '../data/sne/collaborative-problem-solving/03_negotiation';
import CPS_TYPES from '../types/cps-types';
import { HPData } from '../data/sne/collaborative-problem-solving/02_hidden-profile';
import { CPSSurveyData } from '../data/sne/collaborative-problem-solving/01_survey';
import * as sneCoachHelpers from './coachability/sne-coachability.helpers';
import * as sneWCHelpers from './written-communication/sne-written-communication.helpers';
import * as sneCriticalThinkingHelpers from './critical-thinking/sne-critical-thinking.helpers';
import * as sneOCHelpers from './oral-communication/sne-oral-communication.helpers';
import * as sneCPSHelpers from './collaborative-problem-solving-v2/sne-collaborative-problem-solving.helpers';

export enum CCH_HRID {
  CCH_SURVEY = 'CCH-Survey',
  CCH_CHANGE = 'CCH-Change',
  CCH_COACHES = 'CCH-Coaches',
  CCH_CRITICISM = 'CCH-Criticism',
  CCH_MISTAKES = 'CCH-Mistakes',
  CCH_UNCERTAINTY = 'CCH-Uncertainty'
}

export const COACHABILITY_ASSESSMENT_LIST: SNE_GUARD_CONFIG[] = [
  { id: CCH_HRID.CCH_SURVEY, questionList: CoachabilitySurveyData, type: COACHABILITY_TYPES.SURVEY},
  { id: CCH_HRID.CCH_CHANGE, questionList: CoachabilityChangingCircumstancesData, type: COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES },
  { id: CCH_HRID.CCH_COACHES, questionList: CoachabilityCoachesData, type: COACHABILITY_TYPES.COACHES },
  { id: CCH_HRID.CCH_CRITICISM, questionList: CoachabilityCriticismData, type: COACHABILITY_TYPES.CRITICISM },
  { id: CCH_HRID.CCH_MISTAKES, questionList: CoachabilityPersonalMistakesData, type: COACHABILITY_TYPES.PERSONAL_MISTAKES },
  { id: CCH_HRID.CCH_UNCERTAINTY, questionList: CoachabilityUncertaintyData, type: COACHABILITY_TYPES.UNCERTAINTY }
];

export enum CRT_HRID {
  CRT_DEDUCTION = 'CRT-Deduction',
  CRT_PRODUCTION = 'CRT-Production_Graph',
  CRT_RHET_ANALYSIS = 'CRT-Rhet_Analysis',
  CRT_SCHEDULING = 'CRT-Scheduling',
  CRT_ARGUMENTATION_1 = 'CRT-Argumentation_Public_Speaking',
  CRT_ARGUMENTATION_2 = 'CRT-Argumentation_Office'
}

export const CRITICAL_ASSESSMENT_LIST: SNE_GUARD_CONFIG[] = [
  { id: CRT_HRID.CRT_DEDUCTION, questionList: CTDeductionData, type: CT_TYPES.DEDUCTION },
  { id: CRT_HRID.CRT_PRODUCTION, questionList: CTProductionData, type: CT_TYPES.PRODUCTION },
  { id: CRT_HRID.CRT_RHET_ANALYSIS, questionList: CTRhetoricalData, type: CT_TYPES.RHETORICAL },
  { id: CRT_HRID.CRT_SCHEDULING, questionList: CTSchedulingData, type: CT_TYPES.SCHEDULING },
  { id: CRT_HRID.CRT_ARGUMENTATION_1, questionList: CTArgumentation1Data, type: CT_TYPES.ARGUMENTATION_1 },
  { id: CRT_HRID.CRT_ARGUMENTATION_2, questionList: CTArgumentation2Data, type: CT_TYPES.ARGUMENTATION_2 }
];

export enum ORC_HRID {
  ORC_INFO_1 = 'ORC-Info_1',
  ORC_INFO_2 = 'ORC-Info_2',
  ORC_DIALOGIC_1 = 'ORC-Dialogic_1',
  ORC_DIALOGIC_2 = 'ORC-Dialogic_2',
  ORC_PERSUASIVE_1 = 'ORC-Persuasive_1',
  ORC_PERSUASIVE_2 = 'ORC-Persuasive_2'
}

export const ORAL_COMMUNICATION_ASSESSMENT_LIST: SNE_GUARD_CONFIG[]  = [
  { id: ORC_HRID.ORC_INFO_1, questionList: OCInformative1Data, type: OC_TYPES.INFORMATIVE_1 },
  { id: ORC_HRID.ORC_INFO_2, questionList: OCInformative2Data, type: OC_TYPES.INFORMATIVE_2 },
  { id: ORC_HRID.ORC_DIALOGIC_1, questionList: OCDialogic1Data, type: OC_TYPES.DIALOGIC_1 },
  { id: ORC_HRID.ORC_DIALOGIC_2, questionList: OCDialogic2Data, type: OC_TYPES.DIALOGIC_2 },
  { id: ORC_HRID.ORC_PERSUASIVE_1, questionList: OCPersuasive1Data, type: OC_TYPES.PERSUASIVE_1 },
  { id: ORC_HRID.ORC_PERSUASIVE_2, questionList: OCPersuasive2Data, type: OC_TYPES.PERSUASIVE_2 },
];

export enum WRC_HRID {
  WRC_SET_1 = 'WRC-Set_1',
  WRC_SET_2 = 'WRC-Set_2',
  WRC_SET_3 = 'WRC-Set_3'
}

export const WRITTEN_OC_ASSESSMENT_LIST: SNE_GUARD_CONFIG[] = [
  // { id: 'WRC-Freedom_Of_Speech', questionList: WCFreedomOfSpeechData, type: WC_TYPES.FREEDOM_OF_SPEECH },
  { id: WRC_HRID.WRC_SET_1, questionList: WCSet1Data, type: WC_TYPES.SET_1 },
  { id: WRC_HRID.WRC_SET_2, questionList: WCSet2Data, type: WC_TYPES.SET_2 },
  { id: WRC_HRID.WRC_SET_3, questionList: WCSet3Data, type: WC_TYPES.SET_3 }
];

export enum CPS_HRID {
  CPS_FACT_SORTING = 'CPS-Fact_Sorting',
  CPS_HIDDEN_PROFILE = 'CPS-Hidden_Profile',
  CPS_NEGOTIATION = 'CPS-Negotiation'
}

export const CPS_ASSESSMENT_LIST: SNE_GUARD_CONFIG[] = [
  { id: CPS_HRID.CPS_FACT_SORTING, questionList: CPSSurveyData, type: CPS_TYPES.FACT_SORTING},
  { id: CPS_HRID.CPS_HIDDEN_PROFILE, questionList: HPData, type: CPS_TYPES.HIDDEN_PROFILE},
  { id: CPS_HRID.CPS_NEGOTIATION, questionList: CPSNegotiationData, type: CPS_TYPES.NEGOTIATION},
];

export function findNextAssessmentPath(assessments: SneAssessmentRecord[], assessmentType: string): string | undefined {
  let configList: SNE_GUARD_CONFIG[] = [];
  let genPath: any;
  let path: string | undefined;

  switch (assessmentType) {
    case 'CCH':
      configList = COACHABILITY_ASSESSMENT_LIST;
      genPath = sneCoachHelpers.generateNextQuestionPath;
      break;
    case 'WRC':
      configList = WRITTEN_OC_ASSESSMENT_LIST;
      genPath = sneWCHelpers.generateNextQuestionPath;
      break;
    case 'CRT':
      configList = CRITICAL_ASSESSMENT_LIST;
      genPath = sneCriticalThinkingHelpers.generateNextQuestionPath;
      break;
    case 'ORC':
      configList = ORAL_COMMUNICATION_ASSESSMENT_LIST;
      genPath = sneOCHelpers.generateNextQuestionPath;
      break;
    case 'CPS':
      configList = CPS_ASSESSMENT_LIST;
      genPath = sneCPSHelpers.generateNextQuestionPath;
      break;
    default:
      console.log('Could not find assessment type');
  }

  if (configList) {
    let foundContinue = false;

    configList.forEach(config => {
      if (!foundContinue) {
        const assessmentFound = assessments.find(_assessment => _assessment.assessmentHumanReadableID === config.id);
        const keysArray = Array.from(config.questionList.keys());
        const valueArray = Array.from(config.questionList.values());

        if (assessmentFound) {
          let numOfQuestionCompleted = Object.keys(assessmentFound.answerJSON).length;
          if (!assessmentFound.completed) {
            foundContinue = true;
            let nextQuestionIndex;
            if (assessmentFound.assessmentHumanReadableID === CPS_HRID.CPS_NEGOTIATION || assessmentFound.assessmentHumanReadableID === CPS_HRID.CPS_HIDDEN_PROFILE) {
              nextQuestionIndex = keysArray.findIndex(key => key.includes('question'));
            } else {
              let counter = 0; // use counter to count the number of questions before getting to the next question
              nextQuestionIndex = keysArray.findIndex(key => {
                if (key.includes('question')) {
                  if (counter === numOfQuestionCompleted) {
                    return true; // counter is equal to the num of questions answered so this question must be next
                  } else {
                    counter++; // iterate counter for the next pass through
                    return false;
                  }
                } else {
                  return false;
                }
              });
            }
            if (nextQuestionIndex !== -1) {
              path = genPath(valueArray[nextQuestionIndex], keysArray[nextQuestionIndex], config.type);
            } else {
              console.log('Could not find index to next question');
            }
          }
        } else {
          foundContinue = true;
          path = genPath(valueArray[0], keysArray[0], config.type);
        }
      }
    });
  }
  return path;
}
