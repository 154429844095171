<ets-header [headerState]="headerState" [simpleBack]='true'></ets-header>

<clr-modal [(clrModalOpen)]="resultsError" [clrModalClosable]="false">
  <h3 class="modal-title">Attention</h3>
  <div class="modal-body">
    <p>We're sorry! It's not you, it's us. We had a problem retrieving your results. Please try again from the Quiz Results page in your Profile!</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline modal-btn" (click)='handleScoreError()'>Ok</button>
  </div>
</clr-modal>

<div class="page-container">

  <div class="outer-title-container">
    <div class="title-container">
      <h1 class="title">{{preferredName}}, get to know yourself!</h1>
      <p class="subtitle">Based on your answers, we've identified what makes you unique.</p>
    </div>

    <!-- <div class="trait-section-wrapper">
      <div class="stand-out-traits" *ngIf='standOutSkillPills.length ne 0'
        [ngClass]="{'one-trait-section': traitSectionColumns === 1, 'two-trait-sections': traitSectionColumns === 2, 'three-trait-sections': traitSectionColumns === 3}"
        >
        <h4 class="trait-section-title">You Stand Out</h4>
        <hr class="trait-section-line-break">
        <div class="trait-section-skill-pill-wrapper">
          <ets-skill-pills [skillPills]='standOutSkillPills'></ets-skill-pills>
        </div>
      </div>

      <div class="on-target-traits" *ngIf='onTargetSkillPills.length ne 0'
        [ngClass]="{'one-trait-section': traitSectionColumns === 1, 'two-trait-sections': traitSectionColumns === 2, 'three-trait-sections': traitSectionColumns === 3}"
        >
        <h4 class="trait-section-title">On Target</h4>
        <hr class="trait-section-line-break">
        <div class="trait-section-skill-pill-wrapper">
          <ets-skill-pills [skillPills]='onTargetSkillPills'></ets-skill-pills>
        </div>
      </div>

      <div class="growth-areas-traits" *ngIf='growthAreasSkillPills.length ne 0'
        [ngClass]="{'one-trait-section': traitSectionColumns === 1, 'two-trait-sections': traitSectionColumns === 2, 'three-trait-sections': traitSectionColumns === 3}"
        >
        <h4 class="trait-section-title">Growth Areas</h4>
        <hr class="trait-section-line-break">
        <div class="trait-section-skill-pill-wrapper">
          <ets-skill-pills [skillPills]='growthAreasSkillPills'></ets-skill-pills>
        </div>
      </div>
    </div> -->
  </div>

  <div class="traits-container">
    <div
        class="trait-outer-wrapper"
        *ngFor='let trait of personalityTraits; let i = index'
      >

      <div class="trait-card-container">
        <!-- [ngClass]="{'trait-card-container-show': trait.showCard,'trait-card-container-hide': !trait.showCard}" -->
        <div class="card-right-wrapper">
          <img class="trait-img" src="{{trait.img}}" alt="trait image">
        </div>
        
        <div class="card-left-wrapper">
          <div class="card-left-title-wrapper">
            <h3 class="trait-title">{{trait.title}}</h3>
            <h2 class="card-title">{{trait.personalityTraitCardVersion.traitCardTitle}}</h2>
            <p class="card-description">{{trait.personalityTraitCardVersion.traitCardDescription}}</p>
          </div>

          <!-- <div class="card-left-inner-wrapper">
            <ets-skill-pills [skillPills]='trait.skillPills'></ets-skill-pills>
          </div> -->
        </div>

        

      </div>
      <hr
        class="trait-line-break"
        *ngIf="i !== 4"
      >
    </div>
  </div>


  <div class="button-wrapper">
    <!-- View My Careers -->
    <ets-rounded-contained-button
      style="width: 131px; margin-bottom: 40px; position: absolute; bottom: 10px; z-index: 1000; align-self: center"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'Finish'"
      (click)="finish()"
    ></ets-rounded-contained-button>
  </div>

</div>



