<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="true"
>
</ets-header>

<div class="interstitial-container">

  <ets-interstitial
    [title]="questionData?.title"
    [info]="questionData?.info"
    [image]="questionData?.image"
    [buttonText]="questionData?.buttonText"
    [buttonColor]="APP_COLORS.uiaction"
    [showButton]="false"
    [questionIndex]="questionData?.questionIndex"
    [moduleSize]="questionData?.moduleSize"
    [duration]="questionData?.duration"
    [durationBoxColor]="questionData?.durationBoxColor"
  >
  </ets-interstitial>

</div>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="false"
  [isButtonDisabled]="false"
  (nextEmitter)="getNextQuestion()"
  [drawerActive]="false"
  [buttonText]="questionData?.buttonText"
  [showSaveAndExit]="endInterstitial"
>
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeModalAndNav()"
>

  <div *ngIf="instructionModal?.customContent" contentID="customContent">

    <ets-sne-email-layout
      *ngIf="instructionModal?.customContent[0].to"
      [emails]="instructionModal?.customContent"
    ></ets-sne-email-layout>

    <ets-sne-conversation-log-text
      *ngIf="instructionModal?.customContent[0].user"
      [chatLog]="instructionModal?.customContent"
      [showHeader]="false"
    ></ets-sne-conversation-log-text>

    <ets-sne-video
      *ngIf="instructionModal?.customContent[0].url"
      [videoUrl]="instructionModal?.customContent[0].url"
      [videoId]="instructionModal?.customContent[0].id"
      [videoOnlyFlag]="true"
    ></ets-sne-video>

  </div>

</ets-modal>
 