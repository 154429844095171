const CAREER_BG_MAP = [
  { careerTitle: 'Conservationist', bgColor: '#D5F2E2' },
  { careerTitle: 'Gardener', bgColor: '#E8EFFE' },
  { careerTitle: 'Buyer', bgColor: '#FFF4CA' },
  { careerTitle: 'Chief Executive Officer', bgColor: '#D5F2E2' },
  { careerTitle: 'Fundraising Manager', bgColor: '#E8EFFE' },
  { careerTitle: 'General Manager', bgColor: '#E8EFFE' },
  { careerTitle: 'Operations Manager', bgColor: '#FEDAC5' },
  { careerTitle: 'Program Manager', bgColor: '#E8EFFE' },
  { careerTitle: 'Project Manager', bgColor: '#FEDAC5' },
  { careerTitle: 'Public Administrator', bgColor: '#FFF4CA' },
  { careerTitle: 'Executive Assistant', bgColor: '#D5F2E2' },
  { careerTitle: 'Office Manager', bgColor: '#E8EFFE' },
  { careerTitle: 'Career Coach', bgColor: '#D5F2E2' },
  { careerTitle: 'Caregiver', bgColor: '#FFF4CA' },
  { careerTitle: 'Minister', bgColor: '#FEDAC5' },
  { careerTitle: 'Social Worker', bgColor: '#FEDAC5' },
  { careerTitle: 'Community Service Manager', bgColor: '#FEDAC5' },
  { careerTitle: 'Architect', bgColor: '#FFF4CA'},
  { careerTitle: 'Construction Manager', bgColor: '#E8EFFE'},
  { careerTitle: 'Landscape Architect', bgColor: '#FEDAC5'},
  { careerTitle: 'Flight Attendant', bgColor: '#D5F2E2'},
  { careerTitle: 'Fashion Designer', bgColor: '#FEDAC5'},
  { careerTitle: 'Industrial Designer', bgColor: '#D5F2E2'},
  { careerTitle: 'Interior Designer', bgColor: '#E8EFFE'},
  { careerTitle: 'Photographer', bgColor: '#E8EFFE'},
  { careerTitle: 'Reporter', bgColor: '#D5F2E2'},
  { careerTitle: 'Writer', bgColor: '#FEDAC5'},
  { careerTitle: 'College Administrator', bgColor: '#FFF4CA'},
  { careerTitle: 'Archivist', bgColor: '#FFF4CA'},
  { careerTitle: 'Art Teacher', bgColor: '#FEDAC5'},
  { careerTitle: 'Language Teacher', bgColor: '#E8EFFE'},
  { careerTitle: 'Preschool Administrator', bgColor: '#FFF4CA'},
  { careerTitle: 'Athletic Coach', bgColor: '#FFF4CA'},
  { careerTitle: 'College Professor', bgColor: '#D5F2E2'},
  { careerTitle: 'Museum Curator', bgColor: '#FFF4CA'},
  { careerTitle: 'Curriculum Designer', bgColor: '#FFF4CA'},
  { careerTitle: 'Grade School Administrator', bgColor: '#E8EFFE'},
  { careerTitle: 'Elementary School Teacher', bgColor: '#FFF4CA'},
  { careerTitle: 'Middle or High School Teacher', bgColor: '#FFF4CA'},
  { careerTitle: 'Music Teacher', bgColor: '#D5F2E2'},
  { careerTitle: 'Preschool Teacher', bgColor: '#FFF4CA'},
  { careerTitle: 'Chemical Engineer', bgColor: '#D5F2E2'},
  { careerTitle: 'Civil Engineer', bgColor: '#D5F2E2'},
  { careerTitle: 'Civil Engineering Technician', bgColor: '#E8EFFE'},
  { careerTitle: 'Electrical Designer', bgColor: '#D5F2E2'},
  { careerTitle: 'Electrical Engineer', bgColor: '#E8EFFE'},
  { careerTitle: 'Electronics Engineer', bgColor: '#E8EFFE'},
  { careerTitle: 'Environmental Engineer', bgColor: '#FFF4CA'},
  { careerTitle: 'Engineering Technologist', bgColor: '#E8EFFE'},
  { careerTitle: 'Industrial Engineer', bgColor: '#FFF4CA'},
  { careerTitle: 'Marine Engineer', bgColor: '#FEDAC5'},
  { careerTitle: 'Drafter', bgColor: '#E8EFFE'},
  { careerTitle: 'Mechanical Engineer', bgColor: '#FEDAC5'},
  { careerTitle: 'Nuclear Engineer', bgColor: '#FFF4CA'},
  { careerTitle: 'Land Surveyor', bgColor: '#D5F2E2'},
  { careerTitle: 'Accountant', bgColor: '#FFF4CA'},
  { careerTitle: 'Bookkeeper', bgColor: '#FEDAC5'},
  { careerTitle: 'Auditor', bgColor: '#FFF4CA'},
  { careerTitle: 'Financial Analyst', bgColor: '#FFF4CA'},
  { careerTitle: 'Financial Manager', bgColor: '#FFF4CA'},
  { careerTitle: 'Insurance Sales Agent', bgColor: '#E8EFFE'},
  { careerTitle: 'Loan Officer', bgColor: '#D5F2E2'},
  { careerTitle: 'Financial Advisor', bgColor: '#E8EFFE'},
  { careerTitle: 'Financial Trader', bgColor: '#E8EFFE'},
  { careerTitle: 'Athletic Trainer', bgColor: '#D5F2E2'},
  { careerTitle: 'Case Manager', bgColor: '#D5F2E2'},
  { careerTitle: 'Clinical Nurse Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'Medical Office Manager', bgColor: '#D5F2E2'},
  { careerTitle: 'Community Health Worker', bgColor: '#FEDAC5'},
  { careerTitle: 'Dentist or Orthodontist', bgColor: '#E8EFFE'},
  { careerTitle: 'Nutritionist', bgColor: '#D5F2E2'},
  { careerTitle: 'EMT or Paramedic', bgColor: '#FFF4CA'},
  { careerTitle: 'Physician', bgColor: '#FFF4CA'},
  { careerTitle: 'Wellness Coordinator', bgColor: '#D5F2E2'},
  { careerTitle: 'Healthcare Administrator', bgColor: '#FFF4CA'},
  { careerTitle: 'ICU Nurse', bgColor: '#FFF4CA'},
  { careerTitle: 'Medical Director', bgColor: '#E8EFFE'},
  { careerTitle: 'Medical Office Assistant', bgColor: '#D5F2E2'},
  { careerTitle: 'Nursing Manager', bgColor: '#FFF4CA'},
  { careerTitle: 'Nurse Practitioner', bgColor: '#FEDAC5'},
  { careerTitle: 'Patient Navigator', bgColor: '#D5F2E2'},
  { careerTitle: 'Pharmacist', bgColor: '#FEDAC5'},
  { careerTitle: 'Physical Therapist', bgColor: '#D5F2E2'},
  { careerTitle: 'Physical Therapist Assistant', bgColor: '#D5F2E2'},
  { careerTitle: 'Physician Assistant', bgColor: '#FEDAC5'},
  { careerTitle: 'Psychologist', bgColor: '#E8EFFE'},
  { careerTitle: 'Registered Nurse', bgColor: '#FFF4CA'},
  { careerTitle: 'Speech-Language Pathologist', bgColor: '#FFF4CA'},
  { careerTitle: 'Veterinarian', bgColor: '#E8EFFE'},
  { careerTitle: 'Bartender', bgColor: '#FEDAC5'},
  { careerTitle: 'Chef', bgColor: '#FFF4CA'},
  { careerTitle: 'Tour Guide', bgColor: '#D5F2E2'},
  { careerTitle: 'Travel Agent', bgColor: '#D5F2E2'},
  { careerTitle: 'Compensation Manager', bgColor: '#FFF4CA'},
  { careerTitle: 'Human Resources Manager', bgColor: '#FFF4CA'},
  { careerTitle: 'Human Resources Specialist', bgColor: '#FEDAC5'},
  { careerTitle: 'Recruiter', bgColor: '#FFF4CA'},
  { careerTitle: 'Training Specialist', bgColor: '#D5F2E2'},
  { careerTitle: 'Computer Programmer', bgColor: '#D5F2E2'},
  { careerTitle: 'Computer Support Specialist', bgColor: '#FFF4CA'},
  { careerTitle: 'Cybersecurity Analyst', bgColor: '#E8EFFE'},
  { careerTitle: 'Database Administrator', bgColor: '#D5F2E2'},
  { careerTitle: 'Database Architect', bgColor: '#D5F2E2'},
  { careerTitle: 'IT Project Manager', bgColor: '#D5F2E2'},
  { careerTitle: 'IT Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'Multimedia Designer', bgColor: '#E8EFFE'},
  { careerTitle: 'Network Engineer', bgColor: '#FFF4CA'},
  { careerTitle: 'Network Support Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'Software Developer', bgColor: '#D5F2E2'},
  { careerTitle: 'Software QA Tester', bgColor: '#FFF4CA'},
  { careerTitle: 'Network Administrator', bgColor: '#D5F2E2'},
  { careerTitle: 'Computer Systems Analyst', bgColor: '#E8EFFE'},
  { careerTitle: 'Telecom Engineering Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'UX Designer', bgColor: '#E8EFFE'},
  { careerTitle: 'Web Developer', bgColor: '#FEDAC5'},
  { careerTitle: 'Attorney', bgColor: '#D5F2E2'},
  { careerTitle: 'Judge', bgColor: '#FFF4CA'},
  { careerTitle: 'Law Clerk', bgColor: '#FFF4CA'},
  { careerTitle: 'Legal Assistant', bgColor: '#FFF4CA'},
  { careerTitle: 'Safety Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'Maintenance Supervisor', bgColor: '#FFF4CA'},
  { careerTitle: 'Production Supervisor', bgColor: '#FEDAC5'},
  { careerTitle: 'Manufacturing Manager', bgColor: '#D5F2E2'},
  { careerTitle: 'Advertising Manager', bgColor: '#D5F2E2'},
  { careerTitle: 'Digital Marketing Specialist', bgColor: '#D5F2E2'},
  { careerTitle: 'Market Researcher', bgColor: '#E8EFFE'},
  { careerTitle: 'Marketing Manager', bgColor: '#FEDAC5'},
  { careerTitle: 'Communications Specialist', bgColor: '#E8EFFE'},
  { careerTitle: 'Communications Manager', bgColor: '#FEDAC5'},
  { careerTitle: 'Actor', bgColor: '#D5F2E2'},
  { careerTitle: 'Choreographer', bgColor: '#FFF4CA'},
  { careerTitle: 'Composer', bgColor: '#FFF4CA'},
  { careerTitle: 'Music Director', bgColor: '#FEDAC5'},
  { careerTitle: 'Musician', bgColor: '#FFF4CA'},
  { careerTitle: 'Business Analyst', bgColor: '#E8EFFE'},
  { careerTitle: 'Urban Planner', bgColor: '#D5F2E2'},
  { careerTitle: 'Data Analyst', bgColor: '#E8EFFE'},
  { careerTitle: 'Marketing Data Analyst', bgColor: '#FFF4CA'},
  { careerTitle: 'Data Scientist', bgColor: '#D5F2E2'},
  { careerTitle: 'Economist', bgColor: '#E8EFFE'},
  { careerTitle: 'Mathematician', bgColor: '#E8EFFE'},
  { careerTitle: 'Security Analyst', bgColor: '#E8EFFE'},
  { careerTitle: 'Account Executive', bgColor: '#D5F2E2'},
  { careerTitle: 'Account Manager', bgColor: '#FFF4CA'},
  { careerTitle: 'Medical Sales Representative', bgColor: '#FFF4CA'},
  { careerTitle: 'Sales Manager', bgColor: '#E8EFFE'},
  { careerTitle: 'Sales Representative', bgColor: '#FFF4CA'},
  { careerTitle: 'Software Sales Representative', bgColor: '#FEDAC5'},
  { careerTitle: 'Archeologist', bgColor: '#FFF4CA'},
  { careerTitle: 'Biological Technician', bgColor: '#E8EFFE'},
  { careerTitle: 'Chemist', bgColor: '#FFF4CA'},
  { careerTitle: 'Environmental Planner', bgColor: '#FFF4CA'},
  { careerTitle: 'Geophysicist', bgColor: '#E8EFFE'},
  { careerTitle: 'Meteorologist', bgColor: '#FFF4CA'},
  { careerTitle: 'Natural Science Manager', bgColor: '#FEDAC5'},
  { careerTitle: 'Physicist', bgColor: '#E8EFFE'},
  { careerTitle: 'Social Scientist', bgColor: '#E8EFFE'},
  { careerTitle: 'Wildlife Biologist', bgColor: '#E8EFFE'},
  { careerTitle: 'Pilot', bgColor: '#D5F2E2'}
];

export default CAREER_BG_MAP;
