import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MadlibsComponent } from './madlibs/madlibs.component';
import { MultiVideoChoiceVideoComponent } from './multi-video-choice-video/multi-video-choice-video.component';
import { SneConversationLogTextComponent } from './sne-conversation-log-text/sne-conversation-log-text.component';
import { SneEmailLayoutComponent } from './sne-email-layout/sne-email-layout.component';
import { SNEInstructionComponent } from './sne-instruction/s-n-e-instruction.component';
import { SneMultiChoiceListComponent } from './sne-multi-choice-list/sne-multi-choice-list.component';
import { SneProgressBarComponent } from './sne-progress-bar/sne-progress-bar.component';
import { SneQuestionVideoComponent } from './sne-ques-video/sne-question-video.component';
import {
  SneSingleMultiChoiceQuestionComponent
} from './sne-single-multi-choice-question/sne-single-multi-choice-question.component';
import { SneSplitScreenComponent } from './sne-split-screen/sne-split-screen.component';
import { SneVideoComponent } from './sne-video/sne-video.component';
import { SharedModule } from '../../shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { SneOverviewComponent } from './sne-overview/sne-overview.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SneSocialMediaPostComponent } from './sne-social-media-post/sne-social-media-post.component';
import { SneChatbotMessagesComponent } from './sne-chatbot-messages/sne-chatbot-messages.component';
import { SneHpMenuComponent } from './sne-hp-menu/sne-hp-menu.component';
import { ClientRankingStarsComponent } from './client-ranking-stars/client-ranking-stars.component';
import { SneSingleMultiChoiceAndVideosComponent } from './sne-single-multi-choice-and-videos/sne-single-multi-choice-and-videos.component';
import { DirectivesModule } from 'src/app/directives/directives.module';

@NgModule({
  declarations: [
    MadlibsComponent,
    MultiVideoChoiceVideoComponent,
    SneConversationLogTextComponent,
    SneEmailLayoutComponent,
    SNEInstructionComponent,
    SneMultiChoiceListComponent,
    SneProgressBarComponent,
    SneQuestionVideoComponent,
    SneSingleMultiChoiceQuestionComponent,
    SneSplitScreenComponent,
    SneVideoComponent,
    SneOverviewComponent,
    SneSocialMediaPostComponent,
    SneChatbotMessagesComponent,
    SneHpMenuComponent,
    ClientRankingStarsComponent,
    SneSingleMultiChoiceAndVideosComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClarityModule,
    FontAwesomeModule,
    DirectivesModule,
  ],
  exports: [
    SneSocialMediaPostComponent,
    MadlibsComponent,
    MultiVideoChoiceVideoComponent,
    SneConversationLogTextComponent,
    SneEmailLayoutComponent,
    SNEInstructionComponent,
    SneMultiChoiceListComponent,
    SneProgressBarComponent,
    SneQuestionVideoComponent,
    SneSingleMultiChoiceQuestionComponent,
    SneSplitScreenComponent,
    SneVideoComponent,
    SneOverviewComponent,
    SneHpMenuComponent,
    ClientRankingStarsComponent,
    SneChatbotMessagesComponent,
    SneSingleMultiChoiceAndVideosComponent,
  ]
})
export class SneComponentsModule { }
