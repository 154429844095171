import Profile from '../types/profile';
import { createReducer, on } from '@ngrx/store';
import { setProfile } from './profile.actions';

export interface State {
  profile: Profile | undefined;
}

export const profileFeatureKey = 'profile';

export const initialState: State = {
  profile: undefined
};

export const reducer = createReducer(
  initialState,
  on(setProfile, (state, { payload }) => ({...state, profile: payload}))
);
