<div class="container">
  <div class="left"
    [ngStyle]="{'background-color': leftBgColor ? leftBgColor : null}"
    [ngClass]="{
      'first-section': questionSection === QUESTION_SECTIONS.SECTION_ONE && !leftBgColor,
      'second-section': questionSection === QUESTION_SECTIONS.SECTION_TWO && !leftBgColor,
      'third-section': questionSection === QUESTION_SECTIONS.SECTION_THREE && !leftBgColor,
      'fourth-section': questionSection === QUESTION_SECTIONS.SECTION_FOUR && !leftBgColor,
      'fifth-section': questionSection === QUESTION_SECTIONS.SECTION_FIVE && !leftBgColor
    }"
  >
    <img
      *ngIf="desktopImageSrc"
      src="{{desktopImageSrc}}"
      alt=""
      aria-hidden="true"
      class="section-bg intro-image"
      [ngClass]="{'full-section-bg': fullSectionBgImage}"
    >
    <img
      *ngIf="tabletImageSrc"
      src="{{tabletImageSrc}}"
      alt=""
      aria-hidden="true"
      class="tablet-section-bg intro-image"
    >
    <img
    *ngIf="mobileImageSrc"
    src="{{mobileImageSrc}}"
    alt=""
    aria-hidden="true"
    class="mobile-section-bg intro-image"
  >
  </div>
  <div class="right">
    <div class="inner mobile-overlap">
      <div class="title-text">
        <span>{{title}}</span>
      </div>
      <div class="display-text" *ngIf="info">
        <span>{{info}}</span>
      </div>
      <div class="button-wrapper" *ngIf="!isRoundedButton">
        <ets-solid-button [text]="buttonText" [color]="buttonColor" (clickEvent)="onClick()">
        </ets-solid-button>
      </div>
      <div class="button-wrapper" *ngIf="isRoundedButton">
        <ets-rounded-contained-button
          (clickEvent)="onClick()"
          [buttonText]="buttonText"
          [backgroundColor]="APP_COLORS.uiaction"
          [textColor]="APP_COLORS.white"
          >
        </ets-rounded-contained-button>
        <!-- Commenting out until functionality is done -->
        <!-- <div class="later-button-wrapper" *ngIf="questionSection === QUESTION_SECTIONS.SECTION_THREE">
          <button (click)="skipSection()">Later</button>
        </div> -->
      </div>
    </div>
  </div>
</div>
