<div class="container">
  <span class="question" [innerHTML]="headerQuestion"></span>
  <div class="video-wrapper">
    <div class="video-container" *ngFor="let video of videos">
      <ets-sne-video [videoId]="video.id" [videoUrl]="video.url" [videoTitle]="video.title" [videoOnlyFlag]="true"
        [questionSide]="true">
      </ets-sne-video>
      <span class="video-title" [innerHTML]="video.title"></span>
    </div>
  </div>

  <div *ngIf="inputType === SNE_INPUT_TYPES.RADIO">
    <div class="option" *ngFor="let option of multiChoiceOptions; let i = index;">
      <ets-radio-button (clickEvent)="selectSingleResponse(option.userLetter)" [value]="option.userLetter"
        [name]="'answer'" [id]="mobileCheck ? option.userLetter + '-mobile' : option.userLetter"
        [label]="option.userOption">
      </ets-radio-button>
    </div>
  </div>
  <div *ngIf="inputType === SNE_INPUT_TYPES.CHECKBOX">
    <div class="options" *ngFor='let option of multiChoiceOptions; let i = index;'>
      <div class="option" *ngIf="inputType && inputType === SNE_INPUT_TYPES.CHECKBOX">
        <ets-checkbox (clickEvent)="selectMultiResponse(option.userLetter)" [value]="option.userLetter"
          [name]="option.userLetter" [id]="mobileCheck ? option.userLetter + '-mobile' : option.userLetter"
          [label]="option.userOption">
        </ets-checkbox>
      </div>
    </div>
  </div>
</div>