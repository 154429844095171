import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ets-progress-number',
  templateUrl: './progress-number.component.html',
  styleUrls: ['./progress-number.component.scss']
})
export class ProgressNumberComponent implements OnInit {

  constructor() { }

  @Input() section: string | undefined;
  @Input() arcPercent: number | undefined;

  ngOnInit(): void {
  }

}
