import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../services/profile.service';
import Profile from '../../../types/profile';
import { Store } from '@ngrx/store';
import { selectProfile } from '../../../store';
import { HttpResponse } from '@angular/common/http';
import { setProfile } from '../../../store/profile.actions';
import { DISABILITY_STATUS, RACE, GENDERS } from './diversity-and-privacy.helpers';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-diversity-and-privacy',
  templateUrl: './diversity-and-privacy.component.html',
  styleUrls: ['./diversity-and-privacy.component.scss']
})
export class DiversityAndPrivacyComponent implements OnInit {

  constructor(private learnerService: ProfileService, private store: Store, private eventService: EventService) { }

  getErrorMessage: string = '';
  postErrorMessage: string = '';
  sub!: Subscription;
  userProfile!: Profile;
  diversityAndPrivacyForm = new FormGroup({
    diversityWillingToShare: new FormControl(''),
    diversityGender: new FormControl(''),
    diversityLGBTQ: new FormControl(''),
    diversityLatinX: new FormControl(''),
    diversityRace: new FormControl(''),
    diversityDisabilityStatus: new FormControl(''),
    privacyAllowEmployersToView: new FormControl(''),
    privacyAllowTrainingPartnersToView: new FormControl(''),
    privacyAllowDataPartnersToView: new FormControl(''),
  });

  GENDERS = GENDERS;
  RACE = RACE;
  DISABILITY_STATUS = DISABILITY_STATUS;

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        this.setInitialFormValues();
      }
    });
  }

  ngOnInit(): void {
    document.title = 'Apprize | User Profile';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Diversity & Privacy',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Diversity & Privacy', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Diversity & Privacy', EventActionEnum.PAGE_VIEW, EventSectionEnum.USER_PROFILE);


    this.getProfile();
  }

  setInitialFormValues(): void {
    if (this.userProfile) {
      this.diversityAndPrivacyForm.controls.diversityWillingToShare.setValue(this.userProfile.diversityWillingToShare);
      this.diversityAndPrivacyForm.controls.diversityGender.setValue(this.userProfile.diversityGender);
      this.diversityAndPrivacyForm.controls.diversityLGBTQ.setValue(this.userProfile.diversityLGBTQ);
      this.diversityAndPrivacyForm.controls.diversityLatinX.setValue(this.userProfile.diversityLatinX);
      this.diversityAndPrivacyForm.controls.diversityRace.setValue(this.userProfile.diversityRace);
      // defaulting this field to no if it hasn't been set yet
      if (this.userProfile.diversityDisabilityStatus) {
        this.diversityAndPrivacyForm.controls.diversityDisabilityStatus.setValue(this.userProfile.diversityDisabilityStatus);
      } else {
        this.diversityAndPrivacyForm.controls.diversityDisabilityStatus.setValue(DISABILITY_STATUS.NO);
      }

      this.diversityAndPrivacyForm.controls.privacyAllowEmployersToView.setValue(this.userProfile.privacyAllowEmployersToView);
      this.diversityAndPrivacyForm.controls.privacyAllowTrainingPartnersToView.setValue(this.userProfile.privacyAllowTrainingPartnersToView);
      this.diversityAndPrivacyForm.controls.privacyAllowDataPartnersToView.setValue(this.userProfile.privacyAllowDataPartnersToView);
    }
  }

  updateProfile(savedProfile: Profile): void {
    savedProfile.diversityWillingToShare = this.diversityAndPrivacyForm.controls.diversityWillingToShare.value;
    savedProfile.diversityGender = this.diversityAndPrivacyForm.controls.diversityGender.value;
    savedProfile.diversityLGBTQ = this.diversityAndPrivacyForm.controls.diversityLGBTQ.value;
    savedProfile.diversityRace = this.diversityAndPrivacyForm.controls.diversityRace.value;
    savedProfile.diversityLatinX = this.diversityAndPrivacyForm.controls.diversityLatinX.value;
    savedProfile.diversityDisabilityStatus = this.diversityAndPrivacyForm.controls.diversityDisabilityStatus.value;
    savedProfile.privacyAllowEmployersToView = this.diversityAndPrivacyForm.controls.privacyAllowEmployersToView.value;
    savedProfile.privacyAllowTrainingPartnersToView = this.diversityAndPrivacyForm.controls.privacyAllowTrainingPartnersToView.value;
    savedProfile.privacyAllowDataPartnersToView = this.diversityAndPrivacyForm.controls.privacyAllowDataPartnersToView.value;
  }

  saveChanges(): void {
    // Populate all the fields on the learner object from the form group
    if (this.userProfile) {
      let newProfile: Profile = {...this.userProfile};
      this.updateProfile(newProfile);
      this.sub = this.learnerService.saveProfile(newProfile)
        // .pipe(catchError()); // write error function
        .subscribe((response: HttpResponse<any>) => {
          const profile = response.body ? response.body.learner : undefined;
          // Commenting this out for now until the backend sends back the expected response
          // if (profile) {
          this.store.dispatch(setProfile({payload: newProfile}));
        // }
        });
    }
  }

  // Method to track the click of the Privacy Policy link
  trackPrivacyPolicyLinkClick() {
    // Track the event in AWS
    this.eventService.buildEvent('Privacy Policy link', EventActionEnum.CLICK);
  }

}
