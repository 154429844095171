<div class="container">
  <div class="section section-left"
    [ngStyle]="{'background-color': leftBackgroundColor}"
  >
    <ng-content select=".left"></ng-content>
  </div>
  <div class="section section-right"
    [ngStyle]="{'background-color': rightBackgroundColor}"
  >
    <ng-content select=".right"></ng-content>
  </div>
</div>
