<!-- Desktop view -->
<div class="video-thumbnail-container mobile-hide" *ngIf="!videoOnlyFlag">
  <div *ngIf="noModal" [id]="videoId + '-' + videoSetId + '-desktop'">
    <iframe
          class="iframe-coachability"
          [src]="sanitizedVideoUrl"
          width="100px"
          height="100%"
          frameborder="0"
          allow="autoplay; fullscreen" allowfullscreen mozallowfullscreen webkitallowfullscreen>
      </iframe>
  </div>
  <div *ngIf="!noModal" [id]="videoId + '-' + videoSetId + '-desktop'"></div> <!-- thumbnail -->
  <img *ngIf="!noModal" class="play-button" src="/assets/icons/play-button.svg" alt="play button" (click)="toggleModal()">

  <div class="video-data-container" *ngIf="videoTitle || inputType">
    <!-- checkbox input -->
    <input *ngIf="inputType && inputType === SNE_INPUT_TYPES.CHECKBOX"
      [type]="inputType"
      class="video-input checkbox {{videoId}}-input"
      id="desktop-{{videoId}}"
      (click)="emitUserResponse(videoId)"
    >
    <!-- radio input -->
    <input *ngIf="inputType && inputType === SNE_INPUT_TYPES.RADIO"
      [type]="inputType"
      class="video-input radio-button {{videoId}}-input"
      id="desktop-{{videoId}}"
      name="desktop-video-input"
      (click)="emitUserResponse(videoId)"
    >

    <label class="video-title-duration-container" for="desktop-{{videoId}}">
      <p *ngIf="videoTitle" class="video-title">{{videoTitle}}</p>
      <p class="video-duration">{{videoDuration}}</p>
    </label>
  </div>
</div>

<!-- Mobile view -->
<div class="video-thumbnail-container non-mobile-hide" *ngIf="!videoOnlyFlag">
  <div [id]="videoId + '-' + videoSetId + '-mobile'"></div> <!-- thumbnail -->

  <div class="video-data-container" *ngIf="videoTitle || inputType">
    <!-- checkbox input -->
    <input *ngIf="inputType && inputType === SNE_INPUT_TYPES.CHECKBOX"
      [type]="inputType"
      class="video-input checkbox {{videoId}}-input"
      id="mobile-{{videoId}}"
      (click)="emitUserResponse(videoId)"
    >
    <!-- radio input -->
    <input *ngIf="inputType && inputType === SNE_INPUT_TYPES.RADIO"
      [type]="inputType"
      class="video-input radio-button {{videoId}}-input"
      id="mobile-{{videoId}}"
      name="mobile-video-input"
      (click)="emitUserResponse(videoId)"
    >

    <label class="video-title-duration-container" for="mobile-{{videoId}}">
      <p *ngIf="videoTitle" class="video-title">{{videoTitle}}</p>
      <p class="video-duration">{{videoDuration}}</p>
    </label>
  </div>
</div>

<div class="video-only-container" *ngIf="videoOnlyFlag">
  <div *ngIf="videoIsPlaying && playOnce" class="video-is-playing"></div>
  <div *ngIf="videoHasEnded && playOnce" class="video-ended">
    <h1>This video has already been played.</h1>
  </div>
    <iframe
      #videoOnlyFrame
      class='iframe'
      [ngClass]="{'question-side': questionSide}"
      id="video-only-frame"
      [src]="sanitizedVideoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen" allowfullscreen mozallowfullscreen webkitallowfullscreen>
    </iframe>
    <!-- <iframe
      class="iframe"
      [src]="sanitizedVideoUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen" allowfullscreen mozallowfullscreen webkitallowfullscreen>
  </iframe> -->
</div>

<clr-modal [(clrModalOpen)]="showModal" [clrModalClosable]="true" [clrModalSize]="'lg'" *ngIf="!noModal">
  <h3 class="modal-title section-title">Video</h3>
  <h2 class="modal-title instruction-title">{{videoTitle}}</h2>
  <div class="modal-body">
    <div class="video-modal-container">
      <iframe
          class="iframe"
          [src]="sanitizedVideoUrl"
          width="100%"
          height="100%"
          frameborder="0"
          allow="autoplay; fullscreen" allowfullscreen mozallowfullscreen webkitallowfullscreen>
      </iframe>
    </div>
  </div>

  <div class="modal-footer overview-footer">
  </div>
</clr-modal>
