import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountProfileComponent } from './account-profile.component';
import { AccountComponent } from './components/account/account.component';
import { DiversityAndPrivacyComponent } from './components/diversity-and-privacy/diversity-and-privacy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { QuizResultsComponent } from './components/quiz-results/quiz-results.component';
import { TopNavBarComponent } from './components/top-nav-bar/top-nav-bar.component';
import { WorkPreferencesComponent } from './components/work-preferences/work-preferences.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AccountProfileComponent,
    AccountComponent,
    DiversityAndPrivacyComponent,
    ProfileComponent,
    QuizResultsComponent,
    TopNavBarComponent,
    WorkPreferencesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    NgxChartsModule,
    FontAwesomeModule,
    // IvyCarouselModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AccountProfileModule { }
