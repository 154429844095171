import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';

@Component({
  selector: 'ets-oc-sne-intro',
  templateUrl: './oc-sne-intro.component.html',
  styleUrls: ['./oc-sne-intro.component.scss']
})
export class OcSneIntroComponent extends SneOralCommunicationBase implements OnInit {

  headerState: HEADER_STATES = HEADER_STATES.BACK;

  APP_COLORS = APP_COLORS;
  numberText = '04.';

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  onClick(){
    this.getNextQuestion();
  }

  ngOnInit(): void {
    this.processParams();
  }

}
