import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '../../validators/password-validator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
// import { LearnerCareerService } from '../../services/learner-career.service';
import { faEye, faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { setProfile } from '../../store/profile.actions';
import { APP_COLORS } from 'src/global-styles';

@Component({
  selector: 'ets-forgot-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  faEye = faEye;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  APP_COLORS = APP_COLORS;
  passwordRow1Focused = false;
  passwordRow2Focused = false;

  // Error state flags used to display error messages on the UI
  initiateReset_unknownErrorFlag = false;
  resetPassword_unknownErrorFlag = false;

  // Flags to display various elements on the UI
  showConfirmPasswordReset = false;

  // Forms for all the various sign in/up pages
  initiatePasswordResetForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
  });
  resetPasswordForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(99),
      PasswordValidator.hasNumber,
      PasswordValidator.hasLower,
      PasswordValidator.hasUpper,
      PasswordValidator.hasSpecial
    ]),
    confirmPassword: new FormControl('', [Validators.required]),
  },
  {
    validators: PasswordValidator.passwordMatchValidator
  });

  // These fields are used to transfer the username/password fields between forms/methods
  username!: string;
  password!: string;

  // constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService, private learnerCareerService: LearnerCareerService) {}
  constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService) {}

  // Method to initiate a forgotten password request
  async forgotPassword() {
    try {
      this.resetUiErrorFlags();
      this.username = this.initiatePasswordResetForm.controls.username.value;

      // Call forgot password to send the email and show the confirm password reset form if successful
      await this.authService.forgotPassword(this.username);
      this.showConfirmPasswordReset = true;
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          default:
            this.initiateReset_unknownErrorFlag = true;
        }
      }
    }
  }

  // Method to submit the forgotten password fields
  async forgotPasswordSubmit() {
    try {
      this.resetUiErrorFlags();
      this.password = this.resetPasswordForm.controls.password.value;

      // Call forgot password submit
      await this.authService.forgotPasswordSubmit(
        this.username,
        this.resetPasswordForm.controls.code.value,
        this.password);

      // Call sign in to actually sign the user in - await is needed to ensure the call completes successfully prior to setting the userSignedInFlag
      await this.authService.signIn(this.username, this.password);
      // user is signed in, route to the rest of the app
      this.getProfileAndRoute();
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'Incorrect username or password.':
            // Go back to the sign in page and show error message
            break;
          case 'User is not confirmed.': // user hasn't confirmed their email yet
            // Go back to the sign in page and show error message
            break;
          default:
            this.resetPassword_unknownErrorFlag = true;
        }
      }
    }
  }

  focusPassword(value: boolean, row: number) {
    row === 1 ? this.passwordRow1Focused = value : this.passwordRow2Focused = value;
  }

  getProfileAndRoute() {
    this.profileService.getProfile().subscribe(profile => {
      this.store.dispatch(setProfile({payload: profile}));

      if (profile.onboardingComplete) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/on-boarding']);
      }
      
    });
  }

  // Method to show the passwords being typed in the password fields
  toggleShowPassword(id: string) {
    // Casting to an input element because this method will only be called from input elements
    let inputField = <HTMLInputElement>document.getElementById(id);
    if (inputField) {
      if (inputField.type === 'password') {
        inputField.type = 'text';
      } else {
        inputField.type = 'password';
      }

    }
  }

  // Method to reset UI error flags to false (hide them)
  resetUiErrorFlags() {
    this.initiateReset_unknownErrorFlag = false;
    this.resetPassword_unknownErrorFlag = false;
  }
}
