//import COACHABILITY_QUESTION_TYPES from '../../../types/coachability-question-types';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from '../../../types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CoachabilityChangingCircumstancesData: Map<string, SneQuestion> =
  new Map([
    [
      'start',
      {
        questionType: QUESTION_TYPES.INTERSTITIAL,
        title: 'Changing Circumstances',
        info: 'This task will help us understand how you navigate unexpected changes at work—we’ll use the insights we gather to add to your Skills Profile.',
        duration: '<span class="boldText">Duration:</span> This should take about 15 minutes, and you’ll have the option to pause when you finish.',
        questionIndex: 2,
        moduleSize: 6,
        durationBoxColor: 'lbb',
        image: '/assets/section-3/sne-interstitial/changing-circumstances.svg',
        buttonText: 'Next',
        modalBeforeNext: true,
      },
    ],
    [
      'question-1',
      {
        questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
        email: {
          to: 'You',
          from: 'Eileen',
          emailSubject: 'Important! Client Ad Change',
          image: '/assets/sne/coachability-eileen-profile-pic.png',
          body: 'Sorry to do this to you, but I just got off the phone with the client. They’re suddenly not OK with using animals in their ads-worried about blowback from animal rights activists. We need to redo everything without live animals, but they still want the campaign finished well before the winter holidays.',
        },
        inputType: SNE_INPUT_TYPES.CHECKBOX,
        header:
          'Select the <span class="convertToBlue">three</span> best potential responses to Eileen’s request.',
        numSelectOptions: 3,
        singleMulChoiceOptions: [
          {
            userOption:
              'Eileen, that really isn’t feasible. We’ve all put a lot of time and effort into the campaign, and we’re proud of what we’ve accomplished. I don’t think that a few animal rights activists should drive the client’s decisions.',
            userLetter: 'A',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'Would they be open to the possibility of animated animals instead? If they let us shift to animation, we wouldn’t have to start from scratch, and we might even save them some money.',
            userLetter: 'B',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'We can think about it, but first let my team finish up what they’re in the middle of right now, which should take another week or so. We can show it to the client and see if they still have concerns. If they do, we can discuss next steps.',
            userLetter: 'C',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'They’re just discovering now that they don’t want to use animals? They’ve had months to think about this.',
            userLetter: 'D',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'We’ll need to work quickly. Let me discuss this with my team so I can get you some realistic options by the end of the day.',
            userLetter: 'E',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'I don’t think that this is fair to my team. You don’t have any idea how hard they’ve been working—nights, weekends, whatever. I can’t tell them that they’re going to have to start all over again.',
            userLetter: 'F',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'I never liked the animal thing either, and I told them that when we first met about the concept. If they had listened to me then, we wouldn’t all be under this much pressure. Maybe I should have tried harder to argue the point.',
            userLetter: 'G',
            autoUser: '',
            response: '',
          },
          {
            userOption:
              'That’s a tall order, but I have a great team. We can certainly get something out for them by that deadline; we’re just going to have to think creatively to see how much of the work we’ve already done can be salvaged.',
            userLetter: 'H',
            autoUser: '',
            response: '',
          },
        ],
        submitId: 'item_1',
      },
    ],
    [
      'question-2',
      {
        questionType: QUESTION_TYPES.VIDEO_LIST,
        header: 'How do you want to reply?',
        complete: true,
        numSelectOptions: 3,
        submitId: 'item_2',
      },
    ],
    [
      'end',
      {
        questionType: QUESTION_TYPES.INTERSTITIAL,
        title: 'Congrats! You completed another task.',
        info: 'Your responses will help us explore how you face unexpected hurdles—where your strengths lie and where you have room to grow. Feel free to pause if you need a break or start the next task if you’re ready.',
        questionIndex: 2,
        moduleSize: 6,
        image: '/assets/section-3/sne-interstitial/coach-end-interstitial.png', // placeholder
        buttonText: 'Next',
        goto: COACHABILITY_TYPES.COACHES,
      },
    ],
  ]);

export const SneChangingCircumstancesVideos: SneVideos[] = [
  {
    url: 'https://vimeo.com/602504496',
    id: '602504496',
    title: 'Jonathan',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'A',
  },
  {
    url: 'https://vimeo.com/602504516',
    id: '602504516',
    title: 'Jules',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'B',
  },
  {
    url: 'https://vimeo.com/602470756',
    id: '602470756',
    title: 'Erica',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'C',
  },
  {
    url: 'https://vimeo.com/602504519',
    id: '602504519',
    title: 'Aya',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'D',
  },
  {
    url: 'https://vimeo.com/602504417',
    id: '602504417',
    title: 'Mateo',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'E',
  },
  {
    url: 'https://vimeo.com/600628317',
    id: '600628317',
    title: 'Lauren',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'F',
  },
  {
    url: 'https://vimeo.com/602470740',
    id: '602470740',
    title: 'Dax',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'G',
  },
  {
    url: 'https://vimeo.com/600837408',
    id: '600837408',
    title: 'Hunter',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    answerChoice: 'H',
  },
];

export const CoachabilityChangingCircumstancesInstructionModal: SneModal = {
  title: 'Project Management',
  pageList: [
    {
      info: 'You are a project manager at WunderKind Interactive, one of the top advertising firms in the United States. For the past six months, you have led a team of writers and artists in the creation of a major new advertising campaign for one of Wunderkind\'s top clients.\n\nThe campaign is close to being ready to present to your client when you receive the following email from Eileen, your supervisor...',
    },
    {
      info: '',
      showCustomContent: true,
    },
    {
      info:'There is no quick and easy way to accommodate this request, since live animals are central to your team’s concept.',
    }
  ],
  customContent: [
    {
      to: 'You',
      from: 'Eileen',
      emailSubject: 'Important! Client Ad Change',
      image: '/assets/sne/coachability-eileen-profile-pic.png',
      body: 'Sorry to do this to you, but I just got off the phone with the client. They’re suddenly not OK with using animals in their ads-worried about blowback from animal rights activists. We need to redo everything without live animals, but they still want the campaign finished well before the winter holidays.',
    },
  ],
};
