import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { ProfileService } from 'src/app/services/profile.service';
import { SkillsService } from 'src/app/services/skills.service';
import { SKILL_TYPES_ENUM } from 'src/app/types/question';
import { SkillsResponse } from 'src/app/types/skills';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { selectSkillsInFlight } from '../../../store';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'ets-skill-selection-overview',
  templateUrl: './skill-selection-overview.component.html',
  styleUrls: ['./skill-selection-overview.component.scss']
})
export class SkillSelectionOverviewComponent extends OnBoardingV2Base implements OnInit, OnDestroy {

  // Response object for getSkills() call
  skillsResponse!: SkillsResponse;
  // Arrays of selected skills for the different skill types
  transferableSkills: string[] = [];
  jobSpecificSkills: string[] = [];
  topSkills: string[] = [];
  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];
  callCompleted = false;

  constructor(protected store: Store,
              protected router: Router,
              protected route: ActivatedRoute,
              protected profileService: ProfileService,
              protected skillsService: SkillsService,
              protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  ngOnInit() {
    this.initialize();
    this.store.select(selectSkillsInFlight).pipe(filter(flight => !flight), take(1)).subscribe(inFlight => {
      if (!inFlight) {
        this.skillsService.getSkills().subscribe({
          next: response => {
            this.skillsResponse = response;
            // Separate out skill types by calling parse method in Skills Service
            let selectedSkillsMap = this.skillsService.parseSelectedSkillsFromSkillsResponse(this.skillsResponse);
            selectedSkillsMap.get(SKILL_TYPES_ENUM.TRANSFERABLE)?.forEach(skill => {
              this.transferableSkills.push(skill);
            });
            selectedSkillsMap.get(SKILL_TYPES_ENUM.JOB_SPECIFIC)?.forEach(skill => {
              this.jobSpecificSkills.push(skill);
            });
            selectedSkillsMap.get(SKILL_TYPES_ENUM.TOP)?.forEach(skill => {
              this.topSkills.push(skill);
            });
            this.disableTopSkills();
          },
          complete: () => {
            this.callCompleted = true;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.teardown();
  }

  // Method to determine if the Top Skills section is enabled
  disableTopSkills(): void {
    // Determine if both transferable and job specific skills have been selected to enable top skills section
    if (this.currentQuestion && this.currentQuestion.skillTypes) {
      this.currentQuestion.skillTypes.forEach(skillType => {
        if (skillType.type === SKILL_TYPES_ENUM.TOP) {
          skillType.disabled = !(this.transferableSkills.length > 0 && this.jobSpecificSkills.length > 0);
        }
      });
    }
  }

  // Method to get the specified type of self inputted array of skills,
  // whether it's transferrable, job specific, or top skills
  getSelectedSkillsOfType(type: SKILL_TYPES_ENUM): string[] {
    switch(type) {
      case SKILL_TYPES_ENUM.TRANSFERABLE:
        return this.transferableSkills;
      case SKILL_TYPES_ENUM.JOB_SPECIFIC:
        return this.jobSpecificSkills;
      case SKILL_TYPES_ENUM.TOP:
        return this.topSkills;
      default:
        return [];
    };
  }

  selectSkillType(type: SKILL_TYPES_ENUM) {
    switch(type) {
      case SKILL_TYPES_ENUM.TRANSFERABLE:
      case SKILL_TYPES_ENUM.JOB_SPECIFIC:
      case SKILL_TYPES_ENUM.TOP:
        // Set the user response to type to match the ChoiceNav answers
        this.userResponse = type;
        this.submitResponse();
        break;
      default:
        console.log('Unexpected skills type on Skill Selection Overview:', type);
    };
  }

  disableSaveAndContinue(): boolean {
    // return true until all skills are selected
    return !(this.transferableSkills.length > 0 && this.jobSpecificSkills.length > 0 && this.topSkills.length > 0);
  }

  saveAndContinue() {
    console.log('Save & Continue');
    // Set the user response to "Save & Continue" to match the ChoiceNav answer
    this.userResponse = 'Save & Continue';
    this.submitResponse();
  }
}
