import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatMessage } from 'src/app/types/sne';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import QUESTION_TYPES from 'src/app/types/question-types';
import { SneCoachabilityBase } from '../sne-coachability.base';
import COACHABILITY_TYPES from 'src/app/types/coachability-types';
import { APP_COLORS } from '../../../../global-styles';
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { SneAnswerService } from '../../../services/sne-answer.service';

@Component({
  selector: 'ets-coachability-multi-choice-list-convo-log',
  templateUrl: './coachability-multi-choice-list-convo-log.component.html',
  styleUrls: ['./coachability-multi-choice-list-convo-log.component.scss']
})
export class CoachabilityMultiChoiceListConvoLogComponent extends SneCoachabilityBase implements OnInit {

  showModal = false;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  showHintModal = false;
  chatList: ChatMessage[] = [];
  headerQuestion: string = '';
  responseList: string[] = [];

  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  toggleModal() {
    this.showModal=!this.showModal;
  }
  toggleHintModal(){
    if(this.questionData?.hintModalFlag === true){
      this.showHintModal=!this.showHintModal;
    }
    //this.showHintModal = true;
  }

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  additionalNextQuestionSteps = () => {
    this.responseList.push(this.singleResponse);
    this.generateChatList();
  }

  singleResponseHandler(answer: string) {
    this.singleResponse = answer;
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
    }

  }

  saveProgress() {
  }

  generateChatList() {
    if (this.coachabilityData) {
      const multiChoiceQuestions = Array.from(this.coachabilityData.values()).filter(element => element.questionType === QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO);
      this.chatList = [];
      if(this.coachabilityType === COACHABILITY_TYPES.COACHES){
        let chat: ChatMessage = {
          user: 'Bill',
          message: 'Hey! What do you think of my idea? I think if we both back it, they’ll really need to think about it seriously.',
        };
        this.chatList.push(chat);
      }
      if(this.coachabilityType === COACHABILITY_TYPES.UNCERTAINTY){
        let chat: ChatMessage = {
          user: '',
          message: 'After texting with your supervisor, you need to decide how to proceed with your project. From the choices below, use the drop-down menus to indicate the FIRST THING and the SECOND THING that you would like to do to start the project.',
        };
        this.chatList.push(chat);
      }
      if(this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES){
        let chat: ChatMessage = {
          user: 'Sam',
          message: 'Yikes, what now? I can’t believe we messed up those data! Do we have to tell Gail about it?',
        };
        this.chatList.push(chat);
      }
      this.responseList.forEach((response, index) => {
        const question = multiChoiceQuestions[index].singleMulChoiceOptions?.find(question => question.userLetter === response);
        if (question) {
          let chat: ChatMessage = {
            user: 'You',
            message: question.userOption,
          };
          this.chatList.push(chat);
        }
        if (question && question.response) {
          let chat: ChatMessage = {
            user: question.autoUser,
            message: question.response,
          };
          this.chatList.push(chat);
        }
      });
    }
  }

  additionalParamProcess() {
    console.log('Additional things happening here.');
  }

  selectAnswer(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }

  ngOnInit(): void {
    this.processParams();
    if(this.coachabilityType === COACHABILITY_TYPES.COACHES){
      let chat: ChatMessage = {
        user: 'Bill',
        message: 'Hey! What do you think of my idea? I think if we both back it, they’ll really need to think about it seriously.',
      };
      this.chatList.push(chat);
    }
    if(this.coachabilityType === COACHABILITY_TYPES.UNCERTAINTY){
      let chat: ChatMessage = {
        user: 'Supervisor',
        message: 'We really need to address those issues that woman brought up at last month’s All Staff meeting-she made some great points. Can you make sure that it gets done?',
      };
      this.chatList.push(chat);
    }
    if(this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES){
      let chat: ChatMessage = {
        user: 'Sam',
        message: 'Yikes, what now? I can’t believe we messed up those data! Do we have to tell Gail about it?',
      };
      this.chatList.push(chat);
    }
    this.toggleHintModal();
  }
}

