import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Assessment } from '../../../../types/assessments';
import { Router } from '@angular/router';
import { SneAssessmentRecord } from '../../../../types/sne';
import { findNextAssessmentPath } from '../../../../sne-v2/sne-v2.helpers';

@Component({
  selector: 'ets-career-checklist',
  templateUrl: './career-checklist.component.html',
  styleUrls: ['./career-checklist.component.scss']
})
export class CareerChecklistComponent implements OnChanges {

  constructor(private router: Router) { }

  @Input() skillsAssessments: Assessment[] = [];
  @Input() assessmentRecords: SneAssessmentRecord[] = [];

  notStartedAssessmentTitle: string | undefined;
  notStartedAssessment: Assessment | undefined;

  identifyNextAssessment() {
    this.notStartedAssessment = this.skillsAssessments.find(assessment => {
      return ((assessment.status === 'Not started' || assessment.status === 'In progress') && assessment.show !== false)
        && (assessment.assessmentID !== 'CIP' && assessment.assessmentID !== 'PSQ');
    });
    if (this.notStartedAssessment) {
      switch (this.notStartedAssessment.assessmentID) {
        case 'CPS':
          this.notStartedAssessmentTitle = 'Collaborative Problem Solving';
          break;
        case 'CCH':
          this.notStartedAssessmentTitle = 'Coachability';
          break;
        case 'CRT':
          this.notStartedAssessmentTitle = 'Critical Thinking';
          break;
        case 'ORC':
          this.notStartedAssessmentTitle = 'Oral Communication';
          break;
        case 'WRC':
          this.notStartedAssessmentTitle = 'Written Communication';
          break;
        default:
          console.log('Can\'t find assessment');
      }
    }
  }

  navigateToAssessment(): void {
    if (this.notStartedAssessment) {
      const path = findNextAssessmentPath(this.assessmentRecords, this.notStartedAssessment.assessmentID as string);
      if (path) {
        this.router.navigate([path]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['skillsAssessments']) {
      this.identifyNextAssessment();
    }
  }
}
