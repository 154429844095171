import { Component, Input, EventEmitter, Output } from '@angular/core';
import { APP_COLORS } from 'src/global-styles';
import { Subscription } from 'rxjs';
import { SneVideos } from 'src/app/types/sne';
import { HEADER_STATES } from '../../../shared/header/header.helper';

@Component({
  selector: 'ets-multi-video-choice-video',
  templateUrl: './multi-video-choice-video.component.html',
  styleUrls: ['./multi-video-choice-video.component.scss']
})
export class MultiVideoChoiceVideoComponent {

  constructor() { }
  @Input() videoSetId: string = '';
  @Input() question?: any;
  @Input() videos?: SneVideos[];
  @Input() splitScreen?: boolean;
  @Input() mobileCheck=false;
  @Input() noModal?: boolean = false;
  @Output() userResponseEvent = new EventEmitter<any>();

  subs: Subscription[] = [];
  showModal = false;
  showHintModal = true;

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;

  onClick(emittedVideoId: string) {
    this.userResponseEvent.emit(emittedVideoId);
  }

}
