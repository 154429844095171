import {ActivatedRoute, Router} from '@angular/router';
import {OCInformative1Data, OCInformative1InstructionModal} from 'src/app/data/sne/oral-communication/01_Informative-1';
import {OCInformative2Data, OCInformative2InstructionModal} from 'src/app/data/sne/oral-communication/02_Informative-2';
import {OCDialogic1Data, OCDialogic1InstructionModal} from 'src/app/data/sne/oral-communication/03_Dialogic-1';
import {OCDialogic2Data, OCDialogic2InstructionModal} from 'src/app/data/sne/oral-communication/04_Dialogic-2';
import {OCPersuasive1Data, OCPersuasive1InstructionModal} from 'src/app/data/sne/oral-communication/05_Persuasive-1';
import {OCPersuasive2Data, OCPersuasive2InstructionModal} from 'src/app/data/sne/oral-communication/06_Persuasive-2';
import OC_TYPES from '../../types/oral-communication-types';
import {SNE_INPUT_TYPES, SNE_TASK_ENUM, SneAnswerRequest, SneModal, SneQuestion} from '../../types/sne';
import * as _ from 'lodash';
import {SneAnswerService} from 'src/app/services/sne-answer.service';
import {generateNextQuestionPath} from './sne-oral-communication.helpers';
import QUESTION_TYPES from 'src/app/types/question-types';

export class SneOralCommunicationBase {
  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService: SneAnswerService) {
  }

  questionNum: string | undefined;
  questionData: SneQuestion | undefined;
  questionIndex: number | undefined;
  moduleIndex: number | undefined;
  moduleSize: number = 6;
  questionKeyList: string[] = [];
  ocType: OC_TYPES | undefined;
  ocData: Map<string, SneQuestion> | undefined;
  showInstructionModal = false;
  instructionModal: SneModal | undefined;
  singleResponse: string = '';
  multiResponse: string[] = [];
  madlibsResponse: { [key: number]: string | number } = {};
  startTimestamp!: string;
  endTimestamp!: string;
  showHintModal = false;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  additionalNextQuestionSteps() {
  }

  generateNextSectionPath() {
    let path: string | undefined;
    if (this.questionData && this.questionData.goto) {
      switch (this.questionData.goto) {
        case OC_TYPES.INFORMATIVE_1:
          path = generateNextQuestionPath(
            Array.from(OCInformative1Data.values())[0],
            Array.from(OCInformative1Data.keys())[0],
            OC_TYPES.INFORMATIVE_1,
            this.additionalNextQuestionSteps
          );
          break;
        case OC_TYPES.INFORMATIVE_2:
          path = generateNextQuestionPath(
            Array.from(OCInformative2Data.values())[0],
            Array.from(OCInformative2Data.keys())[0],
            OC_TYPES.INFORMATIVE_2,
            this.additionalNextQuestionSteps
          );
          break;
        case OC_TYPES.DIALOGIC_1:
          path = generateNextQuestionPath(
            Array.from(OCDialogic1Data.values())[0],
            Array.from(OCDialogic1Data.keys())[0],
            OC_TYPES.DIALOGIC_1,
            this.additionalNextQuestionSteps
          );
          break;
        case OC_TYPES.DIALOGIC_2:
          path = generateNextQuestionPath(
            Array.from(OCDialogic2Data.values())[0],
            Array.from(OCDialogic2Data.keys())[0],
            OC_TYPES.DIALOGIC_2,
            this.additionalNextQuestionSteps
          );
          break;
        case OC_TYPES.PERSUASIVE_1:
          path = generateNextQuestionPath(
            Array.from(OCPersuasive1Data.values())[0],
            Array.from(OCPersuasive1Data.keys())[0],
            OC_TYPES.PERSUASIVE_1,
            this.additionalNextQuestionSteps
          );
          break;
        case OC_TYPES.PERSUASIVE_2:
          path = generateNextQuestionPath(
            Array.from(OCPersuasive2Data.values())[0],
            Array.from(OCPersuasive2Data.keys())[0],
            OC_TYPES.PERSUASIVE_2,
            this.additionalNextQuestionSteps
          );
          break;
        default:
          path = this.questionData.goto;
          console.error('Cannot find question type');
      }
    }
    return path;
  }

  navToNextPage() {
    //make call to backend to save response
    let path: string | undefined;
    let newQuestion: SneQuestion | undefined;
    if (this.ocData) {
      const keysArray = Array.from(this.ocData.keys());
      let keyIndex = keysArray.findIndex(key => key === this.questionNum);
      if (keyIndex !== -1) {
        if (keyIndex < keysArray.length - 1) {
          keyIndex++; // go to next kwy in the question map
          const nextKey = keysArray[keyIndex];
          newQuestion = this.ocData.get(nextKey);
          if (newQuestion && this.ocType) {
            path = generateNextQuestionPath(newQuestion, nextKey, this.ocType, this.additionalNextQuestionSteps);
          }

        } else {
          path = this.generateNextSectionPath();
        }

      }
    }
    if (path) {
      this.router.navigate([path]);
    }
  }

  getNextQuestion() {
    if (this.questionData?.modalBeforeNext) {
      this.openInstructionModal();
    } else {
      this.submit();
      this.navToNextPage();
    }
  }

  submit() {
    if (this.sneAnswerService) {
      if (this.questionIndex !== undefined && this.questionData?.submitId) {
        this.endTimestamp = Date.now().toString();
        const completed = !!this.questionData?.complete;
        const data: { [key: string]: any } = {};
        if (this.singleResponse) {
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: this.singleResponse,
            end: this.endTimestamp
          };
        } else if (this.multiResponse.length > 0) {
          let aggResponse: string = '';
          this.multiResponse.forEach(response => {
            aggResponse = aggResponse + response;
          });
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: aggResponse,
            end: this.endTimestamp
          };
        } else if (!_.isEmpty(this.madlibsResponse)) {
          let aggResponse: string = '';
          for (const [key, value] of Object.entries(this.madlibsResponse)) {
            aggResponse = aggResponse + value;
          }
          ;
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: aggResponse,
            end: this.endTimestamp
          };
        }

        if (!_.isEmpty(data)) {
          let task: SNE_TASK_ENUM | undefined;
          switch (this.ocType) {
            case OC_TYPES.INFORMATIVE_1:
              task = SNE_TASK_ENUM.INFORMATIVE_1;
              break;
            case OC_TYPES.INFORMATIVE_2:
              task = SNE_TASK_ENUM.INFORMATIVE_2;
              break;
            case OC_TYPES.DIALOGIC_1:
              task = SNE_TASK_ENUM.DIALOGIC_1;
              break;
            case OC_TYPES.DIALOGIC_2:
              task = SNE_TASK_ENUM.DIALOGIC_2;
              break;
            case OC_TYPES.PERSUASIVE_1:
              task = SNE_TASK_ENUM.PERSUASIVE_1;
              break;
            case OC_TYPES.PERSUASIVE_2:
              task = SNE_TASK_ENUM.PERSUASIVE_2;
              if (completed) {
                let requestBody: SneAnswerRequest = {
                  task: SNE_TASK_ENUM.ORC,
                  data: null,
                  completed: completed
                };
                this.sneAnswerService.postAction(requestBody).subscribe();
                console.log('Oral Communication completed.');
              }
              break;
            default:
              console.error(`Oral Communication Type could not be identified: ${this.ocType}`);
          }
          if (task) {
            let requestBody: SneAnswerRequest = {
              task: task,
              data: data,
              completed: completed
            };
            this.sneAnswerService.postAction(requestBody).subscribe(response => {
              console.log(`Response to Oral Communication post request:  + ${response}`);
              this.singleResponse = '';
              this.multiResponse = [];
              this.madlibsResponse = {};
            });
          }
        }
      }
    }
  }

  closeInstructionModal() {
    this.showInstructionModal = false;
  }

  openInstructionModal() {
    this.showInstructionModal = true;
  }

  hintToggle() {
    this.showHintModal = !this.showHintModal;
  }

  closeModalAndNav() {
    this.closeInstructionModal();
    this.navToNextPage();
  }

  additionalParamProcess() {
  }

  setData(wcData: Map<string, SneQuestion>) {
    if (this.questionNum) {
      console.log(this.questionNum);
      this.ocData = wcData;
      this.questionData = this.ocData.get(this.questionNum);
      console.log(this.questionData);
      this.questionKeyList = Array.from(this.ocData.keys());
      console.log(this.questionKeyList);
      this.questionIndex = this.questionKeyList
        .findIndex(key => key === this.questionNum);
      console.log(this.questionIndex);
    }
  }

  processParams() {
    this.route.params.subscribe(params => {
      const {questionNum, section} = params;
      this.ocType = section;
      this.questionNum = questionNum;
      this.startTimestamp = Date.now().toString();

      if (this.questionNum && this.ocType) {
        switch (this.ocType) {
          case OC_TYPES.INFORMATIVE_1:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 2;
            this.setData(OCInformative1Data);
            this.instructionModal = OCInformative1InstructionModal;
            break;
          case OC_TYPES.INFORMATIVE_2:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 3;
            this.setData(OCInformative2Data);
            this.instructionModal = OCInformative2InstructionModal;
            break;
          case OC_TYPES.DIALOGIC_1:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 4;
            this.setData(OCDialogic1Data);
            this.instructionModal = OCDialogic1InstructionModal;
            break;
          case OC_TYPES.DIALOGIC_2:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 5;
            this.setData(OCDialogic2Data);
            this.instructionModal = OCDialogic2InstructionModal;
            break;
          case OC_TYPES.PERSUASIVE_1:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 6;
            this.setData(OCPersuasive1Data);
            this.instructionModal = OCPersuasive1InstructionModal;
            break;
          case OC_TYPES.PERSUASIVE_2:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 7;
            this.setData(OCPersuasive2Data);
            this.instructionModal = OCPersuasive2InstructionModal;
            break;
          default:
            console.error(`Oral Communication Type could not be identified: ${this.ocType}`);
        }
        this.additionalParamProcess();
      }
    });
  }
}
