import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoundationalAssessmentComponent } from './foundational-assessment.component';
import { FacetsComponent } from './components/facets/facets.component';
import { FacetsInterstitialComponent } from './components/facets-interstitial/facets-interstitial.component';
import { FacetsIntroComponent } from './components/facets-intro/facets-intro.component';
import { SharedModule } from '../shared/shared.module';
import {
  FacetsPersonalityResultsComponent
} from './components/facets-personality-results/facets-personality-results.component';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    FoundationalAssessmentComponent,
    FacetsComponent,
    FacetsInterstitialComponent,
    FacetsIntroComponent,
    FacetsPersonalityResultsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ClarityModule,
    FontAwesomeModule
  ]
})
export class FoundationalAssessmentModule { }
