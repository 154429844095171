<button
  attr.aria-label="{{ariaLabel}}"
  class="btn btn-outline outline-button"
  [disabled]="disabled"
  [ngStyle]="{
    'color': color ? color : '',
    'border-color': borderColor ? borderColor : (color ? color : ''),
    'width': '100%',
    'padding': padding ? padding : ''
  }"
  [ngClass]="{
    'disable-button': disabled,
    'small-button': size === 'sm',
    'medium-button': size === 'md',
    'max-width': maxWidth,
    'transparentBg': transparentBg
  }"
  (click)="onClick()"
>
  <img *ngIf="iconImgSrc" class="button-icon" [src]="iconImgSrc" [alt]="iconImgAlt" [ngStyle]="{'width': iconWidth ? iconWidth : '16px'}">
  {{text}}
</button>
