enum OB_PAGES {
  SECTION_ONE_START = 'sectionOneStart',
  PREFERRED_NAME = 'preferredName',
  SECTION_ONE_WELCOME_INFO_BREAK = 'sectionOneWelcomeInfoBreak',
  EMAIL = 'email',
  BIRTH_DATE = 'birthDate',
  BIRTH_YEAR = 'birthYear',
  ASPIRATION = 'aspiration',
  PERSONAL_GOALS = 'personalGoals',
  INITIAL_ROLE = 'initialRole',
  INITIAL_EXPERIENCE = 'initialExperience',
  CURRENT_SALARY = 'currentSalary',
  SECTION_ONE_END_INFO_BREAK = 'sectionOneEndInfoBreak',
  SECTION_TWO_START = 'sectionTwoStart',
  EDUCATION_HISTORY = 'educationHistory',
  FIELD_OF_STUDY = 'fieldOfStudy',
  ENGLISH_FLUENCY = 'englishFluency',
  SPEAKS_OTHER_LANGUAGES = 'speaksOtherLanguages',
  FLUENT_LANGUAGES = 'fluentLanguages',
  KNOWS_CODING_LANGUAGES = 'knowsCodingLanguages',
  CODING_LANGUAGES = 'codingLanguages',
  WORK_ELIGIBLE_US = 'workEligibleUS',
  MILITARY_STATUS = 'militaryStatus',
  ZIP_CODE = 'zipCode',
  DIVERSITY_GENDER = 'diversityGender',
  DIVERSITY_RACE = 'diversityRace',
  SECTION_TWO_END_INFO_BREAK = 'sectionTwoEndInfoBreak',
  SECTION_THREE_START = 'sectionThreeStart',
  DESIRED_WORK_HOURS_PER_WEEK = 'desiredWorkHoursPerWeek',
  DESIRED_REMOTE_WORK = 'desiredRemoteWork',
  DESIRED_COMMUTE = 'desiredCommute',
  DESIRED_BENEFITS = 'desiredBenefits',
  CRITICAL_BENEFITS = 'criticalBenefits',
  DESIRED_SALARY = 'desiredSalary',
  SECTION_THREE_END_INFO_BREAK = 'sectionThreeEndInfoBreak',
  SECTION_FOUR_START = 'sectionFourStart',
  EXPERIENCE_INPUT = 'experienceInput',
  WORK_HISTORY_START = 'workHistoryStart',
  SKILL_SELECTION_OVERVIEW = 'skillSelectionOverview',
  TRANSFERABLE_SKILLS = 'transferableSkills',
  JOB_SPECIFIC_SKILLS = 'jobSpecificSkills',
  TOP_SKILLS = 'topSkills',
  RESUME_OPTION = 'resumeOption',
  RESUME_ATTACH = 'resumeAttach',
  SKILLS_LOADING = 'skillsLoading'
}

export default OB_PAGES;
