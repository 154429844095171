import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtMultiChoiceChatLogComponent } from './ct-multi-choice-chat-log/ct-multi-choice-chat-log.component';
import { CtMultiChoiceEmailComponent } from './ct-multi-choice-email/ct-multi-choice-email.component';
import { CtInterstitialComponent } from './ct-interstitial/ct-interstitial.component';
import { SharedModule } from '../../shared/shared.module';
import { SneComponentsModule } from '../components/sne-components.module';
import { CriticalThinkingComponent } from './critical-thinking.component';
import { RouterModule } from '@angular/router';
import {
  CtMultiChoiceSocialMediaComponent
} from './ct-multi-choice-social-media/ct-multi-choice-social-media.component';
import { CtSneIntroComponent } from './ct-sne-intro/ct-sne-intro.component';
import { CtMultiChoiceScheduleMapComponent } from './ct-multi-choice-schedule-map/ct-multi-choice-schedule-map.component';
import { CtProductionFlowMapComponent } from './ct-production-flow-map/ct-production-flow-map.component';

@NgModule({
  declarations: [
    CtMultiChoiceChatLogComponent,
    CtMultiChoiceEmailComponent,
    CtInterstitialComponent,
    CriticalThinkingComponent,
    CtMultiChoiceSocialMediaComponent,
    CtSneIntroComponent,
    CtMultiChoiceScheduleMapComponent,
    CtProductionFlowMapComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SneComponentsModule,
    RouterModule
  ],
  exports: [
    CtMultiChoiceChatLogComponent,
    CtMultiChoiceEmailComponent,
    CtInterstitialComponent,
    CriticalThinkingComponent,
    CtMultiChoiceSocialMediaComponent
  ]
})
export class CriticalThinkingModule { }
