enum CT_TYPES {
  DEDUCTION = 'deduction',
  PRODUCTION = 'production',
  RHETORICAL = 'rhetorical',
  SCHEDULING = 'scheduling',
  ARGUMENTATION_1 = 'argumentation-1',
  ARGUMENTATION_2 = 'argumentation-2',
}

export default CT_TYPES;
