<div class="container">
  <div class="inner">
    <div class="question-container">
      <h1 class="question">{{currentQuestion?.question}}</h1>
      <!-- <h3 class="description">Description</h3> -->
    </div>
    <div class="slider">
      <ngx-slider [(value)]="value" [options]="options">
      </ngx-slider>
    </div>

    <div class="button-wrapper"></div>
      <ets-rounded-contained-button
      style="width: 225px; z-index: 1000;"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'NEXT QUESTION'"
      (click)="triggerNextQuestion()"
      ></ets-rounded-contained-button>
  </div>
</div>
