<ets-header [isBGWhite]="true" [headerState]="headerState" [showBackButton]="false" [simpleBack]="true">
</ets-header>

<div class="interstitial-container">
  <ets-interstitial [title]="questionData?.title" [info]="questionData?.info" [image]="questionData?.image"
    [buttonText]="questionData?.buttonText" [buttonColor]="APP_COLORS.uiaction" [showButton]="false"
    [questionIndex]="questionData?.questionIndex" [moduleSize]="questionData?.moduleSize"
    [duration]="questionData?.duration" [durationBoxColor]="questionData?.durationBoxColor"
    [marquis]="questionData?.marquis">
  </ets-interstitial>
</div>

<ets-sne-progress-bar [questionIndex]="moduleIndex" [moduleSize]="moduleSize" [overviewEnabled]="false"
  [isButtonDisabled]="false" (nextEmitter)="getNextQuestion()" [drawerActive]="false"
  [buttonText]="questionData?.buttonText" [showSaveAndExit]="endInterstitial">
</ets-sne-progress-bar>

<ets-modal [title]="instructionModal?.title" [showModal]="showInstructionModal" [list]="instructionModal?.pageList"
  (modalClose)="closeModalAndNav()" (currentPageOutput)="updateCurrentPage($event)">
  <div class="classifications-container"
    *ngIf="instructionModal?.customContent && !instructionModal?.customContent[0].negotiation"
    contentID="customContent">
    <div *ngFor="let classification of instructionModal?.customContent; let idx = index">
      <div class="text-wrapper">
        <span class="classification-header">{{ classification.header }}</span>
        <p class="classification-info">{{ classification.info }}</p>
      </div>
      <hr style="color: #ccc;" *ngIf="instructionModal?.customContent.length !== idx + 1" />
    </div>
  </div>

  <div class="client-ranking-modal-container"
    *ngIf="instructionModal?.customContent && instructionModal?.customContent[0].negotiation" contentID="customContent">
    <ets-client-ranking-stars *ngIf="currentModalPage === 2" [jordanStars]="jordanStars" [clientStars]="clientStars"
      [StarStatus]="StarStatus"></ets-client-ranking-stars>
    <div class="incentives-available-container" *ngIf="currentModalPage === 1" contentID="customContent">
      <div class="content-box">
        <div class="content-1">
          <img class="line-photo" src="../../../assets/icons/ticket.svg" alt="Ticket photo" id="ticket-photo">
          <span class="line-text">50 free tickets to a sold-out concert</span>
        </div>
        <div class="content-2">
          <img class="line-photo" src="../../../assets/icons/hand.svg" alt="Hand photo" id="hand-photo">
          <span class="line-text">A meet and greet for 10 people with the artists performing at the sold-out concert</span>
        </div>
        <div class="content-3">
          <img class="line-photo" src="../../../assets/icons/basketball.svg" alt="basketball photo" id="basketball-photo">
          <span class="line-text">2 season tickets to home games for the city's basketball team</span>
        </div>
        <div class="content-4">
          <img class="line-photo" src="../../../assets/icons/outdoor_grill.svg" alt="Grill photo" id="grill-photo">
          <span class="line-text">A picnic catered by the food service providers that sell concessions at your events</span>
        </div>
      </div>
    </div>
  </div>






</ets-modal>