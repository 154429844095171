<!-- <div class="background"> -->
  <div class="page-container">
    <div class="content-container">
      <h2 class="title">Share your resume</h2>
      <h3 class="sub-title">Upload your resume to help us match you with job opportunities. You can always add or change your resume from your dashboard.</h3>
    </div>

    <h4 *ngIf="fileTypeError" class="error-text">Upload Failed. Please upload only PDFs</h4>
    <h4 *ngIf="sizeError" class="error-text">Upload Failed. File size too big, 5MB maximum</h4>

    <div class="upload-textbox" *ngIf="!file || sizeError || fileTypeError">
      <div class="content-wrapper">
        <img src="/assets/on-boarding/upload.svg" alt="" aria-hidden="true" class="upload-icon">
        <h4 class="directions">Drag and drop your file here.</h4>
        <h5 class="directions-subtitle">Make sure your file is a PDF</h5>
        <input type="file" id="get_file" value="BROWSE" title="BROWSE" (change)="onFileChange($event)">
        <input type="file" id="my_file">
      </div>
    </div>

    <div *ngIf="!sizeError && !fileTypeError && file" class ="uploaded-content-wrapper">
      <img src="/assets/on-boarding/PDF.svg" alt="" aria-hidden="true" class="pdf-icon">
      <div class="upload-info">
        <p class="file-title">{{fileTitle}}</p>
        <div class="file-size-trash-container">
          <p class="file-size">{{fileSize}} MB</p>
          <img alt="delete" src="assets/on-boarding/trashcan.png" class="trash" (click)="delete()"/>
        </div>
        <div class="flex">
          <img alt="success" src="assets/on-boarding/greenCheckMark.png"/>
          <h4 class="success-text">Successfully Uploaded!</h4>
        </div>
      </div>
      
    </div>
    <span class="size-limit-text">5MB max file size limit</span>

    <div class="view-careers-button">
      <ets-rounded-contained-button
        [buttonText]="'FINISH'"
        [backgroundColor]="'#0E3AD6'"
        [textColor]="'#FFFFFF'"
        [disabled]="disableButton"
        (clickEvent)="viewMyCareers()"
        class="bottom-button"
      >
      </ets-rounded-contained-button>
    </div>
  </div>
<!-- </div> -->