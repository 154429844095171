import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import QUESTION_TYPES, { QUESTION_SECTIONS } from '../../../types/question-types';
import { ActivatedRoute, Router } from '@angular/router';
import Profile from '../../../types/profile';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ProfileService } from '../../../services/profile.service';
import { APP_COLORS } from '../../../../global-styles';
import { EventService } from 'src/app/services/event.service';
import OB_PAGES from 'src/app/types/on-boarding-pages.types';

@Component({
  selector: 'ets-multiple-choice-v2',
  templateUrl: './multiple-choice-v2.component.html',
  styleUrls: ['./multiple-choice-v2.component.scss']
})
export class MultipleChoiceV2Component extends OnBoardingV2Base implements OnInit, OnDestroy {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  APP_COLORS = APP_COLORS;
  sections = QUESTION_SECTIONS;
  questionTypes = QUESTION_TYPES;
  oddList: string[] | undefined;
  evenList: string[] | undefined;
  inOrderList: string[] | undefined;
  @Input() buttonText: string | undefined  = '';
  @Input() buttonColor: string | undefined  = '';
  selectedList = new Map();
  selectedOption: string | undefined;
  multiSelectedOptions: string[] = [];
  @Output() clickEvent = new EventEmitter<any>();

  onClick = () => {
    this.clickEvent.emit();
  };

  toggleOption = (option: string) => {
    const multiSelect = this.currentQuestion?.type === QUESTION_TYPES.MULTI_MULTIPLE_CHOICE;
    if (multiSelect) {
      if (this.currentQuestion?.page === OB_PAGES.DIVERSITY_RACE) {
        const preferNotToSayKey = 'Prefer not to say';
        if (option === preferNotToSayKey) {
          this.selectedList.forEach((value, key) => {
            this.selectedList.set(key, false);
          });
        }
        else {
          this.selectedList.set(preferNotToSayKey, false);
        }
      }
      this.selectedList.set(option, !this.selectedList.get(option));
      this.multiSelectedOptions = [];
      this.selectedList.forEach((value, key) => {
        if (value) {
          this.multiSelectedOptions.push(key);
        }
      });
    } 
    else {
      this.selectedList.forEach((value, key) => {
        this.selectedList.set(key, false);
      });
      this.selectedList.set(option, true);
      this.selectedOption = option;
      // Trigger next question because it's a single selection question
      this.triggerNextQuestion();
    }
  }

  isOptionSelected = (option: string) => {
    return this.selectedList.get(option);
  }

  processQuestion() {
    if (this.userProfile) {
      this.processQuestionChoices();
    }
  };

  select = (option: string) => {
    this.userResponse = option ;
  };

  processQuestionChoices = () => {
    this.selectedList = new Map();
    this.evenList = [];
    this.oddList = [];
    this.inOrderList = [];
    if (this.currentQuestion && this.currentQuestion.choices && this.userProfile) {
      this.currentQuestion.choices.forEach((value, index) => {
        if (this.currentQuestion?.profileAttribute && this.userProfile) {
          const key = this.currentQuestion.profileAttribute as keyof Profile;
          const data = this.userProfile[key] ? this.userProfile[key] : null;
          if (data && Array.isArray(data) && data.find(_value => _value === value)) {
            this.selectedList.set(value, true);
          } else if (data && data === value) {
            this.selectedList.set(value, true);
          } else {
            this.selectedList.set(value, false);
          }

        }
        this.inOrderList?.push(value);
        if ((index % 2) === 0) {
          this.evenList?.push(value);
        } else {
          this.oddList?.push(value);
        }

      });
    }
  };

  triggerNextQuestion() {
    const multiSelect = this.currentQuestion?.type === QUESTION_TYPES.MULTI_MULTIPLE_CHOICE;
    if (multiSelect) {
      if (this.multiSelectedOptions.length > 0) {
        this.userResponse = this.multiSelectedOptions;
      }
    } else {
      if (this.selectedOption) {
        this.userResponse = this.selectedOption;
      }
    }
    this.submitResponse();
  }

  disableSubmit() {
    return !Array.from(this.selectedList.values()).find(value => value);
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }
}


