import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { Store } from '@ngrx/store';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { APP_COLORS } from '../../../../global-styles';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  endDateValidator,
  startDateValidator,
  startToEndDateValidator
} from '../../../validators/future-date.validator';
import { Subject, Subscription } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { WorkHistory } from '../../../types/skills';
import { EI_FORM_NAMES } from './experience-input.helpers';
import { SkillsService } from '../../../services/skills.service';
import { setWorkHistoryInFlight } from '../../../store/on-boarding.actions';
import { ClrAriaLivePoliteness, ClrAriaLiveService } from '@clr/angular';

const {ROLE_NAMES, FROM_MONTH, FROM_YEAR, TO_MONTH, TO_YEAR, CURRENTLY_EMPLOYED} = EI_FORM_NAMES;

interface RoleData {
  startDate: string;
  endDate: string;
  roleTitle: string;
  currentlyEmployed: boolean;
}

@Component({
  selector: 'ets-experience-input',
  templateUrl: './experience-input.component.html',
  styleUrls: ['./experience-input.component.scss'],
  providers: [ClrAriaLiveService],
})
export class ExperienceInputComponent extends OnBoardingV2Base implements OnInit, OnDestroy {

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService,
              private skillService: SkillsService,
              public ariaLiveService: ClrAriaLiveService) {
    super(store, router, route, profileService, eventService);
  }

  EI_FORM_NAMES = EI_FORM_NAMES;

  // icons
  faAngleDown = faAngleDown;
  APP_COLORS = APP_COLORS;
  selected: any;
  disabled = false;
  placeholder = 'I worked as a...';
  months = moment.months();
  years: any[] = [];
  form = new FormGroup({roles: new FormArray([])})
  showErrors: boolean = false;
  states = ['red', 'blue'];
  selection: any;
  input = '';

  careerList: string[] = [];

  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];

  searchSub: Subject<string> = new Subject<string>();

  get roles() {
    return this.form.controls['roles'] as FormArray;
  }

  generateYears(): void {
    const yearList = [];
    const dateStart = moment('1922');
    const dateEnd = moment();
    yearList.push(dateStart.format('YYYY'));
    while (dateEnd.year() !== dateStart.year()) {
      dateStart.add(1, 'year');
      yearList.push(dateStart.format('YYYY'));
    }
    this.years = yearList;
  }

  setupCurrentlyEmployedForm(formControl: AbstractControl | null) {
    if (formControl) {
      formControl.get(CURRENTLY_EMPLOYED)?.valueChanges.subscribe(value => {
        if (value) {
          formControl.get(TO_MONTH)?.setValue(null);
          formControl.get(TO_MONTH)?.disable();
          formControl.get(TO_MONTH)?.clearValidators();
          formControl.get(TO_MONTH)?.updateValueAndValidity();
          formControl.get(TO_YEAR)?.setValue(null);
          formControl.get(TO_YEAR)?.disable();
          formControl.get(TO_YEAR)?.clearValidators();
          formControl.get(TO_YEAR)?.updateValueAndValidity();
        } else {
          formControl.get(TO_MONTH)?.enable();
          formControl.get(TO_MONTH)?.setValidators(Validators.required);
          formControl.get(TO_MONTH)?.updateValueAndValidity();
          formControl.get(TO_YEAR)?.enable();
          formControl.get(TO_YEAR)?.setValidators(Validators.required);
          formControl.get(TO_YEAR)?.updateValueAndValidity();
        }
      });
    }
  }

  createForm(): void {
    this.roles.push(new FormGroup({
      [ROLE_NAMES]: new FormControl(null, Validators.required),
      [FROM_MONTH]: new FormControl(null, Validators.required),
      [FROM_YEAR]: new FormControl(null, Validators.required),
      [TO_MONTH]: new FormControl(null, Validators.required),
      [TO_YEAR]: new FormControl(null, Validators.required),
      [CURRENTLY_EMPLOYED]: new FormControl(false)
    },
    { validators: [startDateValidator(), endDateValidator(), startToEndDateValidator()]}
    ));

    this.setupCurrentlyEmployedForm(this.roles.controls[0]);
  }

  addRole(): void {
    this.roles.push(new FormGroup({
      [ROLE_NAMES]: new FormControl(null, Validators.required),
      [FROM_MONTH]: new FormControl(null, Validators.required),
      [FROM_YEAR]: new FormControl(null, Validators.required),
      [TO_MONTH]: new FormControl(null, Validators.required),
      [TO_YEAR]: new FormControl(null, Validators.required),
      [CURRENTLY_EMPLOYED]: new FormControl(false),
    },
    { validators: [startDateValidator(), endDateValidator(), startToEndDateValidator()]}
    ));

    const index = this.roles.controls.length - 1;
    this.setupCurrentlyEmployedForm(this.roles.controls[index]);


    setTimeout(() => document.getElementById(index.toString())?.focus());
    this.ariaLiveService.announce('Role Added', ClrAriaLivePoliteness.assertive);
  }


  deleteRow(roleIndex: number) {
    if (this.roles.controls.length > 1) {
      if (this.roles.controls[roleIndex].value.roleNames){
        let roleTitle = this.roles.controls[roleIndex].value.roleNames[0];
        this.skillService.deleteWorkHistory({roleTitle}).subscribe(response => console.log(response));
      }
      this.roles.removeAt(roleIndex);
    } else if (this.roles.controls.length === 1){
      this.addRole(); // always at least one row in UI

      if (this.roles.controls[0].value.roleNames){
        let roleTitle = this.roles.controls[0].value.roleNames[0];
        this.skillService.deleteWorkHistory({roleTitle}).subscribe(response => console.log(response));
      }

      this.roles.controls.shift();
    }
  }

  search(searchString: string) {
    // remove special characters
    searchString = searchString.replace(/[^0-9A-Za-z ']/, '');
    if (searchString.length >= 3) {
      this.skillService.careerSearch(searchString).subscribe(results => {
        this.careerList = results.value.map((career: any) => career.label);
      });
    }
  }

  submitForm() {
    this.showErrors = this.roles.invalid;
    if (this.roles.valid) {
      const workHistoryList: WorkHistory[] = [];
      this.roles.controls.forEach(control => {
        const group = control as FormGroup;
        const workHistory: WorkHistory = {};
        const isEmployed = group.get(CURRENTLY_EMPLOYED)?.value;
        workHistory.currentlyEmployed = isEmployed;
        // create date for start
        const startMonth = group.get(FROM_MONTH)?.value;
        const startYear = group.get(FROM_YEAR)?.value;
        if (startMonth !== null && startYear !== null) {
          workHistory.startDate = new Date(startYear, startMonth);
        }
        //create end date
        if (!isEmployed) {
          const endMonth = group.get(TO_MONTH)?.value;
          const endYear = group.get(TO_YEAR)?.value;
          if (endMonth !== null && endYear !== null) {
            workHistory.endDate = new Date(endYear, endMonth);
          }
        } else {
          workHistory.endDate = null;
        }

        let role = group.get(ROLE_NAMES)?.value;
        typeof role === 'string' ?
          workHistoryList.push({...workHistory, title: role}) :
          workHistoryList.push({...workHistory, title: role[0]});
      });
      this.store.dispatch(setWorkHistoryInFlight({ payload: true}));
      this.skillService.saveWorkHistory({workHistory: workHistoryList})
        .subscribe(data => {
          this.store.dispatch(setWorkHistoryInFlight({ payload: false }));
        });
      this.submitResponse();
    }

  }

  removeSpecialChars(value: string) {
    return value.replace('[^0-9a-zA-Z]+', '');
  }

  getUserSubmittedWorHistory() {
    this.skillService.getWorkHistory().subscribe(response => {
      if (response && response.workHistory) {
        response.workHistory.forEach((history: any, index: number) => {
          if (index !== 0) {
            this.addRole();
          }
          this.roles.controls[index].get(ROLE_NAMES)?.setValue(history.roleTitle);
          this.roles.controls[index].get(FROM_MONTH)?.setValue(new Date(history.startDate).getUTCMonth());
          this.roles.controls[index].get(FROM_YEAR)?.setValue(new Date(history.startDate).getUTCFullYear());
          this.roles.controls[index].get(TO_MONTH)?.setValue(new Date(history.endDate).getUTCMonth());
          this.roles.controls[index].get(TO_YEAR)?.setValue(new Date(history.endDate).getUTCFullYear());
        });
      }

    });
  }

  ngOnInit(): void {
    this.initialize();
    this.generateYears();
    this.createForm();
    this.getUserSubmittedWorHistory();
  }

  ngOnDestroy() {
    this.teardown();
    this.searchSub.complete();
    this.searchSub.unsubscribe();
  }

}
