import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ets-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {

  constructor() { }

  @Input() name: string | undefined;
  @Input() value: string | undefined;
  @Input() id: string | undefined;
  @Input() label: string | undefined;
  @Input() fontWeight: string = 'bold';
  @Input() disabled = false;
  @Output() clickEvent = new EventEmitter<any>();

  onClick = () => {
    this.clickEvent.emit();
  };
}
