import {
  SneQuestion,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';


export const CTDeductionData: Map<string, SneQuestion> = new Map([
  [ 
    'intro',{
      questionType: QUESTION_TYPES.SNE_INTRO,
      title: 'Critical Thinking',
      info: 'In this section, we will guide you through six different tasks. Each task will help you understand how you use logic to evaluate information and how that applies to the careers that interest you.\n\nReady to get started?',
      image: '/assets/sne/ct/ct-intro.png',
      buttonText: 'BEGIN!'
    }
  ],
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Making Inferences',
      info: 'This task will give you insight into how you create logical conclusions from limited information, and we will use what we learn together to enhance your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/deduction.svg',
      questionIndex: 1,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish. Find a quiet place where you can focus, and select \"Next\" to begin',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'STATEMENT',
      botProfilePic: '/../assets/sne/negotiation-you-profile-pic.png',
      chatMessages: [
        {
          user: 'Jerome',
          message: 'At our company\'s shareholder meeting, two proposals, A and B, were put up for voting. Unfortunately, neither proposal A nor proposal B received more than 45% of the votes.',
        }
      ],
      multipleMulChoiceOptions: [
        {
          question: 'Which statement is the <span class="convertToBlue">most</span> logical conclusion given the information from Jerome?',
          mulChoiceOptions: [
            {
              userOption:
                'Some shareholders voted for proposal A but not proposal B.',
              userLetter: 'A',
            },
            {
              userOption:
                'Some shareholders voted for proposal B but not proposal A.',
              userLetter: 'B',
            },
            {
              userOption:
                'Some shareholders voted both proposals.',
              userLetter: 'C',
            },
            {
              userOption:
                'Some shareholders did not vote for either proposal.',
              userLetter: 'D',
            },
          ],
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'STATEMENT',
      botProfilePic: '/../assets/sne/negotiation-you-profile-pic.png',
      chatMessages: [
        {
          user: 'Jerome',
          message: 'Tiana and Rochelle work in our IT department. They each took a two-week summer vacation at the same time. Before Tiana and Rochelle left for vacation, IT staff members worked eight-hour days. IT staff worked ten-hour days while the two women were away. After they came back, staff in IT have been able to regularly work eight-hour days again.',
        }
      ],
      multipleMulChoiceOptions: [
        {
          question: 'Which statement is the <span class=\'convertToBlue\'>most</span> logical conclusion given the information from Jerome?',
          mulChoiceOptions: [
            {
              userOption:
                'The IT department staff work two hours more per workday every time multiple staff members go on vacation.',
              userLetter: 'A',
            },
            {
              userOption:
                'While Tiana and Rochelle were away, other members of the IT department obviously received new work that caused them to work longer hours.',
              userLetter: 'B',
            },
            {
              userOption:
                'The IT department was definitely short-staffed during Tiana and Rochelle’s absence, which caused the other members of the IT department to work longer hours.',
              userLetter: 'C',
            },
            {
              userOption:
                'While Tiana and Rochelle were away, other members of the IT department worked two hours more per workday.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'STATEMENT',
      botProfilePic: '/../assets/sne/negotiation-you-profile-pic.png',
      chatMessages: [
        {
          user: 'Jerome',
          message: 'The majority of the candidates who applied for an internship with our company were linguistics majors. Interestingly, the majority of the candidates who were accepted are not linguistics majors.',
        }
      ],
      multipleMulChoiceOptions: [
        {
          question: 'Which of the given options logically follows from Jerome\'s statements?',
          mulChoiceOptions: [
            {
              userOption:
                'Acceptance rate was higher for linguistics majors.',
              userLetter: 'A',
            },
            {
              userOption:
                'Acceptance rate was lower for linguistic majors.',
              userLetter: 'B',
            },
            {
              userOption:
                'Most of the linguistics majors were rejected.',
              userLetter: 'C',
            },
            {
              userOption:
                'Most of the non-linguistics majors were accepted.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'STATEMENT',
      botProfilePic: '/../assets/sne/negotiation-you-profile-pic.png',
      chatMessages: [
        {
          user: 'Jerome',
          message: 'Our company\'s stock price fell in 2018, but it has never fallen for three years in a row.',
        }
      ],
      multipleMulChoiceOptions: [
        {
          question: 'Which statement is the <span class=\'convertToBlue\'> most</span> logical conclusion given the information from Jerome?',
          mulChoiceOptions: [
            {
              userOption:
                'The company\'s stock price fell in 2015.',
              userLetter: 'A',
            },
            {
              userOption:
                'The company\'s stock price did not fall in 2017.',
              userLetter: 'B',
            },
            {
              userOption:
                'If the company’s stock price fell in 2016, then it also fell in 2017.',
              userLetter: 'C',
            },
            {
              userOption:
                'If the company\'s stock price fell in 2019, then it did not fall in 2020.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_4',
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'STATEMENT',
      botProfilePic: '/../assets/sne/negotiation-you-profile-pic.png',
      chatMessages: [
        {
          user: 'Jerome',
          message: 'At our company, we strive to be environmentally friendly. For example, we encourage our clients to choose paperless delivery methods. When we do create paper-based materials, we always use recycled paper that is not bleached.',
        }
      ],
      multipleMulChoiceOptions: [
        {
          question: 'Which statement is the <span class=\'convertToBlue\'>most</span> logical conclusion given the information from Jerome?',
          mulChoiceOptions: [
            {
              userOption:
                'The company’s clients are able to choose from a range of environmentally-friendly options, including purchasing carbon offsets when placing an order.',
              userLetter: 'A',
            },
            {
              userOption:
                'The company’s clients are able to choose electronic delivery for their monthly statements.',
              userLetter: 'B',
            },
            {
              userOption:
                'The majority of the company’s clients support environmentally-friendly and sustainable practices, such as the use of recycled paper.',
              userLetter: 'C',
            },
            {
              userOption:
                'The majority of the company’s clients select electronic delivery for their monthly statements.',
              userLetter: 'D',
            },
          ]
        }
      ],
      submitId: 'item_5',
      complete: true,
    },
  ],

  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the first task.',
      info: 'Nice work interpreting those statements! Your decisions help illustrate how you draw conclusions, where your strengths lie, and where you have room to grow. We will cover this in more detail after you complete all six tasks. <br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png',
      questionIndex: 1,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: CT_TYPES.PRODUCTION
    },
  ],
]);

export const CTDeductionInstructionModal: SneModal = {
  title: 'The Logical Choice',
  pageList: [
    {
      info: 'You will read a series of short statements and use deductive reasoning to answer questions about each statement.',
    },
  ],
};
