<ets-header [isBGWhite]="true"
            [headerState]="headerState"
            [showBackButton]="false"
            [simpleBack] = "true"
>
</ets-header>

<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="left">
    <!-- Facts Known to You -->
    <div class="known-facts-container">
      <span class="title">Facts Known to You</span>
      <div class="card-container">
        <div class="vendor-card">
          <span class="vendor">Software Vendor A</span>
            <div class="fact-card" *ngFor="let statement of userFactA; let i = index">
                <div class="fact-card-num-wrapper">
                  <p class="fact-card-num">#{{i+1}}</p>
                </div>
                <div [ngClass]="{'shared-facts' : (statement.private === 'S'), 'given-facts':(statement.private === 'P')}">
                  <span>{{statement.content}}</span>
                </div>
            </div>
        </div>
        <div class="vendor-card">
          <span class="vendor">Software Vendor B</span>
          <div class="fact-card" *ngFor="let statement of userFactB; let i = index">
            <div class="fact-card-num-wrapper">
              <p class="fact-card-num">#{{i+1}}</p>
            </div>
            <div [ngClass]="{'shared-facts' : (statement.private === 'S'), 'given-facts':(statement.private === 'P')}">
              <span>{{statement.content}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="right">
    <!-- Bot Messages and Menu -->
    <div *ngIf="optionsMenu">
      <div class="options-container">
        <div class="teammate-box">
            <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
            <div class="title-wrapper">
              <span class="teammate-title">Tabitha</span>
            <div class="teammate-description" *ngIf="!quizInProgress">
              <span>
                Hi! I am your teammate. Remember that our goal is to interact and make the best vendor selection together.
                <br><br>What would you like to do?
              </span>
            </div>
          <div class="teammate-description" *ngIf="quizInProgress">
            <span>
              Looks like we are closer to making our selection!
              <br><br>How would you like to proceed?
            </span>
          </div>
          </div>
        </div>

        <div class="button-containers">
          <button class="options-button" (click)="redirectRequestInfo()" [disabled]="!remaining_vendor_A_facts && !remaining_vendor_B_facts">Request Information</button>
          <button class="options-button" (click)="redirectShareInfo()" [disabled] = "!remaining_facts_to_share">Share Information</button>
          <button class="options-button" (click)="redirectPickVendor()">Pick a vendor</button>
        </div>
      </div>
    </div>

    <div *ngIf="requestInfo">
      <div class="options-container">
          <div class="teammate-box">
            <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
            <div class="title-wrapper">
              <div class="teammate-title">
                <span>Tabitha</span>
              </div>
              <div class="teammate-description">
                <span style="white-space: pre-line;">
                  {{bot_statement}}
                </span>
                <span class="bot-alert" *ngIf='!remaining_vendor_A_facts'><br><br>Sorry, I do not have any more information about Vendor A.</span>
                <span class="bot-alert" *ngIf='!remaining_vendor_B_facts'><br><br>Sorry, I do not have any more information about Vendor B.</span>
              </div>
            </div>
          </div>

          <div class="button-containers">
            <button class="options-button" (click)="get_A_fact()" [disabled]="!remaining_vendor_A_facts">TELL ME ABOUT VENDOR A</button>
            <button class="options-button" (click)="get_B_fact()" [disabled]="!remaining_vendor_B_facts">TELL ME ABOUT VENDOR B</button>
            <button class="options-button" (click)="resetState()">RETURN TO MENU</button>
          </div>
      </div>
    </div>

    <div *ngIf="requestFactVendorA || requestFactVendorB">
      <div class="options-container">
        <div class="vendor-info-container">
          <div class="teammate-box">
            <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
            <div class="title-wrapper">
              <div class="teammate-title">
                <span>Tabitha</span>
              </div>
              <div class="teammate-description">
                <span>
                  {{bot_statement}}
                </span>
              </div>
            </div>
          </div>

          <div class="vendor-fact-question" *ngIf="!showRequestFactConfirmation">
            <div class="question-helpful">
              <span>Is this information helpful?</span>
            </div>
            <form [formGroup]="shareFactResponseForm">
              <div class='MC-choices'>
                <div *ngFor="let choice of sharedFactResponse">
                  <div class="option">
                    <input
                      type="radio"
                      [id]="choice.id"
                      name="shareFactRadio"
                      value={{choice.id}}
                      class='radio-btn'
                      (click)="requestFactResponseClick()"
                      formControlName="shareFactRadio"
                    >
                    <label for={{choice.id}} class='option-label'>{{choice.copy}}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="button-containers margin-top-8-bottom-48" *ngIf="!showRequestFactConfirmation">
            <button class="options-button" [disabled]="requestFactSubmitBtnDisabled" (click)="addFact()">Confirm</button>
          </div>
          <div class="button-containers margin-top-32-bottom-48" *ngIf="showRequestFactConfirmation">
            <button class="options-button" (click)="continue()">Continue</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="shareInfo">
      <div class="options-container">
        <div class="share-info-container">
          <div class="teammate-box">
            <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
            <div class="title-wrapper">
              <div class="teammate-title">
                <span>Tabitha</span>
              </div>
              <div class="teammate-description">
                <span style="white-space: pre-line;">
                  {{bot_statement}}
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="!showShareFactConfirmation" style="width: 100%;">
            <form [formGroup]="shareVendorForm">
              <div class="share-vendor-info">
                <span class="section-subtitle">Software Vendor A</span>
                <div class="MC-choices">
                  <div *ngFor="let choice of userFactA; let i = index">
                    <div class="option">
                      <input type="radio"
                             [id]="choice.id"
                             name="shareVendorRadio"
                             value={{choice.id}}
                             class="radio-btn"
                             [attr.disabled]="choice.private === 'S' ? '' : null"
                             (click)="shareFactResponseClick()"
                             formControlName="shareVendorRadio"
                      >
                      <label for={{choice.id}} class='option-label' [ngClass] = "{'disabled-label': choice.private === 'S'}">#{{i+1}}: {{choice.content}}</label>
                    </div>
                  </div>
                </div>
                <div class="share-facts-border-line"></div>
                <div class="vendor-info-container">
                  <span class="section-subtitle">Software Vendor B</span>
                  <div class='MC-choices'>
                    <div *ngFor="let choice of userFactB; let i = index">
                      <div class="option">
                        <input type="radio"
                               [id]="choice.id"
                               name="shareVendorRadio"
                               value={{choice.id}}
                               class="radio-btn"
                               [attr.disabled]="choice.private === 'S' ? '' : null"
                               (click)="shareFactResponseClick()"
                               formControlName="shareVendorRadio"
                        >
                        <label for={{choice.id}} class='option-label' [ngClass] = "{'disabled-label': choice.private === 'S'}">#{{i+1}}: {{choice.content}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="button-containers margin-top-8-bottom-48" *ngIf="!showShareFactConfirmation">
            <button class="options-button" [disabled]="shareFactSubmitBtnDisabled" (click)='shareFact()'>Confirm</button>
          </div>
          <div class="button-containers margin-top-32-bottom-48" *ngIf="showShareFactConfirmation">
            <button class="options-button" (click)="continue()">Continue</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="pickVendor">
      <div class="options-container">
        <div class="pick-a-vendor">
          <div class="teammate-box">
            <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
            <div class="title-wrapper">
              <div class="teammate-title">
                <span>Tabitha</span>
              </div>
              <div class="teammate-description">
                <span style="white-space: pre-line;">
                  {{bot_statement}}
                </span>
              </div>
            </div>
          </div>
          <div class="button-containers margin-top-32-bottom-48" *ngIf="!showPickVendorConfirmation">
            <button class="options-button" (click)="pickVendorResponseClick('A')">I WANT TO SELECT VENDOR A</button>
            <button class="options-button" (click)="pickVendorResponseClick('B')">I WANT TO SELECT VENDOR B</button>
            <button class="options-button" (click)="resetState()">RETURN TO MENU</button>

          </div>
          <div class="button-containers margin-top-32-bottom-48" *ngIf="showPickVendorConfirmation">
            <button class="options-button" (click)="confirmVendorPick()">YES, I AM CERTAIN</button>
            <button class="options-button" (click)="reconsiderVendorPick()">NO, LET'S THINK SOME MORE</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  [nextEnabled]="false"
  [isButtonDisabled]="true"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [buttonText]="questionData?.buttonText"
>
  <!-- Bot Messages and Menu -->
  <div *ngIf="optionsMenu">
    <div class="options-container">
      <div class="teammate-box">
          <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
          <div class="title-wrapper">
            <span class="teammate-title">Tabitha</span>
          <div class="teammate-description" *ngIf="!quizInProgress">
            <span>
              Hi! I am your teammate. Remember that our goal is to interact and make the best vendor selection together.
              <br><br>What would you like to do?
            </span>
          </div>
        <div class="teammate-description" *ngIf="quizInProgress">
          <span>
            Looks like we are closer to making our selection!
            <br><br>How would you like to proceed?
          </span>
        </div>
        </div>
      </div>

      <div class="button-containers">
        <button class="options-button" (click)="redirectRequestInfo()" [disabled]="!remaining_vendor_A_facts && !remaining_vendor_B_facts">Request Information</button>
        <button class="options-button" (click)="redirectShareInfo()" [disabled] = "!remaining_facts_to_share">Share Information</button>
        <button class="options-button" (click)="redirectPickVendor()">Pick a vendor</button>
      </div>
    </div>
  </div>

  <div *ngIf="requestInfo">
    <div class="options-container">
        <div class="teammate-box">
          <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
          <div class="title-wrapper">
            <div class="teammate-title">
              <span>Tabitha</span>
            </div>
            <div class="teammate-description">
              <span style="white-space: pre-line;">
                {{bot_statement}}
              </span>
              <span class="bot-alert" *ngIf='!remaining_vendor_A_facts'><br><br>Sorry, I do not have any more information about Vendor A.</span>
              <span class="bot-alert" *ngIf='!remaining_vendor_B_facts'><br><br>Sorry, I do not have any more information about Vendor B.</span>
            </div>
          </div>
        </div>

        <div class="button-containers">
          <button class="options-button" (click)="get_A_fact()" [disabled]="!remaining_vendor_A_facts">TELL ME ABOUT VENDOR A</button>
          <button class="options-button" (click)="get_B_fact()" [disabled]="!remaining_vendor_B_facts">TELL ME ABOUT VENDOR B</button>
          <button class="options-button" (click)="resetState()">RETURN TO MENU</button>
        </div>
    </div>
  </div>

  <div *ngIf="requestFactVendorA || requestFactVendorB">
    <div class="options-container">
      <div class="vendor-info-container">
        <div class="teammate-box">
          <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
          <div class="title-wrapper">
            <div class="teammate-title">
              <span>Tabitha</span>
            </div>
            <div class="teammate-description">
              <span>
                {{bot_statement}}
              </span>
            </div>
          </div>
        </div>

        <div class="vendor-fact-question" *ngIf="!showRequestFactConfirmation">
          <div class="question-helpful">
            <span>Is this information helpful?</span>
          </div>
          <form [formGroup]="shareFactResponseForm">
            <div class='MC-choices'>
              <div *ngFor="let choice of sharedFactResponse">
                <div class="option">
                  <input
                    type="radio"
                    [id]="choice.id + '-mobile'"
                    name="shareFactRadio"
                    value={{choice.id}}
                    class='radio-btn'
                    (click)="requestFactResponseClick()"
                    formControlName="shareFactRadio"
                  >
                  <label [for]="choice.id + '-mobile'" class='option-label'>{{choice.copy}}</label>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="button-containers margin-top-8-bottom-48" *ngIf="!showRequestFactConfirmation">
          <button class="options-button" [disabled]="requestFactSubmitBtnDisabled" (click)="addFact()">Confirm</button>
        </div>
        <div class="button-containers margin-top-24-bottom-48" *ngIf="showRequestFactConfirmation">
          <button class="options-button" (click)="continue()">Continue</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="shareInfo">
    <div class="options-container">
      <div class="share-info-container">
        <div class="teammate-box">
          <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
          <div class="title-wrapper">
            <div class="teammate-title">
              <span>Tabitha</span>
            </div>
            <div class="teammate-description">
              <span style="white-space: pre-line;">
                {{bot_statement}}
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="!showShareFactConfirmation" style="width: 100%;">
          <form [formGroup]="shareVendorForm">
            <div class="share-vendor-info">
              <span class="section-subtitle">Software Vendor A</span>
              <div class="MC-choices">
                <div *ngFor="let choice of userFactA; let i = index">
                  <div class="option">
                    <input type="radio"
                            [id]="choice.id + '-mobile'"
                            name="shareVendorRadio"
                            value={{choice.id}}
                            class="radio-btn"
                            [attr.disabled]="choice.private === 'S' ? '' : null"
                            (click)="shareFactResponseClick()"
                            formControlName="shareVendorRadio"
                    >
                    <label [for]="choice.id + '-mobile'" class='option-label' [ngClass]="{'disabled-label': choice.private === 'S'}">#{{i+1}}: {{choice.content}}</label>
                  </div>
                </div>
              </div>
              <div class="share-facts-border-line"></div>
              <div class="vendor-info-container">
                <span class="section-subtitle">Software Vendor B</span>
                <div class='MC-choices'>
                  <div *ngFor="let choice of userFactB; let i = index">
                    <div class="option">
                      <input type="radio"
                              [id]="choice.id + '-mobile'"
                              name="shareVendorRadio"
                              value={{choice.id}}
                              class="radio-btn"
                              [attr.disabled]="choice.private === 'S' ? '' : null"
                              (click)="shareFactResponseClick()"
                              formControlName="shareVendorRadio"
                      >
                      <label [for]="choice.id + '-mobile'" class='option-label' [ngClass] = "{'disabled-label': choice.private === 'S'}">#{{i+1}}: {{choice.content}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="button-containers margin-top-8-bottom-48" *ngIf="!showShareFactConfirmation">
          <button class="options-button" [disabled]="shareFactSubmitBtnDisabled" (click)='shareFact()'>Confirm</button>
        </div>
        <div class="button-containers margin-top-24-bottom-48" *ngIf="showShareFactConfirmation">
          <button class="options-button" (click)="continue()">Continue</button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="pickVendor">
    <div class="options-container">
      <div class="pick-a-vendor">
        <div class="teammate-box">
          <img class="step-person-icon" src='../../../../assets/sne/hidden-profile-profile-pic.png' alt="Teammate Profile Picture">
          <div class="title-wrapper">
            <div class="teammate-title">
              <span>Tabitha</span>
            </div>
            <div class="teammate-description">
              <span style="white-space: pre-line;">
                {{bot_statement}}
              </span>
            </div>
          </div>
        </div>
        <div class="button-containers margin-top-24-bottom-48" *ngIf="!showPickVendorConfirmation">
          <button class="options-button" (click)="pickVendorResponseClick('A')">I WANT TO SELECT VENDOR A</button>
          <button class="options-button" (click)="pickVendorResponseClick('B')">I WANT TO SELECT VENDOR B</button>
          <button class="options-button" (click)="resetState()">RETURN TO MENU</button>

        </div>
        <div class="button-containers margin-top-24-bottom-48" *ngIf="showPickVendorConfirmation">
          <button class="options-button" (click)="confirmVendorPick()">YES, I AM CERTAIN</button>
          <button class="options-button" (click)="reconsiderVendorPick()">NO, LET'S THINK SOME MORE</button>
        </div>
      </div>
    </div>
  </div>
</ets-sne-progress-bar>


<!-- Overview Modal -->
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div class="classifications-container" *ngIf="instructionModal?.customContent" contentID="customContent">
    <div *ngFor="let classification of instructionModal?.customContent; let idx = index">
      <div class="text-wrapper">
        <span class="classification-header">{{ classification.header }}</span>
        <p class="classification-info">{{ classification.info }}</p>
      </div>
      <hr style="color: #ccc;" *ngIf="instructionModal?.customContent.length !== idx + 1" />
    </div>
  </div>
</ets-modal>