<clr-modal [(clrModalOpen)]="showModal" [clrModalClosable]="false">
    <h3 class="modal-title section-title" *ngIf="!hint">Overview</h3>
    <h3 class="modal-title section-title" *ngIf="hint">
        <img class="hint-icon" src="/assets/icons/blue-lightbulb-no-flash.svg" alt="blue lightbulb">
        Hint
    </h3>
    <h2 class="modal-title instruction-title">{{displayInstruction?.question}}</h2>
    <div class="modal-body">
        <div *ngIf="displayInstruction?.blueInfo">
            <span class="instruction">{{displayInstruction?.blueInfo}}</span>
        </div>

        <!-- Last page of Changing Circumstances instructions -->
        <div class="changing-circumstances-email-container"
                *ngIf="task === 'changing-circumstances-instructions' &&
                displayInstruction.progress && +displayInstruction.progress === listLength">
            <ets-sne-email-layout
                *ngIf="displayInstruction.emails"
                [emails]=displayInstruction.emails
                [colorArray]="['blue']"
                [firstColorIndex]="0"
            ></ets-sne-email-layout>
        </div>

        <div class='wrapper-details'
            *ngIf="displayInstruction.question !== 'Classifications'">
            <p class="details">{{displayInstruction?.info}}</p>
        </div>

        <!-- Last page of Fact Sorting instructions -->
        <div class="fact-sorting-information"
            *ngIf="task === 'fact-sorting-instructions' && displayInstruction.progress && +displayInstruction.progress === listLength">
            <div class='info-textbox'>
            <span class= 'titles'> Cost+ </span>
            <p class= 'section-details'>The fact is relevant to the Cost dimension and is positive; that is, it makes the firm more attractive in terms of cost.</p>
            <hr>
            <span class= 'titles'> Cost- </span>
            <p class= 'section-details'>The fact is relevant to the Cost dimension but is negative ; that is, it makes the firm less attractive in terms of cost. </p>
            <hr>
            <span class= 'titles'> Quality+ </span>
            <p class= 'section-details'>The fact is relevant to the Quality dimension and is positive ; that is, it makes the firm more attractive in terms of quality.</p>
            <hr>
            <span class= 'titles'> Quality- </span>
            <p class= 'section-details'>The fact is relevant to the Quality dimension and is negative ; that is, it makes the firm less attractive in terms of quality.</p>
            <hr>
            <span class= 'titles'> Not Relevant </span>
            <p class= 'section-details'>The fact is not relevant to either dimension.</p>
            </div>
            <br>
        </div>

        <!-- Last page of Negotiation instructions -->
        <div class="satisfaction-information"
            *ngIf="task === 'negotiation-instructions' && displayInstruction.progress && +displayInstruction.progress === listLength">
            <div class="client">
                <p class="satisfaction-text">Client Satisfaction</p>
                <div class="stars">
                    <fa-icon class="fa-icon" class="fa-icon" [icon]="faStar" *ngFor="let i of [0, 1, 2]"></fa-icon>
                    <fa-icon class="fa-icon faded" [icon]="faStar" *ngFor="let i of [0, 1]"></fa-icon>
                </div>
            </div>
            <div class="jordan">
                <p class="satisfaction-text">Jordan Satisfaction</p>
                <div class="stars">
                    <fa-icon class="fa-icon" class="fa-icon" [icon]="faStar" *ngFor="let i of [0, 1, 2]"></fa-icon>
                    <fa-icon class="fa-icon faded" [icon]="faStar" *ngFor="let i of [0, 1]"></fa-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer overview-footer">
        <div class="modal-section-numbers">{{displayInstruction?.progress}}/{{listLength}}</div>
        <div class="button-wrapper">
            <ets-solid-button [text]="displayInstruction?.buttonText" [color]="APP_COLORS.uiaction"
                (clickEvent)="nextInstruction()">
            </ets-solid-button>
        </div>
    </div>
</clr-modal>
