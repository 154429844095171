import { Component, OnDestroy, OnInit } from '@angular/core';
import { faMinus, faEllipsisH, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ScaleType } from '@swimlane/ngx-charts';
import {
  CareerDetail,
  CIPResult,
  EulerSkill,
  SpecializedSkill,
  SpecializedSkillSets
} from '../../types/career-detail';
import { CareerDetailService } from '../../services/career-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HEADER_STATES } from '../../shared/header/header.helper';
import { CareerPrioritizationService } from 'src/app/services/career-prioritization.service';
import {
  CareerPrioritizationActionEnum,
  CareerPrioritizationRequest,
  CareerPrioritizationResponse,
  LearnerCareer,
  LearnerCareersResponse
} from 'src/app/types/career-prioritization';
import { interval, Observable, of, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { raisec_likes, RIASEC_TEXT } from '../../data/cip-results-text';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../../services/profile.service';
import Profile, {
  ProfileSkill,
  ProfileSkillsRequest,
  SkillTaxonomySourceEnum,
  SkillValidationSourceEnum
} from 'src/app/types/profile';
import { FacetsGetAssessmentsResponse, FacetsRequestBody, FacetsScoreResponse } from 'src/app/types/facets';
import { FacetsService } from 'src/app/services/facets.service';
import {
  AMIABLE_VERSIONS, APPROACHABLE_VERSIONS, CONSCIENTIOUSNESS_VERSIONS,
  OPEN_MINDED_VERSIONS, PersonalityTraits, TEMPERAMENT_VERSIONS
} from 'src/app/foundational-assessment/components/facets-personality-results/facets-personality-results-helper';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';
import { delay, finalize, throttle } from 'rxjs/operators';
import CAREER_BG_MAP from 'src/app/data/career-bg-map';
import { ClrAriaLivePoliteness, ClrAriaLiveService } from '@clr/angular';

@Component({
  selector: 'ets-career-details',
  templateUrl: './career-detail.component.html',
  styleUrls: ['./career-detail.component.scss']
})
export class CareerDetailComponent implements OnInit {

  faMinus = faMinus;
  faEllipsisH = faEllipsisH;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  careerDetailData: CareerDetail | undefined;
  matchScoreData: any;
  basic: boolean = false;
  headerStates = HEADER_STATES;
  cipHeading: string = 'Explore what you love to do';
  cipSubHeading: string = 'Answer a set of questions to reveal what types of careers might appeal to you.';
  facetsHeading: string = '';
  facetsSubHeading: string = '';
  calculatedInfo: string = 'This number represents how the skills you selected for your profile align with the skills required for this career. When you add or edit skills, your score will automatically update.';
  skillsScoredInfo: string = 'The scoring is a result of how well your <b>current skills</b> match with the <b>required skills</b> for this career.'

  trainingLinksClicked: number[] = [];
  // countMeInLinkClicked: number[] = [];
  profile: Profile | undefined;
  headerState: HEADER_STATES = HEADER_STATES.BACK;


  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];
  followedCareersCount = 0;
  followedCareersList: number[] = [];

  // These two flags are for the Get to Know Yourself button text
  incompleteFacets: boolean = false;
  completedFacets: boolean = false;
  facetsBtnText: string = '';


  // Chart
  results: any[] = [];
  displayedResult: number = 0;
  view: [number, number] = [200, 200];
  gradient: boolean = false;
  disableTooltip: boolean = true;
  isDoughnut: boolean = true;
  colorScheme = {
    domain: ['#62A420', '#D5F2E2'],
    name: 'donut-chart',
    selectable: false,
    group: ScaleType.Linear
  };

  subTimeAlternate = true;

  // The personalityTraitCardVersions are ordered from low (index 0) to medium (index 1) to high (index 2).
  ORIGINAL_PERSONALITY_TRAITS: PersonalityTraits[] = [
    {
      id: 0,
      rating: 0,
      title: 'Conscientiousness',
      img: '/assets/facets/conscientiousness.svg',
      personalityTraitCardVersion: CONSCIENTIOUSNESS_VERSIONS[2]
    },
    {
      id: 1,
      rating: 0,
      title: 'Temperament',
      img: '/assets/facets/temperament.svg',
      personalityTraitCardVersion: TEMPERAMENT_VERSIONS[2]
    },
    {
      id: 2,
      rating: 0,
      title: 'Approachability',
      img: '/assets/facets/approachable.svg',
      personalityTraitCardVersion: APPROACHABLE_VERSIONS[1]
    },
    {
      id: 3,
      rating: 0,
      title: 'Collaboration',
      img: '/assets/facets/amiable.svg',
      personalityTraitCardVersion: AMIABLE_VERSIONS[1]
    },
    {
      id: 4,
      rating: 0,
      title: 'Mindfulness',
      img: '/assets/facets/open-minded.svg',
      personalityTraitCardVersion: OPEN_MINDED_VERSIONS[0]
    }
  ]

  personalityTraits!: PersonalityTraits[];


  constructor(
    private careerDetailService: CareerDetailService,
    private activatedRoute: ActivatedRoute,
    private careerPrioritizationService: CareerPrioritizationService,
    private profileService: ProfileService,
    private facetsService: FacetsService,
    private eventService: EventService,
    private router: Router,
    public ariaLiveService: ClrAriaLiveService
  ) {
  }

  getCareerDetail() {
    this.activatedRoute.paramMap.subscribe(params => {
      const careerID = params.get('careerID');
      if (careerID) {
        this.careerDetailService.getCareerDetail(+careerID).subscribe(careerDetail => {
          this.careerDetailData = careerDetail;
          this.setMatchChartResults();
          this.processCIP();
          this.initializeSkillsetVisibility();
        });
      }
      const basic = params.get('basic');
      if (basic) {
        this.basic = true;
      }

    });
  }

  processCIP() {
    // Low (0-14), Medium (15-25), High (26-40)
    if (this.careerDetailData && this.careerDetailData.learnerAssessment
      && this.careerDetailData.learnerAssessment.cipResults) {
      const {cipResults} = this.careerDetailData?.learnerAssessment;
      let highestCIPScoreKey: string = '';
      let highestCIPScore: number = 0;
      _.keys(cipResults).forEach((learnerKey) => {
        const key = learnerKey as keyof CIPResult;
        if (cipResults[key] > highestCIPScore) {
          highestCIPScore = cipResults[key];
          highestCIPScoreKey = learnerKey;
        }
      });

      switch (highestCIPScoreKey) {
        case 'Person_A_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Artistic);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Artistic);
          break;
        case 'Person_C_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Conventional);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Conventional);
          break;
        case 'Person_E_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Enterprising);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Enterprising);

          break;
        case 'Person_I_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Investigative);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Investigative);
          break;
        case 'Person_R_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Realistic);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Realistic);
          break;
        case 'Person_S_total':
          this.cipHeading = this.findCIPText(highestCIPScore, RIASEC_TEXT.Social);
          this.cipSubHeading = this.findCIPText(highestCIPScore, raisec_likes.Social);
          break;
        default:
          console.log('Something went wrong');

      }

    }
  }

  findCIPText(score: number, textArray: string[]) {
    if (score > 0 && score <= 14) {
      return textArray[0];
    } else if (score > 15 && score <= 25) {
      return textArray[1];
    } else {
      return textArray[2];
    }

  }

  setMatchChartResults() {
    if (this.careerDetailData) {
      this.displayedResult = Math.round((+this.careerDetailData.learnerCareer.matchScore) * 100);
      console.log(this.displayedResult);
      this.results = [
        {
          'name': 'Career',
          'value': Math.round(+this.careerDetailData?.learnerCareer.matchScore * 100)
        },
        {
          'name': 'Remainder',
          'value': Math.round((1 - +this.careerDetailData?.learnerCareer.matchScore) * 100)
        }
      ];
    }
  }

  // Function to save the skills to the DB
  saveSkillsAndTriggerNextQuestion(eulerSkill: EulerSkill) {
    if (this.profile?.learnerID) {
      // Combine the selected skills from the suggested and selectedTypeahead skills array into the finalSelectedSkills array
      // this.suggestedSkills.forEach(skill => {
      //   if (skill.selected) {
      //     this.finalSelectedSkills.push(skill);
      //   }
      // });
      // this.selectedTypeaheadSkills.forEach(skill => {
      //   if (skill.selected) {
      //     this.finalSelectedSkills.push(skill);
      //   }
      // });

      // Copy selected skills into string array for submittal
      let profileSkillArr: ProfileSkill[] = [];
      let profileSkill: ProfileSkill = {
        skill_name: eulerSkill.eulerSkillName,
      };
      profileSkillArr.push(profileSkill);

      let profileSkillsRequest: ProfileSkillsRequest = {
        learnerID: this.profile.learnerID,
        source_taxonomy: SkillTaxonomySourceEnum.EULER,
        validation: SkillValidationSourceEnum.SELF,
        skills: profileSkillArr
      };
      this.subArr.push(this.profileService.saveProfileSkills(profileSkillsRequest).subscribe({
        next: response => {
          this.getCareerDetail();
        }
      }));
    }
  }

  toggleTransferableSkillSet(eulerSkill: EulerSkill | SpecializedSkill) {
    const changedSkill = {
      skills: [{
        eulerID: eulerSkill.eulerSkillID,
        proficient: !eulerSkill.eulerSkillProficient,
      }],
    };

    if (this.careerDetailData && this.careerDetailData.transferableSkillSets) {

      const skillFound = this.careerDetailData.transferableSkillSets.transferableSkills.find(specialSkill => specialSkill.eulerSkillID === eulerSkill.eulerSkillID);

      if (skillFound) {
        skillFound.eulerSkillProficient = !eulerSkill.eulerSkillProficient;
      }

    }
    this.updateSkillProficiency(changedSkill);
  }

  toggleSpecializedSkillSet(eulerSkill: EulerSkill | SpecializedSkill, skillsetID: number) {
    const changedSkill = {
      skills: [{
        eulerID: eulerSkill.eulerSkillID,
        proficient: !eulerSkill.eulerSkillProficient,
      }],
    };

    let numProficient = 0;
    const skillsetFound = this.careerDetailData?.specializedSkillSets.find(specialSkill =>
      specialSkill.skillsetID === skillsetID
    );

    if (skillsetFound) {
      skillsetFound.eulerSkills.forEach(skill => {

        if (skill.eulerSkillID === eulerSkill.eulerSkillID) {
          skill.eulerSkillProficient = !eulerSkill.eulerSkillProficient;
        }

        if (skill.eulerSkillProficient) {
          numProficient++;
        }


      });

      skillsetFound.skillsetProficiency = Math.floor((numProficient * 5) / skillsetFound.eulerSkills.length);
      skillsetFound.skillsetProficiency = (skillsetFound.skillsetProficiency === 0 && numProficient > 0) ? 1 : skillsetFound.skillsetProficiency;
    }

    this.updateSkillProficiency(changedSkill);
  }

  updateSkillProficiency(changedSkill: any) {
    const delayedObservable = of([]).pipe(delay(this.subTimeAlternate ? 3000 : 1500));
    delayedObservable.subscribe(() => {
      this.careerDetailService.updateSkills(changedSkill)
        .subscribe((newMatchScoreData: any) => {
          console.log('match scores array: ', newMatchScoreData.allMatchScores.matchScores);
          this.matchScoreData = newMatchScoreData;
          const scoreFound = this.matchScoreData.allMatchScores.matchScores.find((score: any) =>
            score[0] === this.careerDetailData?.careerID
          );
          if (scoreFound) {
            console.log(scoreFound);
            this.careerDetailData!.learnerCareer.matchScore = scoreFound[1];
          } else {
            console.error('matchScore not found.');
          }

          this.setMatchChartResults();
        });
    });
    this.subTimeAlternate = !this.subTimeAlternate;
  }

  toggleFollowCareer(career: CareerDetail | undefined) {
    // default request
    let request: CareerPrioritizationRequest = {
      careerIDList: this.followedCareersList,
      action: CareerPrioritizationActionEnum.FOLLOW
    };

    if (career && career.careerID) {
      // if the career is being followed, add it to the followed career list else unfollow it
      if (!career?.learnerCareer.isFollowing) {
        this.followedCareersList.push(career.careerID);
        request.careerIDList = this.followedCareersList; // update the list with the new career added
        this.ariaLiveService.announce('Saved Career', ClrAriaLivePoliteness.assertive);
      } else {
        let unfollowedCareerList: number[] = [];
        unfollowedCareerList.push(career.careerID);
        request.careerIDList = unfollowedCareerList;
        request.action = CareerPrioritizationActionEnum.UNFOLLOW;
        this.ariaLiveService.announce('Removed career from saved careers', ClrAriaLivePoliteness.assertive);
      }
    }

    this.subArr.push(this.careerPrioritizationService.updateLearnerCareers(request).subscribe({
      next: response => {
        let careerPrioritizationResponse: CareerPrioritizationResponse = response;
        if (careerPrioritizationResponse.success && career) {
          career.learnerCareer.isFollowing = !career.learnerCareer.isFollowing;
          if (!career.learnerCareer.isFollowing) {
            this.followedCareersCount--;
          } else {
            this.followedCareersCount = this.followedCareersList.length;
          }

        }
      }
    }));
  }

  getLearnerCareers() {
    let learnerCareersResponse: LearnerCareersResponse;

    this.subArr.push(this.careerPrioritizationService.getLearnerCareers().subscribe(data => {
      learnerCareersResponse = data;
      this.processCareerResponse(learnerCareersResponse.careers);
    }));
  }

  processCareerResponse(careers: LearnerCareer[]) {
    careers.forEach(career => {
      // increment count if it's already been followed
      if (career.isFollowing) {
        this.followedCareersCount++;
        this.followedCareersList.push(career.careerID);
      }
    });
  }

  gotoTrainingLink(link: string, skillSetID: number) {
    this.trainingLinksClicked.push(skillSetID);
    this.careerDetailService.trainingLink(skillSetID).subscribe();
    if (link && link.length > 0 && link !== '[placeholder]') {
      window.open(link, '_blank');
    }

  }

  countMeInLinkClick() {
    if (this.careerDetailData && this.careerDetailData.careerID) {
      this.careerDetailService.countMeInLink(this.careerDetailData.careerID).subscribe(response => {
        if (this.careerDetailData?.learnerCareer) {
          this.careerDetailData.learnerCareer.confirmNotifications = true;
        }

      });
    }
  }

  isTrainingLinkClicked(skillsetID: number): boolean {
    return !!this.trainingLinksClicked.find((skillID: number) => skillsetID === skillID);
  }

  // Toggle skillset visibility
  toggleSkillsetVisibility(skill: SpecializedSkillSets) {
    console.log('toggling visibility');
    skill.showSkillset = !skill.showSkillset;
  }

  // Initialize skillset visibility flags to true when payload arrives
  initializeSkillsetVisibility() {
    if (this.careerDetailData && this.careerDetailData.inDemand) {
      let sss = this.careerDetailData?.specializedSkillSets;
      for (let i in sss) {
        sss[i].showSkillset = true;
      }

    }
  }

  // Gets existing FACETS assessments for the current user
  getFacetsAssessments() {
    let getAssessmentRequest: FacetsRequestBody = {
      action: 'getAssessment'
    };

    this.subArr.push(this.facetsService.getAssessments(getAssessmentRequest).subscribe({
      next: response => {
        this.processGetAssessmentsResponse(response);
      },

    }));
  }

  processGetAssessmentsResponse(response: FacetsGetAssessmentsResponse) {
    let assessments = response.assessments;
    // Determine whether there is an uncomplete FACETS quiz on the user's account
    // if so, then resume that test
    assessments.forEach(assessment => {
      if (assessment.completed) {
        this.completedFacets = true;
        let resultJSON = assessment.resultJSON;
        this.processScoreResponse(resultJSON);
      } else {
        this.incompleteFacets = true;
      }

    });

    if (!this.incompleteFacets && !this.completedFacets) {
      // User has no unfinished test or completed test
      this.facetsBtnText = 'Begin';
    } else if (this.incompleteFacets && !this.completedFacets) {
      // User has an unfinished test
      this.facetsBtnText = 'Resume';
    }
  }

  redirectFacets() {
    // Route to Facets intro page where they'll resume or begin the test there
    this.router.navigate(['/foundational-assessment/power-skills-intro']);
  }

  processScoreResponse(scoreResponse: FacetsScoreResponse) {
    const copyTraits = [...this.ORIGINAL_PERSONALITY_TRAITS];
    scoreResponse.groups.forEach(group => {
      switch (group.name) {

        // Amiable = Agreeableness
        case 'ACC_AGRE':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[3].rating = rating;
              copyTraits[3].personalityTraitCardVersion = AMIABLE_VERSIONS[rating - 1];
              break; // break out of loop once we get the rating
            }
          }
          break;

          // Conscientiousness = Conscientiousness
        case 'ACC_CONSC':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[0].rating = rating;
              copyTraits[0].personalityTraitCardVersion = CONSCIENTIOUSNESS_VERSIONS[rating - 1];
              break; // break out of loop once we get the rating
            }
          }
          break;

          // Temperament = Emotional Stability
        case 'ACC_EMOT':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[1].rating = rating;
              copyTraits[1].personalityTraitCardVersion = TEMPERAMENT_VERSIONS[rating - 1];
              break; // break out of loop once we get the rating
            }
          }
          break;

          // Approachability = Extraversion
        case 'ACC_EXTR':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[2].rating = rating;
              copyTraits[2].personalityTraitCardVersion = APPROACHABLE_VERSIONS[rating - 1];
              break; // break out of loop once we get the rating
            }
          }
          break;

          // Open-Mindedness = Openness
        case 'ACC_OPEN':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[4].rating = rating;
              copyTraits[4].personalityTraitCardVersion = OPEN_MINDED_VERSIONS[rating - 1];
              break; // break out of loop once we get the rating
            }
          }
          break;

        default:

      }
    });
    // Sort the final results from highest to lowest
    copyTraits.sort((a, b) => (a.rating < b.rating) ? 1 : ((b.rating < a.rating) ? -1 : 0));
    this.personalityTraits = copyTraits;
  }

  findBGColor(careerTitle: string) {
    const bgCareer = CAREER_BG_MAP.find((career: {careerTitle: string, bgColor: string}) => career.careerTitle === careerTitle);
    if (bgCareer) {
      return bgCareer.bgColor;
    } else {
      return '#ffffff';
    }
  }


  ngOnInit(): void {
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Career Details',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Career Details', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Career Details', EventActionEnum.PAGE_VIEW, EventSectionEnum.CAREER_DETAILS);

    this.profileService.getProfile().subscribe(profile => {
      this.profile = profile;
    });

    this.getCareerDetail();
    this.getLearnerCareers();
    this.getFacetsAssessments();
  }

}
