import { ActivatedRoute, Router } from '@angular/router';
import COACHABILITY_TYPES from '../../types/coachability-types';
import {
  CoachabilityChangingCircumstancesData,
  CoachabilityChangingCircumstancesInstructionModal
} from '../../data/sne/coachability/02_changing-circumstances';
import { CoachabilityCoachesData, CoachabilityCoachInstructionModal } from '../../data/sne/coachability/03_coaches';
import { CoachabilityCriticismData, CoachabilityCriticismInstructionModal } from '../../data/sne/coachability/04_criticism';
import {
  CoachabilityPersonalMistakesData,
  CoachabilityPersonalMistakesInstructionModal
} from '../../data/sne/coachability/05_personal-mistakes';
import { CoachabilitySurveyData } from '../../data/sne/coachability/01_survey';
import {
  CoachabilityUncertaintyData,
  CoachabilityUncertaintyInstructionModal
} from '../../data/sne/coachability/06_uncertainty';
import { SneQuestion, SNE_TASK_ENUM, SneAnswerRequest, SneModal } from '../../types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import * as _ from 'lodash';
import { SneAnswerService } from '../../services/sne-answer.service';
import { generateNextQuestionPath } from './sne-coachability.helpers';

export class SneCoachabilityBase {
  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService?: SneAnswerService) {
  }

  questionNum: string | undefined;
  questionData: SneQuestion | undefined;
  questionIndex: number | undefined;
  moduleIndex: number | undefined;
  moduleSize: number = 6;
  questionKeyList: string[] = [];
  coachabilityType: COACHABILITY_TYPES | undefined;
  coachabilityData: Map<string, SneQuestion> | undefined;
  showInstructionModal = false;
  instructionModal: SneModal | undefined;
  singleResponse: string = '';
  multiResponse: string[] = [];
  startTimestamp!: string;
  endTimestamp!: string;
  showHintModal = false;
  responseList: string[] = [];

  additionalNextQuestionSteps() {}

  generateNextSectionPath() {
    let path: string | undefined;
    if (this.questionData && this.questionData.goto) {
      switch (this.questionData.goto) {
        case COACHABILITY_TYPES.SURVEY:
          path = generateNextQuestionPath(
            Array.from(CoachabilitySurveyData.values())[0],
            Array.from(CoachabilitySurveyData.keys())[0],
            COACHABILITY_TYPES.SURVEY,
            this.additionalNextQuestionSteps
          );
          break;
        case COACHABILITY_TYPES.COACHES:
          path = generateNextQuestionPath(
            Array.from(CoachabilityCoachesData.values())[0],
            Array.from(CoachabilityCoachesData.keys())[0],
            COACHABILITY_TYPES.COACHES,
            this.additionalNextQuestionSteps
          );
          break;
        case COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES:
          path = generateNextQuestionPath(
            Array.from(CoachabilityChangingCircumstancesData.values())[0],
            Array.from(CoachabilityChangingCircumstancesData.keys())[0],
            COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES,
            this.additionalNextQuestionSteps
          );
          break;
        case COACHABILITY_TYPES.CRITICISM:
          path = generateNextQuestionPath(
            Array.from(CoachabilityCriticismData.values())[0],
            Array.from(CoachabilityCriticismData.keys())[0],
            COACHABILITY_TYPES.CRITICISM,
            this.additionalNextQuestionSteps
          );
          break;
        case COACHABILITY_TYPES.PERSONAL_MISTAKES:
          path = generateNextQuestionPath(
            Array.from(CoachabilityPersonalMistakesData.values())[0],
            Array.from(CoachabilityPersonalMistakesData.keys())[0],
            COACHABILITY_TYPES.PERSONAL_MISTAKES,
            this.additionalNextQuestionSteps
          );
          break;
        case COACHABILITY_TYPES.UNCERTAINTY:
          path = generateNextQuestionPath(
            Array.from(CoachabilityUncertaintyData.values())[0],
            Array.from(CoachabilityUncertaintyData.keys())[0],
            COACHABILITY_TYPES.UNCERTAINTY,
            this.additionalNextQuestionSteps
          );
          break;
        default:
          path= this.questionData.goto;
          console.error('Cannot find question type');
      }
    }
    return path;
  }

  navToNextPage() {
    //make call to backend to save response
    let path: string | undefined;
    if (this.coachabilityData) {
      const keysArray = Array.from(this.coachabilityData.keys());
      let keyIndex = keysArray.findIndex(key => key === this.questionNum);
      if (keyIndex !== -1) {
        if (keyIndex < keysArray.length - 1) {
          keyIndex++; // go to next key in the question map
          const nextKey = keysArray[keyIndex];
          const newQuestion = this.coachabilityData.get(nextKey);
          if (newQuestion && this.coachabilityType) {
            path = generateNextQuestionPath(newQuestion, nextKey, this.coachabilityType, this.additionalNextQuestionSteps);
          }
        } else {
          path = this.generateNextSectionPath();
        }

      }
    }
    if (path) {
      this.router.navigate([path]);
    }
  }

  getNextQuestion() {
    if (this.questionData?.modalBeforeNext) {
      this.openInstructionModal();
    }else {
      if (this.questionData?.nextPageHasHint) {
        this.hintToggle();
      } 
      this.submit();
      this.navToNextPage();
    }
  }

  submit() {
    if (this.sneAnswerService) {
      if (this.questionIndex !== undefined && this.questionData?.submitId) {
        this.endTimestamp = Date.now().toString();
        const completed = !!this.questionData?.complete;
        const data: { [key: string]: any } = {};
        if (this.singleResponse) {
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: this.singleResponse,
            end: this.endTimestamp
          };
        } else if (this.multiResponse.length > 0) {
          let aggResponse: string = '';
          this.multiResponse.forEach(response => {
            aggResponse = aggResponse + response;
          });
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: aggResponse,
            end: this.endTimestamp
          };
        }

        if (!_.isEmpty(data)) {
          let task: SNE_TASK_ENUM | undefined;
          switch (this.coachabilityType) {
            case COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES:
              task = SNE_TASK_ENUM.CHANGING_CIRCUMSTANCES;
              break;
            case COACHABILITY_TYPES.COACHES:
              task = SNE_TASK_ENUM.COACHES;
              break;
            case COACHABILITY_TYPES.CRITICISM:
              task = SNE_TASK_ENUM.CRITICISM;
              break;
            case COACHABILITY_TYPES.PERSONAL_MISTAKES:
              task = SNE_TASK_ENUM.PERSONAL_MISTAKES;
              break;
            case COACHABILITY_TYPES.SURVEY:
              task = SNE_TASK_ENUM.SURVEY;
              break;
            case COACHABILITY_TYPES.UNCERTAINTY:
              task = SNE_TASK_ENUM.UNCERTAINTY;
              if (completed) { 
                let requestBody: SneAnswerRequest = {
                  task: SNE_TASK_ENUM.CCH,
                  data: null,
                  completed: completed
                };
                this.sneAnswerService.postAction(requestBody).subscribe();
                console.log('Coachability completed.');
              }
              break;
            default:
              console.error(`Coachability Type could not be identified: ${this.coachabilityType}`);
          }
          if (task) {
            let requestBody: SneAnswerRequest = {
              task: task,
              data: data,
              completed: completed
            };

            this.sneAnswerService.postAction(requestBody).subscribe( response => {
              console.log(`Response to Coachability post request:  + ${response}`);
              this.singleResponse = ''; // refresh singleResponse
              this.multiResponse = []; // refresh multiResponse
            });
          }
        }
      }
    }
  }

  closeInstructionModal() {
    this.showInstructionModal = false;
  }

  openInstructionModal() {
    this.showInstructionModal = true;
  }
  hintToggle(){
    this.showHintModal = !this.showHintModal;
  }

  closeModalAndNav() {
    this.closeInstructionModal();
    this.navToNextPage();
  }

  additionalParamProcess() {}

  setData(coachabilityData: Map<string, SneQuestion>) {
    if (this.questionNum) {
      this.coachabilityData = coachabilityData;
      this.questionData = this.coachabilityData.get(this.questionNum);
      this.questionKeyList = Array.from(this.coachabilityData.keys());
      this.questionIndex = this.questionKeyList
        .findIndex(key => key === this.questionNum);
    }
  }

  processParams() {
    this.route.params.subscribe(params => {
      this.startTimestamp = Date.now().toString();
      this.singleResponse = '';
      this.multiResponse = [];
      const { questionNum, section } = params;
      this.coachabilityType = section;
      this.questionNum = questionNum;

      if (this.questionNum && this.coachabilityType) {
        switch (this.coachabilityType) {
          case COACHABILITY_TYPES.SURVEY:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex=2;
            this.setData(CoachabilitySurveyData);
            break;
          case COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 3;
            this.setData(CoachabilityChangingCircumstancesData);
            this.instructionModal = CoachabilityChangingCircumstancesInstructionModal;
            break;
          case COACHABILITY_TYPES.COACHES:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 4;
            this.setData(CoachabilityCoachesData);
            this.instructionModal = CoachabilityCoachInstructionModal;
            break;
          case COACHABILITY_TYPES.CRITICISM:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex =5;
            this.setData(CoachabilityCriticismData);
            this.instructionModal = CoachabilityCriticismInstructionModal;
            break;
          case COACHABILITY_TYPES.PERSONAL_MISTAKES:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex=6;
            this.setData(CoachabilityPersonalMistakesData);
            this.instructionModal = CoachabilityPersonalMistakesInstructionModal;
            break;
          case COACHABILITY_TYPES.UNCERTAINTY:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex=7;
            this.setData(CoachabilityUncertaintyData);
            this.instructionModal = CoachabilityUncertaintyInstructionModal;
            break;
          default:
            console.error(`Coachability Type could not be identified: ${this.coachabilityType}`);
        }
        this.additionalParamProcess();
      }
    });
  }
}
