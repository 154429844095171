<div class="container">

  <h1>JOB OPPORTUNITIES</h1>

  <div class="inner" *ngIf="jobPostings && jobPostings.length !== 0">
      <div class="slide-wrapper" *ngFor="let jobPosting of jobPostings; index as i;">
        <div *ngIf="i === jobPostingIndex" class="transition">
            <div class="inner-slide" @carouselAnimation>
              <ets-job-posting-card [jobPosting]="jobPosting" [assessments]="assessments" [learnerID]="profile.learnerID" [email]="profile.email"></ets-job-posting-card>
            </div>
        </div>
      </div>
  </div>

  <div class="navigation-buttons" *ngIf="jobPostings && jobPostings.length > 1">
    <button class="carousel-control-prev" (click)="decrement()" aria-label="previous job"
      aria-live="assertive"><</button>
    
    <div class="circle" *ngFor="let jobPosting of jobPostings; index as i;">
        <span [class]="i === jobPostingIndex ? 'active' : 'inactive' "
            (click)="changeSlide(i)">
        </span>
    </div>
    
    <button class="carousel-control-next" (click)="increment()" aria-label="next job"
      aria-live="assertive">></button>
  </div>

  <!-- No Job Postings -->
  <div class="noTraining" *ngIf="jobPostings && jobPostings.length === 0 || jobPostings === undefined">
    <div class="textbox-wrapper">
      <span class="text-header"><b>Job opportunities coming soon!</b> <br> Check back soon to view job opportunities for
        this career!</span>
    </div>
  </div>
</div>