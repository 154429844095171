import { SneQuestion } from '../../types/sne';
import CT_TYPES from '../../types/critical-thinking-types';
import QUESTION_TYPES from '../../types/question-types';

export function generateNextQuestionPath(question: SneQuestion, key: string, sneType: CT_TYPES, additionalNextQuestionSteps?: () => void) {
  let path = '';
  if (question) {
    switch (question.questionType) {
      case QUESTION_TYPES.SNE_INTRO:
        path = `sne-new/ct/sne-intro/${key}/${sneType}`;
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        break;
      case QUESTION_TYPES.INTERSTITIAL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/interstitial/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO:
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_STATEMENT:
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_COMMENTS:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/multiple-choice-convo/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/multiple-choice-email/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/multiple-choice-social-media/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/multiple-choice-production-flow-map/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SCHEDULE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/ct/multiple-choice-schedule/${key}/${sneType}`;
        break;
      default:
        console.error('Cannot find question type');
    }
  }
  return path;
}
