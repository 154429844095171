import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Assessment } from 'src/app/types/assessments';
import { APP_COLORS } from '../../../../global-styles';
import { Router } from '@angular/router';
@Component({
  selector: 'ets-skill-card',
  templateUrl: './skill-card.component.html',
  styleUrls: ['./skill-card.component.scss']
})
/**
 * Skill Card Component:
 * @param Assessment
 * @param assessmentID string
 * @description Receives Assessment object and shows Skill Card and state based on assessment completion status.
 */

export class SkillCardComponent implements OnInit {

  constructor(private router: Router) { }

  // skillAssessmentMap contains styles and image locations based on assessment Type
  skillAssessmentMap = new Map([
    ['CPS', {
      humanReadableName: 'Collaborative Problem Solving',
      cardImg: '../../../../assets/dashboard/skills/cps-skill-task.png',
      link: ''
    }],
    ['CRT', {
      humanReadableName: 'Critical Thinking',
      cardImg: '../../../../assets/dashboard/skills/crt-skill-task.png',
      link: ''
    }],
    ['CCH', {
      humanReadableName: 'Coachability',
      cardImg: '../../../../assets/dashboard/skills/cch-skill-task.png',
      link: ''
    }],
    ['ORC', {
      humanReadableName: 'Oral Communication',
      cardImg: '../../../../assets/dashboard/skills/orc-skill-task.png',
      link: ''
    }],
    ['WRC', {
      humanReadableName: 'Written Communication',
      cardImg: '../../../../assets/dashboard/skills/wrc-skill-task.png',
      link: '',
    }],
  ]);

  @Input() skillAssessment?: Assessment;
  @Input() assessmentId?: string;
  @Output() openAssessment = new EventEmitter<string>();
  leftBackgroundColor = APP_COLORS.bkrdpage;
  rightBackgroundColor = APP_COLORS.bkrdpage;
  blueCheck: string = '../../../assets/icons/Blue-check.svg';
  inProgress: string = '../../../assets/icons/circleprogress.svg';
  inactiveBtn = APP_COLORS.uiaction;
  btnText: string = '';
  desc: string = '';

  assessmentInfo!: {
        cardImg: '',
        humanReadableName: '',
        link: ''
  };

  ngOnInit(): void {
    const assessmentCardId = this.assessmentId as string;
    const assessmentCardStatus = this.skillAssessment?.status as string;
    this.assessmentInfo = this.getAssessmentInfo(assessmentCardId as string);
    this.leftBackgroundColor = this.setBackground(assessmentCardStatus, assessmentCardId);
    this.rightBackgroundColor = this.setBackground(assessmentCardStatus, assessmentCardId);
  }

  clickTo(assessmentStatus: string) {
    let completePath = '/sne-new/report/';

    if (assessmentStatus === 'Completed') {
      switch(this.assessmentId) {
        case 'CRT':
          this.router.navigate([completePath + 'CT']);
          break;
        case 'CPS':
          this.router.navigate([completePath + 'CPS']);
          break;

        case 'CCH':
          this.router.navigate([completePath + 'COACHABILITY']);
          break;

        case 'ORC':
          this.router.navigate([completePath + 'OC']);
          break;

        case 'WRC':
          this.router.navigate([completePath + 'WC']);
          break;
      }
    } else if (assessmentStatus === 'In progress' || assessmentStatus === 'Not started') {
      this.openAssessment.emit(this.assessmentId);
    }
  }

  getAssessmentInfo(assessmentId: string): any | undefined {
    return this.skillAssessmentMap.get(assessmentId);
  }

  setBackground(status: string, id: string): any {
    // Return BG Color
    if (status === 'Completed') {
      /**  In the case when completed skill card is completed **/
      if(id === 'CPS') {
        this.desc = 'Collaborative Problem Solving Complete';
        return APP_COLORS.mustardlight;
      }
      if(id === 'CRT') {
        this.desc = 'Critical Thinking Completed';
        return APP_COLORS.aquamarinelight;
      }
      if(id === 'CCH') {
        this.desc = 'Coachability Completed';
        return APP_COLORS.persianbluebright;
      }
      if(id === 'ORC') {
        this.desc = 'Oral Communication Completed';
        return APP_COLORS.mangotangolight;
      }
      if(id === 'WRC') {
        this.desc = 'Written Communication Completed';
        return APP_COLORS.lbblight;
      }
    } else {
      /**  In the case when completed skill card is not "completed" **/
      if(id === 'CPS') {
        this.desc = 'Understand how you <b class="cps-bold">work with others</b> to resolve issues.';
        return APP_COLORS.mustardbright;
      }
      if(id === 'CRT') {
        this.desc = 'Understand how you <b class="crt-bold">use logic to evaluate information</b>.';
        return APP_COLORS.aquamarinebright;
      }
      if(id === 'CCH') {
        this.desc = 'Understand your <b class="cch-bold">learning potential</b>.';
        return APP_COLORS.persianbluebright + '80';
      }
      if(id === 'ORC') {
        this.desc = 'Understand how you <b class="orc-bold">share and receive spoken information</b>.';
        return APP_COLORS.mangotangobright;
      }
      if(id === 'WRC') {
        this.desc = 'Understand how you <b class="wrc-bold">approach and analyze writing</b>.';
        return APP_COLORS.lbblight + '80';
      }
      return APP_COLORS.bkrdpage;
    }
  }
}
