<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>
<ets-sne-split-screen
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="right">
    <ets-sne-multi-choice-list
      [inputType]="questionData?.inputType"
      [headerQuestion]="questionData?.header"
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
      (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
    >
    </ets-sne-multi-choice-list>
  </div>
  <div class="left">
    <div class="hint-btn-container">
      <span class="interaction-text">Email Thread</span>
      <ets-outline-button
        [text]="'Hint'"
        [color]="APP_COLORS.uiaction"
        *ngIf="questionData?.hintModal"
        [iconImgSrc]="'/assets/icons/blue-lightbulb-no-flash.svg'"
        [iconImgAlt]="'blue lightbulb'"
        [iconWidth]="'16px'"
        (clickEvent)="hintToggle()"
      >
      </ets-outline-button>
    </div>
    <ets-sne-email-layout
      [subject]="emailSubject"
      [emails]="emailList"
      [colorArray]="['white', 'green']"
      [firstColorIndex]="1"
    >
    </ets-sne-email-layout>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  (overviewEmitter)="openInstructionModal()"
  (nextEmitter)="getNextQuestion()"
  [questionNum]="questionNum"
  [isButtonDisabled]="
    questionData?.inputType === SNE_INPUT_TYPES.RADIO
      ? multiResponse.length !== questionData?.multipleMulChoiceOptions?.length
      : multiResponse.length !== questionData?.numSelectOptions
  "
>
  <ets-sne-multi-choice-list
    [inputType]="questionData?.inputType"
    [headerQuestion]="questionData?.header"
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectMultiResponseEvent)="multiResponseQuestionHandler($event)"
    (selectSingleResponseEvent)="singleReponseQuestionHandler($event.questionIndex, $event.answer)"
    [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID="customContent">
    <ets-sne-email-layout
      *ngIf="instructionModal?.customContent[0].to"
      [emails]="instructionModal?.customContent"
      [colorArray]="['green']"
      [firstColorIndex]="0"
    ></ets-sne-email-layout>
    <ets-sne-conversation-log-text
      *ngIf="instructionModal?.customContent[0].user"
      [chatLog]="instructionModal?.customContent"
      [showHeader]="false"
    ></ets-sne-conversation-log-text>
  </div>
</ets-modal>
<ets-modal
  [title]="questionData?.hintModal?.title"
  [showModal]="showHintModal"
  [list]="questionData?.hintModal?.pageList"
  (modalClose)="hintToggle()"
  [hint]="true"
>
</ets-modal>
