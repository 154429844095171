<ets-header [headerState]="headerState" [simpleBack]='true' [isBGWhite]="true"></ets-header>

<div class="container">
    <div class="flexbox">
        <div class="interstitial-image-wrapper">
            <img class="interstitial-image" src="../../../../assets/facets/facets-new-interstitial.png" alt="facets-interstitial image">
        </div>    
        <div class="info">
            <div class="mobile-hide">
            <div class="interstitial-title"> 
                <span>Selecting your answers</span>
            </div>
            </div>
            <div class="mobile-display">
                <div class="interstitial-title"> 
                    <span>Selecting your answers</span>
                </div>
            </div>
            <div class="interstitial-subtitle">
                You'll see a series of two cards, each featuring a different statement. Read the statements and decide how much each reflects your personality.
            </div>
            <div class="small-box">
                <div class="icon">
                    <fa-icon [icon]="faMapSigns" size="2x" [styles]="{'color': 'black'}"></fa-icon>
                </div>
                <span class="disclaimer-text">
                    <span class="bold">You cannot make the same selection for both cards. </span>To ensure accurate results, you must choose a different response for each statement.
                </span>
            </div>
        </div>
    </div>
</div>

<div class="footer-container">
    <div class="solo-button-container">
      <ets-solid-button class="next-question-button mobile-hide"
                        [text]="'Let’s Go!'"
                        [color]="APP_COLORS.uiaction"
                        (click)="nextPageSkillAssessment()"
      >
      </ets-solid-button>
      <ets-solid-button class="next-question-button mobile-display"
                        [text]="'Let’s Go!'"
                        [color]="APP_COLORS.uiaction"
                        (click)="nextPageSkillAssessment()"
                        [size]="'md'"
      >
      </ets-solid-button>
    </div>
  </div>