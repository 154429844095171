import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
 
 @Injectable({
   providedIn: 'root'
 })
 
export class FileManagerService extends BaseService{
 
   private fileManagerUrl = environment.fileManagerAPI;
 
   constructor(private http: HttpClient, protected logService: LogService) {
     super(logService);
     this.serviceName = 'Facets';
   }
 
   calculateUploadUrl(fileName:string,fileType:string): Observable<any> {
     let accessToken = localStorage.getItem('accessToken');
     if (accessToken) {
       let headers = new HttpHeaders().set('Authorization', accessToken);
 
       // The default Facet's test ID is hardcoded on the BE in Secrets Manager. Only pass in the test ID if you would 
       // like to override the ID (i.e. - the shorter quiz for testing purposes) that's in the BE
       let body = {
         fileType: fileType,
         fileName: fileName
       };
       let requestLogObj: LogObject = {
         message: 'FileManager: calculateUploadUrl',
         object: body,
       };
       this.logService.logInfo(requestLogObj)?.subscribe();
 
       return this.http.post<any>(this.fileManagerUrl + '/getUploadUrl', body, {headers}).pipe(
         tap(data => { 
           let responseLogObj: LogObject = {
             message: 'FileManager: calculateUploadUrl Response',
             object: data,
           };
           this.logService.logInfo(responseLogObj)?.subscribe();
         }),
         catchError(this.handleError)
       );
     }
     // Shouldn't happen
     return new Observable<any>();
   }
 
   deleteFile(fileName:string): Observable<any> {
     let accessToken = localStorage.getItem('accessToken');
     if (accessToken) {
       let headers = new HttpHeaders().set('Authorization', accessToken);
 
       // The default Facet's test ID is hardcoded on the BE in Secrets Manager. Only pass in the test ID if you would 
       // like to override the ID (i.e. - the shorter quiz for testing purposes) that's in the BE
       let body = {
         fileName: fileName
       };
       let requestLogObj: LogObject = {
         message: 'FileManager: deleteFile',
         object: body,
       };
       this.logService.logInfo(requestLogObj)?.subscribe();
 
       return this.http.post<any>(this.fileManagerUrl + '/deleteFile', body, {headers}).pipe(
         tap(data => { 
           let responseLogObj: LogObject = {
             message: 'FileManager: deleteFile',
             object: data,
           };
           console.log(responseLogObj);
           this.logService.logInfo(responseLogObj)?.subscribe();
         }),
         catchError(this.handleError)
       );
     }
     // Shouldn't happen
     return new Observable<any>();
   }

   listFiles(): Observable<any> {
     let accessToken = localStorage.getItem('accessToken');
     if (accessToken) {
       let headers = new HttpHeaders().set('Authorization', accessToken);
       let requestLogObj: LogObject = {
         message: 'FileManager: listFiles',
       };
       this.logService.logInfo(requestLogObj)?.subscribe();

       return this.http.get<any>(this.fileManagerUrl + '/listFiles', {headers}).pipe(
         tap(data => { 
           let responseLogObj: LogObject = {
             message: 'FileManager: listFiles',
             object: data,
           };
           this.logService.logInfo(responseLogObj)?.subscribe();
         }),
         catchError(this.handleError)
       );
     }
     // Shouldn't happen
     return new Observable<any>();
   }
};