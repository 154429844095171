import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';

@Component({
  selector: 'ets-ct-sne-intro',
  templateUrl: './ct-sne-intro.component.html',
  styleUrls: ['./ct-sne-intro.component.scss']
})
export class CtSneIntroComponent extends SneCriticalThinkingBase implements OnInit {

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  numberText = '02.';

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  onClick(){
    this.getNextQuestion();
  }

  ngOnInit(): void {
    this.processParams();
  }

}
