import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfile } from 'src/app/store';
import Profile from 'src/app/types/profile';
import { faChevronDown, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { HEADER_STATES } from '../shared/header/header.helper';
import { Router } from '@angular/router';
import { CipService } from 'src/app/services/cip.service';
import { CipScores, RAISEC } from 'src/app/types/cip';
import { RIASEC_TEXT, raisec_likes, raisec_dislikes } from 'src/app/data/cip-results-text';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { EventService } from '../services/event.service';
import { EventActionEnum, EventSectionEnum } from '../types/event';

@Component({
  selector: 'ets-personality-traits',
  templateUrl: './career-interests.component.html',
  styleUrls: ['./career-interests.component.scss']
})
export class CareerInterestsComponent implements OnInit {

  constructor(private store: Store, private router: Router, private cipService: CipService, private profileService: ProfileService, private eventService: EventService) { }

  userProfile!: Profile;
  scoreData: CipScores | undefined;
  faChevronDown = faChevronDown;
  faCommentDots = faCommentDots;
  preferredName: string = 'Jane';

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  CAREER_INTERESTS: RAISEC[] | undefined;
  // CAREER_INTERESTS: RAISEC[] | undefined = [
  //   {id: 0, title: "Realistic", opposite: "Idealistic", description: RIASEC_TEXT["Realistic"][this.indexFromScore(0)], img: "/assets/cip/realistic/"+this.indexFromScore(0).toString()+".svg", percentage: 100-(0), showCard: false, useFaIcon: false,
  //     cardTitle: "The Do-ers", cardDesc: "Individuals who are independent, practical, enjoy the outdoors, and prefer working with their hands.", likes: raisec_likes["Realistic"][this.indexFromScore(0)], dislikes: raisec_dislikes["Realistic"][0]},
  //   {id: 1, title: "Investigative", opposite: "Practical", description: RIASEC_TEXT["Investigative"][this.indexFromScore(10)], img: "/assets/cip/investigative/"+this.indexFromScore(10).toString()+".svg", percentage: 100-(10), showCard: false, useFaIcon: false,
  //     cardTitle: "The Thinkers", cardDesc: "Individuals who are curious, analytical, logical, and enjoy problem solving.", likes: raisec_likes["Investigative"][this.indexFromScore(10)], dislikes: raisec_dislikes["Investigative"][this.indexFromScore(10)]},
  //   {id: 2, title: "Artistic", opposite: "Methodical", description: RIASEC_TEXT["Artistic"][this.indexFromScore(20)], img: "/assets/cip/artistic/"+this.indexFromScore(20).toString()+".svg", percentage: 100-(20/40.0*100), showCard: false, useFaIcon: false,
  //     cardTitle: "The Creators", cardDesc:"Individuals who are creative, expressive, imaginative, and like to work with ideas.", likes: raisec_likes["Artistic"][this.indexFromScore(20)], dislikes: raisec_dislikes["Artistic"][this.indexFromScore(20)]},
  //   {id: 3, title: "Social", opposite: "Independent", description: RIASEC_TEXT["Social"][this.indexFromScore(40)], img: "/assets/cip/social/"+this.indexFromScore(0).toString()+".svg", percentage: 100-(0/40.0*100), showCard: false, useFaIcon: true,
  //     cardTitle: "The Helpers", cardDesc: "Individuals who are generous, helpful, and enjoy teamwork and helping others.", likes: raisec_likes["Social"][this.indexFromScore(0)], dislikes: raisec_dislikes["Social"][this.indexFromScore(0)]},
  //   {id: 4, title: "Enterprising", opposite: "Careful", description: RIASEC_TEXT["Enterprising"][this.indexFromScore(40)], img: "/assets/cip/enterprising/"+this.indexFromScore(40).toString()+".svg", percentage: 100-(40/40.0*100), showCard: false, useFaIcon: false,
  //     cardTitle: "The Persuaders", cardDesc: "Individuals who are ambitious, extroverted, confident, and enjoy leading.", likes: raisec_likes["Enterprising"][this.indexFromScore(40)], dislikes: raisec_dislikes["Enterprising"][this.indexFromScore(40)]},
  //   {id: 5, title: "Conventional", opposite: "Atypical", description: RIASEC_TEXT["Conventional"][this.indexFromScore(30)], img: "/assets/cip/conventional/"+this.indexFromScore(30).toString()+".svg", percentage: 100-(30/40.0*100), showCard: false, useFaIcon: false,
  //     cardTitle: "The Organizers", cardDesc: "Individuals who are logical, organized, detail-oriented, and prefer structured environments.", likes: raisec_likes["Conventional"][this.indexFromScore(30)], dislikes: raisec_dislikes["Conventional"][this.indexFromScore(30)]}
  // ];

  back() {
    this.router.navigate(['/cip-questions/table/9']);
  }

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        if (profile.preferredName) {
          this.preferredName = profile.preferredName;
        }

      } else {
        this.profileService.getProfile().subscribe(profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.userProfile = profile;
          if (profile.preferredName) {
            this.preferredName = profile.preferredName;
          }

        });
      }
      this.getScores();
    });
  }

  getScores() {
    if (this.userProfile && this.userProfile.learnerID) {
      this.cipService.getScoresApi().subscribe(data => {
        this.scoreData = data;
        this.populateScores();
        this.sortScores();
      });
    }
  }

  sortScores() {
    if (this.CAREER_INTERESTS) {
      this.CAREER_INTERESTS.sort((a,b) => (a.percentage > b.percentage) ? 1 : ((b.percentage > a.percentage) ? -1 : 0));
    }

  }

  populateScores() {
    if (this.scoreData) {
      // if (this.scoreData['Person_R_total'] === 0.0) {
      //   this.scoreData['Person_R_total'] = 0.01;
      // }
      // if (this.scoreData['Person_I_total'] === 0.0) {
      //   this.scoreData['Person_I_total'] = 0.01;
      // }
      // if (this.scoreData['Person_A_total'] === 0.0) {
      //   this.scoreData['Person_A_total'] = 0.01;
      // }
      // if (this.scoreData['Person_S_total'] === 0.0) {
      //   this.scoreData['Person_S_total'] = 0.01;
      // }
      // if (this.scoreData['Person_E_total'] === 0.0) {
      //   this.scoreData['Person_E_total'] = 0.01;
      // }
      // if (this.scoreData['Person_C_total'] === 0.0) {
      //   this.scoreData['Person_C_total'] = 0.01;
      // }
      let x = 5;
      this.CAREER_INTERESTS = [
        {id: 0, title: 'Realistic', opposite: 'Idealistic', description: RIASEC_TEXT['Realistic'][this.indexFromScore(this.scoreData['Person_R_total'])], img: '/assets/cip/realistic/'+this.indexFromScore(this.scoreData['Person_R_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_R_total']/40.0*100), showCard: false, useFaIcon: false,
          cardTitle: 'The Do-ers', cardDesc: 'Individuals who are independent, practical, enjoy the outdoors, and prefer working with their hands.', likes: raisec_likes['Realistic'][this.indexFromScore(this.scoreData['Person_R_total'])], dislikes: raisec_dislikes['Realistic'][this.indexFromScore(this.scoreData['Person_R_total'])]},
        {id: 1, title: 'Investigative', opposite: 'Active', description: RIASEC_TEXT['Investigative'][this.indexFromScore(this.scoreData['Person_I_total'])], img: '/assets/cip/investigative/'+this.indexFromScore(this.scoreData['Person_I_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_I_total']/40.0*100), showCard: false, useFaIcon: false,
          cardTitle: 'The Thinkers', cardDesc: 'Individuals who are curious, analytical, logical, and enjoy problem solving.', likes: raisec_likes['Investigative'][this.indexFromScore(this.scoreData['Person_I_total'])], dislikes: raisec_dislikes['Investigative'][this.indexFromScore(this.scoreData['Person_I_total'])]},
        {id: 2, title: 'Artistic', opposite: 'Structured', description: RIASEC_TEXT['Artistic'][this.indexFromScore(this.scoreData['Person_A_total'])], img: '/assets/cip/artistic/'+this.indexFromScore(this.scoreData['Person_A_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_A_total']/40.0*100), showCard: false, useFaIcon: false,
          cardTitle: 'The Creators', cardDesc:'Individuals who are creative, expressive, imaginative, and like to work with ideas.', likes: raisec_likes['Artistic'][this.indexFromScore(this.scoreData['Person_A_total'])], dislikes: raisec_dislikes['Artistic'][this.indexFromScore(this.scoreData['Person_A_total'])]},
        {id: 3, title: 'Social', opposite: 'Private', description: RIASEC_TEXT['Social'][this.indexFromScore(this.scoreData['Person_S_total'])], img: '/assets/cip/social/'+this.indexFromScore(this.scoreData['Person_S_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_S_total']/40.0*100), showCard: false, useFaIcon: true,
          cardTitle: 'The Helpers', cardDesc: 'Individuals who are generous, helpful, and enjoy teamwork and helping others.', likes: raisec_likes['Social'][this.indexFromScore(this.scoreData['Person_S_total'])], dislikes: raisec_dislikes['Social'][this.indexFromScore(this.scoreData['Person_S_total'])]},
        {id: 4, title: 'Enterprising', opposite: 'Supporting', description: RIASEC_TEXT['Enterprising'][this.indexFromScore(this.scoreData['Person_E_total'])], img: '/assets/cip/enterprising/'+this.indexFromScore(this.scoreData['Person_E_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_E_total']/40.0*100), showCard: false, useFaIcon: false,
          cardTitle: 'The Persuaders', cardDesc: 'Individuals who are ambitious, extroverted, confident, and enjoy leading.', likes: raisec_likes['Enterprising'][this.indexFromScore(this.scoreData['Person_E_total'])], dislikes: raisec_dislikes['Enterprising'][this.indexFromScore(this.scoreData['Person_E_total'])]},
        {id: 5, title: 'Conventional', opposite: 'Unorthodox', description: RIASEC_TEXT['Conventional'][this.indexFromScore(this.scoreData['Person_C_total'])], img: '/assets/cip/conventional/'+this.indexFromScore(this.scoreData['Person_C_total']).toString()+'.svg', percentage: 100-(this.scoreData['Person_C_total']/40.0*100), showCard: false, useFaIcon: false,
          cardTitle: 'The Organizers', cardDesc: 'Individuals who are logical, organized, detail-oriented, and prefer structured environments.', likes: raisec_likes['Conventional'][this.indexFromScore(this.scoreData['Person_C_total'])], dislikes: raisec_dislikes['Conventional'][this.indexFromScore(this.scoreData['Person_C_total'])]}
      ];
    }
  }

  indexFromScore(score: number): number {
    if (score <= 14) {
      return 0;
    } else if (score <= 25) {
      return 1;
    } else {
      return 2;
    }

  }

  ngOnInit(): void {
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Career Interest Results',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Career Interest Results', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Career Interest Results', EventActionEnum.PAGE_VIEW, EventSectionEnum.CIP);

    this.getProfile();
  }

  toggleCard(cardIndex: number): void {
    if (this.CAREER_INTERESTS) {
      this.CAREER_INTERESTS[cardIndex].showCard = !this.CAREER_INTERESTS[cardIndex].showCard;

      this.CAREER_INTERESTS.forEach((trait, index) => {
        if (index !== cardIndex) {
          trait.showCard = false;
        }
      });
    }
  }

}
