import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnBoardingV2Component } from './on-boarding-v2.component';
import { YesNoV2Component } from './ob-profile/yes-no-v2/yes-no-v2.component';
import { SingleQuestionV2Component } from './ob-profile/single-question-v2/single-question-v2.component';
import { MultipleChoiceV2Component } from './ob-profile/multiple-choice-v2/multiple-choice-v2.component';
import { InfoBreakComponent } from './ob-profile/info-break/info-break.component';
import { IntroV2Component } from './ob-profile/intro-v2/intro-v2.component';
import { SharedModule } from '../shared/shared.module';
import { CardSelectionV2Component } from './ob-profile/card-selection-v2/card-selection-v2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { FooterV2Component } from './components/footer-v2/footer-v2.component';
import { SliderComponent } from './ob-profile/slider/slider.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FieldOfStudyComponent } from './ob-profile/field-of-study/field-of-study.component';
import {
  SkillSelectionOverviewComponent
} from './ob-skills/skill-selection-overview/skill-selection-overview.component';
import { ExperienceInputComponent } from './ob-skills/experience-input/experience-input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ResumeOptionComponent } from './ob-profile/resume-option/resume-option.component';
import { SkillTypeOverviewComponent } from './ob-skills/skill-selection-overview/components/skill-type-overview/skill-type-overview.component';
import { TopSkillsComponent } from './ob-skills/top-skills/top-skills.component';
import { WorkHistoryStartComponent } from './work-history/work-history-start.component';
import { LoadingPageComponent } from './ob-skills/loading-page/loading-page.component';
import { ResumeAttachmentComponent } from './ob-profile/resume-attachment/resume-attachment.component';
import { TransferableAndJobSpecificSkillsComponent } from './ob-skills/transferable-and-job-specific-skills/transferable-and-job-specific-skills.component';
import { LoadingModalComponent } from './ob-skills/skill-selection-overview/components/loading-modal/loading-modal.component';

@NgModule({
  declarations: [
    OnBoardingV2Component,
    YesNoV2Component,
    SingleQuestionV2Component,
    MultipleChoiceV2Component,
    InfoBreakComponent,
    IntroV2Component,
    CardSelectionV2Component,
    FooterV2Component,
    SliderComponent,
    FieldOfStudyComponent,
    SkillSelectionOverviewComponent,
    ExperienceInputComponent,
    ResumeOptionComponent,
    SkillSelectionOverviewComponent,
    SkillTypeOverviewComponent,
    TopSkillsComponent,
    WorkHistoryStartComponent,
    LoadingPageComponent,
    ResumeAttachmentComponent,
    TransferableAndJobSpecificSkillsComponent,
    LoadingModalComponent,
  ],
  exports: [
    TopSkillsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    RouterModule,
    NgxSliderModule,
    FontAwesomeModule,
  ]
})
export class OnBoardingV2Module { }
