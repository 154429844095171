import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-spinner',
  template: `
    <div class="donut-container" [style]="{width: width, height: height, margin: margin}">
      <img class="donut" src='./assets/icons/spinner.svg' alt="loading spinner" />
    </div>
  `,
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() width = '';
  @Input() height = '';
  @Input() margin = '';
}
