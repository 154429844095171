<div class="container">
  <span [class]=" titleColor !== 'lbb' ? 'video-text' : 'lbb' ">{{mainHeader}}</span>
  <span class="video-title-text">{{title}}</span>
  <span *ngIf="description" class="video-description-text" [innerHTML]="description">{{description}}</span>
  <div class="video-container">
    <ets-sne-video *ngIf="videoId && videoUrl" 
      [videoId]="videoId"
      [videoUrl]="videoUrl"
      [videoOnlyFlag]="true"
      [playOnce]="playOnce"
      [sharedVideo]="sharedVideo"
    >
    </ets-sne-video>
  </div>
</div>
