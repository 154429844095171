import { Component, Input, EventEmitter, Output} from '@angular/core';
import { SneMulChoiceOption } from 'src/app/types/sne';
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { HEADER_STATES } from '../../../shared/header/header.helper';

@Component({
  selector: 'ets-single-multi-choice-question',
  templateUrl: './sne-single-multi-choice-question.component.html',
  styleUrls: ['./sne-single-multi-choice-question.component.scss']
})
export class SneSingleMultiChoiceQuestionComponent {

  constructor() { }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  @Input() multiChoiceOptions: SneMulChoiceOption[] | undefined;
  @Input() headerQuestion: string | undefined = '';
  @Input() inputType: SNE_INPUT_TYPES | undefined;
  @Input() mobileCheck = false;
  @Input() centerAlign = false;
  @Output() selectSingleResponseEvent = new EventEmitter<string>();
  @Output() selectMultiResponseEvent = new EventEmitter<string>();

  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  selectSingleResponse(userChoice: string | undefined) {
    if (userChoice) {
      this.selectSingleResponseEvent.emit(userChoice);
    }

  };

  selectMultiResponse(userChoice: string | undefined) {
    if (userChoice) {
      this.selectMultiResponseEvent.emit(userChoice);
    }

  }
}
