import { AfterViewInit, Component } from '@angular/core';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { EventService } from '../../../services/event.service';
import { selectWorkHistoryInFlight } from '../../../store';
import { QUESTION_LIST_V2 } from 'src/app/data/sign-up-questions-v2';
import OB_PAGES from 'src/app/types/on-boarding-pages.types';

@Component({
  selector: 'ets-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent extends OnBoardingV2Base implements AfterViewInit {

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  QUESTION_LIST = QUESTION_LIST_V2;

  ngAfterViewInit() {
    this.store.select(selectWorkHistoryInFlight).subscribe(inFlight => {
      if (!inFlight) {
        // Find the index of the Skill Selection Overview page and route to it
        this.QUESTION_LIST.forEach((question, index) => {
          if (question.page === OB_PAGES.SKILL_SELECTION_OVERVIEW) {
            let skillSelectionUrl = 'on-boarding/skill-selection-overview/' + index;
            this.router.navigate([skillSelectionUrl]);
          }
        });
      }
    });
  }

}
