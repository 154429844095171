import { Component, OnDestroy, OnInit } from '@angular/core';
import { CIP_QUESTION_LIST, } from 'src/app/data/cip-answers';
import { CIP_ANSWERS, } from 'src/app/data/cip-answers';
import Question from 'src/app/types/question';
import QUESTION_TYPES, { QUESTION_SECTIONS } from 'src/app/types/question-types';
import { CipAnswer } from 'src/app/types/cip';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectCipAnswers,
  selectCipQuestion,
  selectCipQuestionIndex,
  selectCipQuestionList,
  selectCIPState,
  selectProfile,
  selectTriggerNextCipQuestion,
} from 'src/app/store';
import {
  setCipAnswers,
  setCipQuestion,
  setCipQuestionIndex,
  setCipQuestionList,
} from 'src/app/store/ui.actions';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { combineLatest, Subscription } from 'rxjs';
import { CipService } from 'src/app/services/cip.service';
import { ProfileService } from 'src/app/services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import Profile from 'src/app/types/profile';
import { environment } from 'src/environments/environment';
import { EventService } from '../services/event.service';
import { EventActionEnum, EventSectionEnum } from '../types/event';
import { skip, take } from 'rxjs/operators';
import { loadQuestions } from '../store/cip.actions';
import CIP_PAGES from '../types/cip-pages.types';


@Component({
  selector: 'ets-cip-controller',
  templateUrl: './cip-questions.component.html',
  styleUrls: ['./cip-questions.component.scss']
})
export class CipQuestionsComponent implements OnInit, OnDestroy {
  userProfile: Profile | undefined;
  questionList: Question[] | undefined = [];
  currentQuestionIndex: number | undefined;
  currentQuestion: Question | undefined;
  progress: string | undefined;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  sections = QUESTION_SECTIONS;
  scoredCip = false;
  displayProgressBar: boolean = false;

  $route: Subscription | undefined;
  $router: Subscription | undefined;

  constructor(
    private router: Router,
    private store: Store,
    private cipService: CipService,
    private profileService: ProfileService,
    private eventService: EventService,
    private route: ActivatedRoute
  ) {}

  findPageRoute = (question: Question, questionNum: number) => {
    // find the page associated with the type of question
    const {
      TABLE_QUESTION,
      INTRO,
    } = QUESTION_TYPES;
    let route;
    const { type } = question;
    const base = 'cip-questions/';
    switch (type) {
      case TABLE_QUESTION:
        route=`${base}table/${questionNum}`;
        this.displayProgressBar=true;
        break;
      case INTRO:
        route=`${base}intro/${questionNum}`;
        this.displayProgressBar=true;
        break;
      default:
        console.error('LoginSignUpController: Route could not be found.');
    }
    return route ? route: '';
  };

  nextQuestion() {
    // insert logic to move on to next question
    if (this.questionList && this.currentQuestionIndex !== undefined) {
      const newIndex = this.currentQuestionIndex + 1;
      if (newIndex <= (this.questionList.length - 1)) {
        const nextQuestion = this.questionList[this.currentQuestionIndex + 1];
        this.store.dispatch(setCipQuestion({ payload: nextQuestion }));
        this.store.dispatch(setCipQuestionIndex({ payload: newIndex }));
        this.router.navigate([this.findPageRoute(nextQuestion, newIndex)]);
      } else {
        this.store.select(selectCipAnswers).subscribe(cipAnswers => {
          if (cipAnswers && cipAnswers.length > 0) {
            this.cipService.callScoringApi(cipAnswers).subscribe(data => {
              this.router.navigate(['/career-interests']); // Ensures data is ready before read on next page
            });
          }
        });
        // this.store.dispatch(setCip({ payload: false}));
      }
    }
  };

  previousQuestion() {
    if (this.questionList && this.currentQuestionIndex !== undefined) {
      const newIndex = this.currentQuestionIndex - 1;
      if (newIndex >= 0) {
        const previousQuestion = this.questionList[this.currentQuestionIndex - 1];
        this.store.dispatch(setCipQuestion({ payload: previousQuestion }));
        this.store.dispatch(setCipQuestionIndex({ payload: newIndex }));
        this.router.navigate([this.findPageRoute(previousQuestion, newIndex)]);
      } else {
        this.router.navigate(['..']);
      }

    }
  };

  populateStore() {
    this.store.select(selectCIPState).subscribe(
      props => {
        const newCipQuestionList:Question[] = [...CIP_QUESTION_LIST];
        if (props.status === 'success') {
          newCipQuestionList.forEach((question, index, array) => {
            const desc = Object.getOwnPropertyDescriptor(question, 'choices') || {};
            if (question.type === QUESTION_TYPES.TABLE_QUESTION && desc.writable) {
              if (question.numbers) {
                question.choices = question.numbers?.map(number => {
                  const q = props.questions?.find(q => q.identifier === number);
                  if (q) {
                    return q.text;
                  } else {
                    return 'n/a ' + number;
                  }
                });
                array[index].choices = question.choices;
              }
            }
          });
          this.store.dispatch(setCipQuestionList({ payload: newCipQuestionList }));
          // this.store.dispatch(setCip({ payload: true}));
          this.populateCipAnswers();
          this.getQuestions();

          this.getCipQuestion();
          this.getCipQuestionIndex();
          this.calculateCompletion();
          this.onTriggerNextQuestion();
        }
      }
    );
  };

  populateCipAnswers() {
    this.store.dispatch(setCipAnswers({ payload: CIP_ANSWERS }));
    this.retrieveProgress();
  };

  getQuestions() {
    this.store.select(selectCipQuestionList)
      .subscribe(questions => {
        this.questionList = questions;
      });
  };

  getCipQuestionIndex() {
    this.store.select(selectCipQuestionIndex)
      .subscribe(index => this.currentQuestionIndex = index);
  };

  getCipQuestion() {
    this.store.select(selectCipQuestion)
      .subscribe(question => {
        this.currentQuestion = question;
      });
  };

  showNextButton(): boolean {
    const { INTRO, JUMP_TO_CIP } = QUESTION_TYPES;

    if (this.currentQuestion) {
      return this.currentQuestion.type !== INTRO
        && this.currentQuestion.type !== JUMP_TO_CIP;
    }
    return false;
  }

  calculateCompletion() {
    combineLatest([this.store.select(selectCipQuestionIndex), this.store.select(selectCipQuestionList)])
      .subscribe(([index, questionList]) => {
        if ((index !== undefined) && questionList) {
          this.progress = `${(index / questionList.length) * 100}%`;
        }

      });
  };

  onTriggerNextQuestion() {
    this.store.select(selectTriggerNextCipQuestion).pipe(skip(1)).subscribe(trigger => {
      this.nextQuestion();
      if (this.currentQuestionIndex !== undefined && this.questionList && (this.currentQuestionIndex < this.questionList.length - 2)) {
        this.saveProgress();
      }

    });
  }

  getUserProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
      }

    });
  }

  saveProgress() {
    this.store.select(selectCipAnswers).pipe(take(1)).subscribe(answers => {
      if (answers && answers.length > 1) {
        this.cipService.saveInProgress(answers).subscribe();
      }

    });
  }

  retrieveProgress() {
    this.cipService.getAnswersApi().subscribe(data => {
      if (data) {
        let answers: CipAnswer[] = [];
        let obj = JSON.parse(data);
        for (let item in obj) {
          let answer: CipAnswer = {
            questionNum: item,
            answer: Number(obj[item])
          };
          answers.push(answer);
        }
        this.store.dispatch(setCipAnswers({ payload: answers }));
      }
    });
  }

  initCip(): void {
    this.getUserProfile();
    this.populateStore();
  }

  checkForQuestionParam(): void {
    this.$route = this.route.firstChild?.params.subscribe((params: Params) => {
      const { questionNum } = params;
      const num = +questionNum;
      if (!isNaN(num)) {
        const selectedQuestion = this.questionList?this.questionList[num]:CIP_QUESTION_LIST[num];
        this.store.dispatch(setCipQuestion({ payload: selectedQuestion }));
        this.store.dispatch(setCipQuestionIndex({ payload: num }));
      }
    });
  }

  ngOnInit(): void {
    this.profileService.getProfile().subscribe(profile => {
      this.store.dispatch(setProfile({payload: profile}));
      this.initCip();
      this.store.dispatch(loadQuestions());
    });


    // executes after successful navigation from page to page
    this.$router = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.checkForQuestionParam();
      }

    });

    // this executes on page refresh
    this.checkForQuestionParam();

    this.store.select(selectCipQuestionIndex).subscribe(index => {
      if (index !== undefined && !isNaN(index)) {
        // if (QUESTION_LIST[index].type !== QUESTION_TYPE)
        if (environment.analyticsTrackingEnabled) {
          // Replace state for Google Analytics
          let stateObj = {
            pathname: window.location.pathname + '?page=' + CIP_QUESTION_LIST[index].page
          };
          history.replaceState(stateObj, 'CIP', window.location.pathname + '?page=' + CIP_QUESTION_LIST[index].page);
        }
        // Track the page view in AWS Athena
        this.eventService.buildEvent('CIP Quiz', EventActionEnum.PAGE_VIEW, EventSectionEnum.CIP);
      }
    });
  }

  ngOnDestroy() {
    this.$router?.unsubscribe();
    this.$route?.unsubscribe();
  }
}
