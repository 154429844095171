import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import CT_TYPES from 'src/app/types/critical-thinking-types';
import { ChatMessage, SNE_INPUT_TYPES } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';

@Component({
  selector: 'ets-ct-multi-choice-chat-log',
  templateUrl: './ct-multi-choice-chat-log.component.html',
  styleUrls: ['./ct-multi-choice-chat-log.component.scss']
})
export class CtMultiChoiceChatLogComponent extends SneCriticalThinkingBase implements OnInit {

  showModal = false;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  chatList: ChatMessage[] | undefined= [];
  drawerVisible = false;

  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  toggleModal() {
    this.showModal=!this.showModal;
  }

  toggleDrawer() {
    this.drawerVisible = !this.drawerVisible;
  }

  multiResponseQuestionHandler(response: string) {
    let arrIndex = this.multiResponse.indexOf(response);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(response);
      this.multiResponse.sort();
    }
  }

  singleReponseQuestionHandler(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }


  additionalParamProcess() {
    this.chatList = this.questionData?.chatMessages;
    this.drawerVisible = false;
  }
  

  ngOnInit(): void {
    this.processParams();
  }

}
