<ets-auth-split-screen [leftBackgroundColor]="APP_COLORS.mustardlight" [rightBackgroundColor]="APP_COLORS.white">
  <div class="left">
    <img src="../../../assets/sign-up/sign-up-image.svg" alt="Sign Up Photo" id="sign-in-photo">

    <div class="sign-up-content-wrapper">
      <div class="sign-up-list-title">
        Fulfill your potential
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Discover possibilities
          </p>
          <p class="sign-up-list-text">
            Unpack your experience and explore in-demand careers that match your skills today.
          </p>
        </div>
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Prepare for opportunities
          </p>
          <p class="sign-up-list-text">
            Take quizzes that reveal what makes you unique and consider curated training options.
          </p>
        </div>
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Land the career you deserve
          </p>
          <p class="sign-up-list-text">
            Learn how to convey your skills and showcase yourself to employers.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="right">
    <!-- Verify Account -->
    <div class="confirmation-content-wrapper">
      <div class="header-info-wrapper">
        <h1 class="sign-in-header">
          Verify your account
        </h1>
        <p class="sent-code-text">
          We sent a code to {{email ? email : 'your email'}}. Please enter it below to verify your account.
        </p>
      </div>
    
      <div class="login-form-wrapper">
        <form [formGroup]="confirmationCodeForm" (ngSubmit)="confirmSignUp()">
          <!-- Code -->
          <div class="input-content-wrapper">
            <label for="code1" class="input-label">Verification code</label>
            <div class="confirmation-codes-wrapper">
              <!-- [ngClass]="{'input-field-wrapper-error': confirmationCodeForm.controls['code'].invalid &&
              (confirmationCodeForm.controls['code'].touched || confirmationCodeForm.controls['code'].dirty)}" -->
              <div class="input-field-wrapper" >
                <input type="text" id="code1" name="code" class="sign-in-input" formControlName="code1" inputmode="numeric" pattern="[0-9]">
              </div>
              <div class="input-field-wrapper" >
                <input type="text" id="code2" name="code" class="sign-in-input" formControlName="code2" inputmode="numeric" pattern="[0-9]">
              </div>
              <div class="input-field-wrapper" >
                <input type="text" id="code3" name="code" class="sign-in-input" formControlName="code3" inputmode="numeric" pattern="[0-9]">
              </div>
              <div class="input-field-wrapper" >
                <input type="text" id="code4" name="code" class="sign-in-input" formControlName="code4" inputmode="numeric" pattern="[0-9]">
              </div>
              <div class="input-field-wrapper" >
                <input type="text" id="code5" name="code" class="sign-in-input" formControlName="code5" inputmode="numeric" pattern="[0-9]">
              </div>
              <div class="input-field-wrapper" >
                <input type="text" id="code6" name="code" class="sign-in-input" formControlName="code6" inputmode="numeric" pattern="[0-9]">
              </div>
            </div>
            <!-- <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon'
                 *ngIf="confirmationCodeForm.controls['code'].invalid &&
                  (confirmationCodeForm.controls['code'].touched || confirmationCodeForm.controls['code'].dirty)"
            >
            <div *ngIf="confirmationCodeForm.controls['code'].invalid && (confirmationCodeForm.controls['code'].touched || confirmationCodeForm.controls['code'].dirty)">
              <div *ngIf="confirmationCodeForm.controls['code'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div> -->
          </div>
    
          <!-- Error Handling -->
          <p class="error-msg" *ngIf='confirm_invalidCode'>Invalid or expired code entered. Please try again.</p>
          <p class="error-msg" *ngIf='confirm_userAlreadyConfirmed'>
            User has already been confirmed.
            <button class="sign-up" (click)="gotoSignIn()">Sign In</button>
          </p>
          <p class="error-msg" *ngIf='confirm_unknownErrorFlag'>An unknown error occurred. Please try again.</p>
          
          <div class="submit-container">
            <input type="submit" id="submit" name="submit" value="VERIFY ACCOUNT" class="login-button" [disabled]="!confirmationCodeForm.valid">
          </div>
        </form>
      </div>
      
    
      <p class="need-account">
        Didn't receive an email? 
        <button class="sign-up" 
          (click)="resendConfirmationCode()"
          [ngClass]="{'code-sent-success': resendCodeText === 'Code sent'}"
        >
          {{resendCodeText}}
        </button>
      </p>
    </div>
  </div>
</ets-auth-split-screen>

