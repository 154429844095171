<div class="container" *ngIf="currentQuestion" aria-label="work history" role="alert">
    <div class="right">
        <img 
            class="image"
            src="../../../assets/on-boarding/{{currentQuestion.picFileName}}.png"
            alt="work-history-image">
    </div>

    <div class="left">
        <div class="info-wrapper">
            <h1 class="title" role="alert">{{question}}</h1>
            <h2 class="info">{{info}}</h2>
            <div class="duration-box">
                <div class="duration-icon-box">
                    <img src="/assets/duration-timer.svg" alt="duration" class="duration-icon" />
                </div>
                <span id="duration" [innerHtml]="duration"></span>
            </div>
        </div>

        <button class="button" (click)="onClick()">Next Question</button>
    </div>
</div>