import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidator } from '../../validators/password-validator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
// import { LearnerCareerService } from '../../services/learner-career.service';
import { setProfile } from '../../store/profile.actions';
import { HEADER_STATES } from '../../shared/header/header.helper';
import { faEye, faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { setOpenSesame, setEmail } from '../../store/auth.actions';
import { APP_COLORS } from 'src/global-styles';

// Ambient declaration for Facebook Pixel to allow compiler to be happy... fbq will be defined by runtime
declare const fbq: any;

@Component({
  selector: 'ets-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  showTermsAndConditions = false;
  keepMeUpdated = true;
  user: CognitoUserInterface | undefined;
  authState!: AuthState;
  HEADER_STATES = HEADER_STATES;
  faEye = faEye;
  faCircle = faCircle;
  faCheckCircle = faCheckCircle;
  APP_COLORS = APP_COLORS;
  passwordFocused = false;

  // Flag to denote whether the user is signed in
  userSignedInFlag = false;

  // Error state flags used to display error messages on the UI
  signUp_userAlreadyExists = false;
  signUp_unknownErrorFlag = false;

  // Flags to display various elements on the UI
  signingInSpinnerFlag = false;

  // Forms for all the various sign in/up pages
  signUpForm = new FormGroup({
    given_name: new FormControl('', [Validators.required]),
    family_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(99),
      PasswordValidator.hasNumber,
      PasswordValidator.hasLower,
      PasswordValidator.hasUpper,
      PasswordValidator.hasSpecial
    ]),
  });

  // These fields are used to transfer the username/password fields between forms/methods
  username!: string;
  password!: string;

  // Field to capture the UTM parameters if they're present
  urlQueryString!: string;

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    private authService: AuthService,
    private profileService: ProfileService,
    // private learnerCareerService: LearnerCareerService
  ) {}

  ngOnInit(): void {
    // Logic to capture UTM codes in the query string if they're present
    this.urlQueryString = window.location.search;
    if (this.urlQueryString && this.urlQueryString.length > 1) {
      this.urlQueryString = this.urlQueryString.substring(1);
    }
  }

  getProfileAndRoute() {
    this.profileService.getProfile().subscribe(profile => {
      this.store.dispatch(setProfile({ payload: profile }));
      // this.learnerCareerService.populateLearnerCareers().subscribe();

      if (profile.onboardingComplete) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/on-boarding']);
      }

    });
  }

  // Method to go to sign up page
  gotoSignIn() {
    this.router.navigate(['/auth/sign-in']);
  }

  async signUp() {
    try {
      this.resetUiErrorFlags();
      this.username = this.signUpForm.controls.email.value;
      this.password = this.signUpForm.controls.password.value;

      // Call Sign Up with the values from the sign up form - await is needed to ensure the call completes successfully prior to
      // setting the userSignedInFlag
      await this.authService.signUp(
        this.username,
        this.password,
        this.signUpForm.controls.email.value,
        this.signUpForm.controls.given_name.value,
        this.signUpForm.controls.family_name.value,
        this.keepMeUpdated,
        this.urlQueryString
      );
      fbq('trackCustom', 'SignUp');
      this.store.dispatch(setEmail({ payload: this.signUpForm.controls.email.value }));
      this.store.dispatch(setOpenSesame({ payload: this.password }));
      this.router.navigate(['/auth/confirmation-code']);
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'User already exists':
          case 'PreSignUp failed with error User already exists.':
            this.signUp_userAlreadyExists = true;
            break;
          default:
            this.signUp_unknownErrorFlag = true;
        }
      }
    }
  }

  async resendConfirmationCode() {
    try {
      this.resetUiErrorFlags();

      // Call resend to send the code email
      await this.authService.resendConfirmationCode(this.username);
    } catch (error) {
      console.log('error resending the confirmation code', error);
    }
  }

  toggleKeepMeUpdated() {
    this.keepMeUpdated = !this.keepMeUpdated;
  }

  toggleShowTermsAndConditions() {
    this.showTermsAndConditions = !this.showTermsAndConditions;
  }

  focusPassword(value: boolean) {
    this.passwordFocused = value;
  }

  // Method to show the passwords being typed in the password fields
  toggleShowPassword(id: string) {
    // Casting to an input element because this method will only be called from input elements
    let inputField = <HTMLInputElement>document.getElementById(id);
    if (inputField) {
      if (inputField.type === 'password') {
        inputField.type = 'text';
      } else {
        inputField.type = 'password';
      }

    }
  }

  showControls() {
    console.log('controls', this.signUpForm.controls);
  }

  // Method to reset UI error flags to false (hide them)
  resetUiErrorFlags() {
    this.signUp_userAlreadyExists = false;
    this.signUp_unknownErrorFlag = false;
  }
}
