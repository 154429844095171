import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DashboardProfile } from '../../../../types/dashboard';

@Component({
  selector: 'ets-foundation',
  templateUrl: './foundation.component.html',
  styleUrls: ['./foundation.component.scss']
})
export class FoundationComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() dashboardProfile: DashboardProfile | undefined;

  dashboardProfileLength: number = 0;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dashboardProfile']) {
      if (this.dashboardProfile?.aspiration.length) {
        this.dashboardProfileLength = this.dashboardProfile?.aspiration.length;
      }
    }
  }
}
