<ets-header [isBGWhite]="true"
            [headerState]="headerState"
            [showBackButton]="false"
>
</ets-header>
<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
  [sneSection]="'left-oc'"
>
  <div class="left">
    <ets-sne-ques-video 
      [videoId]="questionData?.headerVideo?.id"
      [mainHeader]="questionData?.headerVideo?.mainHeader"
      [title]="questionData?.headerVideo?.title"
      [videoUrl]="questionData?.headerVideo?.url"
      [description]="questionData?.headerVideo?.description"
      [playOnce]="questionData?.headerVideo?.playOnce"
      [sharedVideo]="questionData?.sharedVideo"
      >
    </ets-sne-ques-video>
  </div>
  <div class="right">
    <ets-single-multi-choice-question
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType">
    </ets-single-multi-choice-question>
  </div>

</ets-sne-split-screen>
<!-- Params for progress bar from base class -->
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
  : multiResponse.length !== questionData?.numSelectOptions"
>
  <ets-single-multi-choice-question
    [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    (selectMultiResponseEvent)="multiResponseHandler($event)"
    [headerQuestion]="questionData?.header"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true">
  </ets-single-multi-choice-question>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video *ngIf="instructionModal?.customContent[0].url"
                   [videoUrl]="instructionModal?.customContent[0].url"
                   [videoId]="instructionModal?.customContent[0].id"
                   [videoOnlyFlag]="true">
    </ets-sne-video>
  </div>
</ets-modal>
