import { Component, OnInit } from '@angular/core';
import { APP_COLORS } from 'src/global-styles';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';
import { ActivatedRoute, Router } from '@angular/router';
import { Star, StarStatus } from 'src/app/types/sne';

@Component({
  selector: 'ets-cps-interstitial',
  templateUrl: './cps-interstitial.component.html',
  styleUrls: ['./cps-interstitial.component.scss']
})
export class CpsInterstitialComponent extends SneCollaborativeProblemSolvingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router) {
    super(route, router);
  }

  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  endInterstitial = false;
  currentModalPage = 0;

  // Stars for Negotiation Overview modal
  jordanStars: Star[] = [
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.EMPTY
    },
    {
      status: StarStatus.EMPTY
    },
  ];
  clientStars: Star[] = [
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.FILLED
    },
    {
      status: StarStatus.EMPTY
    },
    {
      status: StarStatus.EMPTY
    },
  ];
  StarStatus = StarStatus;

  ngOnInit() {
    // Get the route params to determine if it's the end interstitial
    this.route.params.subscribe(params => {
      const { questionNum, section } = params;
      this.endInterstitial = questionNum === 'end';
    });
    this.processParams();

  }

  updateCurrentPage(page:any){
    this.currentModalPage = page;
  }
}
