import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProfile } from 'src/app/store';
import Profile from 'src/app/types/profile';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { Router } from '@angular/router';
import { AMIABLE_VERSIONS, APPROACHABLE_VERSIONS, CONSCIENTIOUSNESS_VERSIONS,
  OPEN_MINDED_VERSIONS, PersonalityTraits, TEMPERAMENT_VERSIONS } from './facets-personality-results-helper';
import SkillPill from 'src/app/types/skill-pill';
import { FacetsAssessmentData, FacetsRequestBody, FacetsScoreResponse } from 'src/app/types/facets';
import { FacetsService } from 'src/app/services/facets.service';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-facets-personality-results',
  templateUrl: './facets-personality-results.component.html',
  styleUrls: ['./facets-personality-results.component.scss']
})
export class FacetsPersonalityResultsComponent implements OnInit, OnDestroy {

  constructor(private store: Store, private router: Router, private facetsService: FacetsService, private profileService: ProfileService, private eventService: EventService) { }

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  headerState: HEADER_STATES = HEADER_STATES.BACK;

  userProfile!: Profile;
  preferredName: string = '';
  resultsError: boolean = false;
  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];

  conscientiousSkillPills: SkillPill[] = [
    {
      skillName: 'Perseverance',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Responsibility',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Organization',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Self-Discipline',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Abidance',
      skillLevel: 0,
      lineBreak: false
    }
  ];

  temperamentSkillPills: SkillPill[] = [
    {
      skillName: 'Disposition',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Resiliency',
      skillLevel: 0,
      lineBreak: false
    }
  ];

  approachableSkillPills: SkillPill[] = [
    {
      skillName: 'Sociability',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Leadership',
      skillLevel: 0,
      lineBreak: false
    }
  ];

  amiableSkillPills: SkillPill[] = [
    {
      skillName: 'Collaboration',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Trust',
      skillLevel: 0,
      lineBreak: false
    }
  ];

  openMindedSkillPills: SkillPill[] = [
    {
      skillName: 'Creativity',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Curiosity',
      skillLevel: 0,
      lineBreak: false
    },
    {
      skillName: 'Artistic',
      skillLevel: 0,
      lineBreak: false
    }
  ];

  standOutSkillPills: SkillPill[] = [];
  onTargetSkillPills: SkillPill[] = [];
  growthAreasSkillPills: SkillPill[] = [];
  traitSectionColumns = 0; // This number will represent the number of the 3 above arrays that contain values

  // The personalityTraitCardVersions are ordered from low (index 0) to medium (index 1) to high (index 2).
  // reordered Personality Traits IDs to match design, old order: 2,0,3,1,4
  ORIGINAL_PERSONALITY_TRAITS: PersonalityTraits[] = [
    {id: 0, rating: 0, title: 'Approachability', img: '/assets/facets/FACETS_Approachable.png', skillPills: this.approachableSkillPills, personalityTraitCardVersion: APPROACHABLE_VERSIONS[1]},
    {id: 1, rating: 0, title: 'Conscientiousness', img: '/assets/facets/FACETS_Conscientious.png', skillPills: this.conscientiousSkillPills, personalityTraitCardVersion: CONSCIENTIOUSNESS_VERSIONS[2]},
    {id: 2, rating: 0, title: 'Teamwork', img: '/assets/facets/FACETS_Collaborative.png', skillPills: this.amiableSkillPills, personalityTraitCardVersion: AMIABLE_VERSIONS[1]},
    {id: 3, rating: 0, title: 'Temperament', img: '/assets/facets/FACETS_Temperament.png', skillPills: this.temperamentSkillPills, personalityTraitCardVersion: TEMPERAMENT_VERSIONS[2]},
    {id: 4, rating: 0, title: 'Mindfulness', img: '/assets/facets/FACETS_OpenMinded.png', skillPills: this.openMindedSkillPills, personalityTraitCardVersion: OPEN_MINDED_VERSIONS[0]}
  ]

  personalityTraits!: PersonalityTraits[];

  back() {
    this.router.navigate(['..']);
  }

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        if (profile.preferredName) {
          this.preferredName = profile.preferredName;
        }

      } else {
        this.subArr.push(this.profileService.getProfile().subscribe(profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.userProfile = profile;
          if (profile.preferredName) {
            this.preferredName = profile.preferredName;
          }

        }));
      }
    });
  }

  getFacetsScore(scoreRequest: FacetsRequestBody) {
    // scoreRequest.sessionID = "no";
    this.subArr.push(this.facetsService.getScoreResults(scoreRequest).subscribe({
      next: scoreResults => {
        let response: FacetsScoreResponse = scoreResults;

        // Process results if there wasn't an error
        if (!response.error) {
          this.processScoreResponse(response);
        } else {
          this.resultsError = true;
        }

      },
      error: err => {
        // Error handling for when we can't get the results
        this.resultsError = true;
      }
    }));
  }

  handleScoreError() {
    this.resultsError = false;
    this.router.navigate(['/dashboard']);
  }

  ngOnInit(): void {
    document.title = 'Apprize | Power Skills';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Power Skills Personality Results',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Power Skills Personality Results', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Power Skills Personality Results', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);

    this.getProfile();

    let facetsSessionId = localStorage.getItem('facetsSessionId');
    if (facetsSessionId) {
      let scoreRequest: FacetsRequestBody = {
        sessionID: facetsSessionId,
        action: 'score'
      };
      this.getFacetsScore(scoreRequest);
    } else {
      this.getMostRecentlyCompletedFacetsAssessment();
    }
    // this.getFacetsScore(scoreRequest);

  }

  ngOnDestroy() {
    this.subArr.forEach(sub => {
      sub.unsubscribe();
    });
  }

  populateTraitSectionColumns() {
    if (this.growthAreasSkillPills.length > 0) {
      this.traitSectionColumns++;
    }

    if (this.onTargetSkillPills.length > 0) {
      this.traitSectionColumns++;
    }

    if (this.standOutSkillPills.length > 0) {
      this.traitSectionColumns++;
    }

  }

  // Function that insert blank Skill Pills into the array to help with formatting of the Trait Section
  insertTraitSectionSkillPillLineBreaks() {
    let counter = 0;
    let lineBreakPill = {
      skillName: '',
      skillLevel: 0,
      lineBreak: true
    };

    for (let i = 0; i < this.growthAreasSkillPills.length; i++) {
      let pill = this.growthAreasSkillPills[i];

      // Only increment the counter if it's not a line break pill
      if (!pill.lineBreak) {
        counter++;
      }

      // Insert line break
      if (counter === 2) {
        if (this.growthAreasSkillPills.length > i + 1) {
          this.growthAreasSkillPills.splice(i + 1, 0, lineBreakPill);
        }

        counter = 0; // reset counter
      }
    }

    counter = 0; // reset counter
    for (let i = 0; i < this.onTargetSkillPills.length; i++) {
      let pill = this.onTargetSkillPills[i];

      // Only increment the counter if it's not a line break pill
      if (!pill.lineBreak) {
        counter++;
      }

      // Insert line break
      if (counter === 2) {
        if (this.onTargetSkillPills.length > i + 1) {
          this.onTargetSkillPills.splice(i + 1, 0, lineBreakPill);
        }

        counter = 0; // reset counter
      }
    }

    counter = 0; // reset counter
    for (let i = 0; i < this.standOutSkillPills.length; i++) {
      let pill = this.standOutSkillPills[i];

      // Only increment the counter if it's not a line break pill
      if (!pill.lineBreak) {
        counter++;
      }

      // Insert line break
      if (counter === 2) {
        if (this.standOutSkillPills.length > i + 1) {
          this.standOutSkillPills.splice(i + 1, 0, lineBreakPill);
        }

        counter = 0; // reset counter
      }
    }
  }

  populateTraitSectionSkillPills(skillPill: SkillPill) {
    if (skillPill.skillLevel === 1) {
      this.growthAreasSkillPills.push(skillPill);
    } else if (skillPill.skillLevel === 2) {
      this.onTargetSkillPills.push(skillPill);
    } else if (skillPill.skillLevel === 3) {
      this.standOutSkillPills.push(skillPill);
    }

  }

  processScoreResponse(scoreResponse: FacetsScoreResponse) {
    const copyTraits = [...this.ORIGINAL_PERSONALITY_TRAITS];
    scoreResponse.groups.forEach(group => {
      switch (group.name) {
      // Abidance
        case 'ACC_ABID':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.conscientiousSkillPills[4].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.conscientiousSkillPills[4]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Amiable = Agreeableness
        case 'ACC_AGRE':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[2].rating = rating;
              copyTraits[2].personalityTraitCardVersion = AMIABLE_VERSIONS[rating-1];
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Artistic
        case 'ACC_ARTI':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.openMindedSkillPills[2].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.openMindedSkillPills[2]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Collaboration
        case 'ACC_COLL':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.amiableSkillPills[0].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.amiableSkillPills[0]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Conscientiousness = Conscientiousness
        case 'ACC_CONSC':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[1].rating = rating;
              copyTraits[1].personalityTraitCardVersion = CONSCIENTIOUSNESS_VERSIONS[rating-1];
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Creativity
        case 'ACC_CREA':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.openMindedSkillPills[0].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.openMindedSkillPills[0]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Curiousity
        case 'ACC_CURI':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.openMindedSkillPills[1].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.openMindedSkillPills[1]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Temperament = Emotional Stability
        case 'ACC_EMOT':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[3].rating = rating;
              copyTraits[3].personalityTraitCardVersion = TEMPERAMENT_VERSIONS[rating-1];
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Even-temperedness = Disposition
        case 'ACC_EVEN':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.temperamentSkillPills[0].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.temperamentSkillPills[0]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Approachability = Extraversion
        case 'ACC_EXTR':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[0].rating = rating;
              copyTraits[0].personalityTraitCardVersion = APPROACHABLE_VERSIONS[rating-1];
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Leadership
        case 'ACC_LEAD':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.approachableSkillPills[1].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.approachableSkillPills[1]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Open-Mindedness = Openness
        case 'ACC_OPEN':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              copyTraits[4].rating = rating;
              copyTraits[4].personalityTraitCardVersion = OPEN_MINDED_VERSIONS[rating-1];
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Organization
        case 'ACC_ORGA':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.conscientiousSkillPills[2].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.conscientiousSkillPills[2]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Perseverance
        case 'ACC_PERS':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.conscientiousSkillPills[0].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.conscientiousSkillPills[0]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Resiliency
        case 'ACC_RESI':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.temperamentSkillPills[1].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.temperamentSkillPills[1]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Responsibility
        case 'ACC_RESP':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.conscientiousSkillPills[1].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.conscientiousSkillPills[1]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Self-Discipline
        case 'ACC_SELF':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.conscientiousSkillPills[3].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.conscientiousSkillPills[3]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Sociability
        case 'ACC_SOCI':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.approachableSkillPills[0].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.approachableSkillPills[0]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        // Trust
        case 'ACC_TRUS':
          for (let param of group.params) {
            if (param.value === 'RatingValue') {
              let rating = Number.parseInt(param.text);
              this.amiableSkillPills[1].skillLevel = rating;
              this.populateTraitSectionSkillPills(this.amiableSkillPills[1]);
              break; // break out of loop once we get the rating
            }
          }
          break;
        default:
          console.log('Group name not found');
      }
    });

    this.populateTraitSectionColumns(); // Get the number of trait section columns
    this.insertTraitSectionSkillPillLineBreaks(); // Insert line breaks between skill pills

    // Sort the final results from highest to lowest
    copyTraits.sort((a,b) => (a.rating < b.rating) ? 1 : ((b.rating < a.rating) ? -1 : 0));
    this.personalityTraits = copyTraits;
  }

  // Gets the most recently completed FACETS assessment and sets the session ID in localStorage
  getMostRecentlyCompletedFacetsAssessment() {
    let getAssessmentRequest: FacetsRequestBody = {
      action: 'getAssessment'
    };

    let recentAssessment: FacetsAssessmentData;

    this.subArr.push(this.facetsService.getAssessments(getAssessmentRequest).subscribe({
      next: response => {
        let assessments = response.assessments;
        // The array is in order by time, so the last completed assessment will be the most recent one
        assessments.forEach(assessment => {
          if (assessment.completed) {
            recentAssessment = assessment;
          }

        });
        this.processScoreResponse(recentAssessment.resultJSON);
      },
      error: err => {
        // error handling
      }
    }));
  }

  finish() {
    this.router.navigate(['/dashboard']);
  }

}
