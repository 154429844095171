import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { selectProfile } from 'src/app/store';
import Profile from 'src/app/types/profile';
import { HttpResponse } from '@angular/common/http';
import { setProfile } from '../../../store/profile.actions';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private learnerService: ProfileService, private store: Store, private eventService: EventService) { }

  getErrorMessage: string = '';
  postErrorMessage: string = '';
  sub!: Subscription;
  userProfile!: Profile;
  profileSettingsForm = new FormGroup({
    fullName: new FormControl('',[Validators.required]),
    preferredName: new FormControl('',[Validators.required]),
    personalWebsite: new FormControl('')
  });


  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        this.setInitialFormValues();
      }
    });
  }

  ngOnInit(): void {
    document.title = 'Apprize | User Profile';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Profile',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Profile', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Profile', EventActionEnum.PAGE_VIEW, EventSectionEnum.USER_PROFILE);

    this.getProfile();
  }

  setInitialFormValues(): void {
    if (this.userProfile) {
      this.profileSettingsForm.controls.fullName.setValue(this.userProfile.fullName);
      this.profileSettingsForm.controls.preferredName.setValue(this.userProfile.preferredName);
      this.profileSettingsForm.controls.personalWebsite.setValue(this.userProfile.personalWebsite);

    }
  }

  updateProfile(savedProfile: Profile): void {
    savedProfile.fullName = this.profileSettingsForm.controls.fullName.value;
    savedProfile.preferredName = this.profileSettingsForm.controls.preferredName.value;
    savedProfile.personalWebsite = this.profileSettingsForm.controls.personalWebsite.value;
  }

  saveChanges(): void {
    // Populate all the fields on the learner object from the form group
    if (this.userProfile) {
      let newProfile: Profile = {...this.userProfile};
      this.updateProfile(newProfile);
      this.sub = this.learnerService.saveProfile(newProfile)
        .subscribe((response: HttpResponse<{ learner: Profile }>) => {
          const profile = response.body ? response.body.learner : undefined;
          // Commenting this out for now until the backend sends back the expected response
          // if (profile) {
          this.store.dispatch(setProfile({payload: newProfile}));
        // }
        });
    }
  }

}
