<div class="container">
  <div class="heading">
    <span class="module-title-desktop">GETTING STARTED</span>
    <span class="module-header">Explore careers and trainings</span>
    <p>
      Figuring out a destination will reveal the steps it takes to reach it.
      Check out potential careers and trainings, then let us know which ones interest you most.
    </p>
  </div>

  <div class="explore-careers">
    <div>
      <span class="saved-careers-tooltip">my saved careers</span>
      <ets-tooltip [text]="tip"></ets-tooltip>
    </div>

    <!-- Tooltip goes here, waiting on Kyle to see if his bug will also fix the tooltip from showing up -->

    <ets-outline-button [text]="'EXPLORE ALL CAREERS'" [color]="APP_COLORS.uiaction" (clickEvent)="openCareers()"></ets-outline-button>
  </div>

  <div class="career-cards">
    <ets-career-card
      *ngFor="let career of careerList; let id = index"
      [careerTitle]="career.careerTitle"
      [matchScore]="+career.matchScore"
      [id]="career.careerID"
      [arrLength]="careerList.length"
    >
    </ets-career-card>
  </div>
</div>
