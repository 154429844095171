import {
  SneQuestion,
  SNE_INPUT_TYPES,
  SneMulChoiceOption,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES, { QUESTION_SECTIONS } from 'src/app/types/question-types';
import OC_TYPES from 'src/app/types/oral-communication-types';

const question_3_answers: SneMulChoiceOption[] = [
  {
    userOption: 'Satisfactory',
    userLetter: 'A',
  },
  {
    userOption: 'Needs Improvement',
    userLetter: 'B'
  }
];

export const OCInformative1Data: Map<string, SneQuestion> = new Map([
  [
    'intro', 
    {
      questionType: QUESTION_TYPES.SNE_INTRO,
      title: 'Oral Communication',
      info: 'In this section, we will guide you through six different tasks. Each task will help you understand how you might share and receive spoken information and how that applies to the careers that interest you.\n\nReady to get started?',
      image: '/assets/sne/oc/oc-intro.png',
      buttonText: 'BEGIN!',
    },
  ],
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Interpreting a Presentation',
      info: 'This task will give you insight into how you might interpret and understand spoken information, and we will use what we learn together to enhance your Skills Profile. <br><br> You will play one or more videos and answer corresponding questions.  In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish. Find a quiet place where you can focus, and select “Next” to begin.',
      questionIndex: 1,
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      image: '/assets/section-4/sne-interstitial/informative-1.svg',
      buttonText: 'Next',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/605595533',
        id: '605595533',
        title: 'Interpreting a Presentation',
      },
      header: 'The main purpose of the presentation is to provide information about',
      singleMulChoiceOptions: [
        {
          userOption: 'New rules for using personal leave time',
          userLetter: 'A',
        },
        {
          userOption: 'Upcoming volunteer events sponsored by the company',
          userLetter: 'B',
        },
        {
          userOption: 'How to donate part of your paycheck to a local charity',
          userLetter: 'C',
        },
        {
          userOption: 'The accomplishments of some employees who volunteer on behalf of the company',
          userLetter: 'D',
        },
      ],
      submitId: 'item_1'
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      splitScreen: false,
      header:
        'In which video clip does the speaker provide technical details that are probably <span class=\'convertToBlue\'>not</span> relevant to the audience and the goals of the presentation?',
      headerVideo: {
        url: 'https://vimeo.com/605595533',
        id: '605595533',
        title: 'Interpreting a Presentation',
      },
      videos: [
        {
          url: 'https://vimeo.com/617380334',
          id: '617380334',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617380241',
          id: '617380241',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617380008',
          id: '617380008',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617379842',
          id: '617379842',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_LIST_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/605595533',
        id: '605595533',
        title: 'Interpreting a Presentation',
      },
      header: 'Consider the speaker’s use of nonverbal cues and body language. For each characteristic, determine if it was <span class=\'convertToBlue\'>satisfactory</span> or <span class=\'convertToBlue\'>needs improvement</span>.',
      multipleMulChoiceOptions: [
        {
          question: 'The speaker’s use of hand gestures to emphasize important information.',
          mulChoiceOptions: [...question_3_answers],
        },
        {
          question: 'The speaker’s use of eye contact to engage the audience.',
          mulChoiceOptions: [...question_3_answers],
        },
        {
          question: 'The speaker’s use of pauses and filler words.',
          mulChoiceOptions: [...question_3_answers],
        }
      ],
      numSelectOptions: 3,
      submitId: 'item_3'
    },
  ],
  // [
  //   'question-4',
  //   {
  //     questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
  //     header:
  //       'At the end of the presentation, an audience member asks, “Where can we find more information about how to sign up to participate?” <br/> <br/> This is an appropriate follow-up question because it _________ and _______.<br/><br/> Select the <span class=\'convertToBlue\'>two</span> best options to complete the sentence.',
  //     inputType: SNE_INPUT_TYPES.CHECKBOX,
  //     numSelectOptions: 2,
  //     headerVideo: {
  //       url: 'https://vimeo.com/605595533',
  //       id: '605595533',
  //       title: 'Interpreting a Presentation',
  //     },
  //     singleMulChoiceOptions: [
  //       {
  //         userOption:
  //           'reveals a weakness in the presenter’s use of body language',
  //         userLetter: 'A',
  //       },
  //       {
  //         userOption:
  //           'requests information that will be useful for the audience',
  //         userLetter: 'B',
  //       },
  //       {
  //         userOption:
  //           'demonstrates that the audience member did not pay attention ',
  //         userLetter: 'C',
  //       },
  //       {
  //         userOption:
  //           'relates to and expands on the information provided by the speaker',
  //         userLetter: 'D',
  //       },
  //       {
  //         userOption:
  //           'critiques the speaker’s presentation style and the choice of details to share',
  //         userLetter: 'E',
  //       },
  //     ],
  //     submitId: 'item_4',
  //   },
  // ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.MADLIBS_FULL,
      title:
        'At the end of the presentation, an audience member asks, “Where can we find more information about how to sign up to participate?"',
      subtitle: '<br>Select the <span class=\'convertToBlue\'>two</span> best options to complete the sentence.',
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      numSelectOptions: 2,
      headerVideo: {
        url: 'https://vimeo.com/605595533',
        id: '605595533',
        title: 'Interpreting a Presentation',
      },
      madlibsSingleQuestion: {
        prompts: [
          {
            prompt: 'This is an appropriate follow-up question because it',
          },
          { 
            prompt: 'and' 
          }
        ],
        choices: [
          {
            option: 'reveals a weakness in the presenter’s use of body language',
            letter: 'A',
          },
          {
            option: 'requests information that will be useful for the audience',
            letter: 'B',
          },
          {
            option: 'demonstrates that the audience member did not pay attention ',
            letter: 'C',
          },
          {
            option: 'relates to and expands on the information provided by the speaker',
            letter: 'D',
          },
          {
            option: 'critiques the speaker’s presentation style and the choice of details to share',
            letter: 'E',
          },
        ],
      },
      submitId: 'item_4'
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      header:
        'The speaker has asked for some feedback about her presentation. Which feedback is most appropriate for helping the speaker make improvements?',
      inputType: SNE_INPUT_TYPES.RADIO,
      numSelectOptions: 1,
      headerVideo: {
        url: 'https://vimeo.com/605595533',
        id: '605595533',
        title: 'Interpreting a Presentation',
      },
      singleMulChoiceOptions: [
        {
          userOption:
            'You only provided information about three organizations. You should discuss two more, for a total of five, so the audience can make an informed decision.',
          userLetter: 'A',
        },
        {
          userOption:
            'You didn’t introduce yourself at the beginning of the presentation. You should introduce yourself so the audience knows who you are.',
          userLetter: 'B',
        },
        {
          userOption:
            'You went back and forth between topics too frequently, which made the presentation hard to follow. You should group related ideas together.',
          userLetter: 'C',
        },
        {
          userOption:
            'You should leave time at the end of your presentation for audience questions.',
          userLetter: 'D',
        },
      ],
      submitId: 'item_5',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the first task!',
      info: 'Nice work interpreting that presentation! Your decisions help illustrate how you might interpret spoken information, where your strengths lie, and where you have room to grow. We will cover this in more detail after you complete all six tasks. <br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png', // placeholder
      questionIndex: 1,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: OC_TYPES.INFORMATIVE_2,
    },
  ],
]);

export const OCInformative1InstructionModal: SneModal = {
  title: 'Interpreting a Presentation',
  pageList: [
    {
      info: 'You work as an associate at a large accounting firm. A colleague is giving a presentation for a group of employees. <br><br> This section contains one video and several corresponding questions. Play the video, then answer the questions that follow. You can only play the video <span class=\'convertToBlue\'>once</span>.',
    },
    {
      info: '',
      showCustomContent: true,
    },
  ],
  customContent: [
    {
      url: 'https://vimeo.com/605595533',
      id: '605595533',
      info: 'Watch carefully, you can only play this video <span class="convertToBlue">once.</span>',
      playOnce: true,
      sharedVideo: { 
        url: 'https://vimeo.com/605595533',
        id: '605595533',
      },
    },
  ],
};
export const surveyAnswers: string[] = [
  'Satisfactory',
  'Needs Improvement',
];

export const answerMap = new Map<string, string>([
  ['Satisfactory', 'A'],
  ['Needs Improvement', 'B'],
]);

