import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventService } from 'src/app/services/event.service';
import { ProfileService } from 'src/app/services/profile.service';
import { setProfile } from 'src/app/store/profile.actions';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';

@Component({
  selector: 'ets-resume-option',
  templateUrl: './resume-option.component.html',
  styleUrls: ['./resume-option.component.scss']
})
export class ResumeOptionComponent extends OnBoardingV2Base implements OnInit, OnDestroy{

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
      protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  ariaLabel = 'We\'re busy connecting employers to Mind kind. If we have your resume, we can use it to match you to any open positions that fit your skillset.'

  ngOnInit(): void {
    this.initialize();
  }

  triggerAddResume(){
    this.submitResponse();
  }

  triggerCareerSuggestions(){
    this.router.navigate(['/career-suggestions']);
  }

  ngOnDestroy(): void {
    // Update lastPageVisited and onboardingComplete flag
    super.updateState();
    this.profileService.saveProfile({...this.userProfile})
      .subscribe(profile => {
        if (profile) {
          this.store.dispatch(setProfile({payload: profile}));
        }
      });
  }
}
