<ets-header [isBGWhite]="true" [headerState]="headerState" [showBackButton]="false" [simpleBack]="true">
</ets-header>

<div class="container">
  <div class="content-wrapper">
    <ets-madlibs 
      [title]="questionData?.title" 
      [subtitle]="questionData?.subtitle" 
      [inputType]="questionData?.inputType"
      [madlibsBlanks]="questionData?.madlibsBlanks" 
      [madLibsSingleBlanks]="questionData?.madlibsSingleQuestion" 
      (selectBlankEvent)="handleUserResponse($event)" 
      (answersClickedEvent)="handleOptionsSelected($event)" 
      [listAnswers]="questionData?.numSelectOptions" 
      [list]="true">
    </ets-madlibs>
  </div>
</div>

<ets-sne-progress-bar [questionIndex]="moduleIndex" [overviewEnabled]="true" [moduleSize]="moduleSize" [drawerActive]="false"
  (nextEmitter)="getNextQuestion()" (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="responseLength !== questionData?.numSelectOptions" >
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video 
      *ngIf="instructionModal?.customContent[0].url"
      [videoUrl]="instructionModal?.customContent[0].url"
      [videoId]="instructionModal?.customContent[0].id"
      [videoOnlyFlag]="true"
      [playOnce]="instructionModal?.customContent[0].playOnce"
      [sharedVideo]="instructionModal?.customContent[0].sharedVideo"
    >
    </ets-sne-video>
  </div>
</ets-modal>