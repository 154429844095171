<div class="white-container">
    <div class="header-img">
        <img src={{training.course_header}} alt="" aria-hidden="true"/>
    </div>
    <div class="inner">
        <h1>{{ training.course_title }}</h1>
        <div class="flex">
            <img class='logo' src="{{logo}}" alt="" aria-hidden="true"/>
            <div class="titles">
                <h2> 
                    {{training.training_provider}}
                </h2>
                <h3>Course host</h3>
            </div>
        </div>

        <p>{{training.course_description}}</p>

        <div class="info-flex-row">
            <div class="category">
                <p class="header" >START DATE</p>
                <p>{{startDateText}}</p>
            </div>

            <div class="category">
                <p class="header" >DURATION</p>
                <p>{{duration}}</p>
            </div>

            <div class="category">
                <p class="header">SCHEDULE</p>
                <p>{{schedule}}</p>
            </div>

            <div class="category">
                <div class="flex">
                    <div>
                        <p class="header">TRAINING TYPE</p>
                        <p>{{trainingType}}</p>
                    </div>
                    <div class="info-image">
                        <div class="desktop-check">
                            <ets-tooltip [text]="trainingTypeText" [innerToolTip]="false" [toolTipSize]="'md'" [width]="'500px'" [height]="'170px'" [position]="'tooltip-top-left'" [backgroundColor]="'#565656'"></ets-tooltip>
                        </div>
                        <!-- <div class = "mobile-check">
                            <ets-tooltip  [text]="trainingTypeText" [innerToolTip]="false" [toolTipSize]="'md'" [width]="'250px'" [height]="'170px'" [position]="'tooltip-top-left'" [backgroundColor]="'#565656'"[overflow] = "'auto'"></ets-tooltip>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="yellow-box" [class]="openBox ? 'open' : 'closed'">
            <div class="title">
                <img src="../../../assets/icons/requirements.svg" alt="" aria-hidden="true"/>
                <p (click)="openBox = !openBox">
                    Please <span class="blue-text">review requirements</span> before proceeding.
                </p>
                <img src="../../../assets/career-details/blue-arrow-down.png" [class]="openBox ? 'rotate' : 'blue-arrow' " role="button" alt="dropdown"/>
            </div>
            <div class="expanded-view" [class]="openBox ? 'openBox' : 'hidden'">
                <div *ngIf="sections" (click)="openBox = !openBox">
                    <div class="margin-top" *ngFor="let section of sections">
                        <img src="../../../assets/career-details/bullet-point.png" alt="" aria-hidden="true"/>
                        <h1>{{section.title}}</h1>
                        <div class="flex">
                            <div class="bullets">
                                <ul *ngFor="let item of section.bullets; index as i">
                                    <li *ngIf="i !== 0">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <p>If you have questions regarding your eligibility, please contact <a href="mailto:support@mindkind.com">support@mindkind.com</a></p> -->
            </div>
        </div>

        <div class="horizontal-line"></div>

        <div class="what-you-get">
            <h1>WHAT YOU GET</h1>
            <div class="flex-container">
                <div class="value-prop">
                    <img src={{valuePropImage}} class="value-prop-img" alt="" aria-hidden="true" />
                    <p>{{valueProp1}}</p>
                </div>
                <div class="value-prop">
                    <img src={{valuePropImage}} class="value-prop-img" alt="" aria-hidden="true" />
                    <p>{{valueProp2}}</p>
                </div>
                <div class="value-prop">
                    <img src={{valuePropImage}} class="value-prop-img" alt="" aria-hidden="true" />
                    <p>{{valueProp3}}</p>
                </div>
                <div class="value-prop">
                    <img src={{valuePropImage}} class="value-prop-img" alt="" aria-hidden="true" />
                    <p>{{valueProp4}}</p>
                </div>
            </div>
        </div>

        <div class="blue-box">
            <div class="flex">
                <h1>HOW PREPARED CAN I BECOME?</h1>
                <div class="info-image">
                <div class="desktop-check">
                <ets-tooltip [text]="becomePrepared" [innerToolTip]="false" [toolTipSize]="'md'" [width]="'332px'" [height]="'345px'" [position]="'tooltip-top-right'" [backgroundColor]="'#565656'" ></ets-tooltip>
            </div>
            <div class = "mobile-check">
                <ets-tooltip [text]="becomePrepared" [innerToolTip]="false" [toolTipSize]="'md'" [width]="'200px'" [height]="'300px'" [position]="'tooltip-top-left'" [backgroundColor]="'#565656'" [overflow] = "'auto'"></ets-tooltip>
            </div>
            </div>
            </div>
            <div class="flex-spread">
                <div class="slider-section">
                    <div class="flex-spread">
                        <div class="column">
                            <h4 [class]="training.course_training_type === 'skill-specific' ? 'bold' : ''">
                                Close a skill gap
                                <img [class]="training.course_training_type === 'skill-specific' ? 'blue-triangle' : 'hide' " src="/assets/career-details/blue-triangle.png" />
                            </h4>
                        </div>
                        <div class="column">
                            <h4>Become a specialist
                                <!-- <span>
                                    <img [class]="training.course_training_type === 'career-intensive' ||
                                                training.course_training_type === 'train-to-hire' ?  
                                                'blue-triangle' : 'hide' " 
                                        src="/assets/career-details/blue-triangle.png" />
                                </span> -->
                            </h4>
                        </div>

                        <div class="column">
                            <h4 [class]="training.course_training_type === 'career-intensive' ||
                                        training.course_training_type === 'train-to-hire' ? 
                                        'bold' : '' ">
                                        Career Ready
                            </h4>
                            <img [class]="training.course_training_type === 'career-intensive' ||
                                        training.course_training_type === 'train-to-hire' ?  
                                        'blue-triangle' : 'hide' " 
                                src="../../../assets/career-details/blue-triangle.png" alt="" aria-hidden="true"/>
                        </div>
                    </div>
                    <img class="slider-img" src={{sliderImg}} alt="" aria-hidden="true"/>
                    <h2 class="salary">EXPECTED SALARY: {{salaryRange}}</h2>
                </div>
                <div class="price-section">
                    <h1>{{ training.course_price }}</h1>
                    <h2>{{ offerTitle }}</h2>
                    <p>{{ trainingExplanation }}</p>
                </div>
            </div>
        </div>

        <div class="assurance">
            <p>
                Taking this step does not commit you to the program. It is simply the first part of the application process—you will have the opportunity to confirm your submission later.
            </p>
            <!-- For additional information on this training, please contact <a href="mailto:support@mindkind.com">support@mindkind.com</a>. -->
        </div>

        <div class="horizontal-line"></div>

        <button class="blue-button" (click)="toggleModal()" *ngIf="this.externalTpLink === ''">
            BEGIN MY APPLICATION
        </button>
        <button class="blue-button" (click)="externalLinkLoad()" *ngIf="this.externalTpLink !== ''">
            BEGIN MY APPLICATION
        </button>
    </div>
</div>

<clr-modal [(clrModalOpen)]="showAdditionalInfoModal" [clrModalStaticBackdrop]="false" [clrModalClosable]="false" id="additional-info-modal">
    <div class="modal-body">
        <ets-additional-info-modal
            (closeModalSignal)="closeModal()"
            [training]="training"
            [career]="career">
        </ets-additional-info-modal>
    </div>
  </clr-modal>
