import { Component, OnInit } from '@angular/core';
import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from '../../../services/sne-answer.service';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import {
  HPBotStatements,
  HPSharedFacts,
  VendorFacts
} from '../../../data/sne/collaborative-problem-solving/02_hidden-profile';
import {
  HP_BOT_MESSAGES_KEYS,
  SNE_TASK_ENUM,
  SneAnswerRequest,
  VENDOR_FACT_IMPACT,
  VENDOR_SET,
  VendorFact
} from '../../../types/sne';
import { APP_COLORS } from '../../../../global-styles';
import * as _ from 'lodash';

@Component({
  selector: 'ets-menu-fact-card-split-screen',
  templateUrl: './menu-fact-card-split-screen.component.html',
  styleUrls: ['./menu-fact-card-split-screen.component.scss']
})
export class MenuFactCardSplitScreenComponent extends SneCollaborativeProblemSolvingBase implements OnInit {

  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState = HEADER_STATES.BACK;
  HPBotStatements = HPBotStatements;
  vendorAList: VendorFact[] = [];
  vendorBList: VendorFact[] = [];
  vendorAUserKnownList: VendorFact[] = [];
  vendorBUserKnownList: VendorFact[] = [];
  selectedRequestVendorFact: VendorFact | undefined;
  botMessage: string | undefined = '';
  selectedVendor: VENDOR_SET | undefined;
  VENDOR_SET = VENDOR_SET;
  APP_COLORS = APP_COLORS;
  HP_BOT_MESSAGES_KEYS = HP_BOT_MESSAGES_KEYS

  // share fact
  // let action_record = {
  //   'action': 'fact_share',
  //   'shared_fact_id': factToShare.id,
  //   'relevance': this.TT_fact_relevance_map.get(factToShare.id)
  // };

  // let data: {[key: string]: any} = {};
  // data[Date.now().toString()] = action_record;
  // this.saveAction(data);

  // add fact
  // let action_record = {
  //   'action': 'radio_confirm',
  //   'req_fact_id': this.pending_fact.id,
  //   'relevance': this.pending_fact.impact,
  //   'overlap': this.pending_fact.overlap,
  //   'radio_sel': resIndex.toString()
  // };

  // let data: {[key: string]: any} = {};
  // data[Date.now().toString()] = action_record;
  // this.saveAction(data);

  RADIO_CONFIRM_SELECT_OPTIONS_MAP = {
    'positive': '0',
    'negative': '1',
    'neutral': '2',
  }

  initialVendorListProcess() {
    this.vendorAList = VendorFacts.filter(fact => fact.vendor === VENDOR_SET.A);
    this.vendorBList = VendorFacts.filter(fact => fact.vendor === VENDOR_SET.B);
  }

  setBotMessage(message: string | undefined) {
    this.botMessage = message;
  }

  initiateUserRequestVendorOption() {
    this.botMessage = HPBotStatements.get(HP_BOT_MESSAGES_KEYS.SHARE_FACT_PROMPT);
  }

  generateUserRequestVendorOption(vendor: VENDOR_SET) {
    let filteredList: VendorFact[] = [];
    // filter the list for facts that are not known to the user
    if (vendor === VENDOR_SET.A) {
      filteredList = this.vendorAList.filter(fact => fact.userKnown === false);
    } else if (vendor === VENDOR_SET.B) {
      filteredList = this.vendorBList.filter(fact => fact.userKnown === false);
    }
    if (filteredList.length > 0) {
      this.selectedRequestVendorFact = filteredList[0];
      this.setBotMessage(`${HPBotStatements.get(HP_BOT_MESSAGES_KEYS.FACT_SELECTED_PROMPT)} ${vendor} ${_.lowerFirst(this.selectedRequestVendorFact.fact)}`);
    }
  }

  finalizeUserRequestVendorOption(userResponseKey: string) {
    const vendor: VENDOR_SET | undefined = this.selectedRequestVendorFact?.vendor;
    let vendorFound: VendorFact | undefined;
    if (vendor !== undefined) {
      if (vendor === VENDOR_SET.B) {
        vendorFound = this.vendorBList.find(fact => fact.id === this.selectedRequestVendorFact?.id);
      } else if (vendor === VENDOR_SET.A) {
        vendorFound = this.vendorAList.find(fact => fact.id === this.selectedRequestVendorFact?.id);
      }
      if (vendorFound) {
        vendorFound.userKnown = true;
        vendorFound.botShared = true;
      }
    }
    if (userResponseKey) {
      const sharedFact = HPSharedFacts.get(userResponseKey);
      if (sharedFact) {
        this.setBotMessage(sharedFact.response);
      }
    }

    if (vendorFound && userResponseKey) {
        // add fact
      // let action_record = {
      //   'action': 'radio_confirm',
      //   'req_fact_id': this.pending_fact.id,
      //   'relevance': this.pending_fact.impact,
      //   'overlap': this.pending_fact.overlap,
      //   'radio_sel': resIndex.toString()
      // };

      // let data: {[key: string]: any} = {};
      // data[Date.now().toString()] = action_record;
      // this.saveAction(data);
    }
  }

  generateBotShareVendorOptions() {
    // grab only user known facts from vendor list A and B
    this.vendorAUserKnownList = this.vendorAList.filter(fact => fact.userKnown === true);
    this.vendorBUserKnownList = this.vendorBList.filter(fact => fact.userKnown === true);
    this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.SHARE_FACT_SELECT));
  }

  finalizeBotShareVendorOption(shareVendorFact: {factId: string, vendor: VENDOR_SET}) {
    let vendorFound: VendorFact | undefined;
    if (shareVendorFact.vendor === VENDOR_SET.A) {
      vendorFound = this.vendorAList.find(fact => fact.id === shareVendorFact.factId);
      if (vendorFound) {
        if (vendorFound.botKnown) {
          this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.FACT_ALREADY_KNOWN_A));
        } else {
          this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.FACT_NOT_KNOWN_A));
        }
      }
    } else if (shareVendorFact.vendor === VENDOR_SET.B) {
      vendorFound = this.vendorBList.find(fact => fact.id === shareVendorFact.factId);
      if (vendorFound) {
        if (vendorFound.botKnown) {
          this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.FACT_ALREADY_KNOWN_B));
        } else {
          this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.FACT_NOT_KNOWN_B));
        }
      }
    }
    if (vendorFound) {
      vendorFound.botShared = true;
    }
  }

  disableRequestVendorInfoBtn(vendor: VENDOR_SET): boolean {
    let filteredList: VendorFact[] = [];
    if (vendor === VENDOR_SET.A) {
      filteredList = this.vendorAList.filter(fact => fact.userKnown === false);
    } else if (vendor === VENDOR_SET.B) {
      filteredList = this.vendorBList.filter(fact => fact.userKnown === false);
    }
    return !(filteredList.length > 0);
  }

  initiateVendorPick() {
    this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.VENDOR_SELECT));
  }

  pickVendor(vendor: VENDOR_SET) {
    this.selectedVendor = vendor;
    if (vendor === VENDOR_SET.A) {
      this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.VENDOR_SELECT_A));
    } else if (vendor === VENDOR_SET.B) {
      this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.VENDOR_SELECT_B));
    }
  }

  confirmVendorSelection() {
    // calculate score
    const userAFactsKnown = this.vendorAList.filter(fact => fact.userKnown === true);
    const userBFactsKnown = this.vendorBList.filter(fact => fact.userKnown === true);

    const unsharedAFacts = this.vendorAList.filter(fact => fact.botShared === false && fact.impact !== VENDOR_FACT_IMPACT.IRRELEVANT);
    const unsharedBFacts = this.vendorBList.filter(fact => fact.botShared === false && fact.impact !== VENDOR_FACT_IMPACT.IRRELEVANT);

    const botAFacts = this.vendorAList.filter(fact => fact.botKnown === true);
    const botBFacts = this.vendorBList.filter(fact => fact.botKnown === true);

    let userAScore = 0;
    userAFactsKnown.forEach(fact => {
      if (fact.impact) {
        userAScore += fact.impact;
      }
    });

    let userBScore = 0;
    userBFactsKnown.forEach(fact => {
      if (fact.impact) {
        userBScore += fact.impact;
      }
    });

    let botAScore = 0;
    botAFacts.forEach(fact => {
      if (fact.impact) {
        botAScore += fact.impact;
      }
    });

    let botBScore = 0;
    botBFacts.forEach(fact => {
      if (fact.impact) {
        botBScore += fact.impact;
      }
    });

    let action_record = {
      'action': 'vendor_selection_finalized',
      'vendor_id': this.selectedVendor,
      'A_facts_known': userAFactsKnown.length,
      'B_facts_known': userBFactsKnown.length,
      'rel_A_facts_unshared': unsharedAFacts.length,
      'rel_B_facts_unshared': unsharedBFacts.length,
      'TT_A_score': userAScore,
      'TT_B_score': userBScore,
      'BOT_A_score': botAScore,
      'BOT_B_score': botBScore
    };

    let data: {[key: string]: any} = {};
    data[Date.now().toString()] = action_record;
    this.saveAction(data, true);
  }

  reconsiderVendorPick() {
    let action_record = {
      'action': 'reconsider',
    };

    let data: {[key: string]: any} = {};
    data[Date.now().toString()] = action_record;
    this.saveAction(data);
  }

  saveAction(data: any, completed = false) {
    let request: SneAnswerRequest = {
      task: SNE_TASK_ENUM.HIDDEN_PROFILE,
      data: data,
      completed: completed
    };

    console.log('Request', request);
    this.sneAnswerService.postAction(request).subscribe({
      next: response => {
        if (completed) {
          this.navToNextPage();
        }
      }
    });
  }

  ngOnInit(): void {
    this.processParams();
    this.initialVendorListProcess();
    this.setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.MENU));

    let action_record = {
      'action': 'start_task',
    };

    let data: {[key: string]: any} = {};
    data[Date.now().toString()] = action_record;
    this.saveAction(data);
  }
}
