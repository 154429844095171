import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ets-solid-button',
  templateUrl: './solid-button.component.html',
  styleUrls: ['./solid-button.component.scss']
})
export class SolidButtonComponent {

  constructor() { }

  @Input() text: string | undefined;
  @Input() color: string | undefined;
  @Input() disabled: boolean = false;
  @Input() size: string = 'sm';
  @Input() buttonMaxWidth: boolean = false;
  @Input() padding: string | undefined;
  @Output() clickEvent = new EventEmitter<any>();

  onClick = () => {
    this.clickEvent.emit();
  };

}
