import { DESIRED_COMMUTE } from '../../../account-profile/components/work-preferences/work-preferences.helpers';
import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import OC_TYPES from 'src/app/types/oral-communication-types';

export const OCDialogic2Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Performance Review',
      info: 'This task will give you insight into how you might engage with supervisors, and we will use what we learn together to enhance your Skills Profile. <br><br>In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      questionIndex: 4,
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      image: '/assets/section-4/sne-interstitial/dialogic-2.svg',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.VIDEOS_AND_MULTI_CHOICE,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/605626922',
        id: '605626922',
        title: 'Performance Review',
        mainHeader: 'Video 1',
        description: 'Watch your supervisor\'s initial performance review, then answer the first question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'The following videos are two possible responses to your supervisor\'s question. Play the videos. Then select the best way to evaluate them.',
      videos: [
        {
          url: 'https://vimeo.com/605830834',
          id: '605830834',
          title: 'Video 1',
        },
        {
          url: 'https://vimeo.com/605830921',
          id: '605830921',
          title: 'Video 2',
        },
      ],

      singleMulChoiceOptions: [
        {
          userOption: 'Video 1 is the best response because it hints that there may be a problem but doesn\'t state it outright, allowing the supervisor to draw her own conclusions.',
          userLetter: 'A',
        },
        {
          userOption: 'Video 1 is the best response because it addresses the issue of how others are behaving, avoiding possible repercussions.',
          userLetter: 'B',
        },
        {
          userOption:
            'Video 2 is the best response because it states the purpose for the meeting clearly and directly, demonstrating a willingness to take ownership of the issue.',
          userLetter: 'C',
        },
        {
          userOption:
            'Video 2 is the best response because it demonstrates an interest taking on additional responsibilities, showing an interest in future leadership roles.',
          userLetter: 'D',
        },
      ],
      numSelectOptions: 1,
      submitId: 'item_1'
    },
  ],

  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      header: 'The following video options are possible responses to your supervisor\'s question. ' +
        'After playing each video, select the option that best supports constructive communication. ' +
        'Constructive communication will help you to receive the support you need from your supervisor. ',
      question: 'Play each video option. Then, select the <span class="convertToBlue">two</span> most constructive responses.',
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      numSelectOptions: 2,
      submitId: 'item_2',
      headerVideo: {
        url: 'https://vimeo.com/605627104',
        id: '605627104',
        title: 'Offering Assistance',
        mainHeader: 'Video 2',
        description: 'Watch and listen to your supervisor, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605830964',
          id: '605830964',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605831012',
          id: '605831012',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605831074',
          id: '605831074',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'C',
        },
        {
          url: 'https://vimeo.com/605831116',
          id: '605831116',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'D',
        },
        {
          url: 'https://vimeo.com/605831178',
          id: '605831178',
          title: 'Clip E',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'E',
        },
        {
          url: 'https://vimeo.com/605831217',
          id: '605831217',
          title: 'Clip F',
          inputType: SNE_INPUT_TYPES.CHECKBOX,
          answerChoice: 'F',
        },
      ],
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO,
      header: 'The following video options are possible responses to your supervisor\'s question. ' +
        'After playing each video, select the option that best supports constructive communication. ' +
        'Constructive communication will help ensure that you\'ll continue to get the support you need from ' +
        'your supervisor as well as continue to foster a positive working relationship.',
      question: 'Play each video option. Then, select the most constructive response.',
      inputType: SNE_INPUT_TYPES.RADIO,
      numSelectOptions: 1,
      submitId: 'item_3',
      complete: true,
      headerVideo: {
        url: 'https://vimeo.com/605627177',
        id: '605627177',
        title: 'Concluding the Review',
        mainHeader: 'Video 3',
        description: 'Watch and listen to your supervisor, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      videos: [
        {
          url: 'https://vimeo.com/605831265',
          id: '605831265',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
        },
        {
          url: 'https://vimeo.com/605831351',
          id: '605831351',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
        },
        {
          url: 'https://vimeo.com/605831534',
          id: '605831534',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
        },
        {
          url: 'https://vimeo.com/605831609',
          id: '605831609',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
        },
      ],
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work navigating your performance review! Your decisions help illustrate how you might engage with supervisors, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png',
      questionIndex: 4,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: OC_TYPES.PERSUASIVE_1,
    },
  ],
]);

export const OCDialogic2InstructionModal: SneModal = {
  title: 'Performance Review',
  pageList: [
    {
      info: 'After your first year at this company, you are sitting down for your annual performance review with your direct supervisor.<br><br>You have been taking on more responsibility over the course of the year, but as you take on more tasks, you have become worried about your ability to complete everything. You are enjoying the work, but it is becoming more and more difficult for you to complete your assignments each week and you are worried about your capacity to take on more work.<br><br>This section contains multiple videos and corresponding questions. Each video can be played <span class="convertToBlue">once</span>.<br><br>Play the first video. Then answer the question(s) that follow.',
    },
  ],
};
