<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="true"
>
</ets-header>
<ets-sne-split-screen [rightBackgroundColor]="APP_COLORS.white"
                      [hideMobile]="'right'">
  <div class="left">
    <ets-sne-instruction
      [title]="questionData?.title"
      [subtitle]="questionData?.subtitle"
      [subtitle2]="questionData?.subtitle2"
    ></ets-sne-instruction>
  </div>
  <div class="right">
    <div class="right-container">
      <span class="info">{{questionData?.info}}</span>
      <!-- <clr-textarea-container class="text-area-container"> -->
        <textarea clrTextarea
                  [(ngModel)]="singleResponse"
                  name="singleResponse"
                  required
                  class="text-area"
                  [placeholder]="'Type your response here'">
        </textarea>
      <!-- </clr-textarea-container> -->
    </div>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="!singleResponse"
>
  <div class="right-container">
    <span class="info">{{questionData?.info}}</span>
    <!-- <clr-textarea-container class="text-area-container"> -->
      <textarea clrTextarea
                [(ngModel)]="singleResponse"
                name="singleResponse"
                required
                class="text-area"
                [placeholder]="'Type your response here'">
      </textarea>
    <!-- </clr-textarea-container> -->
  </div>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
<div *ngIf="instructionModal?.customContent" contentID=customContent>
<ets-sne-email-layout *ngIf="instructionModal?.customContent[0].to"
                      [emails]="instructionModal?.customContent"
></ets-sne-email-layout>
<ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user"
                               [chatLog]="instructionModal?.customContent"
                               [showHeader]="false"
></ets-sne-conversation-log-text>
</div>
</ets-modal>

