import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';
import { ShowRemoveDirective } from './show-remove.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScrollToBottomDirective,
    ShowRemoveDirective
  ],
  exports: [
    ScrollToBottomDirective,
    ShowRemoveDirective
  ]
})
export class DirectivesModule { }
