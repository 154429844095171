<div class="mobile-container">
  <div class="container-file-uploaded" *ngIf="fileName">
    <div class="inner">
      <div class="image-wrapper">
        <img src="assets/dashboard/side-bar/pdf.svg" alt="pdf logo" />
      </div>
      <div class="file-name-wrapper">
        <span class="file-name">{{fileName}}</span>
  <!--      <span class="file-size">15 MB</span>-->
      </div>
      <button class="delete-button" (click)="toggleDeleteFileModal()">
        <img src="assets/dashboard/side-bar/trash.svg"  alt="trash icon"/>
      </button>
    </div>
  </div>

  <div class="container-file-browse" *ngIf="!fileName">
    <div class="outer">
      <div class="inner">
        <img src="assets/dashboard/side-bar/up-arrow-outline.svg" alt="up arrow outline icon" />
        <span class="upload-pdf">Upload your file here.</span>
        <span class="ensure-pdf">Make sure it's a PDF!</span>
        <!-- <ets-outline-button [text]="'Browse'" [color]="APP_COLORS.uiaction"></ets-outline-button> -->
        <input type="file" class="get-file-input" value="BROWSE" (change)="onFileChange($event)">
      </div>
    </div>
    <span class="size-restriction">5 MB max file size limit</span>
  </div>
</div>

<clr-modal [(clrModalOpen)]="showDeleteModal" [clrModalClosable]="false">
  <div class="modal-body">
    <p class="modal-question">
      Are you sure you want to delete this resume?
    </p>
  </div>
  <div class="modal-footer modal-buttons">
    <ets-solid-button [text]="'DELETE RESUME'" [color]="APP_COLORS.uiaction" (clickEvent)="deleteFile()"></ets-solid-button>
    <ets-outline-button [text]="'CANCEL'" [color]="APP_COLORS.uiaction" (clickEvent)="closeModal()"></ets-outline-button>
  </div>
</clr-modal>
