import { Component, OnInit } from '@angular/core';
import { AGREE_STATES, ENJOY_STATES, YES_NO_STATES } from './question-ranking.helper';
import { Store } from '@ngrx/store';
import { selectCipAnswers, selectCipQuestion, selectTriggerNextCipQuestion } from '../../../store';
import { setCipAnswers, setTriggerNextCipQuestion } from 'src/app/store/ui.actions';
import Question from '../../../types/question';
import { CipAnswer } from 'src/app/types/cip';
import { CipService } from 'src/app/services/cip.service';
import { FormControl, FormGroup } from '@angular/forms';
import { APP_COLORS } from '../../../../global-styles';


@Component({
  selector: 'ets-question-ranking',
  templateUrl: './question-ranking.component.html',
  styleUrls: ['./question-ranking.component.scss']
})
export class QuestionRankingComponent implements OnInit {

  APP_COLORS = APP_COLORS;
  enjoyStates = ENJOY_STATES;
  agreeStates = AGREE_STATES;
  yesNoStates = YES_NO_STATES;
  questionData: Question | undefined;
  rankingType = 'ENJOY';
  currentAnswers: CipAnswer[] | undefined;
  changeAnswers = true;
  triggerNext = false;
  cipForm = new FormGroup({

  });

  constructor(private store: Store, private cipService: CipService,) {}

  getQuestion = () => {
    this.store.select(selectCipQuestion)
      .subscribe(question => {
        let elementList = document.querySelectorAll('.inner');
        elementList.forEach(
          function(currentValue, currentIndex, listObj) {
            currentValue.scrollTo(0,0);
          });
        this.questionData = question;
        this.initializeForm();
        this.getOldAnswerList();
        // this.saveAnswer();
        if (question?.headers === 'AGREE') {
          this.enjoyStates = AGREE_STATES;
        } else if (question?.headers === 'ENJOY') {
          this.enjoyStates = ENJOY_STATES;
        } else {
          this.enjoyStates = YES_NO_STATES;
        }

      });
  };

  initializeForm = () => {
    this.cipForm = new FormGroup({});
    if (this.questionData && this.questionData.choices) {
      this.questionData.choices.forEach((choice, index) => {
        this.cipForm.addControl(`${choice}`, new FormControl(''));
      });
    }
  }

  getOldAnswerList = () => {
    this.store.select(selectCipAnswers)
      .subscribe(cipAnswers => {
        if (cipAnswers) {
          this.currentAnswers = cipAnswers;
          cipAnswers.forEach(cipAnswer => {
            const index = this.questionData?.numbers?.findIndex(number => number === cipAnswer.questionNum);
            if (index !== undefined && index !== -1 && this.questionData?.choices) {
              const choice = this.questionData?.choices[index];
              this.cipForm.controls[choice].setValue(cipAnswer.answer.toString());
            }
          });
        }
      });
  }

  saveAnswer = () => {
    const answerList: CipAnswer[] = [];
    Object.keys(this.cipForm.controls).forEach((key) => {
      const index = this.questionData?.choices?.findIndex(choice => choice === key);
      if (index !== undefined && index !== -1 && this.questionData?.numbers) {
        answerList.push({
          questionNum: this.questionData?.numbers[index],
          answer: this.cipForm.controls[key].value
        });
      }
    });
    this.currentAnswers?.forEach(currentAnswer => {
      const index = answerList.findIndex(answer => answer.questionNum === currentAnswer.questionNum);
      if (index !== undefined && index === -1) {
        answerList.push(currentAnswer);
      }

    });
    this.store.dispatch(setCipAnswers({payload: answerList}));
  }

  checkComplete() {
    let complete = true;
    Object.keys(this.cipForm.controls).forEach((key) => {
      if (this.cipForm.controls[key].value === '') {
        complete = false;
      }

    });
    return complete;
  }

  getTriggerValue() {
    this.store.select(selectTriggerNextCipQuestion).subscribe(trigger => this.triggerNext = trigger);
  }

  triggerNextQuestionCip() {
    if (this.checkComplete()) {
      this.saveAnswer();
      this.store.dispatch(setTriggerNextCipQuestion({ payload: !this.triggerNext }));
    }
  }

  ngOnInit() {
    this.getQuestion();
    this.getTriggerValue();
  }

}
