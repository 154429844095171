import { Component, OnInit } from '@angular/core';
import { APP_COLORS } from 'src/global-styles';
import { Store } from '@ngrx/store';
import { selectCipAnswers, selectCipQuestion, selectTriggerNextCipQuestion } from '../../../store';
import { setCipAnswers, setTriggerNextCipQuestion } from 'src/app/store/ui.actions';


@Component({
  selector: 'ets-cip-intro',
  templateUrl: './cip-intro.component.html',
  styleUrls: ['./cip-intro.component.scss']
})
export class CipIntroComponent implements OnInit {

  constructor(private store: Store) { }

  APP_COLORS = APP_COLORS;
  info = 'Help us gain insight into your career aspirations and interests so we can guide you on the right path.'
  desktopImageSrc = '/assets/section-4/intro-image-desktop.png';
  tabletImageSrc = '/assets/section-4/intro-image-tablet.png';
  mobileImageSrc = '/assets/section-4/intro-image-mobile.png';
  triggerNext = false;

  getTriggerValue() {
    this.store.select(selectTriggerNextCipQuestion).subscribe(trigger => this.triggerNext = trigger);
  }

  triggerNextQuestionCip() {
    this.store.dispatch(setTriggerNextCipQuestion({ payload: !this.triggerNext }));
  }

  onClick() {
    this.triggerNextQuestionCip();
  }
  
  ngOnInit(): void {
    this.getTriggerValue();
  }

}
