import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ets-foundational-assessment',
  templateUrl: './foundational-assessment.component.html',
  styleUrls: ['./foundational-assessment.component.scss']
})
export class FoundationalAssessmentComponent {

  constructor() { }

}
