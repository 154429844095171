import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';


@Component({
  selector: 'ets-multi-choice-video',
  templateUrl: './multi-choice-video.component.html',
  styleUrls: ['./multi-choice-video.component.scss']
})
export class MultiChoiceVideoComponent extends SneOralCommunicationBase implements OnInit {

  constructor(router: Router, route: ActivatedRoute, protected sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  showModal = false;
  

  toggleModal() {
    this.showModal = !this.showModal;
  }

  singleResponseHandler(answer: string) {
    this.singleResponse = answer;
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
      this.multiResponse.sort();
    }

  }

  ngOnInit() {
    this.processParams();
  }


}
