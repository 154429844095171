import { SneModal, SneMulChoiceOption, SneMulChoiceQuestion, SneQuestion, SNE_INPUT_TYPES } from '../../../types/sne';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CoachabilityCriticismData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Criticism',
      info: 'This task will give you insight into how you might receive and respond to feedback, and we will use what we learn together to enhance your Skills Profile.',
      image: '../assets/section-3/sne-interstitial/criticism.svg',
      buttonText: 'Next',
      questionIndex: 4,
      moduleSize: 6,
      header: '',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      durationBoxColor: 'lbb',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'Review each video option. Select the video showing Jonathan’s most effective and appropriate response to Valerie.',
      headerVideo:{
        url: 'https://vimeo.com/602482740 ',
        id: '602482740',
        title: 'Participating in Meetings',
        mainHeader: 'Video 1',
        description: 'Valerie gives Jonathan some constructive criticism.',
        playOnce: false,
      },
      videos: [
        {
          url: 'https://vimeo.com/679976491',
          id: '679976491',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/605777815',
          id: '605777815',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,

        },
        {
          url: 'https://vimeo.com/679976673',
          id: '679976673',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/679976885',
          id: '679976885',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,

        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'What should Jonathan do as a result of Valerie’s feedback? Choose the <span class="convertToBlue">best</span> action from the following choices.',
      headerVideo:{
        url: 'https://vimeo.com/602482740 ',
        id: '602482740',
        title: 'Participating in Meetings',
        mainHeader: 'Video 1',
        description: 'Valerie gives Jonathan some constructive criticism.',
        playOnce: false,
      },
      singleMulChoiceOptions: [
        {
          userOption: 'Talk to other members of the team, and ask them whether they agree with Valerie about his failure to contribute during brainstorming sessions.',
          userLetter: 'A',
        },
        {
          userOption: 'Decide to find reasons to attend fewer brainstorming sessions so that his relative lack of participation will be less obvious.',
          userLetter: 'B',
        },
        {
          userOption: 'Think about possible ideas before the next brainstorming session and then make sure to offer at least two contributions during the session itself.',
          userLetter: 'C',
        },
        {
          userOption: 'Resolve to make a comment on every issue brought up in the meeting, even if that comment might not always be helpful.',
          userLetter: 'D',
        },
      ],
      numSelectOptions: 1,
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'Review each video option. Select the video showing Jonathan’s most effective and appropriate response to Steve.',
      headerVideo:{
        url: 'https://vimeo.com/600829534',
        id: '600829534',
        title: 'Punctuality',
        mainHeader: 'Video 2',
        description: 'The next team leader who meets with Jonathan is Steve.',
        playOnce: false,
      },
      videos: [
        {
          url: 'https://vimeo.com/679977678',
          id: '679977678',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/679977713',
          id: '679977713',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,

        },
        {
          url: 'https://vimeo.com/605778113',
          id: '605778113',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/605778136',
          id: '605778136',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,

        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'What should Jonathan do as a result of Steve’s feedback? Choose the <span class="convertToBlue">best</span> action from the following choices.',
      headerVideo:{
        url: 'https://vimeo.com/600829534',
        id: '600829534',
        title: 'Punctuality',
        mainHeader: 'Video 2',
        description: 'The next team leader who meets with Jonathan is Steve.',
        playOnce: false,
      },
      singleMulChoiceOptions: [
        {
          userOption: 'Set an earlier morning alarm with the goal of arriving a few minutes early for all meetings going forward.',
          userLetter: 'A',
        },
        {
          userOption: 'Ask the other team members if meetings could possibly be rescheduled for later in the day.',
          userLetter: 'B',
        },
        {
          userOption: 'Gather data about his typical arrival times at meetings to present to Steve at a later date.',
          userLetter: 'C',
        },
        {
          userOption: 'Send Steve an email providing specific details about the personal issues he had dealt with last month.',
          userLetter: 'D',
        },
      ],
      numSelectOptions: 1,
      submitId: 'item_4',
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'Review each video option. Select the video showing Jonathan’s most effective and appropriate response to April.',
      headerVideo:{
        url: 'https://vimeo.com/602504295',
        id: '602504295',
        title: 'Time Commitment',
        mainHeader: 'Video 3',
        description: 'The final team leader who meets with Jonathan is April.',
        playOnce: false,
      },
      videos: [
        {
          url: 'https://vimeo.com/605778201',
          id: '605778201',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/679977744',
          id: '679977744',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,

        },
        {
          url: 'https://vimeo.com/605778450',
          id: '605778450',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/679977810',
          id: '679977810',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,

        },
      ],
      submitId: 'item_5',
    },
  ],
  [
    'question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'What should Jonathan do as a result of April’s feedback? Choose the <span class="convertToBlue">best</span> action from the following choices.',
      headerVideo:{
        url: 'https://vimeo.com/602504295',
        id: '602504295',
        title: 'Time Commitment',
        mainHeader: 'Video 3',
        description: 'The final team leader who meets with Jonathan is April.',
        playOnce: false,
      },
      singleMulChoiceOptions: [
        {
          userOption: 'Explain to the other team leaders that he will be unavailable to help them for the foreseeable future to allow him to concentrate solely on April’s team.',
          userLetter: 'A',
        },
        {
          userOption: 'Speak to his supervisor about adjusting his workload so he can devote more time and effort to April’s team.',
          userLetter: 'B',
        },
        {
          userOption: 'Prove to April that she is being unfair in her assessment of his work by demonstrating that it was well received by several others.',
          userLetter: 'C',
        },
        {
          userOption: 'Attend a workshop that provides tools to help employees manage their email and personal documents in order to gain efficiencies.',
          userLetter: 'D',
        },
      ],
      numSelectOptions: 1,
      submitId: 'item_6',
    },
  ],
  [
    'question-7',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      question: 'Review each video option. Select the video showing Jonathan’s most effective and appropriate response to Sam.',
      headerVideo:{
        url: 'https://vimeo.com/602504258',
        id: '602504258',
        title: 'High Praise',
        mainHeader: 'Video 4',
        description: 'Later that week, Jonathan receives a video call from Sam, one of his teammates and friends.',
        playOnce: false,
      },
      videos: [
        {
          url: 'https://vimeo.com/605778597',
          id: '605778597',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/605778631',
          id: '605778631',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,

        },
        {
          url: 'https://vimeo.com/605778662',
          id: '605778662',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/679977875',
          id: '679977875',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,

        },
      ],
      submitId: 'item_7',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! <br> You finished another task.',
      info: 'Nice work communicating with your team! Your decisions help illustrate how you handle receiving criticism, where your strengths lie, and where you have room to grow. Feel free to pause if you need a break or start the next task if you are ready.',
      questionIndex: 4,
      moduleSize: 6,
      image: '/assets/section-3/sne-interstitial/coach-end-interstitial.png', // placeholder
      buttonText: 'Next',
      goto: COACHABILITY_TYPES.PERSONAL_MISTAKES,
    },
  ],
]);


export const CoachabilityCriticismInstructionModal: SneModal = {
  title: 'Receiving Feedback',
  pageList: [
    {
      info: 'Jonathan is an employee of WeSellStuff, Inc. He works on several different teams to produce new products for marketing. He was hired because of his strong skills managing emails and documents. Today, Jonathan will be receiving feedback from his team leaders regarding his work on the teams so far. The first team leader to meet with him is Valerie.',
    }
  ]
};
