import { Component, OnInit, Input } from '@angular/core';
import { ScaleType } from '@swimlane/ngx-charts';
import { EventService } from 'src/app/services/event.service';
import { Router } from '@angular/router';
import { EventActionEnum } from 'src/app/types/event';
import { APP_COLORS } from '../../../../global-styles';

@Component({
  selector: 'ets-career-card',
  templateUrl: './career-card.component.html',
  styleUrls: ['./career-card.component.scss']
})
export class CareerCardComponent implements OnInit {
  constructor(private eventService: EventService, private router: Router) {  }

  @Input() careerTitle: string = '';
  @Input() matchScore: number = 0;
  @Input() arrLength: number = 0;
  @Input() id: string = '';

  currentDesktopStyles = {};
  currentMobileStyles = {};
  windowWidth: number = 0;

  // ngx-charts-pie-chart.
  results: any[] = [];
  view: [number, number] = [90, 90];
  isDoughnut: boolean = true;
  isGradient: boolean = true;
  disableTooltip: boolean = true;
  arcWidth = 0.20;
  colorScheme = {
    domain: ['#0E3AD6', '#E8EFFE'],
    name: 'donut-chart',
    selectable: false,
    group: ScaleType.Linear
  };

  APP_COLORS = APP_COLORS;

  setMatchScoreResults() {
    if (this.matchScore) {
      this.matchScore = Math.round(this.matchScore! * 100);
    }
    return this.results = [
      {
        'name': 'Score',
        'value': this.matchScore
      },
      {
        'name': 'Remainder',
        'value': (100 - this.matchScore)
      }
    ];
  }
  // ngx-charts-pie-chart ^^

  ngOnInit() {
    this.setMatchScoreResults();
  }

  onClick() {
    // Track the event in AWS
    this.eventService.buildEvent('Dashboard Career Card - Career ' + this.id, EventActionEnum.CLICK);
    this.router.navigate([`/career-detail/${this.id}`]);
  }

}
