<div class="container">
  <div class="content-wrapper">
    <div class="question-wrapper">
      <img class="img" src="../../assets/section-4/skill-types/top-skills.svg" alt="" aria-hidden="true" />
      <div class="question-text-wrapper">
        <div class="prefered-name-wrapper">
          <h1 *ngIf="userProfile?.preferredName">{{userProfile?.preferredName + '\'s' }}</h1>
          <h1 [innerHTML]='currentQuestion?.question'></h1>
        </div>
        <p [innerHTML]="currentQuestion?.info"></p>
      </div>
    </div>

    <div class="top-skills-container-desktop">
      <div class="top-skill-wrapper">
        <button 
          class="top-skill"
          *ngFor="let topSkill of currentQuestion?.topSkills; let idx = index" 
          (click)="removeTopSkill(idx)" 
          [disabled]="disabledTopSkill(idx)"
        >
          {{ topSkill.selectedSkill ? topSkill.selectedSkill : topSkill.placeholderSkill }}
        </button>
      </div>
      <div>
        <span class="great-choice-text" *ngIf="topSkillCount === 5">
          Great choices!
        </span>
        <span class="top-skill-count"  *ngIf="topSkillCount < 5">
          {{ topSkillCount }} / 5 top skills
        </span>
      </div>
    </div>

    <div class="transferable skills-container">
      <div class="header-wrapper">
        <img class="img" src="../../assets/section-4/skill-types/transferable-skills.svg" alt="" aria-hidden="true" />
        <h3 class="title">My Power Skills + Behaviors</h3>
      </div>
      <div class="pill-wrapper">
        <ets-skills-button
          *ngFor="let skill of transferableSkills; let idx = index"
          [skill]="skill.eulerSkill"
          [id]="idx"
          [isSelected]="skill.isTopSkill"
          [disableAll]="this.topSkillCount >= this.currentQuestion?.topSkills?.length!"
          (selectedToggle)="selectTransferableSkill($event)"
        >
        </ets-skills-button>
      </div>
    </div>

    <div class="jobSpecific skills-container">
      <div class="header-wrapper">
        <img class="img" src="../../assets/section-4/skill-types/job-specific-skills.svg" alt="" aria-hidden="true" />
        <h3 class="title">My Specialized Skills</h3>
      </div>
      <div class="pill-wrapper">
        <ets-skills-button
          *ngFor="let skill of jobSpecificSkills; let idx = index"
          [skill]="skill.eulerSkill"
          [id]="idx"
          [isSelected]="skill.isTopSkill"
          [disableAll]="this.topSkillCount >= this.currentQuestion?.topSkills?.length!"
          (selectedToggle)="selectJobSpecificSkill($event)"
        >
        </ets-skills-button>
      </div>
    </div>

    <button class="save-and-continue-btn" [disabled]="this.topSkillCount <= 0" (click)="saveAndContinue()">
      {{ currentQuestion?.choiceNavList?.[0]?.choice }}
    </button>


<!-- TOP SKILL MOBILE VERSION -->
    <div class="top-skills-container-mobile">
      <div class="top-skill-wrapper">
        <button 
          class="top-skill"
          *ngFor="let topSkill of currentQuestion?.topSkills; let idx = index" 
          (click)="removeTopSkill(idx)" 
          [disabled]="disabledTopSkill(idx)"
        >
          {{ topSkill.selectedSkill ? topSkill.selectedSkill : 'Add skill' }}
        </button>
      </div>

      <div class="top-skill-counter-wrapper">
        <span class="great-choice-text" *ngIf="topSkillCount === 5">
          Great choices!
        </span>
        <span class="top-skill-count"  *ngIf="topSkillCount < 5">
          {{ topSkillCount }} / 5 top skills
        </span>
      </div>

    </div>
  </div>
</div>