import Question, {ChoiceNav} from '../types/question';
import QUESTION_TYPES, {QUESTION_SECTIONS} from '../types/question-types';
import {ActivatedRoute, Router} from '@angular/router';
import {setProfile} from '../store/profile.actions';
import {ProfileService} from '../services/profile.service';
import {Store} from '@ngrx/store';
import Profile from '../types/profile';
import {
  setOnBoardingCurrentQuestionIndex,
  setOnBoardingQuestionType,
  setOnBoardingSection
} from '../store/on-boarding.actions';
import {QUESTION_LIST_V2} from '../data/sign-up-questions-v2';
import OB_PAGES from '../types/on-boarding-pages.types';
import { generateNextQuestionPath, higherEducationList } from './on-boarding-v2.helpers';
import {combineLatest, Subscription} from 'rxjs';
import {selectProfile} from '../store';
import * as _ from 'lodash';
import {environment} from 'src/environments/environment';
import {EventActionEnum, EventSectionEnum} from '../types/event';
import {EventService} from '../services/event.service';

// Ambient declaration for Facebook Pixel to allow compiler to be happy... fbq will be defined by runtime
declare const fbq: any;

export class OnBoardingV2Base {

  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
  }

  questionList: Question[] = QUESTION_LIST_V2;
  currentQuestion: Question | undefined;
  currentQuestionIndex: number | undefined;
  sections = QUESTION_SECTIONS;
  questionTypes = QUESTION_TYPES;
  userResponse: string | string[] | number | undefined | Date;
  oldUserResponse: string | string[] | number | undefined | Date;
  userProfile: Profile | undefined;
  subs: Subscription[] = [];

  processParamsAndProfile(): void {
    const routeSub = this.route.params;
    const profileSub = this.store.select(selectProfile);
    this.subs.push(combineLatest([routeSub, profileSub])
      .subscribe(([params, profile]) => {
        if (profile && params) {
          this.userProfile = {...profile};
          const {questionNum} = params;
          this.currentQuestionIndex = +questionNum;
          if (!isNaN(this.currentQuestionIndex)) {
            this.currentQuestion = this.questionList[this.currentQuestionIndex];
            this.store.dispatch(setOnBoardingSection({payload: this.currentQuestion.section}));
            this.store.dispatch(setOnBoardingQuestionType({payload: this.currentQuestion.type}));
            this.store.dispatch(setOnBoardingCurrentQuestionIndex({payload: this.currentQuestionIndex}));
            this.processQuestion();
          }
        }
      }));
    // Anytime the params change, call track analytics
    this.subs.push(routeSub.subscribe(params => {
      if (this.currentQuestion) {
        this.trackAnalytics();
      }
    }));
  }

  processQuestion(): void {
  }

  cleanData() {
    this.currentQuestion?.choiceNavList?.forEach(choice => {
      const page = choice.navTo;
      const question = this.questionList.find(question => question.page === page);
      if (question && this.userProfile && question.profileAttribute) {
        const key = question.profileAttribute as keyof Profile;
        // @ts-ignore
        this.userProfile[key] = null;
      }
    });
  }

  submitResponse() {
    // Temporarily save the user response for the choice nav logic
    this.oldUserResponse = this.userResponse;
    this.cleanData(); // clean data prior to submit
    this.updateState(); // Update the lastPageVisited/onboardingComplete field
    this.submit(); //submit response
  }


  additionalSubmitSteps() {
  }

  proceedToNextQuestion(newProfile?: Profile): void {
    if (this.currentQuestionIndex !== undefined) {
      let newIndex;
      let choiceNavObj: ChoiceNav | undefined;

      // Choice Nav logic
      if (this.currentQuestion?.choiceNavList) {
        if (this.currentQuestion.page === OB_PAGES.EDUCATION_HISTORY && Array.isArray(this.oldUserResponse)) {
          let higherEducationSelected = false;
          const intersectArray = _.intersection(this.oldUserResponse, higherEducationList);
          higherEducationSelected = intersectArray.length > 0;
          choiceNavObj = this.currentQuestion.choiceNavList.find(value => {
            return value.choice === higherEducationSelected;
          });
        } else {
          choiceNavObj = this.currentQuestion.choiceNavList.find(value => {
            return value.choice === this.oldUserResponse;
          });
        }
      }

      // if undefined, choice wasn't found in the list
      if (choiceNavObj) {
        newIndex = this.questionList.findIndex(question => {
          if (choiceNavObj) {
            return question.page === choiceNavObj.navTo;
          }
          return undefined;
        });
      }

      // if findIndex above returned undefined and/or choiceNavObj is undefined
      if (newIndex === undefined) {
        newIndex = this.currentQuestionIndex + 1;
      }

      if (newIndex <= (this.questionList.length - 1)) {
        const nextQuestion = this.questionList[newIndex];
        const newPath = generateNextQuestionPath(nextQuestion, newIndex);
        if (newPath) {
          this.router.navigate([newPath]).then(() => {
            if (newProfile) {
              this.store.dispatch(setProfile({payload: newProfile}));
            }
          });
        }
      }
    }
  }

  // Function to save the lastPastVisited and onboardingComplete flag
  updateState() {
    try {
      let question;
      if (this.currentQuestionIndex !== undefined) {
        question = this.questionList[this.currentQuestionIndex];
      }

      if (this.userProfile) {
        if (question && question.page !== OB_PAGES.SKILLS_LOADING) {
          this.userProfile.lastPageVisited = question.page;
          this.userProfile.onboardingComplete = !!question.onboardingComplete;
          if (this.userProfile.onboardingComplete) {
            fbq('trackCustom', 'OnboardingComplete', {
              learnerID: this.userProfile.learnerID,
            });
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  submit(): void {
    const attribute = this.currentQuestion?.profileAttribute;
    if (this.userResponse !== undefined && attribute) {
      this.subs.push(this.profileService.saveProfile({...this.userProfile, [attribute]: this.userResponse})
        .subscribe(profile => {
          if (profile) {
            // this.store.dispatch(setProfile({payload: profile}));
            this.proceedToNextQuestion(profile);
          }
        }));
      this.userResponse = undefined;
    } else {
      // For the scenario when the profile is not updated on the question, but we
      // still need to update the lastPageVisited and onboardingComplete fields
      this.subs.push(this.profileService.saveProfile({...this.userProfile})
        .subscribe(profile => {
          if (profile) {
            // this.store.dispatch(setProfile({payload: profile}));
            this.proceedToNextQuestion(profile);
          }
        }));
      this.userResponse = undefined;
    }
    this.additionalSubmitSteps();
  }

  initialize(): void {
    // Set the document title
    document.title = 'Apprize | Onboarding';
    this.processParamsAndProfile();
  }

  teardown(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  trackAnalytics() {
    window.dataLayer.push({
      'userId': this.userProfile?.learnerID
    });

    if (this.currentQuestion) {
      if (environment.analyticsTrackingEnabled) {
        // Replace state for Google Analytics
        let stateObj = {
          pathname: window.location.pathname + '?page=' + this.currentQuestion.page
        };
        history.replaceState(stateObj, 'Onboarding', window.location.pathname + '?page=' + this.currentQuestion.page);
      }
      // Track the page view in AWS Athena
      this.eventService.buildEvent('Onboarding', EventActionEnum.PAGE_VIEW, EventSectionEnum.ONBOARING);
    }
  }
}
