import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcInterstitialComponent } from './wc-interstitial/wc-interstitial.component';
import { WcMultiChoiceMemoComponent } from './wc-multi-choice-memo/wc-multi-choice-memo.component';
import { WcTextFreeResponseComponent } from './wc-text-free-response/wc-text-free-response.component';
import { SharedModule } from '../../shared/shared.module';
import { SneComponentsModule } from '../components/sne-components.module';
import { WrittenCommunicationComponent } from './written-communication.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { WcMultiChoiceEmailComponent } from './wc-multi-choice-email/wc-multi-choice-email.component';
import { WcSneIntroComponent } from './wc-sne-intro/wc-sne-intro.component';

@NgModule({
  declarations: [
    WcInterstitialComponent,
    WcMultiChoiceMemoComponent,
    WcTextFreeResponseComponent,
    WrittenCommunicationComponent,
    WcMultiChoiceEmailComponent,
    WcSneIntroComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SneComponentsModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    WcInterstitialComponent,
    WcMultiChoiceMemoComponent,
    WcTextFreeResponseComponent,
    WrittenCommunicationComponent
  ]
})
export class WrittenCommunicationModule { }
