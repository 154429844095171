import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getResolvedUrl, validatePath } from './guard.helpers';
import { map } from 'rxjs/operators';
import { SneAnswerService } from '../services/sne-answer.service';
import { generateNextQuestionPath } from '../sne-v2/written-communication/sne-written-communication.helpers';
import { WRITTEN_OC_ASSESSMENT_LIST } from '../sne-v2/sne-v2.helpers';


@Injectable({
  providedIn: 'root'
})
export class SneWrittenCommunicationCompleteGuard implements CanActivateChild {
  constructor(private sneAnswerService: SneAnswerService) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentPath = getResolvedUrl(childRoute);
    return this.sneAnswerService?.getSneAssessmentRecords().pipe(map((assessments): boolean | UrlTree => {
      return validatePath(WRITTEN_OC_ASSESSMENT_LIST, assessments, currentPath, generateNextQuestionPath);
    }));
  }

}
