import Question, { CARD_SIZE_ENUM, SKILL_TYPES_ENUM } from '../types/question';
import QUESTION_TYPES, { QUESTION_SECTIONS } from '../types/question-types';
import OB_PAGES from '../types/on-boarding-pages.types';

export const QUESTION_LIST_V2: Question[] = [
  /** section 1 **/
  { // question-0
    type: QUESTION_TYPES.INTRO,
    section: QUESTION_SECTIONS.SECTION_ONE,
    questionFunc: (info) => `Welcome ${info}!`,
    info: 'We’ll start by asking a few questions to help us understand who you are and what you want to achieve.\n\n Ready?',
    page: OB_PAGES.SECTION_ONE_START,
  },
  { //question-1
    type: QUESTION_TYPES.MULTI_MULTIPLE_CHOICE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'What brings you here?',
    choices: [
      'Explore careers',
      'Learn new skills',
      'Uncover my skills and strengths',
      'Find a new job',
    ],
    profileAttribute: 'aspiration',
    page: OB_PAGES.ASPIRATION,
    previousPage: OB_PAGES.SECTION_ONE_START
  },
  { //question-2
    type: QUESTION_TYPES.SINGLE_QUESTION_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'When were you born?',
    placeholder: 'MM/DD/YYYY',
    profileAttribute: 'birthDate',
    validator: '[0123456789][0123456789][0123456789][0123456789]-[0123456789][0123456789]-[0123456789][0123456789]',
    errorMessage: 'Must be valid date.',
    page: OB_PAGES.BIRTH_DATE,
    previousPage: OB_PAGES.ASPIRATION
  },
  { //question-3
    type: QUESTION_TYPES.SINGLE_QUESTION_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'Where in the U.S. do you live?',
    placeholder: 'Zip code',
    profileAttribute: 'zipCode',
    validator: '[1234567890][1234567890][1234567890][1234567890][1234567890]',
    errorMessage: 'Zip code must be in five digit format.',
    page: OB_PAGES.ZIP_CODE,
    previousPage: OB_PAGES.BIRTH_DATE
  },
  { //question-4
    type: QUESTION_TYPES.CARD_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'How do you self-identify?',
    selectAllThatApply: false,
    cardSize: CARD_SIZE_ENUM.LARGE,
    cards: [
      {
        title: 'Woman',
        image: '../assets/section-2/genders/Female.png',
        selected: false,
      },
      {
        title: 'Man',
        image: '../assets/section-2/genders/Male.png',
        selected: false,
      },
      {
        title: 'Non-binary',
        image: '../assets/section-2/genders/Non-Binary.png',
        selected: false,
      },
      {
        title: 'Prefer not to say',
        image: '../assets/section-2/genders/No-Identity.png',
        selected: false,
      },
    ],
    profileAttribute: 'diversityGender',
    page: OB_PAGES.DIVERSITY_GENDER,
    previousPage: OB_PAGES.ZIP_CODE,
    whyWeAskModal: {
      title: 'What does gender have to do with anything?',
      body: 'If you\'re thinking, "Nothing!" we could not agree more. Here at Apprize, we want to help everyone achieve their career goals, regardless of their demographic background. To strive for a more equitable hiring market, we need to know who our users are.'
    },
  },
  { // question-5
    type: QUESTION_TYPES.MULTI_MULTIPLE_CHOICE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'Which races and/or ethnicities do you identify with most?',
    choices: [
      'American Indian or Alaska Native',
      'Asian',
      'Black or African American',
      'Hispanic or Latino',
      'Native Hawaiian or Other Pacific Islander',
      'White',
      'Other',
      'Prefer not to say',
    ],
    profileAttribute: 'diversityRace',
    page: OB_PAGES.DIVERSITY_RACE,
    previousPage: OB_PAGES.DIVERSITY_GENDER,
    whyWeAskModal: {
      title: 'What does race have to do with anything?',
      body: 'If you\'re thinking, "Nothing!" we could not agree more. Here at Apprize, we want to help everyone achieve their career goals, regardless of their demographic background. To strive for a more equitable hiring market, we need to know who our users are.'
    },
  },
  { // question-6
    type: QUESTION_TYPES.SINGLE_QUESTION_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'How much do you expect to earn this year?',
    placeholder: '$',
    validator: '^[$,0-9]+$',  //regex allows only $, digits, and commas
    errorMessage: 'Please only enter numeric values',
    profileAttribute: 'currentSalary',
    whyWeAskModal: {
      title: 'Let\'s increase your earnings potential as much as possible.',
      body: 'By understanding what you are earning currently, we know the benchmark ' +
        'for careers you may be interested in and roughly what training opportunities ' +
        'make the most sense for your upskilling journey.'
    },
    page: OB_PAGES.CURRENT_SALARY,
    previousPage: OB_PAGES.DIVERSITY_RACE
  },
  { // question-7
    type: QUESTION_TYPES.INFO_BREAK,
    section: QUESTION_SECTIONS.SECTION_ONE,
    question: 'Kudos! The first step is always the hardest.',
    info: '',
    picFileName: '/assets/section-1/sne-interstitial/cps-end-interstitial.png',
    page: OB_PAGES.SECTION_ONE_END_INFO_BREAK,
    // previousPage: OB_PAGES.CURRENT_SALARY
    previousPage: OB_PAGES.CURRENT_SALARY
  },


  /** section 2 **/
  { // question-8
    type: QUESTION_TYPES.INTRO,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'Education + Preferences',
    info: 'Tell us what you’ve studied and what you want out of your next career move so we can personalize our guidance.',
    page: OB_PAGES.SECTION_TWO_START,
    previousPage: OB_PAGES.SECTION_ONE_END_INFO_BREAK
  },
  { // question-9
    type: QUESTION_TYPES.CARD_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'What is your education history?',
    selectAllThatApply: true,
    cardSize: CARD_SIZE_ENUM.LARGE,
    cards: [
      {
        title: 'Some high school',
        image: '../assets/large-cards/Some-high-school.svg',
        selected: false,
      },
      {
        title: 'High school or equivalent',
        image: '../assets/large-cards/High-school-or-equivalent.svg',
        selected: false,
      },
      {
        title: 'Vocational training',
        image: '../assets/large-cards/Vocational-training.svg',
        selected: false,
      },
      {
        title: 'Bootcamp or technical training',
        image: '../assets/large-cards/Some-college-no-degree.svg',
        selected: false,
      },
      {
        title: 'Some college but no degree',
        image: '../assets/large-cards/Some-college-no-degree.svg',
        selected: false,
      },
      {
        title: 'Associates degree',
        image: '../assets/large-cards/Associates-degree.svg',
        selected: false,
      },
      {
        title: 'Bachelors degree',
        image: '../assets/large-cards/Bachelors-degree.svg',
        selected: false,
      },
      {
        title: 'Advanced degree',
        image: '../assets/large-cards/Advanced-degree.svg',
        selected: false,
      },
    ],
    profileAttribute: 'educationHistory',
    page: OB_PAGES.EDUCATION_HISTORY,
    previousPage: OB_PAGES.SECTION_TWO_START,
    choiceNavList: [
      {
        choice: true,
        navTo: OB_PAGES.FIELD_OF_STUDY
      },
      {
        choice: false,
        navTo: OB_PAGES.ENGLISH_FLUENCY
      }
    ]
  },
  { // question-10
    type: QUESTION_TYPES.FIELD_OF_STUDY,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'What did you study?',
    placeholder: 'Field of study',
    profileAttribute: 'fieldOfStudy',
    errorMessage: 'This field is required.',
    page: OB_PAGES.FIELD_OF_STUDY,
    previousPage: OB_PAGES.EDUCATION_HISTORY
  },
  { // question-11
    type: QUESTION_TYPES.CARD_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'Which level best describes your knowledge of English?',
    selectAllThatApply: false,
    cardSize: CARD_SIZE_ENUM.MEDIUM,
    cards: [
      {
        title: 'Beginner',
        description: 'I am learning English and could benefit from English as a Second Language (ESL) support.',
        selected: false,
      },
      {
        title: 'Learning',
        description: 'I feel comfortable using English most of the time but am still working on my speaking, reading, and writing skills.',
        selected: false,
      },
      {
        title: 'Fluent',
        description: 'I am very experienced (a fluent or native speaker) and feel confident speaking, reading, and writing in English.',
        selected: false,
      }
    ],
    profileAttribute: 'englishFluency',
    page: OB_PAGES.ENGLISH_FLUENCY,
    previousPage: OB_PAGES.EDUCATION_HISTORY
  },
  { // question-12
    type: QUESTION_TYPES.YES_NO_QUESTION,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'Are you eligible to work in the United States?',
    profileAttribute: 'workEligibleUS',
    page: OB_PAGES.WORK_ELIGIBLE_US,
    previousPage: OB_PAGES.ENGLISH_FLUENCY
  },
  { // question-13
    type: QUESTION_TYPES.YES_NO_QUESTION,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'Are you a veteran or active U.S. military personnel?',
    profileAttribute: 'militaryStatus',
    page: OB_PAGES.MILITARY_STATUS,
    previousPage: OB_PAGES.WORK_ELIGIBLE_US
  },
  { // question-14
    type: QUESTION_TYPES.SLIDER,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'What is your minimum acceptable salary?',
    sliderSteps:[
      40,
      50,
      60,
      70,
      80,
      90,
    ],
    profileAttribute: 'desiredSalary',
    page: OB_PAGES.DESIRED_SALARY,
    previousPage: OB_PAGES.MILITARY_STATUS
  },
  { // question-15
    type: QUESTION_TYPES.CARD_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question: 'Are you open to remote work?',
    selectAllThatApply: false,
    cardSize: CARD_SIZE_ENUM.LARGER,
    cards: [
      {
        title: 'In Person',
        description: 'I enjoy going to the office and working alongside colleagues.',
        image: '../assets/section-3/remote-work/Yes.png',
        selected: false,
      },
      {
        title: 'Remote',
        description: 'I prefer the ability to work from home or in various locations.',
        image: '../assets/section-3/remote-work/No.png',
        selected: false,
      },
      {
        title: 'I\'m Flexible',
        description: 'I’m open to working remotely, in the office, or a hybrid of both.',
        image: '../assets/section-3/remote-work/Only.png',
        selected: false,
      }
    ],
    profileAttribute: 'desiredRemoteWork',
    page: OB_PAGES.DESIRED_REMOTE_WORK,
    previousPage: OB_PAGES.DESIRED_SALARY,
  },
  { //question-21
    type: QUESTION_TYPES.INFO_BREAK,
    section: QUESTION_SECTIONS.SECTION_TWO,
    question:
      'Now that we know each other better,<br> let us talk about why you are here.',
    page: OB_PAGES.SECTION_TWO_END_INFO_BREAK,
    previousPage: OB_PAGES.DESIRED_REMOTE_WORK,
    picFileName: '/assets/section-2/sne-interstitial/ct-end-interstitial.png'
  },


  /** Section Three **/
  { //question-22
    type: QUESTION_TYPES.INTRO,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'Work History + Skills',
    info: 'Let us know what jobs you\'ve had so we can categorize your current skills, then align them with in-demand careers.',
    page: OB_PAGES.SECTION_THREE_START,
    previousPage: OB_PAGES.SECTION_TWO_END_INFO_BREAK
  },
  { //question-32
    type: QUESTION_TYPES.EXPERIENCE_INPUT,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'What jobs have you had?',
    info: 'Enter your work history, and we’ll predict your current skillset. Some of your skills might surprise you!',
    page: OB_PAGES.EXPERIENCE_INPUT,
    previousPage: OB_PAGES.SECTION_THREE_START
  },
  { //question-33
    type: QUESTION_TYPES.SKILLS_LOADING,
    section: QUESTION_SECTIONS.SECTION_THREE,
    page: OB_PAGES.SKILLS_LOADING,
  },
  { //question-34
    type: QUESTION_TYPES.SKILL_SELECTION_OVERVIEW,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'Select your skills',
    info: 'Skills are simply things you know how to do and behaviors are how you do them.<br>At Apprize, we organize all of this into two different categories.',
    skillTypes: [
      {
        type: SKILL_TYPES_ENUM.TRANSFERABLE,
        title: 'Power Skills + Behaviors',
        description: 'These are skills you can apply to a variety of roles. Sometimes you learn them on the job, but you can also learn them from daily life. Examples of Transferable Skills are <span class="convertToBold">Communication</span> and <span class="convertToBold">Leadership</span>.',
        imagePath: '/assets/section-4/skill-types/transferable-skills.svg',
        disabled: false,
      },
      {
        type: SKILL_TYPES_ENUM.JOB_SPECIFIC,
        title: 'Specialized Skills',
        description: 'These skills are usually more technical, and you typically acquire them while working in a specific role. For example, <span class="convertToBold">Budgeting</span> is a job-specific skill for accountants, and <span class="convertToBold">Lead Generation</span> is a skill most salespeople have.',
        imagePath: '/assets/section-4/skill-types/job-specific-skills.svg',
        disabled: false,
      },
      {
        type: SKILL_TYPES_ENUM.TOP,
        title: 'My Top Skills',
        description: 'These are the skills from the two categories above that you feel most confident in and want to highlight to employers.',
        imagePath: '/assets/section-4/skill-types/top-skills.svg',
        disabled: true,
      },
    ],
    page: OB_PAGES.SKILL_SELECTION_OVERVIEW,
    previousPage: OB_PAGES.EXPERIENCE_INPUT,
    choiceNavList: [
      {
        choice: SKILL_TYPES_ENUM.TRANSFERABLE,
        navTo: OB_PAGES.TRANSFERABLE_SKILLS
      },
      {
        choice: SKILL_TYPES_ENUM.JOB_SPECIFIC,
        navTo: OB_PAGES.JOB_SPECIFIC_SKILLS
      },
      {
        choice: SKILL_TYPES_ENUM.TOP,
        navTo: OB_PAGES.TOP_SKILLS
      },
      {
        choice: 'Save & Continue',
        navTo: OB_PAGES.KNOWS_CODING_LANGUAGES
      },
    ]
  },
  { //question-35
    type: QUESTION_TYPES.TRANSFERABLE_JOB_SPECIFIC_SKILLS,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: '<span class="boldText">Power</span> Skills + Behaviors',
    info: 'We think you learned some of these skills based on the combination of roles you described.',
    page: OB_PAGES.TRANSFERABLE_SKILLS,
    previousPage: OB_PAGES.SKILL_SELECTION_OVERVIEW,
    choiceNavList: [
      {
        choice: 'Save & Continue',
        navTo: OB_PAGES.SKILL_SELECTION_OVERVIEW
      },
    ]
  },
  { //question-36
    type: QUESTION_TYPES.TRANSFERABLE_JOB_SPECIFIC_SKILLS,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: '<span class="boldText">Specialized</span> Skills',
    info: 'We think you learned some of these skills based on the specific roles you described.',
    page: OB_PAGES.JOB_SPECIFIC_SKILLS,
    previousPage: OB_PAGES.SKILL_SELECTION_OVERVIEW,
    choiceNavList: [
      {
        choice: 'Save & Continue',
        navTo: OB_PAGES.SKILL_SELECTION_OVERVIEW
      },
    ]
  },
  { //question-37
    type: QUESTION_TYPES.TOP_SKILLS,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: '<span class="boldText">Top Skills</span>',
    info: '<span class="boldText">Select up to five top skills</span>  - in no particular order - that you would like to highlight to an employer.',
    topSkills: [
      {
        placeholderSkill: 'Top Skill 1',
        selectedSkill: undefined,
        eulerID: undefined,
        topSkill: false,
      },
      {
        placeholderSkill: 'Top Skill 2',
        selectedSkill: undefined,
        eulerID: undefined,
        topSkill: false,
      },
      {
        placeholderSkill: 'Top Skill 3',
        selectedSkill: undefined,
        eulerID: undefined,
        topSkill: false,
      },
      {
        placeholderSkill: 'Top Skill 4',
        selectedSkill: undefined,
        eulerID: undefined,
        topSkill: false,
      },
      {
        placeholderSkill: 'Top Skill 5',
        selectedSkill: undefined,
        eulerID: undefined,
        topSkill: false,
      },
    ],
    page: OB_PAGES.TOP_SKILLS,
    previousPage: OB_PAGES.SKILL_SELECTION_OVERVIEW,
    choiceNavList: [
      {
        choice: 'Finish',
        navTo: OB_PAGES.SKILL_SELECTION_OVERVIEW
      },
    ]
  },
  { //question-14
    type: QUESTION_TYPES.YES_NO_QUESTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'Do you know any coding languages?',
    profileAttribute: 'knowsCodingLangs',
    page: OB_PAGES.KNOWS_CODING_LANGUAGES,
    previousPage: OB_PAGES.SKILL_SELECTION_OVERVIEW,
    // Route to codingLanguages if yes
    choiceNavList: [
      {
        choice: 0,
        navTo: OB_PAGES.RESUME_OPTION
      },
      {
        choice: 1,
        navTo: OB_PAGES.CODING_LANGUAGES
      },
    ]
  },
  { //question-15
    type: QUESTION_TYPES.CARD_RESPONSE,
    section: QUESTION_SECTIONS.SECTION_THREE,
    question: 'Which coding languages are you proficient in?',
    selectAllThatApply: true,
    cardSize: CARD_SIZE_ENUM.SMALL,
    cards: [
      {
        title: 'HTML',
        image: '../assets/section-2/coding-languages/HTML5.png',
        selected: false,
      },
      {
        title: 'CSS',
        image: '../assets/section-2/coding-languages/CSS3.png',
        selected: false,
      },
      {
        title: 'C',
        image: '../assets/section-2/coding-languages/C.png',
        selected: false,
      },
      {
        title: 'C#',
        image: '../assets/section-2/coding-languages/C-Sharp.png',
        selected: false,
      },
      {
        title: 'C++',
        image: '../assets/section-2/coding-languages/Cplusplus.png',
        selected: false,
      },
      {
        title: 'JavaScript',
        image: '../assets/section-2/coding-languages/JavaScript.png',
        selected: false,
      },
      {
        title: 'Java',
        image: '../assets/section-2/coding-languages/Java.png',
        selected: false,
      },
      {
        title: 'php',
        image: '../assets/section-2/coding-languages/php.png',
        selected: false,
      },
      {
        title: 'Python',
        image: '../assets/section-2/coding-languages/Python.png',
        selected: false,
      },
      {
        title: 'GO',
        image: '../assets/section-2/coding-languages/GO.png',
        selected: false,
      },
      {
        title: 'SQL',
        image: '../assets/section-2/coding-languages/SQL.png',
        selected: false,
      },
      {
        title: 'Swift',
        image: '../assets/section-2/coding-languages/Swift.png',
        selected: false,
      },
    ],
    profileAttribute: 'codingLanguages',
    page: OB_PAGES.CODING_LANGUAGES,
    previousPage: OB_PAGES.KNOWS_CODING_LANGUAGES
  },
  {// question-24
    type: QUESTION_TYPES.RESUME_OPTION,
    section: QUESTION_SECTIONS.SECTION_THREE,
    page: OB_PAGES.RESUME_OPTION,
    question: 'One last thing—would you like to share your resume?',
    info: 'We\'ll use your resume to recommend you for open jobs.',
    previousPage: OB_PAGES.KNOWS_CODING_LANGUAGES,
    onboardingComplete: true,
  },
  { // question-25
    type: QUESTION_TYPES.RESUME_ATTACH,
    section: QUESTION_SECTIONS.SECTION_THREE,
    page: OB_PAGES.RESUME_ATTACH,
    previousPage: OB_PAGES.RESUME_OPTION,
    onboardingComplete: true,
  },
];

