<div class="progress-circle-container">
  <span class="progress-number">{{section}}</span>
  <div class="progress-svg-wrapper">
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 30 30"
      xml:space="preserve"
      class="svg"
    >
      <circle
        class="circle-bg"
        cx="15"
        cy="15"
        r="12"
        transform="rotate(270 15 15)"
      />
      <circle
        *ngIf="arcPercent"
        class="circle"
        cx="15"
        cy="15"
        r="12"
        [ngStyle]="{'stroke-dasharray': (arcPercent * 50) + ' ' + 50}"
        transform="rotate(270 15 15)"
      />
        </svg>
  </div>
</div>
