import { Component, Input, OnInit } from '@angular/core';
import { WhyWeAskModal } from 'src/app/types/question';

@Component({
  selector: 'ets-why-we-ask-modal',
  templateUrl: './why-we-ask-modal.component.html',
  styleUrls: ['./why-we-ask-modal.component.scss']
})
export class WhyWeAskModalComponent implements OnInit {

  constructor() { }

  @Input() whyWeAskData!: WhyWeAskModal;
  showModal = false;

  toggleModal() {
    this.showModal = !this.showModal;
  }

  ngOnInit() {
  }

}
