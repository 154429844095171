<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="true"
>
</ets-header>
<ets-sne-split-screen [rightBackgroundColor]="APP_COLORS.white"
                      [hideMobile]="'right'"
                       *ngIf="uncertainityFullScreen === false">
  <div class="left">
    <ets-sne-instruction
      [title]="questionData?.title"
      [subtitle]="questionData?.subtitle"
    ></ets-sne-instruction>
  </div>
  <div class="right">
    <ets-sne-multi-choice-list
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [inputType]="questionData?.inputType"
    >
    </ets-sne-multi-choice-list>
  </div>
</ets-sne-split-screen>

<div class="container" *ngIf="uncertainityFullScreen === true">
  <div class="content-wrapper">
    <ets-sne-multi-choice-list
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [inputType]="questionData?.inputType"
    >
    </ets-sne-multi-choice-list>
  </div>
</div>

<div class="regular-progress-bar" *ngIf="uncertainityFullScreen === false">
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [isButtonDisabled]="disableNextButton()"
  [overviewEnabled]="false"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="toggleModal()"
  [questionNum]="questionNum"
>
  <ets-sne-multi-choice-list
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
    (selectMultiResponseEvent)="multiResponseHandler($event)"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>
</ets-sne-progress-bar>
</div>

<div class="progress-bar-no-drawer" *ngIf="uncertainityFullScreen === true">
  <ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [drawerActive]="false"
  [isButtonDisabled]="disableNextButton()"
  [overviewEnabled]="true"
  (nextEmitter)="getNextQuestion()"
  [questionNum]="questionNum"
  (overviewEmitter)="toggleModal()"
>
</ets-sne-progress-bar>
</div>


<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-email-layout *ngIf="instructionModal?.customContent[0].to"
                          [emails]="instructionModal?.customContent"
    ></ets-sne-email-layout>
    <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user"
                                   [chatLog]="instructionModal?.customContent"
                                   [showHeader]="false">
    </ets-sne-conversation-log-text>
  </div>
</ets-modal>
<ets-modal
[title]="questionData?.hintModal?.title"
[showModal]="showHintModal"
[list]="questionData?.hintModal?.pageList"
(modalClose)="hintToggle()"
[hint]="true"
>
</ets-modal>

