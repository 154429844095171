import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LearnerCareersResponse } from '../types/career-prioritization';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService{
    private dashboardURL = environment.dashboardAPI;
    constructor(private http: HttpClient, protected logService: LogService) {
      super(logService);
      this.serviceName = 'Dashboard';
    }
    
    getDashboardData(): Observable<any> {
      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        let headers = new HttpHeaders().set('Authorization', accessToken);
    
        return this.http.get<LearnerCareersResponse>(this.dashboardURL, {headers: headers}).pipe(
          tap(data => {
            let responseLogObj: LogObject = {
              message: 'Dashboard: Get Learner Careers Response',
              object: data,
            };
            this.logService.logInfo(responseLogObj)?.subscribe();
          }),
          catchError(this.handleError)
        );
      }
      // Shouldn't happen
      return new Observable<any>();
    }

}