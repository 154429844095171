<div class="page-container" [attr.aria-hidden]="modalOpen">
    <div class="content-container">
        <div class="info-skills-wrapper">
            <div class="info-container">
                <img class="icon" src="./assets/section-4/skill-types/transferable-skills.svg" *ngIf="currentQuestion?.page === 'transferableSkills' " alt="" aria-hidden="true" />
                <img class="icon" src="./assets/section-4/skill-types/job-specific-skills.svg" *ngIf="currentQuestion?.page === 'jobSpecificSkills' " alt="" aria-hidden="true"/>
                <div class="right">
                    <h1 class="title" [innerHTML]="title" [attr.aria-hidden]="modalOpen" >{{title}}</h1>
                    <h1 class="info" [attr.aria-hidden]="modalOpen">{{info}}</h1>
                    <h1 class="sub-info" [attr.aria-hidden]="modalOpen">{{subInfo}}</h1>
                </div>
            </div>
            <div class="skills-container">
                <div class="sort">
                    <p [attr.aria-hidden]="modalOpen">Sort by experience:</p>
                    <div class="dropdown">
                        <button class="dropbtn" role="button" (click)="toggleOpenMenu()">
                            {{selectedRole}} 
                            <img src="/assets/on-boarding/dropdown-arrow-down.svg" alt="" aria-hidden="true"/>
                        </button>
                        <div role="button" [class]="menuOpen ? 'open-dropdown-content' : 'dropdown-content' ">
                            <a (click)="filterByRole('All roles')" role="button" (click)="toggleOpenMenu()">All roles</a>
                            <a (click)="filterByRole(role)" role="button" (click)="toggleOpenMenu()" *ngFor="let role of uniqueRoleTitles; let index = index">
                                {{role}}
                            </a>
                        </div>
                      </div>
                </div>
                <div class="blue-line"></div>
                <div class="scroll-container">
                    <div class="skill-pill-container">
                        <div class="skill-pills"
                            *ngFor="let skillPill of top25; let index = index"
                            [ngClass]="{'selected': skillPill.eulerSkillName && userSelectedSkills.includes(skillPill)}"
                            (click)="toggleSkill(skillPill)"
                            role="button"
                        >
                            {{skillPill.eulerSkillName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="flex" >
          <ets-rounded-contained-button
            [buttonText]="'Save & Continue'"
            [backgroundColor]="'#0E3AD6'"
            [textColor]="'#FFFFFF'"
            [disabled]="disableSaveAndContinue()"
            (clickEvent)="saveAndContinue()"
            class="bottom-button"
          >
          </ets-rounded-contained-button>
          <ets-rounded-contained-button
            [buttonText]="'Auto-Select'"
            [backgroundColor]="'#FFFFFF'"
            [textColor]="'#0E3AD6'"
            [borderColor]="'#0E3AD6'"
            (clickEvent)="openModal()"
            class="bottom-button"
          >
          </ets-rounded-contained-button>
        </div>
    </div>
</div>

<!-- MODAL -->
<clr-modal [(clrModalOpen)]="modalOpen" [clrModalClosable]="true">
    <h3 class="modal-title" >This will select skills for you based on your work history.</h3>
    <div class="modal-body">
      <p class="modal-body-text">
          Continuing this process will overwrite any selections you've already made.
      </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline modal-solid-btn" (click)="autoSelect()">Continue</button>
        <button type="button" class="btn btn-outline modal-outline-btn" (click)="cancel()">Cancel</button>
    </div>
</clr-modal>