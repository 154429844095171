<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>

<div class="container">
  <div class="content-wrapper">
    <ets-sne-multi-choice-list
      [headerQuestion]="questionData?.header"
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
      [inputType]="questionData?.inputType"
      [questionIndex]="questionIndex"
    >
    </ets-sne-multi-choice-list>
  </div>
</div>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  [drawerActive]="false"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="disableNextButton()"
>
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video 
      *ngIf="instructionModal?.customContent[0].url"
      [videoUrl]="instructionModal?.customContent[0].url"
      [videoId]="instructionModal?.customContent[0].id"
      [videoOnlyFlag]="true"
      [playOnce]="instructionModal?.customContent[0].playOnce"
      [sharedVideo]="instructionModal?.customContent[0].sharedVideo"
    >
    </ets-sne-video>
  </div>
</ets-modal>