import { Component } from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent {

  constructor(private eventService: EventService) { }

  trackProfileNavClicks(selection: string) {
    switch (selection) {
    case 'Account':
      // Track the event in AWS
      this.eventService.buildEvent('Account Nav Bar button', EventActionEnum.CLICK);
      break;
    case 'Profile':
      // Track the event in AWS
      this.eventService.buildEvent('Profile Nav Bar button', EventActionEnum.CLICK);
      break;
    case 'Diversity & Privacy':
      // Track the event in AWS
      this.eventService.buildEvent('Diversity & Privacy Nav Bar button', EventActionEnum.CLICK);
      break;
    case 'Work Preferences':
      // Track the event in AWS
      this.eventService.buildEvent('Work Preferences Nav Bar button', EventActionEnum.CLICK);
      break;
    case 'Quiz Results':
      // Track the event in AWS
      this.eventService.buildEvent('Quiz Results Nav Bar button', EventActionEnum.CLICK);
      break;  
    default:
      // Track the event in AWS
      this.eventService.buildEvent('Profile Pages Nav Bar button', EventActionEnum.CLICK);
    }
  }

}
