export interface RankingQuestion {
    rankingTitle: string;
    rankingDescription: string;
    rankingType: string; // ENJOY/AGREE/YES_NO
    answers: string[];
}

// export const ENJOY_STATES = [
//     {id: "ANSWERS", value: ""},
//     {id: "WOULD_NOT_ENJOY", value: "Wouldn't enjoy"},
//     {id: "PROBABLY_NOT", value: "Probably not"},
//     {id: "NEUTRAL", value: "Neutral"},
//     {id: "PROBABLY_YES", value: "Probably yes"},
//     {id: "WOULD_ENJOY", value: "Would enjoy"}
// ];

export const ENJOY_STATES = [
  {id: 'ANSWERS', value: ''},
  {id: 'STRONGLY_DISLIKE', value: 'Strongly dislike'},
  {id: 'DISLIKE', value: 'Dislike'},
  {id: 'NEUTRAL', value: 'Neutral'},
  {id: 'LIKE', value: 'Like'},
  {id: 'STRONGLY_LIKE', value: 'Strongly like'}
];


export const AGREE_STATES = [
  {id: 'ANSWERS', value: ''},
  {id: 'STRONGLY_DISAGREE', value: 'Strongly disagree'},
  {id: 'DISAGREE', value: 'Disagree'},
  {id: 'SLIGHTLY_DISAGREE', value: 'Slightly disagree'},
  {id: 'SLIGHTLY_AGREE', value: 'Slightly agree'},
  {id: 'AGREE', value: 'Agree'},
  {id: 'STRONGLY_AGREE', value: 'Strongly agree'}
];

export const YES_NO_STATES = [
  {id: 'ANSWERS', value: ''},
  {id: 'NO', value: 'No'},
  {id: 'YES', value: 'Yes'}
];

export const LIKE_STATES = [
  {id: 'ANSWERS', value: ''},
  {id: 'STRONGLY_DISLIKE', value: 'Strongly dislike'},
  {id: 'DISLIKE', value: 'Dislike'},
  {id: 'NEUTRAL', value: 'Neutral'},
  {id: 'LIKE', value: 'Like'},
  {id: 'STRONGLY_LIKE', value: 'Strongly like'}
];
