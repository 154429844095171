import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { APP_COLORS } from './../../../../global-styles';
import { ActivatedRoute, Router } from '@angular/router';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ProfileService } from 'src/app/services/profile.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SkillsService } from 'src/app/services/skills.service';
import { SkillsResponse } from 'src/app/types/skills';
import { SKILL_TYPES_ENUM, ISkills } from 'src/app/types/question';
import { EventService } from 'src/app/services/event.service';
import { setSkillsInFlight } from 'src/app/store/on-boarding.actions';

@Component({
  selector: 'ets-top-skills',
  templateUrl: './top-skills.component.html',
  styleUrls: ['./top-skills.component.scss'],
})
export class TopSkillsComponent extends OnBoardingV2Base implements OnInit {
  constructor(
    protected store: Store,
    protected router: Router,
    protected route: ActivatedRoute,
    protected profileService: ProfileService,
    protected eventService: EventService,
    protected skillsService: SkillsService
  ) {
    super(store, router, route, profileService, eventService);
  }

  APP_COLORS = APP_COLORS;
  headerState = HEADER_STATES.BACK;
  SKILL_TYPES_ENUM = SKILL_TYPES_ENUM;

  topSkillsReqBod?: { eulerID: number | undefined; topSkill: boolean }[] = []; // This is the body of data that is sent with POST Req
  skillsResponse!: SkillsResponse; // Takes in skill data
  transferableSkills: ISkills[] = []; // Holds Transferable Skills Data
  jobSpecificSkills: ISkills[] = []; // Holds Job Specific Data
  topSkillCount: number = 0; // Keeps count of top skills user selected, once reaches 5 disables all skills;
  originalTopSkills: string[] = []; // list of eulerID's of Top Skills at time of page load

  ngOnInit() {
    this.initialize();
    this.clearTopSkills();
    this.getSkills();
  }

  getSkills() {
    this.skillsService.getSkills().subscribe({
      next: (response) => {
        this.skillsResponse = response;
        this.skillsResponse.selfInput?.forEach((skill) => {
          const { eulerID, eulerSkillName, skillType, topSkill } = skill;
          const skillData = {
            eulerID: eulerID,
            eulerSkill: eulerSkillName,
            isTopSkill: false,
          };

          if (topSkill) {
            this.originalTopSkills.push(eulerID);
            this.handlesSelectSkill(skillData);
          }

          switch (skillType) {
            case SKILL_TYPES_ENUM.TRANSFERABLE:
              this.transferableSkills.push(skillData);
              break;
            case SKILL_TYPES_ENUM.JOB_SPECIFIC:
              this.jobSpecificSkills.push(skillData);
              break;
            default:
              console.log('Unexpected skillType:', skill.skillType);
          }
        });
      },
    });
  }

  clearTopSkills() {
    if (this.currentQuestion?.topSkills) {
      this.currentQuestion.topSkills.forEach(topSkill => {
        topSkill.eulerID = undefined;
        topSkill.selectedSkill = undefined;
        topSkill.topSkill = false;
      });
    }
  }

  handlesSelectSkill(skillsObj: ISkills) {
    if (skillsObj) {
      skillsObj.isTopSkill = !skillsObj.isTopSkill;
      if (this.currentQuestion?.topSkills) {
        let objItemsToReplace = this.currentQuestion.topSkills.find(
          (el) => el.selectedSkill === undefined
        );
        if (objItemsToReplace) {
          objItemsToReplace.selectedSkill = skillsObj.eulerSkill;
          objItemsToReplace.topSkill = skillsObj.isTopSkill;
          objItemsToReplace.eulerID = skillsObj.eulerID;
          this.topSkillCount++;
        }
      }
    }
  }

  selectTransferableSkill(id: number) {
    this.handlesSelectSkill(this.transferableSkills[id]);
  }

  selectJobSpecificSkill(id: number) {
    this.handlesSelectSkill(this.jobSpecificSkills[id]);
  }

  handlesRemoveTopSkill(skillsArray: ISkills[], id: number) {
    if (skillsArray) {
      if (this.currentQuestion?.topSkills?.[id]) {
        const { selectedSkill } = this.currentQuestion?.topSkills?.[id];
        const foundObj = skillsArray.find(
          (skill) => skill.eulerSkill === selectedSkill
        );
        if (foundObj?.isTopSkill) {
          foundObj.isTopSkill = false;
          this.currentQuestion.topSkills[id].selectedSkill = undefined;
          this.currentQuestion.topSkills[id].topSkill = false;
          this.currentQuestion.topSkills[id].eulerID = undefined;
          this.topSkillCount--;
        }
      }
    }
  }

  removeTopSkill(id: number) {
    if (this.currentQuestion?.topSkills?.[id] && this.currentQuestion.topSkills[id].selectedSkill) {
      this.handlesRemoveTopSkill(this.transferableSkills, id);
      this.handlesRemoveTopSkill(this.jobSpecificSkills, id);
    }
  }

  disabledTopSkill(id: number): boolean {
    let disabled: boolean;
    if (this.currentQuestion?.topSkills?.[id].selectedSkill !== undefined) {
      disabled = false;
    } else {
      disabled = true;
    }
    return disabled;
  }

  saveAndContinue() {
    this.userResponse = 'Finish';
    if (this.currentQuestion?.topSkills?.length) {
      this.currentQuestion.topSkills.forEach((skill) => {
        const { eulerID, topSkill } = skill;
        if (eulerID && topSkill) {
          const data = { eulerID: eulerID, topSkill: topSkill };
          this.topSkillsReqBod?.push(data);
        }
      });

      // Add skills that were deselected to the request
      let newTopSkills = this.topSkillsReqBod?.map(skill => skill.eulerID);
      this.originalTopSkills.forEach(eulerID => {
        if (!newTopSkills?.includes(+eulerID)) {
          const data = { eulerID: +eulerID, topSkill: false };
          this.topSkillsReqBod?.push(data);
        }
      });

      this.store.dispatch(setSkillsInFlight({ payload: true }));
      this.skillsService.saveProfileSkills({'skills': this.topSkillsReqBod}).subscribe(
        (response) => this.store.dispatch(setSkillsInFlight({ payload: false })),
      );
    }
    this.submitResponse();
  }
}
