<div class="container"
  [ngClass]="{'align-center': centerAlign}"
>
  <span class="question" [innerHTML]="headerQuestion"></span>
  <div *ngIf="inputType === SNE_INPUT_TYPES.RADIO">
    <div class="option" *ngFor="let option of multiChoiceOptions; let i = index;">
      <ets-radio-button (clickEvent)="selectSingleResponse(option.userLetter)"
                        [value]="option.userLetter"
                        [name]="'answer'"
                        [id]="mobileCheck ? option.userLetter + '-mobile' : option.userLetter"
                        [label]="option.userOption"
      >
      </ets-radio-button>
    </div>
  </div>
  <div *ngIf="inputType === SNE_INPUT_TYPES.CHECKBOX">
    <div class="options" *ngFor='let option of multiChoiceOptions; let i = index;'>
      <div class="option" *ngIf="inputType && inputType === SNE_INPUT_TYPES.CHECKBOX" >
        <ets-checkbox (clickEvent)="selectMultiResponse(option.userLetter)"
                      [value]="option.userLetter"
                      [name]="option.userLetter"
                      [id]="mobileCheck ? option.userLetter + '-mobile' : option.userLetter"
                      [label]="option.userOption"
        >
        </ets-checkbox>
      </div>
    </div>
  </div>
</div>
