import {
  SneQuestion,
  SNE_INPUT_TYPES,
  SneMulChoiceOption,
  SneModal,
  SocialMediaPost,
  ChatMessage,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';

const SocialMediaThread_1: SocialMediaPost[] = [

  {
    name: 'Don',
    username: 'Don_Barco',
    image: '/../assets/sne/ct-argumentation-profile-pic.png',
    message: 'I had a great time at the public speaking training! Plus I’m sure that what I learned has improved my communication skills. Since the training, I’ve given speeches at my daughter’s after-school club and a local town hall meeting. Both times, people said I did a great job! And obviously, I’m thrilled about our record-breaking June sales. But I don’t believe the training has any connection to this recent sales increase. The increase is almost surely due to other factors. For one thing, we’ve hired several new, highly-talented sales reps in the past few weeks, and none of them took the training. I took a look at our sales figures, and sales had been increasing before the training started. So we were already on a general upward trend before June.',
  },
  {
    name: 'Elena',
    username: 'Elena_Ryba',
    image: '/../assets/sne/ct-elena-profile-pic.png',
    message: 'The public speaking training many of our colleagues took at the beginning of June has paid off in an amazing way! I was just going over some recent sales figures, and I noticed a big increase. I know that we always do well in June, because the weather’s beautiful and people are eager to work in their gardens. But this June, sales have been higher than any other month in the company’s history! The training must have really helped our staff work more effectively with our customers. I’m super proud of our team and delighted by these results.',
  },
];

const SocialMediaThread_2_3: SocialMediaPost[] = [

  {
    name: 'Don',
    username: 'Don_Barco',
    image: '/../assets/sne/ct-argumentation-profile-pic.png',
    message: '<span class=\'convertToBlue\'>I had a great time at the public speaking training! Plus I’m sure that what I learned has improved my communication skills.</span> Since the training, I’ve given speeches at my daughter’s after-school club and a local town hall meeting. Both times, people said I did a great job! And obviously, I’m thrilled about our record-breaking June sales. <span class=\'convertToBlue\'>But I don’t believe the training has any connection to this recent sales increase.</span> The increase is almost surely due to other factors. For one thing, we’ve hired several new, highly-talented sales reps in the past few weeks, and none of them took the training. I took a look at our sales figures, and sales had been increasing before the training started. So we were already on a general upward trend before June.',
  },
  {
    name: 'Elena',
    username: 'Elena_Ryba',
    image: '/../assets/sne/ct-elena-profile-pic.png',
    message: 'The public speaking training many of our colleagues took at the beginning of June has paid off in an amazing way! I was just going over some recent sales figures, and I noticed a big increase. I know that we always do well in June, because the weather’s beautiful and people are eager to work in their gardens. But this June, sales have been higher than any other month in the company’s history! The training must have really helped our staff work more effectively with our customers. I’m super proud of our team and delighted by these results.',
  },
];

const SocialMediaThread_4: SocialMediaPost[] = [

  {
    name: 'Don',
    username: 'Don_Barco',
    image: '/../assets/sne/ct-argumentation-profile-pic.png',
    message: 'I had a great time at the public speaking training! Plus I’m sure that what I learned has improved my communication skills. Since the training, I’ve given speeches at my daughter’s after-school club and a local town hall meeting. Both times, people said I did a great job! <span class=\'convertToBlue\'>And obviously, I’m thrilled about our record-breaking June sales.</span> But I don’t believe the training has any connection to this recent sales increase. The increase is almost surely due to other factors. <span class=\'convertToBlue\'>For one thing, we’ve hired several new, highly-talented sales reps in the past few weeks, and none of them took the training.</span> I took a look at our sales figures, and sales had been increasing before the training started. <span class=\'convertToBlue\'>So we were already on a general upward trend before June.</span>',
  },
  {
    name: 'Elena',
    username: 'Elena_Ryba',
    image: '/../assets/sne/ct-elena-profile-pic.png',
    message: '<span class=\'convertToBlue\'>The public speaking training many of our colleagues took at the beginning of June has paid off in an amazing way!</span> I was just going over some recent sales figures, and I noticed a big increase. <span class=\'convertToBlue\'>I know that we always do well in June, because the weather’s beautiful and people are eager to work in their gardens. But this June, sales have been higher than any other month in the company’s history!</span> The training must have really helped our staff work more effectively with our customers. I’m super proud of our team and delighted by these results.',
  },
];

const question_5_answers: SneMulChoiceOption[] = [
  {
    userOption: 'Don\'s opinion',
    userLetter: 'A',
  },
  {
    userOption: 'Elena\'s opinion',
    userLetter: 'B',
  },
  {
    userOption: 'Supports neither opinion',
    userLetter: 'C',
  }
];

const question_5_colleague_comments: ChatMessage[] = [
  {
    user: 'Colleague 1',
    message: 'I talked to several customers, and they all said our friendly, helpful staff people convinced them to make purchases. All of these staff members took the public speaking training.​',
  },
  {
    user: 'Colleague 2',
    message: 'We added several new products to our inventory in June. They’ve all been incredibly popular and sold very well.',
  },
  {
    user: 'Colleague 3',
    message: 'The most popular garden tools in the United States include rakes, hoses, trowels, and spades. Tomatoes are the most popular vegetable for home gardens.',
  },
];

export const CTArgumentation1Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Analyzing an Online Conversation',
      info: 'This task will give you insight into how you interpret other people’s commentary, and we will use what we learn together to enhance your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/argumentation.svg', // placeholder
      questionIndex: 5,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA,
      inputType: SNE_INPUT_TYPES.RADIO,
      socialMediaPost: [...SocialMediaThread_1],
      multipleMulChoiceOptions: [
        {
          question: 'Think about Don and Elena’s social media posts. Select the statement that <span class="convertToBlue">best</span> describes the order in which the posts were probably written.',
          mulChoiceOptions: [
            {
              userOption: 'Elena wrote her post first, and Don wrote his post in response to Elena’s.',
              userLetter: 'A',
            },
            {
              userOption: 'Don wrote his post first, and Elena wrote her post in response to Don’s.',
              userLetter: 'B',
            },
            {
              userOption: 'It is not possible to tell which of the posts was written first.',
              userLetter: 'C',
            }
          ]
        }
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA,
      inputType: SNE_INPUT_TYPES.RADIO,
      socialMediaPost: [...SocialMediaThread_2_3],
      multipleMulChoiceOptions: [
        {
          question: 'From the three highlighted sentences in Don’s post, select the one that <span class="convertToBlue">best</span> shows that Don wrote his post in response to Elena.',
          mulChoiceOptions: [
            {
              userOption: 'I had a great time at the public speaking training!',
              userLetter: 'A',
            },
            {
              userOption: 'Plus I’m sure that what I learned has improved my communication skills.',
              userLetter: 'B',
            },
            {
              userOption: 'But I don’t believe the training has any connection to this recent sales increase.',
              userLetter: 'C',
            }
          ]
        }
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA,
      inputType: SNE_INPUT_TYPES.RADIO,
      socialMediaPost: [...SocialMediaThread_1],
      multipleMulChoiceOptions: [
        {
          question: 'On which of these points, if any, would Don and Elena <span class="convertToBlue">most likely</span> agree?',
          mulChoiceOptions: [
            {
              userOption: 'The weather in early summer is perfect for gardening.',
              userLetter: 'A',
            },
            {
              userOption: 'The public speaking training was enjoyable.',
              userLetter: 'B',
            },
            {
              userOption: 'The company’s sales figures rose in June.',
              userLetter: 'C',
            },
            {
              userOption: 'Don and Elena’s posts do not include any points of agreement.​',
              userLetter: 'D',
            }
          ]
        }
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_SOCIAL_MEDIA,
      inputType: SNE_INPUT_TYPES.RADIO,
      socialMediaPost: [...SocialMediaThread_4],
      multipleMulChoiceOptions:[
        {
          question: 'Which sentences in the posts best show that <span class=\'convertToBlue\'>Don</span> agrees with that the company\'s sales figures rose in June?',
          mulChoiceOptions: [
            {
              userOption: 'And obviously, I’m thrilled about our record-breaking June sales.',
              userLetter: 'A',
            },
            {
              userOption: 'For one thing, we’ve hired several new, highly-talented sales reps in the past few weeks, and none of them took the training.',
              userLetter: 'B',
            },
            {
              userOption: 'So we were already on a general upward trend before June.',
              userLetter: 'C',
            },
          ]
        },
        {
          question: 'Which sentences in the posts best show that <span class=\'convertToBlue\'>Elena</span> agrees that the company’s sales figures rose in June?',
          mulChoiceOptions:[
            {
              userOption: 'The public speaking training many of our colleagues took at the beginning of June has paid off in an amazing way! ',
              userLetter: 'A',
            },
            {
              userOption: 'I know that we always do well in June, because the weather’s beautiful and people are eager to work in their gardens.',
              userLetter: 'B',
            },
            {
              userOption: 'But this June, sales have been higher than any other month in the company’s history! ',
              userLetter: 'C',
            },
          ]
        }
      ],
      submitId: 'item_4',
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_COMMENTS,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'COMMENTS',
      chatMessages: [...question_5_colleague_comments],
      header: 'Three of Don and Elena\'s colleagues also commented on the discussion. Read the comments by Don and Elena\’s colleagues and determine which opinion each comment supports.​',
      numSelectOptions: 3,
      multipleMulChoiceOptions: [
        {
          question: 'Colleague 1',
          mulChoiceOptions: [...question_5_answers],    
        },
        {
          question: 'Colleague 2',
          mulChoiceOptions: [...question_5_answers],
        },
        {
          question: 'Colleague 3',
          mulChoiceOptions: [...question_5_answers],
        },
      ],
      submitId: 'item_5',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Excellent work reviewing Don’s and Elena’s posts! Your decisions help illustrate how you interpret your colleagues, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready. One more to go!',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png', // placeholder
      questionIndex: 5,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: CT_TYPES.ARGUMENTATION_2
    },
  ],
]);

export const CTArgumentation1InstructionModal: SneModal = {
  title: 'Social Media Posts',
  pageList: [
    {
      info: 'Don and Elena both work for a garden supply company that operates several retail stores as well as an online business. Recently, a number of employees at the company completed a public speaking training.<br><br>You will read two posts about the training that Don and Elena shared to the company’s social media channel.',
    },
  ],
};
