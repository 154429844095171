<!-- MAIN MENU -->
<div class="menu-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.MAIN_MENU">
  <button class="menu-button"
          (click)="emitRequestInfoBtnClick()"
          [disabled]="disableRequestInfoBtn"
  >
    Request Information
  </button>
  <button class="menu-button"
          (click)="emitShareInfoBtnClick()"
          [disabled]="disableShareInfoBtn"
  >
    Share Information
  </button>
  <button class="menu-button"
          (click)="emitPickVendorBtnClick()"
  >
    Pick a vendor
  </button>
</div>

<!-- REQUEST INFO -->
<div class="menu-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.REQUEST_INFO">
  <button class="menu-button"
          (click)="emitRequestAInfoBtnClick()"
          [disabled]="disableRequestAInfoBtn"
  >
    Tell Me About Vendor A
  </button>
  <button class="menu-button"
          (click)="emitRequestBInfoBtnClick()"
          [disabled]="disableRequestBInfoBtn"
  >
    Tell Me About Vendor B
  </button>
  <button class="menu-button"
          (click)="emitReturnToMenuBtnClick()"
  >
    Return to Menu
  </button>
</div>

<!-- IS INFO HELPFUL -->
<div class="input-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.IS_INFO_HELPFUL">
  <div class="helpful-info-container">
    <h3 class="helpful-info-title">Is this information helpful?</h3>
    <ets-radio-button
      *ngFor="let sharedFactResponse of HP_SHARED_FACTS_MAP | keyvalue; let index = index"
      (clickEvent)="saveHelpfulInfoUserResponse(sharedFactResponse.key)"
      [value]="sharedFactResponse.key"
      [name]="'shared-fact-response'"
      [id]="sharedFactResponse.key"
      [label]="sharedFactResponse.value.message"
    >
    </ets-radio-button>

    <button class="menu-button menu-button-margin-top"
            [disabled]="!helpfulInfoUserResponseKey"
            (click)="emitHelpfulInfoUserResponseClick()"
    >
      Confirm
    </button>
  </div>
</div>

<!-- SHARE INFO -->
<div class="input-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.SHARE_INFO">
  <div class="vendor-fact-container">
    <h3 class="vendor-title">Software Vendor A</h3>
    <ets-radio-button
      *ngFor="let vendorFact of shareInfoVendorAFactList; let index = index"
      (clickEvent)="saveSharedVendorFact(vendorFact.id, VENDOR_SET.A)"
      [value]="vendorFact.id"
      [name]="'vendor-fact'"
      [id]="vendorFact.id"
      [label]="'#' + (index+1) + ': ' + vendorFact.fact"
      [disabled]="vendorFact.botShared === true"
    >
    </ets-radio-button>
  </div>
  <div class="vendor-fact-container">
    <h3 class="vendor-title">Software Vendor B</h3>
    <ets-radio-button
      *ngFor="let vendorFact of shareInfoVendorBFactList; let index = index"
      (clickEvent)="saveSharedVendorFact(vendorFact.id, VENDOR_SET.B)"
      [value]="vendorFact.id"
      [name]="'vendor-fact'"
      [id]="vendorFact.id"
      [label]="'#' + (index+1) + ': ' + vendorFact.fact"
      [disabled]="vendorFact.botShared === true"
    >
    </ets-radio-button>
  </div>
  <button class="menu-button"
          [disabled]="!this.sharedVendorFact"
          (click)="emitShareInfoUserResponseClick()"
  >
    Confirm
  </button>
  <button class="menu-button menu-button-negative-margin-top"
    (click)="emitReturnToMenuBtnClick()"
  >
    Return To Menu
  </button>
</div>

<!-- PICK A VENDOR -->
<div class="menu-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.PICK_VENDOR">
  <button class="menu-button"
          (click)="emitPickVendorABtnClick()"
  >
    I Want To Select Vendor A
  </button>
  <button class="menu-button"
          (click)="emitPickVendorBBtnClick()"
  >
    I Want To Select Vendor B
  </button>
  <button class="menu-button"
          (click)="emitReturnToMenuBtnClick()"
  >
    Return To Menu
  </button>
</div>

<!-- PICK A VENDOR CONFIRMATION -->
<div class="menu-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.PICK_VENDOR_CONFIRMATION">
  <button class="menu-button"
          (click)="emitConfirmVendorPickBtnClick()"
  >
    Yes, I am certain
  </button>
  <button class="menu-button"
          (click)="emitReconsiderVendorPickBtnClick()"
  >
    No, let's think some more
  </button>
</div>

<div class="menu-container" *ngIf="menuType === HIDDEN_PROFILE_MENU_TYPES.BLANK_MENU">
  <button class="menu-button"
          (click)="emitGoToMainMenu()"
  >
    Continue
  </button>
</div>
