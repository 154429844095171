import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardV2Component } from './dashboard-v2.component';
import { DashboardSideBarComponent } from './dashboard-side-bar/dashboard-side-bar.component';
import { DashboardProgressBarComponent } from './dashboard-progress-bar/dashboard-progress-bar.component';
import { DashboardCareersComponent } from './dashboard-careers/dashboard-careers.component';
import { DashboardSkillsComponent } from './dashboard-skills/dashboard-skills.component';
import { DashboardBehaviorInterestsComponent } from './dashboard-behavior-interests/dashboard-behavior-interests.component';

import { CareerCardComponent } from './components/career-card/career-card.component';
import { ClarityModule } from '@clr/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SkillCardComponent } from './components/skill-card/skill-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProgressNumberComponent } from './components/progress-number/progress-number.component';
import { SharedModule } from '../shared/shared.module';
import { CareerChecklistComponent } from './dashboard-side-bar/components/career-checklist/career-checklist.component';
import { FoundationComponent } from './dashboard-side-bar/components/foundation/foundation.component';
import { TopSkillsComponent } from './dashboard-side-bar/components/top-skills/top-skills.component';
import { ResumeComponent } from './dashboard-side-bar/components/resume/resume.component';
import { OpenNewRolesComponent } from './dashboard-side-bar/components/open-new-roles/open-new-roles.component';
import {RouterModule} from '@angular/router';
import { AssessmentCompleteComponent } from './components/assessment-complete/assessment-complete.component';

@NgModule({
  declarations: [
    DashboardV2Component,
    DashboardSideBarComponent,
    DashboardProgressBarComponent,
    DashboardCareersComponent,
    DashboardSkillsComponent,
    DashboardBehaviorInterestsComponent,
    ProgressNumberComponent,
    CareerCardComponent,
    SkillCardComponent,
    CareerChecklistComponent,
    FoundationComponent,
    TopSkillsComponent,
    ResumeComponent,
    OpenNewRolesComponent,
    AssessmentCompleteComponent,
  ],
  imports: [
    CommonModule,
    ClarityModule,
    NgxChartsModule,
    FontAwesomeModule,
    SharedModule,
    RouterModule,
  ]
})
export class DashboardV2Module { }
