<div class="container">
  <div class="nav-wrapper">
    <div class="link-wrapper">
      <a routerLinkActive="active" (click)='trackProfileNavClicks("Account")' [routerLink]="['/account-profile/account']">Account</a>
    </div>
    <!-- <div class="link-wrapper">
      <a routerLinkActive="active" (click)='trackProfileNavClicks("Profile")' [routerLink]="['/account-profile/profile']">Profile</a>
    </div>
    <div class="link-wrapper">
      <a routerLinkActive="active" (click)='trackProfileNavClicks("Diversity & Privacy")' [routerLink]="['/account-profile/diversity-privacy']">Diversity & Privacy</a>
    </div>
    <div class="link-wrapper">
      <a routerLinkActive="active" (click)='trackProfileNavClicks("Work Preferences")' [routerLink]="['/account-profile/work-preferences']">Work Preferences</a>
    </div>
    <div class="link-wrapper">
      <a routerLinkActive="active" (click)='trackProfileNavClicks("Quiz Results")' [routerLink]="['/account-profile/quiz-results']">Quiz Results</a>
    </div> -->
  </div>
</div>
