import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Assessment, PARENT_ASSESSMENT_HRID } from 'src/app/types/assessments';
import { JobPosting } from 'src/app/types/job-posting';

@Component({
  selector: 'ets-job-posting-card',
  templateUrl: './job-posting-card.component.html',
  styleUrls: ['./job-posting-card.component.scss']
})
export class JobPostingCardComponent {

  constructor() { }

  @Input() jobPosting!: JobPosting;
  @Input() assessments: Assessment[] | undefined;
  @Input() learnerID: number | undefined;
  @Input() email: string | undefined;

  learnMore() {
    // Build the URL to the job posting detail page in Weblfow
    let urlParams = "?";
    if (this.learnerID !== undefined) {
      urlParams += "learnerID=" + this.learnerID + "&";
    }

    if (this.email !== undefined) {
      urlParams += "email=" + this.email + "&";
    }
    
    if (this.assessments) {
      this.assessments.forEach(assessment => {
        // If the assessment is a parent assessment, add the HRID to the URL along with if it's completed
        if (assessment.assessmentHumanReadableID && (<any>Object).values(PARENT_ASSESSMENT_HRID).includes(assessment.assessmentHumanReadableID)) {
          urlParams += `${assessment.assessmentHumanReadableID}=${assessment.completed}&`;
        }
      });
    }

    // Remove the trailing '&'
    if (urlParams.endsWith("&")) {
      urlParams = urlParams.slice(0, -1);
    }
  
    window.open(this.jobPosting.jobPostUrl + urlParams, '_self');
  }
}
