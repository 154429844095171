<div class="container">
  <button
    *ngFor="let skill of topSkills"
    class="top-skill"
    [disabled]="true"
  >
    {{skill.skillName}}
  </button>

</div>
