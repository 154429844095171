import { SneQuestion, SNE_INPUT_TYPES, SneModal, Screens } from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';

const flowMapImages: Screens = {
  desktop: './assets/section-2/production-flow-maps/desktop-flow-map.svg',
  tablet: './assets/section-2/production-flow-maps/tablet-flow-map.svg',
  mobile: './assets/section-2/production-flow-maps/mobile-flow-map.svg',
};

export const CTProductionData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Analyzing a Process',
      info: 'This task will help you better understand how you interpret visual information. We will add these insights to your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/production.svg',
      questionIndex: 2,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 15 minutes, and you will have a chance to pause when you finish.',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Production Flow Map',
      flowMapImgs: {...flowMapImages},
      operationsLog: {
        desktop: './assets/section-2/production-flow-maps/desktop-operations-log-ques1.svg',
        mobile: './assets/section-2/production-flow-maps/mobile-operations-log-ques1.svg',
      },
      multipleMulChoiceOptions: [
        {
          question: 'Based on the messages shown in the operations log, which of the following processes <span class="convertToBlue">must</span> have aborted?',
          mulChoiceOptions: [
            {
              userOption: 'Process A',
              userLetter: 'A',
            },
            {
              userOption: 'Process B',
              userLetter: 'B',
            },
            {
              userOption: 'Process C',
              userLetter: 'C',
            },
            {
              userOption: 'Process D',
              userLetter: 'D',
            },
          ],
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Production Flow Map',
      flowMapImgs: {...flowMapImages},
      operationsLog: {
        desktop: './assets/section-2/production-flow-maps/desktop-operations-log-ques2.svg',
        mobile: './assets/section-2/production-flow-maps/mobile-operations-log-ques2.svg',
      },
      multipleMulChoiceOptions: [
        {
          question: 'Which of the following messages <span class="convertToBlue">cannot</span> appear <span class="convertToBlue">between</span> the two messages shown in the operations log?',
          mulChoiceOptions: [
            {
              userOption: 'ERROR: Process F failed to start',
              userLetter: 'A',
            },
            {
              userOption: 'Process G started',
              userLetter: 'B',
            },
            {
              userOption: 'ERROR: Process F aborted',
              userLetter: 'C',
            },
            {
              userOption: 'Process E started',
              userLetter: 'D',
            },
          ],
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Production Flow Map',
      flowMapImgs: {...flowMapImages},
      multipleMulChoiceOptions: [
        {
          question: 'Which of the following error messages can <span class="convertToBlue">both</span> appear in the operations log <span class="convertToBlue">in the given order</span> (possibly with other messages in between)?',
          mulChoiceOptions: [
            {
              userOption: '"Process C failed to start", … , "Process G aborted"',
              userLetter: 'A',
            },
            {
              userOption: '"Process A aborted", … , "Process F aborted"',
              userLetter: 'B',
            },
            {
              userOption: '"Process G failed to start" , … , "Process F failed to start"',
              userLetter: 'C',
            },
            {
              userOption: '"Process D aborted", … , "Process E failed to start"',
              userLetter: 'D',
            },
          ],
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_PRODUCTION_FLOW_MAP,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'Production Flow Map',
      flowMapImgs: {...flowMapImages},
      multipleMulChoiceOptions: [
        {
          question: 'If the message "ERROR: Process E aborted" appears in the operations log, which of the following messages must also appear in the log (either before or after the cited message)?',
          mulChoiceOptions: [
            {
              userOption: 'Process D started',
              userLetter: 'A',
            },
            {
              userOption: 'ERROR: Process B aborted',
              userLetter: 'B',
            },
            {
              userOption: 'ERROR: Process D failed to start',
              userLetter: 'C',
            },
            {
              userOption: 'Process F started',
              userLetter: 'D',
            },
          ],
        },
      ],
      submitId: 'item_4',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work reviewing those maps! Your decisions help illustrate how you interpret visual information, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png',
      questionIndex: 2,
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: CT_TYPES.RHETORICAL
    },
  ],
]);

export const CTProductionInstructionModal: SneModal = {
  pageList: [
    {
      title: 'How to Read a Production Flow Graph',
      info: 'A production flow graph represents the production flow at a manufacturing facility. <br><br>Arrows show dependencies between processes. For a process to start, <span class=\'convertToBlue\'>all</span> processes which feed into it, directly or indirectly, <span class=\'convertToBlue\'>must</span> complete successfully.<br><br>Dashed lines mark scheduled start and end times for each process on the timeline.',
      image: './assets/section-2/production-flow-maps/first-modal-graph.svg',
    },
    {
      title: 'Production Flow',
      info: 'If a process X does <span class=\'convertToBlue\'>not</span> have all necessary feeder processes completed, the message "Process X failed to start" will appear in the operations log at the time process X was scheduled to start.<br><br>If a process X starts successfully but then terminates abnormally, the message "Process X aborted" will appear in the operations log at the time process X actually terminates.',
      image: './assets/section-2/production-flow-maps/second-modal-graph.svg',
    },
    {
      title: 'Example',
      info: 'For example, according to this sample production flow graph, process D depends directly on processes B and C, and indirectly on process A.<br><br>So if any of processes A, B or C fails to start or starts but does not complete successfully (aborts), process D will fail to start and the corresponding error message will appear in the operations log.',
      image: './assets/section-2/production-flow-maps/third-modal-graph.svg',
    }
  ],

};