import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ets-sne-chatbot-messages',
  templateUrl: './sne-chatbot-messages.component.html',
  styleUrls: ['./sne-chatbot-messages.component.scss']
})
export class SneChatbotMessagesComponent {

  @Input() botTitle: string|undefined = 'Teammate';
  @Input() botMessage: string|undefined;
  @Input() botImage: string|undefined = '/assets/sne/hidden-profile-profile-pic.png';

  constructor() { }


}
