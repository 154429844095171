<ets-header [headerState]="headerState" (backTrigger)="previousQuestion()"></ets-header>
<div class="container" [ngClass]="{
  'first-section': currentQuestion?.section === sections.SECTION_ONE,
  'second-section': currentQuestion?.section === sections.SECTION_TWO,
  'third-section': currentQuestion?.section === sections.SECTION_THREE,
  'fourth-section': currentQuestion?.section === sections.SECTION_FOUR
  }">
  <router-outlet></router-outlet>
<!--  <div class="submit-btn-container">-->
<!--    <button *ngIf="showNextButton()" type="submit" class="submit-btn" (click)="triggerNextQuestion()">-->
<!--      Next question-->
<!--    </button>-->
<!--  </div>-->

<ets-footer [progress]="progress" [showQuestionNumbers]='false' [displayProgressBar]="displayProgressBar"></ets-footer>

</div>
