<ets-header [isBGWhite]="false" [headerState]="headerState" [showBackButton]="false"
            [simpleBack]="true">
</ets-header>
<ets-sne-split-screen [rightBackgroundColor]="APP_COLORS.white"
                      [hideMobile]="'right'"
>
  <div class="right">
    <ets-single-multi-choice-question
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType"
    >
    </ets-single-multi-choice-question>
  </div>
  <div class="left">
    <ets-sne-conversation-log-text
      [chatLog]="chatList"
      [hintExists]="questionData?.hintModal!==undefined"
      (hintModalEvent)="hintToggle()"
      [botProfilePic]="questionData?.botProfilePic"
      [youProfilePic]="questionData?.youProfilePic"
      [botPics]="botPictures"
      [showBotPicsOnly]="botPictures.size > 0"
    >
    </ets-sne-conversation-log-text>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [questionNum]="questionNum"
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
    : multiResponse.length !== questionData?.numSelectOptions"
>
  <ets-single-multi-choice-question
    [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    (selectMultiResponseEvent)="multiResponseHandler($event)"
    [headerQuestion]="questionData?.header"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-single-multi-choice-question>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
<div *ngIf="instructionModal?.customContent" contentID=customContent>
  <ets-sne-email-layout
    *ngIf="instructionModal?.customContent[0].to"
    [emails]="instructionModal?.customContent"
    [colorArray]="['blue']"
    [firstColorIndex]="0"
  ></ets-sne-email-layout>
  <ets-sne-conversation-log-text *ngIf="instructionModal?.customContent[0].user" [chatLog]="instructionModal?.customContent" [showHeader]="false" [botProfilePic]="instructionModal?.customContent[0].image"></ets-sne-conversation-log-text>
</div>
</ets-modal>
<ets-modal
[title]="questionData?.hintModal?.title"
[showModal]="showHintModal"
[list]="questionData?.hintModal?.pageList"
(modalClose)="hintToggle()"
[hint]="true"
>
</ets-modal>
