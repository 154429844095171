import { SneCoachabilityBase } from '../sne-coachability.base';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from '../../../../global-styles';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { SneAnswerService } from '../../../services/sne-answer.service';
import * as _ from 'lodash';
import COACHABILITY_TYPES from 'src/app/types/coachability-types';

@Component({
  selector: 'ets-coachability-survey',
  templateUrl: './coachability-survey.component.html',
  styleUrls: ['./coachability-survey.component.scss']
})

export class CoachabilitySurveyComponent extends SneCoachabilityBase implements OnInit {
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  showHintModal = false;
  showInstructionModal = false;
  numAnswers: number = 0;
  uncertainityFullScreen = false;

  selectAnswer(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
    }

  }
  toggleModal() {
    this.showInstructionModal=!this.showInstructionModal;
  }
  toggleHintModal(){
    if(this.questionData?.hintModalFlag === true){
      this.showHintModal=!this.showHintModal;
    }
    //this.showHintModal = true;
  }
  disableButton() {
    return this.multiResponse.find(ele => ele === null) === null;
  }

  ngOnInit(): void {
    this.processParams();
    if (this.questionData && this.questionData.singleMulChoiceOptions) {
      this.multiResponse = new Array<string>(this.questionData?.singleMulChoiceOptions?.length);
      _.fill(this.multiResponse, null);
    }
    if (this.coachabilityType === COACHABILITY_TYPES.UNCERTAINTY) {
      this.uncertainityFullScreen = true;
    }
    this.toggleHintModal();
  }

  disableNextButton(): boolean {
    let disable = true;
    if (this.multiResponse.length === this.questionData?.numSelectOptions) {
      const undefinedResponseFound = this.multiResponse.findIndex(response => response === undefined);
      disable = undefinedResponseFound !== -1;
    }
    return disable;
  }

}
