<div class="container">
  <div class="inner">
    <h1 class="question">{{currentQuestion?.question}}</h1>
    <span class="info-1">{{currentQuestion?.info}}</span>
    <span *ngIf="currentQuestion?.type===questionTypes.MULTI_MULTIPLE_CHOICE" class="info-2 upper-case">Select all that apply.</span>
    <div class="answers-container desktop" *ngIf="currentQuestion?.choices">
      <div class="multi-column">
        <button
          class="answer-card"
          *ngFor="let item of evenList"
          [ngClass] = "{
            'selected-card': isOptionSelected(item)
          }"
          (click)="toggleOption(item)"
        >
        <span class="button-text" [ngClass] = "{
          'selected-button-text': isOptionSelected(item)
        }">{{item}}</span>
        
        </button>
      </div>
      <div class="multi-column">
        <button
          class="answer-card"
          *ngFor="let item of oddList"
          [ngClass] = "{
            'selected-card': isOptionSelected(item)
          }"
          (click)="toggleOption(item)"
        >
        <span class="button-text" [ngClass] = "{
          'selected-button-text': isOptionSelected(item)
        }">{{item}}</span>
        </button>
      </div>
    </div>
    <div class="answers-container mobile" *ngIf="currentQuestion?.choices">
      <div class="multi-column">
        <button
          class="answer-card"
          *ngFor="let item of inOrderList"
          [ngClass] = "{
            'selected-card': isOptionSelected(item)
          }"
          (click)="toggleOption(item)"
        >
        <span class="button-text" [ngClass] = "{
          'selected-button-text': isOptionSelected(item)
        }">{{item}}</span>
        </button>
      </div>
      
    </div>
    <ets-why-we-ask-modal 
      *ngIf="currentQuestion && currentQuestion.whyWeAskModal" 
      [whyWeAskData]="currentQuestion.whyWeAskModal"
      class="modal-button"
    ></ets-why-we-ask-modal>
    <div class="button-wrapper" *ngIf="currentQuestion?.type===questionTypes.MULTI_MULTIPLE_CHOICE">
      <ets-rounded-contained-button
        style="width: 225px; z-index: 1000;"
        type="button"
        [fullWidth]="true"
        [backgroundColor]="'#0E3AD6'"
        [textColor]="'#fff'"
        [buttonText]="'NEXT QUESTION'"
        (click)="triggerNextQuestion()"
        [disabled]="disableSubmit()"
      ></ets-rounded-contained-button>
    </div>
  </div>
</div>

