
<ets-auth-split-screen [leftBackgroundColor]="APP_COLORS.mustardlight" [rightBackgroundColor]="APP_COLORS.white">
  <div class="left">
    <img src="../../../assets/sign-up/new-hand.png" alt="Waving Hand" id="sign-in-photo">
  </div>
  
  <div class="right">
    <!-- Sign In Container -->
    <div class="sign-in-content-wrapper">
      <div class="header-info-wrapper">
        <h1 class="sign-in-header">
          Welcome back
        </h1>
        <p class="need-account">
          New to Apprize? <button class="sign-up" (click)="gotoSignUp()">Sign up for free</button><br>
        </p>
      </div>
    
      <div class="login-form-wrapper">
        <form [formGroup]="signInForm" (ngSubmit)="signIn()">
          <!-- Email -->
          <div class="input-content-wrapper">
            <label for="fusername" class="input-label">Email</label>
            <div class="input-field-wrapper" 
              [ngClass]="{'input-field-wrapper-error': signInForm.controls['email'].invalid &&
                (signInForm.controls['email'].touched || signInForm.controls['email'].dirty)}"
            >
              <input type="text" id="fusername" name="email" class="sign-in-input" formControlName="email">
            </div>
            <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon'
                *ngIf="signInForm.controls['email'].invalid &&
                (signInForm.controls['email'].touched || signInForm.controls['email'].dirty)"
            >
            <div *ngIf="signInForm.controls['email'].invalid && (signInForm.controls['email'].touched || signInForm.controls['email'].dirty)">
              <div *ngIf="signInForm.controls['email'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div>
          </div>
    
          <!-- Password -->
          <div class="input-content-wrapper">
            <label for="signIn_password" class="input-label">Password</label>
            <input type="password" id="signIn_password" name="password" class="sign-in-input password-input" formControlName="password">
            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("signIn_password")'></fa-icon>
            <div *ngIf="signInForm.controls['password'].invalid && (signInForm.controls['password'].touched || signInForm.controls['password'].dirty)">
              <div *ngIf="signInForm.controls['password'].errors?.required" class='validation-error'>
                This field is required
              </div>
            </div>
          </div>
    
          <!-- Error Handling -->
          <p class="error-msg" *ngIf='signIn_incorrectUsernameOrPasswordFlag'>Invalid email/password entered. Please try again.</p>
          <p class="error-msg unconfirmed-account" *ngIf='signIn_unconfirmedAccountFlag'>
            The email associated with this account has not yet been confirmed.
            <button class="sign-up" (click)="gotoConfirmAccount()">Confirm account</button>
          </p>
          <p class="error-msg" *ngIf='signIn_unknownErrorFlag'>An unknown error occurred. Please try again.</p>

          <!-- Forgot Password -->
          <p class="need-account forgot-password sign-up" (click)="gotoResetPassword()">
            Forgot password?
          </p>
    
          <div class="submit-container">
            <input type="submit" id="submit" name="submit" value="SIGN IN" class="login-button" [disabled]="!signInForm.valid || signIn_disableSubmitFlag">
            <clr-spinner [clrSmall]='true' *ngIf="signingInSpinnerFlag"></clr-spinner>
          </div>
        </form>
      </div>
      
    </div>
  </div>
</ets-auth-split-screen>