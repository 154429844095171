import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Store } from '@ngrx/store';
import { selectProfile } from 'src/app/store';
import { setProfile } from 'src/app/store/profile.actions';
import { Observable, Subscription } from 'rxjs';
import Profile from 'src/app/types/profile';
import { SneScoreReportService } from 'src/app/services/sne-score-report.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ets-sne-report',
  templateUrl: './sne-report.component.html',
  styleUrls: ['./sne-report.component.scss']
})
export class SneReportComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private store: Store, private profileService: ProfileService, private sneScoreReportService: SneScoreReportService) {
  }
  module: string = '';
  userProfile!: Profile;
  preferredName = '';
  stringScore = '';
  numScore!: number;
  CPS_collab_score = '';
  CPS_PS_score = '';
  persuasive_subscore = '';
  info_subscore = '';
  dialogic_subscore = '';
  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        if (profile.preferredName) {
          this.preferredName = profile.preferredName;
        }

      } else {
        this.subArr.push(this.profileService.getProfile().subscribe(profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.userProfile = profile;
          if (profile.preferredName) {
            this.preferredName = profile.preferredName;
          }
        }));
      }
    });
  }

  getModule() {
    this.activatedRoute.paramMap.subscribe(params => {
      if(params.get('module-name') && typeof params.get('module-name') === 'string') {
        this.module = params.get('module-name')?.toUpperCase() || '';
      }
    });
  }


  translateScore(module_score_decimal: number) {
    // >75% = High,    50-74% = Moderate,   <50% = Low
    let score = '';
    if (module_score_decimal > 0.74 ) {
      score = 'high';
    } else if (module_score_decimal <= 0.74 && module_score_decimal > 0.50) {
      score = 'medium';
    } else if (module_score_decimal <= 0.50) {
      score = 'low';
    }
    return score;
  }

  translateFEModuleNametoBEModuleName(frontEndName: string) {
    let backEndName = '';
    switch (frontEndName) {
      case 'CPS':
        backEndName = 'CPS';
        break;
      case 'OC':
        backEndName = 'ORC';
        break;
      case 'WC':
        backEndName = 'WRC';
        break;
      case 'CT':
        backEndName = 'CRT';
        break;
      case 'COACHABILITY':
        backEndName = 'CCH';
        break;
      default:
        break;
    }
    return backEndName;
  }


  ngOnInit(): void {
    this.getModule();
    this.getProfile();
    this.module = this.translateFEModuleNametoBEModuleName(this.module);

    //generate score report
    this.sneScoreReportService.generateScoreByModule(this.module).subscribe(result => {
      this.stringScore = this.translateScore(result.module_score);
      this.numScore = result.module_score;

      //translate subscores for CPS
      result.ps_subscore ? this.CPS_PS_score = this.translateScore(result.ps_subscore) : null;
      result.collab_subscore ? this.CPS_collab_score = this.translateScore(result.collab_subscore) : null;

      //translate subscores for OC
      result.dialogic_subscore ? this.dialogic_subscore = this.translateScore(result.dialogic_subscore) : null;
      result.persuasive_subscore? this.persuasive_subscore = this.translateScore(result.persuasive_subscore) : null;
      result.info_subscore ? this.info_subscore = this.translateScore(result.info_subscore) : null;

    });
  };
}
