import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';
import { VendorFact } from 'src/app/types/sne';

@Component({
  selector: 'ets-fact-card-list',
  templateUrl: './fact-card-list.component.html',
  styleUrls: ['./fact-card-list.component.scss'],
})

export class FactCardListComponent extends SneCollaborativeProblemSolvingBase {
  constructor(protected router: Router, protected activatedRoute: ActivatedRoute, protected sneAnswerService: SneAnswerService) {
    super(activatedRoute, router, sneAnswerService);
  }

  @Input() vendorFactsListA: VendorFact[] | undefined = [];
  @Input() vendorFactsListB: VendorFact[] | undefined = [];
}
