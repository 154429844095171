import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { CipAnswer} from 'src/app/types/cip';
import { environment } from '../../environments/environment';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';
@Injectable({
  providedIn: 'root'
})
export class CipService extends BaseService{

  // private cipUrl = 'https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/latest/cip';
  private cipBaseUrl = environment.cipAPI;
  private cipScoreUrl = this.cipBaseUrl + '/score';
  private cipQuestionUrl = this.cipBaseUrl + '/question';

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'CIP';
  }

  getQuestions(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      return this.http.get<HttpResponse<any>>(this.cipQuestionUrl, {headers: headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CIP: Get Questions API Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        map(data => data),
        catchError(this.handleError)
      );
    }
    return new Observable<any>();
  }
  
  answersToJson(answers: CipAnswer[]): string {
    let bodyString = '{';
    for (let i = 0; i < answers.length; i++) {
      let cipAnswer = answers[i];
      bodyString = bodyString + '\"' + cipAnswer.questionNum + '\":' + cipAnswer.answer.toString() + ',';
    }
    bodyString = bodyString.slice(0, -1);
    bodyString += '}';

    return (bodyString);
  }

  callScoringApi(answers: CipAnswer[]): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = this.answersToJson(answers);
      let requestLogObj: LogObject = {
        message: 'CIP: Score Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.cipScoreUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CIP: Score Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  } 

  saveInProgress(answers: CipAnswer[]): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = this.answersToJson(answers);
      let params = {progress: 'save'};
      let requestLogObj: LogObject = {
        message: 'CIP Save in Progress Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.cipScoreUrl, body, {headers, params: params}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CIP: Save in Progress Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  getScoresApi(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      return this.http.get<HttpResponse<any>>(this.cipScoreUrl, {headers: headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CIP: Get Scores API Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        map(data => data),
        catchError(this.handleError)
      );
    }
    return new Observable<any>();
  }

  getAnswersApi(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let params = {progress: 'retrieve'};
      return this.http.get<HttpResponse<any>>(this.cipScoreUrl, {headers: headers, params: params}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CIP: Get Answers API Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        map(data => data),
        catchError(this.handleError)
      );
    }
    return new Observable<any>();
  }  
}

