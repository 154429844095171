import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import WC_TYPES from 'src/app/types/written-communication-types';

export const WCSet3Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Improving a Visitor Handout',
      info: 'This task will give you insight into how you evaluate written information, and we will use what we learn together to enhance your Skills Profile.',
      duration: '<span class="boldText">Duration:</span> This task should take less than 10 minutes, and is the last task in this section!',
      questionIndex: 3,
      moduleSize: 3,
      image:'/assets/section-5/sne-interstitial/written-com.svg',
      buttonText: 'NEXT',
      modalBeforeNext: true,
      durationBoxColor: 'lbb',
    },
  ],
  ['question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Mr. Jones decides he needs to revise the <span class="highlighted">highlighted</span> phrase in sentence 1. Which of the following best explains why a change is needed?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates <span class="highlighted">going up and up</span>, skilled trade jobs have a great outlook. (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'The phrase is too long to be clear to readers.',
          userLetter: 'A'
        },
        {
          userOption: 'The phrase does not match the style and tone of the handout.',
          userLetter: 'B'
        },
        {
          userOption: 'The phrase may confuse the student visitors.',
          userLetter: 'C'
        },
        {
          userOption: 'The phrase is not likely to engage readers of the handout.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_1'
    }],
  ['question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Mr. Jones is considering removing sentence 3 (<span class="highlighted">highlighted</span>). Should he make the revision? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook. (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. <span class="highlighted">(3) Becoming an electrician is one such case.</span> (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'No, because it signals a transition to a supporting example.',
          userLetter: 'A'
        },
        {
          userOption: 'No, because it helps maintain readers’ interest in the handout.',
          userLetter: 'B'
        },
        {
          userOption: 'Yes, because it does not match the style of the handout.',
          userLetter: 'C'
        },
        {
          userOption: 'Yes, because the information is provided elsewhere.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_2'
    }],
  ['question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'What is the main purpose of the <span class="highlighted">highlighted</span> portion of sentence 5?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, <span class="highlighted">installing wiring and lighting and using specialized testing devices.</span> (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'To list other types of activities performed by electricians besides construction',
          userLetter: 'A'
        },
        {
          userOption: 'To demonstrate the author’s expertise on the subject',
          userLetter: 'B'
        },
        {
          userOption: 'To give examples of electricians’ construction duties',
          userLetter: 'C'
        },
        {
          userOption: 'To explain how electricians learn their trade on-the-job',
          userLetter: 'D'
        },
      ],
      submitId: 'item_3'
    }],
  ['question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Mr. Jones may change the <span class="highlighted">highlighted</span> portion of sentence 5 to “wall scanners, loop testers, and proving units.” Should he make the change? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using <span class="highlighted">specialized testing devices.</span> (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Yes, because it provides a better sense of how skilled electricians need to be.',
          userLetter: 'A'
        },
        {
          userOption: 'Yes, because it provides more information for the students to learn.',
          userLetter: 'B'
        },
        {
          userOption: 'No, because the terms are unlikely to be familiar to those reading the handout.',
          userLetter: 'C'
        },
        {
          userOption: 'No, because it is unrelated to the topics covered elsewhere in the handout.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_4'
    }],
  ['question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Mr. Jones wants to provide more details about the work that electricians do. Which of the following, if added to the <span class="highlighted">highlighted</span> part of sentence 6, would do so most effectively?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making <span class="highlighted">repairs.</span> (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'repairs, along with other things that the homeowners may ask them to do.',
          userLetter: 'A'
        },
        {
          userOption: 'repairs, and do-it-yourself projects are very popular among homeowners.',
          userLetter: 'B'
        },
        {
          userOption: 'repairs, though getting to some industrial construction sites involves extensive travel.',
          userLetter: 'C'
        },
        {
          userOption: 'repairs, while other electricians work full-time at factories for on-site electrical work.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_5'
    }],
  ['question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Where should sentence 9 (<span class="highlighted">highlighted</span>) most logically be placed?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. <span class="highlighted">(9) Though electricians do not need a college degree, learning their trade requires a similar time commitment.</span> (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Where it is now (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'Immediately before sentence 8',
          userLetter: 'B'
        },
        {
          userOption: 'Immediately after sentence 10',
          userLetter: 'C'
        },
        {
          userOption: 'Immediately after sentence 11',
          userLetter: 'D'
        },
      ],
      submitId: 'item_6'
    }],
  ['question-7',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Which of the following is the best version of the <span class="highlighted">highlighted</span> part of sentence 11?',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentice’s time is spent doing hands-on electrical work. (11) <span class="highlighted">Similarly,</span> apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. (12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'Similarly, (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'Be that as it may,',
          userLetter: 'B'
        },
        {
          userOption: 'Next,',
          userLetter: 'C'
        },
        {
          userOption: 'Beyond that,',
          userLetter: 'D'
        },
      ],
      submitId: 'item_7'
    }],
  ['question-8',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
      header: 'Mr. Jones added sentence 12 (<span class="highlighted">highlighted</span>) to the handout most likely because it',
      inputType: SNE_INPUT_TYPES.RADIO,
      subtitle: 'Becoming an Electrician',
      memo: '(1) With construction rates going up and up, skilled trade jobs have a great outlook (2) Going into a skilled trade profession is an excellent choice for those who like hands-on work and are not interested in a four-year degree program. (3) Becoming an electrician is one such case. (4) Electricians are professionals who specialize in electrical systems. (5) They are involved in construction, installing wiring and lighting and using specialized testing devices. (6) Private owners may hire them for projects like installing outlets and making repairs. (7) Construction companies may hire them to design the entire electrical system for new buildings. (8) Some electricians choose to attend technical school, but most do apprenticeships: training on the job with an experienced electrician. (9) Though electricians do not need a college degree, learning their trade requires a similar time commitment. (10) Most of an apprentices time is spent doing hands-on electrical work. (11) Similarly, apprentices study relevant information like electrical theory, electrical code requirements, and safety practices. <span class="highlighted">(12) With the high demand and good pay for skilled trade workers like electricians, those looking to enter the workforce in the next decade should certainly consider skilled trade jobs.</span>'
      ,
      singleMulChoiceOptions: [
        {
          userOption: 'provides a transition to the topic of pay',
          userLetter: 'A'
        },
        {
          userOption: 'acknowledges that there may be objections to his position',
          userLetter: 'B'
        },
        {
          userOption: 'reinforces the case he is making in the handout',
          userLetter: 'C'
        },
        {
          userOption: 'summarizes the kind of work involved in skilled trade jobs',
          userLetter: 'D'
        },
      ],
      submitId: 'item_8',
      complete: true
    }],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the last task!',
      // TODO there needs to be a dynamic name change in the sentence below - see Figma
      info: 'Nice work finishing all of the sections! We know that took serious time and effort. You just took a big leap forward on your career path, and we are proud of you!<br><br>Check out your score report to learn more about what this means, how we will apply it to your Skills Profile, and how it might influence your career search.',
      questionIndex: 3,
      moduleSize: 3,
      image: '/assets/section-5/sne-interstitial/written-com-end-interstitial.png',
      buttonText: 'VIEW REPORT',
      goto: '/sne-new/report/wc'
    },
  ],
]);

export const WCSet3InstructionModal: SneModal = {
  pageList: [
    {
      info: 'The following is a draft of a handout written by Omar Jones, owner of a large electrical-services company. The handout is for a group of students visiting the company.'
    }
  ],
  title: 'Evaluating a Handout',
};
