import QUESTION_TYPES, { QUESTION_SECTIONS } from './question-types';
import OB_PAGES from './on-boarding-pages.types';
import CIP_PAGES from './cip-pages.types';

interface Question {
  type: QUESTION_TYPES;
  section: QUESTION_SECTIONS;
  questionFunc?: ((info: string) => string);
  question?: string;
  info?: string;
  placeholder?: string;
  choiceHeaders?: string[];
  choices?: string[];
  showNextQuestion?: boolean;
  answers?: number[];
  numbers?: string[];
  headers?: string;
  profileAttribute?: string;
  validator?: string;
  errorMessage?: string;
  page: OB_PAGES | CIP_PAGES;
  previousPage?: OB_PAGES;
  skipNextQuestionAnswer?: string; // deprecated
  buttonText?: string;
  cards?: Card[];
  cardSize?: CARD_SIZE_ENUM;
  selectAllThatApply?: boolean;
  choiceNavList?: ChoiceNav[];
  sliderSteps?: number[];
  whyWeAskModal?: WhyWeAskModal;
  picFileName?: string;
  skillTypes?: SkillTypes[];
  topSkills?: TopSkill[];
  duration?: string;
  onboardingComplete?: boolean; // flag to denote that the onboardingComplete flag should be set to true on the profile after this question
}

export default Question;

export interface sliderStep {
  value: string;
}
export interface ChoiceNav {
  choice?: string | number | boolean;
  navTo: OB_PAGES; // 'page' field on the Question object to route to
}

export interface TopSkill {
  placeholderSkill: string;
  selectedSkill: string | undefined;
  eulerID: number | undefined;
  topSkill: boolean;
}

// Interface used for transferable and job-specific top skills component
export interface  ISkills {
  eulerID: number | undefined; 
  eulerSkill: string; 
  isTopSkill: boolean;
}

export interface Card {
  title: string;
  description?: string;
  image?: string;
  selected: boolean;
}

export enum CARD_SIZE_ENUM {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGER = 'larger'
}

export interface WhyWeAskModal {
  title: string;
  body: string;
}

export interface SkillTypes {
  type: SKILL_TYPES_ENUM;
  title: string;
  description: string;
  imagePath: string;
  disabled: boolean;
  selectedSkills?: string[];
}

export enum SKILL_TYPES_ENUM {
  TRANSFERABLE = 'transferable',
  JOB_SPECIFIC = 'job-specific',
  TOP = 'top'
}