import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ets-sne-v2',
  templateUrl: './sne-v2.component.html',
  styleUrls: ['./sne-v2.component.scss']
})
export class SneV2Component {

  constructor() { }

}
