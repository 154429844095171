export interface Assessment {
  startTimestamp: Date;
  formattedStartTimestamp?: string;
  completed: boolean;
  completionTimestamp: Date;
  assessmentID: number | string;
  assessmentHumanReadableID?: string;
  assessmentTitle?: string;
  sessionID?: string;
  resultJSON?: any;
  answerJSON?: any;
  show?: boolean;
  SNE?: boolean;
  status?: string;
}

export enum PARENT_ASSESSMENT_HRID {
  CPS = 'CPS',
  CCH = 'CCH',
  CRT = 'CRT',
  WRC = 'WRC',
  ORC = 'ORC',
  FACETS = 'PSQ',
  CIP = 'CIP',
}