import { Component, OnInit } from '@angular/core';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Profile from 'src/app/types/profile';
import { Store } from '@ngrx/store';
import { FacetsAssessmentData, FacetsGetAssessmentsResponse, FacetsRequestBody } from 'src/app/types/facets';
import { Subscription } from 'rxjs';
import { FacetsService } from 'src/app/services/facets.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-facets-intro',
  templateUrl: './facets-intro.component.html',
  styleUrls: ['./facets-intro.component.scss']
})
export class FacetsIntroComponent implements OnInit {

  userProfile: Profile | undefined;
  sub!: Subscription;
  incompleteQuiz: boolean = false;
  facetsOutage: boolean = false;

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  faArrowRight = faArrowRight;

  constructor(private store: Store, private router: Router, private facetsService: FacetsService, private eventService: EventService) { }

  // Gets existing FACETS assessments for the current user
  getFacetsAssessments() {
    let getAssessmentRequest: FacetsRequestBody = {
      action: 'getAssessment'
    };

    this.sub = this.facetsService.getAssessments(getAssessmentRequest).subscribe({
      next: response => {
        this.processGetAssessmentsResponse(response);
      },
    });
  }

  processGetAssessmentsResponse(response: FacetsGetAssessmentsResponse) {
    localStorage.removeItem('facetsSessionId'); // remove the session ID if it exists

    this.facetsOutage = response.outage;
    let assessments = response.assessments;
    // Determine whether there is an uncomplete FACETS quiz on the user's account
    // if so, then resume that test
    assessments.forEach(assessment => {
      if (!assessment.completed) {
        localStorage.setItem('facetsSessionId', assessment.sessionID);
        this.incompleteQuiz = true;
      }
    });
  }

  resumeAssessment() {
    this.incompleteQuiz = false;
    this.router.navigate(['/foundational-assessment/power-skills-interstitial']);
  }

  letsBeginAction() {
    // added this method for keyboard acessibility
    this.router.navigate(['/foundational-assessment/power-skills-interstitial']);
  }

  ngOnInit() {
    document.title = 'Apprize | Power Skills';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Power Skills Landing Page',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Power Skills Landing Page', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Power Skills Landing Page', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);

    this.getFacetsAssessments();
  }

}
