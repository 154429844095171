import { Component } from '@angular/core';

@Component({
  selector: 'ets-written-communication',
  templateUrl: './written-communication.component.html',
  styleUrls: ['./written-communication.component.scss']
})
export class WrittenCommunicationComponent {

  constructor() { }

}
