import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogObject } from '../types/log';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  // private logUrl = 'https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/latest/log';
  // private logEventUrl = 'https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/latest/event';
  private logUrl = environment.logAPI;

  constructor(private http: HttpClient) { }

  // Log info
  logInfo(logObject: LogObject) {
    let logObj = logObject;
    logObj.type = 'INFO';

    if (environment.environmentName !== 'prod') {
      console.log('LOG:', logObj);
    }
    
    if (environment.loggingEnabled) {
      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        let headers = new HttpHeaders().set('Authorization', accessToken);

        return this.http.post<any>(this.logUrl, logObj, {headers});
      }
    }
    return;
  }

  // Log an error
  logError(logObject: LogObject) {
    let logObj = logObject;
    logObj.type = 'ERROR';

    if (environment.environmentName !== 'prod') {
      console.error('LOG:', JSON.stringify(logObj));
    }
    
    if (environment.loggingEnabled) {
      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        let headers = new HttpHeaders().set('Authorization', accessToken);

        return this.http.post<any>(this.logUrl, logObj, {headers});
      }
    }
    return;
  }

}
