import { SneModal, SneQuestion, SNE_INPUT_TYPES } from '../../../types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CPSNegotiationData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Negotiation',
      info: 'This task will give you insight into how you identify and resolve conflicts to reach an agreement, and we will use what we learn together to enhance your Skills Profile.',
      duration:
        '<span class="boldText">Duration:</span> This should take about 10 minutes, and is the last task in this section!',
      questionIndex: 3,
      moduleSize: 3,
      durationBoxColor: 'mustard-yellow',
      image: '/assets/section-1/sne-interstitial/negotiation.svg',
      buttonText: 'Next',
      modalBeforeNext: true,
    },
  ],
  [
    'question',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_CLIENT_RANKING_CHAT_HISTORY,
      numSelectOptions: 1,
      inputType: SNE_INPUT_TYPES.RADIO,
      botProfilePic: '/../assets/sne/negotiation-jordan-profile-pic.png',
      youProfilePic: '/../assets/sne/negotiation-you-profile-pic.png' ,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You completed the last task!',
      info: 'Nice work communicating with Jordan! Your decisions help illuminate how you might negotiate with a colleague, where your strengths lie, and where you have room to grow. <br> <br> Check out your score report to learn more about what this means, how we\'ll apply it to your Skills Profile, and how it might influence your career search.',
      questionIndex: 3,
      moduleSize: 3,
      image: '/assets/section-1/sne-interstitial/cps-end-interstitial.png',
      buttonText: 'REVIEW REPORT',
      goto: '/sne-new/report/cps',
    },
  ],
]);

export const CPSNegotiationDataInstructionModal: SneModal = {
  pageList: [
    {
      title: 'About You',
      info: 'You work for a large event space that hosts concerts, theatrical productions, and sporting events. Part of your duties includes securing sponsorship of events from businesses and other clients by selling advertising that is shown during the events. <br> <br> To help with negotiating sponsorships with clients, your company provides you with certain incentives you can offer. Currently, you have the following incentives available. ',
    },
    {
      title: 'Incentives Available',
      info: '',
      showCustomContent: true,
    },
    {
      title: 'Your Task',
      info: 'Your colleague Jordan has an additional number of tickets to the concert. You have asked her to meet with you to see if she might be willing to help you satisfy your client. <br> <br> You will be able to see both the client\'s and Jordan\'s satisfaction ratings as you progress through the task.',
      showCustomContent: true // negotiation stars
    },
  ],
  customContent: [
    {
      negotiation: true,
    },
  ]
};

export const CPSNegotiationDataSubmitModal: SneModal ={
  pageList: [
    {
      title: 'Time to submit',
      info: 'Let\'s wrap up the conversation with Jordan. Go ahead and review or submit your responses now.',
    }
  ]
};
