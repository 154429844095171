<div class="container" *ngIf="headerState===headerStates.SIGN_UP">
  <img role="img" src="~/../assets/apprize-logo-blue.svg" class="logo-margin-left" alt="apprize logo" id="mindkind-logo" />
  <label for="mindkind-logo" value="Apprize"></label>
  <div class="right-wrapper mobile-hide">
    <div class="sign-up-container" *ngIf='!showSignIn'>
      <span class="need-account">Need an account?</span>
      <button (click)="signUp()" class="sign-up-link">Sign Up</button>
    </div>
    <div class="sign-in-container" *ngIf='showSignIn'>
      <span class="need-account">Already have an account?</span>
      <button (click)="signIn()" class="sign-up-link">Sign In</button>
    </div>
  </div>

  <div class="right-wrapper desktop-hide">
    <div class="sign-up-container" *ngIf='!showSignIn'>
      <button (click)="signUp()" class="sign-in-btn">Sign Up</button>
    </div>
    <div class="sign-in-container" *ngIf='showSignIn'>
      <button (click)="signIn()" class="sign-in-btn">Sign In</button>
    </div>
  </div>
</div>

<div class="container" *ngIf="headerState===headerStates.SIGN_IN">
  <img role="img" src="~/../assets/apprize-logo-blue.svg" class="logo-margin-left" alt="apprize logo" />
  <div class="right-wrapper">
    <span class="have-account">I already have an account.</span>
    <a href="#" class="sign-in-link">Sign In</a>
  </div>
</div>

<div
  class="container"
  *ngIf="headerState===headerStates.BACK"
  [ngStyle]="{'background-color': backgroundColor && !isBGWhite ? backgroundColor : null}"
  [ngClass]="{
    'container-white-bg': isBGWhite,
    'first-section': currentQuestionSection === QUESTION_SECTIONS.SECTION_ONE && (!isBGWhite && !backgroundColor),
    'second-section': currentQuestionSection === QUESTION_SECTIONS.SECTION_TWO && (!isBGWhite && !backgroundColor),
    'third-section': currentQuestionSection === QUESTION_SECTIONS.SECTION_THREE && (!isBGWhite && !backgroundColor),
    'fourth-section': currentQuestionSection === QUESTION_SECTIONS.SECTION_FOUR && (!isBGWhite && !backgroundColor),
    'fifth-section': currentQuestionSection === QUESTION_SECTIONS.SECTION_FIVE && (!isBGWhite && !backgroundColor)
}">
  <button class="back-button" (click)="back()" *ngIf="showBackButton" aria-label="go-back">
    <fa-icon [icon]="faChevronLeft"></fa-icon>
    <span class="back">Back</span>
  </button>
  <div class="logo-wrapper">
    <img class="img-click" role="img" src="~/../assets/apprize-logo-blue.svg" alt="apprize logo" (click)="redirectToDashboard()"/>
  </div>
</div>

<div class="container" *ngIf="headerState===headerStates.FACETS">
  <button class="facets-back-button" (click)="back()">
    <fa-icon [icon]="faChevronLeft"></fa-icon>
  </button>
  <div class="logo-wrapper">
    <img class="img-click" role="img" src="~/../assets/apprize-logo-blue.svg" alt="apprize logo" (click)="redirectToDashboard()"/>
  </div>
</div>

<div class="container" [ngClass]="{'container-white-bg': isBGWhite}" *ngIf="headerState===headerStates.DASHBOARD">
<!--  <div class="hamburger-wrapper">-->
<!--    <button-->
<!--      class="icon-button"-->
<!--      (click)="toggleMobileDropDown('')"-->
<!--    >-->
<!--      <img src="../../../assets/icons/hamburger.svg">-->
<!--    </button>-->
<!--  </div>-->
  <div class="dash-mindkind-logo">
    <img class="img-click" role="img" src="~/../assets/apprize-logo-blue.svg" alt="apprize logo" (click)="redirectToDashboard()"/>
  </div>
  <div class="icon-wrapper">
    <button
      class="icon-button"
      (click)="signOut()"
    >
      <img src="assets/icons/signout.svg" alt="sign out icon" title="Sign Out"/>
    </button>
    <button class="headshot-btn" (click)='trackProfileHeadshotClick()'>
        <img class="headshot-img" src="../../../../assets/default-profile-images/default-profile-image-2.svg" alt="profile headshot"/>
    </button>
  </div>
</div>

<div class="mobile-menu-container" *ngIf="showMobileDropDown">
  <ul class="menu-list">
    <li>
      <a class="menu-item-link" (click)='toggleMobileDropDown("Overview")' routerLink="/dashboard">
        Overview
      </a>
    </li>
    <li>
      <a (click)='toggleMobileDropDown("Career Prep")' routerLink='/career-prep'>
        Career Prep
      </a>
    </li>
  </ul>
  <div class="survey-wrapper">
    <ets-feedback></ets-feedback>
  </div>
<!--  <div class="bottom-border"></div>-->
</div>

<!-- Header for Auth pages -->
<div class="container" [ngClass]="{'container-white-bg': isBGWhite}" *ngIf="headerState===headerStates.LOGO_ONLY">
  <div class ="logo-only-wrapper">
    <img class="img-click" role="img" src="~/../assets/apprize-logo-blue.svg" alt="apprize logo" (click)="redirectToSignIn()"/>
  </div>
</div>
