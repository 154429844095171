<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="true"
></ets-header>

<ets-sne-split-screen
  [hideMobile]="'right'"
  [sneSection]="'left-coachability'"
  [rightBackgroundColor]="'white'"
>
  <div class="left">
    <ets-sne-ques-video
    [videoId]="questionData?.headerVideo?.id"
    [mainHeader]="questionData?.headerVideo?.mainHeader"
    [title]="questionData?.headerVideo?.title"
    [description]="questionData?.headerVideo?.description"
    [videoUrl]="questionData?.headerVideo?.url"
    [playOnce]="questionData?.headerVideo?.playOnce"
    [sharedVideo]="questionData?.sharedVideo"
    [titleColor]="'lbb'"
  >
  </ets-sne-ques-video>
  </div>
  <div class="right">
    <ets-multi-video-choice-video
      [videoSetId]="'1'"
      [question]="questionData"
      [videos]="questionData?.videos"
      (userResponseEvent)="handleUserResponse($event)"
      [noModal]="true"
    ></ets-multi-video-choice-video>
  </div>
</ets-sne-split-screen>


<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
    : multiResponse.length !== questionData?.numSelectOptions"
>
<ets-multi-video-choice-video
  [videoSetId]="'2'"
  [question]="questionData"
  [videos]="questionData?.videos"
  (userResponseEvent)="handleUserResponse($event)"
  [mobileCheck]="true"
  ></ets-multi-video-choice-video>
</ets-sne-progress-bar>


<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video *ngIf="instructionModal?.customContent[0].url"
                   [videoUrl]="instructionModal?.customContent[0].url"
                   [videoId]="instructionModal?.customContent[0].id"
                   [videoOnlyFlag]="true">
    </ets-sne-video>
  </div>
</ets-modal>
