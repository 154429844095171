<ets-header [headerState]="headerState" [isBGWhite]='true' [simpleBack]='true'></ets-header>
<div class="container" [ngClass]="{ 'basic-container': basic }">
  <div class="banner-wrapper"
  *ngIf="careerDetailData?.careerTitle"
  [ngStyle]="{'background-color': findBGColor(careerDetailData?.careerTitle!)}">
    <div class="banner-image-wrapper">
      <img
        class="banner-image"
        src="https://assets.apprize.com/Careers/Narrow/{{careerDetailData?.careerTitle}}_narrow_%403x.png"
        alt=""
        aria-hidden="true"
        *ngIf="careerDetailData?.careerTitle"
      />
    </div>
  </div>
  <div class="content-container">
    <div class="flex-container">
      <div class="left-section">
        <div class="title-wrapper">
          <span class="title" [innerHTML]="careerDetailData?.careerTitle"></span>
          <button
            class="btn btn-outline follow-button"
            [disabled]="followedCareersCount === 3"
            *ngIf="!careerDetailData?.learnerCareer?.isFollowing"
            (click)="toggleFollowCareer(careerDetailData)"
            aria-label="Save Career"
          >
            Save
          </button>
          <button
            class="btn btn-outline unfollow-button desktop"
            *ngIf="careerDetailData?.learnerCareer?.isFollowing"
            (click)="toggleFollowCareer(careerDetailData)"
            aria-label="Remove Career"
          >
            <fa-icon [icon]="faMinus" class="minus-icon"></fa-icon>
            Remove
          </button>
          <button
            class="btn btn-outline unfollow-button mobile"
            *ngIf="careerDetailData?.learnerCareer?.isFollowing"
            (click)="toggleFollowCareer(careerDetailData)"
            aria-label="Remove Career"
          >
            <fa-icon [icon]="faMinus" class="minus-icon"></fa-icon>
          </button>

        </div>
        <div class="subtitle-wrapper">
          <div class="subtitle active-subtitle" *ngIf="careerDetailData?.inDemand">
            <img src="../../../assets/icons/up-graph-icon.svg" alt="" aria-hidden="true"><span>In-Demand</span>
          </div>
          <div class="subtitle">
            <span>{{ careerDetailData?.careerIndustry }}</span>
          </div>
        </div>
        <div class="description-container">
          <p class="description">
            {{ careerDetailData?.careerDescription }}
          </p>
        </div>

        <div class="info-container">
          <div class="info-wrapper">
            <span class="info-title">National Salary:<sup aria-hidden="true">1</sup></span>
            <span class="info-entry">{{
              careerDetailData?.salaryMedian
              }}</span>
          </div>
          <div class="info-wrapper">
            <span class="info-title">Job Demand:</span>
            <span class="info-entry">Faster than average</span>
          </div>
        </div>
        <div class="chart-and-training mobile">
          <div class="chart-wrapper">
            <div class="career-match-percent">
              <div class="percent-outer-circle">
                <ngx-charts-pie-chart
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="results"
                  [gradient]="gradient"
                  [tooltipDisabled]="disableTooltip"
                  [doughnut]="isDoughnut"
                >
                </ngx-charts-pie-chart>
                <div class="percent-inner-circle">
                  <span class="percent" *ngIf="careerDetailData">
                    {{ displayedResult }}%
                  </span>
                  <span class="percent-description">Career Match</span>
                </div>
              </div>
            </div>
          </div>
          <div class="career-match-content">
            <h1>CAREER MATCH SCORE</h1>
            <p><span class="bold" role="text">Increase your Career Match Score</span>
              by gaining new skills through our set of recommended trainings that will set you up for success in this
              career.
            </p>
            <div class="flex-row">
              <span>HOW IS MY SCORE CALCULATED </span>
              <span>
                <div class = "desktop-check">
                <ets-tooltip [text]="calculatedInfo" [innerToolTip]="true" [toolTipSize]="'md'" [width]="'500px'"
                             [height]="'170px'" [position]="'tooltip-top-left'"
                             [backgroundColor]="'#565656'"></ets-tooltip>
                </div>
                <div class = "mobile-check">
                  <ets-tooltip [text]="calculatedInfo" [innerToolTip]="true" [toolTipSize]="'md'" [width]="'200px'"
                             [height]="'100px'" [position]="'tooltip-top-left'"
                             [backgroundColor]="'#565656'"></ets-tooltip>
                </div>
              </span>
            </div>
          </div>

          <div class="jobs-trainings-wrapper">
            <!-- Job Opportunities  -->
            <ets-job-posting-carousel [careerID]="careerDetailData?.careerID"></ets-job-posting-carousel>
    
            <!-- training provider carousel component -->
            <ets-training-provider
              [careerTitle]="careerDetailData?.careerTitle"
              [careerID]="careerDetailData?.careerID"
              [careerDetailData]="careerDetailData"
            >
            </ets-training-provider>
          </div>
        </div>

        <div class="skill-sets-container">
          <div class="content">
            <div
              *ngIf="
                careerDetailData?.transferableSkillSets?.transferableSkills
              "
            >
              <div class="transferable-skills-container">
                <span class="sub-title">TRANSFERABLE SKILL SETS</span>
                <div class="instruction-wrapper">
                  <img
                    src="../../../assets/icons/finger-tap.svg"
                    class="icon"
                    alt=""
                    aria-hidden="true"
                  />
                  <span
                  >Select any of the skills below to add them to your set of
                    skills in order to find out what path works for you.</span
                  >
                </div>
                <div class="scoring-info">
                  <span class="text">How are my skills scored?</span>
                  <span>
                    <div class = "desktop-check">
                    <ets-tooltip [text]="skillsScoredInfo" [innerToolTip]="true" [toolTipSize]="'sm'" [width]="'250px'"
                                 [height]="'50px'" [position]="'tooltip-top-left'"
                                 [backgroundColor]="'#565656'"></ets-tooltip>
                    </div>
                    <div class = "mobile-check">
                      <ets-tooltip [text]="skillsScoredInfo" [innerToolTip]="true" [toolTipSize]="'sm'" [width]="'200px'"
                                   [height]="'50px'" [position]="'tooltip-top-left'"
                                   [backgroundColor]="'#565656'"></ets-tooltip>
                      </div>

                  </span>
                </div>

                <div class="border"></div>
                <div class="transferable-skillsets-list">
                  <div class="skill-pills-container">
                    <button
                      *ngFor="
                        let transferableSkill of careerDetailData
                          ?.transferableSkillSets?.transferableSkills
                      "
                      class="btn btn-outline skill-pill"
                      [ngClass]="{
                        'active-skill-pill':
                          transferableSkill?.eulerSkillProficient,
                        'non-active-skill-pill':
                          !transferableSkill?.eulerSkillProficient
                      }"
                      (click)="toggleTransferableSkillSet(transferableSkill)"
                      id="{{ transferableSkill.eulerSkillName }}"
                    >
                      <span class="description">{{
                        transferableSkill.eulerSkillName
                        }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="specialized-skills-container"
              *ngIf="!careerDetailData?.inDemand"
            >
              <span class="sub-title">JOB-SPECIFIC SKILL SETS</span>
              <div class="instruction-wrapper">
                <img
                  src="../../../assets/icons/finger-tap.svg"
                  class="icon"
                  alt=""
                  aria-hidden="true"
                />
                <span
                >Select any of the skills below to add them to your set of
                  skills in order to find out what path works for you.</span
                >
              </div>
              <div class="border"></div>
              <div class="specialized-skillsets-list">
                <div class="skill-pills">
                  <button
                    *ngFor="
                      let eulerSkill of careerDetailData?.specializedSkills
                    "
                    class="btn btn-outline skill-pill"
                    [ngClass]="{
                      'active-skill-pill': eulerSkill?.eulerSkillProficient,
                      'non-active-skill-pill': !eulerSkill?.eulerSkillProficient
                    }"
                  >
                    <span class="description">{{
                      eulerSkill.eulerSkillName
                      }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="careerDetailData?.specializedSkillSets">
              <div class="specialized-skillsets-container">
                <span class="sub-title">Specialized Skill Sets</span>
                <div class="instruction-wrapper">
                  <img
                    src="../../../assets/icons/finger-tap.svg"
                    class="icon"
                    alt=""
                    aria-hidden="true"
                  />
                  <span
                  >Select any of the skills below to add them to your set of
                    skills in order to find out what path works for you.</span
                  >
                </div>
                <div class="scoring-info">
                  <span class="text">How are my skills scored?</span>
                  <span>
                    <div class = "desktop-check">
                    <ets-tooltip [text]="skillsScoredInfo" [innerToolTip]="true" [toolTipSize]="'sm'" [width]="'250px'"
                                 [height]="'50px'" [position]="'tooltip-top-left'"
                                 [backgroundColor]="'#565656'"></ets-tooltip>
                    </div>
                    <div class = "mobile-check">
                      <ets-tooltip [text]="skillsScoredInfo" [innerToolTip]="true" [toolTipSize]="'sm'" [width]="'200px'"
                                   [height]="'50px'" [position]="'tooltip-top-left'"
                                   [backgroundColor]="'#565656'"></ets-tooltip>
                      </div>
                  </span>
                </div>
                <div class="border"></div>
                <div class="specialized-skillsets-list">
                  <div
                    class="specialized-skillset"
                    *ngFor="let skill of careerDetailData?.specializedSkillSets"
                  >
                    <div class="specialized-skill-box">
                      <div class="wrapper">
                        <div
                          class="skills-info"
                          *ngIf="careerDetailData?.inDemand"
                        >
                          <button
                            class="expand-button desktop"
                            (click)="toggleSkillsetVisibility(skill)"
                            *ngIf="skill.showSkillset"
                          >
                            <fa-icon [icon]="faChevronDown"></fa-icon>
                          </button>
                          <button
                            class="expand-button desktop"
                            (click)="toggleSkillsetVisibility(skill)"
                            *ngIf="!skill.showSkillset"
                          >
                            <fa-icon [icon]="faChevronUp"></fa-icon>
                          </button>
                          <div class="specialized-skill-wrapper">
                            <div class="skill-header">
                              <span class="skill-title">{{
                                skill.skillsetName
                                }}</span>
                              <div class="mobile skill-value">
                                <span
                                  class="proficiency-text"
                                  *ngIf="skill.skillsetProficiency === 1"
                                >It's a start</span
                                >
                                <span
                                  class="proficiency-text"
                                  *ngIf="skill.skillsetProficiency === 2"
                                >Okay</span
                                >
                                <span
                                  class="proficiency-text"
                                  *ngIf="skill.skillsetProficiency === 3"
                                >Looking good</span
                                >
                                <span
                                  class="proficiency-text"
                                  *ngIf="skill.skillsetProficiency === 4"
                                >Very good</span
                                >
                                <span
                                  class="proficiency-text"
                                  *ngIf="skill.skillsetProficiency === 5"
                                >Great</span
                                >
                                <span class="proficiency-number"
                                >{{ skill.skillsetProficiency }}/5</span
                                >
                              </div>
                            </div>
                            <span
                              class="skill-description"
                              *ngIf="skill.showSkillset"
                            >{{ skill.skillsetDescription }}</span
                            >
                            <div class="skill-pills" *ngIf="skill.showSkillset">
                              <button
                                *ngFor="let eulerSkill of skill.eulerSkills"
                                class="btn btn-outline skill-pill"
                                [ngClass]="{
                                  'active-skill-pill':
                                    eulerSkill?.eulerSkillProficient,
                                  'non-active-skill-pill':
                                    !eulerSkill?.eulerSkillProficient
                                }"
                                (click)="toggleSpecializedSkillSet(eulerSkill, skill.skillsetID)"
                                id="{{ eulerSkill.eulerSkillName }}"
                              >
                                <span class="description">{{
                                  eulerSkill.eulerSkillName
                                  }}</span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="desktop specialized-skill-value">
                          <span *ngIf="skill.skillsetProficiency === 1"
                          >It's a start</span
                          >
                          <span *ngIf="skill.skillsetProficiency === 2"
                          >Okay</span
                          >
                          <span *ngIf="skill.skillsetProficiency === 3"
                          >Looking good</span
                          >
                          <span *ngIf="skill.skillsetProficiency === 4"
                          >Very good</span
                          >
                          <span *ngIf="skill.skillsetProficiency === 5"
                          >Great</span
                          >
                          <div class="value-list">
                            <span
                              class="value"
                              [ngClass]="{
                                'success-value': skill.skillsetProficiency >= 1,
                                'empty-value': skill.skillsetProficiency === 0
                              }"
                            ></span>
                            <span
                              class="value"
                              [ngClass]="{
                                'success-value': skill.skillsetProficiency >= 2,
                                'empty-value': skill.skillsetProficiency <= 1
                              }"
                            ></span>
                            <span
                              class="value"
                              [ngClass]="{
                                'success-value': skill.skillsetProficiency >= 3,
                                'empty-value': skill.skillsetProficiency <= 2
                              }"
                            ></span>
                            <span
                              class="value"
                              [ngClass]="{
                                'success-value': skill.skillsetProficiency >= 4,
                                'empty-value': skill.skillsetProficiency <= 3
                              }"
                            ></span>
                            <span
                              class="value"
                              [ngClass]="{
                                'success-value': skill.skillsetProficiency >= 5,
                                'empty-value': skill.skillsetProficiency <= 4
                              }"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sources-wrapper">
            <span class="burning-glass-text">Emsi Burning Glass Labor Market Data, 2021</span>
            <div class="bgt-logo-wrapper">
              <img src="../../../assets/BGT-logo.png" alt="" aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>


      <div class="right-section desktop">
        <div class="chart-wrapper">
          <div class="career-match-percent">
            <div class="percent-outer-circle">
              <ngx-charts-pie-chart
                [view]="view"
                [scheme]="colorScheme"
                [results]="results"
                [gradient]="gradient"
                [tooltipDisabled]="disableTooltip"
                [doughnut]="isDoughnut"
                alt=""
                aria-hidden="true"
              >
              </ngx-charts-pie-chart>
              <div class="percent-inner-circle">
                <span class="percent" *ngIf="careerDetailData">
                  {{ displayedResult }}%
                </span>
                <span class="percent-description">Career Match</span>
              </div>
            </div>
          </div>
        </div>
        <div class="career-match-content">
          <h1>CAREER MATCH SCORE</h1>
          <p><span class="bold" role="text">Increase your Career Match Score</span>
            by gaining new skills through our set of recommended trainings that will set you up for success in this
            career.
          </p>
          <div class="flex-row">
            <span>HOW IS MY SCORE CALCULATED </span>
            <span>
              <ets-tooltip [text]="calculatedInfo" [innerToolTip]="true" [toolTipSize]="'sm'" [width]="'230px'"
                           [height]="'92px'" [position]="'tooltip-top-left'"
                           [backgroundColor]="'#565656'"></ets-tooltip>
            </span>
          </div>
        </div>

        <div class="jobs-trainings-wrapper">
          <!-- Job Opportunities  -->
          <ets-job-posting-carousel [careerID]="careerDetailData?.careerID"></ets-job-posting-carousel>
  
          <!-- training provider carousel component -->
          <ets-training-provider
            [careerTitle]="careerDetailData?.careerTitle"
            [careerID]="careerDetailData?.careerID"
            [careerDetailData]="careerDetailData"
          >
          </ets-training-provider>
        </div>
      </div>
    </div>
  </div>
</div>
