<ets-header [isBGWhite]="true" [headerState]="headerState" [showBackButton]="false">
</ets-header>

<div class="container">
  <ets-sne-overview [task]="'changing-circumstances-instructions'" [showModal]="showModal"
    (modalClosed)='toggleModal()'></ets-sne-overview>
  <ets-sne-overview [task]="'coachability-multi-choice-video-hint'" [showModal]="showHintModal"
    (modalClosed)='toggleHintModal()'></ets-sne-overview>

  <div class="content-wrapper">
    <h3 class="question-text">Based on these reactions, <span class="bold-blue-text">choose three members</span> to
      learn the new program</h3>
    <ets-outline-button [text]="'Hint'" [color]="APP_COLORS.uiaction"
      [iconImgSrc]="'/assets/icons/blue-lightbulb-no-flash.svg'" [iconImgAlt]="'blue lightbulb'" [iconWidth]="'16px'"
      (clickEvent)="toggleHintModal()">
    </ets-outline-button>

    <div class="videos-wrapper">
        <div class="video-parent-container" *ngFor="let video of videos">
          <ets-sne-video
            [videoUrl]="video.url"
            [videoId]="video.id"
            [videoTitle]="video.title"
            [inputType]="video.inputType"
            (userResponseEvent)="handleUserResponse($event)">
          </ets-sne-video>
        </div>
    </div>
  </div>

  <ets-sne-progress-bar
    [questionIndex]="moduleIndex"
    [moduleSize]="moduleSize"
    [isButtonDisabled]="multiResponse.length !== questionData?.numSelectOptions"
    (overviewEmitter)="toggleModal()"
    (nextEmitter)="completeChangingCircumstancesTask()"
    [drawerActive]="false"
  ></ets-sne-progress-bar>
</div>
