import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from '../../../../global-styles';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { SneAnswerService } from '../../../services/sne-answer.service';
import { SneCoachabilityBase } from '../sne-coachability.base';
import { SNE_INPUT_TYPES} from 'src/app/types/sne';

@Component({
  selector: 'ets-coachability-split-screen-multi-choice-video',
  templateUrl: './coachability-split-screen-multi-choice-video.component.html',
  styleUrls: ['./coachability-split-screen-multi-choice-video.component.scss']
})
export class CoachabilitySplitScreenMultiChoiceVideoComponent extends SneCoachabilityBase implements OnInit {
  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  moduleSize: number = 6;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  inputType = this.questionData?.inputType;
  singleResponse: string = '';

  ngOnInit(): void {
    this.processParams();
  }

  handleUserResponse(emittedVideoId: string | undefined) {
    const vidFound = this.questionData?.videos?.find(
      // using includes here because mobile videos will have an id of "<videoId>-mobile"
      (video) => emittedVideoId?.includes(video.id)
    );

    if (vidFound && vidFound.answerChoice) {
      if (this.questionData?.inputType === SNE_INPUT_TYPES.RADIO) {
        this.singleResponse = vidFound.answerChoice;
      }
    }
  }
}
