import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SneAssessmentRecord } from '../../types/sne';
import { findNextAssessmentPath } from '../../sne-v2/sne-v2.helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'ets-dashboard-skills',
  templateUrl: './dashboard-skills.component.html',
  styleUrls: ['./dashboard-skills.component.scss']
})
export class DashboardSkillsComponent implements OnChanges {

  constructor(private router: Router) { }

  @Input() skillAssessments: Array<any> = [];
  @Input() assessmentRecords: SneAssessmentRecord[] = [];

  totalAssessmentsCompleted = 0;
  totalAssessments = 0;


  navigateToAssessment(assessmentType: string): void {
    const path = findNextAssessmentPath(this.assessmentRecords, assessmentType);
    if (path) {
      this.router.navigate([path]);
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['skillAssessments']) {
      this.totalAssessmentsCompleted = 0;
      this.totalAssessments = 0;
      this.skillAssessments.forEach(assessment => {
        if (assessment.SNE && assessment.show) {
          this.totalAssessments += 1;
          this.totalAssessmentsCompleted = assessment.status === 'Completed' ? this.totalAssessmentsCompleted + 1 : this.totalAssessmentsCompleted;
        }
      });
    }
  }

}
