<div class="container">
  <div *ngFor="let section of sectionList; index as index">
    <div class="progress-bar">
      <label for="progress-bar" class="progress-bar-label" id="progress-bar">progress bar</label>
      <div class="progress-bar-section"
      [ngStyle]="{'width':progressSectionWidth,
      'background-color': index+1 < sectionIndex! ? APP_COLORS.uiaction: APP_COLORS.lbbsoft}">
      </div>
      <div class="whitespace-bar" *ngIf="section !==sectionList!.length"></div>
    </div>
  </div>
</div>