export const COUNTRIES = [
  'Spain',
  'United Kingdom',
  'United States',
  'Italy',
  'Mexico',
  'Canada',
  'Colombia'
];

export const LANGUAGES = [
  'English',
  'Spanish',
  'French',
  'Italian',
  'Chinese',
  'Japanese'
];
