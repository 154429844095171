import QUESTION_TYPES from 'src/app/types/question-types';
import OC_TYPES from 'src/app/types/oral-communication-types';
import {
  SneQuestion,
  SneMulChoiceOption,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from '../../../types/sne'; // OralCommunicationQuestion

export const OCDialogic1InstructionModal: SneModal = {
  title: 'A Call Between Coworkers',
  pageList: [
    {
      info: 'Two coworkers who are on the same collaborative team at a marketing firm are having their daily update meeting. Today’s meeting is held via video call.<br><br>This section contains multiple videos and corresponding questions. You can only play each video <span class=\'convertToBlue\'>once</span>. <br><br>Play the first video. Then answer the questions.',
    },
  ],
};

const question_4_answers: SneMulChoiceOption[] = [
  {
    userOption: 'Appropriate',
    userLetter: 'A',
  },
  {
    userOption: 'Not appropriate',
    userLetter: 'B'
  }
];

const question_6_answers: SneMulChoiceOption[] = [
  {
    userOption: 'Mike',
    userLetter: 'A',
  },
  {
    userOption: 'Anya',
    userLetter: 'B'
  },
  {
    userOption: 'Both',
    userLetter: 'C',
  }
];

export const OCDialogic1Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'A Call Between Coworkers',
      info: 'This task will give you insight into how you comprehend subtleties and nuance in conversation, and we will use what we learn together to enhance your Skills Profile.<br><br>You will play one or more videos and answer corresponding questions.  In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you\'ll have a chance to pause when you finish.',
      questionIndex: 3, 
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      image: '/assets/section-4/sne-interstitial/dialogic-1.svg', 
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/617830667',
        id: '617830667',
        title: 'Evaluating a Request',
        mainHeader: 'Video 1',
        description: 'Watch your colleagues\' video conversation, then answer the first question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true, // the second question video needs to be disabled once its been played here.
      },
      header: 'What is Anya\'s intent in having this discussion with Mike?',
      singleMulChoiceOptions: [
        {
          userOption: 'To assert her role as a leader in their department',
          userLetter: 'A',
        },
        {
          userOption: 'To discuss ways to improve their work environment',
          userLetter: 'B',
        },
        {
          userOption: 'To share the progress she has made on their proposal',
          userLetter: 'C',
        },
        {
          userOption: 'To communicate her preference for working from home',
          userLetter: 'D',
        },
        {
          userOption: 'To suggest that they move their workstations further apart',
          userLetter: 'E',
        },
      ],
      submitId: 'item_1'
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      headerVideo: {
        url: 'https://vimeo.com/617830667',
        id: '617830667',
        title: 'Evaluating a Request',
        mainHeader: 'Video 1',
        description: 'Watch your colleagues\' video conversation, then answer the first question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'What are some positive attributes of Anya\'s comments to Mike? Select <span class=\'convertToBlue\'>two</span> answers.',
      singleMulChoiceOptions: [
        {
          userOption: 'Anya admits her own faults as part of a compromise',
          userLetter: 'A',
        },
        {
          userOption: 'Anya appropriately uses humor to get Mike\'s attention',
          userLetter: 'B',
        },
        {
          userOption: 'Anya uses a calm, firm tone when stating the problem',
          userLetter: 'C',
        },
        {
          userOption:
            'Anya incorporates both positive feedback and constructive criticism in her comments',
          userLetter: 'D',
        },
        {
          userOption:
            'Anya suggests both innovative and traditional solutions to the problem',
          userLetter: 'E',
        },
      ],
      numSelectOptions: 2,
      submitId: 'item_2',
      sharedVideo: { 
        url: 'https://vimeo.com/617830667',
        id: '617830667',
      },
    },
  ],
  [
    'question-3', {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MADLIBS_VIDEO,
      headerVideo: {
        url: 'https://vimeo.com/617830578',
        id: '617830578',
        title: 'Gauging the Response',
        mainHeader: 'Video 2',
        description: 'Continue watching your colleagues\' video conversation, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'Complete the sentence',
      madlibsBlanks: [
        {
          prompt: 'Mike\'s reaction is',
          choices: [
            {
              option: 'Appropriate',
              letter: 'A',
            },
            {
              option: 'Not Appropriate',
              letter: 'B',
            },
          ],
        },
        {
          prompt: 'because he',
          choices: [
            {
              option: 'Raises his voice',
              letter: 'A',
            },
            {
              option: 'References a scientific study',
              letter: 'B',
            },
            {
              option: 'Makes assumptions about other employees',
              letter: 'C',
            },
          ],
        },
        {
          prompt: 'and',
          choices: [
            {
              option: 'Uses name-calling.',
              letter: 'A',
            },
            {
              option: 'Offers an unconventional solution.',
              letter: 'B',
            },
            {
              option: 'Suggests escalating the issue to management.',
              letter: 'C',
            },
          ],
        },
      ],
      numSelectOptions: 3,
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/617830476',
        id: '617830476',
        title: 'Continuing the Discussion',
        mainHeader: 'Video 3',
        description: 'Continue watching your colleague\'s video conversation, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'Select whether each attribute of Anya\'s response to Mike is appropriate or not appropriate for a workplace conflict.',
      multipleMulChoiceOptions: [
        {
          question: 'Names other employees who have raised similar concerns',
          mulChoiceOptions: [...question_4_answers],
        },
        {
          question: 'Details how the problem is impacting business relations',
          mulChoiceOptions: [...question_4_answers],
        },
        {
          question: 'Uses light humor to help defuse the situation',
          mulChoiceOptions: [...question_4_answers],
        }
      ],
      numSelectOptions: 3,
      submitId: 'item_4'
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO,
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      headerVideo: {
        url: 'https://vimeo.com/617830365',
        id: '617830365',
        title: 'Reaching a Conclusion',
        mainHeader: 'Video 4',
        description: 'Finish watching your colleagues\' video conversation, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true
      },
      header: 'What are the positive features of Mike\'s response? Select <span class=\'convertToBlue\'>two</span> answers. ',
      singleMulChoiceOptions: [
        {
          userOption: 'Mike admits some fault and suggests a compromise',
          userLetter: 'A',
        },
        {
          userOption: 'Mike uses a tone that helps validate Anya\'s concerns',
          userLetter: 'B',
        },
        {
          userOption:
            'Mike quickly turns back to the work after the conflict is resolved',
          userLetter: 'C',
        },
        {
          userOption:
            'Mike uses sarcasm to establish a positive rapport with Anya',
          userLetter: 'D',
        },
        {
          userOption:
            'Mike encourages Anya to consider other ways of working together',
          userLetter: 'E',
        },
      ],
      numSelectOptions: 2,
      submitId: 'item_5'
    },
  ],
  // insert question 6 --> multiple question video (similar to Coachability_Survey)
  [
    'question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO,
      inputType: SNE_INPUT_TYPES.RADIO,
      headerVideo: {
        url: 'https://vimeo.com/605594455',
        id: '605594455',
        title: 'Reviewing Body Language',
        mainHeader: 'Video 5',
        description: 'Review your colleagues\' video conversation, then answer the question. Pay attention, this video can only be played <span class="convertToBlue">once</span>.',
        playOnce: true,
      },
      header: 'Consider the body language and nonverbal cues used by both people. For each behavior, select whether it was used by Mike, by Anya, or by both.',
      multipleMulChoiceOptions: [
        {
          question: 'Maintains appropriate eye contact.',
          mulChoiceOptions: [...question_6_answers],
        },
        {
          question: 'Uses facial expressions that consistently show empathy.',
          mulChoiceOptions: [...question_6_answers],
        },
      ],
      numSelectOptions: 2,
      submitId: 'item_6',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work determining how to deliver that presentation! Your decisions help illustrate how you understand best practices for speaking with co-workers, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png',
      questionIndex: 3, 
      moduleSize: 6,
      buttonText: 'NEXT',
      goto: OC_TYPES.DIALOGIC_2,
    },
  ],
]);
