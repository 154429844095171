import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { SneAnswerService } from 'src/app/services/sne-answer.service';

@Component({
  selector: 'ets-ct-interstitial',
  templateUrl: './ct-interstitial.component.html',
  styleUrls: ['./ct-interstitial.component.scss']
})
export class CtInterstitialComponent extends SneCriticalThinkingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  endInterstitial = false;

  ngOnInit() {
    // Get the route params to determine if it's the end interstitial
    this.route.params.subscribe(params => {
      const { questionNum, section } = params;
      this.endInterstitial = questionNum === 'end';
    });
    this.processParams();
  }

}
