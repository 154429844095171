// THIS IS WHAT USER KNOWS (NOT INITIALLY VISIBLE TO THE BOT, EXCEPT FOR THE TWO SHARED FACTS)
export let TT_facts_A = [
  {
    content: 'Provides free software updates for the entire duration of the contract term', // content
    private: 'P',      // private or shared; all facts start out as private. Shared fact is "S"
    impact: 1,        // 1 for positive, -1 for negative, 0 for irrelevant facts
    id: 't_A0' // fact id
  },
  {
    content: 'Has placed several bids on US government contracts',
    private: 'P',
    impact: 0,
    id: 't_A1'
  },
  {
    content: 'Actively promotes its products on social media',
    private: 'P',
    impact: 0,
    id: 't_A2'
  }
];

export let TT_facts_B = [
  {
    content: 'Maintains its corporate headquarters in the Netherlands',
    private: 'P',
    impact: 0,
    id: 't_B0'
  },
  {
    content: 'Makes software documentation available in many languages',
    private: 'P',
    impact: 1,
    id: 't_B1'
  },
  {
    content: 'Offers a 15% discount if the contract is signed for 2+ years',
    private: 'P',
    impact: 1,
    id: 't_B2'
  }
];


// THIS IS WHAT BOT KNOWS (NOT INITIALLY VISIBLE TO THE TEST TAKER, EXCEPT FOR THE TWO SHARED FACTS)
export let BOT_facts_A = [
  {
    content: 'Had its software recognized as the fastest of its kind in the industry',
    private: 'P',
    impact: 1,
    id: 'b_A0',
    overlap: false
  },
  {
    content: 'Has placed several bids on US government contracts',
    private: 'P',
    impact: 0,
    id: 'b_A1',
    overlap: true
  },
  {
    content: 'Has designed a graphical user interface that is very intuitive and easy to learn',
    private: 'P',
    impact: 1,
    id: 'b_A2',
    overlap: false
  }
];

export let BOT_facts_B = [
  {
    content: 'Already has several clients in your industry',
    private: 'P',
    impact: 1,
    id: 'b_B0',
    overlap: false
  },
  {
    content: 'Has recently acknowledged a major security flaw in one of its products',
    private: 'P',
    impact: -1,
    id: 'b_B1',
    overlap: false
  },
  {
    content: 'Offers a 15% discount if the contract is signed for 2+ years',
    private: 'P',
    impact: 1,
    id: 'b_B2',
    overlap: true
  }
];

export const shared_fact_options_list = [
  {
    id:'positive',
    copy:'Yes, this fact is new and seems relevant to our task.',
    response: 'Great, this brings us closer to our goal!'
  },
  {
    id:'negative',
    copy:'Well... this fact is new, but I do not think it is relevant.',
    response: 'It\'s OK, though, we are still making progress sorting through the facts.'
  },
  {
    id:'neutral',
    copy: 'Thanks for sharing, but I already know this fact.',
    response: 'Oh well, sorry. No worries, though, let\'s keep going.'
  }
];
