<ets-header [isBGWhite]="true" [headerState]="headerState" [showBackButton]="false">
</ets-header>

  <div class="container">
    <div class="content-wrapper">
      <ets-single-multi-choice-question 
        [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
        (selectSingleResponseEvent)="singleResponseHandler($event)"
        (selectMultiResponseEvent)="multiResponseHandler($event)" 
        [headerQuestion]="questionData?.header"
        [inputType]="questionData?.inputType"
        [centerAlign]="true"
      >
      </ets-single-multi-choice-question>
    </div>
  </div>

<!-- Params for progress bar from base class -->
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  [drawerActive]="false"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
  : multiResponse.length !== questionData?.numSelectOptions"
>
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  [info]="instructionModal?.customContent[0].info"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video 
      *ngIf="instructionModal?.customContent[0].url"
      [videoUrl]="instructionModal?.customContent[0].url"
      [videoId]="instructionModal?.customContent[0].id"
      [videoOnlyFlag]="true"
      [playOnce]="instructionModal?.customContent[0].playOnce"
      [sharedVideo]="instructionModal?.customContent[0].sharedVideo"
    >
    </ets-sne-video>
  </div>
</ets-modal>