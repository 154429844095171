import { Component } from '@angular/core';

@Component({
  selector: 'ets-critical-thinking',
  templateUrl: './critical-thinking.component.html',
  styleUrls: ['./critical-thinking.component.scss']
})
export class CriticalThinkingComponent {

  constructor() { }

}
