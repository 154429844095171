<button
  class="btn solid-button"
  [disabled]="disabled"
  [ngStyle]="{
    'background-color': color ? color : '',
    'min-width': buttonMaxWidth ? '100%' : '',
    'padding': padding ? padding : ''
  }"
  [ngClass]="{
    'disable-button': disabled,
    'small-button': size === 'sm',
    'medium-button': size === 'md'
  }"
  (click)="onClick()"
>
  {{text}}
</button>
