import {
  HiddenProfileUserResponses,
  HP_BOT_MESSAGES_KEYS,
  SneModal,
  SneQuestion,
  VENDOR_FACT_IMPACT,
  VENDOR_SET,
  VendorFact,
} from '../../../types/sne';
import CPS_TYPES from 'src/app/types/cps-types';
import QUESTION_TYPES from 'src/app/types/question-types';

export const HPData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Hidden Profile',
      info: 'This task will give you insight into how you exchange information to complete a shared goal, and we will use what we learn together to enhance your Skills Profile.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      questionIndex: 2,
      moduleSize: 3,
      durationBoxColor: 'mustard-yellow',
      image: '/assets/section-1/sne-interstitial/hidden-profile.svg',
      buttonText: 'Next',
      modalBeforeNext: true,
    },
  ],
  [
    'question',
    {
      questionType: QUESTION_TYPES.HIDDEN_PROFILE,
    }
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Excellent work collaborating with your teammate! Your decisions help illustrate how you work with a teammate to solve problems, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready. One more to go!',
      questionIndex: 2,
      moduleSize: 3,
      image: '/assets/section-1/sne-interstitial/cps-end-interstitial.png',
      buttonText: 'NEXT',
      goto: CPS_TYPES.NEGOTIATION,
    },
  ],
]);


export const HPInstructionModal: SneModal = {
  pageList: [
    {
      title: 'Work Together',
      info: '<span class="convertToBlue">In this task, you and your virtual teammate, Tabitha, will need to work together to select the best software vendor for your company.</span> <br> <br> Each of you knows several facts about the two vendors. Some of the facts may be known to both of you, while others may be known only to you or only to Tabitha. Sharing information with your teammate will help your team make the best choice.',
    },
    {
      title: 'Evaluate Facts',
      info: 'Certain facts may be irrelevant to the decision you are trying to make. Sharing such facts with Tabitha will not be helpful. <br> <br> Your goal is to interact with your teammate and select the best vendor based on your combined information.',
    },
  ],
};

export const HPSubmitModal: SneModal =
{
  pageList: [
    {
      title: 'Time to submit',
      info: 'I think we’re ready to give this information to our director—it should help her choose which firm to hire! But we can always review before we submit if we want to be sure.',
    },
  ],
};

export const VendorFacts: VendorFact[] = [
  {
    fact: 'Provides free software updates for the entire duration of the contract term',
    vendor: VENDOR_SET.A,
    userKnown: true,
    botKnown: false,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 't_A0'
  },
  {
    fact: 'Has placed several bids on US government contracts',
    vendor: VENDOR_SET.A,
    userKnown: true,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.IRRELEVANT,
    id: 't_A1'
  },
  {
    fact: 'Actively promotes its products on social media',
    vendor: VENDOR_SET.A,
    userKnown: true,
    botKnown: false,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.IRRELEVANT,
    id: 't_A2'
  },
  {
    fact: 'Maintains its corporate headquarters in the Netherlands',
    vendor: VENDOR_SET.B,
    userKnown: true,
    botKnown: false,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.IRRELEVANT,
    id: 't_B0'
  },
  {
    fact: 'Makes software documentation available in many languages',
    vendor: VENDOR_SET.B,
    userKnown: true,
    botKnown: false,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 't_B1'
  },
  {
    fact: 'Offers a 15% discount if the contract is signed for 2+ years',
    vendor: VENDOR_SET.B,
    userKnown: true,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 't_B2'
  },
  {
    fact: 'Had its software recognized as the fastest of its kind in the industry',
    vendor: VENDOR_SET.A,
    userKnown: false,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 'b_A0',
  },
  {
    fact: 'Has designed a graphical user interface that is very intuitive and easy to learn',
    vendor: VENDOR_SET.A,
    userKnown: false,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 'b_A2',
  },
  {
    fact: 'Already has several clients in your industry',
    vendor: VENDOR_SET.B,
    userKnown: false,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.POSITIVE,
    id: 'b_B0',
  },
  {
    fact: 'Has recently acknowledged a major security flaw in one of its products',
    vendor: VENDOR_SET.B,
    userKnown: false,
    botKnown: true,
    botShared: false,
    impact: VENDOR_FACT_IMPACT.NEGATIVE,
    id: 'b_B1',
  }
];

export const HPSharedFacts: Map<string, HiddenProfileUserResponses> = new Map([
  ['positive', {
    message:'Yes, this fact is new and seems relevant to our task.',
    response: 'Great, this brings us closer to our goal!',
  }],
  ['negative', {
    message:'Well... this fact is new, but I do not think it is relevant.',
    response: 'It\'s OK, though, we are still making progress sorting through the facts.'
  }],
  ['neutral', {
    message: 'Thanks for sharing, but I already know this fact.',
    response: 'Oh well, sorry. No worries, though, let\'s keep going.'
  }],
]);

export const HPBotStatements: Map<HP_BOT_MESSAGES_KEYS, string> = new Map<HP_BOT_MESSAGES_KEYS, string>(
  [
    [
      HP_BOT_MESSAGES_KEYS.SHARE_FACT_SELECT ,
      'Sure. Click on the fact that you would like to share with me. <br><br>Once shared, the fact will be added to the list of facts and shaded to indicate that we both know it.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.VENDOR_FACT_SELECT,
      'Sure. Click on the fact that you would like to share with me. <br><br>Once shared, the fact will be added to the list of facts and shaded to indicate that we both know it.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.MENU,
      'Hi! I am your teammate. Remember that our goal is to interact and make the best vendor selection together. <br><br>What would you like to do?',
    ],
    [
      HP_BOT_MESSAGES_KEYS.CLOSER_TO_SELECTION,
      'Looks like we are closer to making our selection! <br> <br> How do you want to proceed?'
    ],
    [
      HP_BOT_MESSAGES_KEYS.SHARE_FACT_PROMPT,
      'No problem. Do you want information about Vendor A or about Vendor B? <br> <br> Once I share a fact with you, it will be added to the list of facts and shaded to indicate that we both know it.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_SELECTED_PROMPT,
      'OK, I know that.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_SUBMIT_PROMPT,
      'Great, this brings us closer to our goal!'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_ALREADY_KNOWN_B,
      'Oh yes, this is something I already know about Vendor B.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_ALREADY_KNOWN_A,
      'Oh yes, this is something I already know about Vendor A.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_NOT_KNOWN_A,
      'Thanks! I did not know this about Vendor A.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.FACT_NOT_KNOWN_B,
      'Thanks I did not know this about Vendor B.'
    ],
    [
      HP_BOT_MESSAGES_KEYS.VENDOR_SELECT,
      'OK, sure, which vendor do you want to pick?'
    ],
    [
      HP_BOT_MESSAGES_KEYS.VENDOR_SELECT_A,
      'Yes, I agree. The information I have shows that Vendor A is the best choice. Shall we go ahead and pick Vendor A, then?'
    ],
    [
      HP_BOT_MESSAGES_KEYS.VENDOR_SELECT_B,
      'Hmmm... Based on what I know, I see Vendor A as a better choice. Are you confident enough to pick Vendor B anyway?'
    ],
  ]
);




// THIS IS WHAT USER KNOWS (NOT INITIALLY VISIBLE TO THE BOT, EXCEPT FOR THE TWO SHARED FACTS)
export let TT_facts_A = [
  {
    content: 'Provides free software updates for the entire duration of the contract term', // content
    private: 'P',      // private or shared; all facts start out as private. Shared fact is "S"
    impact: 1,        // 1 for positive, -1 for negative, 0 for irrelevant facts
    id: 't_A0' // fact id
  },
  {
    content: 'Has placed several bids on US government contracts',
    private: 'P',
    impact: 0,
    id: 't_A1'
  },
  {
    content: 'Actively promotes its products on social media',
    private: 'P',
    impact: 0,
    id: 't_A2'
  }
];

export let TT_facts_B = [
  {
    content: 'Maintains its corporate headquarters in the Netherlands',
    private: 'P',
    impact: 0,
    id: 't_B0'
  },
  {
    content: 'Makes software documentation available in many languages',
    private: 'P',
    impact: 1,
    id: 't_B1'
  },
  {
    content: 'Offers a 15% discount if the contract is signed for 2+ years',
    private: 'P',
    impact: 1,
    id: 't_B2'
  }
];


// THIS IS WHAT BOT KNOWS (NOT INITIALLY VISIBLE TO THE TEST TAKER, EXCEPT FOR THE TWO SHARED FACTS)
export let BOT_facts_A = [
  {
    content: 'Had its software recognized as the fastest of its kind in the industry',
    private: 'P',
    impact: 1,
    id: 'b_A0',
    overlap: false
  },
  {
    content: 'Has placed several bids on US government contracts',
    private: 'P',
    impact: 0,
    id: 'b_A1',
    overlap: true
  },
  {
    content: 'Has designed a graphical user interface that is very intuitive and easy to learn',
    private: 'P',
    impact: 1,
    id: 'b_A2',
    overlap: false
  }
];

export let BOT_facts_B = [
  {
    content: 'Already has several clients in your industry',
    private: 'P',
    impact: 1,
    id: 'b_B0',
    overlap: false
  },
  {
    content: 'Has recently acknowledged a major security flaw in one of its products',
    private: 'P',
    impact: -1,
    id: 'b_B1',
    overlap: false
  },
  {
    content: 'Offers a 15% discount if the contract is signed for 2+ years',
    private: 'P',
    impact: 1,
    id: 'b_B2',
    overlap: true
  }
];

export const shared_fact_options_list = [
  {
    id:'positive',
    copy:'Yes, this fact is new and seems relevant to our task.',
    response: 'Great, this brings us closer to our goal!'
  },
  {
    id:'negative',
    copy:'Well... this fact is new, but I do not think it is relevant.',
    response: 'It\'s OK, though, we are still making progress sorting through the facts.'
  },
  {
    id:'neutral',
    copy: 'Thanks for sharing, but I already know this fact.',
    response: 'Oh well, sorry. No worries, though, let\'s keep going.'
  }
];
