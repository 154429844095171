import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CAREER_JOB_POSTINGS_MAP } from 'src/app/data/career-job-postings-map';
import { ProfileService } from 'src/app/services/profile.service';
import { selectProfile } from 'src/app/store';
import { setProfile } from 'src/app/store/profile.actions';
import { Assessment } from 'src/app/types/assessments';
import { JobPosting } from 'src/app/types/job-posting';
import Profile from 'src/app/types/profile';
import { AssessmentService } from '../../../services/assessment.service';

@Component({
  selector: 'ets-job-posting-carousel',
  templateUrl: './job-posting-carousel.component.html',
  styleUrls: ['./job-posting-carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class JobPostingCarouselComponent implements OnInit, OnChanges {

  constructor(private store: Store, private assessmentService: AssessmentService, private profileService: ProfileService) { }

  @Input() careerID: number | undefined;
  profile!: Profile;
  jobPostingIndex = 0;
  jobPostings: JobPosting[] | undefined;
  assessments: Assessment[] | undefined;
  CAREER_JOB_POSTINGS_MAP = CAREER_JOB_POSTINGS_MAP;

  ngOnInit() {
    this.assessmentService.getAssessments().subscribe(assessments => {
      this.assessments = assessments;
    });

    this.getProfile();
  }

  ngOnChanges() {
    if (this.careerID) {
      this.jobPostings = this.CAREER_JOB_POSTINGS_MAP.get(this.careerID);
    }
  }

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.profile = profile;
      } else {
        this.profileService.getProfile().subscribe(profile => {
          this.store.dispatch(setProfile({payload: profile}));
          this.profile = profile;
        });
      }
    });
  }

  increment() {
    if (this.jobPostings) {
      this.jobPostingIndex < this.jobPostings.length - 1 ? this.jobPostingIndex++ : this.jobPostingIndex = 0;
    }
  }

  decrement() {
    if (this.jobPostings) {
      this.jobPostingIndex > 0 ? this.jobPostingIndex-- : this.jobPostingIndex = this.jobPostings.length - 1;
    }
  }

  changeSlide(index: number){
    this.jobPostingIndex = index;
  }

}
