import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SneReportHeaderComponent } from './sne-report-header/sne-report-header.component';
import { SneReportBodyComponent } from './sne-report-body/sne-report-body.component';
import { SneReportComponent } from './sne-report.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SneReportHeaderComponent,
    SneReportBodyComponent,
    SneReportComponent,
  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    SneReportComponent,
  ]
})
export class SneReportModule { }
