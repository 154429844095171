<div class="container">
  <div class="overview-wrapper" [ngClass]="{'the-facts-wrapper': marquis === true}">
    <span *ngIf="marquis === undefined" class="overview">OVERVIEW</span>
    <span *ngIf="marquis === true" class="the-facts">THE FACTS</span>
  </div>
  <div class="content" *ngIf="title && subtitle">
    <span class="title">{{ title }}</span>
    <span class="subtitle" [innerHtml]="subtitle"></span>
    <span *ngIf="subtitle2" class="subtitle">{{ subtitle2 }} </span>
  </div>
  <div class="marquis-container" *ngIf="marquis === true">
    <img
      class="marquis-img"
      src="/assets/sne/cps/marquis-avatar.png"
      alt="Marquis"
    />
    <div class="marquis-text">
      <h2>Marquis</h2>
      <p>{{ info }}</p>
    </div>
  </div>
</div>
