import QUESTION_TYPES from 'src/app/types/question-types';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import { SneQuestion, SneModal, SNE_INPUT_TYPES } from '../../../types/sne';

export const CoachabilityCoachesData: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Coaches',
      info: 'This task will help us better understand how you might handle disagreements at work, and we’ll use the insights we gather from this task to enhance your Skills Profile.',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you’ll have the option to pause when you finish.',
      questionIndex: 3,
      moduleSize: 6,
      durationBoxColor: 'lbb',
      image: '/assets/section-3/sne-interstitial/coaches.svg',
      buttonText: 'Next',
      header: '',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
      header: 'How do you want to reply?',
      inputType: SNE_INPUT_TYPES.RADIO,
      botProfilePic: '/assets/sne/coachability-bill-profile-pic.png',
      youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
      singleMulChoiceOptions: [
        {
          userOption:
            'I don\'t know—it really seems like something that might only appeal to older guys, and they\'re not the ones spending the money right now. ', // userOption
          userLetter: 'A',
          autoUser: 'Bill',
          response:
            'You wouldn\'t think that if you understood it better. Let me explain.',
        },
        {
          userOption:
            'Sure, if that\'s what you think is best. I don\'t really have an opinion. ', // userOption
          userLetter: 'B',
          autoUser: 'Bill',
          response:
            'Great, but maybe I should go into a little more detail and explain it better.',
        },
        {
          userOption:
            'I always learn a lot from what you have to say. Can you explain it a little more? ', // userOption
          userLetter: 'C',
          autoUser: 'Bill',
          response: 'Good idea. I\'d love to! ',
        },
        {
          userOption:
            'I\'m pretty sure that the company isn\'t going to want to go in that direction. Why do you think that they will? Didn\'t you read that last memo about our strategy? ', // userOption
          userLetter: 'D',
          autoUser: 'Bill',
          response:
            'I read it, but the person who wrote that memo doesn\'t understand the realities of our business. Let me explain my ideas a little more to you. ',
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
      header: 'How do you want to reply?',
      botProfilePic:'/assets/sne/coachability-bill-profile-pic.png',
      youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
      inputType: SNE_INPUT_TYPES.RADIO,
      singleMulChoiceOptions: [
        {
          userOption:
            'OK, but I think you\'re totally on the wrong track here. Do you really think that kids would be at all interested in what you\'re proposing? ', // userOption
          userLetter: 'A',
          autoUser: 'Bill',
          response:
            'I do think that, and kids aren\'t our only market. We need to focus on all of our customers.',
        },
        {
          userOption:
            'Alright, but maybe you should ask Helen or Simone what they think about it. They might connect with it more than I am.', // userOption
          userLetter: 'B',
          autoUser: 'Bill',
          response:
            'Good idea. You do see my point, right? Kids aren\'t the only market. We need to focus on all of our customers. ',
        },
        {
          userOption:
            'Sure, you can tell me more, but I\'m going to go with what my team thinks in the end. You can work on it with your own team, if you think it\'s the right course.  ', // userOption
          userLetter: 'C',
          autoUser: 'Bill',
          response:
            'I think you should reconsider. Your team is too obsessed with the youth market. We need to focus on all of our customers. ',
        },
        {
          userOption:
            'That would be really helpful, thanks. I\'m not sure I agree yet, but you\'ve been at this a lot longer than I have, and I want to have all the facts before I make up my mind.  ', // userOption
          userLetter: 'D',
          autoUser: 'Bill',
          response:
            'That\'s all I\'m asking. We can\'t just aim our products at kids. We need to focus on all of our customers.',
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
      botProfilePic: '/assets/sne/coachability-bill-profile-pic.png',
      youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
      header: 'How do you want to reply?',
      nextPageHasHint: true,
      inputType: SNE_INPUT_TYPES.RADIO,
      singleMulChoiceOptions: [
        {
          userOption:
            'I really appreciate your perspective. I\'m going to give this a lot of thought overnight.  ', // userOption
          userLetter: 'A',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'I just think you\'re not getting where the company wants to go right now.   ', // userOption
          userLetter: 'B',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption: 'Yeah, OK. I have to get back to work now. ', // userOption
          userLetter: 'C',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Sure, but, respectfully, how many of those older “customers” are willing to pay big bucks for a skateboard? ', // userOption
          userLetter: 'D',
          autoUser: 'Bill',
          response: '',
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
      header:
        'Based on this conversation, what steps should you take to improve communication with your team ' +
        'the next time you need to tell them about a controversial new company policy? Please select the <span class=\'convertToBlue\'>three</span> best ' +
        'actions by clicking on each statement.',
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      numSelectOptions: 3,
      hintModalFlag: true,
      chatMessages: [
        {
          user: 'Manuel',
          message: 'I just wish we could get out of it. Can’t you figure out how to get us an exemption or something? We work hard; we shouldn’t have to put up with this.'
        },
        {
          user: 'Alex',
          message: 'I just think it’s a dumb policy, and it’s going to cost us a lot of talented workers. They’d rather work somewhere that doesn’t have this policy, and who would blame them?'
        },
        {
          user: 'Shanice',
          message: 'It’s kind of the WAY you did it as much as the new policy itself. You didn’t give us any time to ask you any questions—you just rushed through it like it didn’t matter.'
        },
        {
          user: 'Robyn',
          message: 'Yeah, it probably would have been a lot better if you just told us about it in your own words instead of reading that memo to us. We can read!'
        },
        {
          user: 'Brett',
          message: 'We know it’s not your fault and you probably can’t do anything about it, but it would have been nice to hear what you personally thought about it.'
        }
      ],
      singleMulChoiceOptions: [
        {
          userOption:
            'Suggest to your team that they post their opinions about the new policy on the company\'s employee social media site.  ', // userOption
          userLetter: 'A',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Reassure your team that no company policy will have a negative effect on the company\'s ability to attract talent. ', // userOption
          userLetter: 'B',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Provide time for questions and answers after introducing a new policy. ', // userOption
          userLetter: 'C',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Summarize the new policy and explain clearly what it will mean. ', // userOption
          userLetter: 'D',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Refer your team to any research studies that offer concrete evidence in support of the underlying idea of the new policy.  ', // userOption
          userLetter: 'E',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Explain to your team that you will do your best to make sure that they need not adhere to unpopular new policies. ', // userOption
          userLetter: 'F',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Discuss your own feelings about the new policy, focusing on both potentially positive and negative aspects. ', // userOption
          userLetter: 'G',
          autoUser: 'Bill',
          response: '',
        },
        {
          userOption:
            'Assure your team that you agree with their opinions about any new policy.  ', // userOption
          userLetter: 'H',
          autoUser: 'Bill',
          response: '',
        },
      ],
      hintModal: {
        title: 'Later that week...',
        pageList: [
          {
            info: 'You and several people you supervise have the following discussion about some unpopular new company policies that you had recently been asked to communicate to them.',
          },
        ],
      },
      complete: true,
      submitId: 'item_4',
    },
  ],

  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the third task.',
      image: '/assets/section-3/sne-interstitial/coach-end-interstitial.png', // placeholder
      info:  'Nice work communicating with your team! We\'ll use the decisions you made in this exercise to help us determine where you excel and where you have room to grow when managing differing opinions and perspectives. Feel free to pause if you need a break or start the next task if you\'re ready.',
      questionIndex: 3,
      moduleSize: 6,
      buttonText: 'Next',
      goto: COACHABILITY_TYPES.CRITICISM,
    },
  ],
]);

export const CoachabilityCoachInstructionModal: SneModal = {
  title: 'Reaction to Coaches',
  pageList: [
    {
      info: 'You are a manager at SportLand, a company that manufactures sporting equipment. You supervise one of the two marketing groups that are in charge of promoting SportLand products to the public.',
    },
    {
      info: 'Bill, one of your colleagues, supervises the other marketing group. Bill has worked at SportLand much longer than you have and has always served as both a trusted mentor and friend. But right now, Bill is arguing in favor of a marketing strategy that you think will actually hurt SportLand\'s image among the young, active demographic that the company is seeking to attract. Bill sends you an email asking for your support, but before you respond, he follows up with an instant message.',
    },
    {
      info: '',
      showCustomContent: true,
    },
  ],
  customContent: [
    {
      user: 'Bill',
      image: '/../assets/sne/coachability-bill-profile-pic.png',
      message:
        'Hey! What do you think of my idea? I think if we both back it, they\'ll really need to think about it seriously.',
    },
  ],
};
