import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-sne-instruction',
  templateUrl: './s-n-e-instruction.component.html',
  styleUrls: ['./s-n-e-instruction.component.scss'],
})
export class SNEInstructionComponent {
 @Input() title?: string;
 @Input() subtitle?: string;
 @Input() subtitle2?: string;
 @Input() marquis?: boolean;
 @Input() info?: string;

 constructor() {}
}
