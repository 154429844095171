<div class="container" *ngIf="currentQuestion">
  <div class="inner">
    <div class="info-wrapper">
      <h1 class="title"[innerHTML]="question">{{question}}</h1>
    </div>
    <div class="image-wrapper"
    >
      <img
        class="image"
        [src]="currentQuestion?.picFileName"
        alt="break-image"
      >
    </div>
  </div>
</div>
<div class="button-wrapper">
  <ets-rounded-contained-button
    [backgroundColor]="APP_COLORS.uiaction"
    [textColor]="APP_COLORS.white"
    [buttonText]="'Next Section'"
    (click)="submitResponse()"
  >
  </ets-rounded-contained-button>
</div>
