<div class="container">
  <input type="checkbox"
         id="{{id}}"
         name="{{name}}"
         value="{{value}}"
         (click)="onClick()"
         [disabled]="disable"
         [checked]="checked"
  >
  <label for="{{id}}">{{label}}</label>
</div>
