import { SneQuestion } from '../../types/sne';
import COACHABILITY_TYPES from '../../types/coachability-types';
import QUESTION_TYPES from '../../types/question-types';

export function  generateNextQuestionPath(question: SneQuestion, key: string,
  coachabilityType: COACHABILITY_TYPES, additionalNextQuestionSteps?: () => void): string {
  let path = '';
  if (question) {
    switch (question.questionType) {
      case QUESTION_TYPES.SNE_INTRO:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/sne-intro/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.INTERSTITIAL:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/interstitial/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/multi-choice-convo/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/multi-select-email/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_TEXT:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/survey/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.VIDEO_LIST:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/video-list/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_CONVO:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/multi-choice-list-convo/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/multi-choice-list-video/${key}/${coachabilityType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO:
        if (additionalNextQuestionSteps !== undefined) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/coachability/multi-choice-video/${key}/${coachabilityType}`;
        break;
      default:
        console.error('Cannot find question type');
    }
  }
  return path;
}
