<div role="region" aria-live="assertive" class="container">
  <div class="section"
       [ngStyle]="{'background-color': leftBackgroundColor}"
       [ngClass]="{'dont-show': hideMobile === 'left',
                   'cps-section': sneSection === 'left-cps',
                   'ct-section': sneSection === 'left-ct',
                   'coachability-section': sneSection === 'left-coachability',
                   'oc-section': sneSection === 'left-oc',
                   'wc-section': sneSection === 'left-wc' }">
    <ng-content select=".left"></ng-content>
  </div>
  <div class="section"
       [ngStyle]="{'background-color': rightBackgroundColor}"
       [ngClass]="{'dont-show': hideMobile === 'right',
                   'cps-section': sneSection === 'right-cps',
                   'ct-section': sneSection === 'right-ct',
                   'coachability-section': sneSection === 'right-coachability',
                   'oc-section': sneSection === 'right-oc',
                   'wc-section': sneSection === 'right-wc' }">
    <ng-content select=".right"></ng-content>
  </div>
</div>
