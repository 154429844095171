import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from './services/auth.service';
import { ProfileService } from './services/profile.service';
import { selectErrorModal } from './store';
import { setErrorModal } from './store/error-modal.actions';
import { setProfile } from './store/profile.actions';
import { Router } from '@angular/router';

declare global {
  interface Window { dataLayer: any; }
}

window.dataLayer = window.dataLayer || [];

@Component({
  selector: 'ets-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private store: Store, private authService: AuthService, private profileService: ProfileService, private router: Router) {}

  pageURL = '';
  showErrorModal = false;

  ngOnInit(): void {
    this.pageURL = window.location.href;
    if (this.authService.isLoggedIn()) {
      this.profileService.getProfile().subscribe(profile => this.store.dispatch(setProfile({payload: profile})));
    }
    this.store.select(selectErrorModal).subscribe(flag => this.showErrorModal = flag);
  }

  toggleErrorModal() {
    this.store.dispatch(setErrorModal({payload: false}));

    if (this.pageURL.includes('on-boarding')) {
      // Reload the page in onboarding because we can't route to the dashboard yet due to the guard
      window.location.reload();
    } else {
      // Route to dashboard
      this.router.navigate(['/dashboard']);
    }
  }
}
