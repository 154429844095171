import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectProfile } from '../../../store';
import { Store } from '@ngrx/store';
import { QUESTION_SECTIONS } from '../../../types/question-types';
import Profile from '../../../types/profile';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { Card, CARD_SIZE_ENUM } from 'src/app/types/question';
import OB_PAGES from '../../../types/on-boarding-pages.types';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'ets-card-selection-v2',
  templateUrl: './card-selection-v2.component.html',
  styleUrls: ['./card-selection-v2.component.scss']
})
export class CardSelectionV2Component extends OnBoardingV2Base implements OnInit, OnDestroy {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  CARD_SIZE_ENUM = CARD_SIZE_ENUM;
  OB_PAGES = OB_PAGES;

  questionSections = QUESTION_SECTIONS;
  selectedOption: string | undefined;
  cardsArray: Card[] | undefined;

  BENEFITS_ARRAY: Card[] = [
    {
      title: 'Health Insurance',
      image: '../assets/section-3/benefits/Health-Insurance.png',
      selected: false,
    },
    {
      title: 'Life Insurance',
      image: '../assets/section-3/benefits/Life-Insurance.png',
      selected: false,
    },
    {
      title: 'Disability Insurance',
      image: '../assets/section-3/benefits/Disability-Insurance.png',
      selected: false,
    },
    {
      title: 'Dental / Vision',
      image: '../assets/section-3/benefits/Dental-Vision.png',
      selected: false,
    },
    {
      title: 'Retirement Savings',
      image: '../assets/section-3/benefits/Retirement.png',
      selected: false,
    },
    {
      title: 'Paid Time Off',
      image: '../assets/section-3/benefits/PTO.png',
      selected: false,
    },
    {
      title: 'Family Leave',
      image: '../assets/section-3/benefits/Family-Leave.png',
      selected: false,
    },
    {
      title: 'Wellness Benefits',
      image: '../assets/section-3/benefits/Wellness.png',
      selected: false,
    },
    {
      title: 'Commuter Benefits',
      image: '../assets/section-3/benefits/Commuter.png',
      selected: false,
    },
    {
      title: 'Continued Education',
      image: '../assets/section-3/benefits/Continued-Education.png',
      selected: false,
    }
  ];

  processQuestion() {
    if (this.currentQuestion && this.userProfile) {
      const key = this.currentQuestion?.profileAttribute as keyof Profile;
      if (this.userProfile && this.userProfile[key]) {
        if (this.currentQuestion.selectAllThatApply) {
          this.selectedOption = undefined;
          const array = this.userProfile[key] as Array<string>;
          array.forEach(item => {
            const cardFound = this.currentQuestion?.cards?.find(card => card.title === item);
            if (cardFound) {
              cardFound.selected = true;
            }
          });
        } else {
          this.selectedOption = this.userProfile[key] as string;
        }
      }

      // if the next question is criticalBenefits
      if (this.currentQuestion.page === OB_PAGES.CRITICAL_BENEFITS && this.currentQuestion.cards && this.userProfile.desiredBenefits) {
        this.cardsArray = [...this.currentQuestion.cards];
        // Remove answer choices that weren't selected from the previous question
        for (let i = this.cardsArray.length - 1; i >= 0; i--) {
          if (!this.userProfile.desiredBenefits.includes(this.cardsArray[i].title)) {
            this.cardsArray.splice(i, 1);
          }
        }
      } else {
        this.cardsArray = undefined;
      }
    }
  };

  triggerNextQuestion() {
    // Single response
    if (this.selectedOption) {
      this.userResponse = this.selectedOption;
      this.selectedOption = undefined;
      this.submitResponse();
    } else {
      // Multi response
      if (this.cardsArray) {
        let answerArr: string[] = [];
        this.cardsArray.forEach(card => {
          if (card.selected) {
            answerArr.push(card.title);
          }
        });
        this.userResponse = [...answerArr];
        this.submitResponse();
      } else if (this.currentQuestion && this.currentQuestion.cards) {
        let answerArr: string[] = [];
        this.currentQuestion.cards.forEach(card => {
          if (card.selected) {
            answerArr.push(card.title);
          }
        });
        this.userResponse = [...answerArr];
        this.submitResponse();
      }
    }
  }

  cardToggle(index: number) {
    if (this.cardsArray) {
      this.cardsArray[index].selected = !this.cardsArray[index].selected;
    } else if (this.currentQuestion && this.currentQuestion.cards) {
      if (!this.currentQuestion?.selectAllThatApply) {
        this.selectedOption = this.currentQuestion.cards[index].title;
        this.triggerNextQuestion();
      } else {
        this.currentQuestion.cards[index].selected = !this.currentQuestion.cards[index].selected;
      }
    }
  }

  disableSubmit(): boolean {
    let cardSelected;
    if (this.currentQuestion && this.currentQuestion.cards) {
      cardSelected = this.currentQuestion.cards.find(card => {
        return card.selected;
      });
    }
    // if cardSelected is undefined, disable button (return true)
    return !cardSelected;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }

}
