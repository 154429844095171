<div class="container">
  <!-- <div class="content-wrapper"> -->
    <div class="question-wrapper" [ngClass]="{'question-wrapper-center': splitScreen === false}">
      <h3 class="question-text" *ngIf="question?.header" [innerHTML]="question.header">{{question.header}}</h3>
      <h3 class="question-text" *ngIf="question?.question" [innerHTML]="question.question">{{question.question}}</h3>
    </div>
    <div [class]="noModal ? 'videos-wrapper-coachability' : 'videos-wrapper'">
      <div class="video-parent-container" *ngFor="let video of videos">
        <ets-sne-video
          [videoSetId]="videoSetId"
          [videoUrl]="video.url"
          [videoId]="mobileCheck ? video.id + '-mobile' : video.id"
          [videoTitle]="video.title"
          [inputType]="video.inputType"
          (userResponseEvent)="onClick($event)"
          [noModal]="noModal"
        >
        </ets-sne-video>
      </div>
    </div>
  <!-- </div> -->
</div>
