<div class="right-side-container">
    <div class="known-facts-container">
      <span class="title">Facts Known to You</span>
      <div class="card-container">
        <div class="vendor-card">
          <span class="vendor">Software Vendor A</span>
            <div *ngFor="let statement of vendorFactsListA; let i = index">
                <div class="fact-card" *ngIf="statement.userKnown === true">
                    <div class="fact-card-num-wrapper">
                    <p class="fact-card-num">#{{i+1}}</p>
                    </div>
                    <div [ngClass]="{'shared-facts' : (statement.botShared === true), 'given-facts':(statement.botShared === false)}">
                    <span>{{statement.fact}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="vendor-card vendor-B">
          <span class="vendor">Software Vendor B</span>
          <div *ngFor="let statement of vendorFactsListB; let i = index">
              <div class="fact-card" *ngIf="statement.userKnown === true">
                <div class="fact-card-num-wrapper">
                <p class="fact-card-num">#{{i+1}}</p>
                </div>
                <div [ngClass]="{'shared-facts' : (statement.botShared === true), 'given-facts':(statement.botShared === false)}">
                <span>{{statement.fact}}</span>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
