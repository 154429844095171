import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'ets-yes-no-v2',
  templateUrl: './yes-no-v2.component.html',
  styleUrls: ['./yes-no-v2.component.scss']
})
export class YesNoV2Component extends OnBoardingV2Base implements OnInit, OnDestroy {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
              protected profileService: ProfileService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  select = (option: boolean) => {
    this.userResponse = option ? 1 : 0;
    this.submitResponse();
  };

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }
}
