<div class="container">
    <ets-sne-report-header
        [name]="preferredName"
        [numScore]="numScore"
        [stringScore]="stringScore"
        [module]="module"
    >
    </ets-sne-report-header>

    <ets-sne-report-body
        [name]="preferredName"
        [score]="stringScore"
        [module]="module"
        [CPS_collab_score]="CPS_collab_score"
        [CPS_PS_score]="CPS_PS_score"
        [persuasive_subscore]="persuasive_subscore"
        [info_subscore]="info_subscore"
        [dialogic_subscore]="dialogic_subscore"
    >
    </ets-sne-report-body>
</div>
