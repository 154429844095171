import { SneQuestion, SneModal, SNE_INPUT_TYPES } from '../../../types/sne';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CoachabilityUncertaintyInstructionModal = {
  title: 'Mixed Messages',
  pageList: [
    {
      info: 'You have just joined the Customer Satisfaction team of a large firm that manufactures and distributes high-end dog food. At the beginning of your second week on the job, you receive a text message from your supervisor that is not entirely clear to you.',
    }
  ],
};

export const CoachabilityUncertaintyData: Map<string, SneQuestion> = new Map([

  ['start', {
    questionType: QUESTION_TYPES.INTERSTITIAL,
    title: 'Uncertainty',
    info: 'This task will help us better understand how you ask questions when things are unclear, and we’ll use the insights we gather to enhance your Skills Profile.',
    duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you’ll have the option to pause when you finish.',
    questionIndex: 6,
    moduleSize: 6,
    durationBoxColor: 'lbb',
    image: '/assets/section-3/sne-interstitial/uncertainty.svg',
    buttonText: 'Next',
    modalBeforeNext: true
  }],

  ['question-1', {
    questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
    header: 'How do you want to reply?',
    inputType: SNE_INPUT_TYPES.RADIO,
    botProfilePic: '/assets/sne/coachability-uncertainty-profile-pic.png',
    youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
    singleMulChoiceOptions: [
      {
        userOption: 'No problem. I\'m on it!', // userOption
        userLetter: 'A',
        autoUser: 'Supervisor',
        response: 'Awesome! By the way, that woman\'s name is Sophia Ruiz. She works in marketing.',
      },
      {
        userOption: 'I\'d be glad to. What\'s the name of the woman who made the points?', // userOption
        userLetter: 'B',
        autoUser: 'Supervisor',
        response: 'Great! Sophia Ruiz. She works in marketing.',
      },
      {
        userOption: 'I think it might be better if you assigned this to someone who was at the meeting and heard her in person.', // userOption
        userLetter: 'C',
        autoUser: 'Supervisor',
        response: 'Why don\'t you take a crack at it and see how far you get? By the way, that woman\'s name is Sophia Ruiz. She works in marketing.'
      },
      {
        userOption: 'Ok, sounds good. Do you have All Staff meetings every month or just once in a while?', // userOption
        userLetter: 'D',
        autoUser: 'Supervisor',
        response: 'We try for once a month, and the employees really seem to like them. By the way, that woman\'s name is Sophia Ruiz. She works in marketing.',
      }
    ],
    submitId: 'item_1'
  }],
  ['question-2', {
    questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
    header: 'How do you want to reply?',
    inputType: SNE_INPUT_TYPES.RADIO,
    botProfilePic: '/assets/sne/coachability-uncertainty-profile-pic.png',
    youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
    singleMulChoiceOptions: [
      {
        userOption: 'Terrific! I bumped into Sophia in the break room the other day. She seems great.', // userOption
        userLetter: 'A',
        autoUser: 'Supervisor',
        response: 'She\'s one of our stars. By the way, I\'d like you to give me an estimate in a week about how long it would take us to implement her ideas.',
      },
      {
        userOption: 'Got it. I hope Sophia is still interested in helping us with her ideas.', // userOption
        userLetter: 'B',
        autoUser: 'Supervisor',
        response: 'I\'m sure she is. By the way, I\'d like you to give me an estimate in a week about how long it would take us to implement those ideas.',
      },
      {
        userOption: 'Thanks! Do you have a timeframe in mind to get everything going?', // userOption
        userLetter: 'C',
        autoUser: 'Supervisor',
        response: 'I think it would be a good idea for you to give me an estimate in a week about how long it would take us to implement her ideas.',
      },
      {
        userOption: 'Excellent, thanks. Do you know if other folks support what she had to say? ', // userOption
        userLetter: 'D',
        autoUser: 'Supervisor',
        response: 'I think many of them do. She\'s popular, and her ideas are very sensible. By the way, I\'d like you to give me an estimate in a week about how long it would take us to implement her ideas.',
      }
    ],
    submitId: 'item_2'
  }],
  ['question-3', {
    questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO,
    header: 'How do you want to reply?',
    inputType: SNE_INPUT_TYPES.RADIO,
    botProfilePic: '/assets/sne/coachability-uncertainty-profile-pic.png',
    youProfilePic: '/assets/sne/negotiation-you-profile-pic.png',
    singleMulChoiceOptions: [
      {
        userOption: 'No problem. Do you know how long Sophia has worked here?', // userOption
        userLetter: 'A',
        autoUser: 'Supervisor',
        response: 'No, I\'m afraid I don\'t. Good luck!',
      },
      {
        userOption: 'I hope a week is enough time to give you that estimate. If it\'s going to take longer, is it OK for me to ask you about it? ', // userOption
        userLetter: 'B',
        autoUser: 'Supervisor',
        response: 'Of course. Good luck with everything!',
      },
      {
        userOption: 'This will be my first real project here. Did you ask anyone else before you asked me?', // userOption
        userLetter: 'C',
        autoUser: 'Supervisor',
        response: 'We\'re excited you\'re working on it. Good luck with it!',
      },
      {
        userOption: 'That should be fine. I think I have enough to proceed, but I\'ll let you know if I need anything else. Thanks!', // userOption
        userLetter: 'D',
        autoUser: 'Supervisor',
        response: 'Perfect. Best of luck with everything!',
      }
    ],
    complete: false,
    submitId: 'item_3'
  }],
  ['question-4', {
    questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_CONVO,
    title: 'Instructions',
    subtitle: 'After texting with your supervisor, you need to decide how to proceed with your project.',
    subtitle2: 'From the choices to the left, indicate the first thing and the second thing that you would like to do to start the project.',
    inputType: SNE_INPUT_TYPES.RADIO,
    numSelectOptions: 2,
    hintModalFlag: true,
    multipleMulChoiceOptions: [
      {
        question: 'Select the <span class="convertToBlue">first thing</span> you would do to start the project',
        mulChoiceOptions: [
          {
            userOption: 'Ask your supervisor to explain in detail why she supports Sophia’s ideas', // userOption
            userLetter: 'A',
          },
          {
            userOption: 'Make sure that next month’s All Staff meeting is prioritized on your calendar', // userOption
            userLetter: 'B',
          },
          {
            userOption: 'Watch the video recording of last month’s All Staff meeting', // userOption
            userLetter: 'C',
          },
          {
            userOption: 'Clear your schedule so that you can concentrate on Sophia Ruiz’s idea for a solid week', // userOption
            userLetter: 'D',
          },
          {
            userOption: 'Send an email to company employees soliciting their opinions about Sophia Ruiz’s idea', // userOption
            userLetter: 'E',
          },
          {
            userOption: 'Interview Sophia Ruiz about her ideas for your company', // userOption
            userLetter: 'F',
          },
          {
            userOption: 'Email Sophia Ruiz to ask whether she believed her ideas would be implemented when she spoke at the All Staff meeting', // userOption
            userLetter: 'G',
          },
          {
            userOption: 'Conduct research to help you understand the current financial status and overall goals of your company', // userOption
            userLetter: 'H',
          }
        ],
      },
      {
        question: 'Select the <span class="convertToBlue">second thing</span> you would do to start the project',
        mulChoiceOptions: [
          {
            userOption: 'Ask your supervisor to explain in detail why she supports Sophia’s ideas', // userOption
            userLetter: 'A',
          },
          {
            userOption: 'Make sure that next month’s All Staff meeting is prioritized on your calendar', // userOption
            userLetter: 'B',
          },
          {
            userOption: 'Watch the video recording of last month’s All Staff meeting', // userOption
            userLetter: 'C',
          },
          {
            userOption: 'Clear your schedule so that you can concentrate on Sophia Ruiz’s idea for a solid week', // userOption
            userLetter: 'D',
          },
          {
            userOption: 'Send an email to company employees soliciting their opinions about Sophia Ruiz’s idea', // userOption
            userLetter: 'E',
          },
          {
            userOption: 'Interview Sophia Ruiz about her ideas for your company', // userOption
            userLetter: 'F',
          },
          {
            userOption: 'Email Sophia Ruiz to ask whether she believed her ideas would be implemented when she spoke at the All Staff meeting', // userOption
            userLetter: 'G',
          },
          {
            userOption: 'Conduct research to help you understand the current financial status and overall goals of your company', // userOption
            userLetter: 'H',
          }
        ]        
      }
    ],
    hintModal: {
      title: 'Your Next Move',
      pageList: [
        {
          info: 'After texting with your supervisor, you need to decide how to proceed with your project.',
        },
      ],
    },
    complete: false,
    submitId: 'item_4'
  }],
  ['question-5', {
    questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_TEXT,
    header: 'Select the <span class="convertToBlue">two</span> most useful resources you would use to start the project.',
    title: 'Instructions',
    subtitle: 'You have learned that, among other suggestions, Sophia has argued that your company would get a lot of favorable publicity by donating dog food as prizes in competitive canine events. You agree, but you are unsure about how to connect with organizations and individuals who participate in these events. Your company’s librarian suggests a number of external resources that he feels might be helpful.',
    inputType: SNE_INPUT_TYPES.CHECKBOX,
    numSelectOptions: 2,
    hintModalFlag: true,
    multipleMulChoiceOptions: [
      {
        question: 'Select the <span class="convertToBlue">two</span> most useful resources you would use to start the project.',
        mulChoiceOptions: [
          {
            userOption: 'A survey of your customers indicating how many of them are interested in learning about fun trips to take with their dogs',
            userLetter: 'A'
          },
          {
            userOption: 'A magazine featuring in-depth articles on current dog sports in the United States and profiles of popular human/canine teams',
            userLetter: 'B'
          },
          {
            userOption: 'An article discussing the early history of dog sports in the United States and Europe',
            userLetter: 'C'
          },
          {
            userOption: 'A list of the top twenty most popular dog breeds in the United States',
            userLetter: 'D'
          },
          {
            userOption: 'A current catalog of dog clubs that includes contact information for the clubs’ officers and directors',
            userLetter: 'E'
          },
          {
            userOption: 'A book explaining to owners of new puppies how to train their dogs to jump and swim safely',
            userLetter: 'F'
          },
          {
            userOption: 'An overview of the greater nutritional requirements of dogs who compete in different sports compared to those of dogs serving primarily as pets',
            userLetter: 'G'
          },
          {
            userOption: 'A podcast exploring the complex relationship between human beings and their dogs as an important aspect of the culture of the United States',
            userLetter: 'H'
          }
        ]
      }
    ],
    hintModal: {
      title: 'Evaluating Resources',
      pageList: [
        {
          info: 'You have learned that, among other suggestions, Sophia has argued that your company would get a lot of favorable publicity by donating dog food as prizes in competitive canine events. You agree, but you are unsure about how to connect with organizations and individuals who participate in these events. Your company’s librarian suggests a number of external resources that he feels might be helpful.',
        },
      ],
    },
    complete: true,
    submitId: 'item_5'
  }],
  ['end', {
    questionType: QUESTION_TYPES.INTERSTITIAL,
    title: 'Congrats! You completed the last task.',
    info: 'Check out your score report to see your results and understand how they might inform your career search.',
    questionIndex: 6,
    moduleSize: 6,
    image: '/assets/section-3/sne-interstitial/coach-end-interstitial.png',
    buttonText: 'REVIEW REPORT',
    goto: '/sne-new/report/coachability'
  }]
]);
