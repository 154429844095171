<div class="container" [ngClass]="{'container-full-page': inputType === SNE_INPUT_TYPES.CHECKBOX}">
  <div class="question-container">
    <div class="title" *ngIf="title" [innerHTML]="title"></div>
    <div class="light-border" *ngIf="title"></div>
    <div class="question" *ngIf="headerQuestion" [innerHTML]="headerQuestion"></div>
    <div class="prompt-container">
      <span class="prompt" *ngFor="let element of blankQuestionArray">
        <span class="prompt-text" *ngIf="isPromptText(element)">{{element}}</span>
        <span class="blank-placeholder" *ngIf="element === '[blank]'">BLANK</span>
        <span class="blank-option" *ngIf="isBlankOption(element)">{{element.toLowerCase()}}</span>
      </span>
      <div class="sub-title" *ngIf="subtitle"[innerHTML]="subtitle"></div>
    </div>
    <div class="light-border"*ngIf="headerQuestion"></div>
  </div>
  <div class="blank-list" *ngIf="!list">
    <div class="blank-container" *ngFor="let blank of madlibsBlanks; let blankIndex = index;">
      <div class="blank-header" *ngIf="inputType !== SNE_INPUT_TYPES.CHECKBOX"> Blank {{blankIndex+1}} </div>
      <div class="option" *ngFor="let option of blank.choices; let optionIndex = index">
        <ets-radio-button *ngIf="inputType === SNE_INPUT_TYPES.RADIO" name="{{blankIndex}}"
                          [value]="option.letter"
                          [id]="mobileCheck ? blankIndex + '' + optionIndex + '-mobile' : blankIndex + '' + optionIndex"
                          [label]="option.option"
                          fontWeight="normal"
                          (clickEvent)="selectBlank(blankIndex, option.option, option.letter)"
        >
      </ets-radio-button>
      <ets-checkbox *ngIf="inputType === SNE_INPUT_TYPES.CHECKBOX"
                  [value]="blankIndex + '' + optionIndex"
                  [name]="option.letter"
                  [id]="mobileCheck ? blankIndex + '' + optionIndex + '-mobile' : blankIndex + '' + optionIndex"
                  [label]="option.option"
                  (clickEvent)="selectBlank(blankIndex, option.option, option.letter)"
                >
                </ets-checkbox>
      </div>
      <div class="light-border" *ngIf="inputType === SNE_INPUT_TYPES.RADIO"></div>
    </div>
  </div>
  <div class="single-blank" *ngIf="list">
    <div class="blank-container">
      <div class="option" *ngFor="let choice of madLibsSingleBlanks?.choices  let choiceIndex = index">
      <ets-checkbox *ngIf="inputType === SNE_INPUT_TYPES.CHECKBOX"
                  [value]="'' + choiceIndex"
                  [name]="choice.letter"
                  [id]="'' + choiceIndex"
                  [id]="mobileCheck ? '' + choiceIndex + '-mobile' : '' + choiceIndex"
                  [label]="choice.option"
                  [disable]="!disableAnswer[choiceIndex]"
                  (clickEvent)="selectSingleAnswerBlank(choiceIndex, choice.option, choice.letter)"
                >
                </ets-checkbox>
      </div>
    </div> 
  </div>
</div>
