import { createReducer, on } from '@ngrx/store';
import { setEmail, setOpenSesame } from './auth.actions';

export interface State {
  email: string | undefined;
  openSesame: string | undefined;
}

export const authFeatureKey = 'auth';

export const initialState: State = {
  email: undefined,
  openSesame: undefined
};

export const reducer = createReducer(
  initialState,
  on(setEmail, (state, { payload }) => ({ ...state, email: payload })),
  on(setOpenSesame, (state, { payload }) => ({ ...state, openSesame: payload }))
);
