
<!--<div *ngIf="!userSignedInFlag">-->
<!--  <ets-header [headerState]="HEADER_STATES.SIGN_UP" [showSignIn]='showSignInOnHeader' (signUpTrigger)='toggleSignUp()' (signInTrigger)='toggleSignUp()'></ets-header>-->
<!--  <div class="outer-div" >-->

<!--    &lt;!&ndash; Sign In Container &ndash;&gt;-->
<!--    <div class="left-screen" *ngIf='!showSignUp && !showConfirmAccount'>-->
<!--      <div class="left-inner-div" *ngIf='!showPasswordReset'>-->
<!--        <div class="header-info-wrapper">-->
<!--          <h1 class="sign-in-header">-->
<!--              Discover and be Discovered-->
<!--          </h1>-->
<!--          <p class="need-account">-->
<!--              Need an account? <button class="sign-up" (click)="toggleSignUp()">Sign Up</button><br>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="login-form-wrapper">-->
<!--          <form [formGroup]="signInForm" (ngSubmit)="signIn()">-->
<!--            &lt;!&ndash; Username &ndash;&gt;-->
<!--            <label for="fusername" class="input-label">Username:</label><br>-->
<!--            <input type="text" id="fusername" name="username" class="sign-in-input" formControlName="username">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="signInForm.controls['username'].invalid && -->
<!--              (signInForm.controls['username'].touched || signInForm.controls['username'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="signInForm.controls['username'].invalid && (signInForm.controls['username'].touched || signInForm.controls['username'].dirty)">-->
<!--              <div *ngIf="signInForm.controls['username'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; Password &ndash;&gt;-->
<!--            <label for="signIn_password" class="input-label">Password:</label><br>-->
<!--            <input type="password" id="signIn_password" name="password" class="sign-in-input" formControlName="password">-->
<!--            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("signIn_password")'></fa-icon>-->
<!--            <div *ngIf="signInForm.controls['password'].invalid && (signInForm.controls['password'].touched || signInForm.controls['password'].dirty)">-->
<!--              <div *ngIf="signInForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash; Error Handling &ndash;&gt;-->
<!--            <p class="error-msg" *ngIf='signIn_incorrectUsernameOrPasswordFlag'>Invalid username/password entered. Please try again.</p>-->
<!--            <p class="error-msg unconfirmed-account" *ngIf='signIn_unconfirmedAccountFlag'>-->
<!--              The email associated with this account has not yet been confirmed.<br>-->
<!--              <button class="sign-up" (click)="toggleConfirmAccount()">Confirm account</button>-->
<!--            </p>-->
<!--            <p class="error-msg" *ngIf='signIn_unknownErrorFlag'>An unknown error occurred. Please try again.</p>-->

<!--            <div class="submit-container">-->
<!--              <input type="submit" id="submit" name="submit" value="LOG IN" class="login-button" [disabled]="!signInForm.valid || signIn_disableSubmitFlag">-->
<!--              <clr-spinner [clrSmall]='true' *ngIf="signingInSpinnerFlag"></clr-spinner>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->

<!--        <p class="need-account">-->
<!--          <button class="sign-up" (click)="toggleResetPassword()">Forgot your password?</button>-->
<!--        </p>-->
<!--      </div>-->

<!--      &lt;!&ndash; Password Reset Container &ndash;&gt;-->
<!--      <div class="left-inner-div" *ngIf='showPasswordReset'>-->
<!--        <div class="header-info-wrapper">-->
<!--          <h1 class="sign-in-header">-->
<!--              Forgot your password?-->
<!--          </h1>-->
<!--          <p class="need-account" *ngIf='!showConfirmPasswordReset'>-->
<!--            Enter your username to reset your password. If we find a -->
<!--            matching account, a one-time code will be sent to your email.-->
<!--          </p>-->
<!--          <p class="need-account" *ngIf='showConfirmPasswordReset'>-->
<!--            If we found a matching account, a one-time code was sent to your email.-->
<!--            Please enter it below to reset your password.-->
<!--          </p>-->
<!--        </div>-->

<!--        &lt;!&ndash; Initiate Password Reset Form &ndash;&gt;-->
<!--        <div class="login-form-wrapper" *ngIf='!showConfirmPasswordReset'>-->
<!--          <form [formGroup]="initiatePasswordResetForm" (ngSubmit)="forgotPassword()">-->
<!--            &lt;!&ndash; Username &ndash;&gt;-->
<!--            <label for="fusername" class="input-label">Username:</label><br>-->
<!--            <input type="text" id="fusername" name="username" class="sign-in-input" formControlName="username">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="initiatePasswordResetForm.controls['username'].invalid && -->
<!--              (initiatePasswordResetForm.controls['username'].touched || initiatePasswordResetForm.controls['username'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="initiatePasswordResetForm.controls['username'].invalid && (initiatePasswordResetForm.controls['username'].touched || initiatePasswordResetForm.controls['username'].dirty)">-->
<!--              <div *ngIf="initiatePasswordResetForm.controls['username'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash; Error Handling &ndash;&gt;-->
<!--            <p class="error-msg" *ngIf='initiateReset_unknownErrorFlag'>An unknown error occurred. Please try again.</p>-->

<!--            <div class="submit-container">-->
<!--              <input type="submit" id="submit" name="submit" value="Reset Password" class="login-button" [disabled]="!initiatePasswordResetForm.valid">-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->

<!--        &lt;!&ndash; Confirm Password Reset Form &ndash;&gt;-->
<!--        <div class="login-form-wrapper" *ngIf='showConfirmPasswordReset'>-->
<!--          <form [formGroup]="resetPasswordForm" (ngSubmit)="forgotPasswordSubmit()">-->
<!--            &lt;!&ndash; Code &ndash;&gt;-->
<!--            <label for="fcode" class="input-label">Code:</label><br>-->
<!--            <input type="text" id="fcode" name="code" class="sign-in-input" formControlName="code">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="resetPasswordForm.controls['code'].invalid && -->
<!--              (resetPasswordForm.controls['code'].touched || resetPasswordForm.controls['code'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="resetPasswordForm.controls['code'].invalid && (resetPasswordForm.controls['code'].touched || resetPasswordForm.controls['code'].dirty)">-->
<!--              <div *ngIf="resetPasswordForm.controls['code'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; New Password &ndash;&gt;-->
<!--            <label for="reset_password" class="input-label">New Password:</label><br>-->
<!--            <input type="password" id="reset_password" name="password" class="sign-in-input" formControlName="password">-->
<!--            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("reset_password")'></fa-icon>-->
<!--            <div *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].touched || resetPasswordForm.controls['password'].dirty)">-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].errors?.minlength && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must be at least 8 characters long-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].errors?.maxlength && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must be no more than 99 characters long-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].hasError('hasNumber') && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one number-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].hasError('hasLower') && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one lowercase letter-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].hasError('hasUpper') && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one uppercase letter-->
<!--              </div>-->
<!--              <div *ngIf="resetPasswordForm.controls['password'].hasError('hasSpecial') && !resetPasswordForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one special character-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash; Confirm Password &ndash;&gt;-->
<!--            <label for="reset_confirmPassword" class="input-label">Confirm Password:</label><br>-->
<!--            <input type="password" id="reset_confirmPassword" name="confirmPassword" class="sign-in-input" formControlName="confirmPassword">-->
<!--            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("reset_confirmPassword")'></fa-icon>-->
<!--            <div *ngIf="resetPasswordForm.controls['confirmPassword'].invalid && (resetPasswordForm.controls['confirmPassword'].touched || resetPasswordForm.controls['confirmPassword'].dirty)">-->
<!--              <div *ngIf="resetPasswordForm.controls['confirmPassword'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            <div *ngIf="resetPasswordForm.hasError('noPasswordMatch') && !resetPasswordForm.controls['confirmPassword'].errors?.required" class='validation-error'>-->
<!--              Password does not match the above password-->
<!--            </div>-->

<!--            &lt;!&ndash; Error Handling &ndash;&gt;-->
<!--            <p class="error-msg" *ngIf='resetPassword_unknownErrorFlag'>An unknown error occurred. Please try again.</p>-->

<!--            <div class="submit-container">-->
<!--              <input type="submit" id="submit" name="submit" value="Change Password" class="login-button" [disabled]="!resetPasswordForm.valid">-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Sign Up Container &ndash;&gt;-->
<!--    <div class="left-screen sign-up-container" *ngIf='showSignUp'>-->
<!--      <div class="left-inner-div">-->
<!--        <div class="header-info-wrapper">-->
<!--          <h1 class="sign-in-header">-->
<!--              Create an Account-->
<!--          </h1>-->
<!--          <p class="need-account">-->
<!--            Already have an account? <button class="sign-up" (click)="toggleSignUp()">Sign in</button><br>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="login-form-wrapper">-->
<!--          <form [formGroup]="signUpForm" (ngSubmit)="signUp()">-->
<!--            &lt;!&ndash; Username &ndash;&gt;-->
<!--            <label for="fusername" class="input-label">Username:</label><br>-->
<!--            <input type="text" id="fusername" name="username" class="sign-in-input" formControlName="username">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="signUpForm.controls['username'].invalid && -->
<!--              (signUpForm.controls['username'].touched || signUpForm.controls['username'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="signUpForm.controls['username'].invalid && (signUpForm.controls['username'].touched || signUpForm.controls['username'].dirty)">-->
<!--              <div *ngIf="signUpForm.controls['username'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['username'].errors?.maxlength && !signUpForm.controls['username'].errors?.required" class='validation-error'>-->
<!--                Username may not be no more than 128 characters long-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; First name &ndash;&gt;-->
<!--            <label for="fgivenName" class="input-label">First name:</label><br>-->
<!--            <input type="text" id="fgivenName" name="given_name" class="sign-in-input" formControlName="given_name">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="signUpForm.controls['given_name'].invalid && -->
<!--              (signUpForm.controls['given_name'].touched || signUpForm.controls['given_name'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="signUpForm.controls['given_name'].invalid && (signUpForm.controls['given_name'].touched || signUpForm.controls['given_name'].dirty)">-->
<!--              <div *ngIf="signUpForm.controls['given_name'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; Last name &ndash;&gt;-->
<!--            <label for="ffamilyName" class="input-label">Last name:</label><br>-->
<!--            <input type="text" id="ffamilyName" name="family_name" class="sign-in-input" formControlName="family_name">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="signUpForm.controls['family_name'].invalid && -->
<!--              (signUpForm.controls['family_name'].touched || signUpForm.controls['family_name'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="signUpForm.controls['family_name'].invalid && (signUpForm.controls['family_name'].touched || signUpForm.controls['family_name'].dirty)">-->
<!--              <div *ngIf="signUpForm.controls['family_name'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; Email &ndash;&gt;-->
<!--            <label for="femail" class="input-label">Email:</label><br>-->
<!--            <input type="text" id="femail" name="email" class="sign-in-input" formControlName="email">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="signUpForm.controls['email'].invalid && -->
<!--              (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)">-->
<!--              <div *ngIf="signUpForm.controls['email'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['email'].errors?.email" class='validation-error'>-->
<!--                Please enter a valid email address-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; Password &ndash;&gt;-->
<!--            <label for="signUp_password" class="input-label">Password:</label><br>-->
<!--            <input type="password" id="signUp_password" name="password" class="sign-in-input" formControlName="password">-->
<!--            <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("signUp_password")'></fa-icon>-->
<!--            <div *ngIf="signUpForm.controls['password'].invalid && (signUpForm.controls['password'].touched || signUpForm.controls['password'].dirty)">-->
<!--              <div *ngIf="signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].errors?.minlength && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must be at least 8 characters long-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].errors?.maxlength && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must be no more than 99 characters long-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].hasError('hasNumber') && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one number-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].hasError('hasLower') && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one lowercase letter-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].hasError('hasUpper') && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one uppercase letter-->
<!--              </div>-->
<!--              <div *ngIf="signUpForm.controls['password'].hasError('hasSpecial') && !signUpForm.controls['password'].errors?.required" class='validation-error'>-->
<!--                Password must contain at least one special character-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash; Error Handling &ndash;&gt;-->
<!--            <p class="error-msg" *ngIf='signUp_userAlreadyExists'>A user already exists with the provided username.<br>Please try again.</p>-->
<!--            <p class="error-msg" *ngIf='signUp_unknownErrorFlag'>An unknown error occurred. Please try again.</p>-->

<!--            <div class="submit-container">-->
<!--              <input type="submit" id="submit" name="submit" value="Sign Up" class="login-button" [disabled]="!signUpForm.valid">-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Confirmation Code Container &ndash;&gt;-->
<!--    <div class="left-screen" *ngIf='!showSignUp && showConfirmAccount'>-->
<!--      <div class="left-inner-div">-->
<!--        <div class="header-info-wrapper">-->
<!--          <h1 class="sign-in-header">-->
<!--            Confirm the Account-->
<!--          </h1>-->
<!--          <p class="need-account">-->
<!--            We have sent a code to the email associated with this account. -->
<!--            Please enter it below to confirm your account.<br>-->
<!--          </p>-->
<!--        </div>-->

<!--        <div class="login-form-wrapper">-->
<!--          <form [formGroup]="confirmationCodeForm" (ngSubmit)="confirmSignUp()">-->
<!--            &lt;!&ndash; Code &ndash;&gt;-->
<!--            <label for="fcode" class="input-label">Verification Code:</label><br>-->
<!--            <input type="text" id="fcode" name="code" class="sign-in-input" formControlName="code">-->
<!--            <img src="../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' -->
<!--              *ngIf="confirmationCodeForm.controls['code'].invalid && -->
<!--              (confirmationCodeForm.controls['code'].touched || confirmationCodeForm.controls['code'].dirty)"-->
<!--            >-->
<!--            <div *ngIf="confirmationCodeForm.controls['code'].invalid && (confirmationCodeForm.controls['code'].touched || confirmationCodeForm.controls['code'].dirty)">-->
<!--              <div *ngIf="confirmationCodeForm.controls['code'].errors?.required" class='validation-error'>-->
<!--                This field is required-->
<!--              </div>-->
<!--            </div>-->
<!--            -->
<!--            &lt;!&ndash; Error Handling &ndash;&gt;-->
<!--            <p class="error-msg" *ngIf='confirm_invalidCode'>Invalid verification code provided. Please try again.</p>-->
<!--            <p class="error-msg" *ngIf='confirm_userAlreadyConfirmed'>-->
<!--              User has already been confirmed. -->
<!--              <button class="sign-up" (click)="toggleSignUp()">Sign In</button>-->
<!--            </p>-->
<!--            <p class="error-msg" *ngIf='confirm_unknownErrorFlag'>An unknown error occurred. Please try again.</p>-->

<!--            <div class="submit-container">-->
<!--              <input type="submit" id="submit" name="submit" value="Confirm Account" class="login-button" [disabled]="!confirmationCodeForm.valid">-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->

<!--        <p class="need-account">-->
<!--          Didn't receive a code? <button class="sign-up" (click)="resendConfirmationCode()">Resend it</button><br>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="right-screen">-->
<!--      <img src="../../assets/section-1/intro-image-desktop.png" alt="Sign In Photo" id="sign-in-photo">-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div *ngIf="userSignedInFlag" class="App">-->
<!--    <div class="container">-->
<!--      <router-outlet></router-outlet>-->
<!--    </div>-->
<!--</div>-->

<div class="App">
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

<clr-modal [(clrModalOpen)]="showErrorModal" [clrModalClosable]="false">
  <h3 class="modal-title">Uh oh! This is embarrassing.</h3>
  <div class="modal-body">
    <p>
      Not sure what happened—our mind is working a bit slow today. Sorry about that! We tried to save your progress if you want to try again.
    </p>
  </div>
  <div class="modal-footer">
    <ets-solid-button [text]="pageURL.includes('on-boarding') ? 'Try again' : 'Take me home'" [color]="'#0E3AD6'"
      (clickEvent)="toggleErrorModal()">
    </ets-solid-button>
  </div>
</clr-modal>
