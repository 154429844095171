import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ets-sne-split-screen',
  templateUrl: './sne-split-screen.component.html',
  styleUrls: ['./sne-split-screen.component.scss']
})
export class SneSplitScreenComponent {

  @Input() leftBackgroundColor: string | undefined;
  @Input() rightBackgroundColor: string | undefined;
  @Input() hideMobile: string = '';
  @Input() sneSection: string = '';

  constructor() { }

}
