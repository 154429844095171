import COACHABILITY_TYPES from './coachability-types';
import WC_TYPES from './written-communication-types';
import QUESTION_TYPES from './question-types';
import CT_TYPES from './critical-thinking-types';
import OC_TYPES from './oral-communication-types';
import CPS_TYPES from './cps-types';

export interface Interstitial {
  question?: string;
  info?: string;
  image?: string;
  buttonText?: string;
  next?: string;
  instructionList?: Interstitial[];
  progress?: string;
  blueInfo?: string;
  emails?: Email[];
  questionIndex?: number;
  moduleSize?: number;
  duration?: string;
  durationBoxColor?: string;
  endIndex?: number;
}

export interface NegotiationQuestion {
  question: string;
  options: NegotiationOption[];
  jordan?: string;
  clientSatisfaction: number;
  jordanSatisfaction: number;
}

export interface NegotiationOption {
  text: string;
}

export interface NegotiationAnswer {
  answer?: number;
  time?: number;
}

export interface HiddenProfileQuestion {
  question: string;
  options: string[];
  jordanResponsetoPrevious?: string;
  clientSatisfaction: number;
  jordanSatisfaction: number;
}

export interface HiddenProfileVendorData {
  facts: VendorFact[];
}

export interface HiddenProfileUserResponses {
  id?: string;
  message?: string;
  response?: string;
}

export interface VendorFact {
  fact: string;
  id: string;
  impact?: VENDOR_FACT_IMPACT;
  userKnown?: boolean;
  botKnown?: boolean;
  botShared?: boolean;
  vendor: VENDOR_SET;
}

export enum VENDOR_FACT_IMPACT {
  POSITIVE = 1,
  NEGATIVE = -1,
  IRRELEVANT = 0
}

export enum VENDOR_SET {
  A = 'A',
  B = 'B'
}

export enum HP_BOT_MESSAGES_KEYS {
  SHARE_FACT_SELECT = 'share-fact-select',
  VENDOR_FACT_SELECT = 'vendor-fact-select',
  MENU = 'menu',
  CLOSER_TO_SELECTION = 'closer-to-selection',
  SHARE_FACT_PROMPT = 'share-fact-prompt',
  FACT_SELECTED_PROMPT = 'fact-selected-prompt',
  FACT_SUBMIT_PROMPT = 'fact-submit-prompt',
  FACT_ALREADY_KNOWN_A = 'fact-already-known-A',
  FACT_ALREADY_KNOWN_B = 'fact-already-known-B',
  FACT_NOT_KNOWN_A = 'fact-not-known-A',
  FACT_NOT_KNOWN_B = 'fact-not-known-B',
  VENDOR_SELECT = 'vendor-select',
  VENDOR_SELECT_A = 'vendor-select-A',
  VENDOR_SELECT_B = 'vendor-select-B',
}


export interface SneQuestion {
  singleMulChoiceOptions?: SneMulChoiceOption[];
  multipleMulChoiceOptions?: SneMulChoiceQuestion[];
  numSelectOptions?: number;
  questionType: QUESTION_TYPES;
  question?: string;
  title?: string;
  subtitle?: string;
  subtitle2?: string;
  info?: string;
  image?: string;
  buttonText?: string;
  progress?: string;
  header?: string;
  inputType?: SNE_INPUT_TYPES;
  hintModal?: SneModal;
  goto?: COACHABILITY_TYPES | WC_TYPES | OC_TYPES | CT_TYPES |string | undefined;
  modalBeforeNext?: boolean;
  email?: Email;
  complete?: boolean;
  submitId?: string;
  memo?: string;
  splitScreen?: boolean;
  headerVideo?: SneVideos;
  madlibsBlanks?: OcMadlibsBlanks[];
  videos?: SneVideos[];
  emailThread?: Email[];
  socialMediaPost?: SocialMediaPost[];
  chatMessages?: ChatMessage[];
  contentHeader?: string;
  questionIndex?: number;
  moduleSize?: number;
  duration?: string;
  durationBoxColor?: string;
  marquis?: boolean;
  submitModal?: SneModal;
  sharedVideo?: SneVideos;
  madlibsSingleQuestion?: OcMadLibsSingleQuestionBlanks;
  taskMap?: scheduleMap;
  botProfilePic?: string;
  youProfilePic?: string;
  flowMapImgs?: Screens;
  operationsLog?: Screens;
  hintModalFlag?: boolean;
  nextPageHasHint?: boolean;
}


export interface scheduleMap {
  statement: String;
  scheduleMap: String;
}
export interface SneMulChoiceQuestion {
  question: string;
  mulChoiceOptions: SneMulChoiceOption[];
  isDisagree?: boolean;
  marquisClassification?: SneMulChoiceOption;
}

export interface SneMulChoiceOption {
  emailResponse?: Email;
  userOption?: string;
  userLetter?: string;
  response?: any;
  autoUser?: string;
}

/*export interface OralCommunicationQuestion {
  title?: string;
  header?: string;
  questionType?: ORAL_COMMUNICATION_QUESTION_TYPES;
  info?: string;
  image?: string;
  buttonText?: string;
  videos?: SneVideos [];
  headerVideo?: SneVideos;
  mulChoiceOption?: CoachabilityMulChoiceOption;
  madlibsBlanks?: string;
}
*/

export interface ChatMessage {
  user?: string;
  message?: string;
}

export interface Statement {
  person: string;
  message: string;
  image: string;
}

export enum SNE_TASK_ENUM {
  FACT_SORTING = 'FACT_SORTING',
  HIDDEN_PROFILE = 'HIDDEN_PROFILE',
  NEGOTIATION = 'NEGOTIATION',
  SURVEY = 'SURVEY',
  CHANGING_CIRCUMSTANCES = 'CHANGING_CIRCUMSTANCES',
  COACHES = 'COACHES',
  CRITICISM = 'CRITICISM',
  PERSONAL_MISTAKES = 'PERSONAL_MISTAKES',
  UNCERTAINTY = 'UNCERTAINTY',
  INFORMATIVE_1 = 'INFORMATIVE_1',
  INFORMATIVE_2 = 'INFORMATIVE_2',
  DIALOGIC_1 = 'DIALOGIC_1',
  DIALOGIC_2 = 'DIALOGIC_2',
  PERSUASIVE_1 = 'PERSUASIVE_1',
  PERSUASIVE_2 = 'PERSUASIVE_2',
  FREEDOM_OF_SPEECH = 'FREEDOM_OF_SPEECH',
  DEDUCTION = 'DEDUCTION',
  PRODUCTION = 'PRODUCTION_GRAPH',
  RHETORICAL = 'RHETORICAL_ANALYSIS',
  SCHEDULING = 'SCHEDULING',
  ARGUMENTATION_1 = 'ARGUMENTATION_PUBLIC_SPEAKING',
  ARGUMENTATION_2 = 'ARGUMENTATION_OFFICE',
  SET_1 = 'SET_1',
  SET_2 = 'SET_2',
  SET_3 = 'SET_3',
  SNE = 'SNE',
  CPS = 'CPS',
  CCH = 'CCH',
  CRT = 'CRT',
  WRC = 'WRC',
  ORC = 'ORC',
  FACETS = 'FACETS',
  DELETE_SNE_RECORDS = 'DELETE' // use this task to delete all SNE records for the given user (only used on the Dev Dashboard)
}

export interface SneAnswerRequest {
  task: SNE_TASK_ENUM;
  data: any;
  completed?: boolean; // optional - BE defaults to false if it's not passed in the request
}
export interface FactSortingMCQuestions {
  statement: string;
  answer: string | null;
  newAnswer: string | null;
  marquisClassification?: string;
  hasBeenAnswered: boolean;
  marquisAnswer?: string;
}

export interface FactSortingAnswer {
  start: any;
  response: string;
  end?: any;
}

export enum StarStatus {
  FILLED = 'FILLED',
  NEW_FILLED = 'NEW_FILLED',
  NEW_EMPTY = 'NEW_EMPTY',
  EMPTY = 'EMPTY',
}

export interface Star {
  status: StarStatus;
}

export interface Email {
  to?: string;
  from?: string;
  emailSubject?: string;
  emailResponse?: any;
  image?: string;
  body?: string;
}

export interface SocialMediaPost{
  name?: string;
  username?: string;
  message?: string;
  image?: string;
}

export interface SneVideos {
  url: string,
  id: string, // number at the end of the Vimeo URL
  title?: string,
  mainHeader?:string,
  description?: string, //video description
  inputType?: SNE_INPUT_TYPES,
  answerChoice?: string, // answer choice to be sent to the BE
  selected?: boolean;
  playOnce?: boolean;
}

export enum SNE_INPUT_TYPES {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  BUTTON = 'button',
}

export interface SneModal {
  pageList: ModalInfo[];
  title?: string;
  customContent?: any;
}

export interface ModalInfo {
  title?: string;
  info: string;
  showCustomContent?: boolean;
  image?: string;
}

export interface OcMulChoiceOption {
  userOption?: string;
  userLetter?: string;
}

export interface OcMadlibsBlanks {
  prompt: string;
  choices: OcMadlibsChoices[];
}

export interface OcMadLibsSingleQuestionBlanks{
  prompts: prompt[];
  choices: OcMadlibsChoices[];
}

export interface prompt{
  prompt: string;
}

export interface OcMadlibsChoices {
  option: string;
  letter: string;
}

export enum HIDDEN_PROFILE_MENU_TYPES {
  MAIN_MENU = 'MAIN_MENU',
  REQUEST_INFO = 'REQUEST_INFO',
  IS_INFO_HELPFUL = 'IS_INFO_HELPFUL',
  SHARE_INFO = 'SHARE_INFO',
  PICK_VENDOR = 'PICK_VENDOR',
  PICK_VENDOR_CONFIRMATION = 'PICK_VENDOR_CONFIRMATION',
  BLANK = 'BLANK', // hide the menu
  BLANK_MENU = 'BLANK_MENU'
}

export interface SNE_GUARD_CONFIG {
  id: string;
  questionList: Map<string, SneQuestion>;
  type: any;
}

export interface SneAnswerRecord {
  end: string;
  response: string;
  start: string;
}

export interface SneAssessmentRecord {
  answerJSON: {[key: string]: SneAnswerRecord};
  assessmentHumanReadableID: string;
  assessmentID: number;
  completed: boolean;
  completionTimestamp: Date;
  learnerID: number;
  startTimestamp: Date;
}


export interface Screens {
  desktop?: string;
  tablet?: string;
  mobile?: string;
}