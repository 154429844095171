import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { CPSSurveyData, CPSSurveyDataInstructionModal } from 'src/app/data/sne/collaborative-problem-solving/01_survey';
import { HPData, HPInstructionModal } from 'src/app/data/sne/collaborative-problem-solving/02_hidden-profile';
import { CPSNegotiationData, CPSNegotiationDataInstructionModal } from 'src/app/data/sne/collaborative-problem-solving/03_negotiation';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import CPS_TYPES from 'src/app/types/cps-types';
import QUESTION_TYPES from 'src/app/types/question-types';
import { SneAnswerRequest, SneModal, SneQuestion, SNE_TASK_ENUM } from 'src/app/types/sne';
import { generateNextQuestionPath } from './sne-collaborative-problem-solving.helpers';

export class SneCollaborativeProblemSolvingBase {
  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService?: SneAnswerService) {}

  questionNum: string | undefined;
  questionData: SneQuestion | undefined;
  questionIndex: number | undefined;
  moduleIndex: number | undefined;
  moduleSize: number = 3;
  questionKeyList: string[] = [];
  cpsType: CPS_TYPES | undefined;
  cpsData: Map<string, SneQuestion> | undefined;
  showSubmitModal: boolean = false;
  showInstructionModal = false;
  instructionModal: SneModal | undefined;
  singleResponse: string = '';
  multiResponse: string[] = [];
  startTimestamp!: string;
  endTimestamp!: string;
  showHintModal = false;

  QUESTION_TYPES = QUESTION_TYPES;

  additionalNextQuestionSteps() {}

  generateNextSectionPath() {
    let path: string | undefined;
    if (this.questionData && this.questionData.goto) {
      switch (this.questionData.goto) {
        case CPS_TYPES.FACT_SORTING:
          path = generateNextQuestionPath(
            Array.from(CPSSurveyData.values())[0],
            Array.from(CPSSurveyData.keys())[0],
            CPS_TYPES.FACT_SORTING,
            this.additionalNextQuestionSteps
          );
          break;
        case CPS_TYPES.HIDDEN_PROFILE:
          path = generateNextQuestionPath(
            Array.from(HPData.values())[0],
            Array.from(HPData.keys())[0],
            CPS_TYPES.HIDDEN_PROFILE,
            this.additionalNextQuestionSteps
          );
          break;
        case CPS_TYPES.NEGOTIATION:
          path = generateNextQuestionPath(
            Array.from(CPSNegotiationData.values())[0],
            Array.from(CPSNegotiationData.keys())[0],
            CPS_TYPES.NEGOTIATION,
            this.additionalNextQuestionSteps
          );
          break;
        default:
          path= this.questionData.goto;
          console.error('Cannot find question type');
      }
    }
    return path;
  }

  navToNextPage() {
    //make call to backend to save response
    let path: string | undefined;
    if (this.cpsData) {
      const keysArray = Array.from(this.cpsData.keys());
      let keyIndex = keysArray.findIndex(key => key === this.questionNum);
      if (keyIndex !== -1) {
        if (keyIndex < keysArray.length - 1) {
          keyIndex++; // go to next kwy in the question map
          const nextKey = keysArray[keyIndex];
          const newQuestion = this.cpsData.get(nextKey);
          if (newQuestion && this.cpsType) {
            path = generateNextQuestionPath(newQuestion, nextKey, this.cpsType, this.additionalNextQuestionSteps);
          }
        } else {
          path = this.generateNextSectionPath();
        }

      }
    }
    if (path) {
      this.router.navigate([path]);
    }
  }

  getNextQuestion() {
    if (this.questionData?.modalBeforeNext) {
      if (this.questionData?.submitModal) {
        this.openSubmitModal();
      } else {
        this.openInstructionModal();
      }
    } else {
      this.submit();
      this.navToNextPage();
    }
  }

  submit() {
    if (this.sneAnswerService) {

      if (this.questionIndex !== undefined && this.questionData?.submitId) {
        this.endTimestamp = Date.now().toString();
        const completed = !!this.questionData?.complete;
        const data: { [key: string]: any } = {};
        if (this.singleResponse) {
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: this.singleResponse,
            end: this.endTimestamp
          };
        } else if (this.multiResponse.length > 0) {
          let aggResponse: string = '';
          this.multiResponse.forEach(response => {
            aggResponse = aggResponse + response;
          });
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: aggResponse,
            end: this.endTimestamp
          };
        }

        if (!_.isEmpty(data)) {
          let task: SNE_TASK_ENUM | undefined;
          switch (this.cpsType) {
            case CPS_TYPES.FACT_SORTING:
              task = SNE_TASK_ENUM.FACT_SORTING;
              break;
            case CPS_TYPES.HIDDEN_PROFILE:
              task = SNE_TASK_ENUM.HIDDEN_PROFILE;
              break;
            case CPS_TYPES.NEGOTIATION:
              task = SNE_TASK_ENUM.NEGOTIATION;
              break;
            default:
              console.error(`CPS Type could not be identified: ${this.cpsType}`);
          }
          if (task) {
            let requestBody: SneAnswerRequest = {
              task: task,
              data: data,
              completed: completed
            };
            this.sneAnswerService.postAction(requestBody).subscribe( response => {
              this.singleResponse = ''; // refresh singleResponse
              this.multiResponse = [];
            });
          }
        }
      }
    }
  }

  closeInstructionModal() {
    this.showInstructionModal = false;
  }

  openInstructionModal() {
    this.showInstructionModal = true;
  }

  openSubmitModal() {
    this.showSubmitModal = true;
  }

  closeSubmitModal() {
    this.showSubmitModal = false;
    this.submit();
  }

  closeModalAndNav() {
    if (this.questionData?.submitModal) {
      this.closeSubmitModal();
    }
    this.closeInstructionModal();
    this.navToNextPage();
  }

  additionalParamProcess() {}

  setData(cpsData: Map<string, SneQuestion>) {
    if (this.questionNum) {
      this.cpsData = cpsData;
      this.questionData = this.cpsData.get(this.questionNum);
      this.questionKeyList = Array.from(this.cpsData.keys());
      this.questionIndex = this.questionKeyList
        .findIndex(key => key === this.questionNum);
    }
  }

  processParams() {
    this.route.params.subscribe(params => {
      const { questionNum, section } = params;
      this.cpsType = section;
      this.questionNum = questionNum;
      this.singleResponse = '';
      this.startTimestamp = Date.now().toString();

      if (this.questionNum && this.cpsType) {
        switch (this.cpsType) {
          case CPS_TYPES.FACT_SORTING:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 2;
            this.setData(CPSSurveyData);
            this.instructionModal = CPSSurveyDataInstructionModal;
            break;
          case CPS_TYPES.HIDDEN_PROFILE:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 3;
            this.setData(HPData);
            this.instructionModal = HPInstructionModal;
            break;
          case CPS_TYPES.NEGOTIATION:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 4;
            this.setData(CPSNegotiationData);
            this.instructionModal = CPSNegotiationDataInstructionModal;
            break;
          default:
            console.error(`Collaborative Problem Solving Type could not be identified: ${this.cpsType}`);
        }
        this.additionalParamProcess();
      }
    });
  }
}
