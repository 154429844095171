<div class="container">
  <p class="inbox-text">Inbox</p>
  <p class="email-headline"
     [ngClass]="{'bold-subject': largeSubjectLine, 'normal-subject': !largeSubjectLine}"
  >
    {{subject}}
  </p>
    <div class="email-list" [ngClass]="{'disable-scroll': emails.length < 2}" etsScrollToBottom >
      <div class="email-container"
          *ngFor="let email of emails; let i = index;"
          [ngClass]="{ 'white-box': chooseColor(i, 'white'),
                        'blue-box': chooseColor(i, 'blue'),
                        'green-box': chooseColor(i, 'green')
                      }"
      >
        <div class="email-header">
          <img class="profile-pic" src="{{email?.image}}" alt="profile picture">
          <div class="sender-receiver-container">
            <p class="sender">{{email?.from}}</p>
            <p class="receiver">To: {{email?.to}}</p>
          </div>
        </div>
        <!-- <div class="email-content-container"> -->
        <p class="email-content" [innerHTML]="email?.body"></p>
      </div>
    </div>
<!--  </div>-->
</div>
