<ets-header [isBGWhite]="onBoardingQuestionType===questionTypes.INTRO"
            [headerState]="headerState"
            (backTrigger)="previous()"
            [showBackButton]="this.showBackButton()"
            [currentQuestionSection]="onBoardingQuestionSection"
            [backgroundColor]="backgroundColor"
>
</ets-header>
<div class="container"
   [ngClass]="{
    'first-section': onBoardingQuestionSection === questionSections.SECTION_ONE,
    'second-section': onBoardingQuestionSection === questionSections.SECTION_TWO,
    'third-section': onBoardingQuestionSection === questionSections.SECTION_THREE,
    'fourth-section': onBoardingQuestionSection === questionSections.SECTION_FOUR,
    'fifth-section': onBoardingQuestionSection === questionSections.SECTION_FIVE
  }" aria-label="inner content" role="alert" aria-live="polite">
  <router-outlet></router-outlet>

  <ets-footer-v2-component
    [sectionIndex]="onBoardingQuestionSection ? onBoardingQuestionSection + 1 : 2"
    [sectionSize]="3"
  >
  </ets-footer-v2-component>
</div>
