import { ActivatedRoute, Router } from '@angular/router';
import { WCFreedomOfSpeechData, WCFreedomOfSpeechInstructionModal } from 'src/app/data/sne/written-communication/01_freedom-of-speech';
import { WCSet1Data, WCSet1InstructionModal } from 'src/app/data/sne/written-communication/02_set-1';
import { WCSet2Data, WCSet2InstructionModal } from 'src/app/data/sne/written-communication/03_set-2';
import { WCSet3Data, WCSet3InstructionModal } from 'src/app/data/sne/written-communication/04_set-3';
import WC_TYPES from '../../types/written-communication-types';
import { SneQuestion, SneModal, SneAnswerRequest } from '../../types/sne';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import * as _ from 'lodash';
import { SNE_TASK_ENUM } from '../../types/sne';
import { generateNextQuestionPath } from './sne-written-communication.helpers';

export class SneWrittenCommunicationBase {
  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService?: SneAnswerService) {}

  questionNum: string | undefined;
  questionData: SneQuestion | undefined;
  questionIndex: number | undefined;
  moduleIndex: number | undefined;
  moduleSize: number = 3;
  questionKeyList: string[] = [];
  wcType: WC_TYPES | undefined;
  wcData: Map<string, SneQuestion> | undefined;
  showInstructionModal = false;
  instructionModal: SneModal | undefined;
  showHintModal = false;
  singleResponse: string = '';
  startTimestamp!: string;
  endTimestamp!: string;
  marquis?: boolean = false;

  additionalNextQuestionSteps() {}

  generateNextSectionPath() {
    let path: string | undefined;
    if (this.questionData && this.questionData.goto) {
      switch (this.questionData.goto) {
        case WC_TYPES.SET_1:
          path = generateNextQuestionPath(
            Array.from(WCSet1Data.values())[0],
            Array.from(WCSet1Data.keys())[0],
            WC_TYPES.SET_1,
            this.additionalNextQuestionSteps
          );
          break;
        case WC_TYPES.SET_2:
          path = generateNextQuestionPath(
            Array.from(WCSet2Data.values())[0],
            Array.from(WCSet2Data.keys())[0],
            WC_TYPES.SET_2,
            this.additionalNextQuestionSteps
          );
          break;
        case WC_TYPES.SET_3:
          path = generateNextQuestionPath(
            Array.from(WCSet3Data.values())[0],
            Array.from(WCSet3Data.keys())[0],
            WC_TYPES.SET_3,
            this.additionalNextQuestionSteps
          );
          break;
        // case WC_TYPES.FREEDOM_OF_SPEECH:
        //   path = generateNextQuestionPath(
        //     Array.from(WCFreedomOfSpeechData.values())[0],
        //     Array.from(WCFreedomOfSpeechData.keys())[0],
        //     WC_TYPES.FREEDOM_OF_SPEECH,
        //     this.additionalNextQuestionSteps
        //   );
        //   break;
        default:
          path= this.questionData.goto;
          console.error('Cannot find question type');
      }
    }
    return path;
  }

  navToNextPage() {
    //make call to backend to save response
    let path: string | undefined;
    if (this.wcData) {
      const keysArray = Array.from(this.wcData.keys());
      let keyIndex = keysArray.findIndex(key => key === this.questionNum);
      if (keyIndex !== -1) {
        if (keyIndex < keysArray.length - 1) {
          keyIndex++; // go to next key in the question map
          const nextKey = keysArray[keyIndex];
          const newQuestion = this.wcData.get(nextKey);
          if (newQuestion && this.wcType) {
            path = generateNextQuestionPath(newQuestion, nextKey, this.wcType, this.additionalNextQuestionSteps);
          }

        } else {
          path = this.generateNextSectionPath();
        }

      }
    }
    if (path) {
      this.router.navigate([path]);
    }
  }

  getNextQuestion() {
    if (this.questionData?.modalBeforeNext) {
      this.openInstructionModal();
    } else {
      this.submit();
      this.navToNextPage();
    }
  }

  submit() {
    if (this.sneAnswerService) {
      if (this.questionIndex !== undefined && this.questionData?.submitId) {
        this.endTimestamp = Date.now().toString();
        const completed = !!this.questionData?.complete;
        const data: { [key: string]: any } = {};
        if (this.singleResponse) {
          data[this.questionData.submitId] = {
            start: this.startTimestamp,
            response: this.singleResponse,
            end: this.endTimestamp
          };
        }

        if (!_.isEmpty(data)) {
          let task: SNE_TASK_ENUM | undefined;
          switch (this.wcType) {
            // case WC_TYPES.FREEDOM_OF_SPEECH:
            //   task = SNE_TASK_ENUM.FREEDOM_OF_SPEECH;
            //   break;
            case WC_TYPES.SET_1:
              task = SNE_TASK_ENUM.SET_1;
              break;
            case WC_TYPES.SET_2:
              task = SNE_TASK_ENUM.SET_2;
              break;
            case WC_TYPES.SET_3:
              task = SNE_TASK_ENUM.SET_3;
              if (completed) {
                let requestBody: SneAnswerRequest = {
                  task: SNE_TASK_ENUM.WRC,
                  data: null,
                  completed: completed
                };
                this.sneAnswerService.postAction(requestBody).subscribe();
                console.log('Written Communication completed.');
              }
              break;
            default:
              console.error(`Coachability Type could not be identified: ${this.wcType}`);
          }
          if (task) {
            let requestBody: SneAnswerRequest = {
              task: task,
              data: data,
              completed: completed
            };

            this.sneAnswerService.postAction(requestBody).subscribe( response => {
              console.log(`Response to Written Communication post request:  + ${response}`);
              this.singleResponse = ''; // refresh singleResponse
            });
          }
        }
      }
    }
  }

  closeInstructionModal() {
    this.showInstructionModal = false;
  }

  openInstructionModal() {
    this.showInstructionModal = true;
  }

  closeModalAndNav() {
    this.closeInstructionModal();
    this.navToNextPage();
  }

  additionalParamProcess() {}

  setData(wcData: Map<string, SneQuestion>) {
    if (this.questionNum) {
      this.wcData = wcData;
      this.questionData = this.wcData.get(this.questionNum);
      this.questionKeyList = Array.from(this.wcData.keys());
      this.questionIndex = this.questionKeyList
        .findIndex(key => key === this.questionNum);
      this.marquis = this.questionData?.marquis;
    }
  }

  processParams() {
    this.route.params.subscribe(params => {
      const { questionNum, section } = params;
      this.wcType = section;
      this.questionNum = questionNum;
      this.singleResponse = '';
      this.startTimestamp = Date.now().toString();

      if (this.questionNum && this.wcType) {
        switch (this.wcType) {
          // case WC_TYPES.FREEDOM_OF_SPEECH:
          //   // module index is +1 for what it should be because the active task should be filled in on the progress bar
          //   this.moduleIndex = 2;
          //   this.setData(WCFreedomOfSpeechData);
          //   this.instructionModal = WCFreedomOfSpeechInstructionModal;
          //   this.marquis = true;
          //   break;
          case WC_TYPES.SET_1:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 2;
            this.setData(WCSet1Data);
            this.instructionModal = WCSet1InstructionModal;
            break;
          case WC_TYPES.SET_2:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 3;
            this.setData(WCSet2Data);
            this.instructionModal = WCSet2InstructionModal;
            break;
          case WC_TYPES.SET_3:
            // module index is +1 for what it should be because the active task should be filled in on the progress bar
            this.moduleIndex = 4;
            this.setData(WCSet3Data);
            this.instructionModal = WCSet3InstructionModal;
            break;
          default:
            console.error(`Written Communication Type could not be identified: ${this.wcType}`);
        }
        this.additionalParamProcess();
      }
    });
  }
}
