import {SneVideos} from '../../../types/sne';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import Player from '@vimeo/player';
import {SNE_INPUT_TYPES} from 'src/app/types/sne';

@Component({
  selector: 'ets-sne-video',
  templateUrl: './sne-video.component.html',
  styleUrls: ['./sne-video.component.scss']
})
export class SneVideoComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(protected sanitizer: DomSanitizer) {
  }

  @Input() videoSetId: string = '';
  @Input() videoUrl: string = '';
  @Input() videoId: string = '';
  @Input() videoTitle: string | undefined;
  @Input() desktopThumbnailWidth: number = 280;
  @Input() mobileThumbnailWidth: number = 120;
  @Input() mobileThumbnailHeight: number = 67;
  @Input() questionSide?: boolean = false;
  @Input() noModal?: boolean = false; //flag to open modal with video or play directly in screen (ie. in coachability criticism)
  @Input() videoOnlyFlag = false; // flag to only show the video frame and not include any of the data/input fields
  @Input() inputType: SNE_INPUT_TYPES | undefined; // checkboxes vs radio buttons
  @Output() userResponseEvent = new EventEmitter<any>(); // will emit when a choice is selected/deselected

  // flags for handling the screen that prompts user that the video has been played.
  @Input() playOnce?: boolean = false; // passed in from data pages if video can only be played once.
  @Input() sharedVideo?: SneVideos; // pass playOnce && sharedVideo if video can only be played once && if parent component is different.
  videoIsPlaying: boolean = false;
  videoHasEnded: boolean = false;

  iframeVideo: Player | undefined;
  sanitizedVideoUrl: any = '';
  videoDuration: any;
  showModal = false;
  desktopVideoPlayerThumbnail!: Player;
  mobileVideoPlayerThumbnail!: Player;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  @ViewChild('videoOnlyFrame') videoOnlyIframe?: ElementRef;

  // ngOnInit will be run after this
  ngOnChanges(changes: SimpleChanges) {
    if (this.videoOnlyFlag) {
      console.log('Changes =>', changes);

      for (let propName in changes) {
        let change = changes[propName];
        const {currentValue, previousValue, firstChange} = change;

        // if the videoUrl changed without coming from a different component (prevVal would be undefined if it was from a different component)
        if (propName === 'videoUrl' && previousValue !== undefined) {
          window.location.reload();
          let iframe = this.videoOnlyIframe?.nativeElement;
          this.sanitizeUrl();
          this.iframeVideo?.unload();
          if (iframe) {
            (<HTMLImageElement>iframe).src = (<HTMLImageElement>iframe).src;
            this.hasVideoEnded(iframe);
          }
        }
        // sharedVideo.id is same as current videoId then videoHasEnded. (handles if video moves to a new parent component)
        if (propName === 'playOnce' && previousValue || (propName === 'videoId' && currentValue === this.sharedVideo?.id)) {
          this.videoHasEnded = true;
        }
      }
    }
  }

  ngOnInit() {
    this.sanitizeUrl();
  }

  sanitizeUrl() {
    // replace the beginning of the URL to include player so the iframe will load successfully
    this.videoUrl = this.videoUrl.replace('vimeo.com/', 'player.vimeo.com/video/');
    // sanitize the URL for the iframe
    this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }

  hasVideoEnded(iframe: HTMLElement) { // Checks to see whether the video is being played and if it has ended.
    if (iframe) {
      this.iframeVideo = new Player(iframe); // Creates a new Vimeo Player object with an iframe html element.
      this.iframeVideo.on('play', () => this.videoIsPlaying = true); // Once videoIsPlaying is true div with 0 opacity covers video to block user from using video controls.
      this.iframeVideo.on('ended', (data) => this.videoHasEnded = data.percent === 1); // Once data.percent hits 1 = (100%), video has ended converts property videoHasEnded to true.
    }
  }

  ngAfterViewInit() {
    // Checks to see if video has already been played.
    if (this.videoOnlyFlag) {
      this.hasVideoEnded(this.videoOnlyIframe?.nativeElement);
    }

    if (!this.videoOnlyFlag) {
      // desktop embed options
      let desktopThumbnailOptions = {
        url: this.videoUrl,
        width: this.desktopThumbnailWidth,
        controls: false, // hides the controls on the frame - using this to artificially get the thumbnail
      };
      this.desktopVideoPlayerThumbnail = new Player(`${this.videoId}-${this.videoSetId}-desktop`, desktopThumbnailOptions);
      // get the duration of the video - the same regardless of desktop vs mobile
      this.desktopVideoPlayerThumbnail.getDuration().then((duration) => {
        // `duration` indicates the duration of the video in seconds
        this.videoDuration = this.formatMSS(duration);
      });

      // mobile embed options
      let mobileThumbnailOptions = {
        url: this.videoUrl,
        width: this.mobileThumbnailWidth,
        height: this.mobileThumbnailHeight,
        playsinline: false, // forces the video to play in fullscreen
      };
      this.mobileVideoPlayerThumbnail = new Player(`${this.videoId}-${this.videoSetId}-mobile`, mobileThumbnailOptions);
    }
  }

  emitUserResponse(videoId: string) {
    this.userResponseEvent.emit(videoId);
  }

  // Function to format the duration of the video to MM:SS
  formatMSS(seconds: number) {
    return (seconds - (seconds %= 60)) / 60 + (9 < seconds ? ':' : ':0') + seconds;
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
