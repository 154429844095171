import { Component, Input, OnInit } from '@angular/core';
import { TopSkill } from '../../../../types/dashboard';

@Component({
  selector: 'ets-top-skills',
  templateUrl: './top-skills.component.html',
  styleUrls: ['./top-skills.component.scss']
})
export class TopSkillsComponent implements OnInit {

  constructor() { }

  @Input() topSkills: TopSkill[] = [];

  ngOnInit(): void {
  }

}
