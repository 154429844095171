import { Component, Input } from '@angular/core';

@Component({
  selector: 'ets-tooltip',
  template: `
    <a href="javascript:void(0)" role="tooltip" aria-haspopup="true" class="tooltip tooltip-{{toolTipSize}} {{position}}" >
      <img class= "info-circle" src="./assets/icons/info-icon.svg" alt="" aria-hidden="true"/>
      <span class="tooltip-content" style="width:{{width}};background-color:{{backgroundColor}};overflow-y:{{overflow}}">
        <img class='inner-tooltip' src="./assets/icons/white-tooltip.svg" alt="" aria-hidden="true" *ngIf="innerToolTip"/>
        <span class="tooltip-text" [innerHTML] = 'text' style="height:{{height}}"> 
          {{text}}
        </span>
      </span>
    </a>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() text = '';
  @Input() toolTipSize = 'md';
  @Input() innerToolTip = true;
  @Input() width = '';
  @Input() height = '';
  @Input() position = '';
  @Input() backgroundColor = '';
  @Input() overflow = '';
  
}
