import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ets-skill-type-overview',
  templateUrl: './skill-type-overview.component.html',
  styleUrls: ['./skill-type-overview.component.scss']
})
export class SkillTypeOverviewComponent {

  @Input() title: string = ''; // Skill Type Title
  @Input() description: string = ''; // Skill Type Description
  @Input() id!: number;
  @Input() imagePath: string = ''; // Path to the image
  @Input() selectedSkills!: string[]; // Array of selected skill names for the specified skill type
  @Input() disabled: boolean = false; // Flag to denote if entire skill type section is disabled
  @Input() topBorder: boolean = true; // Flag to display the top border of the div. Should be true for all except the final skill type
  @Output() clickEvent = new EventEmitter<number>(); // Event for when the select button is clicked
  
  clickSelect(id: number) {
    this.clickEvent.emit(id);
  }
}
