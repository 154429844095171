import { Component, Input } from '@angular/core';
import { SocialMediaPost } from 'src/app/types/sne';

@Component({
  selector: 'ets-sne-social-media-post',
  templateUrl: './sne-social-media-post.component.html',
  styleUrls: ['./sne-social-media-post.component.scss']
})
export class SneSocialMediaPostComponent {

  @Input() posts: SocialMediaPost[] |undefined= [];
  @Input() firstColorIndex?: number;
  @Input() colorArray: string[] = [];

  constructor() { }

  chooseColor(messageIndex: number, intendedColor: string): boolean {
    const colorIndex = this.colorArray.findIndex(color => color === intendedColor);

    if (this.colorArray.length > 1) {
      if (messageIndex % 2 !== 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 === 0 && colorIndex !== -1;
      }
    } else {
      if (messageIndex % 2 === 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 !== 0 && colorIndex !== -1;
      }
    }
  }
}
