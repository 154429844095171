export let FIELD_OF_STUDY = [
  'GENERAL AGRICULTURE',
  'AGRICULTURE PRODUCTION AND MANAGEMENT',
  'AGRICULTURAL ECONOMICS',
  'ANIMAL SCIENCES',
  'FOOD SCIENCE',
  'PLANT SCIENCE AND AGRONOMY',
  'SOIL SCIENCE',
  'MISCELLANEOUS AGRICULTURE',
  'ENVIRONMENTAL SCIENCE',
  'FORESTRY',
  'NATURAL RESOURCES MANAGEMENT',
  'ARCHITECTURE',
  'AREA ETHNIC AND CIVILIZATION STUDIES',
  'COMMUNICATIONS',
  'JOURNALISM',
  'MASS MEDIA',
  'ADVERTISING AND PUBLIC RELATIONS',
  'COMMUNICATION TECHNOLOGIES',
  'COMPUTER AND INFORMATION SYSTEMS',
  'COMPUTER PROGRAMMING AND DATA PROCESSING',
  'COMPUTER SCIENCE',
  'INFORMATION SCIENCES',
  'COMPUTER ADMINISTRATION MANAGEMENT AND SECURITY',
  'COMPUTER NETWORKING AND TELECOMMUNICATIONS',
  'COSMETOLOGY SERVICES AND CULINARY ARTS',
  'GENERAL EDUCATION',
  'EDUCATIONAL ADMINISTRATION AND SUPERVISION',
  'SCHOOL STUDENT COUNSELING',
  'ELEMENTARY EDUCATION',
  'MATHEMATICS TEACHER EDUCATION',
  'PHYSICAL AND HEALTH EDUCATION TEACHING',
  'EARLY CHILDHOOD EDUCATION',
  'SCIENCE AND COMPUTER TEACHER EDUCATION',
  'SECONDARY TEACHER EDUCATION',
  'SPECIAL NEEDS EDUCATION',
  'SOCIAL SCIENCE OR HISTORY TEACHER EDUCATION',
  'TEACHER EDUCATION: MULTIPLE LEVELS',
  'LANGUAGE AND DRAMA EDUCATION',
  'ART AND MUSIC EDUCATION',
  'MISCELLANEOUS EDUCATION',
  'GENERAL ENGINEERING',
  'AEROSPACE ENGINEERING',
  'BIOLOGICAL ENGINEERING',
  'ARCHITECTURAL ENGINEERING',
  'BIOMEDICAL ENGINEERING',
  'CHEMICAL ENGINEERING',
  'CIVIL ENGINEERING',
  'COMPUTER ENGINEERING',
  'ELECTRICAL ENGINEERING',
  'ENGINEERING MECHANICS PHYSICS AND SCIENCE',
  'ENVIRONMENTAL ENGINEERING',
  'GEOLOGICAL AND GEOPHYSICAL ENGINEERING',
  'INDUSTRIAL AND MANUFACTURING ENGINEERING',
  'MATERIALS ENGINEERING AND MATERIALS SCIENCE',
  'MECHANICAL ENGINEERING',
  'METALLURGICAL ENGINEERING',
  'MINING AND MINERAL ENGINEERING',
  'NAVAL ARCHITECTURE AND MARINE ENGINEERING',
  'NUCLEAR ENGINEERING',
  'PETROLEUM ENGINEERING',
  'MISCELLANEOUS ENGINEERING',
  'ENGINEERING TECHNOLOGIES',
  'ENGINEERING AND INDUSTRIAL MANAGEMENT',
  'ELECTRICAL ENGINEERING TECHNOLOGY',
  'INDUSTRIAL PRODUCTION TECHNOLOGIES',
  'MECHANICAL ENGINEERING RELATED TECHNOLOGIES',
  'MISCELLANEOUS ENGINEERING TECHNOLOGIES',
  'LINGUISTICS AND COMPARATIVE LANGUAGE AND LITERATURE',
  'FRENCH GERMAN LATIN AND OTHER COMMON FOREIGN LANGUAGE STUDIES',
  'OTHER FOREIGN LANGUAGES',
  'FAMILY AND CONSUMER SCIENCES',
  'COURT REPORTING',
  'PRE-LAW AND LEGAL STUDIES',
  'ENGLISH LANGUAGE AND LITERATURE',
  'COMPOSITION AND RHETORIC',
  'LIBERAL ARTS',
  'HUMANITIES',
  'LIBRARY SCIENCE',
  'BIOLOGY',
  'BIOCHEMICAL SCIENCES',
  'BOTANY',
  'MOLECULAR BIOLOGY',
  'ECOLOGY',
  'GENETICS',
  'MICROBIOLOGY',
  'PHARMACOLOGY',
  'PHYSIOLOGY',
  'ZOOLOGY',
  'NEUROSCIENCE',
  'MISCELLANEOUS BIOLOGY',
  'MATHEMATICS',
  'APPLIED MATHEMATICS',
  'STATISTICS AND DECISION SCIENCE',
  'MILITARY TECHNOLOGIES',
  'MULTI/INTERDISCIPLINARY STUDIES',
  'INTERCULTURAL AND INTERNATIONAL STUDIES',
  'NUTRITION SCIENCES',
  'MATHEMATICS AND COMPUTER SCIENCE',
  'COGNITIVE SCIENCE AND BIOPSYCHOLOGY',
  'INTERDISCIPLINARY SOCIAL SCIENCES',
  'PHYSICAL FITNESS PARKS RECREATION AND LEISURE',
  'PHILOSOPHY AND RELIGIOUS STUDIES',
  'THEOLOGY AND RELIGIOUS VOCATIONS',
  'PHYSICAL SCIENCES',
  'ASTRONOMY AND ASTROPHYSICS',
  'ATMOSPHERIC SCIENCES AND METEOROLOGY',
  'CHEMISTRY',
  'GEOLOGY AND EARTH SCIENCE',
  'GEOSCIENCES',
  'OCEANOGRAPHY',
  'PHYSICS',
  'MATERIALS SCIENCE',
  'MULTI-DISCIPLINARY OR GENERAL SCIENCE',
  'NUCLEAR, INDUSTRIAL RADIOLOGY, AND BIOLOGICAL TECHNOLOGIES',
  'PSYCHOLOGY',
  'EDUCATIONAL PSYCHOLOGY',
  'CLINICAL PSYCHOLOGY',
  'COUNSELING PSYCHOLOGY',
  'INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY',
  'SOCIAL PSYCHOLOGY',
  'MISCELLANEOUS PSYCHOLOGY',
  'CRIMINAL JUSTICE AND FIRE PROTECTION',
  'PUBLIC ADMINISTRATION',
  'PUBLIC POLICY',
  'HUMAN SERVICES AND COMMUNITY ORGANIZATION',
  'SOCIAL WORK',
  'GENERAL SOCIAL SCIENCES',
  'ECONOMICS',
  'ANTHROPOLOGY AND ARCHEOLOGY',
  'CRIMINOLOGY',
  'GEOGRAPHY',
  'INTERNATIONAL RELATIONS',
  'POLITICAL SCIENCE AND GOVERNMENT',
  'SOCIOLOGY',
  'MISCELLANEOUS SOCIAL SCIENCES',
  'CONSTRUCTION SERVICES',
  'ELECTRICAL, MECHANICAL, AND PRECISION TECHNOLOGIES AND PRODUCTION',
  'TRANSPORTATION SCIENCES AND TECHNOLOGIES',
  'FINE ARTS',
  'DRAMA AND THEATER ARTS',
  'MUSIC',
  'VISUAL AND PERFORMING ARTS',
  'COMMERCIAL ART AND GRAPHIC DESIGN',
  'FILM VIDEO AND PHOTOGRAPHIC ARTS',
  'ART HISTORY AND CRITICISM',
  'STUDIO ARTS',
  'MISCELLANEOUS FINE ARTS',
  'GENERAL MEDICAL AND HEALTH SERVICES',
  'COMMUNICATION DISORDERS SCIENCES AND SERVICES',
  'HEALTH AND MEDICAL ADMINISTRATIVE SERVICES',
  'MEDICAL ASSISTING SERVICES',
  'MEDICAL TECHNOLOGIES TECHNICIANS',
  'HEALTH AND MEDICAL PREPARATORY PROGRAMS',
  'NURSING',
  'PHARMACY PHARMACEUTICAL SCIENCES AND ADMINISTRATION',
  'TREATMENT THERAPY PROFESSIONS',
  'COMMUNITY AND PUBLIC HEALTH',
  'MISCELLANEOUS HEALTH MEDICAL PROFESSIONS',
  'GENERAL BUSINESS',
  'ACCOUNTING',
  'ACTUARIAL SCIENCE',
  'BUSINESS MANAGEMENT AND ADMINISTRATION',
  'OPERATIONS LOGISTICS AND E-COMMERCE',
  'BUSINESS ECONOMICS',
  'MARKETING AND MARKETING RESEARCH',
  'FINANCE',
  'HUMAN RESOURCES AND PERSONNEL MANAGEMENT',
  'INTERNATIONAL BUSINESS',
  'HOSPITALITY MANAGEMENT',
  'MANAGEMENT INFORMATION SYSTEMS AND STATISTICS',
  'MISCELLANEOUS BUSINESS & MEDICAL ADMINISTRATION',
  'HISTORY',
  'UNITED STATES HISTORY',
];