<div class="container">
  <div *ngIf="hintExists || showHeader" class="hint-btn-container" [ngClass]="{'hint-btn-container-negotiation': cpsCheck}">
    <span class="interaction-text" [ngClass]="{'interaction-text-negotiation': cpsCheck}" *ngIf="showHeader">{{headerText}}</span>
    <ets-outline-button *ngIf="hintExists" [text]="'Hint'" [color]="APP_COLORS.uiaction"
      [iconImgSrc]="'/assets/icons/blue-lightbulb-no-flash.svg'" [iconImgAlt]="'blue lightbulb'" [iconWidth]="'16px'"
      (clickEvent)="hintModalClick()">
    </ets-outline-button>
  </div>

  <div *ngIf = "chatLog?.length === 0">
    <img class = "chat-icon" src="../../../assets/sne/chat-bubble.svg" alt="empty message">
  </div>

  <div etsScrollToBottom class="chat-container"
      [ngClass]="{'standard': showHeader}"
      [ngStyle]="chatContainerPaddingBottom !== '' ? {'padding-bottom': chatContainerPaddingBottom} : {}"
      *ngIf="!showComments && !showBotPicsOnly">
    <div class="chat-log" [ngClass]="{'chat-self': statement.user === 'You'}" *ngFor="let statement of chatLog">
      <div class="message-wrapper">
        <img class="profile bot-pic" *ngIf="statement.user !== 'You'"
             src={{botProfilePic}} alt="supervisor profile picture" width="56" height="56" />
        <img class="profile your-pic" *ngIf="statement.user === 'You'"
             src={{youProfilePic}} alt="your profile picture" width="56" height="56" />
        <div class="user-wrapper"
             [ngClass]="{
             'bot-response-wrapper': (statement.user !== 'You'),
             'you-response-wrapper': (statement.user === 'You' && !cpsCheck) ,
             'you-response-wrapper-negotiation': (statement.user === 'You' && cpsCheck),
             'modal-response-wrapper': !showHeader
           }">
          <h2 class='user-name'>{{statement.user}}</h2>
          <p class='message'>{{statement.message}}</p>
        </div>
      </div>
    </div>
  </div>

  <div etsScrollToBottom class="chat-container"
       [ngClass]="{'standard': showHeader}"
       [ngStyle]="chatContainerPaddingBottom !== '' ? {'padding-bottom': chatContainerPaddingBottom} : {}"
       *ngIf="!showComments && showBotPicsOnly">
    <div class="chat-log" *ngFor="let statement of chatLog">
      <div class="message-wrapper">
        <img class="profile bot-pic"
             [src]="botPics?.get(statement.user ? statement.user : '')" alt="supervisor profile picture" width="56" height="56" />
        <div class="user-wrapper"
             [ngClass]="{
             'bot-response-wrapper': (statement.user !== 'You')
           }">
          <h2 class='user-name'>{{statement.user}}</h2>
          <p class='message'>{{statement.message}}</p>
        </div>
      </div>
    </div>
  </div>

  <div etsScrollToBottom class="comments-container" [ngClass]="{'standard': showHeader}" *ngIf="showComments">
    <div class="comments-log" [ngClass]="{'chat-self': statement.user === 'You'}" *ngFor="let statement of chatLog; let i = index;">
      <div class="comment-message-wrapper">
        <!-- <img class="profile bot-pic" *ngIf="statement.user === 'Colleague 1'"
        src={{youProfilePic}} alt="Colleague 1 Profile Picture"> -->
        <img class="profile bot-pic" *ngIf="i%2 ===0"
            src={{botProfilePic}} alt="supervisor profile picture">
        <img class="profile your-pic" *ngIf="i%2===1"
            src={{youProfilePic}} alt="your profile picture">
        <div class="user-wrapper"
            [ngClass]="{'bot-response-wrapper': (i%2===0),'you-response-wrapper': (i%2===1), 'modal-response-wrapper': !showHeader,
            'white-box': chooseColor(i, 'white'),
            'blue-box': chooseColor(i, 'blue'),
            'green-box': chooseColor(i, 'green')}">
          <h2 class='user-name'>{{statement.user}}</h2>
          <p class='message'>{{statement.message}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
