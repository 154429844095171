
export interface JobPosting {
  jobTitle: string;
  company: string;
  jobDescription: string;
  jobLocation: string;
  jobType: JOB_TYPE;
  jobPostUrl: string;
  jobImage?: string;
}

export enum JOB_TYPE {
  FULL_TIME = 'Full-time', 
  PART_TIME = 'Part-time',
}