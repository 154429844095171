import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CareerDetail } from 'src/app/types/career-detail';
import { APP_COLORS } from '../../../global-styles';
import { Router } from '@angular/router';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Career } from '../../types/dashboard';

@Component({
  selector: 'ets-dashboard-careers',
  templateUrl: './dashboard-careers.component.html',
  styleUrls: ['./dashboard-careers.component.scss']
})
export class DashboardCareersComponent implements OnChanges {
  constructor(private router: Router) { }

  APP_COLORS = APP_COLORS;
  faInfoCircle = faInfoCircle;

  tip = 'Your Saved Careers are the careers you\'ve set your sights on as potential places for you to land at some point during your journey.';

  @Input() careers: Career[] = [];
  careerList: Career[] = [];

  getCareers(careers: any[]): void {
    if (this.careers) {
      this.careerList = careers.reduce((arr, item, idx) => {
        arr[idx] = item;
        return arr;
      }, [{}, {}, {}]); // empty objects to display empty career-cards.
    }
  };

  ngOnChanges(changes: SimpleChanges): void { // Runs if any @Input() properties updates. Called before ngOnInit().
    if (changes.careers) {
      const { previousValue, currentValue, } = changes.careers;
      this.getCareers(currentValue);
    }
  }

  openCareers() {
    this.router.navigate(['/career-suggestions']);
  }

}
