<div class="container">
  <div class="title-container">
    <div class="title-text-container">
      <span class="module-title-desktop">EXPLORING BEHAVIORS & INTERESTS</span>
      <span class="module-title-tablet-mobile">IDENTIFYING TRAITS</span>
      <span class="module-header">Understand what makes you unique</span>

      <div class="complete-counter-mobile">
        <ets-assessment-complete [completion]="assessmentsCompleted" [total]="2"></ets-assessment-complete>
      </div>
      <span class="module-description">
        The quizzes below help identify your ideal work environment. Articulating your
        passions and how you tend to operate will help you find a career that brings out the best in you.
      </span>
    </div>
    <div class="complete-counter-desktop">
      <ets-assessment-complete [completion]="assessmentsCompleted" [total]="2"></ets-assessment-complete>
    </div>
  </div>
  <div class="card-list-container">
    <!-- facets -->
    <div class="card-container">
      <!-- <div *ngIf="facetsResults.status==='not started'"> -->
      <div class="icon-text-container">
        <div class="icon-container">
          <img class="facets-icon" src="/../assets/facets/facets-intro-blue.jpeg">
        </div>
        <div class="image-text-container">
          <span class="image-text">Uncover your unique combination of behaviors</span>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="results-preview-container" *ngIf="facetsCompleted">

      </div> -->
      <div class="text-start-container">
        <div class="text-container">
          <span class="status"[innerHtml]="facetsStatus"> </span>
          <fa-icon *ngIf="facetsComplete" [icon]="faCheckCircle" class="check-mark-icon" size="sm"></fa-icon>
          <br>
          <span class="title">Get to know yourself</span>
          <br>
          <span class="duration">{{facetsDuration}}</span>
        </div>
        <ets-outline-button *ngIf="facetsResults?.status === 'Completed'"
                            [text]="'OPEN REPORT'"
                            [color]="APP_COLORS.uiaction"
                            (clickEvent)="facetsClick()"
        ></ets-outline-button>
        <ets-solid-button *ngIf="facetsResults?.status === 'Not started'"
                          [text]="'START QUIZ'"
                          [color]="APP_COLORS.uiaction"
                          (clickEvent)="facetsClick()"
        ></ets-solid-button>
        <ets-solid-button *ngIf="facetsResults?.status === 'In progress'"
                          [text]="'CONTINUE QUIZ'"
                          [color]="APP_COLORS.uiaction"
                          (clickEvent)="facetsClick()"
        ></ets-solid-button>
      </div>
    </div>
    <!-- CIP -->
    <div class="card-container">
      <div class="icon-text-container">
        <div class="icon-container">
          <img class="cip-icon" src="/../assets/sne/Section-Three.jpeg">
        </div>
        <div class="image-text-container">
          <span class="image-text">Gain insight into your career aspirations and interests</span>
        </div>
      </div>
      <!-- <div class="results-preview-container" *ngIf="cipCompleted">

      </div> -->
      <div class="text-start-container">
        <div class="text-container">
          <span class="status" [innerHtml]="cipStatus"></span>
          <fa-icon *ngIf="cipComplete" [icon]="faCheckCircle" class="check-mark-icon" size="sm"></fa-icon>
          <br>
          <span class="title">What drives you</span>
          <br>
          <span class="duration">{{cipDuration}}</span>
        </div>
        <ets-outline-button *ngIf="cipResults?.status === 'Completed'"
                            [text]="'OPEN REPORT'"
                            [color]="APP_COLORS.uiaction"
                            (clickEvent)="cipClick()"
        ></ets-outline-button>
        <ets-solid-button *ngIf="cipResults?.status === 'Not started'"
                          [text]="'START QUIZ'"
                          [color]="APP_COLORS.uiaction"
                          (clickEvent)="cipClick()"
        ></ets-solid-button>
        <ets-solid-button *ngIf="cipResults?.status === 'In progress'"
                          [text]="'CONTINUE QUIZ'"
                          [color]="APP_COLORS.uiaction"
                          (clickEvent)="cipClick()"
        ></ets-solid-button>
      </div>
    </div>
  </div>

</div>
