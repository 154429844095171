import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'ets-ct-production-flow-map',
  templateUrl: './ct-production-flow-map.component.html',
  styleUrls: ['./ct-production-flow-map.component.scss']
})
export class CtProductionFlowMapComponent extends SneCriticalThinkingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService, private bpo: BreakpointObserver) {
    super(route, router, sneAnswerService);
  }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  
  isHandset$: Observable<boolean> = this.bpo.observe([Breakpoints.HandsetPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  singleReponseQuestionHandler(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }

  ngOnInit(): void {
    this.processParams();
  }

}
