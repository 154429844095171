// Angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Apprize App
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
// SNE - Oral Communication
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { SneOralCommunicationBase } from '../sne-oral-communcation.base';
import { SneAnswerService } from 'src/app/services/sne-answer.service';

@Component({
  selector: 'ets-oc-madlibs-video-splitscreen',
  templateUrl: './oc-madlibs-video-splitscreen.component.html',
  styleUrls: ['./oc-madlibs-video-splitscreen.component.scss']
})
export class OcMadlibsVideoSplitscreenComponent extends SneOralCommunicationBase implements OnInit {

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  headerQuestion: string = '';
  showModal = false;
  responseLength = 0;

  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  ngOnInit(): void {
    this.processParams();
  }

  toggleModal() {
    this.showModal=!this.showModal;
  }

  handleUserResponse(response: any[]) {

    let idx: number = response[0];
    let option: string = response[1];
    let letter: string = response[2];

    this.madlibsResponse[idx] = letter;
    this.responseLength = Object.keys(this.madlibsResponse).length;
  }
}
