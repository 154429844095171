<div class="satisfaction-information">
  <div class="client">
    <p class="satisfaction-text">Client Satisfaction</p>
    <div class="stars">
      <div *ngFor="let i of clientStars">
        <fa-icon class="fa-icon" [icon]="faStar" *ngIf="i.status === StarStatus.FILLED"></fa-icon>
        <fa-icon class="fa-icon new-filled" style="display: block" [icon]="faStar" [@animation]
          *ngIf="i.status === StarStatus.NEW_FILLED"></fa-icon>
        <fa-icon class="fa-icon new-empty" style="display: block" [icon]="faStar" [@animation2]
          *ngIf="i.status === StarStatus.NEW_EMPTY"></fa-icon>
        <fa-icon class="fa-icon faded" [icon]="faStar" *ngIf="i.status === StarStatus.EMPTY"></fa-icon>
      </div>
    </div>
  </div>

  <div class="jordan">
    <p class="satisfaction-text">Jordan Satisfaction</p>
    <div class="stars">
      <div *ngFor="let i of jordanStars">
        <fa-icon class="fa-icon" class="fa-icon" [icon]="faStar" *ngIf="i.status === StarStatus.FILLED">
        </fa-icon>
        <fa-icon class="fa-icon new-filled" style="display: block" [icon]="faStar" [@animation]
          *ngIf="i.status === StarStatus.NEW_FILLED"></fa-icon>
        <fa-icon class="fa-icon new-empty" style="display: block" [icon]="faStar" [@animation2]
          *ngIf="i.status === StarStatus.NEW_EMPTY"></fa-icon>
        <fa-icon class="fa-icon faded" [icon]="faStar" *ngIf="i.status === StarStatus.EMPTY"></fa-icon>
      </div>
    </div>
  </div>
</div>