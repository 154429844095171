import { Component, Input, OnInit, Output, EventEmitter, DoCheck, OnChanges, SimpleChanges } from '@angular/core';
import { Event, Router } from '@angular/router';
import { APP_COLORS } from 'src/global-styles';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-sne-progress-bar',
  templateUrl: './sne-progress-bar.component.html',
  styleUrls: ['./sne-progress-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class SneProgressBarComponent implements OnInit, OnChanges {

  @Input() show: boolean | undefined;
  @Input() showBtnText: string = 'Show Question';
  @Input() hideBtnText: string = 'Hide Question';
  @Input() drawerActive: boolean = true;
  @Input() questionIndex: number | undefined ;
  @Input() moduleSize: number | undefined;
  // Passed in to determine to show overview/next button or not.
  @Input() overviewEnabled: boolean = true;
  @Input() nextEnabled: boolean = true;
  @Output() overviewEmitter = new EventEmitter<any>();
  @Output() nextEmitter = new EventEmitter<any>();
  @Output() drawerOpenEmitter = new EventEmitter<any>();
  @Input() isButtonDisabled: boolean = true;
  @Input() questionNum: string | undefined;
  @Input() buttonText: string | undefined;
  @Input() showSaveAndExit = false;

  // Making drawerVisible an input so assessments can control open/closing of the drawer
  @Input() drawerVisible = false;
  progressSectionWidth: string | undefined;
  overviewBtnText = 'Overview';
  nextBtnText = 'Next';
  showQuestionBtnText = 'Show Question';
  APP_COLORS = APP_COLORS;
  mobileButtonSize = 'md';
  moduleList: number [] | undefined;

  currentWindowWidth: number = 0;

  //Mobile
  showPullUpSection = false;

  constructor(private eventService: EventService, private router: Router) { }

  showDrawer() {
    this.drawerVisible=!this.drawerVisible;
    if (this.drawerVisible) {
      this.drawerOpenEmitter.emit();
    }
  }

  overviewEmit(event: any){
    this.overviewEmitter.emit(event);
  }
  nextEmit(event: any){
    this.nextEmitter.emit(event);
  }

  setProgressBarWidth() {
    this.progressSectionWidth =
      (1/this.moduleSize!) * window.innerWidth - 4 // find the length of a section - the width of the white divider
      + (4/this.moduleSize!) + 'px'; //  make the sections longer by a fraction since we dont show the last white bar
  }

  ngOnInit() {
    this.moduleList = Array.from(Array(this.moduleSize! +1 ).keys());
    this.moduleList = this.moduleList.splice(1);
    this.setProgressBarWidth();
    fromEvent(window, 'resize')
      .pipe(map(() => window.innerWidth), distinctUntilChanged())
      .subscribe(() => this.setProgressBarWidth());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['questionNum']) {
      this.drawerVisible = false;
    }
  }

  saveAndExit() {
    // Track the event in AWS
    this.eventService.buildEvent('SNE Save & Exit', EventActionEnum.CLICK);
    this.router.navigate(['/dashboard']);
  }
}
