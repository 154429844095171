import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Interstitial } from 'src/app/types/sne';
import { ActivatedRoute } from '@angular/router';
import { SneText } from 'src/app/data/sne-text';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { APP_COLORS } from '../../../../global-styles';

@Component({
  selector: 'ets-sne-overview',
  templateUrl: './sne-overview.component.html',
  styleUrls: ['./sne-overview.component.scss']
})
export class SneOverviewComponent implements OnInit {

  @Input() showModal: boolean = true;
  @Input() task: string = 'hidden-profile-instructions';
  @Input() hint = false;
  @Output() modalClosed = new EventEmitter<any>();
  @Output() startAssessment = new EventEmitter<any>();
  instruction:Interstitial | undefined;
  page: number = 0;
  listLength = 0;
  displayInstruction!:Interstitial;
  faStar = faStar;
  APP_COLORS = APP_COLORS;

  getInstructions(task:string){
    this.instruction = SneText.get(this.task);
    if (this.instruction?.instructionList) {
      // only check for 'hint' in the task if it wasn't already explicity set to true
      if (!this.hint) {
        this.hint = this.task.includes('hint');
      }

      this.listLength = this.instruction.instructionList.length;
      this.displayInstruction=this.instruction.instructionList[this.page];
    }
  }

  nextInstruction(){
    this.page++;
    if(this.displayInstruction.progress && (+this.displayInstruction.progress === this.listLength) && this.instruction?.instructionList){
      // this.showModal = false;
      this.page = 0;
      this.displayInstruction = this.instruction.instructionList[this.page];
      this.modalClosed.emit();
      this.startAssessment.emit();
    } else {
      this.getInstructions(this.task);
    }

  }

  constructor() { }

  ngOnInit(): void {
    this.getInstructions(this.task);
  }

}
