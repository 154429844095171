import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/internal/operators';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs/index';
import {Store} from '@ngrx/store';
import { setErrorModal } from '../store/error-modal.actions';
import { State } from '../store/error-modal.reducer';

const ENDPOINTS_TO_IGNORE = ['/bgt/suboccsTypeahead', '/log'];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<State>
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          let ignore = false;

          ENDPOINTS_TO_IGNORE.forEach(endpoint => {
            if (error.url?.includes(endpoint)) {
              ignore = true;
            }
          });
          // TODO make sure this doesn't override the facets error modal
          // TODO limit it to only dispatch if it's a relevant service call for the page your on?
          // TODO failure on career suggestion page takes you back to start of onboarding rather than dashboard - make sure onboarding is completed
          // TODO moving to the next question prior to opening error modal
          if (!ignore) {
            this.store.dispatch(setErrorModal({payload: true}));
          }
          return throwError(error);
        })
      );
  }

}
