import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProfileService } from 'src/app/services/profile.service';
import { SkillsService } from 'src/app/services/skills.service';
import { SkillsResponse } from 'src/app/types/skills';
import { EventService } from 'src/app/services/event.service';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { setSkillsInFlight } from '../../../store/on-boarding.actions';
import OB_PAGES from '../../../types/on-boarding-pages.types';
import { ClrAriaLivePoliteness, ClrAriaLiveService } from '@clr/angular';

@Component({
  selector: 'ets-transferable-and-job-specific-skills',
  templateUrl: './transferable-and-job-specific-skills.component.html',
  styleUrls: ['./transferable-and-job-specific-skills.component.scss'],
  providers: [ClrAriaLiveService]
})
export class TransferableAndJobSpecificSkillsComponent extends OnBoardingV2Base implements OnInit, OnDestroy {
  skillsResponse!: SkillsResponse;
  uniqueRoleTitles: (any[] | string[] | undefined) = [];

  allSkillsWorkHistoryAndSelfInputJobSpecific: any[] = [];
  allSkillsWorkHistoryAndSelfInputTransferable: any[] = [];
  top25: any[] = [];

  userSelectedSkills: SkillsResponse[] = [];
  originalSelfInput: SkillsResponse[] = [];
  title = '';
  info = '';
  subInfo = 'Select each skill you want to add to your profile.';
  selectedRole = 'All roles';
  modalOpen = false;
  currentSkillType: string = '';
  menuOpen = false;

  constructor(protected store: Store,
              protected router: Router,
              protected route: ActivatedRoute,
              protected profileService: ProfileService,
              protected skillsService: SkillsService,
              protected eventService: EventService,
              public ariaLiveService: ClrAriaLiveService) {
    super(store, router, route, profileService, eventService);
  }

  ngOnInit() {
    this.initialize();
    this.skillsService.getSkills().subscribe({
      next: response => {
        this.skillsResponse = response;
        this.parseSkills();       // separate out skill types to transferable and job specific, remove any duplicates between self-input and work history skills
        this.currentSkillType = this.currentQuestion?.page === OB_PAGES.TRANSFERABLE_SKILLS ? 'transferable' : 'job-specific';
        this.title = this.currentQuestion && this.currentQuestion?.page === 'transferableSkills' ? '<span class="convertToBold">Power</span> Skills + Behaviors' : '<span class="convertToBold">Specialized</span> Skills';
        this.info = this.currentQuestion && this.currentQuestion?.info || 'We think you learned some of these skills based on the combination of roles you described.';
        this.originalSelfInput = response.selfInput;

        this.buildTopTwentyFive();
        this.getRolesList();       // get available roles from work history for dropdown list
      }
    });
  }

  ngOnDestroy() {
    this.teardown();
  }

  openModal() {
    this.modalOpen = true;
  }

  autoSelect(){
    this.filterByRole('All roles');
    this.modalOpen = false;
    this.userSelectedSkills = [];

    let topFive = this.top25?.sort((a,b) => b.consolidatedProficiency - a.consolidatedProficiency).slice(0,5);

    topFive?.forEach(skillObj => {
      // highlight top 5 skills
      if(!this.userSelectedSkills.includes(skillObj)){
        this.toggleSkill(skillObj);
      }
      this.skillsResponse.selfInput?.forEach(skill =>{
        if (this.currentSkillType === skill.skillType ) {
          if (!skillObj.eulerSkillName === skill.eulerSkillName) {
            //only toggle if NOT already highlighted
            this.toggleSkill(skill);
          }
        }
      });
    });

    this.ariaLiveService.announce('Auto selected your top five skills. Modal closed.', ClrAriaLivePoliteness.assertive);
  }

  cancel() {
    this.modalOpen = false;
    this.ariaLiveService.announce('Modal Closed', ClrAriaLivePoliteness.assertive);
  }

  buildTopTwentyFive() {
    if(this.currentQuestion?.page === 'transferableSkills'){
      let arrayWithNoDuplicates = this.allSkillsWorkHistoryAndSelfInputTransferable.filter((v,i,a)=>a.findIndex(v2=>(v2.eulerID===v.eulerID))===i); //remove duplicate Euler IDs
      this.top25 = arrayWithNoDuplicates.sort((a,b) => b.consolidatedProficiency - a.consolidatedProficiency).slice(0,25);
    } else if (this.currentQuestion?.page === 'jobSpecificSkills'){
      let arrayWithNoDuplicates = this.allSkillsWorkHistoryAndSelfInputJobSpecific.filter((v,i,a)=>a.findIndex(v2=>(v2.eulerID===v.eulerID))===i); //remove duplicate Euler IDs
      this.top25 = arrayWithNoDuplicates.sort((a,b) => b.consolidatedProficiency - a.consolidatedProficiency).slice(0,25);
    }
  }

  getRolesList() {
    this.top25.forEach(skill => {
      if (skill.roleTitle && !this.uniqueRoleTitles?.includes(skill.roleTitle)){
        this.uniqueRoleTitles?.push(skill.roleTitle);
      }
    });
  }

  parseSkills() {
    //remove duplicate skills with same names
    let noDuplicatesSelfInput = this.skillsResponse.selfInput?.filter((value, index, self) =>
      index === self.findIndex(t => t.eulerSkillName === value.eulerSkillName)
    );
    let noDuplicatesWorkHistory = this.skillsResponse.workHistory?.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.eulerSkillName === value.eulerSkillName
      ))
    );

    // separate self-input skills into transferable and job-specific
    noDuplicatesSelfInput?.forEach(skill => {
      if(skill.skillType === 'transferable'){
        this.allSkillsWorkHistoryAndSelfInputTransferable.push(skill);
      } else if (skill.skillType === 'job-specific'){
        this.allSkillsWorkHistoryAndSelfInputJobSpecific.push(skill);
      }
    });

    // separate work history skills into transferable and job-specific
    noDuplicatesWorkHistory?.forEach(skill => {
      if(skill.skillType === 'transferable'){
        this.allSkillsWorkHistoryAndSelfInputTransferable.push(skill);
      } else if (skill.skillType === 'job-specific'){
        this.allSkillsWorkHistoryAndSelfInputJobSpecific.push(skill);
      }
    });

    //highlight skills already proficient
    this.skillsResponse.selfInput?.forEach(skill => {
      this.toggleSkill(skill);
    });
  }

  toggleOpenMenu(){
    this.menuOpen = !this.menuOpen;
  }

  filterByRole(role: string){
    this.selectedRole = role;
    let newSkillsArray: SkillsResponse[] = [];
    this.buildTopTwentyFive();  //reset array

    // if user filters by a specific role
    if(role !== 'All roles'){
      let filteredSkillObjects = this.top25?.filter(skill => skill.roleTitle === role); // only skills with roleTitles

      filteredSkillObjects.forEach(skill => {
        skill.eulerSkillName && !newSkillsArray.includes(skill) ?
          newSkillsArray.push(skill) :
          null;
      });

      this.top25 = newSkillsArray;
    }
  }


  toggleSkill(skill: SkillsResponse) {
    if (!this.userSelectedSkills.includes(skill) ){
      // add to array
      this.userSelectedSkills.push(skill);
    } else  {
      // remove from array
      this.userSelectedSkills.indexOf(skill) > -1 ? this.userSelectedSkills.splice(this.userSelectedSkills.indexOf(skill), 1) : false;
    }
  }

  disableSaveAndContinue(): boolean {
    return this.userSelectedSkills.length > 0 ? false : true;
  }

  saveAndContinue() {
    let skills: skillsObj[] = [];
    interface skillsObj {
      eulerID?: number;
      proficient: boolean;
    }

    this.userSelectedSkills.forEach(userSelected => {
      //if skill is newly selected
      if(!this.skillsResponse.selfInput?.includes(userSelected)){
        skills.push({
          'eulerID': userSelected.eulerID,
          'proficient': true
        });
      } // else already proficiency: true in DB, don't push/replicate
    });

    this.skillsResponse.selfInput?.forEach(originalSkill => {
      const userSelectedSkillsNameList = this.userSelectedSkills.map(skill => skill.eulerSkillName);

      if (!userSelectedSkillsNameList.includes(originalSkill.eulerSkillName)
        && this.currentSkillType === originalSkill.skillType) {
        // user de-selected skill, send in proficiency: false
        skills.push({
          'eulerID': originalSkill.eulerID,
          'proficient': false
        });
      }
    });

    let arrayWithNoDuplicates = skills.filter((v,i,a)=>a.findIndex(v2=>(v2.eulerID===v.eulerID))===i); //remove duplicate Euler IDs

    this.store.dispatch(setSkillsInFlight({ payload: true }));
    this.skillsService.saveProfileSkills({skills: arrayWithNoDuplicates}).subscribe(response => {
      console.log(response);
      this.store.dispatch(setSkillsInFlight({ payload: false }));
    });

    // Set the user response to "Save & Continue" to match the ChoiceNav answer
    this.userResponse = 'Save & Continue';
    this.submitResponse();
  }
}
