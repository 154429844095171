import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatMessage, SneMulChoiceOption } from 'src/app/types/sne';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import QUESTION_TYPES from 'src/app/types/question-types';
import { SneCoachabilityBase } from '../sne-coachability.base';
import COACHABILITY_TYPES  from 'src/app/types/coachability-types';
import { APP_COLORS } from '../../../../global-styles';
import { SNE_INPUT_TYPES } from 'src/app/types/sne';
import { SneAnswerService } from '../../../services/sne-answer.service';
import * as _ from 'lodash';

@Component({
  selector: 'ets-coachability-multi-choice-convo-log',
  templateUrl: './coachability-multi-choice-convo-log.component.html',
  styleUrls: ['./coachability-multi-choice-convo-log.component.scss']
})
export class CoachabilityMultiChoiceConvoLogComponent extends SneCoachabilityBase implements OnInit {

  showModal = false;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  chatList: ChatMessage[] = [];
  showHintModal = false;
  headerQuestion: string = '';
  responseList: string[] = [];

  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  botPictures: Map<string, string> = new Map<string, string>();

  /*toggleModal() {
    this.showModal = !this.showModal;
  }*/
  toggleHintModal() {
    if (this.questionData?.hintModalFlag === true) {
      this.showHintModal = true;
    }
    //this.showHintModal = true;
  }

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }


  additionalNextQuestionSteps = () => {
    this.responseList.push(this.singleResponse);
    this.generateChatList();
    /*if (this.questionData?.nextPageHasHint === true) {
      this.showHintModal = true;
    }*/

  }

  singleResponseHandler(answer: string) {
    this.singleResponse = answer;
  }

  multiResponseHandler(answer: string) {
    let arrIndex = this.multiResponse.indexOf(answer);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(answer);
      this.multiResponse.sort();
    }

  }

  saveProgress() {
  }

  generateChatList() {
    if (this.coachabilityData) {
      const multiChoiceQuestions = Array.from(this.coachabilityData.values()).filter(element => element.questionType === QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO);
      this.chatList = [];
      if (this.coachabilityType === COACHABILITY_TYPES.COACHES) {
        let chat: ChatMessage = {
          user: 'Bill',
          message: 'Hey! What do you think of my idea? I think if we both back it, they’ll really need to think about it seriously.',
        };
        this.chatList.push(chat);
      }
      if (this.coachabilityType === COACHABILITY_TYPES.UNCERTAINTY) {
        let chat: ChatMessage = {
          user: 'Supervisor',
          message: 'We really need to address those issues that woman brought up at last month’s All Staff meeting-she made some great points. Can you make sure that it gets done?',
        };
        this.chatList.push(chat);
      }
      if (this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES) {
        let chat: ChatMessage = {
          user: 'Sam',
          message: 'Yikes, what now? I can’t believe we messed up the data! Do we have to tell Gail about it?',
        };
        this.chatList.push(chat);
      }

      this.responseList.forEach((response, index) => {
        const question = multiChoiceQuestions[index].singleMulChoiceOptions?.find(question => question.userLetter === response);
        if (question) {
          let chat: ChatMessage = {
            user: 'You',
            message: question.userOption,
          };
          this.chatList.push(chat);
        }

        if (question && question.response) {
          let chat: ChatMessage = {
            user: question.autoUser,
            message: question.response,
          };
          this.chatList.push(chat);
        }
      });
    }
  }

  additionalParamProcess() {
    this.botPictures = new Map<string, string>();
    if(this.questionData && this.questionData.chatMessages) {
      this.chatList = this.questionData.chatMessages;
      this.chatList.forEach(message => {
        if (message.user) {
          this.botPictures.set(message.user, `assets/sne/cch/${_.camelCase(message.user)}-bot.svg`);
        }
      });
    }
  }

  buildChat(option: SneMulChoiceOption) {
    if (option.userLetter) {
      this.responseList.push(option.userLetter);
    }

    let chat: ChatMessage = {
      user: 'You',
      message: option.userOption
    };
    this.chatList.push(chat);

    if (option.response && option.response !== '') {
      let response: ChatMessage = {
        user: option.autoUser,
        message: option.response
      };
      this.chatList.push(response);
    }
  }

  rebuildChatLogOnResume() {
    // rebuild chat log if coming from a resume (or a page refresh)
    if (this.questionData && !this.questionData.chatMessages) {
      this.sneAnswerService?.getSneAssessmentRecords().subscribe(assessments => {

        let alreadyAnsweredQuestions = 0;
        let userAnswerQuestion1 = '';
        let userAnswerQuestion2 = '';
        let userAnswerQuestion3 = '';
        let userAnswerQuestion4 = '';
        let route = window.location.href;

        assessments.forEach(assessment => {
          if (assessment.assessmentHumanReadableID === 'CCH-Coaches' && route.includes('/coaches') ||
          assessment.assessmentHumanReadableID === 'CCH-Uncertainty' && route.includes('/uncertainty') ||
          assessment.assessmentHumanReadableID === 'CCH-Personal-Mistakes' && route.includes('/personal-mistakes')) {

            for (let item in assessment.answerJSON) {

              alreadyAnsweredQuestions++;

              if (item === 'item_1') {
                userAnswerQuestion1 = assessment.answerJSON[item].response;
              } else if (item === 'item_2') {
                userAnswerQuestion2 = assessment.answerJSON[item].response;
              } else if (item === 'item_3') {
                userAnswerQuestion3 = assessment.answerJSON[item].response;
              } else if (item === 'item_4') {
                userAnswerQuestion4 = assessment.answerJSON[item].response;
              }

              if (this.coachabilityData) {
                const multiChoiceQuestions = Array.from(this.coachabilityData.values()).filter(element => element.questionType === QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_CONVO);
                let questions = Array.from(multiChoiceQuestions);

                questions.forEach(question => {
                  if (question.submitId === item) {
                    question.singleMulChoiceOptions?.forEach(option => {
                      if (option.userLetter === userAnswerQuestion1) {
                        this.buildChat(option);
                      }
                    });
                  }
                });
              }
            }
          }
        });
      });
    }
  }


  ngOnInit(): void {
    this.processParams();
    if (this.questionData && !this.questionData.chatMessages) {
      if (this.coachabilityType === COACHABILITY_TYPES.COACHES) {
        let chat: ChatMessage = {
          user: 'Bill',
          message: 'Hey! What do you think of my idea? I think if we both back it, they’ll really need to think about it seriously.',
        };
        this.chatList.push(chat);
      }
      if (this.coachabilityType === COACHABILITY_TYPES.UNCERTAINTY) {
        let chat: ChatMessage = {
          user: 'Supervisor',
          message: 'We really need to address those issues that woman brought up at last month’s All Staff meeting—she made some great points. Can you make sure that it gets done?',
        };
        this.chatList.push(chat);
      }
      if (this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES) {
        let chat: ChatMessage = {
          user: 'Sam',
          message: 'Yikes, what now? I can’t believe we messed up those data! Do we have to tell Gail about it?',
        };
        this.chatList.push(chat);
      }
    }
    this.toggleHintModal();
    this.rebuildChatLogOnResume();
  }
}
