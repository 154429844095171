import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SNE_INPUT_TYPES, OcMadlibsBlanks, OcMadLibsSingleQuestionBlanks } from 'src/app/types/sne';

@Component({
  selector: 'ets-madlibs',
  templateUrl: './madlibs.component.html',
  styleUrls: ['./madlibs.component.scss']
})
export class MadlibsComponent implements OnInit{

  constructor() { }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  blankQuestionArray : string[] = [];
  selection: (string | number)[] = []
  answerClickedArray: boolean[] =[];
  blankAnswers: Map<number, string>= new Map();
  answersClicked: number=0;
  disableAnswer: any=false;
  disableAnswerArray: boolean[]= [];

  @Input() madlibsBlanks:  OcMadlibsBlanks[] | undefined;
  @Input() madLibsSingleBlanks: OcMadLibsSingleQuestionBlanks | undefined;
  @Input() headerQuestion: string | undefined = '';
  @Input() inputType: SNE_INPUT_TYPES | undefined = SNE_INPUT_TYPES.RADIO;
  @Input() title: string | undefined = '';
  @Input() subtitle: string | undefined = '';
  @Input() list: boolean = false;
  @Input() listAnswers: number | undefined;
  @Input() mobileCheck = false;

  @Output() answersClickedEvent = new EventEmitter<(boolean| number)[]>();
  @Output() selectBlankEvent = new EventEmitter<(string | number)[]>();

  ngOnInit(): void {
    if(!this.list){
      this.madlibsBlanks?.forEach(blank =>
        this.blankQuestionArray?.push(blank.prompt, '[blank]')
      );
    } else{
      this.answerClickedArray= new Array(this.madLibsSingleBlanks?.choices.length).fill(false);
      this.disableAnswerArray= new Array(this.madLibsSingleBlanks?.choices.length).fill(true);
      this.disableAnswer= this.disableAnswerArray;
      this.madLibsSingleBlanks?.prompts.forEach(blank => 
        this.blankQuestionArray.push(blank.prompt , '[blank]'));
    }
  }

  selectBlank(idx: number, option: string, letter: string) {
    this.selection = [idx, option, letter];
    this.selectBlankEvent.emit(this.selection);
    this.blankQuestionArray[1+(idx*2)] = option; // replace the odd index of each successive pair of (prompt, blanks)
    console.log('button clicked');
  }

  selectSingleAnswerBlank(idx: number, option: string, letter: string){
    this.selection = [idx, option, letter];
    this.selectBlankEvent.emit(this.selection);

    if(this.answerClickedArray[idx]){
      this.blankAnswers.delete(idx);
    } else{
      if(this.blankAnswers.size === this.blankQuestionArray.length/2){
        return;
      }
      console.log('map size: '+this.blankAnswers.size);
      this.blankAnswers.set(idx,option);
    }

    const blankIterator = this.blankAnswers.entries();
    for(let i=0; i< this.blankQuestionArray.length/2; i++){
      let value = blankIterator.next().value;
      console.log('map value: '+ value);
      if(value!== undefined){ // populate array with values from map
        this.blankQuestionArray[1+(i*2)] = value[1];
      } else{ // fill rest with "blank"
        this.blankQuestionArray[1+(i*2)] = '[blank]';
      }
    }

    this.answerClickedArray[idx]=!this.answerClickedArray[idx];
    this.answersClicked = (this.answerClickedArray.filter(x => x===true)).length;

  
    if( this.answersClicked< this.listAnswers!){
      this.disableAnswer= this.disableAnswerArray;
    } else{
      this.disableAnswer= this.answerClickedArray;
    }
    this.answersClickedEvent.emit(this.answerClickedArray);
  }

  isPromptText(element: string) {
    if(!this.list){
      return this.madlibsBlanks?.some(e => e.prompt === element);
    }else{
      return this.madLibsSingleBlanks?.prompts.some(e => e.prompt ===element);
    }
  }

  isBlankOption(element: string) {
    console.log('element: '+element);
    return (!this.isPromptText(element) && element !=='[blank]');
  }

}
