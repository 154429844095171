<div class="container">
  <div class="career-checklist-info">
    <div class="inner">
      <div class="left-side">
        <ets-progress-number [section]="'2'"></ets-progress-number>
        <div class="border"></div>
        <div class="checklist-content">
          <div class="next">
            <span>NEXT UP</span>
          </div>
          <div class="skill-label-title-wrapper">
            <span class="skill-label">Unvalidated Skill</span>
            <span class="title">{{notStartedAssessmentTitle}}</span>
          </div>
        </div>
      </div>
      <button class="mobile-start-quiz-button" (click)="navigateToAssessment()">
        <img src="assets/icons/greater-than-circle.svg" alt="chevron-right-submit"/>
      </button>
    </div>
  </div>
  <div *ngIf="notStartedAssessment?.status === 'In progress'">
    <button class="start-quiz-button" (click)="navigateToAssessment()">CONTINUE</button>
  </div>
  <div *ngIf="notStartedAssessment?.status === 'Not started'">
    <button class="start-quiz-button" (click)="navigateToAssessment()">START QUIZ</button>
  </div>
</div>
