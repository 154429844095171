<div class="container">
  <ets-split-screen-intro
    [title]="'Your Career Path'"
    [info]="info"
    [buttonText]="'Next Question'"
    [buttonColor]="APP_COLORS.uiaction"
    [mobileButtonSize]="'md'"
    [desktopImageSrc]="desktopImageSrc"
    [tabletImageSrc]="tabletImageSrc"
    [mobileImageSrc]="mobileImageSrc"
    [fullSectionBgImage]="true"
    (clickEvent)="onClick()"
    [isRoundedButton]="true"
  ></ets-split-screen-intro>
</div>