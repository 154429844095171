import { Component, OnInit } from '@angular/core';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Profile from 'src/app/types/profile';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FacetsService } from 'src/app/services/facets.service';
import { faMapSigns } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';
import { APP_COLORS } from 'src/global-styles';

@Component({
  selector: 'ets-facets-interstitial',
  templateUrl: './facets-interstitial.component.html',
  styleUrls: ['./facets-interstitial.component.scss']
})
export class FacetsInterstitialComponent implements OnInit {

  userProfile: Profile | undefined;
  sub!: Subscription;
  incompleteQuiz: boolean = false;
  faMapSigns = faMapSigns;

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  faArrowRight = faArrowRight;

  constructor(private store: Store, private router: Router, private facetsService: FacetsService, private eventService: EventService) { }

  nextPageSkillAssessment() {
    this.router.navigate(['/foundational-assessment/power-skills-assessment']);
  }

  ngOnInit(): void {
    document.title = 'Apprize | Power Skills';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Power Skills Interstitial Page',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Power Skills Interstitial Page', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Power Skills Interstitial Page', EventActionEnum.PAGE_VIEW, EventSectionEnum.POWER_SKILLS);
  }

}
