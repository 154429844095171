<div class="container" *ngIf="skillAssessment">
    <div class="skill-top-row"  [ngStyle]="skillAssessment.status !== 'Completed' && {'background-color': '#FAFAFA' } || {'background-color': rightBackgroundColor } " >
      <div class="image-wrapper">
        <img [src]="assessmentInfo.cardImg"
            [ngStyle]="{'background-color': leftBackgroundColor }"
            [ngClass]="{'gray-img': skillAssessment.status !== 'Completed'}"
        />
      </div>
      <div class="skill-desc-wrapper">
        <p class="skill-desc" [innerHTML]="desc"></p>
      </div>
    </div>
    <div class="skill-bottom-row">
        <div class="col-left">
            <span *ngIf="skillAssessment.status === 'Completed'" class="skill-status">
                Validated Skill
                <img [src]="blueCheck" alt="blue-check" class="blue-check">
            </span>
            <span *ngIf="skillAssessment.status !== 'Completed'" class="skill-status">
                Unvalidated Skill
                <img *ngIf="skillAssessment.status === 'In progress'" [src]="inProgress" alt="status-InProgress-icon" class="blue-check">
            </span>
            <h2 class="skill-title">{{ assessmentInfo.humanReadableName }}</h2>
            <span *ngIf="skillAssessment.status === 'Completed'" class="skill-completion-date">
                Completed {{ skillAssessment.completionTimestamp }}
            </span>
            <span *ngIf="skillAssessment.status !== 'Completed'" class="skill-completion-date">
                Approx. 30 min
            </span>
        </div>
        <div class="col-right">
            <!-- <button aria-label="open report">Open Report</button> -->
            <div class="button-wrapper">
                <ets-solid-button *ngIf="skillAssessment.status === 'Not started'" text="Start Quiz" [color]="inactiveBtn"
                (clickEvent)="clickTo(skillAssessment.status)">
                </ets-solid-button>
                <ets-solid-button *ngIf="skillAssessment.status === 'In progress'" text="Continue Quiz" [color]="inactiveBtn"
                (clickEvent)="clickTo(skillAssessment.status)">
                </ets-solid-button>
                <ets-outline-button  *ngIf="skillAssessment.status === 'Completed'" text="Open Report" [color]="inactiveBtn"
                (clickEvent)="clickTo(skillAssessment.status)">
                </ets-outline-button>
             </div>
        </div>
    </div>
</div>
