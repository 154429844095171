import SkillPill from 'src/app/types/skill-pill';

export let CONSCIENTIOUSNESS_VERSIONS: PersonalityTraitCardVersion[] = [
  {
    traitLevel: 1,
    traitCardTitle: 'Stargazer',
    traitCardDescription: 'You like to take things slowly. Though you may have a typical pace that allows for greater attention to detail, you might have trouble motivating yourself and completing tasks.'
  },
  {
    traitLevel: 2,
    traitCardTitle: 'Self-Starter',
    traitCardDescription: 'You like to take on responsibility and stay organized. You also prefer to balance work and play, but you might get a little stuck on one side or the other.'
  },
  {
    traitLevel: 3,
    traitCardTitle: 'Workhorse',
    traitCardDescription: 'You like working hard and setting lofty goals for yourself. You might be prone to working overtime—forgetting to pay attention to other important elements of your life.'
  }
];

export let TEMPERAMENT_VERSIONS: PersonalityTraitCardVersion[] = [
  {
    traitLevel: 1,
    traitCardTitle: 'Sensitive soul',
    traitCardDescription: 'You like to show that you care and are more in touch with your and others’ feelings than most. You may be prone to mood swings and worrying about things outside of your control.'
  },
  {
    traitLevel: 2,
    traitCardTitle: 'Stoic',
    traitCardDescription: 'You might experience ups and downs but prefer to keep them to yourself. You love finding resolutions on your own, and you dislike public speaking and being put in the spotlight.'
  },
  {
    traitLevel: 3,
    traitCardTitle: 'Anchor',
    traitCardDescription: 'You like to stay level-headed and keep your cool, even in stressful situations that fluster others. This might come across to some people as indifference.'
  }
];

export let APPROACHABLE_VERSIONS: PersonalityTraitCardVersion[] = [
  {
    traitLevel: 1,
    traitCardTitle: 'Lone wolf',
    traitCardDescription: 'You prefer to work independently and need time to recharge after socializing. You like to set and meet your own deadlines, but may have trouble working in groups.'
  },
  {
    traitLevel: 2,
    traitCardTitle: 'Chit-chatter',
    traitCardDescription: 'You enjoy conversing with colleagues but prefer to keep your guard up. This might make it hard for you to develop strong working relationships.'
  },
  {
    traitLevel: 3,
    traitCardTitle: 'Social butterfly',
    traitCardDescription: 'You enjoy being the life of the party—you like to make friends and meet new people. Your love of socializing might distract you from completing tasks.'
  }
];

export let AMIABLE_VERSIONS: PersonalityTraitCardVersion[] = [
  {
    traitLevel: 1,
    traitCardTitle: 'Soloist',
    traitCardDescription: 'You like to manage your own time and set your own priorities. You may be a little stubborn and might put your own needs and interests before those of others.'
  },
  {
    traitLevel: 2,
    traitCardTitle: 'Diplomat',
    traitCardDescription: 'You like collaborating with others and want everyone to succeed, but might be most comfortable working with people you know well. You may also have trouble working alone.'
  },
  {
    traitLevel: 3,
    traitCardTitle: 'First-mate',
    traitCardDescription: 'You like to see everyone happy and getting along, and enjoy supporting group projects. You may devote too much effort to the needs of others.'
  }
];

export let OPEN_MINDED_VERSIONS: PersonalityTraitCardVersion[] = [
  {
    traitLevel: 1,
    traitCardTitle: 'Traditionalist',
    traitCardDescription: 'You like doing things the way they’ve always been done, and enjoy going through tasks with a fine-tooth comb. You may get hung up on the status quo and feel resistant to change.'
  },
  {
    traitLevel: 2,
    traitCardTitle: 'Streamliner',
    traitCardDescription: 'You enjoy finding ways to improve things. You like ironing out the details but prefer others take the lead with big-picture ideas. You might take a back seat too easily.'
  },
  {
    traitLevel: 3,
    traitCardTitle: 'Life-long learner',
    traitCardDescription: 'You like to indulge your curiosity and think outside the box. You enjoy finding novel ways to solve problems, but might get distracted by all the possibilties you see.'
  }
];

export interface PersonalityTraitCardVersion {
  traitLevel: number;
  traitCardTitle: string;
  traitCardDescription: string;
}

export interface PersonalityTraits {
  id: number;
  rating: number;
  title: string;
  img: string;
  skillPills?: SkillPill[];
  personalityTraitCardVersion: PersonalityTraitCardVersion;
}
