import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Profile, { ProfileSkillsRequest } from '../types/profile';
import { environment } from '../../environments/environment';
import { LogObject } from '../types/log';
import { LogService } from './log.service';
import { BaseService } from './base.service';
import Question from '../types/question';
import { QUESTION_LIST_V2 } from '../data/sign-up-questions-v2';
@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService{

  // private profileUrl = 'https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/qa/profile';
  private profileUrl = environment.profileAPI;
  private stepUrl = environment.stepAPI;
  private writeLearnerSkillsSuffix = '/calculateMatchScore';
  private deleteUserSuffix = '/delete';
  questionList!: Question[];

  constructor(private http: HttpClient, protected logService: LogService, private router: Router) {
    super(logService);
    this.serviceName = 'Profile';
    this.questionList = QUESTION_LIST_V2;
  }

  getProfile(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      return this.http.get<HttpResponse<any>>(this.profileUrl, {headers: headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Profile: Get Learner Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        map(data => data),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  saveProfile(learner: Profile): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = {learner: learner};
      let requestLogObj: LogObject = {
        message: 'Profile: Save Learner Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(this.profileUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Profile: Save Learner Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Function to save a Learner's Skills to the DB
  saveProfileSkills(skillsRequest: ProfileSkillsRequest): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let writeSkillsUrl = this.stepUrl + this.writeLearnerSkillsSuffix;
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = skillsRequest;

      let requestLogObj: LogObject = {
        message: 'Profile: Save Skills Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(writeSkillsUrl, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Profile: Save Skills Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  // Function to delete a user's Profile from DB
  deleteUserProfile() : Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken) {
      let deleteProfileURL = this.profileUrl + this.deleteUserSuffix;
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = {};

      let requestLogObj: LogObject = {
        message: 'Profile: Delete Profile Request',
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(deleteProfileURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'Profile: Delete Profile Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
          localStorage.clear();
          window.location.reload();
        }),
        catchError(this.handleError),
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

}

