import { Component, EventEmitter, Input, Output } from '@angular/core';
import { APP_COLORS } from '../../../global-styles';
import { ModalInfo } from '../../types/sne';

@Component({
  selector: 'ets-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor() { }

  @Input() hint = false;
  @Input() showModal = false;
  @Input() title: string | undefined;
  @Input() list: ModalInfo[] | undefined = [];
  @Input() buttonText = 'Continue';
  @Input() info: string | undefined;
  @Input() submitModal?: boolean = false;

  @Output() modalClose = new EventEmitter<any>();
  @Output() currentPageOutput = new EventEmitter<any>();

  currentPage: number = 0;
  APP_COLORS = APP_COLORS;

  nextInstruction() {
    this.currentPage++;
    if (this.list && this.currentPage >= this.list.length) {
      this.currentPage = 0;
      this.modalClose.emit();
    }
    this.currentPageOutput.emit(this.currentPage);
  }

}
