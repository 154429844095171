import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CareerDetailService } from 'src/app/services/career-detail.service';
@Component({
  selector: 'ets-learn-more-modal',
  templateUrl: './learn-more-modal.component.html',
  styleUrls: ['./learn-more-modal.component.scss']
})

export class LearnMoreModalComponent implements OnInit, OnChanges {

  myForm = new FormGroup({
    linkedIninput: new FormControl('',
      [Validators.required,
        Validators.maxLength(256)]),
    phoneNumberInput: new FormControl('',
      [Validators.required,
        Validators.maxLength(10)]),
    startDateInput: new FormControl('',
      [Validators.required,
        Validators.maxLength(256)])
  });
  constructor( private careerDetailService: CareerDetailService) {
  }

  @Input() training: any;
  @Input() career: any;
  openBox = false;
  valueProp1: string = '';
  valueProp2: string = '';
  valueProp3: string = '';
  valueProp4: string = '';
  valuePropImage: string = '/assets/career-details/value-prop-icon.png';
  educationAndExperience: string[] = [];
  supplementalInfo: string[] = [];
  careerTitle= '';
  careerID = '';
  courseTitle = '';
  trainingProviderName = '';
  description = '';
  duration = '';
  program = '';
  cost = '';
  showModal = false;
  pageCounter = 1;
  courseRequirements = '';
  showLearnMoreModal = false;
  showAdditionalInfoModal = false;
  salaryRange: string = '';
  logo: string = '';
  sliderImg = '/assets/career-details/career-ready.png'; //default
  trainingExplanation = '';
  sections: any[] = [];
  offerTitle = '';
  schedule = '';
  startDateText = '';
  trainingType = '';
  linkedIn = true;
  startDate = true;
  phoneNumberField = true;
  toolTipSize = '';
  becomePrepared = `<br><b>Career Ready</b> courses are well-rounded and comprehensive, designed to make individuals with varying levels of experience employable and confident in a new career path. Both Career Intensive and Train to Hire programs fall into this category.<br><br>

  <b>Become a Specialist</b> courses allow individuals to hone in on a specific skillset. They can either prepare people who need to learn a few new skills for a particular career or help those looking to enhance their current capabilities. Certain types of Skill Specific programs (usually those that encompass multiple skills) fall into this category.<br><br>

  <b>Close a Skill Gap</b> courses allow individuals to refresh or learn a specific skill or discover more about a field of work without a significant financial investment or time commitment. Certain types of Skill Specific programs (usually those that focus on one skill) fall into this category.<br><br>

  <b>Expected Salary</b> is the estimated average salary for a full-time employee within a given career`;

  trainingTypeText = `<br><b>Career Intensive</b> programs prepare you to work within a specific field. Completing one of these programs should fully equip you with the skills you need to succeed, and graduates typically receive career services as they explore potential roles and employers.<br><br>

  <b>Train to Hire</b> programs prepare you for a specific role with a specific employer. The training features direct, on-the-job experience, and the program typically concludes when the employer formally hires the trainee.<br><br>

  <b>Skill Specific</b> programs can be highly targeted (i.e., certification in Google Analytics) or general (i.e. “Introduction to Digital Marketing”). They typically add to or enhance your current skillset, but are not comprehensive enough to fully prepare you for a new career.<br>`

  linkedInUrl : any;
  startDateMonth: any;
  phoneNumber : any;
  externalTpLink = '';


  ngOnInit() {
    this.trainingProviderName = this.training.training_provider;
    this.courseTitle = this.training.course_title;
    this.cost = this.training.course_price;
    this.duration = this.transformHyphenText(this.training.course_duration).replace(/-/g, ' ');
    this.duration = this.capitalizeEachWord(this.duration);

    this.startDateText = this.transformHyphenText(this.training.course_start_date).replace(/-/g, ' ');
    this.startDateText = this.capitalizeEachWord(this.startDateText);

    this.description = this.training.course_description;
    this.program = this.transformHyphenText(this.training.course_training_type);
    this.schedule = this.transformHyphenText(this.training.course_schedule);
    this.trainingType = this.transformHyphenText(this.training.course_training_type);

    this.courseRequirements = this.training.course_requirements;

    this.linkedIn = this.training.course_additional_requirements_linkedin;
    this.startDate = this.training.course_additional_requirements_start_date;
    this.phoneNumberField = this.training.course_additional_requirements_phone_number;
    this.valueProp1 = this.transformValuePropText(this.training.course_value_prop_1);
    this.valueProp2 = this.transformValuePropText(this.training.course_value_prop_2);
    this.valueProp3 = this.transformValuePropText(this.training.course_value_prop_3);
    this.valueProp4 = this.transformValuePropText(this.training.course_value_prop_4);
    this.externalTpLink = this.training.exception_link_to_training_provider_application;

    this.logo = this.training.training_provider_logo;

    this.parseExplanationText(this.training.course_requirements);

    this.buildBlueBox();
  }

  ngOnChanges() {
    this.salaryRange = this.career.salaryRange;
  }

  capitalizeEachWord(oldText: string) {
    return oldText.toLowerCase()
      .split(' ')
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  transformHyphenText(oldText: string){
    let newText = '';
    if (oldText === 'part-time'){
      newText = 'Part-Time';
    } else if (oldText === 'full-time'){
      newText = 'Full-Time';
    } else if (oldText === 'train-to-hire'){
      newText = 'Train-to-Hire';
    } else if (oldText === 'career-intensive') {
      newText = 'Career-Intensive';
    } else if (oldText === 'career-support-mentorship') {
      newText = 'Career support & mentorship';
    } else if (oldText === 'flexible-program-schedule') {
      newText = 'Flexible program & schedule';
    } else if (oldText === 'career-intensive') {
      newText = 'Career-Intensive';
    } else if (oldText === 'train-to-hire') {
      newText = 'Train-to-Hire';
    } else if (oldText === 'skill-specific') {
      newText = 'Skill-Specific';
    } else if (oldText === 'real-world') {
      newText = 'Real-World';
    } else if (oldText === 'top-tier') {
      newText = 'Top-Tier';
    } else {
      return oldText.charAt(0).toUpperCase() + oldText.slice(1); // capitalize first char
    }
    return newText;
  }

  transformValuePropText(oldText: string) {
    let newText = '';
    if (oldText === 'career-support-mentorship') {
      newText = 'Career support & mentorship';
    } else if (oldText === 'flexible-program-schedule') {
      newText = 'Flexible program & schedule';
    } else if (oldText === 'career-intensive') {
      newText = 'Career-Intensive';
    } else if (oldText === 'train-to-hire') {
      newText = 'Train-to-Hire';
    } else if (oldText === 'skill-specific') {
      newText = 'Skill-Specific';
    } else if (oldText === 'real-world') {
      newText = 'Real-World';
    } else if (oldText === 'top-tier') {
      newText = 'Top-Tier';
    } else {
      console.log(oldText);
      newText = oldText.replace(/-/g, ' ');
    };
    return newText;
  }

  parseExplanationText(originalText: string) {
    let newText = originalText.split('\n@');

    let newObj = newText.map(section => {
      let title = section.split('\n* ')[0].replace('@', '');
      if (title.charAt(0) === ' ') {
        title.substring(1);
      }
      let bullets = Array.from(section.split('\n* '));

      return {
        title,
        bullets
      };
    });
    this.sections = newObj;
  }

  buildBlueBox() {
    if (this.training.course_training_type === 'train-to-hire') {
      this.offerTitle = 'LEARN AT NO COST TO YOU';
      this.sliderImg = '/assets/career-details/career-ready.png';
      this.trainingExplanation = 'Free train to hire programs allow you to get hands on experience while getting paid for the work that you do.';
    } else if (this.training.course_training_type === 'career-intensive') {
      this.offerTitle = 'BECOME A QUALIFIED CANDIDATE';
      this.sliderImg = '/assets/career-details/career-ready.png';
      this.trainingExplanation = 'Learn the full range of skills you need to apply for and succeed in a particular role.';
    } else if (this.training.course_training_type === 'skill-specific') {
      this.offerTitle = 'HONE IN ON SPECIFICS';
      this.sliderImg = '/assets/career-details/close-a-skill-gap.png';
      this.trainingExplanation = 'Focus on a targeted set of skills to explore a new field and add to your resume.';
    }
  }

  additionalInfo(){
    this.showAdditionalInfoModal = true;
  }

  toggleModal() {
    if (!this.linkedIn && !this.startDate && !this.phoneNumberField){
      this.pageCounter = 2;
      this.showAdditionalInfoModal = !this.showAdditionalInfoModal;
    } else{
      this.showAdditionalInfoModal = !this.showAdditionalInfoModal;
    }
  }
  externalLinkLoad(){
    window.open(this.externalTpLink);
  }
  closeModal(){
    this.showAdditionalInfoModal = false;
  }


}
