import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProfileService } from '../../../services/profile.service';
import Profile from '../../../types/profile';
import { Store } from '@ngrx/store';
import { selectProfile } from '../../../store';
import { DESIRED_COMMUTE, DESIRED_SALARY_RANGE, OPEN_TO_REMOTE_WORK, WORK_HOURS_PER_WEEK } from './work-preferences.helpers';
import { HttpResponse } from '@angular/common/http';
import { setProfile } from '../../../store/profile.actions';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/app/services/event.service';
import { EventActionEnum, EventSectionEnum } from 'src/app/types/event';

@Component({
  selector: 'ets-work-preferences',
  templateUrl: './work-preferences.component.html',
  styleUrls: ['./work-preferences.component.scss']
})
export class WorkPreferencesComponent implements OnInit {

  constructor(private learnerService: ProfileService, private store: Store, private eventService: EventService) { }

  getErrorMessage: string = '';
  postErrorMessage: string = '';
  sub!: Subscription;
  userProfile!: Profile;
  workPreferenceForm = new FormGroup({
    zipCode: new FormControl(''),
    desiredRemoteWork: new FormControl(''),
    desiredCommute: new FormControl(''),
    desiredSalary: new FormControl(''),
    desiredWorkHoursPerWeek: new FormControl(''),
  });

  OPEN_TO_REMOTE_WORK = OPEN_TO_REMOTE_WORK;
  DESIRED_COMMUTE = DESIRED_COMMUTE;
  DESIRED_SALARY_RANGE = DESIRED_SALARY_RANGE;
  WORK_HOURS_PER_WEEK = WORK_HOURS_PER_WEEK;

  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
        this.setInitialFormValues();
      }
    });
  }

  ngOnInit(): void {
    document.title = 'Apprize | User Profile';
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'Work Preferences',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'Work Preferences', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Work Preferences', EventActionEnum.PAGE_VIEW, EventSectionEnum.USER_PROFILE);

    this.getProfile();
  }

  setInitialFormValues(): void {
    if (this.userProfile) {
      this.workPreferenceForm.controls.zipCode.setValue(this.userProfile.zipCode);
      this.workPreferenceForm.controls.desiredRemoteWork.setValue(this.userProfile.desiredRemoteWork);
      this.workPreferenceForm.controls.desiredCommute.setValue(this.userProfile.desiredCommute);
      this.workPreferenceForm.controls.desiredSalary.setValue(this.userProfile.desiredSalary);
      this.workPreferenceForm.controls.desiredWorkHoursPerWeek.setValue(this.userProfile.desiredWorkHoursPerWeek);
    }
  }

  updateProfile(savedProfile: Profile): void {
    savedProfile.zipCode = this.workPreferenceForm.controls.zipCode.value;
    savedProfile.desiredRemoteWork = this.workPreferenceForm.controls.desiredRemoteWork.value;
    savedProfile.desiredCommute = this.workPreferenceForm.controls.desiredCommute.value;
    savedProfile.desiredSalary = this.workPreferenceForm.controls.desiredSalary.value;
    savedProfile.desiredWorkHoursPerWeek = this.workPreferenceForm.controls.desiredWorkHoursPerWeek.value;
  }

  saveChanges(): void {
    // Populate all the fields on the learner object from the form group
    if (this.userProfile) {
      let newProfile: Profile = {...this.userProfile};
      this.updateProfile(newProfile);
      this.sub = this.learnerService.saveProfile(newProfile)
        .subscribe((response: HttpResponse<any>) => {
          const profile = response.body ? response.body.learner : undefined;
          // Commenting this out for now until the backend sends back the expected response
          // if (profile) {
          this.store.dispatch(setProfile({payload: newProfile}));
        // }
        });
    }
  }
}
