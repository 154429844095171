<div class="container">
  <span class="header-question" *ngIf="headerQuestion" [innerHTML]="headerQuestion"> {{headerQuestion}} </span>
  <div [ngClass]="{
      'MC-box': mulChoiceOptions?.length !== 1
    }"
    *ngFor="let option of mulChoiceOptions; let optionIndex = index"
  >
    <div class="MC-question"
      [ngClass]="{ 
        'question-margin': optionIndex !== 0,
        'final-mobile-MC-question': optionIndex+1 === mulChoiceOptions?.length
      }"
    >
      <span class="question" [innerHTML]="option.question"></span>
      <div class="marquis-container" *ngIf="option.marquisClassification">
        <img class="marquis-img" src="/assets/sne/cps/marquis-avatar.png" alt="Marquis" />
        <span class="marquis-text" >Marquis' Classification: {{option.marquisClassification.userOption}}</span>
      </div>
      <div class="MC-choices">
        <div *ngIf="inputType === SNE_INPUT_TYPES.RADIO">
          <div *ngFor="let mulOption of option.mulChoiceOptions; let mulOptionIndex = index">
            <div class="option" *ngIf="mulOption && mulOption?.userOption">
              <ets-radio-button
                (clickEvent)="selectSingleResponse(optionIndex, mulOption?.userLetter, option?.marquisClassification?.userLetter)"
                [value]="mulOption?.userLetter"
                [name]="option.question + optionIndex"
                [id]="mobileCheck ? optionIndex + '' + mulOptionIndex + '-mobile' : optionIndex + '' + mulOptionIndex"
                [label]="mulOption?.userOption"
                [disabled]="disabledRadioBtn(mulOption?.userLetter)"
                [fontWeight]="questionIndex === 4 ? 'normal' : 'bold'"
              >
              </ets-radio-button>
            </div>
            <!-- Below div handles dropdown for CPS -->
            <div *ngIf="mulOption?.userOption === 'Disagree' && option.isDisagree">
              <select (change)="selectDropdownAnswer($event, optionIndex)" [id]="optionIndex + '' + mulOptionIndex" class="inner-box-dropdown" name="disagree-dropdown">
                <option class="inner-box-dropdown-options" value="?">Select New Answer</option>
                <option class="inner-box-dropdown-options" *ngFor="let classification of filteredClassifications?.[optionIndex]" [value]="classification.userLetter">{{classification.userOption}}</option>
              </select>
            </div>
            <!-- Above div handles dropdown for CPS -->
          </div>
        </div>
        <div *ngIf="inputType === SNE_INPUT_TYPES.CHECKBOX">
          <div *ngFor="let mulOption of option.mulChoiceOptions; let mulOptionIndex = index">
            <div class="option" *ngIf="mulOption && mulOption?.userOption">
              <ets-checkbox
                (clickEvent)="selectMultiResponse(mulOption?.userLetter)"
                [value]="mulOption?.userLetter"
                [name]="option.question + optionIndex"
                [id]="mobileCheck ? optionIndex + '' + mulOptionIndex + '-mobile' : optionIndex + '' + mulOptionIndex"
                [label]="mulOption?.userOption"
              >
              </ets-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
