import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ProfileService } from 'src/app/services/profile.service';
import { EventService } from 'src/app/services/event.service';
import { FileManagerService } from 'src/app/services/filemanager.service';
import { setProfile } from 'src/app/store/profile.actions';


@Component({
  selector: 'ets-resume-attachment',
  templateUrl: './resume-attachment.component.html',
  styleUrls: ['./resume-attachment.component.scss']
})
export class ResumeAttachmentComponent extends OnBoardingV2Base implements OnInit, OnDestroy {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
    protected profileService: ProfileService, eventService: EventService, private fileManagerService: FileManagerService) {
    super(store, router, route, profileService, eventService);
  };

  file = false;
  disableButton: boolean = true;
  fileTypeError: boolean = false;
  sizeError: boolean =false;
  fileTitle: string = '';
  fileSize: string = '0';
  fileSizeLimit: number = 5;

  async onFileChange(event: any) {
    let file = event.target.files[0];
    this.file = true;
    this.fileTypeError = false;
    this.sizeError = false;
    this.fileTitle = file.name;
    this.fileSize = (file.size / (1024 ** 2)).toFixed(2); //bytes to MegaBytes


    console.log(this.fileSize);

    // (+file.size) < this.fileSizeLimit


    if (file.type === 'application/pdf' && (+this.fileSize) < this.fileSizeLimit ){
      this.fileTypeError = false;
      this.sizeError = false;
      this.fileManagerService.calculateUploadUrl(file.name,file.type).subscribe(async (fileManagerResponse: { folder: any; uploadURL: any; }) => {
        console.log('fileManagerResponse', fileManagerResponse);
        const folder = fileManagerResponse.folder;
        const newFile = new File([file], folder + '/'+file.name, {type: file.type});

        console.log('file size: '+ file.size);
        const response = await fetch(fileManagerResponse.uploadURL,{
          method: 'PUT',
          headers: new Headers({'Content-Type': 'application/pdf'}),
          body: newFile
        });
        console.log('response', response);
      });
      this.disableButton = false;
    } else {
      if((+this.fileSize) > this.fileSizeLimit){
        this.sizeError = true;
      } else{
        // not a PDF
        this.fileTypeError = true;
      }
    }
  }

  delete() {
    this.file = false;
    this.fileTypeError = false;
    this.sizeError = false;
    this.fileManagerService.deleteFile(this.fileTitle).subscribe(response => {
      console.log(response);
      this.disableButton = true;
    });
  }

  viewMyCareers() {
    this.router.navigate(['/career-suggestions']);
  }

  ngOnInit() {
    this.initialize();
    this.fileManagerService.listFiles().subscribe( response => {
      const fileFound = response.find((file: string) => !!file);

      if (fileFound) {
        this.file = !!fileFound;
        this.fileTitle = fileFound;
      }
    });
  }

  ngOnDestroy(): void {
    // Update lastPageVisited and onboarding complete flag
    super.updateState();
    this.profileService.saveProfile({...this.userProfile})
      .subscribe(profile => {
        if (profile) {
          this.store.dispatch(setProfile({payload: profile}));
        }
      });
  }
}
