export const WORK_HOURS_PER_WEEK = [
  'Part-time',
  'Full-time'
];

export const OPEN_TO_REMOTE_WORK = [
  'Yes',
  'No',
  'Remote only'
];

export const DESIRED_COMMUTE = [
  'Less than 15 minutes',
  'Up to 30 minutes',
  'Up to one hour',
  'Up to two hours'
];

export const DESIRED_SALARY_RANGE = [
  '$25,000-$50,000',
  '$50,000-$75,000',
  '$75,000-$100,000',
  'Over $100,000',
];
