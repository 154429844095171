<div class="container" *ngIf="currentQuestion">
  <div class="inner">
    <h1 class="main-question">{{currentQuestion.question}}</h1>
    <form class="form" [formGroup]="eduForm" novalidate>
      <div class="edu-container vocational-training" *ngFor="let edu of userEduList; let index = index">
        <div class="section-container">
          <div class="section-box">
            <img class="image-icon" src="../../../../assets/on-boarding/Vocational_Training.svg" alt="VT image">
            <div class="content-box">
              <span class="question">{{edu}}</span>
              <h2 class="subtext" *ngIf="currentQuestion.info">{{currentQuestion.info}}</h2>
              <div class="form-group">
                <input class="form-control"  type="text" [formControlName]="_.camelCase(edu)"
                  placeholder="{{currentQuestion.placeholder}}" 
                  [id]="_.camelCase(edu) + '-input'"
                  [ngClass]="{
                    'has-success': eduForm.controls[_.camelCase(edu)].touched,
                    'has-danger': eduForm.controls[_.camelCase(edu)].invalid
                      && (eduForm.controls[_.camelCase(edu)].dirty || eduForm.controls[_.camelCase(edu)].touched)
                  }"
                />
                <div class="typeahead-skills-container" *ngIf="showTypeahead(edu)">
                  <ul class="typeahead-skill-list" *ngFor='let typeaheadMajor of typeaheadMajorsMap.get(_.camelCase(edu))'>
                    <li class="typeahead-skills" (click)='toggleSelectedTypeaheadMajor(_.camelCase(edu), typeaheadMajor)'>{{typeaheadMajor}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="status-container">
          <span class="toggle-option">Currently Studying</span>
          <label [for]="_.camelCase(edu)+'Studying'" class="toggle-label">Currently Studying Toggle</label>
          <clr-toggle-container>
            <clr-toggle-wrapper>
              <input type="checkbox" clrToggle [name]="_.camelCase(edu)+'Studying'"
                     [formControlName]="_.camelCase(edu)+'Studying'"
              />
            </clr-toggle-wrapper>
          </clr-toggle-container>
          <span class="toggle-option">Completed</span>
        </div>
        <p class="form-control-feedback"
           *ngIf="eduForm.controls[_.camelCase(edu)].invalid
             && (eduForm.controls[_.camelCase(edu)].dirty || eduForm.controls[_.camelCase(edu)].touched)"
        >
          {{error}}
        </p>
        <div *ngIf="index !== userEduList.length - 1" class="border-line"></div>
      </div>
    </form>
    <div class="button-wrapper">
      <ets-rounded-contained-button style="width: 225px; z-index: 1000;" type="button" [fullWidth]="true"
                                    [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'NEXT QUESTION'"
                                    (click)="triggerNextQuestion()" [disabled]="disableSubmit()">
      </ets-rounded-contained-button>
    </div>
  </div>
</div>
