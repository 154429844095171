<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>
<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
  [sneSection]="'left-oc'"
>
  <div class="left">
    <ets-sne-ques-video
      [title]="questionData?.headerVideo?.title"
      [mainHeader]="questionData?.headerVideo?.mainHeader"
      [description]="questionData?.headerVideo?.description"
      [videoUrl]="questionData?.headerVideo?.url"
      [videoId]="questionData?.headerVideo?.id"
      [playOnce]="questionData?.headerVideo?.playOnce"
      [sharedVideo]="questionData?.sharedVideo"
    >
    </ets-sne-ques-video>
  </div>
  <div class="right">
    <ets-sne-multi-choice-list
      [headerQuestion]="questionData?.header"
      [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
      (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
      [inputType]="questionData?.inputType"
    >
    </ets-sne-multi-choice-list>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="disableNextButton()"
>
  <ets-sne-multi-choice-list
    [headerQuestion]="questionData?.header"
    [mulChoiceOptions]="questionData?.multipleMulChoiceOptions"
    (selectSingleResponseEvent)="selectAnswer($event.questionIndex, $event.answer)"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-sne-multi-choice-list>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video *ngIf="instructionModal?.customContent[0].url"
                   [videoUrl]="instructionModal?.customContent[0].url"
                   [videoId]="instructionModal?.customContent[0].id"
                   [videoOnlyFlag]="true">
    </ets-sne-video>
  </div>
</ets-modal>
