<div
  class="career-card career-wrapper"
  *ngIf="careerTitle && matchScore; else noCareerCard"
>
  <div class="image-wrapper">
    <img class="career-img"
         src="https://assets.apprize.com/Careers/Narrow/{{careerTitle}}_narrow_%403x.png"
    />
  </div>
  <div class="mid-career-wrapper">
    <h3 class="career-name">{{careerTitle}}</h3>
    <div class="percent-outer-circle">
      <!-- https://swimlane.gitbook.io/ngx-charts/examples/pie-charts/pie-chart -->
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="colorScheme"
        [results]="results"
        [gradient]="isGradient"
        [tooltipDisabled]="disableTooltip"
        [doughnut]="isDoughnut"
        [arcWidth]="arcWidth"
      >
      </ngx-charts-pie-chart>
      <div class="percent-inner-circle">
        <span class="percentage">{{matchScore}}%</span>
      </div>
    </div>
  </div>
<!--  <button class="review-deets" (click)="onClick()">Review Details</button>-->
  <div class="button-wrapper">
    <ets-solid-button [padding]="'6px'"
                      [text]="'Review Details'"
                      [color]="APP_COLORS.uiaction"
                      [buttonMaxWidth]="true"
                      (clickEvent)="onClick()"
    >
    </ets-solid-button>
  </div>
</div>

<ng-template #noCareerCard>
  <!-- Still have more to do with this new anchor tag in place -->
  <a class="anchor-wrapper" [routerLink]="['/career-suggestions']">
    <div class="no-career-card career-wrapper">
      <img class="dotted-plus" src="./assets/dashboard/dotted-plus-icon.svg" />
      <p class="find-careers-text">Find careers that fit</p>
      <a class="explore-href" href="">EXPLORE</a>
    </div>
  </a>
</ng-template>
