


  <div class="modal-title header-title">
      <span class="title-1" *ngIf="pageCounter === 1 && (linkedIn || startDate)">This training provider requires additional information</span>
      <span class="title-2" *ngIf="pageCounter === 2">We value your privacy</span>
  </div>
  <div class="modal-body" *ngIf="pageCounter === 1 && (linkedIn || startDate || phoneNumberField)">
      <div class="para-wrapper">
      <span class="para-1">
          Some training providers require addtional information for their review process. Please
          provide the following information to continue.
      </span>
  </div>
      <h4 class="linkedin-title" *ngIf="linkedIn">LinkedIn Profile URL</h4>
      <form class="form" [formGroup]="myForm" novalidate>
          <div class="linkedIn-box" *ngIf="linkedIn">
              <input class="inner-box-linkedIn"
                     type="text"
                     placeholder="Example: www.linkedin.com/username123"
                     formControlName="linkedIninput"
                     [ngClass]="{
                      'has-success': myForm.controls.linkedIninput.touched,
                      'has-danger': myForm.controls.linkedIninput.invalid && (myForm.controls.linkedIninput.dirty || myForm.controls.linkedIninput.touched)}"
                      />
          </div>
          </form>
      <h4 class="drop-down-title" *ngIf="startDate">Earliest Start Date</h4>

      <div class="text-wrapper" *ngIf="startDate">
      <form class="drop-down" [formGroup]="myForm" novalidate>
          <select class="inner-box-drop-down"
              formControlName="startDateInput"
              [ngClass]="{
                  'has-success': myForm.controls.startDateInput.touched,
                  'has-danger': myForm.controls.startDateInput.invalid && (myForm.controls.startDateInput.dirty || myForm.controls.startDateInput.touched)}"
          >
          <option value=""disabled selected>Month</option>
          <option value="{{month1 + ' ' + year1}}">{{month1 + ' ' + year1}}</option>
          <option value="{{month1 + ' ' + year1}}">{{month2 + ' ' + year2}}</option>
          <option value="{{month1 + ' ' + year1}}">{{month3 + ' ' + year3}}</option>
      </select>
      </form>
  </div>
  <h4 class="phone-number-title" *ngIf="phoneNumberField">Phone Number</h4>
  <form class="form" [formGroup]="myForm" novalidate>
      <div class="phone-number-box" *ngIf="phoneNumberField">
          <input class="inner-box-phone-number"
                 type="text"
                 placeholder="(123)-456-7890"
                 formControlName="phoneNumberInput"
                 [ngClass]="{
                  'has-success': myForm.controls.phoneNumberInput.touched,
                  'has-danger': myForm.controls.phoneNumberInput.invalid && (myForm.controls.phoneNumberInput.dirty || myForm.controls.phoneNumberInput.touched)}"
                  />
      </div>
      </form>
  </div>
  <div class="modal-body" *ngIf="pageCounter === 2">
      <div class="para-wrapper">
      <span class="para-2" span role="text" aria-live="assertive" attr.aria-label="By selecting 'Agree', you consent to sharing the following additional data from Mind kind to {{this.trainingProviderName}}">
        By selecting "Agree", you consent to sharing the following additional data from Apprize to {{this.trainingProviderName}}.
      </span>
  </div>
      <div class="content-box">
          <div class="bullet-list">
              <ul>
                  <li>Demographic Information (Name, Age, Gender Identity, Zip Code)</li>
                  <li>Contact Information (Email)</li>
                  <li>Education & Job History</li>
                  <li>Skills (Quiz Results)</li>
              </ul>
          </div>
      </div>

      <div class = "end-statement-wrapper">
      <span class="end-statement">
        You also agree to the <a href="https://www.ets.org/legal/privacy"> ETS Privacy Policy</a> and <a href={{this.privacyPolicyURL}}> {{this.trainingProviderName}} Policy </a>.
      </span>
    </div>
  </div>
  <div class="modal-body" *ngIf="pageCounter === 3">
      <div class="content-images-wrapper">
          <img class="check-mark" src="../../assets/GreenCheckMark.svg" alt="green check mark">
          <h5 class="last-modal-title">Your information is under review for</h5>
          <div class="small-content-box">
              <span class="training-provider-title">{{this.courseTitle}}</span>
              <span class="course-title-title">{{this.trainingProviderName}}</span>
          </div>
          <p>
              We’ll be reviewing your information shortly to determine your eligibility. You can expect to receive an
              email containing the next steps within 7 business days. 
          </p>
          <!-- For additional information, please contact
              <a href="mailto:support@mindkind.com">support@mindkind.com</a>. -->
      </div>
  </div>
  <div class="modal-footer overview-footer">
      <div class="modal-footer-wrapper" *ngIf="pageCounter === 1 && (linkedIn || startDate || phoneNumberField)">
          <button class="back-button" (click)="closeModal()">
              BACK
          </button>
          <button class="continue-button" (click)="toggleModalNext()" [disabled]="disableSubmit()">
              CONTINUE
          </button>
      </div>
      <div class="modal-footer-wrapper" *ngIf="pageCounter === 2">
          <button class="back-button" (click)="prevModal()">
              BACK
          </button>
          <button class="continue-button" (click)="agreeBtn()">
              AGREE
          </button>
      </div>
      <div class="modal-footer-wrapper-last" *ngIf="pageCounter === 3">
          <button class="close-button" (click)="closeModal()">
              CLOSE
          </button>
      </div>
</div>
