import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_COLORS } from 'src/global-styles';
import { SneCoachabilityBase } from '../sne-coachability.base';
import { HEADER_STATES } from '../../../shared/header/header.helper';
import { Email, SneMulChoiceOption } from '../../../types/sne';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import QUESTION_TYPES from 'src/app/types/question-types';
import { SNE_INPUT_TYPES } from '../../../types/sne';
import { SneAnswerService } from '../../../services/sne-answer.service';

@Component({
  selector: 'ets-coachability-multiple-select-email',
  templateUrl: './coachability-multiple-select-email.component.html',
  styleUrls: ['./coachability-multiple-select-email.component.scss']
})
export class CoachabilityMultipleSelectEmailComponent extends SneCoachabilityBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  emailList: Email[] = [];
  responseList: string[] = [];
  showHintModal = false;
  showModal = false;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;

  toggleModal() {
    this.showModal=!this.showModal;
  }
  toggleHintModal(){
    if(this.questionData?.hintModalFlag === true){
      this.showHintModal=!this.showHintModal;
    }
    //this.showHintModal = true;
  }
  additionalNextQuestionSteps = () => {
    this.responseList.push(this.singleResponse);
    //this.generateChatList();
  }

  singleResponseHandler(answer:string) {
    //change event name
    this.singleResponse = answer;
  }
  
  multiResponseHandler(response: string) {
    let arrIndex = this.multiResponse.indexOf(response);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(response);
      this.multiResponse.sort();
    }

  }

  generateChatList() {
    if (this.coachabilityData) {
      const multiChoiceQuestions = Array.from(this.coachabilityData.values()).filter(element => element.questionType === QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL);
      this.emailList = [];
      this.responseList.forEach((response, index) => {
        const option = multiChoiceQuestions[index].singleMulChoiceOptions?.find(question => question.userLetter === response);

        if (option && option.userOption) {
          if (this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES) {
            this.emailList.push({
              from: 'You',
              to: 'Sam',
              emailSubject: 'Email headline goes here',
              image: '/assets/sne/negotiation-you-profile-pic.png',
              body: option.userOption
            });
          }
        }
        if (option && option.emailResponse) {
          this.emailList.push(option.emailResponse);
        }

      });
    }
  }

  buildChat(option: SneMulChoiceOption){
    if(option.userLetter){
      this.responseList.push(option.userLetter);
    }

    let email: Email = {
      from: 'You',
      to: option?.emailResponse?.from,
      emailSubject: 'Email headline goes here', //TODO?
      image: '/assets/sne/negotiation-you-profile-pic.png',
      body: option.userOption
    };
    this.emailList.push(email);

    if(option.emailResponse){
      let emailResponse: Email = {
        from: option?.emailResponse?.from,
        to: option?.emailResponse?.to,
        emailSubject: 'Email headline goes here',  //TODO?
        image: '/assets/sne/negotiation-you-profile-pic.png',
        body: option?.emailResponse?.body
      };
      this.emailList.push(emailResponse);
    }
  }

  rebuildChatLogOnResume() {
    //rebuild email history if coming from a resume (or a page refresh)
    this.sneAnswerService?.getSneAssessmentRecords().subscribe(assessments => {

      let alreadyAnsweredQuestions = 0;
      let userAnswerQuestion1 = '';
      let userAnswerQuestion2 = '';
      let userAnswerQuestion3 = '';
      let userAnswerQuestion4 = '';

      assessments.forEach(assessment => {
        if (assessment.assessmentHumanReadableID === 'CCH-Mistakes') {
          for (let item in assessment.answerJSON){

            alreadyAnsweredQuestions++;

            if (item === 'item_1') {
              userAnswerQuestion1 = assessment.answerJSON[item].response;
            } else if (item ==='item_2'){
              userAnswerQuestion2 = assessment.answerJSON[item].response;
            } else if (item === 'item_3'){
              userAnswerQuestion3 = assessment.answerJSON[item].response;
            } else if (item === 'item_4'){
              userAnswerQuestion4 = assessment.answerJSON[item].response;
            }

            if (this.coachabilityData) {
              const multiChoiceQuestions = Array.from(this.coachabilityData.values()).filter(element => element.questionType === QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL);
              let questions = Array.from(multiChoiceQuestions);

              questions.forEach(question => {
                if(question.submitId === item){
                  question.singleMulChoiceOptions?.forEach(option => {
                    if (option.userLetter === userAnswerQuestion1) {
                      this.buildChat(option);
                    }
                  });
                }
              });
            }
          }
        }
      });
    });
  }

  ngOnInit() {
    this.processParams();
    if (this.coachabilityType === COACHABILITY_TYPES.PERSONAL_MISTAKES) {
      this.emailList.push({
        to: 'You',
        from: 'Gail',
        emailSubject: 'Re: Topic suggestions for new children\’s game',
        image: '/assets/sne/coachability-gail-profile-pic.png',
        body: 'Could you explain how you and Sam arrived at the chemistry and biography numbers? I want to understand how we got there and if there is anything we can do differently in the future to avoid these types of mistakes. Thanks!'
      });
    }
    if (this.questionData && this.questionData.email) {
      if (this.coachabilityType === COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES) {
        this.emailList.push(this.questionData.email);
      }
    }
    this.toggleHintModal();
    this.rebuildChatLogOnResume();
  }
}
