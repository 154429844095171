<ets-header [isBGWhite]="true"
            [headerState]="headerState"
            [showBackButton]="false"
            [simpleBack] = "true"
>
</ets-header>

<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="right">
    <!-- Virtual Teammate Box Component -->
    <div class="chat-wrapper">
      <ets-sne-chatbot-messages [botMessage]="botMessage"></ets-sne-chatbot-messages>
    </div>
    <!-- Hidden Profile Menu Component -->
    <ets-sne-hp-menu
      (requestInfoBtnClick)="setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.SHARE_FACT_PROMPT))"
      (requestAInfoBtnClick)="generateUserRequestVendorOption(VENDOR_SET.A)"
      (requestBInfoBtnClick)="generateUserRequestVendorOption(VENDOR_SET.B)"
      (helpfulInfoUserResponseClick)="finalizeUserRequestVendorOption($event)"
      [disableRequestAInfoBtn]="disableRequestVendorInfoBtn(VENDOR_SET.A)"
      [disableRequestBInfoBtn]="disableRequestVendorInfoBtn(VENDOR_SET.B)"
      (shareInfoBtnClick)="generateBotShareVendorOptions()"
      [shareInfoVendorAFactList]="vendorAUserKnownList"
      [shareInfoVendorBFactList]="vendorBUserKnownList"
      (pickVendorABtnClick)="pickVendor(VENDOR_SET.A)"
      (pickVendorBBtnClick)="pickVendor(VENDOR_SET.B)"
      (shareUserSelectedFactWithBot)="finalizeBotShareVendorOption($event)"
      (continueToMainMenu)="setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.CLOSER_TO_SELECTION))"
      (returnToMenuBtnClick)="setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.MENU))"
      (pickVendorBtnClick)="initiateVendorPick()"
      (confirmVendorPickBtnClick)="confirmVendorSelection()"
      (reconsiderVendorPickBtnClick)="reconsiderVendorPick()"
    >
    </ets-sne-hp-menu>
  </div>
  <div class="left">
     <ets-fact-card-list [vendorFactsListA]="vendorAList" [vendorFactsListB]="vendorBList"></ets-fact-card-list>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [moduleSize]="moduleSize"
  [overviewEnabled]="true"
  [nextEnabled]="false"
  [isButtonDisabled]="true"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [buttonText]="questionData?.buttonText"
>
  <div class="chat-wrapper">
    <ets-sne-chatbot-messages [botMessage]="botMessage"></ets-sne-chatbot-messages>
  </div>
  <ets-sne-hp-menu
    (requestInfoBtnClick)="setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.SHARE_FACT_PROMPT))"
    (requestAInfoBtnClick)="generateUserRequestVendorOption(VENDOR_SET.A)"
    (requestBInfoBtnClick)="generateUserRequestVendorOption(VENDOR_SET.B)"
    (helpfulInfoUserResponseClick)="finalizeUserRequestVendorOption($event)"
    [disableRequestAInfoBtn]="disableRequestVendorInfoBtn(VENDOR_SET.A)"
    [disableRequestBInfoBtn]="disableRequestVendorInfoBtn(VENDOR_SET.B)"
    (shareInfoBtnClick)="generateBotShareVendorOptions()"
    [shareInfoVendorAFactList]="vendorAUserKnownList"
    [shareInfoVendorBFactList]="vendorBUserKnownList"
    (pickVendorABtnClick)="pickVendor(VENDOR_SET.A)"
    (pickVendorBBtnClick)="pickVendor(VENDOR_SET.B)"
    (shareUserSelectedFactWithBot)="finalizeBotShareVendorOption($event)"
    (continueToMainMenu)="setBotMessage(HPBotStatements.get(HP_BOT_MESSAGES_KEYS.CLOSER_TO_SELECTION))"
    (pickVendorBtnClick)="initiateVendorPick()"
    (confirmVendorPickBtnClick)="confirmVendorSelection()"
    (reconsiderVendorPickBtnClick)="reconsiderVendorPick()"
  >
  </ets-sne-hp-menu>
</ets-sne-progress-bar>


<!-- Overview Modal -->
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div class="classifications-container" *ngIf="instructionModal?.customContent" contentID="customContent">
    <div *ngFor="let classification of instructionModal?.customContent; let idx = index">
      <div class="text-wrapper">
        <span class="classification-header">{{ classification.header }}</span>
        <p class="classification-info">{{ classification.info }}</p>
      </div>
      <hr style="color: #ccc;" *ngIf="instructionModal?.customContent.length !== idx + 1" />
    </div>
  </div>
</ets-modal>