import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { APP_COLORS } from 'src/global-styles';
import { SneWrittenCommunicationBase } from '../sne-written-communication.base';
import { HEADER_STATES } from '../../../shared/header/header.helper';

@Component({
  selector: 'ets-wc-text-free-response',
  templateUrl: './wc-text-free-response.component.html',
  styleUrls: ['./wc-text-free-response.component.scss']
})
export class WcTextFreeResponseComponent extends SneWrittenCommunicationBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;

  ngOnInit(): void {
    this.processParams();
  }

}
