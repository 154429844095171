import { SneModal, SNE_INPUT_TYPES, SneQuestion } from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import OC_TYPES from 'src/app/types/oral-communication-types';

export const OCInformative2Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Presenting to Clients',
      info: 'This task will give you insight into how you apply best practices for speaking with clients, and we will use what we learn together to enhance your Skills Profile.<br><br>You will play one or more videos and answer corresponding questions.  In each video, pay close attention to <span class="convertToBlue">what</span> the speakers say, <span class="convertToBlue">why</span> they say it, and <span class="convertToBlue">how</span> they say it, including verbal and nonverbal communication. ',
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and you will have a chance to pause when you finish.',
      questionIndex: 2,
      moduleSize: 6,
      durationBoxColor: 'mangotango',
      image: '/assets/section-4/sne-interstitial/informative-2.svg', 
      buttonText: 'Next',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      splitScreen: false,
      header:
        'Which of the following is an example of language that would most likely <span class=\'convertToBlue\'>not</span> be used with a client?',
      videos: [
        {
          url: 'https://vimeo.com/617395724',
          id: '617395724',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617395660',
          id: '617395660',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617395630',
          id: '617395630',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617395612',
          id: '617395612',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL,
      inputType: SNE_INPUT_TYPES.RADIO,
      splitScreen: false,
      header:
        'What part of the video contains the most helpful information if a client has a concern about their service?',
      headerVideo: {
        url: 'https://vimeo.com/605595851',
        id: '605595851',
        title: 'Identifying Appropriate Language',
        description: 'Please classify each of the facts by selecting the appropriate section',
      },
      videos: [
        {
          url: 'https://vimeo.com/617809495',
          id: '617809495',
          title: 'Clip A',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'A',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617809483',
          id: '617809483',
          title: 'Clip B',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'B',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617809403',
          id: '617809403',
          title: 'Clip C',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'C',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617809328',
          id: '617809328',
          title: 'Clip D',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'D',
          selected: false,
        },
        {
          url: 'https://vimeo.com/617809285',
          id: '617809285',
          title: 'Clip E',
          inputType: SNE_INPUT_TYPES.RADIO,
          answerChoice: 'E',
          selected: false,
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.MULTIPLE_CHOICE_FULL,
      header:
        'Select the <span class=\'convertToBlue\'>four</span> most important aspects of this interaction that help to make this a positive informational exchange for the client.',
      inputType: SNE_INPUT_TYPES.CHECKBOX,
      numSelectOptions: 4,
      headerVideo: {
        url: 'https://vimeo.com/605595851',
        id: '605595851',
        title: 'Identifying Appropriate Language',
        description: 'Please classify each of the facts by selecting the appropriate section',
      },
      singleMulChoiceOptions: [
        {
          userOption:
            'The sales representative introduces herself, setting a positive and friendly tone for the meeting.',
          userLetter: 'A',
        },
        {
          userOption:
            'The sales representative does not quote the contract verbatim, demonstrating that she knows all the company information off the top of her head.',
          userLetter: 'B',
        },
        {
          userOption:
            'The sales representative provides the client with clear information about how to contact her, which helps the client to feel comfortable about appropriate steps to take in the future.',
          userLetter: 'C',
        },
        {
          userOption:
            'The sales representative provides a clear purpose for the meeting, which helps to support effective communication between her and the client.',
          userLetter: 'D',
        },
        {
          userOption:
            'The sales representative includes a brief but comprehensive overview of the services provided, helping the client to feel acclimated.',
          userLetter: 'E',
        },
        {
          userOption:
            'The sales representative never pauses, hinting that it is not appropriate for the client to ask any questions.',
          userLetter: 'F',
        },
        {
          userOption:
            'The sales representative asks the client a question at the end, which helps demonstrate her humility.',
          userLetter: 'G',
        },
      ],
      submitId: 'item_3',
      complete: true,
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Nice work determining how to deliver that presentation! Your decisions help illustrate how you understand best practices for speaking with clients, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready.',
      questionIndex: 2,
      moduleSize: 6,
      image: '/assets/section-4/sne-interstitial/oc-end-interstitial.png',
      buttonText: 'NEXT',
      goto: OC_TYPES.DIALOGIC_1,
    },
  ],
]);

export const OCInformative2InstructionModal: SneModal = {
  pageList: [
    {
      title: 'Presenting to Clients',
      info: 'You are working with a new client and your job is to explain the services your company will be providing.<br><br>This section contains one video and several corresponding questions. Play the video, then answer the questions that follow. You can only play the video <span class=\'convertToBlue\'>once</span>.'
    },
    {
      title: 'Company Introduction',
      info: '',
      showCustomContent: true,
    }
  ],
  customContent: [
    {
      url: 'https://vimeo.com/605595851',
      id: '605595851',
      info: 'Watch carefully, you can only play this video <span class="convertToBlue">once.</span>',
      playOnce: true,
      sharedVideo: { 
        url: 'https://vimeo.com/605595851',
        id: '605595851',
      },
    },
  ],
};
