<div class="container">
  <input type="radio"
         id="{{id}}"
         name="{{name}}"
         value="{{value}}"
         class="radio-btn"
         (click)="onClick()"
         [disabled]="disabled"
  >
  <label [ngStyle]="{'font-weight': fontWeight}" for="{{id}}" class="radio-label" [innerHTML]="label">{{label}}</label>
</div>
