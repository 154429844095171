import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';
import { NegotiationAnswer, NegotiationQuestion } from '../types/sne';

@Injectable({
  providedIn: 'root'
})
export class NegotiationService extends BaseService {

  private negotiationUrl = environment.sneNegotiationAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Negotiation';
  }

  saveAndGetNextQuestion(requestBody: NegotiationAnswer): Observable<NegotiationQuestion> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'Negotiation: Next Question Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<NegotiationQuestion>(this.negotiationUrl, body, {headers}).pipe(
        tap(data => {          
          let responseLogObj: LogObject = {
            message: 'Negotiation: Next Question Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

}
