export const sneReportModuleScores = [
  {
    module: 'CRT',
    humanReadableName: 'Critical Thinking',
    high: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'critical thinking',
      title: 'What does standing out in critical thinking look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Determine whether or not information is reliable and draw evidence-based conclusions',
        'Analyze complex issues and develop solutions for them',
        'Evaluate arguments, identify assumptions, and form inferences'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills may enjoy roles that require thoroughness, accuracy, and an ability to take a balanced approach in working with information and opinions. They may also succeed in roles that require attention to detail, willingness to ask questions, and flexibility.',
    },
    medium: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'critical thinking',
      title: 'What does being on your way in critical thinking look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Consider the reliability of information and attempt to draw conclusions based on evidence',
        'Develop an understanding of issues but not always develop effective solutions for them',
        'Identify similarities and differences in arguments, and sometimes identify assumptions and form inferences',
      ],
      inTheWorkPlace: 'Those who are still working on these skills might initially prefer roles that require less complex problem solving and instead allow them to focus on the big picture. As they work to improve their skills, they may consider careers that encourage asking questions and evaluating the reliability and accuracy of data.',
    },
    low: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'critical thinking',
      title: 'What does having room to grow in critical thinking look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Determine whether or not information is accurate and reliable and draw evidence-based conclusions',
        'Analyze complex issues and develop effective solutions for them',
        'Evaluate arguments, identify assumptions, and form inferences',
      ],
      inTheWorkPlace: 'Those who are still working on these skills may be a good fit for roles that allow them to complete concrete tasks and apply previously verified information to solve problems.',
    },
    better: {
      title: 'How can I become a better critical thinker?',
      subTitle: 'Carefully analyzing information and applying multiple perspectives will help you think more critically. When you approach problems, consider focusing on the following:',
      ideas: [
        {
          title: 'Question everything',
          text: 'We usually trust what we read and hear in the workplace, which makes sense! But it is always wise to confirm the reliability of a source or authority. Sometimes we do not realize how much people\'s opinions or biases can shape what we presume are facts. Conduct research to confirm you have the most accurate information for any project. '
        },
        {
          title: 'Pay attention to the details',
          text: 'Life is hectic, and it can be tempting to rush a report or task to meet a deadline. Keep in mind that thoroughness and accuracy are highly valued in most careers. Dig deep into your subject and get familiar with the details! If you take time to collect, organize, and verify the information you need, you will convey that you are an analytical thinker who cares about doing a thorough job. '
        },
        {
          title: 'Apply your creativity',
          text: 'Think of problem solving as an opportunity to demonstrate your creativity and expertise. Figure out how to ask questions, and consult sources that others have not yet considered. Unusual approaches to problems can lead to great solutions, and with practice you will become someone others turn to for advice. Workplaces tend to value people who face challenges head on and apply creativity and a strong work ethic to overcome them.'
        }
      ]
    },
    scoreExplanation: 'Improving your critical thinking skills requires focusing on how to draw conclusions, make decisions, and solve complex problems based on accurate information. Learning to adjust your process can be tricky. Here are some common pitfalls to avoid:  ',
    examples: [
      {
        title: 'Believing things are what they seem',
        text: 'The information you read or hear might not be accurate or may only cover one specific perspective. It is essential to ensure that every source of information you use for your job is reliable. It helps to ask colleagues to share their opinions or suggest reputable resources. The more verified information you can access, the deeper understanding of a topic you will achieve. '
      },
      {
        title: 'Jumping to conclusions',
        text: 'It can be tempting to presume that you can trace every result to one particular event. However, other factors could play a more significant part in the eventual outcome than you might have guessed. Unless you ask questions, probe for more information, and figure out how events and results are genuinely related, you may make an illogical conclusion. '
      },
      {
        title: 'Over generalizing',
        text: 'Sometimes we make broad statements about people, events, or information based on only a few examples. However, these examples might be unique occurrences that do not necessarily suggest a pattern. Ensure that your conclusions and assumptions are based on enough cases to make up a reliable sample size. It is wise to consult with more experienced colleagues when you have doubts about how to confirm a general trend.'
      },
    ],
  },

  {
    module: 'WRC',
    humanReadableName: 'Written Communication',
    high: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'written communication',
      title: 'What does standing out in written communication look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Develop and organize accessible, engaging written content that clearly explains and supports ideas',
        'Craft and adapt writing to suit a variety of purposes, contexts, and intended audiences',
        'Demonstrate command of the conventions of standard English grammar, spelling, punctuation, and usage',
        'Assess others’ written work and find ways to strengthen it',
      ],
      inTheWorkPlace: 'Those who demonstrate these skills may enjoy roles requiring the ability to craft both formal and informal writing. They also might enjoy reviewing, revising, and providing feedback on their colleagues’ written work.',
    },
    medium: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'written communication',
      title: 'What does being on your way in written communication look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Produce sufficient written work but only adapt it for select purposes, audiences, and contexts',
        'Develop and organize written work that explains and supports ideas to varying degrees ',
        'Show reasonable control of writing conventions (like punctuation and grammar)',
        'Assess others’ writing effectively but not always find ways to improve it'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might initially be a better fit for jobs in which writing isn’t a top priority. However, as they work to improve these skills, they may consider pursuing careers that allow them to practice writing and gain new confidence and expertise.',
    },
    low: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'written communication',
      title: 'What does having room to grow in written communication look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Create and organize written content that clearly explains and supports ideas',
        'Draft and adapt writing to suit a range of purposes, contexts, and audiences',
        'Demonstrate understanding of the conventions of standard English grammar, spelling, punctuation, and usage',
        'Analyze others’ written work and suggest ways to strengthen it'
      ],
      inTheWorkPlace: 'Those who are still working on these skills may be a better fit for roles that emphasize oral communication (such as meetings, conferences, and presentations) rather than those that involve writing.',
    },
    better: {
      title: 'How can I become a better written communicator?',
      subTitle: 'Becoming a stronger writer and a more critical reader takes time and effort. To strengthen your writing and analysis skills, consider focusing on the following:',
      ideas: [
        {
          title: 'Read widely',
          text: 'Read as much as you can, especially in the genres or topics that you feel are most connected to your work or what you are interested in doing. Pay attention to different writers’ styles and how they approach their material. Try to learn new vocabulary words and study sentence structure. '
        },
        {
          title: 'Ask for feedback',
          text: 'Seek feedback on your work from other people whose writing you admire. Depending on what audience you are writing for, you could ask colleagues for advice or turn to someone outside of work, like a friend or family member.'
        },
        {
          title: 'Compare and contrast',
          text: 'Write something similar to what other people have already written and compare your approach to theirs. Internet discussion forums can be a good place for this. For example, you could write a reply to a posted question or request, then wait until you post your reply to read what others wrote in response to the same prompt. Focus less on what they express and more on how they express it. '
        },
        {
          title: 'Take a class',
          text: 'Attend a class on the writing you are already involved in or are interested in learning. Some employers may even pay for part (or all!) of your tuition if the course is related to the work you do.'
        }
      ]
    },
    scoreExplanation: 'Improving your written communication skills involves focusing on both <span class="convertToBold">what</span> you want to say and <span class="convertToBold">how</span> you want to say it. It requires familiarity with different types of writing, their purpose and context, and their intended audience. It also requires practice applying grammar, spelling, and punctuation standards. Here are some things to watch out for as you progress:',
    examples: [
      {
        title: 'Slipping into “textese”',
        text: 'Texting and IM-ing are widespread methods of written communication. Because they are casual and fast-paced, they allow for shortcuts (abbreviations and spelling changes like “BRB” or "Wat RU up 2?"). While it is perfectly fine to use textese with friends and family, it is important not to allow it to creep into your workplace writing. Practice applying the more formal rules for writing that your readers expect at work.'
      },
      {
        title: 'Failing to proofread',
        text: 'Proofreading involves carefully reviewing finished writing. Even though it can be tempting to give work to others without checking it, it is essential to check your work first. Adopt your readers’ perspective when you proofread. Consider whether your words are well chosen, are spelled correctly, and have the right tone for what you are trying to get across. There are a variety of applications that can check spelling and grammar for you—take advantage of them!'
      },
      {
        title: 'Presuming clarity',
        text: 'Understanding your thoughts does not mean others will automatically grasp them. Writing involves translating what is in your head and requires some finesse. Try asking yourself clarifying questions as you write, such as, "Why is this true?" or "What else might someone reading this want to know?" The more thoroughly you can develop and support your ideas, the clearer they will be to your audience.'
      },
      {
        title: 'Procrastinating',
        text: 'Writing can be easy to avoid or to put off until the last minute. But rushing always impacts quality—it takes time and careful thought to plan what you want to say and to figure out the best way to say it. Set aside enough time to draft and revise your writing. Starting is always the hardest part, so the sooner you start, the sooner you will get over your biggest hurdle!',
      },
    ]
  },


  {
    module: 'CCH',
    humanReadableName: 'Coachability',
    high: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'coachability',
      title: 'What does standing out in coachability look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Seek and respect feedback, and remain open to both constructive criticism and praise',
        'Cultivate their curiosity and pursue new learning opportunities',
        'Get along well with supervisors and colleagues',
        'Accept responsiblity and seek to learn from mistakes',
        'Embrace new technologies and ways of working',
        'Seek appropriate resources to solve problems'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills tend to enjoy roles that require the ability to rapidly shift responsibilities and learn new tools or methods for performing tasks. They also tend to succeed in roles that receive frequent feedback from managers or supervisors, who expect their feedback to be reflected in their employee’s performance.',
    },
    medium: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'coachability',
      title: 'What does being on your way in coachability look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Seek feedback but sometimes receive it with skepticism',
        'Seek new learning opportunities but only in specific areas',
        'Take ownership for mistakes they make but see them as errors rather than as opportunities to learn',
        'Become flustered in some uncertain situations'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might initially be a better fit for careers in which they are less supervised and don’t receive frequent feedback on their performance. However, as they work to improve these skills, they may consider pursuing careers in which they receive more feedback and have the opportunity to take on more responsibility.',
    },
    low: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'coachability',
      title: 'What does having room to grow in coachability look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Seek feedback consistently, and remain open to both constructive criticism and praise',
        'Find and pursue new learning opportunities',
        'Create and maintain positive relationships with supervisors and colleagues',
        'Accept responsiblity for their mistakes and try to learn from them',
        'Embrace new technologies and ways of working',
        'Seek appropriate resources to solve problems'
      ],
      inTheWorkPlace: 'Those who are still working on these skills may be a better fit for careers in which they are less supervised and don’t receive frequent feedback on their performance. They may also be a better fit for careers that are less focused on achieving increased levels of responsibility and where their tasks involve routine, well-established procedures.',
    },
    better: {
      title: 'How can I become more coachable?',
      subTitle: 'Improving how you give and receive feedback and embrace change will help you grow. The next time you face uncertainty or upset, consider focusing on the following:',
      ideas: [
        {
          title: 'Lean into uncertainty',
          text: 'It is OK to have your doubts. But do not let them limit you. The next time a coworker presents an idea to you that you are unsure about, ask thoughtful, probing questions to get a fuller picture and help them explain their perspective.'
        },
        {
          title: 'Pause for reflection',
          text: 'Remember that you do not need to react to new ideas immediately—you can ask for time to think things over before responding.'
        },
        {
          title: 'Look for the opportunity',
          text: 'Think of new ideas or changes as opportunities—while you might initially disagree with them, sharing and listening can allow ideas to develop into something unexpected that ends up benefitting you, your colleagues, and your work.'
        },
        {
          title: 'Grow mutual respect',
          text: 'If you treat people with careful consideration when they present their ideas, they will be more likely to treat you that way when you present yours. And you will help create a supportive working environment in which everyone wins.'
        }
      ]
    },
    scoreExplanation: 'Improving your coachability skills requires being open to new ways of doing things. Learning to adjust your approach can be tricky. Here are some things to look out for in the process:',
    examples: [
      {
        title: 'Shutting down conversations',
        text: 'Try to remember that disagreements among colleagues are OK. Just because a conversation is not going how you thought it would or you have a different perspective does not mean you should abandon trying to hear a new take on something. Healthy debate can lead to new insight.'
      },
      {
        title: 'Resisting change',
        text: 'Although change can be intimidating, it is usually a major part of most working environments. Focus on becoming more open to it and not letting it overwhelm you. '
      },
      {
        title: 'Taking offense at feedback',
        text: 'Accepting constructive criticism can feel uncomfortable. Try to remember that everyone giving it more than likely has your best interests in mind and wants you to succeed.'
      },
      {
        title: 'Writing off other people’s ideas',
        text: 'It is essential to listen to and fully engage with others’ ideas. They may help you improve your own ideas, or they may be rooted in experiences that you do not yet have. It can be helpful to consider all angles of a new idea before you dismiss it.'
      },
    ],
  },

  {
    module: 'CPS',
    humanReadableName: 'Collaborative Problem Solving',
    high: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'collaboration',
      title: 'What does standing out in collaboration look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Maintain open and transparent communication',
        'Establish a shared understanding and negotiate with their colleagues',
        'Actively share and seek new information while remaining focused on accomplishing a common goal'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills typically establish a shared understanding by gathering different perspectives and confirming agreement. They also try to find common ground and resolve conflicts to achieve compromise.',
    },
    medium: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'collaboration',
      title: 'What does being on your way in collaboration look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Build rapport with colleagues, sometimes at the expense of completing a task',
        'Share information but not always listen to others',
        'Negotiate with others but sometimes find it difficult to reach a compromise'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might be a better fit for roles that emphasize individual contributions more than team or group dynamics.',
    },
    low: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'collaboration',
      title: 'What does having room to grow in collaboration look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Maintain open and transparent communication',
        'Establish a shared understanding and negotiate effectively with their colleagues',
        'Seek and share new information and remain focused on accomplishing a common goal',
      ],
      inTheWorkPlace: 'Those who are still working on these skills may be a better fit for roles that emphasize individual contributions more than team or group dynamics.',
    },
    high2: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'problem solving ',
      title: 'What does standing out in problem solving look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Explore all angles of a problem and glean insights',
        'Determine appropriate ways to represent and communicate about a problem',
        'Prioritize tasks and needs, then carry out a plan and monitor its progress'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills tend to succeed in roles that require careful thinking and the ability to address complex challenges.',
    },
    medium2: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'problem solving ',
      title: 'What does being on your way in problem solving look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Explore different aspects of a problem but sometimes overlook important elements',
        'Understand problems but sometimes struggle to communicate about them in a way that makes sense to others',
        'Make decisions about what to prioritize but face challenges carrying out a plan and monitoring its progress'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might be a better fit for roles that emphasize a guided or supervised approach to complex problems.',
    },
    low2: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'problem solving ',
      title: 'What does having room to grow in problem solving look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Explore all angles of a problem and glean insights',
        'Determine appropriate ways to represent and communicate about a problem',
        'Prioritize tasks and needs, then carry out a plan and monitor its progress'
      ],
      inTheWorkPlace: 'Those who are still working on these skills may be a better fit for roles that emphasize a guided or supervised approach to complex problems.',
    },
    better: {
      title: 'How can I become a better collaborator?',
      subTitle: 'Supportive relationships with colleagues will help you tackle difficult challenges and achieve success at work. To build a positive dynamic, focus on the following:',
      ideas: [
        {
          title: 'The art of small talk',
          text: 'Asking the people you work with about their hobbies or weekend plans helps build rapport and develop common ground. Connecting to your colleagues on a personal level will make you feel more comfortable both asking for and giving support as you try to solve tough problems.',
        },
        {
          title: 'Other perspectives',
          text: 'Try to both share and ask for information, and listen carefully when others contribute their ideas. Repeat what others have said back to them to make sure you understand their viewpoint. Everyone has unique insights that can help teams approach complex challenges and develop unexpected solutions.',
        },
        {
          title: 'Mapping it out',
          text: 'Formulate a plan for the problem you are trying to solve. Consider all of the information you have gathered, what resources you will need, how long it will take, and when and how you will be able to tell if you have achieved success.',
        },
        {
          title: 'Diplomacy wins',
          text: 'You may need to involve a manager if a disagreement with a coworker becomes serious. But your first step should always be to try to reach consensus yourself. Keep things civil and remember that regardless of title or position, everyone’s contributions matter.',
        }
      ]
    },
    scoreExplanation: 'Effective collaborative problem solving requires balancing friendly relationships with coworkers and getting your work done. Finding that happy medium can be tricky. Here are some things to look out for in the process:',
    examples: [
      {
        title: 'Getting carried away with chatting ',
        text: 'While positive small talk should always be part of your interactions with colleagues, becoming too absorbed in personal conversation can leave you with less time to resolve the issue at hand. It can be easy to find yourself caught in an interesting discussion and then realize you are late for your next meeting or task.'
      },
      {
        title: 'Viewing team efforts as a contest',
        text: 'This mindset steals focus from what should be your true aim: solving challenges by trying to find the <span class="convertToBold">best</span> outcome for everyone, not trying to compete for the spotlight.',
      },
      {
        title: 'Pushing too hard on sensitive topics',
        text: 'Discussing workplace dynamics with coworkers can be a useful bonding experience, but be sure not to force these subjects if your coworker does not want to talk about them. Small talk should build goodwill and comfort between coworkers so that you will be motivated to help each other and work together.'
      },
    ],
  },

  {
    module: 'ORC',
    humanReadableName: 'Oral Communication',
    high: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'conversational oral communication',
      title: 'What does standing out in conversational oral communication look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Take time to carefully consider what people say and how they say it within the social context',
        'Respond to what people say with professionalism and courtesy',
        'Interpret and apply nonverbal cues (such as eye contact and body language) to support productive conversation',
        'Ask thoughtful questions and give thoughtful responses to achieve both stated and implied goals'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills may enjoy roles that require strong listening skills and an openness to new or different opinions. They may pursue careers that involve frequent interpersonal interactions, such as customer service, human resources (HR), or human services.',
    },
    medium: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'conversational oral communication',
      title: 'What does being on your way in conversational oral communication look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Consider what people say but sometimes fail to take into account the nuances of how they say it within the social context',
        'Respond to what people say with courtesy but occasionally treat interactions too casually or without enough professionalism',
        'Interpret and apply nonverbal cues (such as eye contact and body language) in ways that sometimes support and sometimes hinder productive conversation ',
        'Ask questions and give responses that help achieve stated goals but sometimes fail to work toward achieving implied goals'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles in which only occasional interpersonal communication is required, balancing working collaboratively with working alone. They may pursue jobs such as data analyst, medical coder, or copy editor.',
    },
    low: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'conversational oral communication',
      title: 'What does having room to grow in conversational oral communication look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Maintain professionalism in a variety of contexts by practicing awareness of both what is said and how it is said',
        'Recognize nonverbal cues (such as eye contact and body language) and apply them for productive and effective discourse',
        'Ask appropriate questions and give appropriate responses that drive conversations toward resolution or decision',
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles that emphasize individual contributions. They may pursue jobs such as technical writer, data entry clerk, or laboratory technician.',
    },
    high2: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'persuasive oral communication',
      title: 'What does standing out in persuasive oral communication look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'State their needs, wishes, strengths, and weaknesses with clarity and conviction',
        'Use nonverbal cues (such as eye contact and body language) to convey confidence',
        'Track claims, evidence, and strategies for arguments that others incorporate into their speech'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills may enjoy roles that emphasize considering different perspectives or developing ways to convince others to behave in a certain way. They may pursue careers in sales, politics, public relations, law, or other industries that offer opportunities to persuade others.',
    },
    medium2: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'persuasive oral communication',
      title: 'What does being on your way in persuasive oral communication look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'State their needs and wishes in some contexts but hesitate in others.',
        'Recognize effective use of nonverbal cues (such as eye contact and body language) but sometimes struggle to apply them.',
        'Identify some, but not all, of the claims, reasons, and evidence others use in their arguments'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles in which they use facts, data, and previously synthesized ideas and methods to support a claim or opinion. They may pursue careers in advertising or grant writing.',
    },
    low2: {
      scoreHumanReadable: 'having room to grow',
      moduleOrSubModule: 'persuasive oral communication',
      title: 'What does having room to grow in persuasive oral communication look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'State their needs or wishes clearly to coworkers and supervisors',
        'Recognize and apply nonverbal cues (such as eye contact and body language)',
        'Understand the arguments others present'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles that emphasize persuasive writing rather than speaking and individual contributions over collaboration and compromise. They may pursue jobs in advertising or marketing copywriting, journalism, or fundraising.',
    },
    high3: {
      scoreHumanReadable: 'standing out',
      moduleOrSubModule: 'informational oral communication',
      title: 'What does standing out in informational oral communication look like?',
      subtitle: 'People with results like yours tend to: ',
      abilities: [
        'Listen to and understand both big picture ideas and finer details',
        'Identify and ask appropriate follow-up questions',
        'Understand how both verbal and nonverbal cues play a part in overall messaging'
      ],
      inTheWorkPlace: 'Those who demonstrate these skills may enjoy roles that require collecting and synthesizing facts, data, and ideas, then developing effective ways to share that information. They may pursue careers in journalism, research, pharmacology, education, or other industries that offer opportunities to inform others.',
    },
    medium3: {
      scoreHumanReadable: 'being on your way',
      moduleOrSubModule: 'informational oral communication',
      title: 'What does being on your way in informational oral communication look like?',
      subtitle: 'People with results like yours may: ',
      abilities: [
        'Listen to big picture ideas but sometimes struggle to understand how finer details impact them',
        'Ask follow-up questions but occasionally miss appropriate opportunities to respond to shared information',
        'Understand how verbal and nonverbal cues play a role in overall messaging in some cases and not in others'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles that require collecting and synthesizing facts, data, and ideas, but only sharing that information on occasion. They may pursue jobs such as research assistant or technical writer.',
    },
    low3: {
      scoreHumanReadable: 'having room to grow ',
      moduleOrSubModule: 'informational oral communication',
      title: 'What does having room to grow in informational oral communication look like?',
      subtitle: 'People with results like yours could improve their ability to: ',
      abilities: [
        'Listen to and understand both big picture ideas and finer details',
        'Identify and ask appropriate follow-up questions',
        'Understand how both verbal and nonverbal cues play a part in overall messaging'
      ],
      inTheWorkPlace: 'Those who are still working on these skills might prefer roles that require creating primarily written products or emphasize individual contributions over collaborative work. They may pursue jobs such as copy editor, legal secretary, or medical technologist.',
    },
    better: {
      title: 'How can I become a better oral communicator?',
      subTitle: 'Oral communication is all about speaking, listening, and using body language to effectively communicate with others. The next time you are conversing with a colleague or supervisor, consider focusing on the following:',
      ideas: [
        {
          title: 'Keep an open mind',
          text: 'We listen to others to understand them and their point of view, and we share with others so that they can understand ours. Treat other people’s ideas with the same respect you expect them to give yours, and work on embracing different perspectives as opportunities to build empathy and trust.'
        },
        {
          title: 'Focus on others',
          text: 'Whether listening or speaking, devote your attention to the person or people you are communicating with. Avoid multitasking and other distractions that could take your mind away from the conversation.'
        },
        {
          title: 'Respond rather than react',
          text: 'Sometimes we have automatic or instinctive reactions to questions or comments, especially if we do not agree. If we speak too quickly, our words might not align with how we genuinely feel or think. Take a moment to pause and collect your thoughts before you respond so that you move the conversation forward rather than invite conflict.'
        }
      ]
    },
    scoreExplanation: 'Improving your oral communication skills requires active listening and participation (paying attention to details, evaluating arguments, and using conversations as opportunities to both share and receive information). Here are some common pitfalls to avoid as you focus on improving these skills:',
    examples: [
      {
        title: 'Lacking structure or organization',
        text: 'As a <span class="convertToBold">speaker</span>, it is important to know and adhere to your purpose for sharing information. Be careful not to jump around too much—make connections between ideas to help your listeners follow your thinking.<br><br>As a <span class="convertToBold">listener</span>, it is important to pay careful attention to others. Do not let your mind wander, or you will miss not only the overall purpose of what they say but also the relevant details, words, and phrases that help make sense of their ideas.',
      },
      {
        title: 'Lacking clarity or focus',
        text: 'As a <span class="convertToBold">speaker</span>, it is important to be specific about what you want and need. Avoid generalizing—choose words and phrases that clearly convey your position. <br><br>As a <span class="convertToBold">listener</span>, it is important to assess what others say. Be cautious of presuming their arguments are always valid. Consider the origin and strengths of speakers’ ideas so that you can ask clarifying questions and provide constructive criticism where appropriate.'
      },
      {
        title: 'Ignoring or misinterpreting cues',
        text: 'As a <span class="convertToBold">speaker</span>, it is important to use nonverbal cues to enhance what you say. Try not to stare down at what’s in front of you or hunch over—maintain eye contact and an open posture to better connect with your audience. <br><br>As a <span class="convertToBold">listener</span>, it is important to recognize nonverbal cues and how they impact a speaker’s message. Try not to avoid making eye contact and pay attention to a speaker’s tone of voice and body language.'
      },
    ],
  }
];
