<div class="white-container" [attr.aria-hidden]="showLearnMoreModal">
    <div class="inner-slide" @carouselAnimation>
        <h1>{{ courseTitle }}</h1>
        <h2 >
            <span>
                <img src="../../../assets/career-details/blue-silouette.svg" alt="" aria-hidden="true"/>
            </span>{{trainingProviderName}}
        </h2>
        <p class="description">{{description}}</p>

        <div class="info-flex-row">
            <div class="category">
                <img src="../../../assets/career-details/blue-calendar.svg" alt="" aria-hidden="true"/>
                <p class="duration">{{duration}}</p>
            </div>
            <div class="border-divider"></div>
            

            <div class="category">
                <img src="../../../assets/career-details/blue-clock.svg" alt="" aria-hidden="true"/>
                <p class="uppercase">{{program}}</p>
            </div>
            
            <div class="border-divider"></div>

            <p class="blue-dollars" *ngIf="dollars === 0" id="padding-left">{{cost}}</p>
            <p class="blue-dollars" *ngIf="dollars === 1">$<span class="grey-dollar">$$$</span></p>
            <p class="blue-dollars" *ngIf="dollars === 2">$$<span class="grey-dollar">$$</span></p>
            <p class="blue-dollars" *ngIf="dollars === 3">$$$<span class="grey-dollar">$</span></p>
            <p class="blue-dollars" *ngIf="dollars === 4">$$$$</p>

        </div>
        <div class="footer-wrapper">
            <button class="blue-button" (click)="additionalInfo()" *ngIf="this.externalTpLink === ''">
                BEGIN MY APPLICATION
            </button>
            <button class="blue-button" (click)="externalLinkLoad()" *ngIf="this.externalTpLink !== ''">
                BEGIN MY APPLICATION
            </button>
            <button class="white-button" (click)="learnMore()">
                LEARN MORE
            </button>
        </div>
    </div>
</div>

<div class="navigation-buttons" *ngIf="numberOfTrainings > 1">
    <button class="carousel-control-prev" (click)="decrement()" aria-label="previous training"
    aria-live="assertive"><</button>
    
    <div class="circle" *ngFor="let training of trainings; index as i;">
        <span [class]="i === trainingIndex ? 'active' : 'inactive' "
            (click)="changeSlide(i)">
        </span>
    </div>
    
    <button class="carousel-control-next" (click)="increment()" aria-label="next training"
    aria-live="assertive">></button>
</div>

<clr-modal [(clrModalOpen)]="showLearnMoreModal" [clrModalStaticBackdrop]="false" [clrModalClosable]="true" id="learn-more-modal" role="alertdialog">
    <div class="modal-body">
        <ets-learn-more-modal
            [training]="training"
            [career]="career">
        </ets-learn-more-modal>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="showAdditionalInfoModal" [clrModalStaticBackdrop]="false" [clrModalClosable]="false" id="additional-info-modal" role="alertdialog">
    <div class="modal-body">
        <ets-additional-info-modal
            (closeModalSignal)="closeModal()"
            [training]="training"
            [career]="career">
        </ets-additional-info-modal>
    </div>
  </clr-modal>