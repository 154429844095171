<div class="container"
  [ngClass]="{
    'disabled-container': disabled,
    'my-top-skills-container': title === 'My top skills' && !disabled,
    'top-border': !disabled && topBorder
  }"
  >
  <div class="skill-type-info">
    <div class="title-img-container">
      <img class="skill-type-img" [src]="imagePath" alt="" aria-hidden="true" [ngClass]="{'disabled-img': disabled}"/>
      <div class="title-suggested-skills-container">
        <p class="title" 
          [ngClass]="{'disabled-opacity': disabled}"
          aria-live="assertive"
          aria-atomic="true"
          >
          {{title}}
        </p>
        <!-- display if there's no skills selected -->
        <p class="suggested-skills" 
            aria-live="assertive"
            *ngIf="selectedSkills.length === 0 && title !== 'My top skills'">
            <span class="convertToBlue">25</span>
            suggested skills
        </p>
      </div>
    </div>

    <p class="description" 
      [innerHTML]="description" 
      aria-live="assertive"
    ></p>
    <!-- selectedSkills -->
    <div class="selected-skills-container" *ngIf="selectedSkills.length > 0">
        <div class="skill-pill"
            *ngFor="let skill of selectedSkills.slice(0, 3);" 
            [ngClass]="{'disabled-skill-pill': disabled}"
            aria-live="assertive"
          >
          {{skill}}
        </div>
      <div *ngIf="selectedSkills.length - 3 > 0" 
            class="additional-skills"
            aria-live="assertive"
      >+ {{selectedSkills.length - 3}}</div>
    </div>
  </div>

  <ets-outline-button [text]="selectedSkills.length > 0 ? 'Revise' : 'Start'" [color]="'#0E3AD6'" (clickEvent)="clickSelect(id)" [disabled]="disabled"></ets-outline-button>
</div>