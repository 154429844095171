import { createAction, props } from '@ngrx/store';
import Question from '../types/question';
import { CipAnswer } from '../types/cip';

// On-boarding
export const setCipQuestionList = createAction('[UI] Set Cip Question List', props<{payload: Question[]}>());
export const setCipQuestionIndex = createAction('[UI] Set Cip Question Index', props<{payload: number}>());
export const setCipQuestion = createAction('[UI] Set Cip Question', props<{payload: Question}>());
export const setTriggerNextCipQuestion = createAction('[UI] Trigger Next CIP Question', props<{payload: boolean}>());
export const setCipAnswers = createAction('[UI] Set CIP Answers', props<{payload: CipAnswer[]}>());

// Coaching
export const setCoachingQuestionIndex = createAction('[Coaching] Set Coaching Question Index');
