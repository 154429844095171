export enum GENDERS {
  MALE = 'Male',
  FEMALE = 'Female',
  NON_BINARY = 'Non-binary',
  TRANSGENDER = 'Transgender',
  PREFER_NOT_TO_SAY = 'Prefer not to say'
}

export enum RACE {
  AMERICAN_INDIAN_ALASKA_NATIVE = 'American Indian or Alaska Native',
  ASIAN = 'Asian',
  BLACK_AFRICAN_AMERICAN = 'Black or African American',
  HAWAIIAN_PACIFIC_ISLANDER = 'Native Hawaiian or Other Pacific Islander',
  WHITE = 'White',
  HISPANIC_LATINO = 'Hispanic/Latino',
  PREFER_NOT_TO_SAY = 'Prefer not to say',
  OTHER = 'Other'
}

export enum DISABILITY_STATUS {
  YES = 'Yes',
  NO = 'No',
  // PREFER_NOT_TO_SAY = 'Prefer not to say' // Commenting this out because it's not a valid choice in the DB
}
