import { createReducer, on } from '@ngrx/store';
import Question from '../types/question';
import { CipAnswer } from '../types/cip';
import {
  setCipAnswers,
  setCipQuestion, setCipQuestionIndex, setCipQuestionList,
  setTriggerNextCipQuestion
} from './ui.actions';

export interface State {
  cipAnswers: CipAnswer[] | undefined;
  onBoardingProgress: string;
  triggerPreviousQuestion: boolean;
  cipQuestionList: Question[] | undefined;
  cipQuestionIndex: number | undefined;
  cipQuestion: Question | undefined;
  cipProgress: string;
  triggerNextCipQuestion: boolean;
  triggerPreviousCipQuestion: boolean;
}

export const uiFeatureKey = 'ui';

export const initialState: State = {
  cipAnswers: undefined,
  onBoardingProgress: '0%', // set as a percentage from 0 to 100%
  triggerPreviousQuestion: false,
  cipQuestionList: undefined,
  cipQuestionIndex: undefined,
  cipQuestion: undefined,
  cipProgress: '0%', // set as a percentage from 0 to 100%
  triggerNextCipQuestion: false,
  triggerPreviousCipQuestion: false,
};

export const reducer = createReducer(
  initialState,
  on(setCipQuestionList, (state, { payload }) => ({ ...state, cipQuestionList: payload})),
  on(setCipQuestionIndex, (state, { payload }) => ({ ...state, cipQuestionIndex: payload })),
  on(setCipQuestion, (state, { payload }) => ({ ...state,cipQuestion: payload })),
  on(setTriggerNextCipQuestion, (state, { payload }) => ({ ...state, triggerNextCipQuestion: payload })),
  on(setCipAnswers, (state, { payload }) => ({ ...state, cipAnswers: payload })),
);
