import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ets-skills-button',
  template: `
    <button (click)="onClick(id)" class="skill" [id]="id" [disabled]="isSelected" [ngClass]="{'disableAll': disableAll}">
      {{ skill }}
    </button>
  `,
  styleUrls: ['./skills-button.component.scss']
})

export class SkillsButtonComponent {
  @Input() skill?: string = ''; // skill name
  @Input() id: number = 0; // skill id
  @Input() isSelected?: boolean = false; // Disables skill if selected
  @Input() disableAll?: boolean = false; // Disables all skills once 5 have been selected

  @Output() selectedToggle = new EventEmitter<any>();

  onClick(id: number) {
    this.selectedToggle.emit(id);
  }
}
