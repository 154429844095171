import QUESTION_TYPES, { QUESTION_SECTIONS } from '../types/question-types';
import {
  setOnBoardingCurrentQuestionIndex,
  setOnBoardingQuestionType,
  setOnBoardingSection, setSkillsInFlight, setWorkHistoryInFlight
} from './on-boarding.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  onBoardingQuestionType: QUESTION_TYPES | undefined;
  onBoardingQuestionSection: QUESTION_SECTIONS | undefined;
  onBoardingQuestionIndex: number | undefined;
  workHistoryInFlight: boolean;
  skillsInFlight: boolean;
}

export const onBoardingFeatureKey = 'onBoarding';

export const initialState: State = {
  onBoardingQuestionType: undefined,
  onBoardingQuestionSection: undefined,
  onBoardingQuestionIndex: undefined,
  workHistoryInFlight: false,
  skillsInFlight: false
};

export const reducer = createReducer(
  initialState,
  on(setOnBoardingQuestionType, (state, { payload }) => ({...state, onBoardingQuestionType: payload})),
  on(setOnBoardingSection, (state, { payload }) => ({ ...state, onBoardingQuestionSection: payload})),
  on(setOnBoardingCurrentQuestionIndex, (state, { payload }) => ({ ...state, onBoardingQuestionIndex: payload})),
  on(setWorkHistoryInFlight, (state, { payload }) => ({ ...state, workHistoryInFlight: payload })),
  on(setSkillsInFlight, (state, { payload }) => ({ ...state, skillsInFlight: payload }))
);
