import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CoachabilityMultiChoiceConvoLogComponent
} from './coachability-multi-choice-convo-log/coachability-multi-choice-convo-log.component';
import {
  CoachabilityMultiChoiceVideoComponent
} from './coachability-multi-choice-video/coachability-multi-choice-video.component';
import {
  CoachabilityMultipleSelectEmailComponent
} from './coachability-multiple-select-email/coachability-multiple-select-email.component';
import {
  CoachabilityInterstitialComponent
} from './coachability-interstitial/coachability-interstitial.component';
import { CoachabilitySurveyComponent } from './coachability-survey/coachability-survey.component';
import { CoachabilityComponent } from './coachability.component';
import { SneComponentsModule } from '../components/sne-components.module';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CoachabilityMultiChoiceListConvoLogComponent } from './coachability-multi-choice-list-convo-log/coachability-multi-choice-list-convo-log.component';
import { CchSneIntroComponent } from './cch-sne-intro/cch-sne-intro.component';
import { CoachabilitySplitScreenMultiChoiceVideoComponent } from './coachability-split-screen-multi-choice-video/coachability-split-screen-multi-choice-video.component';
import { CoachabilityMultiChoiceSingleVideoComponent } from './coachability-multi-choice-single-video/coachability-multi-choice-single-video.component';

@NgModule({
  declarations: [
    CoachabilityMultiChoiceConvoLogComponent,
    CoachabilityMultiChoiceVideoComponent,
    CoachabilityMultipleSelectEmailComponent,
    CoachabilityInterstitialComponent,
    CoachabilitySurveyComponent,
    CoachabilityComponent,
    CoachabilityMultiChoiceListConvoLogComponent,
    CchSneIntroComponent,
    CoachabilitySplitScreenMultiChoiceVideoComponent,
    CoachabilityMultiChoiceSingleVideoComponent
  ],
  imports: [
    CommonModule,
    SneComponentsModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    CoachabilityMultiChoiceConvoLogComponent,
    CoachabilityMultiChoiceVideoComponent,
    CoachabilityMultipleSelectEmailComponent,
    CoachabilityInterstitialComponent,
    CoachabilitySurveyComponent,
    CoachabilityComponent,
    CoachabilitySplitScreenMultiChoiceVideoComponent,
    CoachabilityMultiChoiceSingleVideoComponent
  ]
})
export class CoachabilityModule { }
