import { Component, Input, OnInit } from '@angular/core';
import { CareerDetailService } from 'src/app/services/career-detail.service';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ets-training-provider-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class TrainingProviderCarouselComponent implements OnInit {

  myForm = new FormGroup({
    linkedIninput: new FormControl('',
      [Validators.required,
        Validators.maxLength(256)]),
    phoneNumberInput: new FormControl('',
      [Validators.required,
        Validators.maxLength(10)]),
    startDateInput: new FormControl('',
      [Validators.required,
        Validators.maxLength(256)])
  });
  
  constructor( private careerDetailService: CareerDetailService) {
  }

  @Input() training: any;
  @Input() career: any;
  @Input() numberOfTrainings: any;
  @Input() trainingIndex: any;
  @Input() trainings: any;

  @Output() changeSlideEvent = new EventEmitter<any>();

  careerTitle= '';
  careerID = '';
  courseTitle = '';
  trainingProviderName = '';
  description = '';
  duration = '';
  program = '';
  cost = '';
  dollars = 0;
  showModal = false; 
  pageCounter = 1;
  showLearnMoreModal = false;
  showAdditionalInfoModal= false;
  courseRequirements = '';
  linkedInField = true; 
  startDateField = true;
  phoneNumberField = true;
  linkedInUrl : any;
  startDateMonth: any;
  phoneNumber : any;
  earliestStartDate = '';
  externalTpLink = '';


  ngOnInit() {
    this.trainingProviderName = this.training.training_provider;
    this.courseTitle = this.training.course_title;
    this.cost = this.training.course_price;
    this.duration = this.transformHyphenText(this.training.course_duration).replace(/-/g, ' ');
    this.duration = this.capitalizeEachWord(this.duration);

    this.description = this.training.course_description;
    this.program = this.transformHyphenText(this.training.course_training_type);
    this.courseRequirements = this.training.course_requirements;
    this.linkedInField = this.training.course_additional_requirements_linkedin;
    this.startDateField = this.training.course_additional_requirements_start_date;
    this.phoneNumberField = this.training.course_additional_requirements_phone_number;
    this.externalTpLink = this.training.exception_link_to_training_provider_application;

    this.formatCost(this.training.course_price);
  }

  formatCost(CMScost: 'string') {
    let costAsNumber = Number(CMScost.replace(/[^0-9.-]+/g, ''));

    if (CMScost.includes('EARN')) {
      this.cost = 'GET PAID';
      this.dollars = 0;
    } else if (costAsNumber >= 5000) {
      this.dollars = 4;
    } else if (costAsNumber < 5000 && costAsNumber >= 1000){
      this.dollars = 3;
    } else if (costAsNumber < 1000 && costAsNumber >= 250){
      this.dollars = 2;
    } else if (costAsNumber <150 && costAsNumber >=0){
      this.dollars = 1;
    }
  }

  capitalizeEachWord(oldText: string) {
    return oldText.toLowerCase()
      .split(' ')
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  transformHyphenText(oldText: string){
    let newText = '';
    if (oldText === 'part-time'){
      newText = 'Part-Time';
    } else if (oldText === 'full-time'){
      newText = 'Full-Time';
    } else if (oldText === 'train-to-hire'){
      newText = 'Train-to-Hire';
    } else {
      return oldText.charAt(0).toUpperCase() + oldText.slice(1); // capitalize first char
    }
    return newText;
  }

  learnMore() {
    this.showLearnMoreModal = true;
  }
  additionalInfo(){
    this.showAdditionalInfoModal = true;
  }
  externalLinkLoad(){
    window.open(this.externalTpLink);
  }
  closeModal(){
    this.showAdditionalInfoModal = false;
  }
  

  increment() {
    this.trainingIndex < this.trainings.length - 1 ?   // 0-based index
      this.trainingIndex++ : 
      this.trainingIndex = 0;

    this.changeSlideEvent.emit(this.trainingIndex);
  }

  decrement() {
    this.trainingIndex > 0 ?
      this.trainingIndex-- : 
      this.trainingIndex = this.trainings.length - 1;

    this.changeSlideEvent.emit(this.trainingIndex);
  }

  changeSlide(index: number){
    this.trainingIndex = index;
    this.changeSlideEvent.emit(this.trainingIndex);
  }
}