export interface EventObj {
  message: string;
  action: EventActionEnum;
  section?: EventSectionEnum; // Section variable for the Page View action
  object?: any; // Any object you'd like to be included in the Event output

  // These fields will be automatically populated by the Event Service
  username?: string;
  browserTimestamp?: Date;
  userAgent?: string;
  page?: string;
}

export enum EventActionEnum {
  CLICK = 'Click',
  PAGE_VIEW = 'Page View',
  ERROR = 'Error',
  LOG = 'Log',
}

export enum EventSectionEnum {
  ONBOARING = 'Onboarding',
  CIP = 'CIP',
  POWER_SKILLS = 'Power Skills',
  CAREER_SUGGESTIONS = 'Career Suggestions',
  CAREER_DETAILS = 'Career Details',
  DASHBOARD = 'Dashboard',
  USER_PROFILE = 'User Profile',
  CAREER_PREP = 'Career Prep',
  SNE = 'SNE',
}