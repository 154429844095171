import { Component, OnInit } from '@angular/core';
import { FileManagerService } from '../../../../services/filemanager.service';
import { APP_COLORS } from '../../../../../global-styles';

@Component({
  selector: 'ets-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  constructor(private fileManagerService: FileManagerService) { }

  APP_COLORS = APP_COLORS;
  fileName: string | undefined;
  showDeleteModal = false;

  file = false;
  disableButton: boolean = true;
  error: boolean = false;
  fileSize: string = '0';

  toggleDeleteFileModal() {
    this.showDeleteModal = true;
  }

  deleteFile() {
    if (this.fileName) {
      this.fileManagerService.deleteFile(this.fileName).subscribe(response => {
        this.fileName = undefined;
      });
    }
    this.closeModal();
  }

  closeModal() {
    this.showDeleteModal = !this.showDeleteModal;
  }

  async onFileChange(event: any) {
    let file = event.target.files[0];
    this.file = true;
    this.error = false;
    this.fileName = file.name;
    this.fileSize = (file.size / (1024 ** 2)).toFixed(2); //bytes to MegaBytes

    if (file.type === 'application/pdf'){
      this.error = false;
      this.fileManagerService.calculateUploadUrl(file.name,file.type).subscribe(async (fileManagerResponse: { folder: any; uploadURL: any; }) => {
        console.log('fileManagerResponse', fileManagerResponse);
        const folder = fileManagerResponse.folder;
        const newFile = new File([file], folder + '/'+file.name, {type: file.type});

        const response = await fetch(fileManagerResponse.uploadURL,{
          method: 'PUT',
          headers: new Headers({'Content-Type': 'application/pdf'}),
          body: newFile
        });
        console.log('response', response);
      });
      this.disableButton = false;
    } else {
      // not a PDF
      this.error = true;
    }
  }

  ngOnInit(): void {
    this.fileManagerService.listFiles().subscribe(files => {
      const fileFound = files.find((file: string) => !!file);
      if (fileFound) {
        this.fileName = fileFound;
      }
    });
  }

}
