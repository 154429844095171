import { Component } from '@angular/core';

@Component({
  selector: 'ets-coachability',
  templateUrl: './coachability.component.html',
  styleUrls: ['./coachability.component.scss']
})
export class CoachabilityComponent {

  constructor() { }

}
