<div class="page-container clr-col-lg-8 clr-col-sm-12 clr-col-12">

  <div class="title">
    <span>Ideal Workplace Settings</span>
  </div>

  <div class="section-wrapper">
    <div class="section-title">
      <span>New career preferences</span>
    </div>
  </div>

  <form [formGroup]="workPreferenceForm" id="work-preference-form" (ngSubmit)="saveChanges()">

    <div class="option-vertical-container">
      <label for="location" class="option-title">Your location</label>
      <input id="location" class="text-box" formControlName="zipCode">
    </div>

    <div class="option-container">
      <div class="option-data-wrapper clr-col-lg-8 clr-col-md-8 clr-col-sm-12 clr-col-12 padding-0">
        <label for="desired-remote-work" class="option-title">Are you open to remote work?</label>
      </div>
      <div class="input-container clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-12 padding-0">
        <clr-select-container class="margin-top-0">
          <select id="desired-remote-work" clrSelect name="desiredRemoteWork" formControlName="desiredRemoteWork">
            <option [selected]="openToRemoteStatus === userProfile?.desiredRemoteWork"
                    class="select-options"
                    value="{{openToRemoteStatus}}"
                    *ngFor='let openToRemoteStatus of OPEN_TO_REMOTE_WORK'
            >
              {{openToRemoteStatus}}
            </option>
          </select>
        </clr-select-container>
      </div>
    </div>

    <div class="option-container">
      <div class="option-data-wrapper clr-col-lg-8 clr-col-md-8 clr-col-sm-12 clr-col-12 padding-0">
        <label for="commute-distance" class="option-title">What distance are you willing to commute?</label>
      </div>
      <div class="input-container clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-12 padding-0">
        <clr-select-container class="margin-top-0">
          <select id="commute-distance" clrSelect name="desiredCommute" formControlName="desiredCommute">
            <option [selected]="desiredCommute === userProfile?.desiredCommute"
                    class="select-options"
                    value="{{desiredCommute}}"
                    *ngFor='let desiredCommute of DESIRED_COMMUTE'
            >
              {{desiredCommute}}
            </option>
          </select>
        </clr-select-container>
      </div>
    </div>

    <div class="section-wrapper section-margin-top">
      <div class="section-title">
        <span>Lifestyle Preferences</span>
      </div>
    </div>

    <div class="option-container">
        <div class="option-data-wrapper clr-col-lg-8 clr-col-md-8 clr-col-sm-12 clr-col-12 padding-0">
          <span class="option-title">What is your desired salary range?</span>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-12 padding-0">
            <clr-select-container class="margin-top-0">
              <select clrSelect name="desiredSalary" formControlName="desiredSalary">
                <option [selected]="desiredSalary === userProfile?.desiredSalary"
                        class="select-options"
                        value="{{desiredSalary}}"
                        *ngFor='let desiredSalary of DESIRED_SALARY_RANGE'
                >
                  {{desiredSalary}}
                </option>
            </select>
            </clr-select-container>
        </div>
    </div>

    <div class="option-container">
        <div class="option-data-wrapper clr-col-lg-8 clr-col-md-8 clr-col-sm-12 clr-col-12 padding-0">
          <span class="option-title">How many hours per week do you want to work?</span>
        </div>
        <div class="input-container clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-12 padding-0">
            <clr-select-container class="margin-top-0">
                <select clrSelect name="desiredWorkHoursPerWeek" formControlName="desiredWorkHoursPerWeek">
                    <option [selected]="workHours === userProfile?.desiredWorkHoursPerWeek"
                            class="select-options" value="{{workHours}}"
                            *ngFor='let workHours of WORK_HOURS_PER_WEEK'
                    >
                      {{workHours}}
                    </option>
                </select>
            </clr-select-container>
        </div>
    </div>
  </form>

  <div class="button-wrapper mobile-hide">
      <ets-rounded-contained-button form="work-preference-form" (clickEvent)="saveChanges()" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'"></ets-rounded-contained-button>
    </div>

  <div class="button-wrapper non-mobile-hide">
      <ets-rounded-contained-button form="work-preference-form" (clickEvent)="saveChanges()" [fullWidth]="true" [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'Save Changes'"></ets-rounded-contained-button>
  </div>

</div>
