import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lowerFirst } from 'lodash';
import { TT_facts_A, TT_facts_B, BOT_facts_A, BOT_facts_B, shared_fact_options_list } from 'src/app/data/sne-hidden-profile-text';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SneAnswerRequest, SNE_TASK_ENUM } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { CPS_HRID } from '../../sne-v2.helpers';
import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';

@Component({
  selector: 'ets-hidden-profile',
  templateUrl: './hidden-profile.component.html',
  styleUrls: ['./hidden-profile.component.scss']
})
export class HiddenProfileComponent extends SneCollaborativeProblemSolvingBase implements OnInit {

  constructor(protected route: ActivatedRoute, protected router: Router, protected sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  APP_COLORS = APP_COLORS;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  visible = false;
  showModal = false
  questionIndex = 2;
  moduleSize = 3;
  page: string | null = '';

  //known facts
  userFactA = TT_facts_A;
  userFactB = TT_facts_B;
  botFactA = BOT_facts_A;
  botFactB = BOT_facts_B;

  // Stores all the Facts with associated scores
  TT_fact_relevance_map = new Map();

  sharedFactResponse = shared_fact_options_list;

  A_score = 0;
  B_score = 0;
  bot_A_score = 0;
  bot_B_score = 0;

  button_selected:any;
  pending_fact:any;
  bot_statement:any;

  // Fields used for the Pick Vendor Action
  vendorSelected: string = '';
  pickVendorSubmitBtnText = 'Next';

  // Flags for remaining facts
  remaining_vendor_A_facts = true;
  remaining_vendor_B_facts = true;

  // Flag for remaining facts to share
  remaining_facts_to_share = true;

  // Flags for disabling submit buttons
  shareFactSubmitBtnDisabled = true;
  requestFactSubmitBtnDisabled = true;
  pickVendorSubmitBtnDisabled = true;

  // Flags for displaying section
  requestInfo = false;
  shareInfo = false;
  pickVendor = false;
  requestFactVendorA = false;
  requestFactVendorB = false;
  showRequestFactConfirmation = false;
  showShareFactConfirmation = false;
  showPickVendorConfirmation = false;
  optionsMenu = true;
  quizInProgress = false;
  /////////////////////////////

  vendors: string[] =['A', 'B']

  vendorAFacts:string[]=['Provides free software updates for the entire duration of the contract term','Has placed several bids on US government contracts','Actively promotes its products on social media'];
  vendorBFacts:string[]=['Maintains its corporate headquarters in the Netherlands','Makes software documentation available in many languages','Offers a 15% discount if the contract is signed for 2+ years'];

  shareVendorForm = new FormGroup({
    shareVendorRadio: new FormControl()
  });

  shareFactResponseForm = new FormGroup({
    shareFactRadio: new FormControl()
  });

  redirectRequestInfo() {
    this.requestInfo = true;
    this.shareInfo = false;
    this.pickVendor = false;
    this.optionsMenu = false;
    this.bot_statement = 'No problem. Do you want information about Vendor A or about Vendor B?\n\nOnce I share a fact with you, it will be added to the list and shaded yellow to indicate that we both know it.';
  }

  redirectShareInfo() {
    this.requestInfo = false;
    this.shareInfo = true;
    this.pickVendor = false;
    this.optionsMenu = false;
    this.bot_statement = 'Select the fact that you would like to share with me.\n\nOnce shared, the fact will be shaded in the facts panel to indicate that we both know it.';
  }

  redirectPickVendor() {
    this.requestInfo = false;
    this.shareInfo = false;
    this.pickVendor = true;
    this.optionsMenu = false;
    this.bot_statement = 'OK, sure, which vendor do you want to pick?';
  }

  redirectOptionsMenu() {
    this.requestInfo = false;
    this.shareInfo = false;
    this.pickVendor = false;
    this.optionsMenu = true;
  }

  //method for redirecting page
  tellMoreVendorA(){
    this.requestFactVendorA = true;
    this.requestFactVendorB = false;
    this.requestInfo = false;
    this.shareInfo = false;
    this.pickVendor = false;
    this.optionsMenu = false;
  }

  tellMoreVendorB(){
    this.requestFactVendorA = false;
    this.requestFactVendorB = true;
    this.requestInfo = false;
    this.shareInfo = false;
    this.pickVendor = false;
    this.optionsMenu = false;
  }

  resetState(){
    this.requestFactVendorA = false;
    this.requestFactVendorB = false;
    this.showRequestFactConfirmation = false;
    this.showShareFactConfirmation = false;
    this.showPickVendorConfirmation = false;
    this.requestInfo = false;
    this.shareInfo = false;
    this.pickVendor = false;
    this.optionsMenu = true;
    this.shareFactSubmitBtnDisabled = true;
    this.requestFactSubmitBtnDisabled = true;
    this.pickVendorSubmitBtnDisabled = true;
    this.vendorSelected = '';
  }

  toggleModal() {
    this.showModal=!this.showModal;
  }

  shareFactResponseClick() {
    this.shareFactSubmitBtnDisabled = false;
  }

  requestFactResponseClick() {
    this.requestFactSubmitBtnDisabled = false;
  }

  pickVendorResponseClick(vendor: string) {
    this.vendorSelected = vendor;
    this.showPickVendorConfirmation = true;
    this.quizInProgress = true;

    // Determine what the bot should say
    this.computeTally();
    if (this.vendorSelected === 'A') {
    	if (this.bot_A_score > this.bot_B_score) {
        this.bot_statement = 'Yes, I agree. The information I have shows that Vendor A is the best choice. Shall we go ahead and pick A, then?';
      } else if (this.bot_A_score < this.bot_B_score) {
        this.bot_statement = 'I disagree. As far as I can tell, Vendor B is the best candidate. Are you confident enough to pick Vendor A anyway?';
      } else {
        this.bot_statement = 'I am not sure. Based on the information I have, they seem the same to me. If you know more than I do, then go ahead and pick Vendor A.';
      }

    } else if (this.vendorSelected === 'B') {

      if (this.bot_A_score > this.bot_B_score) {
        this.bot_statement = 'Hmmm... Based on what I know, I see Vendor A as a better choice. Are you confident enough to pick Vendor B anyway?';
      } else if (this.bot_A_score < this.bot_B_score) {
        this.bot_statement = 'Yes, I agree. The information I have shows that Vendor B is the best choice. Shall we go ahead and pick B, then?';
      } else {
        this.bot_statement = 'I am not sure. Based on the information I have, they seem the same to me. If you know more than I do, then go ahead and pick Vendor B.';
      }

    }
  }

  confirmVendorPick() {
    if (this.vendorSelected === 'A' || this.vendorSelected === 'B') {
      // Calculate the scores and submit the action record
      this.computeTally();

      let rel_A_facts_unshared = 0;
      this.userFactA.forEach((fact) => {
        if (fact.private === 'P' && fact.impact !== 0) {
          rel_A_facts_unshared += 1;
        }
      });

      let rel_B_facts_unshared = 0;
      this.userFactB.forEach((fact) => {
        if (fact.private === 'P' && fact.impact !== 0) {
          rel_B_facts_unshared += 1;
        }
      });

      let action_record = {
        'action': 'vendor_selection_finalized',
        'vendor_id': this.vendorSelected,
        'A_facts_known': this.userFactA.length,
        'B_facts_known': this.userFactB.length,
        'rel_A_facts_unshared': rel_A_facts_unshared,
        'rel_B_facts_unshared': rel_B_facts_unshared,
        'TT_A_score': this.A_score,
        'TT_B_score': this.B_score,
        'BOT_A_score': this.bot_A_score,
        'BOT_B_score': this.bot_B_score
      };

      let data: {[key: string]: any} = {};
      data[Date.now().toString()] = action_record;
      this.saveAction(data, true);
    }
  }

  reconsiderVendorPick() {
    let action_record = {
      'action': 'reconsider',
    };

    let data: {[key: string]: any} = {};
    data[Date.now().toString()] = action_record;
    this.saveAction(data);
    this.resetState();
  }

  // Function to calculate the score
  computeTally() {
    this.userFactA.forEach(fact => {
      this.A_score += <number>fact.impact;
    });
    this.userFactB.forEach(fact => {
      this.B_score += <number>fact.impact;
    });
    this.botFactA.forEach(fact => {
      this.bot_A_score += <number>fact.impact;
    });
    this.botFactB.forEach(fact => {
      this.bot_B_score += <number>fact.impact;
    });
  }

  get_A_fact() {
    const newFact = this.botFactA.find(fact => fact.private === 'P');

    if (newFact) {
      newFact.private = 'S';
      this.pending_fact = newFact;

      this.bot_statement = 'OK, I know that Vendor A ' + lowerFirst(newFact.content) + '; is this information helpful?';

      this.tellMoreVendorA();
    } else {
      this.remaining_vendor_A_facts = false;
    }

  }

  get_B_fact() {
    const newFact = this.botFactB.find(fact => fact.private === 'P');

    if (newFact) {
      newFact.private = 'S';
      this.pending_fact = newFact;

      // let lowerCaseStatement = (<string>this.botFactB[index][0]).charAt(0).toLowerCase() + (<string>this.botFactB[index][0]).slice(1);
      this.bot_statement = 'OK, I know that Vendor B ' + lowerFirst(newFact.content) + '; is this information helpful?';

      this.tellMoreVendorB();
    } else {
      this.remaining_vendor_B_facts = false;
    }


  }

  addFact() {
    const radioValue = this.shareFactResponseForm.controls.shareFactRadio.value;
    const resIndex = this.sharedFactResponse.findIndex(res => res.id === radioValue);

    if (radioValue && !isNaN(resIndex)) {
      let action_record = {
        'action': 'radio_confirm',
        'req_fact_id': this.pending_fact.id,
        'relevance': this.pending_fact.impact,
        'overlap': this.pending_fact.overlap,
        'radio_sel': resIndex.toString()
      };

      let data: {[key: string]: any} = {};
      data[Date.now().toString()] = action_record;
      this.saveAction(data);

      let candidate = this.pending_fact.id.charAt(2);

      let TT_knows_it = false;

      if (candidate === 'A') {
        const factFound = this.userFactA.find(fact => fact.content === this.pending_fact.content);
        TT_knows_it = !!factFound;

        if (TT_knows_it) {
          const userFactFound = this.userFactA.find(fact => fact.content === this.pending_fact.content);
          if (userFactFound) {
            userFactFound.private = 'S';
          }


          const botFactFound = this.botFactA.find(fact => fact.content === this.pending_fact.content);
          if (botFactFound) {
            botFactFound.private = 'S';
          }


        } else {
          this.userFactA.push(this.pending_fact);
        }

      } else if (candidate === 'B') {
        const factFound = this.userFactB.find(fact => fact.content === this.pending_fact.content);
        TT_knows_it = !!factFound;

        if (TT_knows_it) {
          const userFactFound = this.userFactA.find(fact => fact.content === this.pending_fact.content);
          if (userFactFound) {
            userFactFound.private = 'S';
          }


          const botFactFound = this.botFactB.find(fact => fact.content === this.pending_fact.content);
          if (botFactFound) {
            botFactFound.private = 'S';
          }

        } else {
          this.userFactB.push(this.pending_fact);
        }

      }

      // Set bot statement and show confirmation page
      this.bot_statement = this.sharedFactResponse[resIndex].response;
      this.shareFactResponseForm.controls.shareFactRadio.setValue(undefined); // clear radio selection
      this.showRequestFactConfirmation = true; // show the continue page
      this.quizInProgress = true; // set the quiz as in progress
    }
  }

  continue() {
    // Reset state and close drawer if possible
    this.resetState();
  }

  shareFact() {
    let factToShare = null;
    // let radioBtns = document.getElementsByClassName('radio-btn');
    const factId = this.shareVendorForm.controls.shareVendorRadio.value;
    const candidate = factId.charAt(2);
    const factIndex = Number.parseInt((factId).charAt(3));
    if (candidate === 'A') {
      factToShare = this.userFactA[factIndex];
    } else if (candidate === 'B') {
      factToShare = this.userFactB[factIndex];
    }


    if (factToShare) {
      let action_record = {
        'action': 'fact_share',
        'shared_fact_id': factToShare.id,
        'relevance': this.TT_fact_relevance_map.get(factToShare.id)
      };

      let data: {[key: string]: any} = {};
      data[Date.now().toString()] = action_record;
      this.saveAction(data);


      // Show fact is shared now
      factToShare.private = 'S';

      let candidate = (<string>factToShare.id).charAt(2);
      let fact_index = Number.parseInt((<string>factToShare.id).charAt(3));

      let fact = null;
      let BOT_knows_it = false;

      if (candidate === 'A') {
        this.userFactA[fact_index].private = 'S';
        fact = this.userFactA[fact_index];

        for (var i = 0; i < this.botFactA.length; i++) {
          if (factToShare.content === this.botFactA[i].content){
            this.botFactA[i].private = 'S';
            BOT_knows_it = true;
            break;
          }
        }
      } else if (candidate === 'B') {
        this.userFactB[fact_index].private = 'S';
        fact = this.userFactB[fact_index];

        for (var i = 0; i < this.botFactB.length; i++) {
          if (factToShare.content === this.botFactB[i].content){
            this.botFactB[i].private = 'S';
            BOT_knows_it = true;
            break;
          }
        }
      }

      if (fact) {
        // fact[3] = "";

        if (!BOT_knows_it) {
          if (candidate === 'A') { // @ts-ignore
            this.botFactA.push(fact);
          } else if (candidate === 'B') { // @ts-ignore
            this.botFactB.push(fact);
          }

          this.bot_statement = 'Thanks! I did not know this about Vendor ' + candidate + '.';
        } else {
          this.bot_statement = 'Oh yes, this is something I already know about Vendor ' + candidate + '.';
        }

      }

      this.showShareFactConfirmation = true; // show the continue page
      this.quizInProgress = true; // set the quiz as in progress
      this.remaining_facts_to_share = this.checkForUnsharedFacts();
      this.shareVendorForm.controls.shareVendorRadio.reset();
    }
  }

  saveAction(data: any, completed = false) {
    let request: SneAnswerRequest = {
      task: SNE_TASK_ENUM.HIDDEN_PROFILE,
      data: data,
      completed: completed
    };

    this.sneAnswerService.postAction(request).subscribe({
      next: response => {
        if (completed) {
          this.navToNextPage();
        }
      }
    });
  }

  checkForUnsharedFacts() {
    for (let fact of this.userFactA) {
      if (fact.private === 'P') {
        return true;
      }

    }
    for (let fact of this.userFactB) {
      if (fact.private === 'P') {
        return true;
      }

    }
    return false;
  }

  ngOnInit() {
    // *** TEMPORARY: Delete any existing unfinished HP task until resume is implemented. ***
    // *** Otherwise, multiple start actions will be saved to the same HP record in the DB if the page refreshes causing scoring to fail ***
    this.sneAnswerService.deleteSneAssessmentRecords(CPS_HRID.CPS_HIDDEN_PROFILE).subscribe();

    this.processParams();

    this.userFactA.forEach((fact) => {
      this.TT_fact_relevance_map.set(fact.id, fact.impact);
    });

    this.userFactB.forEach((fact) => {
      this.TT_fact_relevance_map.set(fact.id, fact.impact);
    });

    let action_record = {
      'action': 'start_task',
    };

    let data: {[key: string]: any} = {};
    data[Date.now().toString()] = action_record;
    this.saveAction(data);
  }
}