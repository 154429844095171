<div class="container">
  <p class="post-text">SOCIAL MEDIA POSTS</p>
  
    <div class="post-list" etsScrollToBottom>
      <div class="post-container"
          *ngFor="let post of posts; let i = index;"
          [ngClass]="{ 'white-box': chooseColor(i, 'white'),
                        'blue-box': chooseColor(i, 'blue'),
                        'green-box': chooseColor(i, 'green')
                      }"
      >
        <div class="post-header">
          <img class="profile-pic" src="{{post?.image}}" alt="profile picture">
          <div class="poster-username-container">
            <p class="poster">{{post?.name}}</p>
            <p class="username">@{{post?.username}}</p>
          </div>
        </div>
        <!-- <div class="email-content-container"> -->
        <p class="post-content" [innerHTML]="post?.message"></p>
      </div>
    </div>
<!--  </div>-->
</div>
