import { SneQuestion } from '../../types/sne';
import OC_TYPES from '../../types/oral-communication-types';
import QUESTION_TYPES from '../../types/question-types';

export function generateNextQuestionPath(question: SneQuestion, key: string, sneType: OC_TYPES, additionalNextQuestionSteps?: () => void) {
  let path = '';
  if (question) {
    switch (question.questionType) {
      case QUESTION_TYPES.SNE_INTRO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path= `sne-new/oc/sne-intro/${key}/${sneType}`;
        // create path
        break;
      case QUESTION_TYPES.INTERSTITIAL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        // create path
        path = `sne-new/oc/interstitial/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_VIDEO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multiple-choice-video/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_LIST_VIDEO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multiple-choice-list-video/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MADLIBS_VIDEO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/madlibs-video-splitscreen/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_VIDEO_CHOICE_VIDEO:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multi-video-choice-video-splitscreen/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.MULTIPLE_CHOICE_FULL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multiple-choice-full/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.MULTIPLE_CHOICE_VIDEO_CHOICE_FULL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multiple-choice-video-choice-full/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.MULTIPLE_CHOICE_LIST_FULL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/multi-choice-list-full/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.MADLIBS_FULL:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/madlibs-full/${key}/${sneType}`;
        break;
      case QUESTION_TYPES.VIDEOS_AND_MULTI_CHOICE:
        if (additionalNextQuestionSteps) {
          additionalNextQuestionSteps();
        }
        path = `sne-new/oc/videos-and-multi-choice/${key}/${sneType}`;
        break;
        //ADD MORE AS COMPLETED
        /*case QUESTION_TYPES.FREE_RESPONSE:
                  this.additionalNextQuestionSteps();
                  // create path
                  break;
                case QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE:
                  this.additionalNextQuestionSteps();
                  // create path
                */
      default:
        console.error('Cannot find question type');
    }
  }
  return path;
}
