import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ChatMessage } from 'src/app/types/sne';
import { APP_COLORS } from '../../../../global-styles';

@Component({
  selector: 'ets-sne-conversation-log-text',
  templateUrl: './sne-conversation-log-text.component.html',
  styleUrls: ['./sne-conversation-log-text.component.scss']
})
export class SneConversationLogTextComponent {

  constructor() { }

  @Input() chatLog?: ChatMessage[] = [];
  @Input() headerText: string | undefined = 'Interaction History'; // Initiate to interaction history to not mess up coachability
  @Input() hintExists = false;
  @Input() firstColorIndex?: number;
  @Input() botPics?: Map<string, string> = new Map<string, string>();
  @Input() youProfilePic: string | undefined = 'assets/default-profile-images/default-profile-image-2.svg';
  @Input() botProfilePic: string | undefined = 'assets/default-profile-images/default-profile-image-1.svg';
  @Input() showBotPicsOnly = false;
  @Output() hintModalEvent = new EventEmitter<any>();
  @Input() showHeader: boolean = true;
  @Input() showComments: boolean = false;
  @Input() cpsCheck: boolean = false;
  @Input() colorArray: string[] = [];
  @Input() chatContainerPaddingBottom: string = '';
  APP_COLORS = APP_COLORS;

  hintModalClick(){
    this.hintModalEvent.emit();
  }
  chooseColor(messageIndex: number, intendedColor: string): boolean {
    const colorIndex = this.colorArray.findIndex(color => color === intendedColor);

    if (this.colorArray.length > 1) {
      if (messageIndex % 2 !== 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 === 0 && colorIndex !== -1;
      }
    } else {
      if (messageIndex % 2 === 0 && this.firstColorIndex === colorIndex) {
        return true;
      } else {
        return messageIndex % 2 !== 0 && colorIndex !== -1;
      }
    }
  }
}


