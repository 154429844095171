import { SneCollaborativeProblemSolvingBase } from '../sne-collaborative-problem-solving.base';
import { Component, OnInit } from '@angular/core';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { APP_COLORS } from 'src/global-styles';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { SneMulChoiceOption } from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';

@Component({
  selector: 'ets-cps-survey',
  templateUrl: './cps-survey.component.html',
  styleUrls: ['./cps-survey.component.scss']
})
export class CpsSurveyComponent extends SneCollaborativeProblemSolvingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  sortedClassifications: SneMulChoiceOption[] = [];
  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  QUESTION_TYPES = QUESTION_TYPES
  // Array of booleans for Disagree questions to be used to disable next button
  disagreeAnswersBooleanArr: boolean[] | undefined;

  selectAnswer(questionIndex: number, answer: string, marquisAnswer: string) {
    if (answer) {
      if (this.questionData?.multipleMulChoiceOptions?.[questionIndex].marquisClassification) {
        if (answer === 'B') {
          this.questionData.multipleMulChoiceOptions[questionIndex].isDisagree = true;
          if (this.disagreeAnswersBooleanArr) {
            // Disagree answer selected for this question, disable next button for this index
            this.disagreeAnswersBooleanArr[questionIndex] = true;
          }
        } else {
          this.questionData.multipleMulChoiceOptions[questionIndex].isDisagree = false;
          this.multiResponse[questionIndex] = marquisAnswer;
          if (this.disagreeAnswersBooleanArr) {
            // Agree answer selected for this question, enable next button for this index
            this.disagreeAnswersBooleanArr[questionIndex] = false;
          }
        };
      } else {
        this.multiResponse[questionIndex] = answer;
      };
    };
  };

  handleDropdown(event: any, questionIndex: number) {
      if (event.target.value !== '?') {
        if (this.questionData?.multipleMulChoiceOptions?.[questionIndex].isDisagree) {
          this.multiResponse[questionIndex] = event.target.value;
          if (this.disagreeAnswersBooleanArr) {
            // Answer selected for this Disagree question, enable next button for this index
            this.disagreeAnswersBooleanArr[questionIndex] = false;
          }
        }
      }
      else {
        if (this.disagreeAnswersBooleanArr) {
          // Answer NOT selected for this Disagree question, disable next button for this index
          this.disagreeAnswersBooleanArr[questionIndex] = true;
        }
      }
  }

  disableNextButton(): boolean {
    let disable = false;
    if (this.multiResponse.length === this.questionData?.numSelectOptions) {
      this.multiResponse.forEach((answer: string) => {
        // if answer is not set for any question then disable next button
        if (!answer && !disable) {
          disable = true;
        }
      });
    }
    else {
      disable = true;
    }

    return disable || (this.disagreeAnswersBooleanArr !== undefined && this.disagreeAnswersBooleanArr.includes(true));
  }

  filterOutClassificationFromDropdown() {
    if (this.cpsData) {
      if (this.questionData?.multipleMulChoiceOptions) {
        this.questionData.multipleMulChoiceOptions.reduce((arr, question) => {
          if (question.mulChoiceOptions[1]) {
            let filteredClassifications = question?.mulChoiceOptions[1]?.response.filter((classification: SneMulChoiceOption) => classification?.userOption !== question.marquisClassification?.userOption);
            arr.push(filteredClassifications)
          }
          return arr;
        }, this.sortedClassifications)
      }
    }
  };
  
  ngOnInit() {
    this.processParams();
    if (this.questionData?.questionType === QUESTION_TYPES.MULTI_CHOICE_DROPDOWN) {
      this.filterOutClassificationFromDropdown();
      if (this.questionData.multipleMulChoiceOptions) {
        this.disagreeAnswersBooleanArr = new Array(this.questionData.multipleMulChoiceOptions.length).fill(true);
      }
    }
    
    if (this.questionData?.numSelectOptions && this.questionData?.numSelectOptions > 1) {
      this.multiResponse = new Array(this.questionData.numSelectOptions).fill(null);
    }
  }

}
