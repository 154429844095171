<div role="region" aria-live="polite" [class]="marquis ? 'marquis-container' : 'container'">
  <img *ngIf="image && !marquis" src="{{image}}" alt="" aria-hidden="true" class="image">

  <!-- <div [class]="marquis ? 'inner-marquis' : 'inner' "> -->
  <div [class]="marquis ? 'marquis-info-wrapper' : 'info-wrapper' ">
    <h1 *ngIf="questionIndex !== undefined" class="task">Task {{questionIndex}}/{{moduleSize}}</h1>
    <h1 [class]="marquis ? 'title-marquis' : 'title'" [ngClass]="{'bold-text': marquis && questionIndex === undefined}"
      [innerHTML]="title"></h1>

    <div *ngIf="marquis"
      [ngClass]="{'blueBox': marquis && questionIndex !== undefined, 'cps-box': marquis && questionIndex === undefined}">
      <img src="/assets/sne/cps/marquis-avatar.png" alt="" aria-hidden="true" class="marquis-img" />
      <div class="flex">
        <h1 class="marquis-title">Marquis</h1>
        <p class="marquis-info">{{info}}</p>
      </div>
    </div>
    <img *ngIf="marquis && questionIndex === undefined" src="{{image}}" alt="" aria-hidden="true" class="image">
    <p *ngIf="!marquis" class="blurb" [innerHTML]="info">{{info}}</p>

    <div *ngIf="duration !== undefined" class="duration-container" [ngClass]="{
          'mangotango': durationBoxColor === 'mangotango',
          'lbb':  durationBoxColor === 'lbb',
          'mustard-yellow': durationBoxColor === 'mustard-yellow',
          'aquamarine': durationBoxColor === 'aquamarine'
        }">
      <img src="/assets/duration-timer.svg" alt="" aria-hidden="true" class="duration-icon" />
      <h1 class="duration" [innerHTML]="duration"></h1>
    </div>

    <ets-solid-button *ngIf="showButton" (clickEvent)="handleClick()" [text]="buttonText" [color]="buttonColor"
      class="action-button"></ets-solid-button>
  </div>
</div>
