<ets-header [headerState]="HEADER_STATES.DASHBOARD" [isBGWhite]="true"></ets-header>
<div class="container">
  <ets-dashboard-side-bar
    [profile]="profile"
    [dashboardProfile]="dashboardProfile"
    [topSkills]="topSkills"
    [skillsAssessments]="assessments"
    [assessmentRecords]="assessmentRecords"
  ></ets-dashboard-side-bar>
  <div class="main">
    <div class="section top-section">
      <ets-dashboard-progress-bar [progressCompletePercent]="1" [section]="1"></ets-dashboard-progress-bar>
      <ets-dashboard-careers [careers]="careers"></ets-dashboard-careers>
    </div>
    <div class="section">
      <ets-dashboard-progress-bar
        [section]="2"
        [progressCompletePercent]="(numOfSkillAssessments/(assessments.length - 2))"
        [multiStep]="true"
        [onCurrentSection]="skillAssessmentsStarted"
      ></ets-dashboard-progress-bar>
      <ets-dashboard-skills class="assessments-container"
                            [skillAssessments]="assessments"
                            [assessmentRecords]="assessmentRecords"
      ></ets-dashboard-skills>
    </div>
    <div class="section">
      <ets-dashboard-progress-bar
        [section]="3"
        [progressCompletePercent]="numOfBehaviorAssessments/2"
        [onCurrentSection]="behaviorAssessmentsStarted"
        [multiStep]="true"
        [lastSection]="true"
      ></ets-dashboard-progress-bar>
      <ets-dashboard-behavior-interests
        [facetsResults]="facetsResults"
        [cipResults]="cipResults"
      ></ets-dashboard-behavior-interests>
    </div>
  </div>
</div>
