import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
// import { LearnerCareerService } from '../../services/learner-career.service';
import { selectOpenSesame, selectEmail } from '../../store';
import { setProfile } from '../../store/profile.actions';
import { APP_COLORS } from 'src/global-styles';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ets-confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.scss']
})
export class ConfirmationCodeComponent implements OnInit {

  APP_COLORS = APP_COLORS;
  faCheckCircle = faCheckCircle;
  resendCodeText = "Resend code";

  // Error state flags used to display error messages on the UI
  confirm_invalidCode = false;
  confirm_userAlreadyConfirmed = false;
  confirm_unknownErrorFlag = false;

  // Flags to display various elements on the UI
  showSignUp = false;
  showSignInOnHeader = false;
  showPasswordReset = false;
  showConfirmPasswordReset = false;
  signingInSpinnerFlag = false;

  // Form for the confirmation page
  confirmationCodeForm = new FormGroup({
    // Fields for each individual input on the UI - validation pattern for below fields is set in template
    code1: new FormControl('', [Validators.required]),
    code2: new FormControl('', [Validators.required]),
    code3: new FormControl('', [Validators.required]),
    code4: new FormControl('', [Validators.required]),
    code5: new FormControl('', [Validators.required]),
    code6: new FormControl('', [Validators.required]),
  });

  // These fields are used to transfer the email/password fields between forms/methods
  email!: string;
  password!: string;

  // constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService, private learnerCareerService: LearnerCareerService) {}
  constructor(private ref: ChangeDetectorRef, private router: Router, private store: Store, private authService: AuthService, private profileService: ProfileService) {}
  ngOnInit() {
    this.store.select(selectEmail)
      .subscribe(email => {
        if (email) {
          this.email = email;
        }
      });

    this.store.select(selectOpenSesame)
      .subscribe(openSesame => {
        if (openSesame) {
          this.password = openSesame;
        }
      });

    // Getting each individual code element from the DOM
    let code1 = document.getElementById('code1');
    let code2 = document.getElementById('code2');
    let code3 = document.getElementById('code3');
    let code4 = document.getElementById('code4');
    let code5 = document.getElementById('code5');
    let code6 = document.getElementById('code6');
    
    this.confirmationCodeForm.get('code1')?.valueChanges.subscribe(val => {
      if (val !== '' && code2) {
        // Logic for if they copy and paste a code into the first field
        if (val.length === 6) {
          if (code6) {
            this.confirmationCodeForm.controls.code1.setValue(val[0]);
            this.confirmationCodeForm.controls.code2.setValue(val[1]);
            this.confirmationCodeForm.controls.code3.setValue(val[2]);
            this.confirmationCodeForm.controls.code4.setValue(val[3]);
            this.confirmationCodeForm.controls.code5.setValue(val[4]);
            this.confirmationCodeForm.controls.code6.setValue(val[5]);
            code6.focus();
          }
        }
        else {
          code2.focus();
        }
      }
    });
    this.confirmationCodeForm.get('code2')?.valueChanges.subscribe(val => {
      // if val is empty, go back
      if (val === '' && code1) {
        code1.focus();
      }

      if (val !== '' && code3) {
        code3.focus();
      }
    });
    this.confirmationCodeForm.get('code3')?.valueChanges.subscribe(val => {
      // if val is empty, go back
      if (val === '' && code2) {
        code2.focus();
      }

      if (val !== '' && code4) {
        code4.focus();
      }
    });
    this.confirmationCodeForm.get('code4')?.valueChanges.subscribe(val => {
      // if val is empty, go back
      if (val === '' && code3) {
        code3.focus();
      }

      if (val !== '' && code5) {
        code5.focus();
      }
    });
    this.confirmationCodeForm.get('code5')?.valueChanges.subscribe(val => {
      // if val is empty, go back
      if (val === '' && code4) {
        code4.focus();
      }

      if (val !== '' && code6) {
        code6.focus();
      }
    });
    this.confirmationCodeForm.get('code6')?.valueChanges.subscribe(val => {
      // if val is empty, go back
      if (val === '' && code5) {
        code5.focus();
      }
    });
  }

  async confirmSignUp() {
    try {
      this.resetUiErrorFlags();
      let verificationCode = this.confirmationCodeForm.controls.code1.value + 
        this.confirmationCodeForm.controls.code2.value + 
        this.confirmationCodeForm.controls.code3.value + 
        this.confirmationCodeForm.controls.code4.value + 
        this.confirmationCodeForm.controls.code5.value + 
        this.confirmationCodeForm.controls.code6.value;

      // Call confirm sign up
      await this.authService.confirmSignUp(this.email, verificationCode);
      // Call sign in to actually sign the user in - await is needed to ensure the call completes successfully prior to setting the userSignedInFlag
      await this.authService.signIn(this.email, this.password);
      // user is signed in, route to onboarding
      this.getProfileAndRoute();
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'Invalid verification code provided, please try again.':
            this.confirm_invalidCode = true;
            break;
          case 'Invalid code provided, please request a code again.':
            this.confirm_invalidCode = true;
            break;
          case 'User cannot be confirmed. Current status is CONFIRMED':
            this.confirm_userAlreadyConfirmed = true;
            break;
          default:
            this.confirm_unknownErrorFlag = true;
        }
      }
    }
  }

  async resendConfirmationCode() {
    try {
      this.resetUiErrorFlags();

      // Call resend to send the code email
      await this.authService.resendConfirmationCode(this.email);
      this.resendCodeSuccessful();
    } catch (error) {
      console.log('error resending the confirmation code', error);
    }
  }

  // Method to change the text on the resend code button
  resendCodeSuccessful() {
    this.resendCodeText = "Code sent";
    setTimeout(() => {
      this.resendCodeText = "Resend code";
    }, 5000);
  }

  getProfileAndRoute() {
    this.profileService.getProfile().subscribe(profile => {
      this.store.dispatch(setProfile({payload: profile}));
      // this.learnerCareerService.populateLearnerCareers().subscribe();
      this.router.navigate(['/on-boarding']);
    });
  }

  // Method to go to sign in page
  gotoSignIn() {
    this.router.navigate(['/auth/sign-in']);
  }

  // Method to reset UI error flags to false (hide them)
  resetUiErrorFlags() {
    this.confirm_invalidCode = false;
    this.confirm_userAlreadyConfirmed = false;
    this.confirm_unknownErrorFlag = false;
  }

}
