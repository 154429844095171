import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ets-new-collaborative-problem-solving',
  templateUrl: './collaborative-problem-solving-component-v2.component.html',
  styleUrls: ['./collaborative-problem-solving-component-v2.component.scss']
})
export class CollaborativeProblemSolvingComponentV2 implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
