import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CareerPrioritizationRequest, CareerPrioritizationResponse, LearnerCareersResponse } from '../types/career-prioritization';
import { LogObject } from '../types/log';
import { LogService } from './log.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CareerPrioritizationService extends BaseService{

  private careerPrioritizationURL = environment.careerChoicesAPI;

  constructor(private http: HttpClient, protected logService: LogService) { 
    super(logService);
    this.serviceName = 'Career Prioritization';
  }

  getLearnerCareers(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      return this.http.get<HttpResponse<LearnerCareersResponse>>(this.careerPrioritizationURL, {headers: headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CP: Get Learner Careers Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }

  updateLearnerCareers(requestBody: CareerPrioritizationRequest): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      // “?showDismissed=true” can be used to show the dismissed Learner_Career records.  
      // It defaults to false if the flag is not sent

      let body = requestBody;
      let requestLogObj: LogObject = {
        message: 'CP: Post Learner Career Request',
        object: body,
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<CareerPrioritizationResponse>(this.careerPrioritizationURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CP: Post Learner Career Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }
}
