import {
  SneModal,
  SneMulChoiceOption,
  SneQuestion,
  SNE_INPUT_TYPES,
} from '../../../types/sne';
import CPS_TYPES from 'src/app/types/cps-types';
import QUESTION_TYPES from 'src/app/types/question-types';

export const CPSSurveyClassifications: SneMulChoiceOption[] = [
  {
    userOption: 'Cost+',
    userLetter: 'A',
  },
  {
    userOption: 'Cost-',
    userLetter: 'B',
  },
  {
    userOption: 'Quality+',
    userLetter: 'C',
  },
  {
    userOption: 'Quality-',
    userLetter: 'D',
  },
  {
    userOption: 'Not Relevant',
    userLetter: 'E',
  },
];

export const CPSSurveyAgreeOrDisagree: SneMulChoiceOption[] = [
  {
    userOption: 'Agree',
    userLetter: 'A',
  },
  {
    userOption: 'Disagree',
    userLetter: 'B',
    response: [...CPSSurveyClassifications],
  },
];

export const CPSSurveyData: Map<string, SneQuestion> = new Map([
  [ 
    'intro',{
      questionType: QUESTION_TYPES.SNE_INTRO,
      title: 'Collaborative Problem Solving',
      info: 'In this section, we will guide you through three different tasks. Each task will help you understand how you work with others to resolve issues and how that applies to the careers that interest you.\n\nReady to get started?',
      image: '/assets/sne/cps/cps-intro.png',
      buttonText: 'BEGIN!'
    }
  ],
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Fact Sorting',
      info: 'This task will give you insight into how you interpret information to help reach an agreement, and we\'ll use what we learn together to enhance your Skills Profile.',
      duration:
        '<span class="boldText">Duration:</span> This should take about 10 minutes, and you\'ll have a chance to pause when you finish. Find a quiet place where you can focus, and select "Next" to begin.',
      questionIndex: 1,
      moduleSize: 3,
      durationBoxColor: 'mustard-yellow',
      image: '/assets/section-1/sne-interstitial/fact-sorting.svg',
      buttonText: 'Next',
      modalBeforeNext: true,
    },
  ],
  [
    'your-turn-1',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: '',
      info: 'It\'s your turn first to review facts about Firm A and make classifications.',
      image: '/assets/section-1/sne-interstitial/fact-sorting.svg',
      marquis: true,
      buttonText: 'Next',
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.MULTI_MULTIPLE_CHOICE,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'Facts about Firm A',
      subtitle: 'Please classify each of the facts by selecting the appropriate option.',
      info: 'Hey! Here\'s the first set of facts about Firm A. Can you do the initial classification?',
      marquis: true,
      numSelectOptions: 4,
      multipleMulChoiceOptions: [
        {
          question: '<span class="boldText">Fact:</span> Has received five-star ratings from many past clients',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Conducts follow-up meetings to answer questions and provide further support',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question: '<span class="boldText">Fact:</span> Has an active social media presence',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Is the most expensive of the firms your company is considering',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'your-turn-2',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: '',
      info: 'I\'ll show you my four facts about Firm B. I entered my classifications for these on the next screen.',
      image: '/assets/section-1/sne-interstitial/fact-sorting.svg',
      marquis: true,
      buttonText: 'Next',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.MULTI_CHOICE_DROPDOWN,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'Facts about Firm B',
      subtitle: 'It\'s your turn first to review facts about Firm B and make classifications.',
      info: 'Now here are some facts about Firm B. I went ahead and entered my proposed classifications. If you disagree with my choice, suggest a different label.',
      marquis: true,
      numSelectOptions: 4,
      multipleMulChoiceOptions: [
        {
          question:
            '<span class="boldText">Fact:</span> Has several inconsistencies in product descriptions on its website',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[3],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Conducts a thorough interview with each new client firm to establish a clear understanding of its goals',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[2],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Comes highly recommended by other companies in  your industry',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[2],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Offers a 10 percent discount on all virtual workshop sessions',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[1],
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'your-turn-3',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: '',
      info: 'I\'ll show you my four additional facts about Firm A. I entered my classifications for these on the next screen.',
      image: '/assets/section-1/sne-interstitial/fact-sorting.svg',
      marquis: true,
      buttonText: 'Next',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.MULTI_CHOICE_DROPDOWN,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'Facts about Firm A',
      subtitle: 'Indicate whether you agree or disagree with Marquis’ classifications.',
      info: 'I went ahead and entered my proposed classifications. Indicate whether you agree or disagree with my choices. If you disagree, you can suggest a different label for that fact.',
      marquis: true,
      numSelectOptions: 4,
      multipleMulChoiceOptions: [
        {
          question: '<span class="boldText">Fact:</span> Hires only trainers with a minimum of 10 years of experience in business writing',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[4],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Has had its virtual workshops criticized as “disorganized” and “inefficient” in several online reviews',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[3],
        },
        {
          question: '<span class="boldText">Fact:</span> Offers a refund if a workshop must be cancelled',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[0],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Includes all training materials in the cost of the workshop',
          mulChoiceOptions: [...CPSSurveyAgreeOrDisagree],
          isDisagree: false,
          marquisClassification: CPSSurveyClassifications[0],
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'your-turn-4',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: '',
      info: 'We have a few more classifications to do.',
      image: '/assets/section-1/sne-interstitial/fact-sorting.svg',
      marquis: true,
      buttonText: 'Next',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.MULTI_MULTIPLE_CHOICE,
      inputType: SNE_INPUT_TYPES.RADIO,
      header: 'Facts about Firm B',
      subtitle: 'It\'s your turn first to review facts about Firm B and make classifications.',
      info: 'We\'re almost done! Here are the final four facts about Firm B. How would you classify each of these facts?',
      marquis: true,
      numSelectOptions: 4,
      complete: true,
      multipleMulChoiceOptions: [
        {
          question: '<span class="boldText">Fact:</span> Offers the lowest workshop price available',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Is located in a historic town that attracts many tourists during the summer',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Has several former clients who hired different firms to conduct follow-up trainings',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
        {
          question:
            '<span class="boldText">Fact:</span> Charges an additional fee to work with any employees who are struggling',
          mulChoiceOptions: [...CPSSurveyClassifications],
        },
      ],
      submitId: 'item_4',
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the first task.',
      info: 'Excellent work collaborating with Marquis! Your decisions help illustrate how you evaluate and share information, where your strengths lie, and where you have room to grow. We will cover this in more detail after you complete all three tasks.<br><br>Feel free to pause if you need a break or start the next task if you\'re ready.',
      questionIndex: 1,
      moduleSize: 3,
      image: '/assets/section-1/sne-interstitial/cps-end-interstitial.png',
      buttonText: 'Next',
      goto: CPS_TYPES.HIDDEN_PROFILE,
    },
  ],
]);

export const CPSSurveyDataInstructionModal: SneModal = {
  pageList: [
    {
      title: 'Work Together',
      info: 'You and your virtual teammate, Marquis, are tasked with helping your company\'s management select the best consulting firm to teach professional and technical writing skills to employees at your company. There are two candidate firms, A and B, and two dimensions that are important: <span class=\'convertToBlue\'>cost</span> and <span class=\'convertToBlue\'>quality</span>.',
    },
    {
      title: 'Choosing Between Two Firms',
      info: 'You and Marquis will be presented with several sets of facts about the two firms. Your job is to determine which facts are relevant to which dimension. <br> <br> Not all facts will be relevant. At the end, all relevant facts will be submitted to your company\'s director so she can make the final decision.',
    },
    {
      title: 'Fact Classification Labels',
      info: 'Each fact can be assigned to one of the following five categories',
      showCustomContent: true,
    },
  ],
  customContent: [
    {
      header: 'Cost+',
      info: 'The fact is relevant to the Cost dimension and is positive; that is, it makes the firm more attractive in terms of cost.',
    },
    {
      header: 'Cost-',
      info: 'The fact is relevant to the Cost dimension but is negative; that is, it makes the firm less attractive in terms of cost.',
    },
    {
      header: 'Quality+',
      info: 'The fact is relevant to the Quality dimension and is positive; that is, it makes the firm more attractive in terms of quality.',
    },
    {
      header: 'Quality-',
      info: 'The fact is relevant to the Quality dimension and is negative; that is, it makes the firm less attractive in terms of quality.',
    },
    {
      header: 'Not Relevant',
      info: 'The fact is not relevant to either dimension.',
    },
  ],
};