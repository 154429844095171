<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
  [simpleBack]="false"
>
</ets-header>

<ets-sne-split-screen
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="right">
    <ets-single-multi-choice-question
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType"
    >
    </ets-single-multi-choice-question>
  </div>
  <div class="left">
    <ets-sne-email-layout
    [emails]="questionData?.emailThread!"
    [subject]="questionData?.emailThread?.[0]?.emailSubject"
    [colorArray]="['white']"
    [firstColorIndex]="0"
    >
    </ets-sne-email-layout>
  </div>
</ets-sne-split-screen>

<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]="!singleResponse"
>
  <ets-single-multi-choice-question
    [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
    (selectSingleResponseEvent)="singleResponseHandler($event)"
    [headerQuestion]="questionData?.header"
    [inputType]="questionData?.inputType"
    [mobileCheck]="true"
  >
  </ets-single-multi-choice-question>
</ets-sne-progress-bar>

<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID="customContent">
    <ets-sne-email-layout
      *ngIf="instructionModal?.customContent[0].to"
      [emails]="instructionModal?.customContent"
    ></ets-sne-email-layout>
    <ets-sne-conversation-log-text
      *ngIf="instructionModal?.customContent[0].user"
      [chatLog]="instructionModal?.customContent"
      [showHeader]="false"
    ></ets-sne-conversation-log-text>
  </div>
</ets-modal>
