import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SneMulChoiceOption, SneMulChoiceQuestion, SNE_INPUT_TYPES } from 'src/app/types/sne';

@Component({
  selector: 'ets-sne-multi-choice-list',
  templateUrl: './sne-multi-choice-list.component.html',
  styleUrls: ['./sne-multi-choice-list.component.scss']
})
export class SneMultiChoiceListComponent {
  @Input() mulChoiceOptions?: SneMulChoiceQuestion[];
  @Input() headerQuestion?: string;
  @Input() inputType: SNE_INPUT_TYPES | undefined;
  @Input() preventRepeatAnswers = false;
  @Input() currentAnswers: string[] = [];
  @Input() filteredClassifications: any[] | undefined;
  @Input() mobileCheck = false;
  @Input() questionIndex: number | undefined;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  
  @Output() selectSingleResponseEvent = new EventEmitter<any>();
  @Output() selectMultiResponseEvent = new EventEmitter<any>();
  @Output() handleDropdown = new EventEmitter<any>();

  constructor() { }

  selectSingleResponse = (questionIndex: number, answer: string| undefined, marquisAnswer?: string) => {
    if (answer && !marquisAnswer) {
      this.selectSingleResponseEvent.emit({questionIndex, answer});
    }
    if (marquisAnswer) {
      this.selectSingleResponseEvent.emit({questionIndex, answer, marquisAnswer});
    }
  };

  disabledRadioBtn(currentChoice: string | undefined) {
    if (this.preventRepeatAnswers && currentChoice && this.currentAnswers.includes(currentChoice)) {
      return true;
    } else {
      return false;
    }
  }

  selectMultiResponse(userChoice: string | undefined) {
    if (userChoice) {
      this.selectMultiResponseEvent.emit(userChoice);
    }
  }

  selectDropdownAnswer(event: any, questionIndex: number) {
    if (event) {
      this.handleDropdown.emit({event, questionIndex});
    }
  }
}


