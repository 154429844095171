<div class="container">
  <div class="header-wrapper">
    <img class="company-img" *ngIf="jobPosting.jobImage" src="{{jobPosting.jobImage}}" alt="job image" aria-hidden="true"/>

    <div class="title-wrapper">
      <h2 class="job-title">{{jobPosting.jobTitle}}</h2>
      <h3 class="company-name">{{jobPosting.company}}</h3>
    </div>
  </div>

  <p class="description">{{jobPosting.jobDescription}}</p>

  <div class="job-info-wrapper">
    <div class="job-location-wrapper">
      <img class="job-location-icon" src="../../../../../../assets/icons/blue-location-pin.svg" alt="location icon" aria-hidden="true"/>
      <p class="job-location-text">{{jobPosting.jobLocation}}</p>
    </div>

    <div class="divider"></div>

    <div class="job-type-wrapper">
      <img class="job-type-icon" src="../../../../../../assets/icons/blue-clock.svg" alt="clock icon" aria-hidden="true"/>
      <p class="job-type-text">{{jobPosting.jobType}}</p>
    </div>
  </div>

  <button class="learn-more-button" (click)="learnMore()">LEARN MORE</button>
</div>