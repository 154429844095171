import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LogService } from './log.service';
import { Observable } from 'rxjs';
import { CareerDetail } from '../types/career-detail';
import { TrainingProviderModule } from '../types/career-detail';
import { LogObject } from '../types/log';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root'
})
export class CareerDetailService extends BaseService{
  private careerDetailsURL = environment.careerDetailsAPI;
  private trainingsURL = environment.trainingsAPI;
  private applyToTraining = environment.trainingApplicationAPI;
  private skillsURL = environment.skillsAPI;

  // private stepFuncUrl = "https://hqhgi5dmbj.execute-api.us-east-1.amazonaws.com/latest/step/updateSkillProficiency";

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'Career Detail';
  }

  getCareerDetail(id: number): Observable<CareerDetail> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let params = new HttpParams().set('careerID', id);
      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Initiate GET Career Detail Request'
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<CareerDetail>(this.careerDetailsURL, {headers, params}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Initiate Career Details GET Response',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
    
  }

  getUserCareerList(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Initiate GET User Career List Request'
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.get<any>(`${this.careerDetailsURL}/usercareers`, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Initiate User Career List GET Response',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
    
  }

  updateSkills(skills: any): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Initiate Update Skills POST Request'
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post(this.skillsURL, skills, {headers}).pipe(
      // return this.http.post(`${this.careerDetailsURL}/skills`, skills, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Initiate Update Skills POST Response',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
    
  }


  trainingLink(skillsetID: number): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      
      const body = {
        skillsetID,
        clicked: true
      };

      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Initiate Training Links POST Request',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post(`${this.careerDetailsURL}/traininglink`, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Initiate Training Links POST Response',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
    
  }

  countMeInLink(careerID: number):Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);

      const body = {
        careerID,
        confirm: true
      };

      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Count me in - confirm notifications',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();


      return this.http.post(`${this.careerDetailsURL}/careerTraining`, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Confirm Notifications POST Response',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
  }


  //Get Modules and Info for Training
  getTrainingProviderModules(target_career: string): Observable<TrainingProviderModule[]> {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      let trainingProviderModulesURL = this.trainingsURL + `/${target_career}`;
      let headers = new HttpHeaders().set('Authorization', accessToken);

      let responseLogObj: LogObject = {
        message: 'ButterCMS: Get Training Provider Modules Request',
        object: target_career,
      };
      this.logService.logInfo(responseLogObj)?.subscribe();

      return this.http.get<TrainingProviderModule[]>(trainingProviderModulesURL, {headers}).pipe(
        tap(data => { 
          let responseLogObj: LogObject = {
            message: 'ButterCMS: Get Training Provider Modules Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }else {
      return new Observable<any>();
    }
  }
  submissionTrainingModule(sendBackObj:any):Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    if(accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = sendBackObj;

      let requestLogObj: LogObject = {
        message: 'CAREER DETAILS: Training Provider Post Call',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();


      return this.http.post(this.applyToTraining, sendBackObj, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'CAREER DETAILS: Training Provider Post Call',
            object: data
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError((error) => this.handleError(error))
      );
    } else {
      return new Observable<any>();
    }
  }
}
