<ets-auth-split-screen [leftBackgroundColor]="APP_COLORS.mustardlight" [rightBackgroundColor]="APP_COLORS.white">
  <div class="left">
    <img src="../../../assets/sign-up/sign-up-image.svg" alt="" aria-hidden="true" id="sign-in-photo">

    <div class="sign-up-content-wrapper">
      <div class="sign-up-list-title">
        Fulfill your potential
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Discover possibilities
          </p>
          <p class="sign-up-list-text">
            Unpack your experience and explore in-demand careers that match your skills today.
          </p>
        </div>
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Prepare for opportunities
          </p>
          <p class="sign-up-list-text">
            Take quizzes that reveal what makes you unique and consider curated training options.
          </p>
        </div>
      </div>

      <div class="sign-up-list-row">
        <div class="sign-up-list-col1">
          <fa-icon class="check-circle-icon" [icon]="faCheckCircle" alt="Checked"></fa-icon>
        </div>
        <div class="sign-up-list-col2">
          <p class="sign-up-list-header">
            Land the career you deserve
          </p>
          <p class="sign-up-list-text">
            Learn how to convey your skills and showcase yourself to employers.
          </p>
        </div>
      </div>
    </div>
  </div> <!-- left -->

  <div class="right">
    <div class="login-form-wrapper">
      <div class="header-info-wrapper">
        <h1 class="sign-up-header">
          Create your account
        </h1>
        <p class="need-account">
          Already have an account? &nbsp;<button class="sign-up" (click)="gotoSignIn()">Sign in</button><br>
        </p>
      </div>
      <div>
        <form [formGroup]="signUpForm" (ngSubmit)="signUp()">
          <!-- First name -->
          <label for="fgivenName" class="input-label">First name</label><br>
          <input type="text" id="fgivenName" name="given_name" class="sign-in-input" formControlName="given_name">
          <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' *ngIf="signUpForm.controls['given_name'].invalid &&
            (signUpForm.controls['given_name'].touched || signUpForm.controls['given_name'].dirty)">
          <div
            *ngIf="signUpForm.controls['given_name'].invalid && (signUpForm.controls['given_name'].touched || signUpForm.controls['given_name'].dirty)">
            <div *ngIf="signUpForm.controls['given_name'].errors?.required" class='validation-error'>
              This field is required
            </div>
          </div>

          <!-- Last name -->
          <label for="ffamilyName" class="input-label">Last name</label><br>
          <input type="text" id="ffamilyName" name="family_name" class="sign-in-input" formControlName="family_name">
          <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' *ngIf="signUpForm.controls['family_name'].invalid &&
            (signUpForm.controls['family_name'].touched || signUpForm.controls['family_name'].dirty)">
          <div
            *ngIf="signUpForm.controls['family_name'].invalid && (signUpForm.controls['family_name'].touched || signUpForm.controls['family_name'].dirty)">
            <div *ngIf="signUpForm.controls['family_name'].errors?.required" class='validation-error'>
              This field is required
            </div>
          </div>

          <!-- Email -->
          <label for="femail" class="input-label">Email</label><br>
          <input type="text" id="femail" name="email" class="sign-in-input" formControlName="email">
          <img src="../../../assets/exclamation-circle.svg" alt="red exclamation circle" class='invalid-icon' *ngIf="signUpForm.controls['email'].invalid &&
            (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)">
          <div
            *ngIf="signUpForm.controls['email'].invalid && (signUpForm.controls['email'].touched || signUpForm.controls['email'].dirty)">
            <div *ngIf="signUpForm.controls['email'].errors?.required" class='validation-error'>
              This field is required
            </div>
            <div *ngIf="signUpForm.controls['email'].errors?.email" class='validation-error'>
              Please enter a valid email address
            </div>
          </div>
          <div *ngIf="signUp_userAlreadyExists" class='validation-error'>
            <p class="error-msg">Email already exists. <button class="sign-up" (click)="gotoSignIn()">Sign in</button>
            </p>
          </div>

          <!-- Password -->
          <label for="signUp_password" class="input-label">Password</label><br>
          <input type="password" (focus)="focusPassword(true)" (focusout)="focusPassword(false)" id="signUp_password" name="password" class="sign-in-input" formControlName="password">
          <fa-icon class="show-pass-icon" [icon]="faEye" (click)='toggleShowPassword("signUp_password")'></fa-icon>
          <div *ngIf="passwordFocused === true && signUpForm.controls['password'].errors" class="modal-box">
            <!-- <div *ngIf="signUpForm.controls['password'].errors?.required" class='validation-error'>
              This field is required
            </div> -->
            <div class='sign-up-password-row'>
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                  *ngIf="(signUpForm.controls['password'].value.length === 0)||signUpForm.controls['password'].errors?.minlength && !signUpForm.controls['password'].errors?.required else minLengthTemplate"
                ></fa-icon>
                <ng-template #minLengthTemplate>
                  <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                </ng-template>
              </div>
              <div class="sign-up-password-col2">
                8 characters minimum
              </div>
            </div>
            <div class='sign-up-password-row' *ngIf="signUpForm.controls['password'].errors?.maxlength">
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                ></fa-icon>
              </div>
              <div class="sign-up-password-col2">
                99 characters maximum
              </div>
            </div>
            <div class='sign-up-password-row'>
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                  *ngIf="(signUpForm.controls['password'].value.length===0)||signUpForm.controls['password'].hasError('hasNumber') && !signUpForm.controls['password'].errors?.required else numberTemplate"
                ></fa-icon>
                <ng-template #numberTemplate>
                  <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                </ng-template>
              </div>
              <div class="sign-up-password-col2">
                One number
              </div>
            </div>
            <div class='sign-up-password-row'>
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                  *ngIf="(signUpForm.controls['password'].value.length===0)||signUpForm.controls['password'].hasError('hasUpper') && !signUpForm.controls['password'].errors?.required else upperCaseTemplate"
                ></fa-icon>
                <ng-template #upperCaseTemplate>
                  <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                </ng-template>
              </div>
              <div class="sign-up-password-col2">
                One uppercase letter
              </div>
            </div>
            <div class='sign-up-password-row'>
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                  *ngIf="(signUpForm.controls['password'].value.length===0)||signUpForm.controls['password'].hasError('hasSpecial') && !signUpForm.controls['password'].errors?.required else specialTemplate"
                ></fa-icon>
                <ng-template #specialTemplate>
                  <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                </ng-template>
              </div>
              <div class="sign-up-password-col2">
                One special character
              </div>
            </div>
            <div class='sign-up-password-row'>
              <div class="sign-up-password-col1">
                <fa-icon class="checkmark_circle_grey" 
                  [icon]="faCircle"
                  *ngIf="(signUpForm.controls['password'].value.length===0)||signUpForm.controls['password'].hasError('hasLower') && !signUpForm.controls['password'].errors?.required else lowerCaseTemplate"
                ></fa-icon>
                <ng-template #lowerCaseTemplate>
                  <fa-icon class="checkmark_circle_green" [icon]="faCheckCircle"></fa-icon>
                </ng-template>
              </div>
              <div class="sign-up-password-col2">
                One lowercase letter
              </div>
            </div>
          </div>

          <!-- keep me updated checkbox -->
          <div class="keep-me-updated">
            <ets-checkbox 
              (clickEvent)="toggleKeepMeUpdated()" 
              [checked]="keepMeUpdated"
              [label]="'Keep me updated on new careers, training, and features.'"
            >
            </ets-checkbox>
          </div>
          <!-- Error Handling -->
          <p class="error-msg" *ngIf="signUp_unknownErrorFlag">An unknown error occurred. Please try again.</p>

          <div class="submit-container">
            <ets-rounded-contained-button style="z-index: 1000;" type="submit" [fullWidth]="true"
              [backgroundColor]="'#0E3AD6'" [textColor]="'#fff'" [buttonText]="'CREATE ACCOUNT'"
              [disabled]="!signUpForm.valid"></ets-rounded-contained-button>
            <!-- <input type="submit" id="submit" name="submit" value="Create Account" class="login-button"
              [disabled]="!signUpForm.valid"> -->
          </div>
        </form>
        <div class="agreement-container">
          By continuing, I agree to Apprize's &nbsp;<button type="button" class="sign-up"
            (click)="toggleShowTermsAndConditions()">
            Terms & Conditions</button>&nbsp; & &nbsp;<a href="https://www.ets.org/legal/privacy" target="_blank"
            rel="noreferrer noopener" class="sign-up privacy-policy-link">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div> <!-- right -->

</ets-auth-split-screen>

<clr-modal [(clrModalOpen)]="showTermsAndConditions" [clrModalClosable]="true" [clrModalSize]="'md'">
  <h3 class="modal-title">Terms & Conditions</h3>
  <div class="modal-body">
    <ol>
      <li class="tc-item">I hereby grant to Educational Testing Service (“ETS”) the right to reprint my comments,
        including
        without limitation, testimonials and quotes, photographs, images, (collectively, the “Materials”), and the
        right to use the Materials for promotional or other purposes, in whole or in part, throughout the universe
        at any time, in perpetuity, in any and all media now known and hereafter devised.</li>
      <li class="tc-item">Without in any way limiting the foregoing, I acknowledge and agree that ETS is under no
        obligation to
        use the Materials. I know ETS will incur significant costs and expenses in reliance upon this Release
        Form, so I will not attempt to cancel it or to revoke any of the rights granted to ETS herein.</li>
      <li class="tc-item">I waive any right to any additional compensation related to the use of the Materials. I
        acknowledge that
        I am a volunteer and that I shall not be deemed to be an employee of ETS, nor shall I be entitled to the
        benefits provided by ETS to its employees. I acknowledge and agree that a significant element of the
        consideration I am receiving under this Release Form is the publicity I may receive if ETS uses my
        Materials.</li>
      <li class="tc-item">I agree that ETS may license, assign, and otherwise transfer this Release Form and all rights
        granted by
        me to ETS under this Release Form to any person or entity. This Release Form cannot be terminated,
        rescinded or amended, except by a written agreement signed by both ETS and me. This agreement shall be
        construed in accordance with the substantive laws of the state of New York, without regard to its conflict
        of laws rules.</li>
      <li class="tc-item">I represent to ETS that I am eighteen years of age or older.</li>
    </ol>
  </div>
  <div class="modal-footer modal-footer-tc">
    <button class="tc-continue-button" type="button" (click)="toggleShowTermsAndConditions()">Continue</button>
  </div>
</clr-modal>