<div class="container">
    <div class="white-container">
        <div class="explanation">
            <h1>Your score is just a snapshot of where you stand at this moment. </h1>
            <h2>Different roles and organizations will value these skills differently. We hope this report helps you reflect on your past experiences and future opportunities for improvement.</h2>
        </div>

        <div class="horizontal-line"></div>

        <div class="description">
            <div class="icon-text-container">
                <img class="icon" src={{iconImage1}} alt="icon-image">
            
                <div class="text">
                    <div class="flex">
                        <h1 class="title">What does <span class="convertToBlue">{{scoreHumanReadable}}</span> in {{moduleOrSubModule}} look like? </h1>
                    </div>
                    <h2>{{subTitle}} </h2>
                    <ul *ngFor="let ability of abilities">
                        <li>{{ability}}</li>
                    </ul>
                </div>
            </div>

            <div class="blue-box">
                <h1 class="title">IN THE WORKPLACE</h1>
                <p>{{inTheWorkPlace}}</p>
            </div>
        </div>

        <div class="horizontal-line" *ngIf="twoRoomtoGrowSections"></div>

        <div class="description" *ngIf="twoRoomtoGrowSections">
            <div class="icon-text-container">
                <img class="icon" src={{iconImage2}} alt="icon-image">

                <div class="text">
                    <div class="flex">
                        <h1 class="title">What does <span class="convertToBlue">{{scoreHumanReadable2}}</span> in {{moduleOrSubModule2}} look like? </h1>
                    </div>
                    <h2>{{subTitle2}} </h2>
                    <ul *ngFor="let ability of abilities2">
                        <li>{{ability}}</li>
                    </ul>
                </div>
            </div>

            <div class="blue-box">
                <h1 class="title">IN THE WORKPLACE</h1>
                <p>{{inTheWorkPlace2}}</p>
            </div>
        </div>

        <div class="horizontal-line" *ngIf="threeRoomtoGrowSections"></div>

        <div class="description" *ngIf="threeRoomtoGrowSections">
            <div class="icon-text-container">
                <img class="icon" src={{iconImage3}} alt="icon-image">
                <div class="text">
                    <div class="flex">
                        <h1 class="title">What does <span class="convertToBlue">{{scoreHumanReadable3}}</span> in {{moduleOrSubModule3}} look like? </h1>
                    </div>
                    <h2>{{subTitle3}} </h2>
                    <ul *ngFor="let ability of abilities3">
                        <li>{{ability}}</li>
                    </ul>
                </div>
            </div>

            <div class="blue-box">
                <h1 class="title">IN THE WORKPLACE</h1>
                <p>{{inTheWorkPlace3}}</p>
            </div>
        </div>
    </div>

    <div class="score-means" *ngIf="showWhatScoreMeans">
        <div class="title-row">
            <div class="title-text">
                <h1>What does my score mean?</h1>
                <h2 [innerHtml]="scoreExplanation"></h2>
            </div>
            <div class="title-image">
                <img src="/assets/sne/blue-woman-working.png"/>
            </div>
        </div>
        <div class="examples">
            <div class="example" *ngFor="let example of examples">
                <h1>{{example.title}}</h1>
                <p [innerHtml]="example.text"></p>
            </div>
        </div>
    </div>

    <!-- Careers Section below: On hold -->
    <!-- <div class="grey-container">
        <div class="right-box">
            <h1>Put Written Communication to work</h1>
            <p>You can use your Written Communication skills to help you succeed in many of your Saved Careers. </p>
            <p>Here is an example of how these skills can be applied to the Software Sales Representative role you are interested in.</p>
        </div>

        <div class="left-box">
            <div class="role-explanation">
                <h1>In this role, Written Communication skills might help you to:</h1>
                <li>Develop constructive and cooperative working relationships with others</li>
                <li>Convince others to buy merchandise/goods</li>
                <li>Handle complaints and settle customer disputes</li>
            </div>
            <div class="sample-role">
                <img class="role-picture" src="/assets/sne/sample_role_orange_computer_man.svg"/>
                <div class="role-info">
                    <h1>Software Sales Representative</h1>
                    <h2>TYPICAL SALARY</h2>
                    <div class="flex">
                        <h3>$99,652</h3>
                        <img class="increase" src="/assets/sne/green-triangle-up.png"/>
                        <h4>23%</h4>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="improvement-container" *ngIf="better">
        <h1>{{better.title}}</h1>
        <h2>{{better.subTitle}}</h2>

        <div class="boxes">
            <div class="box" *ngFor="let idea of better.ideas">
                <h3>{{idea.title}}</h3>
                <h4>{{idea.text}}</h4>
            </div>
        </div>
    </div>

    <div class="finish-button">
        <button (click)="finish()">FINISH</button>
    </div>
</div>
