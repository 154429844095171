<div class="page-container" [attr.aria-hidden]="!callCompleted">
  <div class="content-container" [attr.aria-hidden]="!callCompleted">
    <h2 class="title">{{currentQuestion?.question}}</h2>
    <h3 class="subtitle" [innerHTML]="currentQuestion?.info"></h3>

    <!-- Skill Types -->
    <div class="skills-container">
      <!-- Don't display the top border on the last skill type -->
      <ets-skill-type-overview
        *ngFor="let skillType of currentQuestion?.skillTypes; let index = index"
        [title]="skillType.title"
        [description]="skillType.description"
        [id]="index"
        [imagePath]="skillType.imagePath"
        [disabled]="skillType.disabled"
        [topBorder]="currentQuestion !== undefined && currentQuestion.skillTypes !== undefined && index !== (currentQuestion.skillTypes.length - 1)"
        [selectedSkills]="getSelectedSkillsOfType(skillType.type)"
        (clickEvent)="selectSkillType(skillType.type)"
      >
      </ets-skill-type-overview>
    </div>

    <ets-rounded-contained-button
      [buttonText]="'Save & Continue'"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#FFFFFF'"
      [disabled]="disableSaveAndContinue()"
      (clickEvent)="saveAndContinue()"
    >
    </ets-rounded-contained-button>
  </div>
  <ets-loading-modal
    [showModal]="callCompleted"
  >
  </ets-loading-modal>
</div>
