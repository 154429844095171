import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'ets-outline-button',
  templateUrl: './outline-button.component.html',
  styleUrls: ['./outline-button.component.scss']
})
export class OutlineButtonComponent {

  constructor() { }

  @Input() text: string | undefined;
  @Input() color: string | undefined;
  @Input() borderColor: string | undefined;
  @Input() iconImgSrc: string | undefined;
  @Input() iconImgAlt: string | undefined;
  @Input() iconWidth: string | undefined;
  @Input() disabled: boolean = false;
  @Input() size: string = 'sm';
  @Input() padding: string | undefined;
  @Input() maxWidth: boolean = false;
  @Input() transparentBg = false; // make bg transparent
  @Input() ariaLabel? : string | undefined = '';
  @Output() clickEvent = new EventEmitter<any>();

  onClick = () => {
    this.clickEvent.emit();
  };

}
