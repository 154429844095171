import { Component, OnDestroy, OnInit } from '@angular/core';
import { HEADER_STATES } from '../shared/header/header.helper';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CipService } from 'src/app/services/cip.service';
import { CipScores } from 'src/app/types/cip';
import { selectProfile } from '../store';
import Profile from 'src/app/types/profile';
import { Store } from '@ngrx/store';
import { ScaleType } from '@swimlane/ngx-charts';
import { CareerPrioritizationActionEnum, CareerPrioritizationRequest, CareerPrioritizationResponse, LearnerCareer, LearnerCareersResponse } from '../types/career-prioritization';
import { CareerPrioritizationService } from '../services/career-prioritization.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventActionEnum, EventSectionEnum } from '../types/event';
import { EventService } from '../services/event.service';
import CAREER_BG_MAP from '../data/career-bg-map';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'ets-career-suggestions',
  templateUrl: './career-suggestions.component.html',
  styleUrls: ['./career-suggestions.component.scss']
})
export class CareerSuggestionsComponent implements OnInit, OnDestroy {

  constructor(private store: Store, private router: Router, private cipService: CipService, private careerPrioritizationService: CareerPrioritizationService, private eventService: EventService) { }

  firstHover = { value: true };
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  faChevronRight = faChevronRight;
  SORT_BY_OPTIONS = [
    'Career Match',
    'Interest Match',
  ];
  scoreData: CipScores | undefined;
  userProfile: Profile | undefined;
  // Array of subscriptions for all the subscribe calls made in this component
  subArr: Subscription[] = [];
  postCallInProgress: boolean = false;
  followedCareersCount = 0;

  // Match Circle Variables
  matchResults: any[] = [];
  view: [number, number] = [100, 100];
  gradient: boolean = false;
  disableTooltip: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  colorScheme = {
    domain: ['#0E3AD6', '#E8EFFE'],
    name: 'donut-chart',
    selectable: false,
    group: ScaleType.Linear
  };

  showFilterModal = false; // flag that determines when the Filter modal is displayed
  sortBySkillMatchFlag = true; // flag used in the template to display the appropriate score percentages
  sortByValue = 'Skill Match'; // value to hold the Sort By selection
  sortDown = true;
  // These two flag are used in conjunction with the filter toggle switches
  inDemandOnlyFlag = true;
  careerInterestOnlyFlag = false;
  etsFirstHover = true;

  loadMoreFlag = false; // flag that determines when the 'load more' button is displayed
  loadMoreIndex = 0; // index to help track where in the array the user is when they click load more

  // This array will hold the careers the user follows
  followedCareerList: LearnerCareer[] = [];
  // This array will hold the careers to be displayed on the FE
  frontEndCareerList: LearnerCareer[] = [];
  // This array will hold all/the original list of careers received from the BE
  originalCareerList: LearnerCareer[] = [];

  // LEAVING THIS SNIPPET IN CASE WE NEED TO CHANGE SOMETHING TO CALL CIP
  // getScores() {
  //   if (this.userProfile && this.userProfile.learnerID) {
  //     this.cipService.getScoresApi(this.userProfile.learnerID).subscribe(data => {
  //       this.scoreData = data;
  //       // this.populateCareers();
  //     });
  //   }
  // }

  // makeSentence(career: string, category: string, love: boolean, index: number, career_group: string) {
  //   // let career_group = career_to_area_map.get(careers);
  //   let high_category_phrase = raisec_high_phrase_map.get(category);
  //   let low_category_phrase = raisec_low_phrase_map.get(category);
  //   if (career_group) {
  //     let career_group_phrase = career_phrase_map.get(career_group);
  //     if (love && high_category_phrase) {
  //       let category_phrase = high_category_phrase[index];
  //       return "You told us " + category_phrase + ", so you might enjoy " + career_group_phrase +".";
  //     } else if (low_category_phrase) {
  //       let category_phrase = low_category_phrase[index];
  //       return "You told us " + category_phrase + ", so you might not enjoy " + career_group_phrase +".";
  //     }
  //   }
  //   return "Unable to fetch sentence";
  // }

  findBGColor(careerTitle: string) {
    const bgCareer = CAREER_BG_MAP.find((career: {careerTitle: string, bgColor: string}) => career.careerTitle === careerTitle);
    if (bgCareer) {
      return bgCareer.bgColor;
    } else {
      return '#ffffff';
    }
  }

  getLearnerCareers() {
    let learnerCareersResponse: LearnerCareersResponse;

    this.subArr.push(this.careerPrioritizationService.getLearnerCareers().subscribe(data => {
      learnerCareersResponse = data;
      this.processCareerResponse(learnerCareersResponse.careers);
    }));
  }

  populateFrontEndCareerList(startIndex: number) {
    let showCareerCount = 0;

    // add the followed careers to the top of the list
    if (startIndex === 0 && this.followedCareersCount > 0) {
      this.followedCareerList.forEach(career => {
        this.frontEndCareerList.push(career);
      });
      showCareerCount = this.followedCareersCount;
    }

    let i = startIndex;
    for (; i < this.originalCareerList.length; i++) {
      if (this.originalCareerList[i].showCareer) {
        // only add the career if it's not already in the list
        if (!this.frontEndCareerList.includes(this.originalCareerList[i])) {
          this.frontEndCareerList.push(this.originalCareerList[i]);
          showCareerCount++;
        }

        // if (showCareerCount === 10) {
        //   break;
        // } // break out of the loop

      }
    }

    if (showCareerCount === 10) {
      // determine if there is more careers to be shown
      for (let j = i+1; j < this.originalCareerList.length; j++) {
        if (this.originalCareerList[j].showCareer) {
          this.loadMoreFlag = true;
          this.loadMoreIndex = j; // mark the load more index as the next career with showCareer as true
          break; // break out of the loop
        }
      }
    } else {
      this.loadMoreFlag = false;
    }

  }

  resetFrontEndCareerList() {
    this.frontEndCareerList = []; // reset front end list
    this.populateFrontEndCareerList(0); // populate front end list from index 0 of originalCareerList
  }

  resetShowCareerFlags() {
    this.originalCareerList.forEach(career => {
      career.showCareer = true;
    });
  }

  processCareerResponse(careers: LearnerCareer[]) {
    let imageIndex = 0;
    careers.forEach(career => {
      if (imageIndex === 3) {
        imageIndex = 0;
      }


      career.showCareer = true;
      career.enableFollowBtn = true;
      career.enableDismissBtn = true;
      career.matchScore = Math.round(career.matchScore * 100); // rounding to nearest whole number
      career.interestScore = Math.round(career.interestScore * 100); // rounding to the nearest whole number
      career.matchResults = [
        {
          'name': 'Score',
          'value': career.matchScore
        },
        {
          'name': 'Remainder',
          'value': (100 - career.matchScore)
        }
      ];
      career.interestResults = [
        {
          'name': 'Score',
          'value': career.interestScore
        },
        {
          'name': 'Remainder',
          'value': (100 - career.interestScore)
        }
      ];

      // Add career to followed list if it's already been followed
      if (career.isFollowing) {
        career.enableDismissBtn = false; // disable dismiss button because the career is followed
        this.followedCareerList.push(career);
        this.followedCareersCount++;
      }

      imageIndex++;
    });

    // disable all the follow buttons if 3 careers are already followed
    if (this.followedCareersCount === 3) {
      careers.forEach(career => {
        if (!career.isFollowing) {
          career.enableFollowBtn = false;
        }

      });
    }

    this.originalCareerList = [...careers];
    // flip the showCareer flag based on whether the indemand flag is true/false
    this.originalCareerList.forEach(career => {
      if (career.inDemand || career.isFollowing) { // show any followed careers regardless of the filter
        career.showCareer = true;
      } else {
        career.showCareer = false;
      }
    });
    this.resetFrontEndCareerList();
  }

  loadMoreCareers() {
    if (this.originalCareerList.length - this.loadMoreIndex > 0) {
      this.populateFrontEndCareerList(this.loadMoreIndex);
    } // populate front end list from index loadMoreIndex of originalCareerList

  }


  getProfile() {
    this.store.select(selectProfile).subscribe(profile => {
      if (profile) {
        this.userProfile = profile;
      }
      // this.getScores();

    });
  }

  // handleSortByChange(value: string) {
  //   this.sortByValue = value;

  //   // Skill match
  //   if (this.SORT_BY_OPTIONS[0] === this.sortByValue) {
  //     // Sort by skill match
  //     this.sortBySkillMatchFlag = true;
  //     this.originalCareerList.sort(function(a, b) {
  //       return b.matchScore - a.matchScore;
  //     });
  //   }
  //   // Interest match
  //   else if (this.SORT_BY_OPTIONS[1] === this.sortByValue) {
  //     // Sort by interest match
  //     this.sortBySkillMatchFlag = false;
  //     this.originalCareerList.sort(function(a, b) {
  //       return b.interestScore - a.interestScore;
  //     });
  //   }

  //   this.resetFrontEndCareerList();
  // }

  handleSortByChange() {
    this.sortDown = !this.sortDown;

    // Sort high to low
    if (this.sortDown) {
      // Track the click in AWS Athena
      this.eventService.buildEvent('Career Match Sort - High to Low', EventActionEnum.CLICK);

      // Sort by skill match
      this.sortBySkillMatchFlag = true;
      this.originalCareerList.sort(function(a, b) {
        return b.matchScore - a.matchScore;
      });
    } else { // Sort low to high
      // Track the click in AWS Athena
      this.eventService.buildEvent('Career Match Sort - Low to High', EventActionEnum.CLICK);

      // Sort by interest match
      this.sortBySkillMatchFlag = true;
      this.originalCareerList.sort(function(b, a) {
        return b.matchScore - a.matchScore;
      });
    }

    this.resetFrontEndCareerList();
  }

  toggleFollowingCareer(career: LearnerCareer) {
    if (!career.isFollowing) {
      // Track the click in AWS Athena
      this.eventService.buildEvent('Saved Career - Career ' + career.careerID, EventActionEnum.CLICK);

      this.followedCareersCount++;
      career.isFollowing = true; // update followed flag on career
      career.enableDismissBtn = false; // disable the dismiss btn so a user can't accidentally click dismiss

      this.followedCareerList.push(career);

      // logic to enforce 3 careers followed
      if (this.followedCareersCount === 3) {
        // disable all follow buttons
        this.frontEndCareerList.forEach(career => {
          career.enableFollowBtn = false;
        });
        this.originalCareerList.forEach(career => {
          career.enableFollowBtn = false;
        });
      }
    } else {
      // Track the click in AWS Athena
      this.eventService.buildEvent('Unsaved Career - Career ' + career.careerID, EventActionEnum.CLICK);
      this.followedCareersCount--;

      // remove the career from the followed list
      const index = this.followedCareerList.indexOf(career);
      if (index > -1) {
        this.followedCareerList.splice(index, 1);
      }


      // update flags
      career.isFollowing = false;
      career.enableDismissBtn = true;


      if (this.followedCareersCount === 2) {
        // renenable all follow buttons
        this.frontEndCareerList.forEach(career => {
          career.enableFollowBtn = true;
        });
        this.originalCareerList.forEach(career => {
          career.enableFollowBtn = true;
        });
      }
    }
  }

  dismissCareer(career: LearnerCareer) {
    // remove the career from the career lists
    const frontEndIndex = this.frontEndCareerList.indexOf(career);
    if (frontEndIndex > -1) {
      this.frontEndCareerList.splice(frontEndIndex, 1);
    }

    const originalIndex = this.originalCareerList.indexOf(career);
    if (originalIndex > -1) {
      this.originalCareerList.splice(originalIndex, 1);
    }


    // update the flag and make the call to the BE
    career.isDismissed = true;
    let request: CareerPrioritizationRequest = {
      careerIDList: [career.careerID],
      action: CareerPrioritizationActionEnum.DISMISS
    };
    this.subArr.push(this.careerPrioritizationService.updateLearnerCareers(request).subscribe());
  }

  toggleInDemand() {
    // only one flag can be true at a time
    if (this.careerInterestOnlyFlag) {
      this.careerInterestOnlyFlag = false;
    }

    // flip the flag and hide the modal after 1 sec
    this.inDemandOnlyFlag = !this.inDemandOnlyFlag;
    if (this.inDemandOnlyFlag) {
      // Track the click in AWS Athena
      this.eventService.buildEvent('In-demand Only Toggle - On', EventActionEnum.CLICK);
    } else {
      // Track the click in AWS Athena
      this.eventService.buildEvent('In-demand Only Toggle - Off', EventActionEnum.CLICK);
    }
    setTimeout(() => {
      this.showFilterModal = false;
    }, 1000);

    this.resetShowCareerFlags();

    // flip the showCareer flag based on whether the indemand flag is true/false
    this.originalCareerList.forEach(career => {
      if (this.inDemandOnlyFlag) {
        if (career.inDemand || career.isFollowing) { // show any followed careers regardless of the filter
          career.showCareer = true;
        } else {
          career.showCareer = false;
        }

      }
    });

    this.resetFrontEndCareerList();
  }

  toggleCareerInterest() {
    // only one flag can be true at a time
    if (this.inDemandOnlyFlag) {
      this.inDemandOnlyFlag = false;
    }

    // flip the flag and hide the modal after 1 sec
    this.careerInterestOnlyFlag = !this.careerInterestOnlyFlag;
    setTimeout(() => {
      this.showFilterModal = false;
    }, 1000);

    this.resetShowCareerFlags();

    // flip the showCareer flag based on whether the interest flag is true/false
    this.originalCareerList.forEach(career => {
      if (this.careerInterestOnlyFlag) {
        if (career.interestFlag || career.isFollowing) { // show any followed careers regardless of the filter
          career.showCareer = true;
        } else {
          career.showCareer = false;
        }

      }
    });

    this.resetFrontEndCareerList();
  }

  toggleFilterModal() {
    this.showFilterModal = !this.showFilterModal;
  }

  goToCareerDetails(career: LearnerCareer) {
    // before routing away from the page, save any followed careers
    if (this.followedCareersCount > 0) {
      // Save the followed careers
      let careerIdFollowList: number[] = [];
      this.followedCareerList.forEach(career => {
        careerIdFollowList.push(career.careerID);
      });
      let request: CareerPrioritizationRequest = {
        careerIDList: careerIdFollowList,
        action: CareerPrioritizationActionEnum.FOLLOW
      };

      this.subArr.push(this.careerPrioritizationService.updateLearnerCareers(request).subscribe({
        next: response => {
          let careerPrioritizationResponse: CareerPrioritizationResponse = response;
          if (careerPrioritizationResponse.success) {
            // Track the event in AWS
            this.eventService.buildEvent('Career Suggestions Career Card - Career ' + career.careerID, EventActionEnum.CLICK);
            // Route to the career details
            this.router.navigate([`/career-detail/${true}/${career.careerID}`]); // needs route param for career ID
          }
        }
      }));
    } else {
      // Track the event in AWS
      this.eventService.buildEvent('Career Suggestions Career Card - Career ' + career.careerID, EventActionEnum.CLICK);
      // Route to the career details
      this.router.navigate([`/career-detail/${true}/${career.careerID}`]); // needs route param for career ID
    }
  }

  saveCareersAndProceed() {
    if (!this.postCallInProgress) {
      this.postCallInProgress = true;
      // Save the followed careers
      let careerIdFollowList: number[] = [];
      this.followedCareerList.forEach(career => {
        careerIdFollowList.push(career.careerID);
      });
      let request: CareerPrioritizationRequest = {
        careerIDList: careerIdFollowList,
        action: CareerPrioritizationActionEnum.FOLLOW
      };

      this.subArr.push(this.careerPrioritizationService.updateLearnerCareers(request)
        .pipe(finalize(() => this.postCallInProgress = false))
        .subscribe(response => {
          let careerPrioritizationResponse: CareerPrioritizationResponse = response;
          if (careerPrioritizationResponse.success) {
            // Route to the dashboard
            this.router.navigate(['/dashboard']);
          }
        }));
    }
  }

  ngOnInit() {
    if (environment.analyticsTrackingEnabled) {
      // Replace state for Google Analytics
      let stateObj = {
        title: 'CIP Career Suggestions',
        pathname: window.location.pathname
      };
      history.replaceState(stateObj, 'CIP Career Suggestions', window.location.pathname);
    }
    // Track the page view in AWS Athena
    this.eventService.buildEvent('Career Suggestions', EventActionEnum.PAGE_VIEW, EventSectionEnum.CAREER_SUGGESTIONS);

    this.getProfile();
    // this.getScores();
    this.getLearnerCareers();
  }

  ngOnDestroy() {
    this.subArr.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
