export const SneText =  new Map([
  ['cps-intro', {
    question: 'Collaborative Problem Solving',
    info: 'In this assessment, we\'ll guide you through three separate tasks. Each will require you to evaluate and apply facts to a problem you and a teammate are trying to solve. \n\n\nReady to get started?',
    image: '../assets/section-1/intro-image-desktop.png',
    buttonText: 'Let\'s begin!',
    next: 'collaborative-problem-solving/fact-sorting'
  }],
  ['negotiation-start', {
    question: 'Negotiation',
    info: 'This task will help you better understand how you identify and resolve conflicts. We’ll add these insights to your Skills Profile.',
    duration: 'Duration: This should take another 10 minutes or so, and is the last task in this exercise!',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Open Task',
    next: 'negotiation-instructions',
    progress: '66%',
    questionIndex: 3,
    moduleSize: 3
  }],
  ['negotiation-instructions', {
    buttonText: 'Let\'s begin!',
    instructionList: [
      {
        question: 'About You',
        info: 'You work for a large event space that hosts concerts, theatrical productions, and sporting events. Part of your duties includes securing sponsorship of events from businesses and other clients by selling advertising that is shown during the events.',
        progress: '1',
        buttonText: 'Continue'
      },
      {
        question: 'About You',
        info: 'To help with negotiating sponsorships with clients, your company provides you with certain incentives you can offer. Currently, you have the following incentives available:\n\n• 50 free tickets to a sold-out concert;\n• A meet and greet for 10 people with the artists performing at the sold-out concert;\n• 2 season tickets to home games for the city’s basketball team;\n• A picnic catered by the food service providers that sell concessions at your events.',
        progress: '2',
        buttonText: 'Continue'
      },
      {
        question: 'Scenario',
        info: 'You are working with your company’s biggest client to finalize their contract renewal to continue their event sponsorship for the next year. \n\nAs an incentive for renewing their contract, your client has asked for 80 tickets to a sold-out concert.\n\nHowever, you currently only have 50 tickets available to you.',
        progress: '3',
        buttonText: 'Continue'
      },
      {
        question: 'Your Task',
        info: 'Your colleague Jordan has an additional number of tickets to the concert. You have asked her to meet with you to see if she might be willing to help you satisfy your client.\n\nYou will be able to see both the client\'s and Jordan\'s satisfaction ratings as you progress through the task.',
        progress: '4',
        buttonText: 'Continue'
      }],
    next: 'negotiation-questions'
  }],
  ['negotiation-end', {
    question: 'Congrats! <br> You completed the last task.',
    info: 'Check out your score report to see your results and understand how they might inform your career search.',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Close Task',
    next: 'end-page',
    progress: '100%',
    questionIndex: 4,
    endIndex: 3,
    moduleSize: 3
  }],
  ['hidden-profile-start', {
    question: 'Hidden Profile',
    info: 'This task will help you better understand how you exchange information to complete a shared goal. We’ll add these insights to your Skills Profile. ',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Open Task',
    next: 'hidden-profile-instructions',
    progress: '33%',
    questionIndex: 2,
    moduleSize: 3,
    duration: 'Duration: This should take another 10 minutes or so, and you’ll have another chance to pause when you finish.'
  }],
  ['hidden-profile-instructions', {
    buttonText: 'Let\'s begin!',
    instructionList: [
      {
        question: 'Work Together!',
        info: 'Each of you knows several facts about the two vendors;what you know is given below. \nSome of the facts may be known to both of you, while others may be known only to you or only to your virtual teammate, Tabitha.',
        blueInfo:'In this task, you and Tabitha will need to select the best software vendor for your company.',
        progress:'1',
        buttonText:'Continue'
      },
      {
        question: 'Evaluate Facts',
        info: 'Certain facts may be irrelevant to the decision you are trying to make.\n\nThe combined information is sufficient to select the objectively best vendor; Your goal is to interact with your teammate and make the correct selection.',
        progress:'2',
        buttonText:'Open Task',
      }],
    next: 'hidden-profile-questions'
  }],
  ['hidden-profile-end', {
    question: 'Congrats! You finished another task.',
    info: 'Almost there—after the next task, we’ll paint a picture of your collaborative problem-solving skills and what they might mean for your career search. \n\nFeel free to pause if you need a break or start the final task if you’re ready.',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Close Task',
    next: 'negotiation-start',
    progress: '66%',
    questionIndex: 3,
    endIndex: 2,
    moduleSize: 3
  }],

  ['fact-sorting-start', {
    question: 'Fact Sorting',
    info: 'This task will help you better understand how you identify relevant information. We’ll add these insights to your Skills Profile. ',
    duration: 'Duration: This should take about 10 minutes, and you’ll have a chance to pause when you finish. Find a quiet place where you can focus, and let’s begin!',
    image: '../assets/section-1/pointing-finger.svg',
    buttonText: 'Open Task',
    next: 'fact-sorting-instructions',
    progress: '0%',
    questionIndex: 1,
    moduleSize: 3
  }],
  ['fact-sorting-instructions', {
    buttonText: 'Let\'s begin!',
    instructionList: [
      {
        question: 'Work Together!',
        info: 'You and your virtual teammate, Marquis, are tasked with helping your company’s management select the best consulting firm to teach professional and technical writing skills to employees at your company. There are two candidate firms, A and B, and two dimensions that are important: COST and QUALITY.',
        progress:'1',
        buttonText: 'Continue'
      },
      {
        question: 'Choosing Between Two Firms',
        info: 'You and Marquis will be presented with several sets of facts about the two firms. Your job is to determine which facts are relevant to which dimension. \n\nNot all facts will be relevant. At the end, all relevant facts will be submitted to your company’s director so she can make the final decision.',
        progress:'2',
        buttonText: 'Continue'
      },
      {
        question: 'Classifications',
        //title: 'Cost+',
        //info: 'The fact is relevant to the Cost dimension and is positive; that is, it makes the firm more attractive in terms of cost.',
        info: 'Cost+ \n The fact is relevant to the Cost dimension and is positive; that is, it makes the firm more attractive in terms of cost. \n\n Cost- \n The fact is relevant to the Cost dimension but is negative ; that is, it makes the firm less attractive in terms of cost. \n\n Quality+ \n The fact is relevant to the Quality dimension and is positive ; that is, it makes the firm more attractive in terms of quality. \n\n Quality- \n The fact is relevant to the Quality dimension and is negative ; that is, it makes the firm less attractive in terms of quality.\n\n Not Relevant \n The fact is not relevant to either dimension.\n\n\n\n\n\n\n',
        progress:'3',
        buttonText: 'Continue'
      }],
    next: 'fact-sorting-questions/page1'
  }],
  ['fact-sorting-end', {
    question: 'Congrats! You finished the first task.',
    info: 'Your responses will help us explore where your collaborative problem-solving strengths lie and where you have room to grow. We’ll cover this in more detail after you complete all the tasks. \n\nFeel free to pause if you need a break or start the next task if you’re ready.',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Close Task',
    next: 'hidden-profile-start',
    progress: '33%',
    questionIndex: 2,
    endIndex: 1,
    moduleSize: 3
  }],
  ['changing-circumstances-start', {
    question: 'Changing Circumstances',
    info: 'Be sure you have a quiet environment and 15 minutes of time to complete the task. You’ll have the option to pause after this task is complete.',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Next',
    next: 'changing-circumstances-instructions',
    progress: '16.67%'
  }],
  ['changing-circumstances-instructions', {
    buttonText: 'Let\'s begin!',
    instructionList: [
      {
        question: 'Project Management',
        info: 'You are a project manager at WunderKind Interactive, one of the top advertising firms in the United States. For the past six months, you have led a team of writers and artists in the creation of a major new advertising campaign for one of WunderKind’s top clients.\n\nThe campaign is close to being ready to present to your client when you receive the following email from Eileen, your supervisor...',
        progress:'1',
        buttonText: 'Continue'
      },
      {
        question: 'Project Management',
        emails: [{
          to: 'You',
          from: 'Eileen',
          image: '/assets/default-profile-images/default-profile-image-3.svg',
          body:'Sorry to do this to you, but I just got off the phone with the client. They’re suddenly not OK with using animals in their ads-worried about blowback from animal rights activists. We need to redo everything without live animals, but they still want the campaign finished well before the winter holidays.',
        }],
        info: 'There is no quick and easy way to accommodate this request, since live animals are central to your team’s concept.',
        progress:'2',
        buttonText: 'Continue'
      }],
    next: '/coachability-multiple-select-email'
  }],
  ['coachability-multi-choice-video-hint', {
    instructionList: [
      {
        question: 'Your Next Move',
        info: 'After more discussions with the client, you settle on a revised approach to their advertising campaign.\n\nThe new approach, however, means that three of the graphic artists on your team (all of whom are approximately equal in skills and background) will need to master a new software program quickly in order for the team to meet the demanding new schedule.\n\nYou explain the situation to your graphic artists, who have the following reactions...',
        progress:'1',
        buttonText:'Continue'
      }],
  }],
  ['changing-circumstances-end', {
    question: 'Congrats! <br> You completed another task.',
    image: '../assets/section-1/hand-1.png',
    buttonText: 'Close Task',
    next: 'coachability-start',
    progress: '33.33%'
  }],
  ['end-page', {
    question: 'Thank you for taking our survey.\n\nPlease click on the Prolific link to confirm you\'ve completed your participation in this study: https://app.prolific.co/submissions/complete?cc=627E6F47',
  }],
]);
