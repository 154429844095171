<clr-modal [(clrModalOpen)]="showModal" [clrModalClosable]="false" tabindex="-1">
  <h3 class="modal-title section-title" *ngIf="!hint && !submitModal" >Overview</h3>
  <h3 class="modal-title section-title" *ngIf="hint">
    <img class="hint-icon" src="assets/icons/blue-lightbulb-no-flash.svg" alt="blue lightbulb">
    Hint
  </h3>
  <h3 class="modal-title section-title" *ngIf="submitModal">
    <img class="marquis-img" src="/assets/sne/man-searching.svg" alt="man searching" />
  </h3> 
  <h2 *ngIf="title" class="modal-title instruction-title">{{ title }}</h2>
  <h2 *ngIf="!title && list" class="modal-title instruction-title">{{ list[currentPage].title }}</h2>
  <div class="modal-body">
    <p class="content" [class.push-img-down]="list && list[currentPage].image" [innerHTML]="list ? list[currentPage].info : ''"></p>
    <p class="custom-content-info" *ngIf="info && list && list[currentPage].showCustomContent" [innerHTML]="info"></p>
    <img class="modal-img" *ngIf="list && list[currentPage].image" [src]="list[currentPage].image" />
    <div class="external-content" id="customContent" *ngIf="list && list[currentPage].showCustomContent">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="modal-footer overview-footer" [ngStyle]="{'justify-content': !submitModal ? 'space-between' : 'flex-end'}">
    <div *ngIf="!submitModal" class="modal-section-numbers">{{currentPage + 1}}/{{list?.length}}</div>
    <div class="button-wrapper">
      <ets-solid-button [text]="buttonText" [color]="APP_COLORS.uiaction"
                        (clickEvent)="nextInstruction()">
      </ets-solid-button>
    </div>
  </div>
</clr-modal>
