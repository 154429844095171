import { JobPosting, JOB_TYPE } from "../types/job-posting";

// Map of career IDs to job postings
export const CAREER_JOB_POSTINGS_MAP = new Map<number, JobPosting[]>([
  [
    45, [ // Database Administrator

    ]
  ],
  [
    147, [ // Cybersecurity Analyst

    ]
  ],
  [
    148, [ // Data Analyst

    ]
  ],
  [
    149, [ // Marketing Data Analyst

    ]
  ],
  [
    150, [ // Data Scientist

    ]
  ],
  [
    151, [ // Digital Marketing Specialist

    ]
  ],
  [
    152, [ // IT Specialist

    ]
  ],
  [
    153, [ // Project Manager

    ]
  ],
  [
    154, [ // Sales Representative

    ]
  ],
  [
    155, [ // Software Developer

    ]
  ],
  [
    156, [ // Software QA Tester

    ]
  ],
  [
    157, [ // Software Sales Representative

    ]
  ],
  [ 
    158, [ // UX Designer
      {
        jobTitle: "UX Designer",
        company: "Educational Testing Service",
        jobDescription: "Understand, prioritize, and translate client goals into effectively designed digital products. Imagi...",
        jobLocation: "Princeton, NJ",
        jobType: JOB_TYPE.FULL_TIME,
        jobPostUrl: "https://join.apprize.com/careers/apply/ets-ui-ux",
        jobImage: "/assets/job-postings/ets-logo.svg",
      },
    ]
  ],
  [
    159, [ // Web Developer

    ]
  ],
]);