<ets-header
  [isBGWhite]="true"
  [headerState]="headerState"
  [showBackButton]="false"
>
</ets-header>

<ets-sne-split-screen 
  [rightBackgroundColor]="APP_COLORS.white"
  [hideMobile]="'right'"
>
  <div class="left">
    <ets-sne-ques-video
      [title]="questionData?.headerVideo?.title"
      [mainHeader]="questionData?.headerVideo?.mainHeader"
      [videoUrl]="questionData?.headerVideo?.url"
      [videoId]="questionData?.headerVideo?.id"
      [description]="questionData?.headerVideo?.description"
      [playOnce]="questionData?.headerVideo?.playOnce"
    >
    </ets-sne-ques-video>
  </div>
  <div class="right"> 
    <ets-sne-single-multi-choice-and-videos
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType"
      [videos]="questionData?.videos">
    </ets-sne-single-multi-choice-and-videos>
  </div>
</ets-sne-split-screen>
<ets-sne-progress-bar
  [questionIndex]="moduleIndex"
  [overviewEnabled]="true"
  [moduleSize]="moduleSize"
  (nextEmitter)="getNextQuestion()"
  (overviewEmitter)="openInstructionModal()"
  [isButtonDisabled]= "questionData?.inputType === SNE_INPUT_TYPES.RADIO ? !singleResponse
    : multiResponse.length !== questionData?.numSelectOptions"
>
<ets-sne-single-multi-choice-and-videos
      [multiChoiceOptions]="questionData?.singleMulChoiceOptions"
      (selectSingleResponseEvent)="singleResponseHandler($event)"
      (selectMultiResponseEvent)="multiResponseHandler($event)"
      [headerQuestion]="questionData?.header"
      [inputType]="questionData?.inputType"
      [videos]="questionData?.videos"
      [mobileCheck]="true"
      >
    </ets-sne-single-multi-choice-and-videos>
</ets-sne-progress-bar>
<ets-modal
  [title]="instructionModal?.title"
  [showModal]="showInstructionModal"
  [list]="instructionModal?.pageList"
  (modalClose)="closeInstructionModal()"
>
  <div *ngIf="instructionModal?.customContent" contentID=customContent>
    <ets-sne-video *ngIf="instructionModal?.customContent[0].url"
                   [videoUrl]="instructionModal?.customContent[0].url"
                   [videoId]="instructionModal?.customContent[0].id"
                   [videoOnlyFlag]="true">
    </ets-sne-video>
  </div>
</ets-modal>