import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SNE_INPUT_TYPES, SocialMediaPost } from 'src/app/types/sne';
import { APP_COLORS } from 'src/global-styles';
import { SneCriticalThinkingBase } from '../sne-critical-thinking.base';

@Component({
  selector: 'ets-multi-choice-social-media',
  templateUrl: './ct-multi-choice-social-media.component.html',
  styleUrls: ['./ct-multi-choice-social-media.component.scss']
})
export class CtMultiChoiceSocialMediaComponent extends SneCriticalThinkingBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState: HEADER_STATES = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;
  SNE_INPUT_TYPES = SNE_INPUT_TYPES;
  postList: SocialMediaPost [] = [];

  multiResponseQuestionHandler(response: string) {
    let arrIndex = this.multiResponse.indexOf(response);
    if (arrIndex > -1) {
      // if answer is already in the array, remove it at arrIndex
      this.multiResponse.splice(arrIndex, 1);
    } else {
      this.multiResponse.push(response);
      this.multiResponse.sort();
    }
  }

  singleReponseQuestionHandler(questionIndex: number, answer: string) {
    if (answer) {
      this.multiResponse[questionIndex] = answer;
    }
  }

  ngOnInit(): void {
    this.processParams();
    if (this.questionData && this.questionData.socialMediaPost) {
      this.postList = this.questionData.socialMediaPost;
    }
  }


}
