enum CIP_PAGES {
  SECTION_THREE_START = 'sectionThreeStart',
  CIP_PAGE_1 = 'cipPage1',
  CIP_PAGE_2 = 'cipPage2',
  CIP_PAGE_3 = 'cipPage3',
  CIP_PAGE_4 = 'cipPage4',
  CIP_PAGE_5 = 'cipPage5',
  CIP_PAGE_6 = 'cipPage6',
  CIP_PAGE_7 = 'cipPage7',
  CIP_PAGE_8 = 'cipPage8',
  CIP_PAGE_9 = 'cipPage9',
  CIP_PAGE_10 = 'cipPage10',
}

export default CIP_PAGES;
