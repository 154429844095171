import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { environment } from '../../environments/environment';
import { interval } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  cognitoUser: CognitoUserInterface | undefined;
  refreshTokenThreadStarted: boolean = false;

  constructor() { }

  /**
   * Method to sign in a user
   * @param username for the user
   * @param password for the user
   */
  async signIn(username: string, password: string) {
    // Call Sign In with the values passed in
    const tempUser = await Auth.signIn({
      username: username,
      password: password
    });

    if (tempUser) {
      this.cognitoUser = tempUser;
      // Get the ID JWT Token for the authenticated user and set it in localStorage for all the other services to use
      let accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
      }

      this.createRefreshTokenThread(accessToken);
    }
  }

  protected createRefreshTokenThread(accessToken:string) {
    // create a subscription with an interval where interval is based on token expiration time
    let tokenTimeToLive = this.getTokenTimeToLive(accessToken);
    console.log('refresh token in ' + tokenTimeToLive + ' ms');
    let subscription = interval(tokenTimeToLive).subscribe(async obj => {
      this.refreshTokenThreadStarted = true;
      console.log('performing token refresh',obj);
      const newAccessToken = await this.refreshAccessTokenAsync();
      console.log('sameAccessToken', (newAccessToken !== localStorage.getItem('accessToken')));
      localStorage.setItem('accessToken', newAccessToken);
      // unsubscribe and start new interval 
      subscription.unsubscribe();
      this.createRefreshTokenThread(newAccessToken);
    }
    );
  }

  protected getTokenTimeToLive(token: string) {
    if (!token) {
      return 0;
    }

    try {
      const s = token.split('.');
      if (s.length === 3) {
        let text1 = atob(s[1]);
        let user = JSON.parse(text1);
        if (user) {
          const now = new Date();
          const nowInMs = now.getTime();
          if (user.exp) {
            console.log('user.exp', user.exp, new Date(user.exp*1000));
            return (user.exp * 1000) - nowInMs;
          } else {
            console.log('no exp so returning 10 seconds');
            return 10000;
          }

        }
      } else {
        console.log('Invalid auth token format');
      }

      return 20000;
    } catch (err) {
      console.log(err);
      return 30000;
    }
  }

  /**
   * Method to sign up/create a user within Cognito
   * @param username for the user
   * @param password for the user
   * @param email for the user
   * @param given_name for the user
   * @param family_name for the user
   */
  async signUp(username: string, password: string, email: string, given_name: string, family_name: string, keep_me_updated: boolean, urlQueryString?: string) {
    const currentHostname = window.location.hostname;
    let partner_affiliation: string = '';
    if (currentHostname !== environment.baseHostname &&
      currentHostname.indexOf(environment.baseHostname) > -1) {
      partner_affiliation = currentHostname.substring(0, (currentHostname.length - environment.baseHostname.length - 1));
    }
    

    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        given_name,
        family_name,
        'custom:partner_affiliation': partner_affiliation,
        'custom:send_email_updates': (keep_me_updated?'TRUE':'FALSE'),
        'custom:url_query_string': (urlQueryString?urlQueryString:null)
      }
    });
  }

  /**
   * Method to confirm a user's sign up with an emailed verification code
   * @param username for the user
   * @param code emailed to the user
   */
  async confirmSignUp(username: string, code: string) {
    await Auth.confirmSignUp(username, code);
  }

  /**
   * Method to resend an emailed verification code
   * @param username for the user
   */
  async resendConfirmationCode(username: string) {
    await Auth.resendSignUp(username);
  }

  /**
   * Method to initiate a forgotten password request
   * @param username for the user
   */
  async forgotPassword(username: string) {
    await Auth.forgotPassword(username);
  }

  /**
   * Method to submit a forgotten password request
   * @param username for the user
   */
  async forgotPasswordSubmit(username: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(username, code, password);
  }

  /**
   * Method to determine if the current user is logged in
   * @returns true if the current user is logged in or false if not
   */
  isLoggedIn(): boolean {
    // User is not logged in if they don't have an access token present in localStorage
    let loggedIn = (localStorage.getItem('accessToken') !== null && localStorage.getItem('accessToken') !== undefined);

    if (loggedIn && ! this.refreshTokenThreadStarted) {
      let accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        console.log('refresh token thread not started - starting it now');
        this.createRefreshTokenThread(accessToken);
      }
    }
    return loggedIn;
  }

  /**
   * Method to logout a user by clearing out their access token and reloading the page
   */
  logoutUser(): void {
    localStorage.clear();
    window.location.reload();
  }

  async refreshAccessTokenAsync() {
    // console.log('inside refreshAccessTokenAsync');
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
  }
}