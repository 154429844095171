enum COACHABILITY_TYPES {
  SURVEY = 'survey',
  CHANGING_CIRCUMSTANCES = 'changing-circumstances',
  COACHES = 'coaches',
  CRITICISM = 'criticism',
  PERSONAL_MISTAKES = 'personal-mistakes',
  UNCERTAINTY = 'uncertainty'
}

export default COACHABILITY_TYPES;
