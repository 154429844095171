import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnBoardingV2Base } from '../../on-boarding-v2.base';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { EducationHistory } from 'src/app/types/education-history-types';
import { EducationHistoryService } from 'src/app/services/education-history.service';
import * as _ from 'lodash';
import { EventService } from 'src/app/services/event.service';
import { combineLatest } from 'rxjs';
import { FIELD_OF_STUDY } from 'src/app/data/field-of-study';
import { higherEducationList } from '../../on-boarding-v2.helpers';

@Component({
  selector: 'ets-field-of-study',
  templateUrl: './field-of-study.component.html',
  styleUrls: ['./field-of-study.component.scss']
})
export class FieldOfStudyComponent extends OnBoardingV2Base implements OnInit, OnDestroy, AfterViewChecked {
  constructor(protected store: Store, protected router: Router, protected route: ActivatedRoute,
    protected profileService: ProfileService, protected educationHistoryService: EducationHistoryService, protected eventService: EventService) {
    super(store, router, route, profileService, eventService);
  }

  eduForm = new FormGroup({});
  userEduList: string[] = [];
  error: String = 'This field is required.';
  _ = _;

  // The map that populates the list of majors in the popup for each education - key is education type, value is list of majors
  typeaheadMajorsMap: Map<string, string[]> = new Map<string, string[]>();
  // The map that stores the selected major for each education - key is education type, value is selected major
  selectedMajorMap: Map<string, string> = new Map<string, string>();
  possibleMajors = FIELD_OF_STUDY; // The array of possible majors that never gets modified
  // The map that stores the typeahead input for each education - key is education type, value is the input
  typeaheadInputMap: Map<string, string> = new Map<string, string>();


  toggleSelectedTypeaheadMajor(edu: string, major: string): void {
    this.typeaheadMajorsMap.set(_.camelCase(edu), []);
    this.selectedMajorMap.set(_.camelCase(edu), major);
    this.eduForm.controls[_.camelCase(edu)].setValue(major);
  }

  // Gets called in base class
  processQuestion() {
    if (this.userProfile) {
      this.setUserEduList();
      this.processProfile();
      this.createForm();
      this.populateForm();
    }
  }

  setUserEduList() {
    this.userEduList = [];
    if (this.userProfile && this.userProfile.educationHistory) {
      this.userProfile.educationHistory.forEach((history) => {
        if (higherEducationList.includes(history)) {
          this.userEduList.push(history);
          this.typeaheadInputMap.set(_.camelCase(history), '');
          this.typeaheadMajorsMap.set(_.camelCase(history), []);
          this.selectedMajorMap.set(_.camelCase(history), '');
        }
      });
    }
  }

  createForm() {
    if (this.userEduList.length > 0) {
      this.userEduList.forEach(history => {
        const formControlName = _.camelCase(history);

        this.eduForm.addControl(formControlName, new FormControl('',
          [Validators.required, Validators.maxLength(256)])
        );
        this.eduForm.addControl(`${formControlName}Studying`, new FormControl(false));
        this.eduForm.addControl(`${formControlName}Completed`, new FormControl(false));
      });
    }
  }

  ngAfterViewChecked(): void {
    if (this.userEduList.length > 0) {
      this.userEduList.forEach((history) => {
        const formControlName = _.camelCase(history);
        this.eduForm.controls[formControlName].valueChanges.subscribe(value => {
          this.typeaheadMajorsMap.set(_.camelCase(history), []);

          if (value && this.selectedMajorMap.get(formControlName) !== value) {
            this.selectedMajorMap.set(formControlName, '');
            this.typeaheadMajorsMap.set(formControlName, []);

            for(let major of this.possibleMajors){
              this.typeaheadInputMap.set(formControlName, value);
              const typeaheadInput = this.typeaheadInputMap.get(formControlName) ? this.typeaheadInputMap.get(formControlName) as string : '';
              if (major.toLowerCase().includes(typeaheadInput.toLowerCase())) {
                const typeaheadMajors = this.typeaheadMajorsMap.get(formControlName) ? this.typeaheadMajorsMap.get(formControlName) as string[] : [];
                this.typeaheadMajorsMap.set(formControlName, [...typeaheadMajors, major]);
              }
            }
          }
        });
      });
    }
  }

  showTypeahead(edu: string): boolean {
    const camelCaseEdu = _.camelCase(edu);
    const typeaheadMajors = this.typeaheadMajorsMap.get(camelCaseEdu) ? this.typeaheadMajorsMap.get(camelCaseEdu) as string[] : [];
    const typeaheadInput = this.typeaheadInputMap.get(camelCaseEdu) ? this.typeaheadInputMap.get(camelCaseEdu) as string : '';
    const selectedMajor = this.selectedMajorMap.get(camelCaseEdu) ? this.selectedMajorMap.get(camelCaseEdu) as string : '';

    return typeaheadMajors.length > 0 && typeaheadInput.length > 0 && selectedMajor.length === 0 && edu !== 'Bootcamp or technical training';
  }

  populateForm() {
    this.subs.push(this.educationHistoryService.getEducationHistoryRecords().subscribe(records => {
      records.forEach((record) => {
        let edType = record.educationType;
        if (this.eduForm.controls[_.camelCase(edType)]) {
          this.eduForm.controls[_.camelCase(edType)].setValue(record.fieldOfStudy);
          // Setting the selected major will prevent the typeahead from displaying
          this.selectedMajorMap.set(_.camelCase(edType), record.fieldOfStudy);
          // False on the toggle/checkbox means that the user is currently studying so we need the opposite of the value
          this.eduForm.controls[`${_.camelCase(edType)}Studying`].setValue(!record.currentlyStudying);
        }
      });
    }));
  }

  processProfile() {
    if (this.currentQuestion && this.userProfile) {
      if (this.currentQuestion && this.currentQuestion.errorMessage) {
        this.error = this.currentQuestion.errorMessage;
      }
    }
  }

  triggerNextQuestion() {
    this.submitResponse();
  }

  disableSubmit() {
    if (this.eduForm && this.eduForm.controls) {
      let invalid = false;
      if (this.userEduList.length > 0) {
        this.userEduList.forEach(history => {
          const key = _.camelCase(history);
          if (key && !invalid) {
            invalid = this.eduForm.controls[key].invalid;
          }
        });
      }
      return invalid;
    } else {
      return true;
    }
  }

  submit() {
    let educationHistoryArr: EducationHistory[] = [];
    this.userEduList.forEach(history => {
      const edHistoryObject: EducationHistory = {
        educationType: history,
        fieldOfStudy: this.eduForm.controls[_.camelCase(history)].value,
        // False on the toggle/checkbox means that the user is currently studying so we need the opposite of the value
        currentlyStudying: !this.eduForm.controls[`${_.camelCase(history)}Studying`].value
      };
      educationHistoryArr.push(edHistoryObject);
    });
    const $profile = this.profileService.saveProfile({...this.userProfile});
    const $fieldOfStudy = this.educationHistoryService.postAction(educationHistoryArr);
    this.subs.push(combineLatest([$profile, $fieldOfStudy]).subscribe(([profile, study]) => {
      if (profile && study) {
        this.proceedToNextQuestion(profile);
      }
    }));
    this.userResponse = undefined;
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.teardown();
  }
}
