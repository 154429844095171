<div class="container">
  <div class="resume-prompt-container">
    <img
      class="image-icon"
      src=".../../../../assets/on-boarding/woman-gear-blue.png"
      alt=""
      aria-hidden="true"
    />
    <div class="text-container">
      <span class="question" [innerHTML]="currentQuestion?.question"></span>
      <span class="description" [innerHTML]="currentQuestion?.info">{{currentQuestion?.info}}</span>
    </div>
  </div>

  <div class="button-containers">
    <ets-rounded-contained-button
      class="add-resume-button"
      style="width: 226px"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#0E3AD6'"
      [textColor]="'#fff'"
      [buttonText]="'ADD MY RESUME'"
      [ariaLabel]="'add my resume'"
      (click)="triggerAddResume()"
    >
    </ets-rounded-contained-button>
    <ets-rounded-contained-button
      style="width: 226px"
      type="button"
      [fullWidth]="true"
      [backgroundColor]="'#ffffff'"
      [textColor]="'#0E3AD6'"
      [buttonText]="'FINISH'"
      (click)="triggerCareerSuggestions()"
    >
    </ets-rounded-contained-button>
  </div>
</div>
