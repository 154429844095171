import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneModal,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import WC_TYPES from 'src/app/types/written-communication-types';

export const WCSet2Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Improving a Work Email',
      info: 'This task will give you insight into how you comprehend and improve written content, and we will use what we learn together to enhance your Skills Profile.',
      duration: '<span class="boldText">Duration:</span> This task should take about 25 minutes, and you’ll have the chance to pause when you finish.',
      questionIndex: 2,
      moduleSize: 3,
      image:'/assets/section-5/sne-interstitial/written-com.svg',
      buttonText: 'NEXT',
      modalBeforeNext: true,
      durationBoxColor: 'lbb',
    },
  ],
  ['question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Mr. Baker is considering changing the word <span class="highlighted">highlighted</span> in sentence 2. Which of the following best contributes to the function of sentence 2 in the email?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we\'ve seen other companies make. <span class="highlighted">Specifically</span>, our employees need to know that they are allowed to blow off steam and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'Specifically (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'Therefore',
          userLetter: 'B'
        },
        {
          userOption: 'Sometimes',
          userLetter: 'C'
        },
        {
          userOption: 'Respectfully',
          userLetter: 'D'
        },
      ],
      submitId: 'item_1'
    }],
  ['question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Mr. Baker may replace the phrase “blow off steam” <span class="highlighted">highlighted</span> in sentence 2. Which of the following best matches the style of the email?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to <span class="highlighted">blow off steam</span> and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'blow off steam (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'relieve stress',
          userLetter: 'B'
        },
        {
          userOption: 'cool their heads',
          userLetter: 'C'
        },
        {
          userOption: 'leverage self-care strategies',
          userLetter: 'D'
        },
      ],
      submitId: 'item_2'
    }],
  ['question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Vice President Baker is considering removing sentence 3 (<span class="highlighted">highlighted</span>). Should he make the change? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work. <span class="highlighted">They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc.</span> How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'Yes, because it refers to details about other firms rather than relevant facts about SwellCo.',
          userLetter: 'A'
        },
        {
          userOption: 'Yes, because it involves an unreasonable assumption about how workers react to differences among workplaces.',
          userLetter: 'B'
        },
        {
          userOption: 'No, because it provides details needed to convey the meaning of the next sentence (4).',
          userLetter: 'C'
        },
        {
          userOption: 'No, because it shows that Mr. Baker understands SwellCo employees’ concerns.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_3'
    }],
  ['question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Which of the following best expresses the purpose of sentence 5 (<span class="highlighted">highlighted</span>)?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> <span class="highlighted">So, this is about retaining talent.</span> Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'It introduces a new topic discussed in the remainder of the email.',
          userLetter: 'A'
        },
        {
          userOption: 'It conveys the main ideas developed in the email.',
          userLetter: 'B'
        },
        {
          userOption: 'It shows respect for the quality of work done by SwellCo employees.',
          userLetter: 'C'
        },
        {
          userOption: 'It summarizes the argument developed in previous statements in the email.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_4'
    }],
  ['question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Mr. Baker is considering changing the beginning of sentence 6 (<span class="highlighted">highlighted</span>) to read: \n“Now, the budget-minded will object, saying...” <br> <br> Should he make the change? Why or why not?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. <span class="highlighted">Now, the bean counters will say</span> that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don\'t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'No, because the old version introduces needed humor to an otherwise serious subject.',
          userLetter: 'A'
        },
        {
          userOption: 'No, because the new version makes it less clear that the stated view is different from Mr. Baker’s.',
          userLetter: 'B'
        },
        {
          userOption: 'Yes, because the old version conflicts with the kinds of recreational activities mentioned earlier in the email.',
          userLetter: 'C'
        },
        {
          userOption: 'Yes, because the new version uses terminology that is more appropriate for a work email.',
          userLetter: 'D'
        },
      ],
      submitId: 'item_5'
    }],
  ['question-6',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'If a replacement is needed, which word best replaces “since,” the <span class="highlighted">highlighted</span> word?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
        [
          {
            from: 'Alan Baker',
            to: 'You',
            emailSubject: 'Earning Employee Loyalty',
            image: '/assets/default-profile-images/default-profile-image-1.svg',
            body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; <span class="highlighted">since</span>, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
          }
        ],
      singleMulChoiceOptions: [
        {
          userOption: 'since (No change)',
          userLetter: 'A'
        },
        {
          userOption: 'meanwhile',
          userLetter: 'B'
        },
        {
          userOption: 'admitting',
          userLetter: 'C'
        },
        {
          userOption: 'so',
          userLetter: 'D'
        },
      ],
      submitId: 'item_6'
    }],
  // LEAVE THIS COMMENT, WE MAY WANT TO ADD THIS QUESTION BACK IN LATER:
  // ['question-8',
  //   {
  //     questionType: QUESTION_TYPES.SPLIT_SCREEN_MEMO_MULTIPLE_CHOICE,
  //     header: 'Vice President Baker is considering removing the highlighted part of sentence 7. Should he make the change? Why or why not?',
  //     inputType: SNE_INPUT_TYPES.RADIO,
  //     subtitle: 'Earning Employee Loyalty',
  //     memo: ['I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make.',
  //       'Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work.',
  //       'They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc.',
  //       'How long are they going to want to stick with a place where they are expected to work in a cubicle all day?',
  //       'So, this is about retaining talent.',
  //       'Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages.',
  //       'You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks<span class="highlighted">; since, you don’t know whether workers would have left SwellCo without the policy change.</span>',
  //       'The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier.',
  //       'A business that shows it cares about worker health and happiness earns loyalty.'
  //     ],
  //     singleMulChoiceOptions: [
  //       {
  //         userOption: 'Yes, because the first part of the sentence expresses the main point of the sentence sufficiently.',
  //         userLetter: 'A'
  //       },
  //       {
  //         userOption: 'Yes, because it unnecessarily conveys uncertainty on the part of the writer.',
  //         userLetter: 'B'
  //       },
  //       {
  //         userOption: 'No, because it provides details to support an important claim in the previous sentence (6).',
  //         userLetter: 'C'
  //       },
  //       {
  //         userOption: 'No, because it augments other statements in the email about the advantages of the new policy.',
  //         userLetter: 'D'
  //       },
  //     ],
  //     submitId: 'item_7'
  //   }],
  ['question-7',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      header: 'Which of the following best expresses the vice president’s main intent in sending this email?',
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread:
      [
        {
          from: 'Alan Baker',
          to: 'You',
          emailSubject: 'Earning Employee Loyalty',
          image: '/assets/default-profile-images/default-profile-image-1.svg',
          body: 'I really think it is time for SwellCo to adopt some of the workplace enhancements that we’ve seen other companies make. Specifically, our employees need to know that they are allowed to blow off steam and recharge while at work. They see employees at other firms getting time and access to things like recreation rooms, arcade games, pool tables, etc. How long are they going to want to stick with a place where they are expected to work in a cubicle all day? <br> <br> So, this is about retaining talent. Now, the bean counters will say that making these changes creates immediate, tangible costs but has unknown advantages. You pay for the space and equipment but lose the work that would have been done by the employees taking their play breaks; since, you don’t know whether workers would have left SwellCo without the policy change. The companies that allow recreational time at work, though, say that productivity actually increases and that their employees are happier. A business that shows it cares about worker health and happiness earns loyalty.'
        }
      ],
      singleMulChoiceOptions: [
        {
          userOption: 'Convincing the other VPs to adopt his own viewpoint on the subject',
          userLetter: 'A'
        },
        {
          userOption: 'Demonstrating his knowledge of important developments in the technology sector',
          userLetter: 'B'
        },
        {
          userOption: 'Warning other VPs about problems that are affecting the Human Resources division',
          userLetter: 'C'
        },
        {
          userOption: 'Indirectly letting the other VPs know that he is considering leaving SwellCo',
          userLetter: 'D'
        },
      ],
      submitId: 'item_7',
      complete: true
    }],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished another task!',
      info: 'Your decisions help illustrate how you evaluate written content, where your strengths lie, and where you have room to grow.<br><br>Feel free to pause if you need a break or start the next task if you are ready. One more to go!',
      questionIndex: 2,
      moduleSize: 3,
      image: '/assets/section-5/sne-interstitial/written-com-end-interstitial.png',
      buttonText: 'NEXT',
      goto: WC_TYPES.SET_3
    },
  ],
]);

export const WCSet2InstructionModal: SneModal = {
  pageList: [
    {
      info: 'The following is a draft of an email by Alan Baker, the vice president (VP) of Human Resources at SwellCo, a large firm in the technology sector. \
              Baker plans to send the email to the other VPs at SwellCo.'
    }
  ],
  title: 'Evaluating Email',
};
