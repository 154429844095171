import {
  SneQuestion,
  SneVideos,
  SNE_INPUT_TYPES,
  SneMulChoiceOption,
  SneModal,
  Email,
  ChatMessage,
} from 'src/app/types/sne';
import QUESTION_TYPES from 'src/app/types/question-types';
import CT_TYPES from 'src/app/types/critical-thinking-types';

const EmailThread: Email[] = [
  {
    emailSubject: 'Cause for Celebration!',
    emailResponse: [
      {
        from: 'Ed',
        to: 'Lucie',
        image: '/assets/sne/ct-ed-profile-pic.png',
        body: 'Congratulations, everyone! I want to take some time right now to thank all of you for your hard work and dedication. Many of you may already know that we just finalized the contract with an important new client. I also want to share that I think the office redesign really contributed to our success in signing on this client. Multiple team members have told me that the new shared work spaces, comfortable couches, inspiring art, and bright natural light have helped them to feel more focused and productive. Oh, and by the way, we’ve ordered a cake for tomorrow so we can all celebrate our relationship with our new client together!',
      },
      {
        from: 'Lucie',
        to: 'Ed',
        image: '/assets/sne/ct-lucie-profile-pic.png',
        body: 'I’m so proud of our team! We all deserve a round of applause for signing on such a great new client. I know you’ve all been working really hard and I hope you’ll take some time to savor this victory. I hope you’re also enjoying our beautifully redesigned office space—I definitely am! However, I don’t think the redesign has anything to do with our signing on this new client. First of all, several of the key staff people who’ve been handling the new client since they first contacted us work from home. So those staff members have not actually had a chance to experience the redesigned office. Also, don’t forget that we recently redesigned our website. Just a few days after the new website launched, our new client reached out to us.',
      },
    ],
  },
];

const EmailThreadAlt: Email[] = [
  {
    emailSubject: 'Cause for Celebration!',
    emailResponse: [
      {
        from: 'Ed',
        to: 'Lucie',
        image: '/assets/sne/ct-ed-profile-pic.png',
        body: 'Congratulations, everyone! I want to take some time right now to thank all of you for your hard work and dedication. Many of you may already know that we just finalized the contract with an important new client. I also want to share that I think the office redesign really contributed to our success in signing on this client. Multiple team members have told me that the new shared work spaces, comfortable couches, inspiring art, and bright natural light have helped them to feel more focused and productive. Oh, and by the way, we’ve ordered a cake for tomorrow so we can all celebrate our relationship with our new client together!',
      },
      {
        from: 'Lucie',
        to: 'Ed',
        image: '/assets/sne/ct-lucie-profile-pic.png',
        body: 'I’m so proud of our team! <span class="convertToBlue"> We all deserve a round of applause for signing on such a great new client. I know you’ve all been working really hard and I hope you’ll take some time to savor this victory. </span> I hope you’re also enjoying our beautifully redesigned office space—I definitely am! <span class="convertToBlue"> However, I don’t think the redesign has anything to do with our signing on this new client. </span> First of all, several of the key staff people who’ve been handling the new client since they first contacted us work from home. So those staff members have not actually had a chance to experience the redesigned office. Also, don’t forget that we recently redesigned our website. Just a few days after the new website launched, our new client reached out to us.',
      },
    ],
  },
];

const EmailThreadAlt2: Email[] = [
  {
    emailSubject: 'Cause for Celebration!',
    emailResponse: [
      {
        from: 'Ed',
        to: 'Lucie',
        image: '/assets/sne/ct-ed-profile-pic.png',
        body: 'Congratulations, everyone! <span class="convertToBlue"> I want to take some time right now to thank all of you for your hard work and dedication. Many of you may already know that we just finalized the contract with an important new client.</span>  I also want to share that I think the office redesign really contributed to our success in signing on this client. Multiple team members have told me that the new shared work spaces, comfortable couches, inspiring art, and bright natural light have helped them to feel more focused and productive.<span class="convertToBlue"> Oh, and by the way, we’ve ordered a cake for tomorrow so we can all celebrate our relationship with our new client together!</span>',
      },
      {
        from: 'Lucie',
        to: 'Ed',
        image: '/assets/sne/ct-lucie-profile-pic.png',
        body: 'I’m so proud of our team! <span class="convertToBlue"> We all deserve a round of applause for signing on such a great new client. I know you’ve all been working really hard and I hope you’ll take some time to savor this victory. </span> I hope you’re also enjoying our beautifully redesigned office space—I definitely am! <span class="convertToBlue"> However, I don’t think the redesign has anything to do with our signing on this new client. </span> First of all, several of the key staff people who’ve been handling the new client since they first contacted us work from home. So those staff members have not actually had a chance to experience the redesigned office. Also, don’t forget that we recently redesigned our website. Just a few days after the new website launched, our new client reached out to us.',
      },
    ],
  },
];

const question_1_answers: SneMulChoiceOption[] = [
  {
    userOption:
      'Lucie wrote her email first, and Ed wrote his email in response to Lucie’s.',
    userLetter: 'A',
  },
  {
    userOption:
      'Ed wrote his email first, and Lucie wrote her email in response to Ed’s.',
    userLetter: 'B',
  },
  {
    userOption:
      'It is not possible to tell which of the emails was written first.',
    userLetter: 'C',
  },
];

const question_2_answers: SneMulChoiceOption[] = [
  {
    userOption:
      'We all deserve a round of applause for signing on such a great new client.',
    userLetter: 'A',
  },
  {
    userOption:
      'I know you’ve all been working really hard and I hope you’ll take some time to savor this victory.',
    userLetter: 'B',
  },
  {
    userOption:
      'However, I don’t think the redesign has anything to do with our signing on this new client.',
    userLetter: 'C',
  },
];

const question_3_answers: SneMulChoiceOption[] = [
  {
    userOption: 'The firm’s office space used to be uncomfortable and dingy.',
    userLetter: 'A',
  },
  {
    userOption: 'The firm was recently hired by a valuable new client.',
    userLetter: 'B',
  },
  {
    userOption: 'The firm is composed mostly of people who work from home.',
    userLetter: 'C',
  },
  {
    userOption: 'Lucie and Ed’s emails do not include any points of agreement.',
    userLetter: 'D',
  },
];

const question_4_part1_answers: SneMulChoiceOption[] = [
  {
    userOption:
      'I want to take some time right now to thank all of you for your hard work and dedication.',
    userLetter: 'A',
  },
  {
    userOption:
      'Many of you may already know that we just finalized the contract with an important new client.',
    userLetter: 'B',
  },
  {
    userOption:
      'Oh, and by the way, we’ve ordered a cake for tomorrow so we can all celebrate our relationship with our new client together!',
    userLetter: 'C',
  },
];
const question_4_part2_answers: SneMulChoiceOption[] = [
  {
    userOption:
      'We all deserve a round of applause for signing on such a great new client.',
    userLetter: 'A',
  },
  {
    userOption:
      'I hope you’re also enjoying our beautifully redesigned office space-I definitely am!',
    userLetter: 'B',
  },
  {
    userOption:
      'First of all, several of the key staff people who’ve been handling the new client since they first contacted us work from home.',
    userLetter: 'C',
  },
];

const question_5_answers: SneMulChoiceOption[] = [
  {
    userOption: 'Supports Lucie’s opinion',
    userLetter: 'A',
  },
  {
    userOption: 'Supports Ed’s opinion',
    userLetter: 'B',
  },
  {
    userOption: 'Supports Neither opinion',
    userLetter: 'C',
  },
];

const question_5_colleague_comments: ChatMessage[] = [
  {
    user: 'Colleague 1',
    message: 'I just heard that one of our other clients recommended us to this new client months before the office redesign began.​',
  },
  {
    user: 'Colleague 2',
    message: 'After our last meeting with the new client in our redesigned office, their CEO commented that it was a pleasure to do business in such a beautiful space.',
  },
  {
    user: 'Colleague 3',
    message: 'Open-concept office spaces are becoming more common across the country, but not everyone prefers them.',
  },
];

export const CTArgumentation2Data: Map<string, SneQuestion> = new Map([
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Analyzing Emails',
      info: 'This task will give you insight into how you compare and contrast written information, and we will use what we learn together to enhance your Skills Profile.',
      image: '/assets/section-2/sne-interstitial/argumentation.svg', // placeholder
      questionIndex: 6,
      moduleSize: 6,
      duration: '<span class="boldText">Duration:</span> This should take about 10 minutes, and is the last task in this section!',
      durationBoxColor: 'aquamarine',
      buttonText: 'NEXT',
      modalBeforeNext: true,
    },
  ],
  [
    'question-1',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question:
            'Think about Ed and Lucie’s emails. Select the statement that <span class="convertToBlue">best</span> describes the order in which the emails were probably written.',
          mulChoiceOptions: [...question_1_answers],
        },
      ],
      submitId: 'item_1',
    },
  ],
  [
    'question-2',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThreadAlt],
      multipleMulChoiceOptions: [
        {
          question:
            'From the three highlighted sentences in Lucie’s email, select the one that <span class="convertToBlue">best</span> shows that Lucie wrote her email in response to Ed.',
          mulChoiceOptions: [...question_2_answers],
        },
      ],
      submitId: 'item_2',
    },
  ],
  [
    'question-3',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThread],
      multipleMulChoiceOptions: [
        {
          question:
            'On which of these points, if any, would Lucie and Ed <span class="convertToBlue">most likely</span> agree?',
          mulChoiceOptions: [...question_3_answers],
        },
      ],
      submitId: 'item_3',
    },
  ],
  [
    'question-4',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_EMAIL,
      inputType: SNE_INPUT_TYPES.RADIO,
      emailThread: [...EmailThreadAlt2],
      numSelectOptions: 2,
      header:
        'Which sentences in the emails best show that Lucie and Ed agree that the firm was recently hired by a valuable new client?',
      multipleMulChoiceOptions: [
        {
          question:
            'Read the three sentences from <span class=\'convertToBlue\'> Ed\'s </span> email. Then select the one that best shows Ed agrees that the firm was recently hired by a valuable new client.',
          mulChoiceOptions: [...question_4_part1_answers],
        },
        {
          question:
            'Read the three sentences from <span class=\'convertToBlue\'>Lucie\'s </span>  email. Then select the one that best shows Lucie agrees that the firm was recently hired by a valuable new client.',
          mulChoiceOptions: [...question_4_part2_answers],
        },
      ],
      submitId: 'item_4',
    },
  ],
  [
    'question-5',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_COMMENTS,
      inputType: SNE_INPUT_TYPES.RADIO,
      contentHeader: 'COMMENTS',
      chatMessages: [...question_5_colleague_comments],
      numSelectOptions: 3,
      header:
        'Three of Lucie and Ed’s colleagues also emailed comments to the team. Read the comments by Lucie and Ed’s colleagues. Then answer accordingly to show whether each comment supports Lucie’s opinion about the office redesign, supports Ed’s opinion about the office redesign, or supports neither Lucie nor Ed’s opinion.',
      multipleMulChoiceOptions: [
        {
          question: 'Colleague 1',
          mulChoiceOptions: [...question_5_answers],
        },
        {
          question: 'Colleague 2',
          mulChoiceOptions: [...question_5_answers],
        },
        {
          question: 'Colleague 3',
          mulChoiceOptions: [...question_5_answers],
        },
      ],
      submitId: 'item_5',
      complete: true,
    },
  ],

  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the last task!',
      info: 'Nice work interpreting your colleagues’ emails! Your decisions help illustrate how you evaluate opinions, where your strengths lie, and where you have room to grow.<br><br>Check out your results to learn more about what this means, how we will apply it to your Skills Profile, and how it might influence your career search.',
      buttonText: 'REVIEW REPORT',
      image: '/assets/section-2/sne-interstitial/ct-end-interstitial.png', // placeholder
      questionIndex: 6,
      moduleSize: 6,
      goto: '/sne-new/report/ct'
    },
  ],
]);

export const CTArgumentation2InstructionModal: SneModal = {
  title: 'Congratulating the Team',
  pageList: [
    {
      info: 'Lucie and Ed both work for a small consulting firm. <br><br>You will read two emails that Lucie and Ed recently sent to their team.'
    },
  ],
};
