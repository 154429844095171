import { SNE_INPUT_TYPES, SneMulChoiceOption, SneQuestion } from '../../../types/sne';
import COACHABILITY_TYPES from '../../../types/coachability-types';
import QUESTION_TYPES from 'src/app/types/question-types';

const answers: SneMulChoiceOption[] = [
  {
    userOption: 'Strongly Disagree',
    userLetter: 'A',
  },
  {
    userOption: 'Disagree',
    userLetter: 'B'
  },
  {
    userOption: 'Neutral',
    userLetter: 'C'
  },
  {
    userOption: 'Agree',
    userLetter: 'D'
  },
  {
    userOption: 'Strongly Agree',
    userLetter: 'E'
  }
];

export const CoachabilitySurveyData: Map<string, SneQuestion> = new Map([
  [ 
    'intro',{
      questionType: QUESTION_TYPES.SNE_INTRO,
      title: 'Coachability',
      info: 'In this section, we will guide you through six different tasks. Each task will help you understand your learning potential and how that applies to the careers that interest you.\n\nReady to get started?',
      image: '/assets/sne/cch/cch-intro.png',
      buttonText: 'BEGIN!'
    }
  ],
  [
    'start',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Survey',
      info: 'This short survey asks a series of questions that will give you insight into how you operate in the workplace.',
      duration: '<span class="boldText">Duration:</span> This should only take a few minutes, and you will have a chance to pause when you finish. Find a quiet place where you can focus, and select “Next” to begin.',
      questionIndex: 1,
      moduleSize: 6,
      durationBoxColor: 'lbb',
      image: '/assets/section-3/sne-interstitial/survey.svg',
      buttonText: 'Next',
      header: '',
    },
  ],
  [
    'questions',
    {
      questionType: QUESTION_TYPES.SPLIT_SCREEN_MULTIPLE_CHOICE_TEXT,
      header: 'How do you want to reply?',
      title: 'New Coachability Self-Report Survey',
      subtitle:
        'The following survey asks about some of your behaviors and working styles in a workplace environment. Rate how much you agree or disagree with each of the following statements as they apply to you as an employee. “Managers” refers to anyone you report to (supervisor, boss, etc.)<br><br>This survey is anonymous so please answer honestly.',
      buttonText: 'Next',
      inputType: SNE_INPUT_TYPES.RADIO,
      multipleMulChoiceOptions: [
        {
          question: 'I want to learn and am open to changing to improve',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I take responsibility for mistakes',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am determined to master new skills or techniques',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I trust managers\' expertise',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am attentive and listen to instructional feedback',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I engage in honest communication with managers',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I prefer familiar techniques instead of learning new ones',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I know that most mistakes are actually someone else\'s fault',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am genuinely committed to improving my job performance',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am willing to do whatever managers ask',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am hard on myself when I receive constructive criticism',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I get along well with all managers',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I am open to trying new ways of doing things',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I display a positive attitude or outlook',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I provide honest and open feedback to managers',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I exhibit a genuine respect for managers',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I understand that constructive criticism is usually insulting',
          mulChoiceOptions: [...answers]
        },
        {
          question: 'I disagree with managers often',
          mulChoiceOptions: [...answers]
        },
      ],
      numSelectOptions: 18,
      complete: true,
      submitId: 'item_1',
    },
  ],
  [
    'end',
    {
      questionType: QUESTION_TYPES.INTERSTITIAL,
      title: 'Congrats! You finished the first task.',
      info: 'Thanks for completing the survey. Your responses will help us determine how you like to learn and receive feedback at work — we\'ll cover this in more detail after you complete all six tasks. <br> <br> Feel free to pause if you need a break or start the next task if you’re ready.',
      questionIndex: 1,
      moduleSize: 6,
      image: '/assets/section-3/sne-interstitial/coach-end-interstitial.png', // placeholder
      buttonText: 'Next',
      header: '',
      goto: COACHABILITY_TYPES.CHANGING_CIRCUMSTANCES,
    },
  ],
]);
