import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectOnBoardingCurrentQuestionIndex,
  selectOnBoardingQuestionSection,
  selectOnBoardingQuestionType
} from '../store';
import QUESTION_TYPES, { QUESTION_SECTIONS } from '../types/question-types';
import { HEADER_STATES } from '../shared/header/header.helper';
import { Location } from '@angular/common';
import { QUESTION_LIST_V2 } from '../data/sign-up-questions-v2';
import { generateNextQuestionPath } from './on-boarding-v2.helpers';
import { Router } from '@angular/router';
import { APP_COLORS } from '../../global-styles';

@Component({
  selector: 'ets-on-boarding-v2',
  templateUrl: './on-boarding-v2.component.html',
  styleUrls: ['./on-boarding-v2.component.scss']
})
export class OnBoardingV2Component implements OnInit {

  constructor(private store: Store, private location: Location, private router: Router) {}

  onBoardingQuestionType: QUESTION_TYPES | undefined;
  onBoardingQuestionSection: QUESTION_SECTIONS | undefined;
  onBoardingQuestionIndex: number | undefined;
  headerState: HEADER_STATES = HEADER_STATES.BACK;
  questionTypes = QUESTION_TYPES;
  questionSections = QUESTION_SECTIONS;
  questionListLength = QUESTION_LIST_V2.length;
  backgroundColor: string | undefined;

  previous(): void {
    // this.location.back();
    if (this.onBoardingQuestionIndex) {
      const currentQuestion = QUESTION_LIST_V2[this.onBoardingQuestionIndex];
      const previousQuestionIndex = QUESTION_LIST_V2.findIndex(question => question.page === currentQuestion.previousPage);
      if (previousQuestionIndex !== -1) {
        const newPath = generateNextQuestionPath(QUESTION_LIST_V2[previousQuestionIndex], previousQuestionIndex);
        this.router.navigate([newPath]);
      }
    }
  }

  showBackButton(): boolean {
    if (this.onBoardingQuestionIndex !== undefined) {
      return this.onBoardingQuestionIndex > 0;
    } else {
      return true;
    }
  }

  setColor(): void {
    switch (this.onBoardingQuestionSection) {
      case QUESTION_SECTIONS.SECTION_ONE:
        this.backgroundColor = APP_COLORS.mustardbright;
        break;
      case QUESTION_SECTIONS.SECTION_TWO:
        this.backgroundColor = APP_COLORS.aquamarinebright;
        break;
      case QUESTION_SECTIONS.SECTION_THREE:
        this.backgroundColor = APP_COLORS.lbbbright;
        break;
      default:
        this.backgroundColor = undefined;
        console.log('Section not found');
    }
  }

  ngOnInit(): void {
    this.store.select(selectOnBoardingQuestionType).subscribe(type => this.onBoardingQuestionType = type);
    this.store.select(selectOnBoardingQuestionSection).subscribe(section => {
      this.onBoardingQuestionSection = section;
      this.setColor();
    });
    this.store.select(selectOnBoardingCurrentQuestionIndex).subscribe(index => this.onBoardingQuestionIndex = index);
  }

}
