import { Component, OnInit } from '@angular/core';
import { APP_COLORS } from 'src/global-styles';
import { ActivatedRoute, Router } from '@angular/router';
import { HEADER_STATES } from 'src/app/shared/header/header.helper';
import { SneAnswerService } from 'src/app/services/sne-answer.service';
import { SneWrittenCommunicationBase } from '../sne-written-communication.base';


@Component({
  selector: 'ets-wc-multi-choice-email',
  templateUrl: './wc-multi-choice-email.component.html',
  styleUrls: ['./wc-multi-choice-email.component.scss']
})
export class WcMultiChoiceEmailComponent extends SneWrittenCommunicationBase implements OnInit {

  constructor(route: ActivatedRoute, router: Router, sneAnswerService: SneAnswerService) {
    super(route, router, sneAnswerService);
  }

  headerState = HEADER_STATES.BACK;
  APP_COLORS = APP_COLORS;

  singleResponseHandler(answer: string) {
    this.singleResponse = answer;
  }

  ngOnInit(): void {
    this.processParams();
  }
}
