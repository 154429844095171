import { createReducer, on } from '@ngrx/store';
import { loadQuestions, loadQuestionsFailure, loadQuestionsSuccess } from './cip.actions';

export const cipFeatureKey = 'cip';

export interface State {
  questions: any[] | undefined;
  error: string | null;
  status: 'pending' | 'loading' | 'error' | 'success';
}

export const initialState: State = {
  questions: [],
  error: null,
  status: 'pending'    
};

export const reducer = createReducer(
  initialState,
  on(loadQuestions, (state, { }) => ({ 
    // set the status to loading
    ...state, 
    status: 'loading' 
  })),
  on(loadQuestionsFailure, (state, { error }) => ({ 
    // set error message and set status to error
    ...state, 
    status: 'error', 
    error: error 
  })),
  on(loadQuestionsSuccess, (state, { questions }) => ({
    // save questions to state, set status to success, and clear error
    ...state, 
    questions: questions,
    status: 'success',
    error: null 
  })),
);
