interface Profile {
  // Primary key - this is optional because when creating a user, just call saveProfile()
  // passing in the new user with no learnerID
  learnerID?: number;
  password?: string;
  fullName?: string | null;
  preferredName?: string | null;
  email?: string; // varchar(256) NULL CHECK (email REGEXP '^[^@]+@[^@]+\.[^@]{2,}$'),
  aspiration?: string[] | null; // set('Change careers', 'Learn new skills', 'Uncover my strengths', 'Uncover my interests') NULL,
  birthYear?: number | null; // int(4) NULL,
  birthDate?: Date | null;
  residentialStatusUS?: string | null; // enum('U.S. Citizen', 'Naturalized Citizen', 'Immigrant Non-Citizen', 'Visa Holder', 'Other', 'Non-resident') NULL,
  zipCode?: string | null; //char(5) NULL,
  personalGoals?: string[] | null; //set('Better work/life balance', 'More time with family', 'More confidence', 'More financial stability', 'Greater fulfillment', 'A change of direction') NULL,
  initialIndustry?: string | null; // enum('Armed forces', 'Education', 'Plant & machine operations', 'Craft & trades', 'Agricultural', 'Service & sales', 'Clerical support', 'Technictians & associates', 'Professionals', 'Managers') NULL,
  initialRole?: string | null; // varchar(256) default = NULL,
  initialExperience?: string | null; //enum('Less than a year', 'A few years', 'About a decade', 'Many years') NULL,
  desiredWorkHoursPerWeek?: string | null; //enum('Part-time', 'Full-time') NULL,
  desiredRemoteWork?: string | null; //enum('Yes', 'No', 'Remote only') NULL,
  desiredCommute?: string | null; //('Less than 15 minutes', 'Up to 30 minutes', 'Up to one hour', 'Up to two hours') NULL,
  desiredBenefits?: string[] | null; //set('Health Insurance','Life Insurance','Disability Insurance','Dental/Vision','Retirement Savings','Paid Time Off','Family Leave','Wellness Benefits','Commuter Benefits','Continued Education') NULL,
  criticalBenefits?: string[] | null; //set('Health Insurance','Life Insurance','Disability Insurance','Dental/Vision','Retirement Savings','Paid Time Off','Family Leave','Wellness Benefits','Commuter Benefits','Continued Education') NULL,
  desiredSalary?: string | null; // TODO - set('$25000-$50000', '$50000-$75000', '$75000-$100000', 'Over $100000') NULL,
  fluentLanguages?: string[] | null; // set('English', 'Spanish', 'French', 'Italian', 'Chinese', 'Japanese') NULL,
  countryResidence?: string | null; //enum('United States') NULL DEFAULT 'United States',
  countryWorkEligible?: string[] | null; //set('United States') NULL DEFAULT 'United States',
  profileImageURL?: string | null; // varchar(1024) default = NULL,
  personalWebsite?: string | null; // varchar(256) default = NULL,
  diversityWillingToShare?: boolean; // NOT NULL DEFAULT 0,
  diversityGender?: string | null; // enum('Male', 'Female', 'Transgender', 'Non-binary', 'Prefer not to say') NULL,
  diversityLGBTQ?: boolean; // default = NULL,
  diversityLatinX?: string | null;
  diversityRace?: string | null; // enum('Caucasian', 'African American','American Indian', 'Asian', 'Pacific Islander.') NULL,
  diversityDisabilityStatus?: string | null; // enum('Yes', 'No') NULL,
  privacyAllowEmployersToView?: boolean; // NOT NULL DEFAULT true,
  privacyAllowTrainingPartnersToView?: boolean; // NOT NULL DEFAULT true,
  privacyAllowDataPartnersToView?: boolean; // NOT NULL DEFAULT true,
  partnerAffiliation?: string | null; //varchar(256) default=NULL,
  educationHistory?: string[] |null; // enum('Some high school', 'High school or equivalent', 'Vocational training', 'Some college, no degree', 'Associates degree', 'Bachelors degree', 'Advanced degree') NULL,
  ndaCheckOne?: boolean; // NOT NULL DEFAULT 0,
  ndaCheckTwo?: boolean; // NOT NULL DEFAULT 0
  isStudent?: boolean;
  fieldOfStudy?: string | null;
  skippedSections?:string | null;
  lastPageVisited?:string | null;
  onboardingComplete?:boolean;
  currentSalary?: string | null;
  militaryStatus?: boolean;
  currentlyEmployed?: boolean;
  partnerEnrollmentStatus?: string | null; // enum('Applicant','Current Participant','Alumni')
  knowsCodingLangs?: boolean;
  codingLanguages?: string[] | null; // set('HTML','CSS','C','C Sharp','C++','Javascript','Java','php','Python','GO','SQL','Swift')
  workEligibleUS?: boolean;
}

export default Profile;

export interface ProfileSkillsRequest {
  learnerID: number;
  source_taxonomy: SkillTaxonomySourceEnum;
  validation: SkillValidationSourceEnum;
  skills: ProfileSkill[];
}

export interface ProfileSkill {
  skill_name: string;
  proficiency?: number; // If the source_taxonomy is not SELF, there must be a proficiency
}

export enum SkillTaxonomySourceEnum {
    BGT = 'BGT',
    EULER = 'Euler',
}

export enum SkillValidationSourceEnum {
  SELF = 'self',
  ESTIMATION = 'estimation',
  ASSESSMENT = 'assessment',
}

export interface StudentRequest {
  isStudent: boolean;
  fieldOfStudy: string;
}
