import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogObject } from '../types/log';
import { BaseService } from './base.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class SneScoreReportService extends BaseService {
  private sneGenerateScoreURL = environment.sneScoreAPI;

  constructor(private http: HttpClient, protected logService: LogService) {
    super(logService);
    this.serviceName = 'SNE Score Report Service';
  }
 
  // POST Sne score by module to generate score
  generateScoreByModule(moduleName: string): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    let generateModuleReportURL = `${this.sneGenerateScoreURL}/${moduleName}`;
    if (accessToken) {
      let headers = new HttpHeaders().set('Authorization', accessToken);
      let body = {};

      let requestLogObj: LogObject = {
        message: 'SNE: Post Score Request',
        object: body
      };
      this.logService.logInfo(requestLogObj)?.subscribe();

      return this.http.post<any>(generateModuleReportURL, body, {headers}).pipe(
        tap(data => {
          let responseLogObj: LogObject = {
            message: 'SNE: Post Answer Response',
            object: data,
          };
          this.logService.logInfo(responseLogObj)?.subscribe();
        }),
        catchError(this.handleError)
      );
    }
    // Shouldn't happen
    return new Observable<any>();
  }
}
